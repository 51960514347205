import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import {
  useCompaniesControllerCreateCompany,
  useCompaniesControllerDeleteCompany,
  useCompaniesControllerDeletePhoto,
  useCompaniesControllerGetOneCompany,
  useCompaniesControllerUpdateCompany,
} from "../../../api/companies/companies";
import useAppStorage from "../../../hooks/useAppStorage";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import { Attachment } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppDispatch } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import AppTextField from "../../AppTextField";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { DRAWER_UPDATE_STATE } from "../AppDrawer";
import AppSelect from "../../AppSelect";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import { toast } from "sonner";

const CompanyFormSchema = z.object({
  name: z.string().min(1, { message: "Please input a name" }),
  registrationNo: z
    .string()
    .min(1, { message: "Please input a registration number" }),
  code: z.string().min(1, { message: "Please input a company code" }),
  attachment: z.instanceof(File).array().optional(),
  mode: z.enum(["create", "update"]).optional().default("create"),
  environmentType: z.enum(["PRODUCTION", "TESTING"]).default("TESTING"),
});

export type CompanyForm = z.infer<typeof CompanyFormSchema>;

export default function CompanyFormDrawer() {
  const nav = useNavigate();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const qc = useQueryClient();
  const [searchParam, setSearchParams] = useSearchParams();
  const [uploadedPhotos, setUploadedPhotos] = useState<Attachment[]>([]);

  const methods = useForm<CompanyForm>({
    resolver: zodResolver(CompanyFormSchema),
    defaultValues: {
      name: "",
      registrationNo: "",
      code: "",
      attachment: [],
      mode: "create",
      environmentType: "TESTING",
    },
  });

  // const [
  //   createCompany,
  //   { isLoading, isError: createCompanyIsError, isSuccess },
  // ]
  const { mutateAsync: createCompany } = useCompaniesControllerCreateCompany();
  const { useUploadAttachmentMutation } = useAppStorage();
  // const [uploadAttachments, { isLoading: attIsLoading, isError: attIsError }] =
  //   useUploadAttachmentMutation();
  // const [deletePhoto, { isError: deletePhotoIsError }] =
  const { mutate: deletePhoto, isPending: deletePhotoIsPending } =
    useCompaniesControllerDeletePhoto();
  // const [
  //   updateCompany,
  //   {
  //     isLoading: updateCompanyIsLoading,
  //     isSuccess: updateCompanyIsSuccess,
  //     isError: updateCompanyIsError,
  //   },
  // ]
  const { mutateAsync: deleteCompany } = useCompaniesControllerDeleteCompany({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as string).includes("companies");
          },
        });
        setSearchParams(new URLSearchParams());
        nav("/home/super-admin/companies");
        toast("Company deleted successfully");
      },
    },
  });
  const { mutateAsync: updateCompany } = useCompaniesControllerUpdateCompany();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => {
          return (query.queryKey[0] as string).includes("companies");
        },
      });

      setSearchParams((param) => {
        param.delete("drawerType");
        param.delete("drawerState");
        return { ...param };
      });
    },
  });
  const { data: user, isError: getUserIsError } = useGetUserDataQuery();
  const { data: editCompany, isLoading: editCompanyIsLoading } =
    useCompaniesControllerGetOneCompany(searchParam.get("compId") ?? "", {
      query: {
        enabled: !!searchParam.get("compId"),
        select: (res) => res.data,
      },
    });

  const onDeleteUploadedPhoto = async (att: Attachment) => {
    const newUploadedPhotos = uploadedPhotos.filter((v) => v.id !== att.id);
    if (!editCompany || !att.id) return;
    deletePhoto({ attachmentId: att.id, companyId: editCompany.id });
    setUploadedPhotos(newUploadedPhotos);
  };

  const onSubmit: SubmitHandler<CompanyForm> = async (data: CompanyForm) => {
    const { name, registrationNo, code, attachment, environmentType } = data;
    await mutate({
      files: attachment ?? [],
      mutateAsync: async (atts) => {
        if (!user) return;
        if (
          searchParam.get("drawerState") === DRAWER_UPDATE_STATE &&
          editCompany
        ) {
          return await updateCompany({
            companyId: editCompany.id,
            data: {
              name,
              registrationNo,
              code,
              attachments: atts,
              updatedById: user.id,
              environmentType,
            },
          });
        }

        await createCompany({
          data: {
            name,
            registrationNo,
            code,
            createdById: user.id,
            attachments: atts,
            environmentType: data.environmentType,
          },
        });
      },
    });
  };

  // Is edit mode
  useEffect(() => {
    if (searchParam.get("drawerState") === DRAWER_UPDATE_STATE && editCompany) {
      const { name, registrationNo, code, attachments } = editCompany;
      methods.setValue("name", name ?? "");
      methods.setValue("registrationNo", registrationNo ?? "");
      methods.setValue("code", code ?? "");
      setUploadedPhotos(attachments ?? []);
      methods.setValue("mode", "update");
    }
  }, [searchParam, editCompany]);

  // When is editing company, and form is getting data to prefill form, show loading state
  if (editCompanyIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {searchParam.get("drawerState") === DRAWER_UPDATE_STATE
            ? "Update"
            : "Create"}{" "}
          Company
        </p>
        <AppTextField label="Name" name="name" />
        <AppSelect
          label="Environment Type"
          name="environmentType"
          defaultValue="TESTING"
          selections={[
            { label: "Testing", value: "TESTING" },
            { label: "Production", value: "PRODUCTION" },
          ]}
        />
        <AppTextField label="Registration No." name="registrationNo" />
        <AppTextField label="Code" name="code" />
        <Controller
          control={methods.control}
          name="attachment"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFile
                uploadedPhotos={uploadedPhotos}
                onDeleteUploadedPhoto={onDeleteUploadedPhoto}
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.attachment?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex items-center gap-4 mt-4">
          <AppButton
            id="create-company-submit"
            isLoading={isPending}
            label={
              searchParam.get("drawerState") === DRAWER_UPDATE_STATE
                ? "Update"
                : "Create"
            }
            className="w-40"
            onClick={methods.handleSubmit(onSubmit)}
          />
          {searchParam.get("drawerState") === DRAWER_UPDATE_STATE && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editCompany?.name ?? ""}
              onDeleteConfirm={async () => {
                if (!editCompany) return;
                await deleteCompany({ companyId: editCompany.id });
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
