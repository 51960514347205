import { useQueryClient } from "@tanstack/react-query";
import { Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import {
  GetOneWorkOrderResponseDto,
  LedgersDto,
} from "../../../../../../../api/model";
import { useWorkOrderV2ControllerUpdateInventory } from "../../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import useIsCloseWorkOrder from "../../../../../../../hooks/useIsCloseWorkOrder";
import AppButton from "../../../../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../../../ui/dialog";
import SelectInventoryUseSection from "./sections/SelectInventoryUseSection";
import {
  SelectInventoryPageState,
  usePageState,
  useWorkOrderInventoryActions,
  useWorkOrderInventoryLedgers,
} from "../../../../../../../stores/workOrderInventoryStore/workOrderInventoryStore";
import ConfirmInventorySection from "./sections/ConfirmInventorySection";
import { useHasWorkOrderEditAfterClosePolicy } from "../../../../../../../hooks/usePolicies/usePolicies";

interface AddInventoryBtnDialogProps {
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function AddInventoryBtnDialog({
  workOrder,
}: AddInventoryBtnDialogProps) {
  const qc = useQueryClient();
  const isClose = useIsCloseWorkOrder({ workOrder });
  const [currentTab, setCurrentTab] = useState(0);
  const [open, setOpen] = useState(false);
  const pageState = usePageState();
  const ledgers = useWorkOrderInventoryLedgers();
  const { setLedgers, setPageState, cleanUp } = useWorkOrderInventoryActions();
  const hasEditAfterClosePolicy = useHasWorkOrderEditAfterClosePolicy();

  // Clean up ledger, timeout necessary to prevent flickering of the dialog
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!open) timer = setTimeout(() => cleanUp(), 500);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [open]);

  const handleClose = async () => {
    setOpen(false);
    setLedgers([]);
  };

  const handleChange = (event: React.SyntheticEvent, newTabIdx: number) => {
    setCurrentTab(newTabIdx);
  };

  const {
    mutateAsync: updateInventoryUsage,
    isPending: updateInventoryUsagePending,
  } = useWorkOrderV2ControllerUpdateInventory({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (
              (query.queryKey[0] as any).includes("comment") ||
              (query.queryKey[0] as any).includes("/defect/") // This is to ensure that the outer defect tables aren't triggering unnecessary refetching
            );
          },
        });
      },
    },
  });

  const onConfirm = async () => {
    if (!workOrder) return;
    await updateInventoryUsage({
      workOrderId: workOrder?.id?.toString() ?? "",
      data: {
        workOrderId: workOrder?.id ?? 0,
        ledgers: ledgers.map((l) => ({
          ...l,
          adjustmentQty: (-l.adjustmentQty).toString(),
        })),
      },
    });
    setOpen(false);
    setLedgers([]);
  };

  if (isClose && !hasEditAfterClosePolicy) return <></>;

  return (
    <>
      <AppButton
        // loading={btnLoading}
        icon={<Pencil />}
        // label={label}
        label="Inventory"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={(v) => handleClose()}>
        <DialogContent className="w-full max-w-5xl overflow-y-scroll max-h-screen">
          <DialogTitle className="text-2xl font-bold">
            Select Inventory
          </DialogTitle>
          {pageState === SelectInventoryPageState.SELECT_INVENTORY ? (
            <SelectInventoryUseSection />
          ) : (
            <ConfirmInventorySection />
          )}
          <DialogFooter>
            <AppButton
              label={
                pageState === SelectInventoryPageState.SELECT_INVENTORY
                  ? "Cancel"
                  : "Back"
              }
              variant="outline"
              onClick={() => {
                if (pageState === SelectInventoryPageState.CONFIRM_INVENTORY) {
                  setPageState(SelectInventoryPageState.SELECT_INVENTORY);
                  return;
                }
                handleClose();
              }}
            />
            <AppButton
              isLoading={updateInventoryUsagePending}
              label={
                pageState === SelectInventoryPageState.SELECT_INVENTORY
                  ? "Next"
                  : "Confirm"
              }
              onClick={() => {
                if (pageState === SelectInventoryPageState.SELECT_INVENTORY)
                  return setPageState(
                    SelectInventoryPageState.CONFIRM_INVENTORY
                  );
                onConfirm();
              }}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
