import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Divider } from "./Divider";
import { BigCheckBox } from "./BigCheckBox";
import { ItemSection } from "./ItemSection";

export function CertificationOfWorkCompletionSection() {
  return (
    <View style={{ gap: 8 }} wrap={false}>
      <View style={{ gap: 2 }}>
        <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
          E. Certification of Work Completion
        </Text>
        <Divider />
      </View>

      <Text style={{ fontSize: 8 }}>Work Instruction Status</Text>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 12,
        }}
      >
        <BigCheckBox label="Complete" />
        <BigCheckBox label="Not Complete" />
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 12,
        }}
      >
        <BigCheckBox label="Not Complete (KIV - Enforcement Officer|Pegawai Peguasa / Management |Pengurusan / Customer/Pelanggan)" />
        <BigCheckBox label="Not Complete (Not Implemented/Tidak Laksana)" />
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 12,
        }}
      >
        <BigCheckBox label="Not Complete" />
      </View>
      <View style={{ gap: 1 }}>
        <View style={{ display: "flex", flexDirection: "row", width: "50%" }}>
          <ItemSection label="Completed Date/Time" value="" />
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection
            label="Comment by Technician (if any)"
            value=""
            valueWidth="100%"
          />
          <View
            style={{
              width: "30%",
              border: 1,
              borderColor: "#94a3b8",
              padding: 4,
              margin: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 50,
            }}
          >
            <Text style={{ fontSize: 8, textAlign: "center" }}>
              Name & Signature
            </Text>
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection
            label="Comment by Requestor (if any)"
            value=""
            valueWidth="100%"
          />
          <View
            style={{
              width: "30%",
              border: 1,
              borderColor: "#94a3b8",
              padding: 4,
              margin: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 50,
            }}
          >
            <Text style={{ fontSize: 8, textAlign: "center" }}>
              Name & Signature
            </Text>
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection
            label="Customer Rating (Please select)"
            value="( 1 ) Poor | ( 2 ) Requires Improvement | ( 3 ) Satisfactory | ( 4 ) Exceeds Requirement | ( 5 ) Excellent"
            valueWidth="80%"
            labelWidth="20%"
          />
        </View>
      </View>
    </View>
  );
}
