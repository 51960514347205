import { ColumnDef } from "@tanstack/react-table";
import {
  GetStockTypesDto,
  StockType,
} from "../../../../redux/slices/OpenApi/cerevApi";
import { Checkbox } from "../../../../components/ui/checkbox";

export const selectInventoryTypeColumn: ColumnDef<GetStockTypesDto>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Name",
  },
];
