import { ColumnDef } from "@tanstack/react-table";
import { UserEntity } from "../../../../redux/slices/OpenApi/cerevApi";
import { Checkbox } from "../../../ui/checkbox";

export const assignedUserColumns: ColumnDef<UserEntity>[] = [
    {
        accessorKey: "id",
      },
      {
        header: "",
        id: "select",
        cell: ({ row, table }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "position",
        header: "Position"
      }
]