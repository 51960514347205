import { QrCode } from "lucide-react";
import AppButton from "../../../components/AppButton";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "../../../components/ui/dialog";
import { DialogActions } from "@mui/material";

export default function PrintPdfBtn() {
  const nav = useNavigate();
  const [open, setIsOpen] = useState(false);

  return (
    <>
      <AppButton
        variant="outline"
        icon={<QrCode />}
        label="Print QR"
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        open={open}
        onOpenChange={(o) => {
          setIsOpen(o);
        }}
      >
        <DialogContent>
          <DialogTitle>Export Equipment QR Code</DialogTitle>
          <DialogDescription>
            Please choose the size you prefer
          </DialogDescription>
          <Link to="/qr-asset" target="_blank">
            <AppButton
              variant="outline"
              label="A6 Size (ID Card Size)"
              icon={<QrCode />}
            />
          </Link>
          <Link to="/a9-qr-asset" target="_blank">
            <AppButton
              variant="outline"
              label="2.5cm x 5.5cm Size (Badge Size)"
              icon={<QrCode />}
            />
          </Link>
          <DialogActions>
            <AppButton
              label="Cancel"
              onClick={() => {
                setIsOpen(false);
              }}
              variant="outline"
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
