/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateTnbReadingDto,
  GetOneTnbReadingResponse,
  GetTnbReadingsResponseDto,
  TnbAnalyticResponseDto,
  TnbBillControllerGetTnbReadingsParams,
  UpdateTnbReadingDto,
} from ".././model";

export const tnbBillControllerGetTnbReadings = (
  params: TnbBillControllerGetTnbReadingsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetTnbReadingsResponseDto>> => {
  return axios.get(`/tnb-bill`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getTnbBillControllerGetTnbReadingsQueryKey = (
  params: TnbBillControllerGetTnbReadingsParams
) => {
  return [`/tnb-bill`, ...(params ? [params] : [])] as const;
};

export const getTnbBillControllerGetTnbReadingsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
    TnbBillControllerGetTnbReadingsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: TnbBillControllerGetTnbReadingsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
        TError,
        TData,
        Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
        QueryKey,
        TnbBillControllerGetTnbReadingsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTnbBillControllerGetTnbReadingsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
    QueryKey,
    TnbBillControllerGetTnbReadingsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    tnbBillControllerGetTnbReadings(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
    TError,
    TData,
    Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
    QueryKey,
    TnbBillControllerGetTnbReadingsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type TnbBillControllerGetTnbReadingsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>
>;
export type TnbBillControllerGetTnbReadingsInfiniteQueryError =
  AxiosError<unknown>;

export const useTnbBillControllerGetTnbReadingsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
    TnbBillControllerGetTnbReadingsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: TnbBillControllerGetTnbReadingsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
        TError,
        TData,
        Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
        QueryKey,
        TnbBillControllerGetTnbReadingsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTnbBillControllerGetTnbReadingsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTnbBillControllerGetTnbReadingsQueryOptions = <
  TData = Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
  TError = AxiosError<unknown>
>(
  params: TnbBillControllerGetTnbReadingsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTnbBillControllerGetTnbReadingsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>
  > = ({ signal }) =>
    tnbBillControllerGetTnbReadings(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TnbBillControllerGetTnbReadingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>
>;
export type TnbBillControllerGetTnbReadingsQueryError = AxiosError<unknown>;

export const useTnbBillControllerGetTnbReadings = <
  TData = Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
  TError = AxiosError<unknown>
>(
  params: TnbBillControllerGetTnbReadingsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbReadings>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTnbBillControllerGetTnbReadingsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tnbBillControllerCreateTnbReading = (
  createTnbReadingDto: CreateTnbReadingDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/tnb-bill`, createTnbReadingDto, options);
};

export const getTnbBillControllerCreateTnbReadingMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerCreateTnbReading>>,
    TError,
    { data: CreateTnbReadingDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tnbBillControllerCreateTnbReading>>,
  TError,
  { data: CreateTnbReadingDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tnbBillControllerCreateTnbReading>>,
    { data: CreateTnbReadingDto }
  > = (props) => {
    const { data } = props ?? {};

    return tnbBillControllerCreateTnbReading(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TnbBillControllerCreateTnbReadingMutationResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerCreateTnbReading>>
>;
export type TnbBillControllerCreateTnbReadingMutationBody = CreateTnbReadingDto;
export type TnbBillControllerCreateTnbReadingMutationError =
  AxiosError<unknown>;

export const useTnbBillControllerCreateTnbReading = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerCreateTnbReading>>,
    TError,
    { data: CreateTnbReadingDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tnbBillControllerCreateTnbReading>>,
  TError,
  { data: CreateTnbReadingDto },
  TContext
> => {
  const mutationOptions =
    getTnbBillControllerCreateTnbReadingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const tnbBillControllerUpdateTnb = (
  tnbReadingId: number,
  updateTnbReadingDto: UpdateTnbReadingDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/tnb-bill/${tnbReadingId}`, updateTnbReadingDto, options);
};

export const getTnbBillControllerUpdateTnbMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerUpdateTnb>>,
    TError,
    { tnbReadingId: number; data: UpdateTnbReadingDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tnbBillControllerUpdateTnb>>,
  TError,
  { tnbReadingId: number; data: UpdateTnbReadingDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tnbBillControllerUpdateTnb>>,
    { tnbReadingId: number; data: UpdateTnbReadingDto }
  > = (props) => {
    const { tnbReadingId, data } = props ?? {};

    return tnbBillControllerUpdateTnb(tnbReadingId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TnbBillControllerUpdateTnbMutationResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerUpdateTnb>>
>;
export type TnbBillControllerUpdateTnbMutationBody = UpdateTnbReadingDto;
export type TnbBillControllerUpdateTnbMutationError = AxiosError<unknown>;

export const useTnbBillControllerUpdateTnb = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerUpdateTnb>>,
    TError,
    { tnbReadingId: number; data: UpdateTnbReadingDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tnbBillControllerUpdateTnb>>,
  TError,
  { tnbReadingId: number; data: UpdateTnbReadingDto },
  TContext
> => {
  const mutationOptions = getTnbBillControllerUpdateTnbMutationOptions(options);

  return useMutation(mutationOptions);
};
export const tnbBillControllerDeleteTnbReading = (
  tnbReadingId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/tnb-bill/${tnbReadingId}`, options);
};

export const getTnbBillControllerDeleteTnbReadingMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerDeleteTnbReading>>,
    TError,
    { tnbReadingId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tnbBillControllerDeleteTnbReading>>,
  TError,
  { tnbReadingId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tnbBillControllerDeleteTnbReading>>,
    { tnbReadingId: number }
  > = (props) => {
    const { tnbReadingId } = props ?? {};

    return tnbBillControllerDeleteTnbReading(tnbReadingId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TnbBillControllerDeleteTnbReadingMutationResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerDeleteTnbReading>>
>;

export type TnbBillControllerDeleteTnbReadingMutationError =
  AxiosError<unknown>;

export const useTnbBillControllerDeleteTnbReading = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerDeleteTnbReading>>,
    TError,
    { tnbReadingId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tnbBillControllerDeleteTnbReading>>,
  TError,
  { tnbReadingId: number },
  TContext
> => {
  const mutationOptions =
    getTnbBillControllerDeleteTnbReadingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const tnbBillControllerGetOneTnbReading = (
  tnbReadingId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneTnbReadingResponse>> => {
  return axios.get(`/tnb-bill/${tnbReadingId}`, options);
};

export const getTnbBillControllerGetOneTnbReadingQueryKey = (
  tnbReadingId: number
) => {
  return [`/tnb-bill/${tnbReadingId}`] as const;
};

export const getTnbBillControllerGetOneTnbReadingInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>
  >,
  TError = AxiosError<unknown>
>(
  tnbReadingId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTnbBillControllerGetOneTnbReadingQueryKey(tnbReadingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>
  > = ({ signal }) =>
    tnbBillControllerGetOneTnbReading(tnbReadingId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!tnbReadingId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TnbBillControllerGetOneTnbReadingInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>
>;
export type TnbBillControllerGetOneTnbReadingInfiniteQueryError =
  AxiosError<unknown>;

export const useTnbBillControllerGetOneTnbReadingInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>
  >,
  TError = AxiosError<unknown>
>(
  tnbReadingId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTnbBillControllerGetOneTnbReadingInfiniteQueryOptions(
    tnbReadingId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTnbBillControllerGetOneTnbReadingQueryOptions = <
  TData = Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
  TError = AxiosError<unknown>
>(
  tnbReadingId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTnbBillControllerGetOneTnbReadingQueryKey(tnbReadingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>
  > = ({ signal }) =>
    tnbBillControllerGetOneTnbReading(tnbReadingId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!tnbReadingId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TnbBillControllerGetOneTnbReadingQueryResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>
>;
export type TnbBillControllerGetOneTnbReadingQueryError = AxiosError<unknown>;

export const useTnbBillControllerGetOneTnbReading = <
  TData = Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
  TError = AxiosError<unknown>
>(
  tnbReadingId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetOneTnbReading>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTnbBillControllerGetOneTnbReadingQueryOptions(
    tnbReadingId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tnbBillControllerDeleteAttachment = (
  tnbReadingId: number,
  attachmentId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `/tnb-bill/${tnbReadingId}/attachment/${attachmentId}`,
    options
  );
};

export const getTnbBillControllerDeleteAttachmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerDeleteAttachment>>,
    TError,
    { tnbReadingId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tnbBillControllerDeleteAttachment>>,
  TError,
  { tnbReadingId: number; attachmentId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tnbBillControllerDeleteAttachment>>,
    { tnbReadingId: number; attachmentId: number }
  > = (props) => {
    const { tnbReadingId, attachmentId } = props ?? {};

    return tnbBillControllerDeleteAttachment(
      tnbReadingId,
      attachmentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type TnbBillControllerDeleteAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerDeleteAttachment>>
>;

export type TnbBillControllerDeleteAttachmentMutationError =
  AxiosError<unknown>;

export const useTnbBillControllerDeleteAttachment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tnbBillControllerDeleteAttachment>>,
    TError,
    { tnbReadingId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tnbBillControllerDeleteAttachment>>,
  TError,
  { tnbReadingId: number; attachmentId: number },
  TContext
> => {
  const mutationOptions =
    getTnbBillControllerDeleteAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const tnbBillControllerGetTnbAnalytic = (
  projectId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TnbAnalyticResponseDto>> => {
  return axios.get(`/tnb-bill/analytic/${projectId}`, options);
};

export const getTnbBillControllerGetTnbAnalyticQueryKey = (
  projectId: number
) => {
  return [`/tnb-bill/analytic/${projectId}`] as const;
};

export const getTnbBillControllerGetTnbAnalyticInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTnbBillControllerGetTnbAnalyticQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>
  > = ({ signal }) =>
    tnbBillControllerGetTnbAnalytic(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TnbBillControllerGetTnbAnalyticInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>
>;
export type TnbBillControllerGetTnbAnalyticInfiniteQueryError =
  AxiosError<unknown>;

export const useTnbBillControllerGetTnbAnalyticInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTnbBillControllerGetTnbAnalyticInfiniteQueryOptions(
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTnbBillControllerGetTnbAnalyticQueryOptions = <
  TData = Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
  TError = AxiosError<unknown>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTnbBillControllerGetTnbAnalyticQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>
  > = ({ signal }) =>
    tnbBillControllerGetTnbAnalytic(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TnbBillControllerGetTnbAnalyticQueryResult = NonNullable<
  Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>
>;
export type TnbBillControllerGetTnbAnalyticQueryError = AxiosError<unknown>;

export const useTnbBillControllerGetTnbAnalytic = <
  TData = Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
  TError = AxiosError<unknown>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tnbBillControllerGetTnbAnalytic>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTnbBillControllerGetTnbAnalyticQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
