import { Pencil } from "lucide-react";
import AppButton from "../../../../../../../../../../components/AppButton";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../../../../../../../components/ui/dialog";
import { useEffect, useState } from "react";
import { z } from "zod";
import { ProjectSubscriptionType } from "../../../../../../../../../../api/model";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AppSelectWithDialog from "../../../../../../../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { Checkbox } from "../../../../../../../../../../components/ui/checkbox";
import {
  useProjectControllerFindOneProject,
  useProjectControllerUpdateSubscriptionType,
} from "../../../../../../../../../../api/projects/projects";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const EditPlanSchema = z.object({
  subscriptionType: z.object({
    id: z.string(),
    name: z.enum([
      ProjectSubscriptionType.STARTER,
      ProjectSubscriptionType.STANDARD,
      ProjectSubscriptionType.ENTERPRISE,
    ]),
  }),
});

export type EditPlanForm = z.infer<typeof EditPlanSchema>;

export default function EditPlanSummaryBtnDialog() {
  const qc = useQueryClient();
  const [open, setOpen] = useState(false);
  const { projectId } = useParams();
  const methods = useForm<EditPlanForm>({
    resolver: zodResolver(EditPlanSchema),
    defaultValues: {
      subscriptionType: {
        id: ProjectSubscriptionType.STANDARD,
        name: ProjectSubscriptionType.STANDARD,
      },
    },
  });

  const { data: project } = useProjectControllerFindOneProject(
    projectId as string,
    {
      query: {
        select: (res) => res.data.data,
      },
    }
  );

  const { mutate, isPending } = useProjectControllerUpdateSubscriptionType({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("project"),
        });
        setOpen(false);
      },
    },
  });

  useEffect(() => {
    if (project)
      methods.setValue("subscriptionType", {
        id: project.subscriptionType,
        name: project.subscriptionType,
      });
  }, [project]);

  const onSubmit: SubmitHandler<EditPlanForm> = (data) => {
    if (!projectId) return;
    mutate({
      projectId,
      data: {
        subscriptionType: data.subscriptionType.name,
      },
    });
  };

  return (
    <>
      <AppButton
        icon={<Pencil />}
        onClick={() => setOpen(true)}
        variant="outline"
      />
      <Dialog
        open={open}
        onOpenChange={(o) => {
          setOpen(o);
        }}
      >
        <DialogContent>
          <DialogTitle>Project Plan</DialogTitle>
          <FormProvider {...methods}>
            <AppSelectWithDialog
              placeholder="Select Subscription Type"
              defaultValue={ProjectSubscriptionType.STANDARD}
              control={methods.control}
              columns={[
                {
                  header: "",
                  id: "select",
                  cell: ({ row, table }) => (
                    <Checkbox
                      checked={row.getIsSelected()}
                      onCheckedChange={(value) => {
                        row.toggleSelected(!!value);
                      }}
                      aria-label="Select row"
                    />
                  ),
                  enableSorting: false,
                  enableHiding: false,
                },
                {
                  header: "Subscription Type",
                  accessorKey: "name",
                },
              ]}
              label="Subscription Type"
              name="subscriptionType"
              items={[
                {
                  id: ProjectSubscriptionType.STARTER,
                  name: ProjectSubscriptionType.STARTER,
                },
                {
                  id: ProjectSubscriptionType.STANDARD,
                  name: ProjectSubscriptionType.STANDARD,
                },
                {
                  id: ProjectSubscriptionType.ENTERPRISE,
                  name: ProjectSubscriptionType.ENTERPRISE,
                },
              ]}
              dialogTitle="Select Subscription Type"
              error={!!methods.formState.errors.subscriptionType?.message}
              helperText={methods.formState.errors.subscriptionType?.message}
              onResultRender={(item, idx) => (
                <div className="flex flex-col">
                  <div className="font-medium">{item?.name}</div>
                </div>
              )}
              onOptionsRender={(item, idx) => (
                <div className="flex flex-col">
                  <div className="font-medium">{item?.name}</div>
                </div>
              )}
            />
          </FormProvider>
          <DialogFooter>
            <AppButton
              label="Confirm"
              isLoading={isPending}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
