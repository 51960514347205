import { useParams } from "react-router-dom";
import {
  useProjectControllerFindAllUsers,
  useProjectControllerFindOneProject,
} from "../../../../../../../../../api/projects/projects";
import CardSummary from "../../../../components/CardSummary";
import AppButton from "../../../../../../../../../components/AppButton";
import { Pencil } from "lucide-react";
import EditUserQuotaBtnDialog from "./components/EditUserQuotaBtnDialog";

export default function UserQuotaSummaryCard() {
  const { projectId } = useParams();

  const { data: project } = useProjectControllerFindOneProject(
    projectId as string,
    {
      query: {
        select: (res) => res.data.data,
      },
    }
  );

  const { data: userCount } = useProjectControllerFindAllUsers(
    projectId as string,
    {},
    {
      query: {
        select: (res) => res.data.totalCount,
      },
    }
  );

  return (
    <CardSummary
      title="User Quota"
      content={`${userCount ?? "-"} / ${project?.userQuota ?? "-"}`}
      action={<EditUserQuotaBtnDialog />}
    />
  );
}
