import {useSearchParams} from "react-router-dom";
import {useLocationControllerGetOneLocationQuery} from "../../redux/slices/OpenApi/cerevApi";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import {Typography} from "@mui/material";
import AppItemInfo from "../AppItemInfo";
import {
  Code,
  Delete,
  Description,
  DocumentScanner,
  Edit,
  Photo,
  Place,
} from "@mui/icons-material";
import AppImage from "../AppImage";
import AppDocument from "../AppDocument";
import AppButton from "../AppButton";
import {DRAWER_UPDATE_STATE, LOCATION_FORM_DRAWER} from "./AppDrawer";

export default function LocationDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = searchParams.get("locationId");

  const {data: location, isLoading} = useLocationControllerGetOneLocationQuery(
    {
      locationId: locationId ?? "",
    },
    {
      skip: !locationId,
    }
  );

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-1">
          <p className="font-sans text-2xl font-bold">
            {location ? location.name : "-"}
          </p>
          <p className="font-sans text-neutral-600">
            Location
          </p>
        </div>
        <AppButton
          variant="outline"
          icon={<Edit />}
          label="Edit"
          onClick={() => {
            if (!location) return;
            setSearchParams((p) => {
              p.set("drawerType", LOCATION_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("locationId", location.id.toString());
              return p;
            });
          }}
        />
      </div>

      <AppItemInfo
        label="Name"
        icon={<Place className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {location?.name ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Description"
        icon={<Description className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {location?.description ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Location Code"
        icon={<Code className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {location?.code ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Photos"
        icon={<Photo className="text-primary-900" />}
        content={
          <div className="flex flex-col gap-4">
            {location?.photos?.length ? (
              location?.photos.map((p) => <AppImage key={p.id} att={p} />)
            ) : (
              <p className="font-sans text-neutral-900">-</p>
            )}
          </div>
        }
      />
      <AppItemInfo
        label="Documents"
        icon={<DocumentScanner className="text-primary-900" />}
        content={
          <div className="flex flex-col gap-4">
            {location?.additionalDocuments?.length ? (
              location?.additionalDocuments.map((p) => (
                <AppDocument key={p.id} att={p} />
              ))
            ) : (
              <p className="font-sans text-neutral-900">-</p>
            )}
          </div>
        }
      />
      <div className="flex">
        <AppButton
          variant="outline"
          color="warning"
          onClick={() => {}}
          icon={<Delete />}
          label="Delete"
        />
      </div>
    </div>
  );
}
