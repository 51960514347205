import { useSearchParams } from "react-router-dom";
import { useStockTypesControllerGetStockTypes } from "../../../api/stock-inventory-types/stock-inventory-types";
import AppButton from "../../../components/AppButton";
import AppUncontrolledWithDialog from "../../../components/dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { useAppSelector } from "../../../redux/store";
import { useInventoryFilterStore } from "../../../stores/inventoryFilterStore/inventoryFilterStore";
import { selectInventoryTypeColumn } from "./columns/selectInventoryTypeColumn";


export default function InventoryFilterDrawer() {
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const inventoryTypeFilter = useInventoryFilterStore(
    (state) => state.inventoryType
  );
  const setInventoryType = useInventoryFilterStore(
    (state) => state.setInventoryType
  );

  const clearAll = useInventoryFilterStore((state) => state.cleanUp);

  // const {data: inventoryTypes} = useStockControllergetinven

  const { data: inventoryTypes } = useStockTypesControllerGetStockTypes({
    projectId: activeProj?.id?.toString() ?? "",
  });

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-sans text-2xl font-bold">Filter By</h2>
      <AppUncontrolledWithDialog
        placeholder="Select Inventory Type"
        label="Inventory Type"
        items={inventoryTypes?.data ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Inventory Type"
        columns={selectInventoryTypeColumn}
        value={inventoryTypeFilter}
        onChange={(value) => {
          if (!Array.isArray(value)) setInventoryType(value as any);
        }}
      />
      <div className="flex gap-2">
        <AppButton
          label="Confirm"
          onClick={() => {
            setSearchParams(() => {
              return new URLSearchParams();
            });
          }}
        />
        <AppButton
          label="Clear"
          variant="outline"
          onClick={() => {
            clearAll();
          }}
        />
      </div>
    </div>
  );
}
