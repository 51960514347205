import { DefectStatus } from "../../api/model";
import { Badge } from "../ui/badge";

interface AppStatusBadgeProps {
  defectStatus: DefectStatus;
}

export default function AppStatusBadge({ defectStatus }: AppStatusBadgeProps) {
  if(!defectStatus) return <></>
  return (
    <Badge style={{ backgroundColor: `#${defectStatus?.colorHex}` }}>
      {defectStatus?.name}
    </Badge>
  );
}
