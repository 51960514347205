import { ColumnDef, Row } from "@tanstack/react-table";
import {
  Asset,
  PmTemplateResponseDto,
  PmTemplateWithAsset,
} from "../../../api/model";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  PM_TEMPLATE_DRAWER,
} from "../../../components/drawers/AppDrawer";

const PmTemplateNameNav = ({
  pmTemplate,
}: {
  pmTemplate: Row<PmTemplateWithAsset>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", PM_TEMPLATE_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("pmTemplateId", pmTemplate.getValue("id"));
          return p;
        });
      }}
    >
      {pmTemplate.getValue("name") ?? "-"}
    </div>
  );
};

export const pmSettingColumns: ColumnDef<PmTemplateWithAsset>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <PmTemplateNameNav pmTemplate={row} />,
  },
  {
    accessorKey: "asset",
    header: "Asset",
    cell: ({ row }) => (
      <div>
        {(row.getValue("asset") as Array<Asset>)
          ?.map((ast) => ast.name)
          .join(", ")}
      </div>
    ),
  },
];
