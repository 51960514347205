import { useSearchParams } from "react-router-dom";
import AppItemInfo from "../../AppItemInfo";
import {
  AlignLeft,
  Calendar,
  ChevronRight,
  Hash,
  ImageOff,
  Layers,
  MapPin,
  Pin,
  UserCircle,
} from "lucide-react";
import { useStocksControllerGetOneStockWithLedger } from "../../../api/stocks-inventory/stocks-inventory";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import AppImage from "../../AppImage";
import { WORK_ORDER_DETAIL_DRAWER } from "../AppDrawer";
import { CreateStockDtoInventoryMode } from "../../../api/model";
import BalanceStatusBadge from "./components/BalanceInfo/components/BalanceStatusBadge";

export default function InventoryLedgerDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();

  const inventoryId = searchParams.get("inventoryId");
  const ledgerId = searchParams.get("ledgerId");

  const { data: inventory, isLoading: inventoryIsLoading } =
    useStocksControllerGetOneStockWithLedger(inventoryId as string, {
      query: {
        enabled: !!inventoryId && !!ledgerId,
        select: (res) => {
          if (!ledgerId) return {};
          return {
            ledger: res.data?.ledgers.find((l) => l.id === +ledgerId),
            stock: res.data,
          };
        },
      },
    });

  if (inventoryIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <p className="font-sans text-2xl font-bold">Inventory Log Detail</p>
      <AppItemInfo
        label="Created By"
        content={<p>{inventory?.ledger?.createdBy?.name ?? "-"}</p>}
        icon={<UserCircle />}
      />
      <AppItemInfo
        label="Date"
        content={<p>{inventory?.ledger?.date ?? "-"}</p>}
        icon={<Calendar />}
      />
      <AppItemInfo
        label="Location"
        content={<p>{inventory?.ledger?.location?.name ?? "-"}</p>}
        icon={<MapPin />}
      />
      <AppItemInfo
        label="Description"
        content={<p>{inventory?.ledger?.description ?? "-"}</p>}
        icon={<AlignLeft />}
      />
      <AppItemInfo
        label="Inventory Status"
        content={
          <div className="max-w-24 mt-2">
            <BalanceStatusBadge
              balance={inventory?.ledger?.balance}
              warningBalance={inventory?.ledger?.warningBalance}
            />
          </div>
        }
        icon={<Layers />}
      />
      <h3 className="font-sans text-lg font-bold">Inventory Movement</h3>
      <div className="flex justify-center items-center p-4 bg-slate-100 rounded-xl">
        <div className="flex flex-col gap-1">
          <p className="text-primary-900 text-3xl font-bold text-right">
            {inventory?.ledger?.adjustmentQty ?? "-"}
          </p>
          <p className="text-sm">Adjustment</p>
        </div>
        <div className="bg-slate-200 mx-6 h-[50px] w-[2px]"></div>
        <div className="flex flex-col gap-1">
          <p className="text-primary-900 text-3xl font-bold">
            {inventory?.ledger?.balance ?? "-"}
          </p>
          <p className="text-sm">Nos. balance</p>
        </div>
      </div>
      {inventory?.stock?.inventoryMode ===
        CreateStockDtoInventoryMode.SERIALIZED && (
        <div>
          <h3 className="font-sans text-lg font-bold">Serial Numbers</h3>
          <div className="flex flex-col gap-2">
            {inventory?.ledger?.serials?.map((sn, i) => (
              <div
                key={i}
                className="rounded-md bg-slate-100 p-4 flex items-center gap-4"
              >
                <Hash className="text-primary-900" />
                <p>{sn.serial}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {inventory?.ledger?.defect && (
        <div className="flex flex-col gap-6 mt-4">
          <h3 className="text-lg font-bold">Used In</h3>
          <div
            className="rounded-md bg-slate-100 flex items-center pr-4 hover:cursor-pointer"
            onClick={() => {
              setSearchParams((p) => {
                p.delete("inventoryId");
                p.delete("ledgerId");
                p.set(
                  "workOrderId",
                  inventory?.ledger?.defect?.id?.toString() ?? ""
                );
                p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
                return p;
              });
            }}
          >
            {inventory?.ledger?.defect?.photos?.length > 0 ? (
              <AppImage
                att={inventory?.ledger?.defect?.photos[0]}
                className="w-24 h-24 rounded-md"
              />
            ) : (
              <div className="bg-primary-100 rounded-md p-4 flex items-center justify-center h-20 w-20">
                <ImageOff className="text-primary-900" />
              </div>
            )}
            <div className="p-4 flex-1">
              <p className="font-sans text-lg font-bold">
                {inventory?.ledger?.defect?.subject ?? "-"}
              </p>
              <p className="font-sans text-sm">
                {inventory?.ledger?.defect?.description ?? "-"}
              </p>
            </div>
            <ChevronRight className="text-primary-900" />
          </div>
        </div>
      )}
    </div>
  );
}
