import { InboxIcon, Upload } from "lucide-react";
import AppButton from "../../../components/AppButton";
import React, { useRef } from "react";
import useAppStorage from "../../../hooks/useAppStorage";
import { useQueryClient } from "@tanstack/react-query";
import { useBimControllerCreateBimModel } from "../../../api/bim/bim";
import BimUploadDialogBtn from "./BimUploadDialogBtn";

export default function NoBimDetected() {
  return (
    <div className="flex flex-col justify-center items-center flex-grow gap-4">
      <InboxIcon size={64} className="text-neutral-500" />
      <div className="text-3xl font-bold text-neutral-500">
        No BIM Model Detected
      </div>
      <div className="text-neutral-500 text-center">
        Please upload your BIM model to start using 3D Building feature.
      </div>
      <BimUploadDialogBtn />
    </div>
  );
}
