import React from "react";
import {
  useInventoryLocationEnabled,
  useManHoursEnabled,
} from "../hooks/useFeatures";

interface InventoryLocationGuardProps {
  children: React.ReactNode;
}

export default function InventoryLocationGuard({
  children,
}: InventoryLocationGuardProps) {
  const hasAccess = useInventoryLocationEnabled();
  if (!hasAccess) return <></>;

  return <>{children}</>;
}
