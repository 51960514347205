import * as React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import { Divider } from "./Divider";
import { useJKRWorkOrder } from "..";

export function RepairRectificationReplacePictureSection() {
  const jkr = useJKRWorkOrder();

  return (
    <View style={{ gap: 1 }}>
      <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
        Repair/Rectification/Replace Pictures
      </Text>
      <Divider />

      {jkr.photos?.map((j) => (
        <Image
          key={j.url}
          src={j.url}
          style={{
            height: 125,
            width: 125,
          }}
        />
      ))}
      {/* <View
        style={{
          border: 1,
          borderColor: "#94a3b8",
          paddingHorizontal: 4,
          paddingVertical: 8,
        }}
      >
        <Text style={{fontSize: 9, fontWeight: "bold",color: "#336699" }}>BEFORE</Text>
        <Divider />
      </View>
      <View
        style={{
          border: 1,
          borderColor: "#94a3b8",
          paddingHorizontal: 4,
          paddingVertical: 8,
        }}
      >
        <Text style={{fontSize: 9, fontWeight: "bold",color: "#336699" }}>DURING</Text>
        <Divider />
      </View>
      <View
        style={{
          border: 1,
          borderColor: "#94a3b8",
          paddingHorizontal: 4,
          paddingVertical: 8,
        }}
      >
        <Text style={{fontSize: 9, fontWeight: "bold",color: "#336699" }}>AFTER</Text>
        <Divider />
      </View> */}
    </View>
  );
}
