import { useEffect, useState } from "react";
import { useAuthControllerGetCurrentUser } from "../../api/auth/auth";
import {
  useNotificationControllerGetNotifications,
  useNotificationControllerGetNotificationsInfinite,
  useNotificationControllerReadAll,
} from "../../api/notification/notification";
import useCurrentUser from "../../hooks/useCurrentUser/useCurrentUser";
import { useAppSelector } from "../../redux/store";
import DrawerFormSkeleton from "../../components/skeletons/DrawerFormSkeleton";
import AppButton from "../../components/AppButton";
import AppNotificationItem from "./components/AppNotificationItem";
import { useQueryClient } from "@tanstack/react-query";
import { NotificationControllerGetNotificationsFilter } from "../../api/model";
import { Filter } from "lucide-react";
import AppCenterEmpty from "../../components/AppCenterEmpty";

export default function NotificationDrawer() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: userData } = useCurrentUser();
  const qc = useQueryClient();
  const [notificationFilter, setNotificationFilter] =
    useState<NotificationControllerGetNotificationsFilter>(
      NotificationControllerGetNotificationsFilter.All
    );

  const {
    fetchNextPage,
    data,
    isLoading,
    refetch,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
  } = useNotificationControllerGetNotificationsInfinite(
    {
      userId: userData?.id?.toString() ?? "",
      filter: notificationFilter,
    },
    {
      query: {
        enabled: !!userData,
        getNextPageParam: (lastPage) =>
          lastPage.data?.[lastPage.data.length - 1]?.id?.toString(),
      },
    }
  );

  const { mutate, isPending } = useNotificationControllerReadAll({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("notification"),
        });
      },
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 items-center justify-between mr-4">
        <h1 className="font-sans text-2xl font-bold">Notifications</h1>
        <div className="flex gap-2">
          <AppButton
            variant="outline"
            label="Read all"
            isLoading={isPending}
            onClick={() => {
              mutate();
            }}
          />
          <AppButton
            variant={
              notificationFilter ===
              NotificationControllerGetNotificationsFilter.All
                ? "outline"
                : undefined
            }
            label="Unread Only"
            onClick={() => {
              setNotificationFilter((prev) => {
                if (prev === NotificationControllerGetNotificationsFilter.All) {
                  return NotificationControllerGetNotificationsFilter.Unread;
                } else {
                  return NotificationControllerGetNotificationsFilter.All;
                }
              });
            }}
          />
        </div>
      </div>
      {isLoading ? (
        <DrawerFormSkeleton />
      ) : (
        <>
          <div className="flex flex-col">
            {data?.pages?.flatMap((e) => e.data).length === 0 && (
              <AppCenterEmpty />
            )}
            {data?.pages
              ?.flatMap((e) => e.data)
              ?.map((e) => (
                <AppNotificationItem notification={e} key={e.id} />
              ))}
          </div>
          {hasNextPage && (
            <AppButton
              variant="ghost"
              label="Load More..."
              isLoading={isFetchingNextPage}
              onClick={() => {
                fetchNextPage();
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
