import AppProjectSelect from "../../../../components/AppProjectSelect";
import { Card, CardContent } from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import ShowLocationModeSwitch from "./components/ShowLocationModeSwitch";
import WorkRequestSettingCheckboxItem from "./components/WorkRequestSettingCheckboxItem";
import WorkRequestSettingVisibilityCheckbox from "./components/WorkRequestSettingVisibilityCheckbox";

export default function WorkRequestSettingTab() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <AppTitle title="Work Request Settings" className="mb-0" />
      </div>
      <p className="text-sm text-slate-600">
        Adjust here to determine whether your users will need to fill the
        following information before submitting a work request
      </p>
      <div className="flex gap-4 items-center my-1">
        <p>Project :</p>
        <AppProjectSelect />
      </div>
      <Table className="max-w-[60%]">
        <TableHeader>
          <TableRow>
            <TableHead>Fields</TableHead>
            <TableHead>Is Required?</TableHead>
            <TableHead>Is Visible?</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <div className="space-y-1">
                <p>Email</p>
                <p className="text-xs text-slate-500">
                  This field is necessary to allow the app to work properly
                </p>
              </div>
            </TableCell>
            <TableCell>
              <WorkRequestSettingCheckboxItem keyValue="emailRequired" />
            </TableCell>
            <TableCell>
              <WorkRequestSettingVisibilityCheckbox keyValue="emailVisibility" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <div className="space-y-1">
                <p>Subject</p>
                <p className="text-xs text-slate-500">
                  This field is necessary to allow the app to work properly
                </p>
              </div>
            </TableCell>
            <TableCell>
              <WorkRequestSettingCheckboxItem keyValue="subjectRequired" />
            </TableCell>
            <TableCell>
              <WorkRequestSettingVisibilityCheckbox keyValue="subjectVisibility" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>
              <WorkRequestSettingCheckboxItem keyValue="descriptionRequired" />
            </TableCell>
            <TableCell>
              <WorkRequestSettingVisibilityCheckbox keyValue="descriptionVisibility" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Contact No</TableCell>
            <TableCell>
              <WorkRequestSettingCheckboxItem keyValue="contactNoRequired" />
            </TableCell>
            <TableCell>
              <WorkRequestSettingVisibilityCheckbox keyValue="contactNoVisibility" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell>
              <WorkRequestSettingCheckboxItem keyValue="locationRequired" />
            </TableCell>
            <TableCell>
              <WorkRequestSettingVisibilityCheckbox keyValue="locationVisibility" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Location Detail</TableCell>
            <TableCell>
              <WorkRequestSettingCheckboxItem keyValue="locationDetailRequired" />
            </TableCell>
            <TableCell>
              <WorkRequestSettingVisibilityCheckbox keyValue="locationDetailVisibility" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Photo</TableCell>
            <TableCell>
              <WorkRequestSettingCheckboxItem keyValue="photoRequired" />
            </TableCell>
            <TableCell>
              <WorkRequestSettingVisibilityCheckbox keyValue="photoVisibility" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Card className="mt-2 max-w-3xl w-full">
        <CardContent className="p-4 flex gap-4 items-center justify-between">
          <div>
            <p className="text-lg font-medium">Show Top Level Location Only</p>
            <p className="text-sm leading-relaxed text-slate-500">
              When raising work request from qr code, only allow user to select
              the top level location only
            </p>
          </div>
          <ShowLocationModeSwitch />
        </CardContent>
      </Card>
    </div>
  );
}
