/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateProjectDefectStatusDto,
  DefectStatus,
  DefectStatusControllerGetAllWorkOrderStatusParams,
  DefectStatusControllerGetDefectStatusParams,
} from ".././model";

export const defectStatusControllerGetDefectStatus = (
  params: DefectStatusControllerGetDefectStatusParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectStatus[]>> => {
  return axios.get(`/defect-status`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDefectStatusControllerGetDefectStatusQueryKey = (
  params: DefectStatusControllerGetDefectStatusParams
) => {
  return [`/defect-status`, ...(params ? [params] : [])] as const;
};

export const getDefectStatusControllerGetDefectStatusInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
    DefectStatusControllerGetDefectStatusParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: DefectStatusControllerGetDefectStatusParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
        QueryKey,
        DefectStatusControllerGetDefectStatusParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectStatusControllerGetDefectStatusQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
    QueryKey,
    DefectStatusControllerGetDefectStatusParams["cursorId"]
  > = ({ signal, pageParam }) =>
    defectStatusControllerGetDefectStatus(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
    TError,
    TData,
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
    QueryKey,
    DefectStatusControllerGetDefectStatusParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type DefectStatusControllerGetDefectStatusInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>
  >;
export type DefectStatusControllerGetDefectStatusInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectStatusControllerGetDefectStatusInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
    DefectStatusControllerGetDefectStatusParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: DefectStatusControllerGetDefectStatusParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
        QueryKey,
        DefectStatusControllerGetDefectStatusParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectStatusControllerGetDefectStatusInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectStatusControllerGetDefectStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
  TError = AxiosError<unknown>
>(
  params: DefectStatusControllerGetDefectStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectStatusControllerGetDefectStatusQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>
  > = ({ signal }) =>
    defectStatusControllerGetDefectStatus(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectStatusControllerGetDefectStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>
>;
export type DefectStatusControllerGetDefectStatusQueryError =
  AxiosError<unknown>;

export const useDefectStatusControllerGetDefectStatus = <
  TData = Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
  TError = AxiosError<unknown>
>(
  params: DefectStatusControllerGetDefectStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectStatusControllerGetDefectStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectStatusControllerGetDefectStatusQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectStatusControllerCreateProjectBasedDefectStatus = (
  createProjectDefectStatusDto: CreateProjectDefectStatusDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/defect-status`, createProjectDefectStatusDto, options);
};

export const getDefectStatusControllerCreateProjectBasedDefectStatusMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof defectStatusControllerCreateProjectBasedDefectStatus>
      >,
      TError,
      { data: CreateProjectDefectStatusDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof defectStatusControllerCreateProjectBasedDefectStatus>
    >,
    TError,
    { data: CreateProjectDefectStatusDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof defectStatusControllerCreateProjectBasedDefectStatus>
      >,
      { data: CreateProjectDefectStatusDto }
    > = (props) => {
      const { data } = props ?? {};

      return defectStatusControllerCreateProjectBasedDefectStatus(
        data,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DefectStatusControllerCreateProjectBasedDefectStatusMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof defectStatusControllerCreateProjectBasedDefectStatus>
    >
  >;
export type DefectStatusControllerCreateProjectBasedDefectStatusMutationBody =
  CreateProjectDefectStatusDto;
export type DefectStatusControllerCreateProjectBasedDefectStatusMutationError =
  AxiosError<unknown>;

export const useDefectStatusControllerCreateProjectBasedDefectStatus = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof defectStatusControllerCreateProjectBasedDefectStatus>
    >,
    TError,
    { data: CreateProjectDefectStatusDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof defectStatusControllerCreateProjectBasedDefectStatus>
  >,
  TError,
  { data: CreateProjectDefectStatusDto },
  TContext
> => {
  const mutationOptions =
    getDefectStatusControllerCreateProjectBasedDefectStatusMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const defectStatusControllerGetAllWorkOrderStatus = (
  params: DefectStatusControllerGetAllWorkOrderStatusParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectStatus[]>> => {
  return axios.get(`/defect-status/all`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDefectStatusControllerGetAllWorkOrderStatusQueryKey = (
  params: DefectStatusControllerGetAllWorkOrderStatusParams
) => {
  return [`/defect-status/all`, ...(params ? [params] : [])] as const;
};

export const getDefectStatusControllerGetAllWorkOrderStatusInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
      DefectStatusControllerGetAllWorkOrderStatusParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: DefectStatusControllerGetAllWorkOrderStatusParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>
          >,
          QueryKey,
          DefectStatusControllerGetAllWorkOrderStatusParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDefectStatusControllerGetAllWorkOrderStatusQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
      QueryKey,
      DefectStatusControllerGetAllWorkOrderStatusParams["cursorId"]
    > = ({ signal, pageParam }) =>
      defectStatusControllerGetAllWorkOrderStatus(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
      TError,
      TData,
      Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
      QueryKey,
      DefectStatusControllerGetAllWorkOrderStatusParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type DefectStatusControllerGetAllWorkOrderStatusInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>
  >;
export type DefectStatusControllerGetAllWorkOrderStatusInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectStatusControllerGetAllWorkOrderStatusInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
    DefectStatusControllerGetAllWorkOrderStatusParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: DefectStatusControllerGetAllWorkOrderStatusParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
        QueryKey,
        DefectStatusControllerGetAllWorkOrderStatusParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectStatusControllerGetAllWorkOrderStatusInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectStatusControllerGetAllWorkOrderStatusQueryOptions = <
  TData = Awaited<
    ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>
  >,
  TError = AxiosError<unknown>
>(
  params: DefectStatusControllerGetAllWorkOrderStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectStatusControllerGetAllWorkOrderStatusQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>
  > = ({ signal }) =>
    defectStatusControllerGetAllWorkOrderStatus(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectStatusControllerGetAllWorkOrderStatusQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>
  >;
export type DefectStatusControllerGetAllWorkOrderStatusQueryError =
  AxiosError<unknown>;

export const useDefectStatusControllerGetAllWorkOrderStatus = <
  TData = Awaited<
    ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>
  >,
  TError = AxiosError<unknown>
>(
  params: DefectStatusControllerGetAllWorkOrderStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectStatusControllerGetAllWorkOrderStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectStatusControllerGetAllWorkOrderStatusQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
