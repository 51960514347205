import { useQueryClient } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import {
  AccessPermission,
  CerevModule,
  ModuleAccess,
} from "../../../../api/model";
import { useModuleAccessControllerUpdateModuleAccess } from "../../../../api/module-access/module-access";
import { Card, CardDescription, CardHeader, CardTitle } from "../../../ui/card";
import { Switch } from "../../../ui/switch";
import { useSearchParams } from "react-router-dom";
import { useUserControllerGetOneUser } from "../../../../api/users/users";
import { useSelectedSettingProjectId } from "../../../../stores/settingStore/settingStore";

const moduleNameMapper = {
  [CerevModule.WORK_ORDER]: "Work Order",
  [CerevModule.PM]: "PM",
  [CerevModule.EQUIPMENT]: "Equipment",
  [CerevModule.VENDOR]: "Vendor",
  [CerevModule.INVENTORY]: "Inventory",
  [CerevModule.LEGAL]: "Legal",
  [CerevModule.WORK_PERMIT]: "Work Permit",
  [CerevModule.WORK_REQUEST]: "Work Request",
  [CerevModule.DASHBOARD]: "Dashboard",
  [CerevModule.TNB_BILLS]: "TNB Bills",
  [CerevModule.METERS]: "Meters",
  [CerevModule.ESCALATION]: "Escalation",
};

interface ModuleAccessItemProps {
  mod: ModuleAccess;
  description: string;
}

export default function ModuleAccessItem({
  mod,
  description,
}: ModuleAccessItemProps) {
  const qc = useQueryClient();
  const [searchParams] = useSearchParams();
  const selectedProjId = useSelectedSettingProjectId();

  const firebaseId = searchParams.get("firebaseId");

  const { mutate, isPending } = useModuleAccessControllerUpdateModuleAccess({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("module-access"),
        });
      },
    },
  });
  const { data: user } = useUserControllerGetOneUser(firebaseId ?? "", {
    query: {
      enabled: !!firebaseId,
      select: (res) => res.data,
    },
  });

  const onCheckChange = (checked: boolean) => {
    if (!user?.id) return;
    mutate({
      userId: user.id.toString(),
      data: {
        projectId: Number(selectedProjId),
        module: mod.module,
        access: checked
          ? AccessPermission.ALLOWED
          : AccessPermission.NOT_ALLOWED,
      },
    });
  };

  return (
    <Card>
      <div className="flex items-center mr-6">
        <CardHeader className="flex-1 mr-4">
          <CardTitle className="text-lg">
            {moduleNameMapper[mod.module] + " Module"}
          </CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        {isPending && (
          <Loader2 className="w-6 h-6 animate-spin text-primary-900 mr-2" />
        )}
        <Switch
          className="data-[state=checked]:bg-primary-900"
          checked={mod.access === AccessPermission.ALLOWED}
          onCheckedChange={onCheckChange}
        />
      </div>
    </Card>
  );
}
