import { Card, CardContent } from "../../../../ui/card";

interface ManHourCardSummaryProps {
  label: string;
  value: string;
  icon: React.ReactNode;
}

export default function ManHourCardSummary({
  label,
  value,
  icon,
}: ManHourCardSummaryProps) {
  return (
    <Card>
      <CardContent className="flex items-center gap-4 py-4">
        {icon}
        <div className="flex flex-col justify-center">
          <p className="text-xl font-bold text-primary-900">{value}</p>
          <p className="text-sm text-slate-500">{label}</p>
        </div>
      </CardContent>
    </Card>
  );
}
