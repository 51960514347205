import { usePrivateAttachmentEnabled } from "../hooks/useFeatures";
import { useHasViewPrivateAttachmentPolicy } from "../hooks/usePolicies/usePolicies";

interface PrivateAttachmentPolicyGuardProps {
  children: React.ReactNode;
}

export default function PrivateAttachmentGuard({
  children,
}: PrivateAttachmentPolicyGuardProps) {
  const isEnabled = usePrivateAttachmentEnabled();
  const hasAccess = useHasViewPrivateAttachmentPolicy();

  if (!isEnabled || !hasAccess) return <></>;

  return <>{children}</>;
}
