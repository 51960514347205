import { useExtraFieldPricingEnabled } from "../hooks/useFeatures";

interface ExtraFieldPricingGuardProps {
  children: React.ReactNode;
}

export default function ExtraFieldPricingGuard({
  children,
}: ExtraFieldPricingGuardProps) {
  const hasAccess = useExtraFieldPricingEnabled();

  if (!hasAccess) return <></>;

  return <>{children}</>;
}
