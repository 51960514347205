import { UserData } from './model/UserData';
import { RootApi } from './../RootApi';
import { AppResponse } from '../../model/AppResponse';

const AuthApiWithTag = RootApi.enhanceEndpoints({
    addTagTypes: ["Auth"]
});

export const AuthApi = AuthApiWithTag.injectEndpoints({
    endpoints: builder => ({
        getUserData: builder.query<UserData, void>({
            query: () => ({
                url: '/auth/user',
                method: 'GET',
            }),
            providesTags: ["Auth"]
        })
    })
})

export const {
    useGetUserDataQuery
} = AuthApi;