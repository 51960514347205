import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Divider } from "./Divider";
import { ItemSection } from "./ItemSection";
import { useJKRWorkOrder } from "..";

export function RequestorParticularsSection() {

  return (
    <>
      <View style={{ gap: 2 }}>
        <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
          A. Requester Particulars
        </Text>
        <Divider />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection label="Name" value="IBNU ARABI BIN MARIO" />
          <ItemSection label="Contact" value="017-481 8514" />
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection label="Agency/Dept" value="TERAS CITY SDN BHD" />
          <ItemSection label="Email" value="ukishagari91@gmail.com" />
        </View>
      </View>
    </>
  );
}
