import { LocationCsvObject } from "../redux/slices/OpenApi/cerevApi";
import { useEffect, useState } from "react";

// Please cross check with test code as per the following link
//LINK - src/__test__/ImportLocation.test.tsx
export function findDuplicateCodes(locations: LocationCsvObject[]) {
  const codeCount: Record<string, number> = {};
  const errors: { code: string; message: string }[] = [];
  const errorSet: Set<string> = new Set();

  locations.forEach((location) => {
    const { Code } = location;
    if (Code !== undefined && Code !== "") {
      codeCount[Code] = (codeCount[Code] || 0) + 1;

      if (codeCount[Code] > 1 && !errorSet.has(Code)) {
        errorSet.add(Code);

        errors.push({
          code: "DUPLICATE_CODE",
          message: `Duplicate Code found: ${Code}`,
        });
      }
    }
  });

  return errors;
}

export function checkRequiredColumns(
  csvData: LocationCsvObject[]
): { code: string; message: string }[] {
  const requiredColumns = ["LocationId", "Name", "Code", "LocationParentId"];
  if (csvData.length === 0) return [];
  const csvColumns = Object.keys(csvData[0]); // Assuming the first row contains column names
  const errors: { code: string; message: string }[] = [];

  requiredColumns.forEach((column) => {
    if (!csvColumns.includes(column)) {
      errors.push({
        code: "COLUMN_MISSING",
        message: `Column "${column}" is missing.`,
      });
    }
  });

  return errors;
}

export function checkLocationDataValidity(
  locations: LocationCsvObject[]
): { code: string; message: string }[] {
  const errors: { code: string; message: string }[] = [];

  locations.forEach((location, index) => {
    const { Code, Name, LocationId } = location;

    if (
      (Name || Code || LocationId) &&
      (!LocationId ||
        !Name ||
        !Code ||
        Name.trim() === "" ||
        Code.trim() === "" ||
        LocationId.trim() === "")
    ) {
      errors.push({
        code: "INVALID_LOCATION_DATA",
        message: `Row ${
          index + 2
        }: LocationId, Name and Code must exist together`,
      });
    }
  });

  return errors;
}

export function checkDataPresence(
  csvData: LocationCsvObject[]
): { code: string; message: string }[] {
  if (csvData.length === 0) {
    return [
      {
        code: "NO_DATA",
        message: "No data is present in the CSV file.",
      },
    ];
  }

  if (csvData.length === 1) {
    const { LocationId, Name, Code } = csvData[0];
    if (
      LocationId === undefined ||
      Name === undefined ||
      Code === undefined ||
      LocationId.trim() === "" ||
      Name.trim() === "" ||
      Code.trim() === ""
    ) {
      return [
        {
          code: "NO_DATA",
          message:
            "No data is present in the CSV file",
        },
      ];
    }
  }

  return [];
}

export default function useLocationImportCheck({
  csvData,
}: {
  csvData: LocationCsvObject[];
}) {
  const [errors, setErrors] = useState<{ code: string; message: string }[]>([]);

  useEffect(() => {
    const newErrors = findDuplicateCodes(csvData);
    const errorsArray: { code: string; message: string }[] = [];

    const requiredColumnErrors = checkRequiredColumns(csvData);
    requiredColumnErrors.forEach((error) => {
      errorsArray.push(error);
    });

    newErrors.forEach((error) => {
      errorsArray.push({ code: error.code, message: error.message });
    });

    const locationIdNotEmptyErrors = checkLocationDataValidity(csvData);
    locationIdNotEmptyErrors.forEach((error) => {
      errorsArray.push(error);
    });

    const dataPresenceErrors = checkDataPresence(csvData);
    dataPresenceErrors.forEach((error) => {
      errorsArray.push(error);
    });

    setErrors(errorsArray);
  }, [csvData]);

  return errors;
}
