import * as React from "react";
import { View, Text } from "@react-pdf/renderer";

export function Divider() {
  return (
    <View
      style={{ width: "100%", height: 2, backgroundColor: "#336699" }}
    ></View>
  );
}
