import { ArrowDownAZ } from "lucide-react";
import { Toggle } from "../../../components/ui/toggle";
import {
  AssetLocationSorting,
  useAssetLocationSorting,
  useAssetLocationSortingActions,
} from "../../../stores/assetLocationSortingStore/assetLocationSortingStore";

export default function SortingToggleBtn() {
  const sortingMode = useAssetLocationSorting();
  const { setAssetLocationSorting } = useAssetLocationSortingActions();

  return (
    <Toggle
      pressed={sortingMode === AssetLocationSorting.ALPHABETICAL}
      onClick={() => {
        setAssetLocationSorting(
          sortingMode === AssetLocationSorting.ALPHABETICAL
            ? AssetLocationSorting.NONE
            : AssetLocationSorting.ALPHABETICAL
        );
      }}
    >
      <ArrowDownAZ className="text-slate-500" />
    </Toggle>
  );
}
