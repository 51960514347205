import { FileText, Pen } from "lucide-react";
import AppButton from "../../../../../../../components/AppButton";
import { useParams, useSearchParams } from "react-router-dom";
import {
  DRAWER_CREATE_STATE,
  DRAWER_UPDATE_STATE,
  PDF_SETTING_DRAWER,
} from "../../../../../../../components/drawers/AppDrawer";
import AppItemInfo from "../../../../../../../components/AppItemInfo";
import { useProjectControllerFindOneProject } from "../../../../../../../api/projects/projects";
import { useEffect } from "react";
import { Card, CardContent } from "../../../../../../../components/ui/card";
import { Switch } from "../../../../../../../components/ui/switch";
import CustomPdfLetterheadCard from "./components/CustomPdfLetterheadCard";
import CustomEquipmentQrLogoCard from "./components/CustomEquipmentQrLogoCard";

export default function ProjectPDFTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();
  const { data: projectData, isLoading: projectIsLoading } =
    useProjectControllerFindOneProject(projectId ?? "", {
      query: {
        enabled: !!projectId,
        select: (res) => res.data.data,
      },
    });

  // useEffect(() => {
  //   console.log(projectData);
  // }, [projectData]);

  return (
    <div className="flex flex-col gap-6 mt-6">
      <h2 className="font-bold text-2xl mt-2">Feature Toggles</h2>
      <div className="grid grid-cols-2 gap-4">
        <CustomPdfLetterheadCard />
        <CustomEquipmentQrLogoCard />
      </div>
      <div className="flex items-center gap-4">
        <h2 className="font-bold text-2xl mt-2">PDF Settings</h2>
        <AppButton
          icon={<Pen />}
          label="Edit"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("drawerType", PDF_SETTING_DRAWER);
              return p;
            });
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-6">
        <AppItemInfo
          icon={<FileText />}
          label="PDF Company Name"
          content={<p>{projectData?.pdfCompanyName ?? "-"}</p>}
        />
        <AppItemInfo
          icon={<FileText />}
          label="PDF Company Registration No."
          content={<p>{projectData?.pdfCompanyRegNo ?? "-"}</p>}
        />
        <AppItemInfo
          icon={<FileText />}
          label="PDF Address"
          content={<p>{projectData?.pdfAddress ?? "-"}</p>}
        />
        <AppItemInfo
          icon={<FileText />}
          label="PDF Tel No"
          content={<p>{projectData?.pdfTelNo ?? "-"}</p>}
        />
        <AppItemInfo
          icon={<FileText />}
          label="PDF Fax No"
          content={<p>{projectData?.pdfFaxNo ?? "-"}</p>}
        />
        <AppItemInfo
          icon={<FileText />}
          label="PDF Email"
          content={<p>{projectData?.pdfEmail ?? "-"}</p>}
        />
        <AppItemInfo
          icon={<FileText />}
          label="PDF Logo"
          content={
            <div>
              {projectData?.pdfLogo?.map((att, idx) => (
                <img
                  className="w-[300px] h-[300px] rounded-xl object-cover shadow-xl"
                  src={att.url}
                  key={idx}
                />
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
}
