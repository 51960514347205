import { ColumnDef, Row } from "@tanstack/react-table";
import { User, WorkTimeLogWithDoneBy } from "../../../../../api/model";
import { Avatar, AvatarFallback } from "../../../../ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { off } from "process";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../ui/dialog";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ManHourForm, { TimeLogFormType, timeLogSchema } from "./ManHourForm";
import AppButton from "../../../../AppButton";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useWoTimeLogsControllerDeleteTimeLog,
  useWoTimeLogsControllerUpdateTimeLog,
} from "../../../../../api/wo-time-logs/wo-time-logs";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 } from "lucide-react";
import ConfirmDeleteBtnWithDialog from "../../../../dialogs/ConfirmDeleteWithTextDialog";

const DoneByCell = ({ row }: { row: Row<WorkTimeLogWithDoneBy> }) => {
  const doneByUsers = row.getValue("doneBy") as User[];

  if (doneByUsers.length <= 0) return <p>-</p>;

  if (doneByUsers.length < 2)
    return (
      <div>
        {(row.getValue("doneBy") as User[])?.map((user) => (
          <div className="flex gap-2 items-center" key={user.id}>
            <Avatar className="bg-primary-900 text-base">
              <AvatarFallback className="bg-transparent text-white">
                {user?.name?.substring(0, 2)?.toUpperCase() ?? "-"}
              </AvatarFallback>
            </Avatar>
            <p className="text-sm">{user?.name}</p>
          </div>
        ))}
      </div>
    );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center hover:cursor-pointer -space-x-2 ">
          {doneByUsers?.map((u, idx) => {
            if (idx < 2)
              return (
                <Avatar
                  className="bg-primary-900 text-base border-white border"
                  key={u.id}
                >
                  <AvatarFallback className="bg-transparent text-white">
                    {(u?.name ?? "-").substring(0, 2).toUpperCase()}
                  </AvatarFallback>
                </Avatar>
              );
          })}
          {
            <p className="pl-3 text-slate-400 text-sm">
              {doneByUsers.length > 2 ? `+${doneByUsers.length - 2}` : <></>}
            </p>
          }
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {doneByUsers.map((u) => (
          <DropdownMenuItem key={u.id}>
            <div className="flex items-center">
              <Avatar className="bg-primary-900 text-base" key={u.id}>
                <AvatarFallback className="bg-transparent text-white">
                  {(u?.name ?? "-").substring(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <p className="ml-2">{u?.name}</p>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const TimeLoggedCell = ({ row }: { row: Row<WorkTimeLogWithDoneBy> }) => {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const activeWoId = searchParams.get("workOrderId");

  const methods = useForm<TimeLogFormType>({
    resolver: zodResolver(timeLogSchema),
  });

  const { mutate, isPending } = useWoTimeLogsControllerUpdateTimeLog({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("wo-time-logs"),
        });
        setOpen(false);
      },
    },
  });

  const { mutateAsync: deleteWoTimeLog } = useWoTimeLogsControllerDeleteTimeLog(
    {
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("wo-time-logs"),
          });
        },
      },
    }
  );

  useEffect(() => {
    // Cleaning up the search params when the dialog is closed
    if (!open && !!searchParams.get("timeLogId")) {
      setSearchParams((p) => {
        p.delete("timeLogId");
        return p;
      });
      methods.reset();
    }
  }, [open]);

  const onSubmit: SubmitHandler<TimeLogFormType> = (data) => {
    if (!activeWoId) return;
    mutate({
      timeLogId: row.getValue("id") as string,
      data: {
        workOrderId: Number(activeWoId),
        dateStart: data.timeRange.dateStart.toISOString(),
        dateEnd: data.timeRange.dateEnd.toISOString(),
        description: data.description,
        doneByIds: data.doneBy.map((user) => user.id),
      },
    });
  };

  return (
    <>
      <div
        className="flex gap-2 items-center underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
        onClick={() => {
          setSearchParams((p) => {
            p.set("timeLogId", row.getValue("id") as string);
            return p;
          });
          setOpen(true);
        }}
      >
        {row.getValue("dateRange")}
      </div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <div className="mb-6 flex items-center gap-6">
            <DialogTitle>Edit Man Hours</DialogTitle>
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt="Delete"
              onDeleteConfirm={async () => {
                await deleteWoTimeLog({
                  timeLogId: row.getValue("id") as string,
                });
                setOpen(false);
              }}
              isIconDelete
            />
          </div>
          <FormProvider {...methods}>
            <ManHourForm />
          </FormProvider>
          <DialogFooter className="mt-6">
            <AppButton
              label="Cancel"
              variant="ghost"
              onClick={() => setOpen(false)}
            />
            <AppButton
              label="Update"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const manHourColumns: ColumnDef<WorkTimeLogWithDoneBy>[] = [
  {
    id: "id",
    accessorKey: "id",
  },
  {
    header: "Date",
    accessorKey: "dateRange",
    cell: ({ row }) => <TimeLoggedCell row={row} />,
  },
  {
    id: "doneBy",
    accessorKey: "doneBy",
    header: "Attended By",
    cell: ({ row }) => <DoneByCell row={row} />,
  },
  {
    id: "description",
    accessorKey: "description",
    header: "Description",
    cell: ({ row }) => <p>{row.getValue("description") ?? "-"}</p>,
  },
  {
    header: "Time taken",
    accessorKey: "timeTaken",
  },
  {
    header: "Normal Hours Taken",
    accessorKey: "normalHoursTaken",
    cell: ({ row }) => {
      if (row.getValue("normalHoursTaken") === "0 hr 0 m") {
        return "-";
      } else {
        return row.getValue("normalHoursTaken");
      }
    },
  },
  {
    header: "OT Hours Taken",
    accessorKey: "overtimeHoursTaken",
    cell: ({ row }) => {
      if (row.getValue("overtimeHoursTaken") === "0 hr 0 m") {
        return "-";
      } else {
        return row.getValue("overtimeHoursTaken");
      }
    },
  },
];
