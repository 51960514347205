import { ColumnDef } from "@tanstack/react-table";
import { DefectType } from "../../../../../api/model";
import ConfirmDeleteBtnWithDialog from "../../../../../components/dialogs/ConfirmDeleteWithTextDialog";
import { Pencil, Trash2 } from "lucide-react";
import AppButton from "../../../../../components/AppButton";
import { useDefectTypeControllerDeleteDefectType } from "../../../../../api/defect-type/defect-type";
import { useQueryClient } from "@tanstack/react-query";

export const workOrderTypeSettingColumns: ColumnDef<DefectType>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    header: "Actions",
    cell: ({ row }) => {
      const qc = useQueryClient();
      const { mutateAsync } = useDefectTypeControllerDeleteDefectType({
        mutation: {
          onSuccess: () => {
            qc.invalidateQueries({
              predicate: (q) =>
                (q.queryKey[0] as string).includes("defect-type"),
            });
          },
        },
      });

      return (
        <div className="flex gap-2">
          <AppButton variant="outline" label="Edit" icon={<Pencil />} />
          <ConfirmDeleteBtnWithDialog
            className="text-red-500 bg-white border-red-50 border-2 border-solid hover:bg-red-50"
            confirmDeleteTxt={row.getValue("name")}
            onDeleteConfirm={async () => {
              const defectTypeId = row.getValue("id");
              if (defectTypeId === undefined || defectTypeId === null) return;
              await mutateAsync({
                defectTypeId: defectTypeId.toString(),
              });
            }}
            btnIcon={<Trash2 />}
          />
        </div>
      );
    },
  },
];
