import {
  AlignLeft,
  Bookmark,
  CalendarCheck2,
  CalendarClock,
  Check,
  Computer,
  FileDigit,
  MapPin,
  Text,
  User2,
  Video,
  Workflow,
  Star,
  FileText,
} from "lucide-react";
import useDate from "../../../../../hooks/useDate";
import AppFullLocation from "../../../../AppFullLocation";
import AppFullWorkOrderType from "../../../../AppFullWorkOrderType";
import AppItemInfo from "../../../../AppItemInfo";
import AppMultiImage from "../../../../AppMultiImage";
import AppWorkOrderStatusChip from "../../../../AppWorkOrderStatusChip";
import AppWorkOrderStatusUpdateMenu from "../../../../AppWorkOrderStatusUpdateMenu";
import ExportPdfBtn from "./components/ExportPdfBtn";
import { GetOneWorkOrderResponseDto } from "../../../../../api/model";
import DeleteWorkOrderBtn from "@/components/drawers/workOrders/tabs/WorkOrderInfoTab/components/DeleteWorkOrderBtn";
import RoleGuard from "@/guards/RoleGuard";
import AppDocument from "../../../../AppDocument";
import AddDocBtn from "./components/AddDocBtn";
import WorkOrderDocVidItem from "./components/WorkOrderDocVidItem";
import useIsCloseWorkOrder from "../../../../../hooks/useIsCloseWorkOrder";
import { AppRatings } from "../../../../rating/AppRatings";
import AppButton from "../../../../AppButton";
import { Link } from "react-router-dom";

export default function WorkOrderInfoTab({
  workOrder,
}: {
  workOrder?: GetOneWorkOrderResponseDto;
}) {
  const { formatDateToLocal, diffInDays } = useDate();
  const isClose = useIsCloseWorkOrder({ workOrder });

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="h-64 col-span-2">
        <AppMultiImage
          attachments={workOrder?.photos}
          className="h-64 w-[500px] border-solid border-neutral-200"
        />
      </div>
      <div className="flex gap-4 items-center col-span-2">
        <AppWorkOrderStatusChip workOrderStatus={workOrder?.defectStatus} />
        <AppWorkOrderStatusUpdateMenu workOrder={workOrder} />
      </div>
      <AppItemInfo
        label="Subject"
        icon={<Bookmark className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.subject ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Description"
        icon={<AlignLeft className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.description ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Ref No"
        icon={<FileDigit className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.refNo ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Completion Days"
        icon={<CalendarClock className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.completedOn
              ? diffInDays({
                  startDateISO: workOrder?.createdOn,
                  endDateISO: workOrder?.completedOn,
                })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Created"
        icon={<CalendarCheck2 className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.createdOn
              ? formatDateToLocal({ dateStr: workOrder?.createdOn })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Closed On"
        icon={<Check className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.completedOn
              ? formatDateToLocal({ dateStr: workOrder?.completedOn })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Equipment"
        icon={<Computer className="text-primary-900" />}
        content={
          <div className="flex flex-col">
            <p>{workOrder?.asset?.name}</p>
          </div>
        }
      />
      <AppItemInfo
        label="Equipment Code"
        icon={<Computer className="text-primary-900" />}
        content={
          <div className="flex flex-col">
            <p>{workOrder?.asset?.code}</p>
          </div>
        }
      />
      <AppItemInfo
        label="Raised By"
        icon={<User2 className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.createdBy?.name ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Location"
        icon={<MapPin className="text-primary-900" />}
        content={
          <AppFullLocation locationId={workOrder?.locationId?.toString()} />
        }
      />
      <AppItemInfo
        label="Work Order Type"
        icon={<Workflow className="text-primary-900" />}
        content={<p>{workOrder?.defectType?.name ?? "-"}</p>}
      />
      <div className="col-span-2">
        <AppItemInfo
          label="Video or Documents"
          icon={<Video className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-4">
              {workOrder?.docs?.length ? (
                workOrder?.docs.map((p) => (
                  <WorkOrderDocVidItem att={p} key={p.id} />
                ))
              ) : (
                <p className="font-sans text-neutral-900">-</p>
              )}
              <div>
                <AddDocBtn />
              </div>
            </div>
          }
        />
      </div>
      {isClose && (
        <div className="col-span-2">
          <AppItemInfo
            label="Work Order Rating"
            icon={<Star className="text-primary-900" />}
            content={
              <>
                {workOrder?.rating ? (
                  <AppRatings
                    rating={workOrder?.rating ? +workOrder?.rating : 0}
                  />
                ) : (
                  <p>-</p>
                )}
              </>
              // <div className="flex flex-col gap-4">
              //   {workOrder?.docs?.length ? (
              //     workOrder?.docs.map((p) => (
              //       <WorkOrderDocVidItem att={p} key={p.id} />
              //     ))
              //   ) : (
              //     <p className="font-sans text-neutral-900">-</p>
              //   )}
              //   <div>
              //     <AddDocBtn />
              //   </div>
              // </div>
            }
          />
        </div>
      )}
      {isClose && (
        <AppItemInfo
          label="Work Order Closing Remarks"
          icon={<Star className="text-primary-900" />}
          content={
            <p>
              {workOrder?.closingRemark?.length === 0
                ? "-"
                : workOrder?.closingRemark ?? "-"}
            </p>
          }
        />
      )}
      <div className="flex gap-4 col-span-2">
        <ExportPdfBtn workOrder={workOrder} />
        {/* <Link target="_blank" to={`/customPdf/jkr/${workOrder?.id}`}>
          <AppButton
            variant="outline"
            icon={<FileText />}
            label="Print JKR PDF"
          />
        </Link> */}
        <RoleGuard roleName="manager">
          <DeleteWorkOrderBtn />
        </RoleGuard>
      </div>
    </div>
  );
}
