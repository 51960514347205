import {
  Delete,
  DeleteOutlineRounded,
  Description,
  InfoOutlined,
  InfoRounded,
  LabelOffRounded,
  LabelOutlined,
  LabelRounded,
  Photo,
} from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@mui/lab";
import { CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import AppItemInfo from "../../../components/AppItemInfo";
import AppLogoText from "../../../components/AppLogoText";
import PublicPageWrapper from "../../../components/PublicPageWrapper";
import useDate from "../../../hooks/useDate";
import EmptyIcon from "../../../icons/EmptyIcon";
import { useGetPublicWorkRequestQuery } from "../../../redux/slices/WorkRequest/WorkRequestApi";
import { TabPanel } from "../../DashboardScreen/components/AppTabPanel";
import CancelWorkRequestDialog from "./dialog/CancelWorkRequestDialog.tsx/CancelWorkRequestDialog";
import { useWorkRequestControllerGetWorkRequestBasedFromCode } from "../../../api/work-request/work-request";

export default function PublicWorkRequestDetailScreen() {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [tabIdx, setTabIdx] = useState<number>(0);
  const { workRequestCode, projectCode } = useParams();
  const navigate = useNavigate();
  const { formatDateToLocal } = useDate();

  const { data: workReqData, isError } =
    useWorkRequestControllerGetWorkRequestBasedFromCode(
      projectCode ?? "",
      workRequestCode ?? "",

      {
        query: {
          enabled: !!projectCode && !!workRequestCode,
          select: (res) => res.data,
        },
      }
    );

  const handleChange = (event: React.SyntheticEvent, newTabIdx: number) => {
    setTabIdx(newTabIdx);
  };

  if (!workReqData && !isError) {
    return (
      <PublicPageWrapper className="justify-between items-center">
        <AppLogoText subtitle="Work Request Update" className="w-full" />
        <div className="flex flex-col gap-6 items-center">
          <CircularProgress size={60} />
          <p className="font-sans text-neutral-500">
            Searching work request...
          </p>
        </div>
        <AppButton
          className="w-full"
          variant="ghost"
          label="Cancel"
          onClick={() => {
            navigate(`/public/work-request/${projectCode}/track`);
          }}
        />
      </PublicPageWrapper>
    );
  }

  if (isError)
    return (
      <PublicPageWrapper className="justify-between">
        <AppLogoText />
        <div className="flex flex-col gap-5">
          <div className="w-full h-64 rounded-xl flex flex-col justify-center items-center">
            <EmptyIcon />
          </div>
          <p className="text-2xl font-sans text-neutral-700 text-center">
            Sorry your work request is not found
          </p>
        </div>
        <AppButton
          label="Track another"
          onClick={() => {
            navigate(`/public/work-request/${projectCode}/track`);
          }}
        />
      </PublicPageWrapper>
    );

  return (
    <PublicPageWrapper className="gap-4 relative">
      <CancelWorkRequestDialog
        open={isCancelDialogOpen}
        onClose={() => {
          setIsCancelDialogOpen(false);
        }}
        wr={workReqData}
      />
      <AppLogoText subtitle="Work Request Detail" />
      <div className="p-4 rounded-xl border border-neutral-400 border-solid flex flex-col items-center font-sans gap-3">
        <p className="text-neutral-600">Work Request Status on</p>
        <p className="text-xl text-primary-900 font-bold">
          {workReqData?.trackingCode}
        </p>
      </div>
      <Tabs value={tabIdx} onChange={handleChange} variant="fullWidth">
        <Tab label="Updates" value={0} />
        <Tab label="Info" value={1} />
      </Tabs>
      <TabPanel value={tabIdx} index={0}>
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.3,
            },
            padding: 0,
          }}
        >
          {workReqData.workRequestLogs.map((log, idx) => (
            <TimelineItem key={log.id}>
              <TimelineOppositeContent>
                <p className="font-sans text-neutral-500 text-sm">
                  {formatDateToLocal({ dateStr: log.createdOn })}
                </p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {idx !== workReqData.workRequestLogs.length - 1 && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <p className="font-sans text-neutral-900">{log.description}</p>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
        {workReqData.workRequestStatus === "CANCELLED" && (
          <div className="flex flex-col items-center font-sans gap-2 my-6">
            <p className="text-neutral-900 font-bold text-xl">
              Cancellation Remark
            </p>
            <div className="p-4 rounded-xl bg-gray-100 w-full flex flex-col min-h-[150px]">
              <p className="font-sans text-neutral-900">
                {workReqData.cancellationRemark ?? "No remarks"}
              </p>
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabIdx} index={1}>
        <div className="flex flex-col gap-4">
          <AppItemInfo
            label="Title"
            icon={<LabelOutlined className="text-primary-900 text-3xl" />}
            content={<p className="text-neutral-500">{workReqData.title}</p>}
          />
          <AppItemInfo
            label="Description"
            icon={<Description className="text-primary-900 text-3xl" />}
            content={
              <p className="text-neutral-500">{workReqData.description}</p>
            }
          />
          <AppItemInfo
            label="Photos"
            icon={<Photo className="text-primary-900 text-3xl" />}
            content={
              <div className="w-full">
                {workReqData.photos.map((wr) => (
                  <img
                    src={wr.url}
                    height="200"
                    key={wr.url}
                    className="w-full rounded-xl object-cover shadow-xl hover:cursor-pointer"
                    onClick={() => {
                      window.open(wr.url);
                    }}
                  />
                ))}
              </div>
            }
          />
        </div>
      </TabPanel>
      <div className="flex gap-3">
        {/* Only new work request can be cancelled, otherwise hide this button */}
        {workReqData.workRequestStatus === "NEW" && (
          <AppButton
            variant="outline"
            label="Cancel"
            onClick={() => {
              setIsCancelDialogOpen(true);
            }}
            className="flex-grow"
            color="warning"
            icon={<DeleteOutlineRounded className="text-red-500" />}
          />
        )}
        <AppButton
          label="Track Another"
          className="flex-grow"
          onClick={() => {
            navigate(`/public/work-request/${projectCode}/track`);
          }}
        />
      </div>
    </PublicPageWrapper>
  );
}
