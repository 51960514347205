import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Textarea, TextareaProps } from "./ui/textarea";
import { cn } from "../lib/utils";

interface AppTextAreaFieldProps {
  name: string;
  label: string;
  description?: string;
  placeholder?: string;
}

export default function AppTextAreaField({
  name,
  label,
  description,
  placeholder,
}: AppTextAreaFieldProps & TextareaProps) {
  const form = useFormContext();

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea
              placeholder={placeholder}
              className={cn("focus-visible:ring-primary-900 bg-slate-50", {
                "ring-2 ring-offset-1 ring-red-500": !!error,
              })}
              {...field}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
