import { useAuthControllerGetCurrentUser } from "../api/auth/auth";
import { AccountType, AccountTypeEnum } from "../api/model";

interface UseRolesProps {
  role: AccountTypeEnum;
}

function useRoles({ role }: UseRolesProps) {
  const { data: user, isLoading } = useAuthControllerGetCurrentUser({
    query: {
      select: (res) => res.data,
    },
  });

  if (!user) return false;

  return user.accountTypes.map((typ) => typ.type).includes(role);
}

export const useIsSuperadmin = () =>
  useRoles({ role: AccountTypeEnum.SUPERADMIN });

export const useIsAdmin = () =>
  useRoles({ role: AccountTypeEnum.COMPANY_ADMIN });
