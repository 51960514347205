import { useSearchParams } from "react-router-dom";
import { useEscalationControllerGetOneEscalation } from "../../../api/escalation/escalation";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import { Pen } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import EscalationInfoTab from "./tabs/EscalationInfoTab";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import EscalationLogTab from "./tabs/EscalationLogTab";
import { DRAWER_UPDATE_STATE, ESCALATION_FORM_DRAWER } from "../AppDrawer";

export default function EscalationDetailDrawer() {
  const [, setSearchParams] = useSearchParams();
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "escalationDetailTabIdx",
    defaultValue: "0",
  });
  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center gap-4">
        <h1 className="text-2xl font-bold">Escalation Detail</h1>
        <AppButton
          variant="outline"
          label="Edit"
          icon={<Pen />}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", ESCALATION_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              return p;
            });
          }}
        />
      </div>
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <TabsList>
          <TabsTrigger value="0">Info</TabsTrigger>
          <TabsTrigger value="1">Logs</TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <EscalationInfoTab />
        </TabsContent>
        <TabsContent value="1">
          <EscalationLogTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}
