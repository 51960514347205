import {
  useStocksControllerGetInventoryLocations,
  useStocksControllerGetStocks,
} from "../../../../../../../../../api/stocks-inventory/stocks-inventory";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { useWorkOrderInventoryActions } from "../../../../../../../../../stores/workOrderInventoryStore/workOrderInventoryStore";
import { Button } from "../../../../../../../../ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../../ui/popover";

interface InventoryLocationPopoverProps {
  inventoryId: number;
}

export default function InventoryLocationPopover({
  inventoryId,
}: InventoryLocationPopoverProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: inventoryList, isLoading: inventoryListIsLoading } =
    useStocksControllerGetStocks(
      {
        projectId: activeProj?.id?.toString() ?? "",
      },
      {
        query: {
          enabled: !!activeProj,
          select: (res) => res.data,
        },
      }
    );
  const {
    data: inventoryLocations,
    isLoading,
    isFetching,
  } = useStocksControllerGetInventoryLocations(inventoryId.toString(), {
    query: {
      enabled: !!inventoryId,
      select: (res) => res.data.data,
    },
  });

  const { getCurrentAmt, reduceAmt, increaseAmt } =
    useWorkOrderInventoryActions();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="text-primary-900 hover:text-primary-900"
        >
          Pick Location
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96">
        <div className="flex flex-col gap-4">
          {inventoryLocations?.map((l) => (
            <div
              className="flex items-center gap-8 justify-between"
              key={l.location.id}
            >
              <div className="flex flex-col gap-1">
                <p className="font-semibold line-clamp-1">{l.location.name}</p>
                <p className="text-sm text-slate-400">{l.balance} left</p>
              </div>
              <div className="flex items-center gap-4">
                <span
                  className="px-2 py-1 bg-primary-900 text-white font-bold hover:cursor-pointer rounded-md"
                  onClick={() => {
                    if (!activeProj) return;
                    reduceAmt({
                      inventoryId: inventoryId.toString(),
                      locationId: l.location.id ?? -1,
                    });
                  }}
                >
                  -
                </span>
                <span className="px-2 py-1">
                  <p>
                    {getCurrentAmt({
                      inventoryId: inventoryId.toString(),
                      locationId: l.location.id ?? -1,
                    })}
                  </p>
                </span>
                <span
                  data-testid={`add-${l.location.name}`}
                  className="px-2 py-1 bg-primary-900 text-white font-bold hover:cursor-pointer rounded-md"
                  onClick={() => {
                    if (!activeProj) return;
                    increaseAmt({
                      inventoryId: inventoryId.toString(),
                      activeProj,
                      inventoryList: inventoryList ?? [],
                      locationId: l.location.id ?? -1,
                      location: l.location,
                    });
                  }}
                >
                  +
                </span>
              </div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}
