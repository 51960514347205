/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AddInventoryLocationRequestDto,
  AdjustInventoryLocationBalanceRequestDto,
  AdjustWarningInventoryLevelRequestDto,
  CreateStockAdjustmentRequestDto,
  CreateStockDto,
  GetAvailableSerialInventoriesResponseDto,
  GetInventoryLocationResponseDto,
  GetInventoryMovementPdf,
  GetInventoryMovementPdfResponseDto,
  GetOneStockWithLedgerResponseDto,
  GetStockCountResponseDto,
  StockAdjustmentControllerGetAdjustmentListParams,
  StockAdjustmentControllerGetOneAdjustment200,
  StockEntity,
  StocksControllerDeleteDoc200,
  StocksControllerDeletePhoto200,
  StocksControllerGetStocksCountParams,
  StocksControllerGetStocksParams,
  StocksControllerUpdateStock200,
  UpdateStockAdjustmentDto,
  UpdateStockDto,
} from ".././model";

export const stocksControllerGetStocksCount = (
  params: StocksControllerGetStocksCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetStockCountResponseDto>> => {
  return axios.get(`/stocks/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getStocksControllerGetStocksCountQueryKey = (
  params: StocksControllerGetStocksCountParams
) => {
  return [`/stocks/count`, ...(params ? [params] : [])] as const;
};

export const getStocksControllerGetStocksCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
    StocksControllerGetStocksCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
        QueryKey,
        StocksControllerGetStocksCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStocksControllerGetStocksCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
    QueryKey,
    StocksControllerGetStocksCountParams["cursorId"]
  > = ({ signal, pageParam }) =>
    stocksControllerGetStocksCount(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
    TError,
    TData,
    Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
    QueryKey,
    StocksControllerGetStocksCountParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type StocksControllerGetStocksCountInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerGetStocksCount>>
>;
export type StocksControllerGetStocksCountInfiniteQueryError =
  AxiosError<unknown>;

export const useStocksControllerGetStocksCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
    StocksControllerGetStocksCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
        QueryKey,
        StocksControllerGetStocksCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStocksControllerGetStocksCountInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStocksControllerGetStocksCountQueryOptions = <
  TData = Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStocksControllerGetStocksCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetStocksCount>>
  > = ({ signal }) =>
    stocksControllerGetStocksCount(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StocksControllerGetStocksCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerGetStocksCount>>
>;
export type StocksControllerGetStocksCountQueryError = AxiosError<unknown>;

export const useStocksControllerGetStocksCount = <
  TData = Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocksCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStocksControllerGetStocksCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stocksControllerGetInventoryLocations = (
  inventoryId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetInventoryLocationResponseDto>> => {
  return axios.get(`/stocks/${inventoryId}/location`, options);
};

export const getStocksControllerGetInventoryLocationsQueryKey = (
  inventoryId: string
) => {
  return [`/stocks/${inventoryId}/location`] as const;
};

export const getStocksControllerGetInventoryLocationsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>
  >,
  TError = AxiosError<unknown>
>(
  inventoryId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStocksControllerGetInventoryLocationsQueryKey(inventoryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>
  > = ({ signal }) =>
    stocksControllerGetInventoryLocations(inventoryId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!inventoryId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StocksControllerGetInventoryLocationsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>
  >;
export type StocksControllerGetInventoryLocationsInfiniteQueryError =
  AxiosError<unknown>;

export const useStocksControllerGetInventoryLocationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>
  >,
  TError = AxiosError<unknown>
>(
  inventoryId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getStocksControllerGetInventoryLocationsInfiniteQueryOptions(
      inventoryId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStocksControllerGetInventoryLocationsQueryOptions = <
  TData = Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
  TError = AxiosError<unknown>
>(
  inventoryId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStocksControllerGetInventoryLocationsQueryKey(inventoryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>
  > = ({ signal }) =>
    stocksControllerGetInventoryLocations(inventoryId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!inventoryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StocksControllerGetInventoryLocationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>
>;
export type StocksControllerGetInventoryLocationsQueryError =
  AxiosError<unknown>;

export const useStocksControllerGetInventoryLocations = <
  TData = Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
  TError = AxiosError<unknown>
>(
  inventoryId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetInventoryLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStocksControllerGetInventoryLocationsQueryOptions(
    inventoryId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stocksControllerGetStocks = (
  params: StocksControllerGetStocksParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StockEntity[]>> => {
  return axios.get(`/stocks`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getStocksControllerGetStocksQueryKey = (
  params: StocksControllerGetStocksParams
) => {
  return [`/stocks`, ...(params ? [params] : [])] as const;
};

export const getStocksControllerGetStocksInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetStocks>>,
    StocksControllerGetStocksParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocks>>,
        TError,
        TData,
        Awaited<ReturnType<typeof stocksControllerGetStocks>>,
        QueryKey,
        StocksControllerGetStocksParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStocksControllerGetStocksQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetStocks>>,
    QueryKey,
    StocksControllerGetStocksParams["cursorId"]
  > = ({ signal, pageParam }) =>
    stocksControllerGetStocks(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetStocks>>,
    TError,
    TData,
    Awaited<ReturnType<typeof stocksControllerGetStocks>>,
    QueryKey,
    StocksControllerGetStocksParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type StocksControllerGetStocksInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerGetStocks>>
>;
export type StocksControllerGetStocksInfiniteQueryError = AxiosError<unknown>;

export const useStocksControllerGetStocksInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetStocks>>,
    StocksControllerGetStocksParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocks>>,
        TError,
        TData,
        Awaited<ReturnType<typeof stocksControllerGetStocks>>,
        QueryKey,
        StocksControllerGetStocksParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStocksControllerGetStocksInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStocksControllerGetStocksQueryOptions = <
  TData = Awaited<ReturnType<typeof stocksControllerGetStocks>>,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocks>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStocksControllerGetStocksQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetStocks>>
  > = ({ signal }) =>
    stocksControllerGetStocks(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetStocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StocksControllerGetStocksQueryResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerGetStocks>>
>;
export type StocksControllerGetStocksQueryError = AxiosError<unknown>;

export const useStocksControllerGetStocks = <
  TData = Awaited<ReturnType<typeof stocksControllerGetStocks>>,
  TError = AxiosError<unknown>
>(
  params: StocksControllerGetStocksParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetStocks>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStocksControllerGetStocksQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stocksControllerCreateStock = (
  createStockDto: CreateStockDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/stocks`, createStockDto, options);
};

export const getStocksControllerCreateStockMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerCreateStock>>,
    TError,
    { data: CreateStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerCreateStock>>,
  TError,
  { data: CreateStockDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerCreateStock>>,
    { data: CreateStockDto }
  > = (props) => {
    const { data } = props ?? {};

    return stocksControllerCreateStock(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerCreateStockMutationResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerCreateStock>>
>;
export type StocksControllerCreateStockMutationBody = CreateStockDto;
export type StocksControllerCreateStockMutationError = AxiosError<unknown>;

export const useStocksControllerCreateStock = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerCreateStock>>,
    TError,
    { data: CreateStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerCreateStock>>,
  TError,
  { data: CreateStockDto },
  TContext
> => {
  const mutationOptions =
    getStocksControllerCreateStockMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerGetInventoryMovementPdf = (
  inventoryId: string,
  getInventoryMovementPdf: GetInventoryMovementPdf,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetInventoryMovementPdfResponseDto | void>> => {
  return axios.post(
    `/stocks/${inventoryId}/inventory-movement-pdf`,
    getInventoryMovementPdf,
    options
  );
};

export const getStocksControllerGetInventoryMovementPdfMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerGetInventoryMovementPdf>>,
    TError,
    { inventoryId: string; data: GetInventoryMovementPdf },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerGetInventoryMovementPdf>>,
  TError,
  { inventoryId: string; data: GetInventoryMovementPdf },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerGetInventoryMovementPdf>>,
    { inventoryId: string; data: GetInventoryMovementPdf }
  > = (props) => {
    const { inventoryId, data } = props ?? {};

    return stocksControllerGetInventoryMovementPdf(
      inventoryId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerGetInventoryMovementPdfMutationResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerGetInventoryMovementPdf>>
>;
export type StocksControllerGetInventoryMovementPdfMutationBody =
  GetInventoryMovementPdf;
export type StocksControllerGetInventoryMovementPdfMutationError =
  AxiosError<unknown>;

export const useStocksControllerGetInventoryMovementPdf = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerGetInventoryMovementPdf>>,
    TError,
    { inventoryId: string; data: GetInventoryMovementPdf },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerGetInventoryMovementPdf>>,
  TError,
  { inventoryId: string; data: GetInventoryMovementPdf },
  TContext
> => {
  const mutationOptions =
    getStocksControllerGetInventoryMovementPdfMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerAddInventoryLocation = (
  inventoryId: string,
  addInventoryLocationRequestDto: AddInventoryLocationRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/stocks/${inventoryId}/add-location`,
    addInventoryLocationRequestDto,
    options
  );
};

export const getStocksControllerAddInventoryLocationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerAddInventoryLocation>>,
    TError,
    { inventoryId: string; data: AddInventoryLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerAddInventoryLocation>>,
  TError,
  { inventoryId: string; data: AddInventoryLocationRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerAddInventoryLocation>>,
    { inventoryId: string; data: AddInventoryLocationRequestDto }
  > = (props) => {
    const { inventoryId, data } = props ?? {};

    return stocksControllerAddInventoryLocation(
      inventoryId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerAddInventoryLocationMutationResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerAddInventoryLocation>>
>;
export type StocksControllerAddInventoryLocationMutationBody =
  AddInventoryLocationRequestDto;
export type StocksControllerAddInventoryLocationMutationError =
  AxiosError<unknown>;

export const useStocksControllerAddInventoryLocation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerAddInventoryLocation>>,
    TError,
    { inventoryId: string; data: AddInventoryLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerAddInventoryLocation>>,
  TError,
  { inventoryId: string; data: AddInventoryLocationRequestDto },
  TContext
> => {
  const mutationOptions =
    getStocksControllerAddInventoryLocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerGetOneStockWithLedger = (
  stockId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneStockWithLedgerResponseDto>> => {
  return axios.get(`/stocks/${stockId}/ledgers`, options);
};

export const getStocksControllerGetOneStockWithLedgerQueryKey = (
  stockId: string
) => {
  return [`/stocks/${stockId}/ledgers`] as const;
};

export const getStocksControllerGetOneStockWithLedgerInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>
  >,
  TError = AxiosError<unknown>
>(
  stockId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStocksControllerGetOneStockWithLedgerQueryKey(stockId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>
  > = ({ signal }) =>
    stocksControllerGetOneStockWithLedger(stockId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!stockId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StocksControllerGetOneStockWithLedgerInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>
  >;
export type StocksControllerGetOneStockWithLedgerInfiniteQueryError =
  AxiosError<unknown>;

export const useStocksControllerGetOneStockWithLedgerInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>
  >,
  TError = AxiosError<unknown>
>(
  stockId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getStocksControllerGetOneStockWithLedgerInfiniteQueryOptions(
      stockId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStocksControllerGetOneStockWithLedgerQueryOptions = <
  TData = Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
  TError = AxiosError<unknown>
>(
  stockId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStocksControllerGetOneStockWithLedgerQueryKey(stockId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>
  > = ({ signal }) =>
    stocksControllerGetOneStockWithLedger(stockId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!stockId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StocksControllerGetOneStockWithLedgerQueryResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>
>;
export type StocksControllerGetOneStockWithLedgerQueryError =
  AxiosError<unknown>;

export const useStocksControllerGetOneStockWithLedger = <
  TData = Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
  TError = AxiosError<unknown>
>(
  stockId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stocksControllerGetOneStockWithLedger>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStocksControllerGetOneStockWithLedgerQueryOptions(
    stockId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stocksControllerAdjustWarningInventoryLevels = (
  stockId: number,
  adjustWarningInventoryLevelRequestDto: AdjustWarningInventoryLevelRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/stocks/${stockId}/warningBalance`,
    adjustWarningInventoryLevelRequestDto,
    options
  );
};

export const getStocksControllerAdjustWarningInventoryLevelsMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerAdjustWarningInventoryLevels>>,
    TError,
    { stockId: number; data: AdjustWarningInventoryLevelRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerAdjustWarningInventoryLevels>>,
  TError,
  { stockId: number; data: AdjustWarningInventoryLevelRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerAdjustWarningInventoryLevels>>,
    { stockId: number; data: AdjustWarningInventoryLevelRequestDto }
  > = (props) => {
    const { stockId, data } = props ?? {};

    return stocksControllerAdjustWarningInventoryLevels(
      stockId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerAdjustWarningInventoryLevelsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stocksControllerAdjustWarningInventoryLevels>>
  >;
export type StocksControllerAdjustWarningInventoryLevelsMutationBody =
  AdjustWarningInventoryLevelRequestDto;
export type StocksControllerAdjustWarningInventoryLevelsMutationError =
  AxiosError<unknown>;

export const useStocksControllerAdjustWarningInventoryLevels = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerAdjustWarningInventoryLevels>>,
    TError,
    { stockId: number; data: AdjustWarningInventoryLevelRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerAdjustWarningInventoryLevels>>,
  TError,
  { stockId: number; data: AdjustWarningInventoryLevelRequestDto },
  TContext
> => {
  const mutationOptions =
    getStocksControllerAdjustWarningInventoryLevelsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerAdjustInventoryLocationBalance = (
  inventoryId: string,
  adjustInventoryLocationBalanceRequestDto: AdjustInventoryLocationBalanceRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/stocks/${inventoryId}/adjust-inventory-location-balance`,
    adjustInventoryLocationBalanceRequestDto,
    options
  );
};

export const getStocksControllerAdjustInventoryLocationBalanceMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof stocksControllerAdjustInventoryLocationBalance>
      >,
      TError,
      { inventoryId: string; data: AdjustInventoryLocationBalanceRequestDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerAdjustInventoryLocationBalance>>,
    TError,
    { inventoryId: string; data: AdjustInventoryLocationBalanceRequestDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof stocksControllerAdjustInventoryLocationBalance>
      >,
      { inventoryId: string; data: AdjustInventoryLocationBalanceRequestDto }
    > = (props) => {
      const { inventoryId, data } = props ?? {};

      return stocksControllerAdjustInventoryLocationBalance(
        inventoryId,
        data,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type StocksControllerAdjustInventoryLocationBalanceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stocksControllerAdjustInventoryLocationBalance>>
  >;
export type StocksControllerAdjustInventoryLocationBalanceMutationBody =
  AdjustInventoryLocationBalanceRequestDto;
export type StocksControllerAdjustInventoryLocationBalanceMutationError =
  AxiosError<unknown>;

export const useStocksControllerAdjustInventoryLocationBalance = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerAdjustInventoryLocationBalance>>,
    TError,
    { inventoryId: string; data: AdjustInventoryLocationBalanceRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerAdjustInventoryLocationBalance>>,
  TError,
  { inventoryId: string; data: AdjustInventoryLocationBalanceRequestDto },
  TContext
> => {
  const mutationOptions =
    getStocksControllerAdjustInventoryLocationBalanceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerUpdateStock = (
  stockId: string,
  updateStockDto: UpdateStockDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StocksControllerUpdateStock200>> => {
  return axios.put(`/stocks/${stockId}`, updateStockDto, options);
};

export const getStocksControllerUpdateStockMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerUpdateStock>>,
    TError,
    { stockId: string; data: UpdateStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerUpdateStock>>,
  TError,
  { stockId: string; data: UpdateStockDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerUpdateStock>>,
    { stockId: string; data: UpdateStockDto }
  > = (props) => {
    const { stockId, data } = props ?? {};

    return stocksControllerUpdateStock(stockId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerUpdateStockMutationResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerUpdateStock>>
>;
export type StocksControllerUpdateStockMutationBody = UpdateStockDto;
export type StocksControllerUpdateStockMutationError = AxiosError<unknown>;

export const useStocksControllerUpdateStock = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerUpdateStock>>,
    TError,
    { stockId: string; data: UpdateStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerUpdateStock>>,
  TError,
  { stockId: string; data: UpdateStockDto },
  TContext
> => {
  const mutationOptions =
    getStocksControllerUpdateStockMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerDeleteInventory = (
  stockId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/stocks/${stockId}`, options);
};

export const getStocksControllerDeleteInventoryMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerDeleteInventory>>,
    TError,
    { stockId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerDeleteInventory>>,
  TError,
  { stockId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerDeleteInventory>>,
    { stockId: number }
  > = (props) => {
    const { stockId } = props ?? {};

    return stocksControllerDeleteInventory(stockId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerDeleteInventoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerDeleteInventory>>
>;

export type StocksControllerDeleteInventoryMutationError = AxiosError<unknown>;

export const useStocksControllerDeleteInventory = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerDeleteInventory>>,
    TError,
    { stockId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerDeleteInventory>>,
  TError,
  { stockId: number },
  TContext
> => {
  const mutationOptions =
    getStocksControllerDeleteInventoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerDeletePhoto = (
  stockId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StocksControllerDeletePhoto200>> => {
  return axios.delete(`/stocks/${stockId}/attachment/${attachmentId}`, options);
};

export const getStocksControllerDeletePhotoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerDeletePhoto>>,
    TError,
    { stockId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerDeletePhoto>>,
  TError,
  { stockId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerDeletePhoto>>,
    { stockId: string; attachmentId: string }
  > = (props) => {
    const { stockId, attachmentId } = props ?? {};

    return stocksControllerDeletePhoto(stockId, attachmentId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerDeletePhotoMutationResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerDeletePhoto>>
>;

export type StocksControllerDeletePhotoMutationError = AxiosError<unknown>;

export const useStocksControllerDeletePhoto = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerDeletePhoto>>,
    TError,
    { stockId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerDeletePhoto>>,
  TError,
  { stockId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions =
    getStocksControllerDeletePhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stocksControllerDeleteDoc = (
  inventoryId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StocksControllerDeleteDoc200>> => {
  return axios.delete(`/stocks/${inventoryId}/docs/${attachmentId}`, options);
};

export const getStocksControllerDeleteDocMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerDeleteDoc>>,
    TError,
    { inventoryId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stocksControllerDeleteDoc>>,
  TError,
  { inventoryId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stocksControllerDeleteDoc>>,
    { inventoryId: string; attachmentId: string }
  > = (props) => {
    const { inventoryId, attachmentId } = props ?? {};

    return stocksControllerDeleteDoc(inventoryId, attachmentId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StocksControllerDeleteDocMutationResult = NonNullable<
  Awaited<ReturnType<typeof stocksControllerDeleteDoc>>
>;

export type StocksControllerDeleteDocMutationError = AxiosError<unknown>;

export const useStocksControllerDeleteDoc = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stocksControllerDeleteDoc>>,
    TError,
    { inventoryId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stocksControllerDeleteDoc>>,
  TError,
  { inventoryId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions = getStocksControllerDeleteDocMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stockAdjustmentControllerCreateStockAdjustment = (
  createStockAdjustmentRequestDto: CreateStockAdjustmentRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/stock-adjustment`,
    createStockAdjustmentRequestDto,
    options
  );
};

export const getStockAdjustmentControllerCreateStockAdjustmentMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof stockAdjustmentControllerCreateStockAdjustment>
      >,
      TError,
      { data: CreateStockAdjustmentRequestDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerCreateStockAdjustment>>,
    TError,
    { data: CreateStockAdjustmentRequestDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof stockAdjustmentControllerCreateStockAdjustment>
      >,
      { data: CreateStockAdjustmentRequestDto }
    > = (props) => {
      const { data } = props ?? {};

      return stockAdjustmentControllerCreateStockAdjustment(data, axiosOptions);
    };

    return { mutationFn, ...mutationOptions };
  };

export type StockAdjustmentControllerCreateStockAdjustmentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stockAdjustmentControllerCreateStockAdjustment>>
  >;
export type StockAdjustmentControllerCreateStockAdjustmentMutationBody =
  CreateStockAdjustmentRequestDto;
export type StockAdjustmentControllerCreateStockAdjustmentMutationError =
  AxiosError<unknown>;

export const useStockAdjustmentControllerCreateStockAdjustment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerCreateStockAdjustment>>,
    TError,
    { data: CreateStockAdjustmentRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stockAdjustmentControllerCreateStockAdjustment>>,
  TError,
  { data: CreateStockAdjustmentRequestDto },
  TContext
> => {
  const mutationOptions =
    getStockAdjustmentControllerCreateStockAdjustmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stockAdjustmentControllerGetAdjustmentList = (
  params: StockAdjustmentControllerGetAdjustmentListParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/stock-adjustment`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getStockAdjustmentControllerGetAdjustmentListQueryKey = (
  params: StockAdjustmentControllerGetAdjustmentListParams
) => {
  return [`/stock-adjustment`, ...(params ? [params] : [])] as const;
};

export const getStockAdjustmentControllerGetAdjustmentListInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
      StockAdjustmentControllerGetAdjustmentListParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: StockAdjustmentControllerGetAdjustmentListParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>
          >,
          QueryKey,
          StockAdjustmentControllerGetAdjustmentListParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getStockAdjustmentControllerGetAdjustmentListQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
      QueryKey,
      StockAdjustmentControllerGetAdjustmentListParams["cursorId"]
    > = ({ signal, pageParam }) =>
      stockAdjustmentControllerGetAdjustmentList(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
      TError,
      TData,
      Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
      QueryKey,
      StockAdjustmentControllerGetAdjustmentListParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type StockAdjustmentControllerGetAdjustmentListInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>
  >;
export type StockAdjustmentControllerGetAdjustmentListInfiniteQueryError =
  AxiosError<unknown>;

export const useStockAdjustmentControllerGetAdjustmentListInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
    StockAdjustmentControllerGetAdjustmentListParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: StockAdjustmentControllerGetAdjustmentListParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
        TError,
        TData,
        Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
        QueryKey,
        StockAdjustmentControllerGetAdjustmentListParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getStockAdjustmentControllerGetAdjustmentListInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStockAdjustmentControllerGetAdjustmentListQueryOptions = <
  TData = Awaited<
    ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>
  >,
  TError = AxiosError<unknown>
>(
  params: StockAdjustmentControllerGetAdjustmentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStockAdjustmentControllerGetAdjustmentListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>
  > = ({ signal }) =>
    stockAdjustmentControllerGetAdjustmentList(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StockAdjustmentControllerGetAdjustmentListQueryResult = NonNullable<
  Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>
>;
export type StockAdjustmentControllerGetAdjustmentListQueryError =
  AxiosError<unknown>;

export const useStockAdjustmentControllerGetAdjustmentList = <
  TData = Awaited<
    ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>
  >,
  TError = AxiosError<unknown>
>(
  params: StockAdjustmentControllerGetAdjustmentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockAdjustmentControllerGetAdjustmentList>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getStockAdjustmentControllerGetAdjustmentListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stockAdjustmentControllerGetOneAdjustment = (
  stockAdjustmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StockAdjustmentControllerGetOneAdjustment200>> => {
  return axios.get(`/stock-adjustment/${stockAdjustmentId}`, options);
};

export const getStockAdjustmentControllerGetOneAdjustmentQueryKey = (
  stockAdjustmentId: string
) => {
  return [`/stock-adjustment/${stockAdjustmentId}`] as const;
};

export const getStockAdjustmentControllerGetOneAdjustmentInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>
    >,
    TError = AxiosError<unknown>
  >(
    stockAdjustmentId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getStockAdjustmentControllerGetOneAdjustmentQueryKey(stockAdjustmentId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>
    > = ({ signal }) =>
      stockAdjustmentControllerGetOneAdjustment(stockAdjustmentId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!stockAdjustmentId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type StockAdjustmentControllerGetOneAdjustmentInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>
  >;
export type StockAdjustmentControllerGetOneAdjustmentInfiniteQueryError =
  AxiosError<unknown>;

export const useStockAdjustmentControllerGetOneAdjustmentInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>
  >,
  TError = AxiosError<unknown>
>(
  stockAdjustmentId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getStockAdjustmentControllerGetOneAdjustmentInfiniteQueryOptions(
      stockAdjustmentId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStockAdjustmentControllerGetOneAdjustmentQueryOptions = <
  TData = Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
  TError = AxiosError<unknown>
>(
  stockAdjustmentId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStockAdjustmentControllerGetOneAdjustmentQueryKey(stockAdjustmentId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>
  > = ({ signal }) =>
    stockAdjustmentControllerGetOneAdjustment(stockAdjustmentId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!stockAdjustmentId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StockAdjustmentControllerGetOneAdjustmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>
>;
export type StockAdjustmentControllerGetOneAdjustmentQueryError =
  AxiosError<unknown>;

export const useStockAdjustmentControllerGetOneAdjustment = <
  TData = Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
  TError = AxiosError<unknown>
>(
  stockAdjustmentId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockAdjustmentControllerGetOneAdjustment>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStockAdjustmentControllerGetOneAdjustmentQueryOptions(
    stockAdjustmentId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stockAdjustmentControllerUpdateAdjustment = (
  stockAdjustmentId: string,
  updateStockAdjustmentDto: UpdateStockAdjustmentDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.put(
    `/stock-adjustment/${stockAdjustmentId}`,
    updateStockAdjustmentDto,
    options
  );
};

export const getStockAdjustmentControllerUpdateAdjustmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerUpdateAdjustment>>,
    TError,
    { stockAdjustmentId: string; data: UpdateStockAdjustmentDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stockAdjustmentControllerUpdateAdjustment>>,
  TError,
  { stockAdjustmentId: string; data: UpdateStockAdjustmentDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stockAdjustmentControllerUpdateAdjustment>>,
    { stockAdjustmentId: string; data: UpdateStockAdjustmentDto }
  > = (props) => {
    const { stockAdjustmentId, data } = props ?? {};

    return stockAdjustmentControllerUpdateAdjustment(
      stockAdjustmentId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type StockAdjustmentControllerUpdateAdjustmentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stockAdjustmentControllerUpdateAdjustment>>
  >;
export type StockAdjustmentControllerUpdateAdjustmentMutationBody =
  UpdateStockAdjustmentDto;
export type StockAdjustmentControllerUpdateAdjustmentMutationError =
  AxiosError<unknown>;

export const useStockAdjustmentControllerUpdateAdjustment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerUpdateAdjustment>>,
    TError,
    { stockAdjustmentId: string; data: UpdateStockAdjustmentDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stockAdjustmentControllerUpdateAdjustment>>,
  TError,
  { stockAdjustmentId: string; data: UpdateStockAdjustmentDto },
  TContext
> => {
  const mutationOptions =
    getStockAdjustmentControllerUpdateAdjustmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stockAdjustmentControllerDeleteAdjustment = (
  stockAdjustmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/stock-adjustment/${stockAdjustmentId}`, options);
};

export const getStockAdjustmentControllerDeleteAdjustmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerDeleteAdjustment>>,
    TError,
    { stockAdjustmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stockAdjustmentControllerDeleteAdjustment>>,
  TError,
  { stockAdjustmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stockAdjustmentControllerDeleteAdjustment>>,
    { stockAdjustmentId: string }
  > = (props) => {
    const { stockAdjustmentId } = props ?? {};

    return stockAdjustmentControllerDeleteAdjustment(
      stockAdjustmentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type StockAdjustmentControllerDeleteAdjustmentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stockAdjustmentControllerDeleteAdjustment>>
  >;

export type StockAdjustmentControllerDeleteAdjustmentMutationError =
  AxiosError<unknown>;

export const useStockAdjustmentControllerDeleteAdjustment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockAdjustmentControllerDeleteAdjustment>>,
    TError,
    { stockAdjustmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stockAdjustmentControllerDeleteAdjustment>>,
  TError,
  { stockAdjustmentId: string },
  TContext
> => {
  const mutationOptions =
    getStockAdjustmentControllerDeleteAdjustmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const serializedInventoryControllerGetAvailableSerialInventories = (
  inventoryId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAvailableSerialInventoriesResponseDto>> => {
  return axios.get(`/serialized-inventory/${inventoryId}`, options);
};

export const getSerializedInventoryControllerGetAvailableSerialInventoriesQueryKey =
  (inventoryId: string) => {
    return [`/serialized-inventory/${inventoryId}`] as const;
  };

export const getSerializedInventoryControllerGetAvailableSerialInventoriesInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<
          typeof serializedInventoryControllerGetAvailableSerialInventories
        >
      >
    >,
    TError = AxiosError<unknown>
  >(
    inventoryId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof serializedInventoryControllerGetAvailableSerialInventories
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSerializedInventoryControllerGetAvailableSerialInventoriesQueryKey(
        inventoryId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof serializedInventoryControllerGetAvailableSerialInventories
        >
      >
    > = ({ signal }) =>
      serializedInventoryControllerGetAvailableSerialInventories(inventoryId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!inventoryId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<
          typeof serializedInventoryControllerGetAvailableSerialInventories
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SerializedInventoryControllerGetAvailableSerialInventoriesInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof serializedInventoryControllerGetAvailableSerialInventories
      >
    >
  >;
export type SerializedInventoryControllerGetAvailableSerialInventoriesInfiniteQueryError =
  AxiosError<unknown>;

export const useSerializedInventoryControllerGetAvailableSerialInventoriesInfinite =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<
          typeof serializedInventoryControllerGetAvailableSerialInventories
        >
      >
    >,
    TError = AxiosError<unknown>
  >(
    inventoryId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof serializedInventoryControllerGetAvailableSerialInventories
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getSerializedInventoryControllerGetAvailableSerialInventoriesInfiniteQueryOptions(
        inventoryId,
        options
      );

    const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
      TData,
      TError
    > & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

export const getSerializedInventoryControllerGetAvailableSerialInventoriesQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof serializedInventoryControllerGetAvailableSerialInventories
      >
    >,
    TError = AxiosError<unknown>
  >(
    inventoryId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof serializedInventoryControllerGetAvailableSerialInventories
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSerializedInventoryControllerGetAvailableSerialInventoriesQueryKey(
        inventoryId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof serializedInventoryControllerGetAvailableSerialInventories
        >
      >
    > = ({ signal }) =>
      serializedInventoryControllerGetAvailableSerialInventories(inventoryId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!inventoryId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof serializedInventoryControllerGetAvailableSerialInventories
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SerializedInventoryControllerGetAvailableSerialInventoriesQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof serializedInventoryControllerGetAvailableSerialInventories
      >
    >
  >;
export type SerializedInventoryControllerGetAvailableSerialInventoriesQueryError =
  AxiosError<unknown>;

export const useSerializedInventoryControllerGetAvailableSerialInventories = <
  TData = Awaited<
    ReturnType<
      typeof serializedInventoryControllerGetAvailableSerialInventories
    >
  >,
  TError = AxiosError<unknown>
>(
  inventoryId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof serializedInventoryControllerGetAvailableSerialInventories
          >
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSerializedInventoryControllerGetAvailableSerialInventoriesQueryOptions(
      inventoryId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
