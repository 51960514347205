import {CircularProgress} from "@mui/material";
import {useState} from "react";
import {AnalyticResponse} from "../../../redux/slices/Analytic/model/AnalyticReponse";
import {TabPanel} from "./AppTabPanel";

interface AppIFrameProps {
  analyticRes: AnalyticResponse;
  tabIdx: number;
  idx: number;
}

export default function AppIFrame({analyticRes, tabIdx, idx}: AppIFrameProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div>
      <TabPanel value={tabIdx} index={idx} key={analyticRes.iframeUrl}>
        {!isLoaded && (
          <div className="w-full h-32 items-center justify-center flex">
            <CircularProgress />
          </div>
        )}
        <iframe
          className="border-none"
          src={analyticRes.iframeUrl}
          width="100%"
          loading="lazy"
          height={analyticRes.settings.dashboardHeight}
          onLoad={() => {
            setIsLoaded(true);
          }}
        />
      </TabPanel>
    </div>
  );
}
