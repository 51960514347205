import { useSearchParams } from "react-router-dom";
import useDate from "../../../hooks/useDate";
import {
  PmFormatWithType,
  usePmChecklistControllerGetOnePmChecklistQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import AppItemInfo from "../../AppItemInfo";
import AppMultiImage from "../../AppMultiImage";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import {
  ATTACHMENT_TYPE,
  BOOL_TYPE,
  NUMBER_TYPE,
  VARCHAR_TYPE,
} from "./PmChecklistFormDrawer";
import {
  Calendar,
  CalendarClock,
  FileImage,
  Hash,
  MapPin,
  Tag,
  TextCursorInput,
  ToggleLeft,
} from "lucide-react";

export default function PmChecklistDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pmChecklistId = searchParams.get("pmChecklistId");
  const { formatDateToLocal, diffInDays } = useDate();

  const {
    data: pmChecklist,
    isLoading: pmChecklistIsLoading,
    pmFormats: pmFormats,
  } = usePmChecklistControllerGetOnePmChecklistQuery(
    {
      pmChecklistId: pmChecklistId ?? "",
    },
    {
      selectFromResult: (res) => {
        // let sortedPmFormat =
        //   res.data?.pmTemplate?.pmFormat?.sort((a, b) => a.order - b.order) ?? [];

        let sortedPmFormat = [] as PmFormatWithType[];

        if (res.data) {
          sortedPmFormat = [...res.data.pmTemplate.pmFormat].sort(
            (a, b) => a.order - b.order
          );
        }

        return {
          data: res.data,
          isLoading: res.isLoading,
          pmFormats: sortedPmFormat,
        };
      },
      skip: !pmChecklistId,
    }
  );

  if (pmChecklistIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <p className="font-sans text-2xl font-bold">PM Checklist</p>
      <div className="flex flex-col gap-2">
        <p className="text-gray-400">Status</p>
        <div className="flex">
          <div
            className="rounded-md py-2 px-10 mb-2 text-white font-bold"
            style={{ backgroundColor: `#${pmChecklist?.pmStatus?.colorHex}` }}
          >
            {pmChecklist?.pmStatus?.name}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <AppItemInfo
            label="Equipment Name"
            icon={<Tag className="text-primary-900" />}
            content={<p>{pmChecklist?.asset.name ?? "-"}</p>}
          />
          <AppItemInfo
            label="Location"
            icon={<MapPin className="text-primary-900" />}
            content={<p>{pmChecklist?.asset.location.name ?? "-"}</p>}
          />
          <AppItemInfo
            label="Created"
            icon={<Calendar className="text-primary-900" />}
            content={
              <p>
                {formatDateToLocal({
                  dateStr: pmChecklist?.createdOn ?? "",
                  formatting: "d MMM yyyy",
                })}
              </p>
            }
          />
          <AppItemInfo
            label="Aging Days"
            icon={<CalendarClock className="text-primary-900" />}
            content={
              <p>
                {pmChecklist?.completedOn
                  ? diffInDays({
                      startDateISO: pmChecklist?.createdOn,
                      endDateISO: pmChecklist?.completedOn,
                    })
                  : "-"}
              </p>
            }
          />
        </div>
        <p className="font-sans text-xl font-bold my-4">Checklist</p>
        <div className="flex flex-col gap-4">
          {pmFormats.map((pmF) => {
            switch (pmF.pmFormatType.name) {
              case VARCHAR_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<TextCursorInput className="text-primary-900" />}
                    content={
                      <p className="font-sans">
                        {pmChecklist?.pmChecklistText?.find(
                          (v) => v.pmFormatId === pmF.id
                        )?.value ?? "-"}
                      </p>
                    }
                  />
                );
              case NUMBER_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<Hash className="text-primary-900" />}
                    content={
                      <p className="font-sans">
                        {pmChecklist?.pmChecklistNumber?.find(
                          (v) => v.pmFormatId === pmF.id
                        )?.value ?? "-"}
                      </p>
                    }
                  />
                );
              case BOOL_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<ToggleLeft className="text-primary-900" />}
                    content={
                      <p className="font-sans">
                        {pmChecklist?.pmChecklistBool?.find(
                          (v) => v.pmFormatId === pmF.id
                        )?.value
                          ? "YES"
                          : "NO"}
                      </p>
                    }
                  />
                );
              case ATTACHMENT_TYPE:
                return (
                  <AppItemInfo
                    key={pmF.id}
                    label={pmF.name ?? "-"}
                    icon={<FileImage className="text-primary-900" />}
                    content={
                      <AppMultiImage
                        attachments={
                          pmChecklist?.pmChecklistAttachment?.find(
                            (v) => v.pmFormatId === pmF.id
                          )?.attachments
                        }
                      />
                    }
                  />
                );
            }
            return <div key={pmF.id}>{pmF.name}</div>;
          })}
        </div>
      </div>
    </div>
  );
}
