import { useSearchParams } from "react-router-dom";
import { useTnbBillControllerGetOneTnbReadingQuery } from "../../../redux/slices/OpenApi/cerevApi";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import useError from "../../../hooks/useError";
import useDate from "../../../hooks/useDate";
import AppItemInfo from "../../AppItemInfo";
import {
  AlignLeft,
  Calendar,
  CircleDollarSign,
  Files,
  Pencil,
  PlugZap,
  Tag,
} from "lucide-react";
import { ElectricBike } from "@mui/icons-material";
import { DRAWER_UPDATE_STATE, TNB_BILL_FORM_DRAWER } from "../AppDrawer";
import AppButton from "../../AppButton";
import AppDocument from "../../AppDocument";

export default function TnbBillDetailDrawer() {
  const { formatDateToLocal } = useDate();
  const [searchParam, setSearchParams] = useSearchParams();
  const tnbReadingId = searchParam.get("tnbReadingId");
  const { data, isLoading, isError, error } =
    useTnbBillControllerGetOneTnbReadingQuery(
      {
        tnbReadingId: tnbReadingId ? +tnbReadingId : 0,
      },
      {
        skip: !tnbReadingId,
      }
    );

  useError({
    dependencies: [{ isError, error }],
  });

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-4 items-center">
        <p className="font-sans text-2xl font-bold">TNB Reading Detail</p>
        <AppButton
          label="Edit"
          variant="outline"
          icon={<Pencil />}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", TNB_BILL_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              return p;
            });
          }}
        />
      </div>
      <AppItemInfo
        label="Bill Month"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {data?.billMonth &&
              formatDateToLocal({
                dateStr: data?.billMonth.toString(),
                formatting: "MMM yyyy",
              })}
          </p>
        }
      />
      <AppItemInfo
        label="Description"
        icon={<AlignLeft className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {data?.description ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Maximum Demand (kW)"
        icon={<PlugZap className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {data?.kiloWattMd ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Energy Usage (kWh)"
        icon={<PlugZap className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {data?.kiloWattHr ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Total Charges (RM)"
        icon={<CircleDollarSign className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {data?.totalBillCost ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Attachments"
        icon={<Files className="text-primary-900" />}
        content={
          <div className="flex flex-col gap-4">
            {data?.attachments?.length ? (
              data?.attachments.map((p) => <AppDocument key={p.id} att={p} />)
            ) : (
              <p className="font-sans text-neutral-900">-</p>
            )}
          </div>
        }
      />
    </div>
  );
}
