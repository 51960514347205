import { isBefore, parse } from "date-fns";
import { z } from "zod";

export const createWorkPermitInput = z
  .object({
    applierCompanyName: z
      .string()
      .min(1, { message: "Please enter the company name" }),
    email: z.string().email(),
    telNo: z.string().min(6, { message: "Please enter a valid phone number" }),
    description: z
      .string()
      .min(1, { message: "Please enter a description of the works" }),
    workStartDate: z.coerce.date({
      required_error: "You need to select a start date",
    }),
    workEndDate: z.coerce.date({
      required_error: "You need to select an end date",
    }),
    locationOfWork: z.string().min(1, { message: "Please enter a location" }),
  })
  .extend({
    projectCode: z.string(),
    workers: z
      .object({
        name: z.string(),
        icNo: z.string(),
      })
      .array()
      .nonempty({ message: "Must add at least one worker" }),
  })
  .refine(
    (data) => {
      // To ensure that end date is always later than start date,
      // Additional validation to be put under refine method of zod validation.
      const startDate = data.workStartDate;
      const endDate = data.workEndDate;
      if (isBefore(endDate, startDate)) return false;
      return true;
    },
    {
      message: "End date cannot be earlier than start date",
      path: ["workEndDate"],
    }
  );

export type CreateWorkPermitInput = z.infer<typeof createWorkPermitInput>;
