import { useParams } from "react-router-dom";
import { useWorkRequestControllerUpdateRatingWorkRequest } from "../../../api/work-request/work-request";
import { useEffect } from "react";
import { Loader2, ThumbsUp } from "lucide-react";

export default function WRReviewScreen() {
  const { rating, workRequestCode } = useParams();
  const { mutate, isPending, isSuccess } =
    useWorkRequestControllerUpdateRatingWorkRequest();

  useEffect(() => {
    if (!isSuccess && !isPending) {
      mutate({
        rating: rating as string,
        workRequestCode: workRequestCode as string,
      });
    }
  }, [rating, workRequestCode]);

  if (isSuccess) {
    return (
      <div className="w-screen flex flex-col space-y-6 items-center mt-16">
        <p className="text-slate-700 text-xl font-medium">
          Thank you for your feedback!
        </p>
        <p className="text-slate-400">
          Your maintenance team has received your feedback
        </p>
        <ThumbsUp className="text-primary-900 h-12 w-12" />
      </div>
    );
  }

  return (
    <div className="w-screen flex flex-col space-y-4 items-center mt-16">
      <p className="text-slate-700 text-xl font-medium">
        Submitting your review...
      </p>
      <Loader2 className="text-primary-900 animate-spin h-12 w-12" />
    </div>
  );
}
