import { useEffect } from "react";
import { PtwControllerGetPtwsPtwStatus } from "../../../../../api/model";

interface PtwStatusChipProps {
  ptwStatus?: PtwControllerGetPtwsPtwStatus;
}

export default function PtwStatusChip({ ptwStatus }: PtwStatusChipProps) {
  const getColor = (workPermitStatus: PtwControllerGetPtwsPtwStatus) => {
    switch (workPermitStatus) {
      case "NEW":
        return "#FFC107";
      case "APPROVED":
        return "#4CAF50";
      case "REJECTED":
        return "#F44336";
      case "CANCELLED":
        return "#F44336";
      default:
        return "#000000";
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="font-medium text-slate-400">Status</p>
      <div className="flex">
        <div
          className={`text-white px-8 py-2 rounded-xl font-sans text-center font-bold flex items-center`}
          style={{
            backgroundColor: getColor(ptwStatus ?? "NEW"),
          }}
        >
          {ptwStatus}
        </div>
      </div>
    </div>
  );
}
