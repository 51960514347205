import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import {
  usePtwControllerGetOnePtw,
  usePtwTemplateControllerGetOnePtwTemplate,
} from "../../api/permit-to-work-adv/permit-to-work-adv";
import { Document, Page, PDFViewer, Text } from "@react-pdf/renderer";
import WorkPermitPdf from "../../pdf/work-permit-pdf";
import QRCode from "qrcode";

export default function PermitToWorkScanQrCodeScreen() {
  const loc = useLocation();
  const { ptwTemplateId } = useParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data } = usePtwTemplateControllerGetOnePtwTemplate(
    ptwTemplateId as string,
    {
      query: {
        enabled: !!ptwTemplateId,
        select: (res) => res.data.data,
      },
    }
  );

  const qrCodeUrl = `${window.location.origin}/public/work-permit/${activeProj?.workPermitCode}/${ptwTemplateId}`;

  return (
    <PDFViewer style={{ height: "100vh", width: "100vw" }}>
      <WorkPermitPdf
        projectName={activeProj?.name ?? ""}
        qrCodeUrl={QRCode.toDataURL(qrCodeUrl)}
        ptwTemplateName={data?.name ?? ""}
      />
    </PDFViewer>
  );
}
