/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateRoleDto,
  GetProjWithRolesDto,
  GetRolesResponseDto,
  Role,
  RolesControllerGetRolesParams,
  UpdateRoleDto,
} from ".././model";

export const rolesControllerGetAllRoles = (
  companyId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetProjWithRolesDto[]>> => {
  return axios.get(`/roles/all/${companyId}`, options);
};

export const getRolesControllerGetAllRolesQueryKey = (companyId: number) => {
  return [`/roles/all/${companyId}`] as const;
};

export const getRolesControllerGetAllRolesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof rolesControllerGetAllRoles>>>,
  TError = AxiosError<unknown>
>(
  companyId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRolesControllerGetAllRolesQueryKey(companyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rolesControllerGetAllRoles>>
  > = ({ signal }) =>
    rolesControllerGetAllRoles(companyId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!companyId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RolesControllerGetAllRolesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerGetAllRoles>>
>;
export type RolesControllerGetAllRolesInfiniteQueryError = AxiosError<unknown>;

export const useRolesControllerGetAllRolesInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof rolesControllerGetAllRoles>>>,
  TError = AxiosError<unknown>
>(
  companyId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRolesControllerGetAllRolesInfiniteQueryOptions(
    companyId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRolesControllerGetAllRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
  TError = AxiosError<unknown>
>(
  companyId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRolesControllerGetAllRolesQueryKey(companyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rolesControllerGetAllRoles>>
  > = ({ signal }) =>
    rolesControllerGetAllRoles(companyId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!companyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RolesControllerGetAllRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerGetAllRoles>>
>;
export type RolesControllerGetAllRolesQueryError = AxiosError<unknown>;

export const useRolesControllerGetAllRoles = <
  TData = Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
  TError = AxiosError<unknown>
>(
  companyId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetAllRoles>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRolesControllerGetAllRolesQueryOptions(
    companyId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const rolesControllerGetRoles = (
  params: RolesControllerGetRolesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetRolesResponseDto[]>> => {
  return axios.get(`/roles`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getRolesControllerGetRolesQueryKey = (
  params: RolesControllerGetRolesParams
) => {
  return [`/roles`, ...(params ? [params] : [])] as const;
};

export const getRolesControllerGetRolesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof rolesControllerGetRoles>>,
    RolesControllerGetRolesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: RolesControllerGetRolesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetRoles>>,
        TError,
        TData,
        Awaited<ReturnType<typeof rolesControllerGetRoles>>,
        QueryKey,
        RolesControllerGetRolesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRolesControllerGetRolesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rolesControllerGetRoles>>,
    QueryKey,
    RolesControllerGetRolesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    rolesControllerGetRoles(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof rolesControllerGetRoles>>,
    TError,
    TData,
    Awaited<ReturnType<typeof rolesControllerGetRoles>>,
    QueryKey,
    RolesControllerGetRolesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type RolesControllerGetRolesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerGetRoles>>
>;
export type RolesControllerGetRolesInfiniteQueryError = AxiosError<unknown>;

export const useRolesControllerGetRolesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof rolesControllerGetRoles>>,
    RolesControllerGetRolesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: RolesControllerGetRolesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetRoles>>,
        TError,
        TData,
        Awaited<ReturnType<typeof rolesControllerGetRoles>>,
        QueryKey,
        RolesControllerGetRolesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRolesControllerGetRolesInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRolesControllerGetRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof rolesControllerGetRoles>>,
  TError = AxiosError<unknown>
>(
  params: RolesControllerGetRolesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetRoles>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRolesControllerGetRolesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rolesControllerGetRoles>>
  > = ({ signal }) =>
    rolesControllerGetRoles(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof rolesControllerGetRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RolesControllerGetRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerGetRoles>>
>;
export type RolesControllerGetRolesQueryError = AxiosError<unknown>;

export const useRolesControllerGetRoles = <
  TData = Awaited<ReturnType<typeof rolesControllerGetRoles>>,
  TError = AxiosError<unknown>
>(
  params: RolesControllerGetRolesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetRoles>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRolesControllerGetRolesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const rolesControllerCreateRole = (
  createRoleDto: CreateRoleDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Role | void>> => {
  return axios.post(`/roles`, createRoleDto, options);
};

export const getRolesControllerCreateRoleMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rolesControllerCreateRole>>,
    TError,
    { data: CreateRoleDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rolesControllerCreateRole>>,
  TError,
  { data: CreateRoleDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rolesControllerCreateRole>>,
    { data: CreateRoleDto }
  > = (props) => {
    const { data } = props ?? {};

    return rolesControllerCreateRole(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RolesControllerCreateRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerCreateRole>>
>;
export type RolesControllerCreateRoleMutationBody = CreateRoleDto;
export type RolesControllerCreateRoleMutationError = AxiosError<unknown>;

export const useRolesControllerCreateRole = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rolesControllerCreateRole>>,
    TError,
    { data: CreateRoleDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof rolesControllerCreateRole>>,
  TError,
  { data: CreateRoleDto },
  TContext
> => {
  const mutationOptions = getRolesControllerCreateRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rolesControllerGetOneRole = (
  roleId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Role>> => {
  return axios.get(`/roles/${roleId}`, options);
};

export const getRolesControllerGetOneRoleQueryKey = (roleId: string) => {
  return [`/roles/${roleId}`] as const;
};

export const getRolesControllerGetOneRoleInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof rolesControllerGetOneRole>>>,
  TError = AxiosError<unknown>
>(
  roleId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRolesControllerGetOneRoleQueryKey(roleId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rolesControllerGetOneRole>>
  > = ({ signal }) =>
    rolesControllerGetOneRole(roleId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!roleId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RolesControllerGetOneRoleInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerGetOneRole>>
>;
export type RolesControllerGetOneRoleInfiniteQueryError = AxiosError<unknown>;

export const useRolesControllerGetOneRoleInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof rolesControllerGetOneRole>>>,
  TError = AxiosError<unknown>
>(
  roleId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRolesControllerGetOneRoleInfiniteQueryOptions(
    roleId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRolesControllerGetOneRoleQueryOptions = <
  TData = Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
  TError = AxiosError<unknown>
>(
  roleId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRolesControllerGetOneRoleQueryKey(roleId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof rolesControllerGetOneRole>>
  > = ({ signal }) =>
    rolesControllerGetOneRole(roleId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!roleId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RolesControllerGetOneRoleQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerGetOneRole>>
>;
export type RolesControllerGetOneRoleQueryError = AxiosError<unknown>;

export const useRolesControllerGetOneRole = <
  TData = Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
  TError = AxiosError<unknown>
>(
  roleId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof rolesControllerGetOneRole>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRolesControllerGetOneRoleQueryOptions(
    roleId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const rolesControllerUpdateRoleStatus = (
  roleId: number,
  updateRoleDto: UpdateRoleDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Role>> => {
  return axios.patch(`/roles/${roleId}`, updateRoleDto, options);
};

export const getRolesControllerUpdateRoleStatusMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rolesControllerUpdateRoleStatus>>,
    TError,
    { roleId: number; data: UpdateRoleDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rolesControllerUpdateRoleStatus>>,
  TError,
  { roleId: number; data: UpdateRoleDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rolesControllerUpdateRoleStatus>>,
    { roleId: number; data: UpdateRoleDto }
  > = (props) => {
    const { roleId, data } = props ?? {};

    return rolesControllerUpdateRoleStatus(roleId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RolesControllerUpdateRoleStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof rolesControllerUpdateRoleStatus>>
>;
export type RolesControllerUpdateRoleStatusMutationBody = UpdateRoleDto;
export type RolesControllerUpdateRoleStatusMutationError = AxiosError<unknown>;

export const useRolesControllerUpdateRoleStatus = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rolesControllerUpdateRoleStatus>>,
    TError,
    { roleId: number; data: UpdateRoleDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof rolesControllerUpdateRoleStatus>>,
  TError,
  { roleId: number; data: UpdateRoleDto },
  TContext
> => {
  const mutationOptions =
    getRolesControllerUpdateRoleStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
