/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  DefectStatus,
  DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams,
  UpdateUsersToBeNotifiedRequestDto,
} from ".././model";

export const defectStatusV2ControllerUpdateUsersToBeNotified = (
  defectStatusId: string,
  updateUsersToBeNotifiedRequestDto: UpdateUsersToBeNotifiedRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/defect-status/${defectStatusId}/users-notified`,
    updateUsersToBeNotifiedRequestDto,
    options
  );
};

export const getDefectStatusV2ControllerUpdateUsersToBeNotifiedMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof defectStatusV2ControllerUpdateUsersToBeNotified>
      >,
      TError,
      { defectStatusId: string; data: UpdateUsersToBeNotifiedRequestDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof defectStatusV2ControllerUpdateUsersToBeNotified>>,
    TError,
    { defectStatusId: string; data: UpdateUsersToBeNotifiedRequestDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof defectStatusV2ControllerUpdateUsersToBeNotified>
      >,
      { defectStatusId: string; data: UpdateUsersToBeNotifiedRequestDto }
    > = (props) => {
      const { defectStatusId, data } = props ?? {};

      return defectStatusV2ControllerUpdateUsersToBeNotified(
        defectStatusId,
        data,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DefectStatusV2ControllerUpdateUsersToBeNotifiedMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof defectStatusV2ControllerUpdateUsersToBeNotified>>
  >;
export type DefectStatusV2ControllerUpdateUsersToBeNotifiedMutationBody =
  UpdateUsersToBeNotifiedRequestDto;
export type DefectStatusV2ControllerUpdateUsersToBeNotifiedMutationError =
  AxiosError<unknown>;

export const useDefectStatusV2ControllerUpdateUsersToBeNotified = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectStatusV2ControllerUpdateUsersToBeNotified>>,
    TError,
    { defectStatusId: string; data: UpdateUsersToBeNotifiedRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof defectStatusV2ControllerUpdateUsersToBeNotified>>,
  TError,
  { defectStatusId: string; data: UpdateUsersToBeNotifiedRequestDto },
  TContext
> => {
  const mutationOptions =
    getDefectStatusV2ControllerUpdateUsersToBeNotifiedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const defectStatusV2ControllerGetProjectBasedWorkOrderStatus = (
  params: DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectStatus[]>> => {
  return axios.get(`/v2/defect-status`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQueryKey =
  (params: DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams) => {
    return [`/v2/defect-status`, ...(params ? [params] : [])] as const;
  };

export const getDefectStatusV2ControllerGetProjectBasedWorkOrderStatusInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<
          typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
        >
      >,
      DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
            >
          >,
          TError,
          TData,
          Awaited<
            ReturnType<
              typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
            >
          >,
          QueryKey,
          DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
        >
      >,
      QueryKey,
      DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams["cursorId"]
    > = ({ signal, pageParam }) =>
      defectStatusV2ControllerGetProjectBasedWorkOrderStatus(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<
          typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
        >
      >,
      TError,
      TData,
      Awaited<
        ReturnType<
          typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
        >
      >,
      QueryKey,
      DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type DefectStatusV2ControllerGetProjectBasedWorkOrderStatusInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus>
    >
  >;
export type DefectStatusV2ControllerGetProjectBasedWorkOrderStatusInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectStatusV2ControllerGetProjectBasedWorkOrderStatusInfinite =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<
          typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
        >
      >,
      DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
            >
          >,
          TError,
          TData,
          Awaited<
            ReturnType<
              typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
            >
          >,
          QueryKey,
          DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getDefectStatusV2ControllerGetProjectBasedWorkOrderStatusInfiniteQueryOptions(
        params,
        options
      );

    const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
      TData,
      TError
    > & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

export const getDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus>
    >,
    TError = AxiosError<unknown>
  >(
    params: DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
        >
      >
    > = ({ signal }) =>
      defectStatusV2ControllerGetProjectBasedWorkOrderStatus(params, {
        signal,
        ...axiosOptions,
      });

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type DefectStatusV2ControllerGetProjectBasedWorkOrderStatusQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus>
    >
  >;
export type DefectStatusV2ControllerGetProjectBasedWorkOrderStatusQueryError =
  AxiosError<unknown>;

export const useDefectStatusV2ControllerGetProjectBasedWorkOrderStatus = <
  TData = Awaited<
    ReturnType<typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus>
  >,
  TError = AxiosError<unknown>
>(
  params: DefectStatusV2ControllerGetProjectBasedWorkOrderStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof defectStatusV2ControllerGetProjectBasedWorkOrderStatus
          >
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
