export default function WorkPermitLogo() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <g clipPath="url(#clip0_2_2)">
        <path
          d="M24.9975 49.9951C38.8033 49.9951 49.9951 38.8033 49.9951 24.9975C49.9951 11.1918 38.8033 0 24.9975 0C11.1918 0 0 11.1918 0 24.9975C0 38.8033 11.1918 49.9951 24.9975 49.9951Z"
          fill="#A2DAE0"
        />
        <path
          d="M24.6535 40.9859C37.7644 40.9859 48.3928 40.1916 48.3928 39.2116C48.3928 38.2317 37.7644 37.4373 24.6535 37.4373C11.5426 37.4373 0.914185 38.2317 0.914185 39.2116C0.914185 40.1916 11.5426 40.9859 24.6535 40.9859Z"
          fill="#85B6D6"
        />
        <path
          d="M21.2917 19.2863C20.8481 19.2627 20.4305 19.0699 20.1249 18.7476C19.8193 18.4252 19.649 17.998 19.649 17.5538C19.649 17.1096 19.8193 16.6824 20.1249 16.3601C20.4305 16.0378 20.8481 15.8449 21.2917 15.8213H22.0142V13.7226H9.0632C8.13849 13.7226 7.25135 14.0886 6.59564 14.7406C5.93992 15.3926 5.56894 16.2777 5.56374 17.2024V35.9776C5.56374 36.9057 5.93244 37.7958 6.58871 38.4521C7.24499 39.1084 8.13509 39.477 9.0632 39.477H25.2826V19.2863H21.2917ZM23.2085 35.4763C23.2085 35.7013 23.1641 35.9241 23.0778 36.1319C22.9916 36.3398 22.8651 36.5285 22.7058 36.6874C22.5464 36.8463 22.3573 36.9722 22.1492 37.0579C21.9411 37.1436 21.7182 37.1873 21.4932 37.1867H10.2772C9.82357 37.1867 9.38852 37.0065 9.06776 36.6857C8.74699 36.3649 8.56679 35.9299 8.56679 35.4763V22.9677C8.56614 22.7426 8.60991 22.5197 8.69558 22.3116C8.78124 22.1035 8.90713 21.9144 9.06602 21.755C9.22491 21.5957 9.41368 21.4693 9.62152 21.383C9.82936 21.2967 10.0522 21.2523 10.2772 21.2523H21.4932C21.9481 21.2523 22.3844 21.4331 22.7061 21.7547C23.0278 22.0764 23.2085 22.5127 23.2085 22.9677V35.4763Z"
          fill="#E5E5E5"
        />
        <path
          d="M10.2821 21.2523H21.4932C21.9481 21.2523 22.3844 21.4331 22.7061 21.7547C23.0278 22.0764 23.2085 22.5127 23.2085 22.9677V35.4763C23.2085 35.9299 23.0283 36.3649 22.7075 36.6857C22.3868 37.0065 21.9517 37.1867 21.4981 37.1867H10.2821C10.0569 37.1867 9.83381 37.1423 9.62569 37.0561C9.41758 36.9699 9.22849 36.8435 9.0692 36.6843C8.90992 36.525 8.78357 36.3359 8.69737 36.1278C8.61116 35.9197 8.5668 35.6966 8.5668 35.4713V22.9677C8.5668 22.7424 8.61116 22.5193 8.69737 22.3112C8.78357 22.1031 8.90992 21.914 9.0692 21.7547C9.22849 21.5955 9.41758 21.4691 9.62569 21.3829C9.83381 21.2967 10.0569 21.2523 10.2821 21.2523Z"
          fill="white"
        />
        <path
          d="M25.258 6.12405V18.0429H23.8622C23.3763 18.0429 22.9099 17.8516 22.564 17.5103C22.2182 17.169 22.0206 16.7053 22.0142 16.2194V7.97208C22.0142 7.48195 22.2089 7.0119 22.5554 6.66533C22.902 6.31876 23.3721 6.12405 23.8622 6.12405H25.258Z"
          fill="#AB2200"
        />
        <path
          d="M15.8213 33.9428C18.1313 33.9428 20.0039 32.1098 20.0039 29.8486C20.0039 27.5875 18.1313 25.7545 15.8213 25.7545C13.5113 25.7545 11.6386 27.5875 11.6386 29.8486C11.6386 32.1098 13.5113 33.9428 15.8213 33.9428Z"
          fill="#FFB69F"
        />
        <path
          d="M20.1514 29.2883C20.1506 29.6195 20.111 29.9494 20.0334 30.2713H11.6239C11.5503 29.9488 11.5124 29.6191 11.5109 29.2883C11.5043 29.1803 11.5043 29.072 11.5109 28.9639C11.5109 28.9639 11.5109 28.9639 11.5109 28.9246C11.6397 28.0077 12.0399 27.1503 12.6601 26.4627C13.2802 25.7751 14.0919 25.2888 14.9907 25.0664C15.2624 25.0108 15.539 24.9828 15.8164 24.9828C16.0953 24.9831 16.3736 25.0111 16.647 25.0664C17.5633 25.2518 18.3954 25.7278 19.0196 26.4238C19.6439 27.1198 20.027 27.9985 20.1121 28.9295C20.1219 29.0426 20.1514 29.1654 20.1514 29.2883Z"
          fill="#F0C020"
          stroke="#F29500"
          strokeMiterlimit="10"
        />
        <path
          d="M16.706 25.3858C16.706 24.8945 16.3088 24.4962 15.8188 24.4962C15.3289 24.4962 14.9317 24.8945 14.9317 25.3858V29.0622C14.9317 29.5535 15.3289 29.9518 15.8188 29.9518C16.3088 29.9518 16.706 29.5535 16.706 29.0622V25.3858Z"
          fill="#F0C020"
          stroke="#F29500"
          strokeMiterlimit="10"
        />
        <path
          d="M20.9181 29.6078C20.92 29.6987 20.9035 29.7891 20.8697 29.8735C20.836 29.9579 20.7856 30.0347 20.7215 30.0993C20.5907 30.2286 20.414 30.301 20.23 30.3008H11.5354C11.3551 30.2995 11.1826 30.2269 11.0555 30.099C10.9285 29.971 10.8572 29.798 10.8572 29.6176C10.855 29.5267 10.8714 29.4362 10.9051 29.3517C10.9389 29.2673 10.9895 29.1905 11.0538 29.1261C11.1184 29.0618 11.1951 29.011 11.2794 28.9764C11.3638 28.9418 11.4541 28.9242 11.5453 28.9246H20.2349C20.4161 28.9246 20.5899 28.9966 20.718 29.1247C20.8461 29.2528 20.9181 29.4266 20.9181 29.6078Z"
          fill="#F0C020"
          stroke="#F29500"
          strokeMiterlimit="10"
        />
        <path
          d="M14.8236 31.8294C14.9248 32.0408 15.0883 32.2163 15.2921 32.3323C15.4959 32.4482 15.7303 32.4991 15.9638 32.4781C16.1672 32.454 16.3603 32.3756 16.5229 32.251C16.6855 32.1265 16.8116 31.9605 16.8878 31.7704"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M28.5068 7.97208V16.2194C28.5068 16.7095 28.3121 17.1796 27.9656 17.5262C27.619 17.8727 27.1489 18.0674 26.6588 18.0674H25.2826V6.12405H26.6588C27.1489 6.12405 27.619 6.31876 27.9656 6.66533C28.3121 7.0119 28.5068 7.48195 28.5068 7.97208Z"
          fill="#CD2900"
        />
        <path
          d="M44.4313 17.2024V35.9776C44.432 36.4369 44.3421 36.8919 44.1667 37.3164C43.9914 37.7409 43.7341 38.1268 43.4095 38.4518C43.085 38.7768 42.6995 39.0346 42.2752 39.2106C41.8509 39.3865 41.3961 39.477 40.9368 39.477H25.258V19.2863H29.19C29.4253 19.2989 29.6607 19.2634 29.8818 19.182C30.1029 19.1005 30.3051 18.975 30.4761 18.8128C30.647 18.6507 30.7832 18.4555 30.8763 18.239C30.9693 18.0226 31.0173 17.7894 31.0173 17.5538C31.0173 17.3182 30.9693 17.0851 30.8763 16.8686C30.7832 16.6521 30.647 16.4569 30.4761 16.2948C30.3051 16.1327 30.1029 16.0071 29.8818 15.9257C29.6607 15.8443 29.4253 15.8088 29.19 15.8213H28.5068V13.7226H40.9368C41.8611 13.7226 42.7477 14.0887 43.4026 14.7409C44.0575 15.3931 44.4274 16.2781 44.4313 17.2024Z"
          fill="#D8D7DA"
        />
        <path
          d="M28.2906 25.5136H34.8471"
          stroke="#808080"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M28.1038 30.7235H39.4672"
          stroke="#808080"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_2">
          <rect
            width="50"
            height="50"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
