import { Controller } from "react-hook-form";
import { TimePickerInput } from "../../../datetimePicker/TimePickerInput";

interface DayTimeRangeInputProps {
  dayKey: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
}

export default function DayTimeRangeInput({ dayKey }: DayTimeRangeInputProps) {
  const getDayValue = ({ dayKey }: DayTimeRangeInputProps) => {
    switch (dayKey) {
      case "mon":
        return "Monday";
      case "tue":
        return "Tuesday";
      case "wed":
        return "Wednesday";
      case "thu":
        return "Thursday";
      case "fri":
        return "Friday";
      case "sat":
        return "Saturday";
      case "sun":
        return "Sunday";
    }
  };

  return (
    <div className="flex items-center gap-4">
      <div className="min-w-[150px]">
        <p className="font-medium text-sm">{getDayValue({ dayKey })}</p>
      </div>
      <Controller
        name={`${dayKey}.${dayKey}NormStart`}
        render={({ field, fieldState: { error } }) => (
          <div>
            <div className="flex gap-2">
              <TimePickerInput
                picker="hours"
                date={field.value}
                setDate={field.onChange}
                className="focus-visible:ring-primary-900"
              />
              <TimePickerInput
                picker="minutes"
                date={field.value}
                setDate={field.onChange}
                className="focus-visible:ring-primary-900"
              />
            </div>
            {error && <p className="text-red-500 text-xs">{error.message}</p>}
          </div>
        )}
      />
      <div>-</div>
      <Controller
        name={`${dayKey}.${dayKey}NormEnd`}
        render={({ field }) => (
          <div className="flex gap-2">
            <TimePickerInput
              picker="hours"
              date={field.value}
              setDate={field.onChange}
              className="focus-visible:ring-primary-900"
            />
            <TimePickerInput
              picker="minutes"
              date={field.value}
              setDate={field.onChange}
              className="focus-visible:ring-primary-900"
            />
          </div>
        )}
      />
    </div>
  );
}
