import {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import MainPageSkeleton from "../components/skeletons/MainPageSkeleton";
import useAccountType, {SUPER_ADMIN_ACC_TYPE} from "../hooks/useAccountType";

export default function SuperAdminProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const {hasAccess, isLoading} = useAccountType({
    accType: SUPER_ADMIN_ACC_TYPE,
  });

  if (isLoading) return <MainPageSkeleton />;
  if (!hasAccess && !isLoading) return <Navigate to="/home/403" replace/>;
  return <>{children}</>;
}
