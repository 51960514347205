import { AccessPermission, CerevModule } from "../api/model";
import { useModuleAccessControllerGetUserHasAccessModule } from "../api/module-access/module-access";
import useCurrentUser from "../hooks/useCurrentUser/useCurrentUser";
import { useAppSelector } from "../redux/store";

interface ModuleAccessGuardProps {
  children: React.ReactNode;
  module: CerevModule;
}

export default function ModuleAccessGuard({
  children,
  module,
}: ModuleAccessGuardProps) {
  const user = useCurrentUser();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: access, isLoading } =
    useModuleAccessControllerGetUserHasAccessModule(
      user.data?.id?.toString() ?? "",
      {
        module,
        projectId: activeProj?.id ?? 0,
      },
      {
        query: {
          enabled: !!activeProj && !!user.data,
          select: (res) => res.data,
          staleTime: 1000 * 60 * 30,
        },
      }
    );

  if (access?.access !== AccessPermission.ALLOWED && !isLoading)
    return <>No Access</>;

  return children;
}
