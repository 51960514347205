import { useFormContext, useWatch } from "react-hook-form";
import { EscalationForm } from "../EscalationFormSchema";
import { Label } from "../../../ui/label";
import SettingSummary from "./SettingSummary";
import { EscalationType } from "../../../../api/model";

export default function EscalationSettingSummary() {
  const methods = useFormContext<EscalationForm>();

  const escalationType = useWatch({
    control: methods.control,
    name: "escalationStatuses.escalationType",
  });

  const statuses = useWatch({
    control: methods.control,
    name:
      escalationType === EscalationType.WORK_ORDER
        ? "escalationStatuses.workOrderStatuses"
        : EscalationType.PM
        ? "escalationStatuses.pmStatuses"
        : "escalationStatuses.workRequestStatuses",
  });

  const noOfDays = useWatch({ control: methods.control, name: "numberOfDays" });

  const notifyUsers = useWatch({
    control: methods.control,
    name: "notifyUsers",
  });

  return (
    <div className="space-y-1">
      <Label>Summary</Label>
      <SettingSummary
        escalationType={escalationType}
        statusStrs={statuses.map((s) => s.name)}
        noOfDays={noOfDays}
        notifyUsersString={notifyUsers.map((s) => s.name)}
      />
    </div>
  );
}
