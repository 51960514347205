import { Filter } from "lucide-react";
import { Card } from "../../../../components/ui/card";
import {
  PdfReportType,
  usePdfReportActions,
  usePdfReportDateRange,
  usePdfReportReportType,
} from "../../../../stores/pdfReportStore/pdfReportStore";
import { useCallback } from "react";
import { AppDateRangePicker } from "../../../../components/AppDateRangePicker";
import WorkOrderSummaryFilter from "./WorkOrderSummaryFilter";

export default function FilterSection() {
  const pdfReportType = usePdfReportReportType();

  const renderContent = useCallback(() => {
    switch (pdfReportType) {
      case PdfReportType.WORK_ORDER_SUMMARY:
        return <WorkOrderSummaryFilter />;
      case PdfReportType.INITIAL:
      default:
        return (
          <div className="h-full flex items-center justify-center flex-col">
            <Filter className="text-gray-400" />
            <p className="text-gray-400 text-center">No filters available</p>
          </div>
        );
    }
  }, [pdfReportType]);

  return (
    <div className="py-2 pr-2 h-full">
      <Card className="h-full p-4">
        <h3 className="text-lg font-bold">Filters</h3>
        {renderContent()}
      </Card>
    </div>
  );
}
