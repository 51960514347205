import { AlignLeft, CircleDollarSign, FileText, Hash } from "lucide-react";
import AppItemInfo from "../../../../../AppItemInfo";
import UpdatePricingBtnDialog from "./UpdatePricingBtnDialog";
import { useSearchParams } from "react-router-dom";
import { useDefectControllerGetOneDefect } from "../../../../../../api/work-orders-defects/work-orders-defects";
import AppDocument from "../../../../../AppDocument";
import AppPricingDocument from "./AppPricingDocument";
import { Textarea } from "../../../../../ui/textarea";
import Decimal from "decimal.js";

export default function PricingSection() {
  const [searchParam] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");
  const { data: workOrder, isLoading } = useDefectControllerGetOneDefect(
    activeWoId ?? "",
    {
      query: {
        select: (res) => res.data,
      },
    }
  );

  const profitCalculated = new Decimal(workOrder?.costing ?? 0)
    .minus(new Decimal(workOrder?.labourCosting ?? 0))
    .minus(new Decimal(workOrder?.partCosting ?? 0))
    .minus(new Decimal(workOrder?.milleageCosting ?? 0));

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <p className="text-xl font-bold col-span-2">Pricing</p>
        <AppItemInfo
          label="Revenue of this work order (RM)"
          icon={<CircleDollarSign className="text-primary-900" />}
          content={<p className="font-sans">{workOrder?.costing ?? "-"}</p>}
        />
        <AppItemInfo
          label="Document Ref. No"
          icon={<Hash className="text-primary-900" />}
          content={
            <p className="font-sans">{workOrder?.costingRefNo ?? "-"}</p>
          }
        />
        <AppItemInfo
          label="Labour Costing (RM)"
          icon={<CircleDollarSign className="text-primary-900" />}
          content={
            <p className="font-sans">{workOrder?.labourCosting ?? "-"}</p>
          }
        />
        <AppItemInfo
          label="Part Costing (RM)"
          icon={<CircleDollarSign className="text-primary-900" />}
          content={<p className="font-sans">{workOrder?.partCosting ?? "-"}</p>}
        />
        <AppItemInfo
          label="Milleage Costing (RM)"
          icon={<CircleDollarSign className="text-primary-900" />}
          content={
            <p className="font-sans">{workOrder?.milleageCosting ?? "-"}</p>
          }
        />
        <AppItemInfo
          label="Estimated P&L (RM)"
          icon={<CircleDollarSign className="text-primary-900" />}
          content={<p className="font-sans">{profitCalculated.toFixed(2)}</p>}
        />
        <div className="col-span-2">
          <AppItemInfo
            label="Documents"
            icon={<FileText />}
            content={
              workOrder?.pricingAttachment &&
              workOrder?.pricingAttachment?.length > 0 ? (
                <>
                  {workOrder?.pricingAttachment?.map((att) => (
                    <AppPricingDocument key={att.id} att={att} />
                  ))}
                </>
              ) : (
                <>-</>
              )
            }
          />
        </div>
        <div className="col-span-2">
          <AppItemInfo
            label="Remarks"
            icon={<AlignLeft />}
            content={
              <Textarea
                disabled
                value={workOrder?.costingRemarks ?? "-"}
                className="disabled:cursor-default border-0 text-slate-900 disabled:text-slate-900 disabled:opacity-95 pt-2 p-0 h-fit"
              />
            }
          />
        </div>
      </div>
      <div>
        <UpdatePricingBtnDialog workOrder={workOrder} />
      </div>
    </>
  );
}
