import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Avatar, AvatarFallback } from "../../../../components/ui/avatar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { setSelectedLocation } from "../../../../redux/slices/Asset/AssetSlice";
import { useGetUserDataQuery } from "../../../../redux/slices/Auth/AuthApi";
import { Project } from "../../../../redux/slices/OpenApi/cerevApi";
import { setActiveProject } from "../../../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import NotificationBtn from "./components/NotificationBtn";
import SettingsBtn from "./components/SettingsBtn";
import SuperAdminBtn from "./components/SuperAdminBtn";
import AppProjectSelect from "../../../../components/AppProjectSelect";

export default function CerevAppBar() {
  const location = useLocation();

  const { data: userData } = useGetUserDataQuery();

  return (
    <div className="flex justify-between mr-4">
      {!location.pathname.includes("settings") && <AppProjectSelect />}
      {userData && (
        <div className="flex gap-4 items-center">
          <Avatar className="bg-primary-900">
            <AvatarFallback className="bg-transparent text-white">
              {userData.name?.substring(0, 2).toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <p className="font-sans text-neutral-800">{userData.name}</p>
          <NotificationBtn />
          <SettingsBtn />
          <SuperAdminBtn />
        </div>
      )}
    </div>
  );
}
