import { EscalationLog, EscalationType } from "../../../../../../api/model";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../../ui/dialog";
import { DataTable } from "../../../../../react-table/DataTable";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { escalationLogColumns } from "./escalationLogColumns";
import { useEffect } from "react";

interface EscalationLogDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  escalationLogs: EscalationLog[];
  escalationType?: EscalationType;
}

export default function EscalationLogDialog({
  open,
  setOpen,
  escalationLogs,
  escalationType,
}: EscalationLogDialogProps) {
  const table = useReactTable({
    data: escalationLogs,
    columns: escalationLogColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
        workOrders: escalationType === EscalationType.WORK_ORDER,
        pmChecklists: escalationType === EscalationType.PM,
        workRequests: escalationType === EscalationType.WORK_REQUEST,
      },
    },
  });

  if (!escalationType) return <></>;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="overflow-y-scroll max-h-screen">
        <DialogHeader>
          <DialogTitle>Escalation Logs</DialogTitle>
        </DialogHeader>
        <DataTable
          isLoading={false}
          isFetching={false}
          table={table}
          totalCount={escalationLogs.length}
        />
      </DialogContent>
    </Dialog>
  );
}
