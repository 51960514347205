import { useQueryClient } from "@tanstack/react-query";
import { useWorkOrderV2ControllerRemoveLinkedWorkRequest } from "../../../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import AppButton from "../../../../../../../AppButton";
import { Trash2 } from "lucide-react";
import { WorkRequestWithPhotos } from "../../../../../../../../api/model";
import { useSearchParams } from "react-router-dom";

interface RemoveBtnProps {
  wr: WorkRequestWithPhotos;
}

export default function RemoveBtn({ wr }: RemoveBtnProps) {
  const [searchParam] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");

  const qc = useQueryClient();
  const { mutate: removeLinkedWorkReq, isPending: unlinkIsPending } =
    useWorkOrderV2ControllerRemoveLinkedWorkRequest({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("work-order") ||
              (q.queryKey[0] as string).includes("work-request") ||
              (q.queryKey[0] as string).includes("defect") ||
              (q.queryKey[0] as string).includes("comments"),
          });
        },
      },
    });

  return (
    <AppButton
      data-testid="wr-remove-btn"
      className="text-red-500"
      isLoading={unlinkIsPending}
      variant="ghost"
      icon={<Trash2 className="mr-2" />}
      onClick={(e) => {
        e?.stopPropagation();
        removeLinkedWorkReq({
          workOrderId: activeWoId ?? "",
          workRequestId: wr.id.toString(),
        });
      }}
    />
  );
}
