import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Clock, Users2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { useWoTimeLogsControllerReadTimeLog } from "../../../../../api/wo-time-logs/wo-time-logs";
import { useDefectControllerGetOneDefect } from "../../../../../api/work-orders-defects/work-orders-defects";
import { DataTable } from "../../../../react-table/DataTable";
import DrawerFormSkeleton from "../../../../skeletons/DrawerFormSkeleton";
import IsCloseAndEditAfterWorkOrderGuard from "../../components/IsCloseAndEditAfterWorkOrderGuard";
import CreateTimeLogBtn from "./CreateTimeLogBtn";
import ManHourCardSummary from "./ManHourCardSummary";
import { manHourColumns } from "./ManHourColumns";

export default function ManHourTab() {
  const [searchParam] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");
  const { data: workOrder, isLoading } = useDefectControllerGetOneDefect(
    activeWoId ?? "",
    {
      query: {
        enabled: !!activeWoId,
      },
    }
  );

  const {
    data: manHours,
    isLoading: woTimeLogsIsLoading,
    isFetching,
  } = useWoTimeLogsControllerReadTimeLog(
    {
      workOrderId: activeWoId ?? "",
    },
    {
      query: {
        enabled: !!activeWoId,
        select: (res) => res.data,
      },
    }
  );

  const table = useReactTable({
    data: manHours?.logs ?? [],
    columns: manHourColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
        dateStart: false,
        dateEnd: false,
      },
    },
  });

  if (woTimeLogsIsLoading || isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <h3 className="text-xl font-bold">Man Hours</h3>
        <IsCloseAndEditAfterWorkOrderGuard>
          <CreateTimeLogBtn />
        </IsCloseAndEditAfterWorkOrderGuard>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <ManHourCardSummary
          label="Total Time Spent"
          value={`${manHours?.totalTimeSpentHour} Hr ${manHours?.totalTimeSpentRemainingMin} Min`}
          icon={<Clock className="text-primary-900 w-8 h-8" />}
        />
        <ManHourCardSummary
          label="Total Normal"
          value={`${manHours?.totalNormalHours} Hr ${manHours?.totalNormalRemainingMin} Min`}
          icon={<Clock className="text-primary-900 w-8 h-8" />}
        />
        <ManHourCardSummary
          label="Total OT"
          value={`${manHours?.totalOvertimeHours} Hr ${manHours?.totalOvertimeRemainingMin} Min`}
          icon={<Clock className="text-primary-900 w-8 h-8" />}
        />
        <ManHourCardSummary
          label="Manpower involved"
          value={`${manHours?.manpowerCount} nos.`}
          icon={<Users2 className="text-primary-900 w-8 h-8" />}
        />
      </div>
      <DataTable
        isLoading={isLoading}
        isFetching={isFetching}
        table={table}
        totalCount={manHours?.logs?.length ?? 0}
      />
    </div>
  );
}
