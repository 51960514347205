import { useSearchParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import {
  DRAWER_CREATE_STATE,
  ESCALATION_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { escalationColumns } from "../../components/drawers/escalation/escalationColumns";
import { useEffect, useState } from "react";
import { DataTable } from "../../components/react-table/DataTable";
import useSearch from "../../hooks/useSearch";
import { FormProvider, useForm } from "react-hook-form";
import useError from "../../hooks/useError";
import { useEscalationControllerGetEscalations } from "../../api/escalation/escalation";
import {
  EscalationControllerGetEscalationsParams,
  EscalationType,
} from "../../api/model";
import AppTextField from "../../components/AppTextField";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import EscalationTab from "./tabs/EscalationTab";

export default function EscalationScreen() {
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "escalationTabIdx",
    defaultValue: EscalationType.WORK_ORDER,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  return (
    <div className="flex flex-col">
      <div className="flex gap-4">
        <AppTitle title="Escalations" />
        <AppButton
          label="+ Create"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", ESCALATION_FORM_DRAWER);
              p.set("drawerState", DRAWER_CREATE_STATE);
              return p;
            });
          }}
        />
      </div>
      <FormProvider {...methods}>
        <AppTextField
          name="search"
          className="w-[400px] mb-1"
          inputClassName="bg-transparent"
          placeholder="Search Template..."
        />
        <Tabs value={currentTabIdx} onValueChange={onTabChange}>
          <TabsList className="mb-4">
            <TabsTrigger value={EscalationType.WORK_ORDER}>
              Work Orders
            </TabsTrigger>
            <TabsTrigger value={EscalationType.WORK_REQUEST}>
              Work Requests
            </TabsTrigger>
            <TabsTrigger value={EscalationType.PM}>
              Preventive Maintenance
            </TabsTrigger>
          </TabsList>
          <TabsContent value={EscalationType.WORK_ORDER}>
            <EscalationTab escalationType={EscalationType.WORK_ORDER} />
          </TabsContent>
          <TabsContent value={EscalationType.WORK_REQUEST}>
            <EscalationTab escalationType={EscalationType.WORK_REQUEST} />
          </TabsContent>
          <TabsContent value={EscalationType.PM}>
            <EscalationTab escalationType={EscalationType.PM} />
          </TabsContent>
        </Tabs>
      </FormProvider>
    </div>
  );
}
