import AppButton from '@/components/AppButton';
import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Trash } from 'lucide-react';
import { useWorkOrderV2ControllerDeleteWorkOrder } from '@/api/work-order-defect-v2/work-order-defect-v2';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/redux/store';

export default function DeleteWorkOrderBtn(){
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const workOrderId = searchParams.get('workOrderId');
  const activeProj = useAppSelector(state => state.root.activeProject);

  const [open, setIsOpen] = useState(false);

  const {mutate, isPending} = useWorkOrderV2ControllerDeleteWorkOrder({
    mutation: {
      onSuccess: async () => {
        qc.invalidateQueries({
          predicate: q => (q.queryKey[0] as string)?.includes("defect")
        })
        setIsOpen(false);
        setSearchParams(new URLSearchParams());
      }
    }
  })

  const onDeleteWorkOrder = () => {
    if(!workOrderId || !activeProj || !activeProj.id) return;
    mutate({
      workOrderId,
      data: {
        projectId: activeProj.id?.toString() ?? ""
      }
    })
  }

  return (
    <>
      <AppButton label="Delete Work Order" variant="destructive" icon={<Trash />} onClick={() => {
        setIsOpen(true);
      }}/>
      <Dialog open={open} onOpenChange={(o) => setIsOpen(o)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogDescription>Are you sure you want to delete this work order? This action is irreversible.</DialogDescription>
            <DialogFooter>
              <AppButton variant="outline" label="Cancel" onClick={() => {
                setIsOpen(false);
              }}/>
              <AppButton label="Confirm" variant="destructive" onClick={onDeleteWorkOrder} isLoading={isPending}/>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  )
}