import {Navigate} from "react-router-dom";
import MainPageSkeleton from "../components/skeletons/MainPageSkeleton";
import useAccountType, {
  COMPANY_ADMIN_ACC_TYPE,
  SUPER_ADMIN_ACC_TYPE,
} from "../hooks/useAccountType";

export default function CompanyAdminProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const {hasAccess, isLoading} = useAccountType({
    accType: COMPANY_ADMIN_ACC_TYPE,
  });
  if (isLoading) return <MainPageSkeleton />;
  if (!hasAccess && !isLoading) return <Navigate to="/home/403" replace />;
  return <>{children}</>;
}
