import { useEffect } from "react";
import { useTnbBillControllerGetTnbAnalyticQuery } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../redux/store";
import useError from "../../../hooks/useError";
import AppTitle from "../../HomeScreen/components/AppTitle";
import { Error } from "@mui/icons-material";
import { Typography } from "@mui/material";

export default function TnbBillGraphTab() {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const {
    data: tnbAnalytic,
    isLoading: tnbAnalyticIsLoading,
    isError: tnbAnalyticIsError,
    error: tnbAnalyticError,
  } = useTnbBillControllerGetTnbAnalyticQuery(
    {
      projectId: activeProj?.id ?? 0,
    },
    {
      skip: !activeProj,
    }
  );

  useError({
    dependencies: [
      {
        isError: tnbAnalyticIsError,
        error: tnbAnalyticError,
      },
    ],
  });

  if (tnbAnalyticIsLoading) return <div>Loading...</div>;

  if (tnbAnalyticIsError)
    return (
      <div className="py-4">
        <AppTitle title="Dashboard" />
        <div className="h-full w-full flex justify-center items-center">
          <div className="flex flex-col items-center">
            <Error className="text-neutral-500 w-20 h-20" />
            <p className="text-neutral-500 mt-4">
              Unfortunately, there is no analytics setup for this project
            </p>
          </div>
        </div>
      </div>
    );

  return (
    <div className="py-4">
      <iframe
        className="border-none"
        src={tnbAnalytic?.data}
        width="100%"
        loading="lazy"
        height={tnbAnalytic?.dashboardHeight}
      />
    </div>
  );
}
