import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useAuthV2ControllerCreateNewUser } from "../../../api/auth-v2/auth-v2";
import { useAuthControllerDeleteUser } from "../../../api/auth/auth";
import { useCompaniesControllerGetProjectsUnderCompany } from "../../../api/companies/companies";
import { AccountTypeEnum, Attachment } from "../../../api/model";
import { useRolesControllerGetRoles } from "../../../api/roles/roles";
import {
  useUserControllerDeleteAttachment,
  useUserControllerGetOneUser,
  useUserControllerUpdateUser,
} from "../../../api/users/users";
import { useVendorControllerGetVendors } from "../../../api/vendor/vendor";
import useAppStorage from "../../../hooks/useAppStorage";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { Checkbox } from "../../ui/checkbox";
import { DRAWER_UPDATE_STATE } from "../AppDrawer";
import AppSelect from "../../AppSelect";

const optionLabel = {
  COMPANY_ADMIN: "Company Admin",
  NORMAL_USER: "Normal User",
  VENDOR: "Vendor",
} as { [key: string]: string };

const UserFormSchema = z
  .object({
    name: z.string().min(1, { message: "Please input a name" }),
    email: z.string().email({ message: "Please input a valid email" }),
    phoneNo: z.string().min(1, { message: "Please input a phone number" }),
    position: z.string().min(1, { message: "Please input a position" }),
    password: z.string().optional(),
    attachments: z.instanceof(File).array().optional(),
    accountType: z.enum([
      AccountTypeEnum.COMPANY_ADMIN,
      AccountTypeEnum.NORMAL_USER,
      AccountTypeEnum.VENDOR,
    ]),
    // vendorId: z.object({id: z.number()}).optional().nullable(),
    projectIds: z
      .object({ id: z.number() })
      .array()
      .min(1, { message: "Please select at least one project" }),
    // rolesId: z
    //   .object({id: z.number()})
    //   .array()
    //   .min(1, {message: "Please select at least one role"}),
    mode: z.enum(["create", "update"]).optional().default("create"),
  })
  .refine(
    (schema) => {
      if (schema.mode === "create" && schema.password === "") {
        return false;
      }
      return true;
    },
    {
      path: ["password"],
      message: "Please input an password with at least 8 characters",
    }
  );

export type UserForm = z.infer<typeof UserFormSchema>;

export default function UserFormDrawer() {
  const qc = useQueryClient();
  const [searchParam, setSearchParams] = useSearchParams();
  const [uploadedPhotos, setUploadedPhotos] = useState<Attachment[]>([]);
  const { data: user } = useGetUserDataQuery();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const nav = useNavigate();

  const { mutate: deletePhoto } = useUserControllerDeleteAttachment();

  const { mutate: deleteUser } = useAuthControllerDeleteUser();
  const { data: vendors } = useVendorControllerGetVendors(
    {
      companyId: activeComp?.id?.toString() ?? "",
      workscopeIds: [],
      search: "",
    },
    {
      query: {
        enabled: !!activeComp,
        select: (res) => res.data,
      },
    }
  );

  const { data: roles } = useRolesControllerGetRoles(
    { projectId: activeProj?.id?.toString() ?? "" },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  const { data: projUnderComp, isError: projUnderCompIsError } =
    useCompaniesControllerGetProjectsUnderCompany(
      activeComp?.id?.toString() ?? "",
      {
        query: {
          enabled: !!activeComp,
          select: (res) => res.data,
        },
      }
    );

  // const [createUser, { isLoading, isSuccess, isError: createNewUserIsError }] =
  //   useAuthV2ControllerCreateNewUserMutation();
  // const [uploadAttachments, { isLoading: attIsLoading, isError: attIsError }] =
  //   useUploadAttachmentMutation();

  const { useUploadAttachmentMutation } = useAppStorage();

  const { mutateAsync: createUser } = useAuthV2ControllerCreateNewUser();
  const { mutateAsync: updateUser } = useUserControllerUpdateUser();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] as string).includes("user") ||
          (query.queryKey[0] as string).includes("auth"),
      });

      // User may be updating, so do not need to close the sheet, it goes back to user detail
      if (searchParam.get("firebaseId")) {
        nav(-1);
      } else {
        setSearchParams(new URLSearchParams());
      }
    },
  });

  const methods = useForm<UserForm>({
    resolver: zodResolver(UserFormSchema),
    defaultValues: {
      name: "",
      email: "",
      phoneNo: "",
      position: "",
      password: "",
      attachments: [],
      accountType: AccountTypeEnum.NORMAL_USER,
      // accountType: "",
      // vendorId: null,
      projectIds: [],
      // rolesId: [],
      mode: "create",
    },
  });

  // const accountTypeSelected = useWatch({control, name: "accountType"});

  const onSubmit: SubmitHandler<UserForm> = async (data: UserForm) => {
    if (!activeProj || !activeComp || !user) return;
    const {
      attachments,
      name,
      phoneNo,
      email,
      position,
      password,
      // accountType,
      // vendorId,
    } = data;

    if (!name || !phoneNo || !email || !position || !data.projectIds) return;

    await mutate({
      files: attachments ?? [],
      mutateAsync: async (files) => {
        if (
          searchParam.get("drawerState") === DRAWER_UPDATE_STATE &&
          editUser &&
          editUser.id
        ) {
          return await updateUser({
            userId: editUser.id,
            data: {
              updatedById: user.id,
              userId: editUser.id,
              name,
              phoneNo,
              email,
              position,
              attachments: files,
              accountType: data.accountType,
              projectIds: data.projectIds?.map((v) => v.id) ?? [],
            },
          });
        }
        await createUser({
          data: {
            name,
            phoneNo,
            email,
            position,
            password: password ?? "",
            createdById: user.id,
            companiesId: [activeComp.id],
            attachments: files,
            projectIds: data.projectIds?.map((v) => v.id) ?? [],
            accountType: data.accountType,
            // ...(vendorId && {vendorId: vendorId.id}),
          },
        });
      },
    });
  };

  const { data: editUser, isLoading: editUserIsLoading } =
    useUserControllerGetOneUser(searchParam.get("firebaseId") ?? "", {
      query: {
        enabled: !!searchParam.get("firebaseId"),
        select: (res) => res.data,
      },
    });

  const onDeleteUploadedPhoto = async (att: Attachment) => {
    const newUploadedPhotos = uploadedPhotos.filter((v) => v.id !== att.id);
    if (!editUser || !att.id || !editUser.id) return;
    deletePhoto({ attachmentId: att.id, userId: editUser.id });
    setUploadedPhotos(newUploadedPhotos);
  };

  // Is edit mode
  useEffect(() => {
    if (searchParam.get("drawerState") === DRAWER_UPDATE_STATE && editUser) {
      const {
        name,
        phoneNo,
        email,
        position,
        accountTypes,
        roles,
        vendor,
        projects,
      } = editUser;
      methods.setValue("name", name ?? "");
      methods.setValue("phoneNo", phoneNo ?? "");
      methods.setValue("email", email ?? "");
      methods.setValue("position", position ?? "");
      // setValue(
      //   "accountType",
      //   accountTypes.find((a) => a.projectId === activeProj?.id)?.type ?? ""
      // );
      // setValue("projectIds", projects ?? []);
      setUploadedPhotos(editUser.attachments ?? []);
      // setValue(
      //   "rolesId",
      //   roles.filter((r) => r.projectId === activeProj?.id) // Only show roles which the user is under
      // );
      // setValue("vendorId", vendor);
      methods.setValue(
        "projectIds",
        projects
          ?.filter((p) => p.companyId === activeComp?.id)
          ?.map((p) => ({ id: p.id as number, name: p.name ?? "-" }))
      ); // Only show projects which the user is under
      methods.setValue("mode", "update");
      methods.setValue(
        "accountType",
        (accountTypes[0]?.type as "COMPANY_ADMIN" | "NORMAL_USER" | "VENDOR") ??
          "NORMAL_USER"
      );
    }
  }, [searchParam, editUser]);

  if (editUserIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {searchParam.get("drawerState") === DRAWER_UPDATE_STATE
            ? "Update"
            : "Create"}{" "}
          User
        </p>
        <AppTextField label="Name" name="name" />
        <AppTextField label="Email" name="email" />
        <AppTextField label="Phone No." name="phoneNo" />
        <AppTextField label="Position" name="position" />
        {searchParam.get("drawerState") === "CREATE" && (
          <AppTextField label="Password" name="password" type="password" />
        )}
        <Controller
          control={methods.control}
          name="attachments"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFile
                uploadedPhotos={uploadedPhotos}
                onDeleteUploadedPhoto={onDeleteUploadedPhoto}
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.attachments?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
              />
            );
          }}
        />
        <AppSelect
          label="Account Type"
          name="accountType"
          placeholder="Select Account Type"
          selections={[
            {
              label: "Company Admin",
              value: AccountTypeEnum.COMPANY_ADMIN,
            },
            {
              label: "Normal User",
              value: AccountTypeEnum.NORMAL_USER,
            },
            {
              label: "Vendor",
              value: AccountTypeEnum.VENDOR,
            },
          ]}
          defaultValue={AccountTypeEnum.NORMAL_USER}
        />
        {projUnderComp && (
          <AppSelectWithDialog
            defaultValue={[]}
            placeholder="Select Project"
            columns={[
              {
                header: "",
                id: "select",
                cell: ({ row, table }) => (
                  <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => {
                      row.toggleSelected(!!value);
                    }}
                    aria-label="Select row"
                  />
                ),
                enableSorting: false,
                enableHiding: false,
              },
              {
                accessorKey: "name",
                header: "Name",
              },
              {
                accessorKey: "code",
                header: "Code",
              },
            ]}
            multiple
            control={methods.control}
            name="projectIds"
            items={projUnderComp ?? []}
            label="Projects"
            dialogTitle="Select Projects"
            onResultRender={(item, idx) => (
              <div key={idx} className="flex flex-col">
                <div className="font-medium">{item?.name}</div>
                <div className="font-thin mt-1">{item?.code ?? "-"}</div>
              </div>
            )}
            onOptionsRender={(item, idx) => (
              <div key={idx} className="flex flex-col">
                <div className="font-medium">{item?.name}</div>
                <div className="font-thin mt-1">{item?.code ?? "-"}</div>
              </div>
            )}
            error={!!methods.formState.errors.projectIds?.message}
            helperText={methods.formState.errors.projectIds?.message}
          />
        )}
        {/* {accountTypeSelected === "VENDOR" && vendors && (
        <AppAutoComplete
          control={control}
          defaultValue={vendors[0]}
          name="vendorId"
          options={vendors}
          getOptionLabel={(option) => option.name ?? ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          label="Link Vendor"
        />
      )} */}
        <div className="flex gap-4 mt-4">
          <AppButton
            label={
              searchParam.get("drawerState") === DRAWER_UPDATE_STATE
                ? "Update"
                : "Create"
            }
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isPending}
          />
          {searchParam.get("drawerState") === DRAWER_UPDATE_STATE && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editUser?.email ?? "-"}
              onDeleteConfirm={async () => {
                if (!editUser || !user || !editUser.id) return;
                deleteUser({
                  data: {
                    id: editUser.id,
                    firebaseUserId: editUser.firebaseUserId ?? "",
                    updatedById: user.id,
                  },
                });
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
