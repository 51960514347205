import { MousePointer2, Link as LinkIcon, Paintbrush2 } from "lucide-react";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../../../../../components/ui/toggle-group";
import {
  BimPointerState,
  useBimActions,
  useBimOpacity,
  useBimPointerState,
} from "../../../../../stores/bimStore/bimStore";
import { cn } from "../../../../../lib/utils";
import { Slider } from "../../../../../components/ui/slider";

export default function BimBottomBar() {
  const pointerState = useBimPointerState();
  const { changePointerState } = useBimActions();
  const opacity = useBimOpacity();
  const { setOpacity } = useBimActions();

  return (
    <div className="absolute mx-auto bottom-4 bg-transparent rounded-md p-4 w-full flex items-center justify-center">
      <div className="bg-white shadow-xl rounded-md p-2 flex items-center">
        <ToggleGroup
          type="single"
          value={pointerState}
          onValueChange={(v) => changePointerState(v as BimPointerState)}
        >
          <ToggleGroupItem value={BimPointerState.SELECTION}>
            <MousePointer2
              className={cn("h-4 w-4", {
                "text-primary-900": pointerState === BimPointerState.SELECTION,
              })}
            />
          </ToggleGroupItem>
          <ToggleGroupItem value={BimPointerState.EQUIPMENT_LINKING}>
            <LinkIcon
              className={cn("h-4 w-4", {
                "text-primary-900":
                  pointerState === BimPointerState.EQUIPMENT_LINKING,
              })}
            />
          </ToggleGroupItem>
          <ToggleGroupItem value={BimPointerState.OPACITY_ADJUSTMENT}>
            <Paintbrush2
              className={cn("h-4 w-4", {
                "text-primary-900":
                  pointerState === BimPointerState.OPACITY_ADJUSTMENT,
              })}
            />
          </ToggleGroupItem>
        </ToggleGroup>
        <Slider
          max={1}
          step={0.05}
          value={[opacity]}
          onValueChange={(e) => setOpacity(e[0])}
          className={cn("w-[100px]")}
        />
      </div>
    </div>
  );
}
