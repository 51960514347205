import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import useIsUpdateDrawerState from "../../hooks/useDrawerState";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import AppTextField from "../AppTextField";
import AppButton from "../AppButton";
import useError from "../../hooks/useError";
import useSuccess from "../../hooks/useSuccess";
import { useAppSelector } from "../../redux/store";
import { useGetUserDataQuery } from "../../redux/slices/Auth/AuthApi";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import ConfirmDeleteBtnWithDialog from "../dialogs/ConfirmDeleteWithTextDialog";
import {
  useWorkscopeControllerCreateWorkScope,
  useWorkscopeControllerDeleteWorkScope,
  useWorkscopeControllerGetOneWorkscope,
  useWorkscopeControllerUpdateWorkScope,
} from "../../api/workscope/workscope";
import { useQueryClient } from "@tanstack/react-query";

const WorkScoprFormSchema = z.object({
  name: z.string().min(1),
});

type WorkScopeForm = z.infer<typeof WorkScoprFormSchema>;

export default function WorkScopeFormDrawer() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDrawerUpdate = useIsUpdateDrawerState();
  const workScopeId = searchParams.get("workScopeId");
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: user } = useGetUserDataQuery();
  const methods = useForm<WorkScopeForm>({
    resolver: zodResolver(WorkScoprFormSchema),
    defaultValues: {
      name: "",
    },
  });

  const { mutate: createWorkScope, isPending: createWorkScopeIsPending } =
    useWorkscopeControllerCreateWorkScope({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) => {
              return (query.queryKey[0] as any).includes("workscope");
            },
          });
          setSearchParams((p) => new URLSearchParams());
        },
      },
    });

  const { mutate: updateWorkScope, isPending: updateWorkScopeIsPending } =
    useWorkscopeControllerUpdateWorkScope({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) => {
              return (query.queryKey[0] as any).includes("workscope");
            },
          });
          setSearchParams((p) => new URLSearchParams());
        },
      },
    });

  const { mutate: deleteWorkScope, isPending: deleteWorkScopeIsPending } =
    useWorkscopeControllerDeleteWorkScope({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) => {
              return (query.queryKey[0] as any).includes("workscope");
            },
          });
          setSearchParams((p) => new URLSearchParams());
        },
      },
    });

  const {
    data: editWorkScope,
    isLoading: editWorkScopeIsLoading,
    isFetching,
  } = useWorkscopeControllerGetOneWorkscope(
    workScopeId ?? "",

    {
      query: {
        enabled: !!isDrawerUpdate && !!workScopeId,
      },
    }
  );

  const onSubmit: SubmitHandler<WorkScopeForm> = async (data) => {
    if (!activeComp || !user) return;
    if (isDrawerUpdate) {
      if (!editWorkScope) return;
      updateWorkScope({
        workscopeId: editWorkScope?.data?.id?.toString(),
        data: {
          updatedById: user.id.toString(),
          companyId: activeComp.id,
          name: data.name,
        },
      });
    } else {
      createWorkScope({
        data: {
          createdById: user.id,
          companyId: activeComp.id,
          name: data.name,
        },
      });
    }
  };

  // isEdit Mode
  useEffect(() => {
    if (isDrawerUpdate && editWorkScope) {
      methods.setValue("name", editWorkScope?.data?.name);
    }
  }, [editWorkScope, searchParams]);

  // useEffect(() => {
  //   if (deleteWorkScopeIsSuccess) {
  //     setSearchParams((p) => {
  //       p.delete("drawerState");
  //       p.delete("drawerType");
  //       p.delete("workScopeId");
  //       p.set("vendorTabIdx", "0");
  //       return p;
  //     });
  //   }
  // }, [deleteWorkScopeIsSuccess]);

  if (editWorkScopeIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isDrawerUpdate ? "Update" : "Create"} Workscope
        </p>
        <AppTextField label="Name" name="name" />
        <div className="flex gap-4">
          <AppButton
            isLoading={createWorkScopeIsPending || updateWorkScopeIsPending}
            label={isDrawerUpdate ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
          {isDrawerUpdate && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editWorkScope?.data?.name ?? ""}
              onDeleteConfirm={async () => {
                await deleteWorkScope({
                  workscopeId: editWorkScope?.data?.id.toString() ?? "",
                });
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
