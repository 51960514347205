import { wrap } from "comlink";
import type { WorkerType } from "./workers/qrcode.worker";
import Worker from "./workers/qrcode.worker?worker";
import { AssetWithType } from "../../api/model";
import { useAsync } from "react-use";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/store";

export const qrWorker = wrap<WorkerType>(new Worker());

export default function useQRCodeRender({
  assetWithType,
  includeLocation,
  size,
}: Parameters<WorkerType["renderQRCode"]>[0]) {
  const baseUrl = window.location.origin;
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const workRequestCode = activeProj?.workRequestCode;
  const {
    value: data,
    loading,
    error,
  } = useAsync(async () => {
    return qrWorker.renderQRCode({
      assetWithType,
      includeLocation,
      baseUrl,
      workRequestCode,
      size,
    });
  }, [assetWithType?.length]);

  return { data, loading, error };
}
