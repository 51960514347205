import { useParams } from "react-router-dom";
import { CerevLogo } from "../../icons/CerevLogo";
import {
  usePtwControllerCreatePtw,
  usePtwTemplateControllerGetOnePtwTemplate,
  usePtwTemplateControllerGetOnePtwTemplatePublic,
} from "../../api/permit-to-work-adv/permit-to-work-adv";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  PtwFormSchema,
  PtwFormType,
} from "../../components/drawers/ptw/PtwFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import PtwFormatRenderer from "../../components/drawers/ptw/PtwFormatRenderer";
import { useEffect } from "react";
import { AttachmentProjectType, PtwFormatType } from "../../api/model";
import AppButton from "../../components/AppButton";
import { useMutation } from "@tanstack/react-query";
import useAppStorage from "../../hooks/useAppStorage";
import { toast } from "sonner";
import AppTextField from "../../components/AppTextField";
import PublicPageWrapper from "../../components/PublicPageWrapper";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import AppLogoText from "../../components/AppLogoText";
import { Check } from "lucide-react";

export default function PublicCustomWorkPermitScreen() {
  const { projectCode, ptwTemplateId } = useParams();
  const { uploadMultiplePublicFile } = useAppStorage();

  const {
    data: ptwTemplate,
    isLoading,
    isFetchedAfterMount,
  } = usePtwTemplateControllerGetOnePtwTemplatePublic(ptwTemplateId as string, {
    query: {
      enabled: !!ptwTemplateId,
      select: (res) => res.data.data,
    },
  });

  const { mutateAsync: createPtw } = usePtwControllerCreatePtw();

  const methods = useForm<PtwFormType>({
    resolver: zodResolver(PtwFormSchema),
    defaultValues: {
      formFields: [],
    },
  });

  const arrayFields = useFieldArray({
    control: methods.control,
    name: "formFields",
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: async (data: PtwFormType) => {
      for (const ptwAtt of data.ptwAttachments) {
        const uploadedAtt = await uploadMultiplePublicFile({
          files: ptwAtt.newAttachments,
        });
        if (!uploadedAtt) continue;
        // add the new attachment to the existing attachment
        ptwAtt.attachments = ptwAtt.attachments.concat(
          uploadedAtt as AttachmentProjectType[]
        );
      }

      for (const ptwImg of data.ptwImages) {
        const uploadedImg = await uploadMultiplePublicFile({
          files: ptwImg.newAttachments,
        });
        if (!uploadedImg) continue;
        ptwImg.attachments = ptwImg.attachments.concat(
          uploadedImg as AttachmentProjectType[]
        );
      }

      await createPtw({
        data: {
          name: data.name,
          email: data.email,
          location: data.location,
          icNo: data.icNo,
          projectWorkPermitCode: projectCode as string,
          ptwTemplateId: ptwTemplateId as string,
          ptwTexts: data.ptwTexts,
          ptwNumbers: data.ptwNumbers,
          // To see how to fulfill the types, it may consist of union attachment types..
          ptwAttachments: data.ptwAttachments as any[],
          // To see how to fulfill the types, it may consist of union attachment types..
          ptwImages: data.ptwImages as any[],
          ptwDates: data.ptwDates.map((ptwd) => ({
            ...ptwd,
            date: ptwd.date.toISOString(),
          })),
          ptwBools: data.ptwBools,
          ptwOptionValues: data.ptwOptionValues.map((opt) => ({
            id: opt.id,
            ptwFormatId: opt.ptwFormatId,
            ptwOptions: Array.isArray(opt.ptwOptions)
              ? opt.ptwOptions
              : [opt.ptwOptions],
          })),
        },
      });
    },
    onSuccess: () => {
      toast.success(
        "Work Permit created successfully. Please check your email for confirmation."
      );
    },
  });

  const onSubmit: SubmitHandler<PtwFormType> = (data) => {
    mutate(data);
  };

  useEffect(() => {
    if (isFetchedAfterMount && ptwTemplate) {
      methods.reset({
        ptwTemplate: ptwTemplate,
        ptwTexts: ptwTemplate.ptwFormat.reduce((acc, format) => {
          if (format.ptwFormatType === PtwFormatType.TEXT) {
            acc.push({
              id: 0,
              ptwFormatId: format.id,
              value: "",
            });
          }
          return acc;
        }, [] as PtwFormType["ptwTexts"]),
        ptwNumbers: ptwTemplate.ptwFormat.reduce((acc, format) => {
          if (format.ptwFormatType === PtwFormatType.NUMBER) {
            acc.push({
              id: 0,
              ptwFormatId: format.id,
              value: 0,
            });
          }
          return acc;
        }, [] as PtwFormType["ptwNumbers"]),
        ptwAttachments: ptwTemplate.ptwFormat.reduce((acc, format) => {
          if (format.ptwFormatType === PtwFormatType.ATTACHMENT) {
            acc.push({
              id: 0,
              ptwFormatId: format.id,
              attachments: [],
              newAttachments: [],
            });
          }
          return acc;
        }, [] as PtwFormType["ptwAttachments"]),
        ptwImages: ptwTemplate.ptwFormat.reduce((acc, format) => {
          if (format.ptwFormatType === PtwFormatType.IMAGE) {
            acc.push({
              id: 0,
              ptwFormatId: format.id,
              attachments: [],
              newAttachments: [],
            });
          }
          return acc;
        }, [] as PtwFormType["ptwImages"]),
        ptwDates: ptwTemplate.ptwFormat.reduce((acc, format) => {
          if (format.ptwFormatType === PtwFormatType.DATE) {
            acc.push({
              id: 0,
              ptwFormatId: format.id,
              date: new Date(),
            });
          }
          return acc;
        }, [] as PtwFormType["ptwDates"]),
        ptwBools: ptwTemplate.ptwFormat.reduce((acc, format) => {
          if (format.ptwFormatType === PtwFormatType.BOOLEAN) {
            acc.push({
              id: 0,
              ptwFormatId: format.id,
              value: false,
            });
          }
          return acc;
        }, [] as PtwFormType["ptwBools"]),
        ptwOptionValues: ptwTemplate.ptwFormat.reduce((acc, format) => {
          if (
            format.ptwFormatType === PtwFormatType.SINGLE_OPTION ||
            format.ptwFormatType === PtwFormatType.MULTI_OPTION
          ) {
            acc.push({
              id: format.id,
              ptwOptions: [],
              ptwFormatId: format.id,
            });
          }
          return acc;
        }, [] as PtwFormType["ptwOptionValues"]),
        formFields: ptwTemplate.ptwFormat.map((format) => ({
          ...format,
          ptwFormatType: format.ptwFormatType,
          ptwOptions: format.ptwOptions,
          ptwFormatId: format.id,
        })),
      });
    }
  }, [ptwTemplate, isFetchedAfterMount]);

  if (isSuccess)
    return (
      <PublicPageWrapper className="justify-between items-center">
        <div className="flex space-x-4 items-center mb-6 w-full">
          <div className="h-16 w-16">
            <CerevLogo />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-2xl font-bold text-slate-800">
              {ptwTemplate?.name ?? "-"}
            </p>
            <p className=" text-slate-500">Work Permit</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 items-center w-full">
          <Check className="text-primary-900 h-32 w-32" />
          <p className="text-neutral-800">Work Permit submitted successfully</p>
        </div>
        <AppButton
          className="w-full"
          label="Raise another"
          onClick={() => {
            location.reload();
          }}
        />
      </PublicPageWrapper>
    );

  return (
    <PublicPageWrapper>
      <div className="flex space-x-4 items-center mb-6">
        <div className="h-16 w-16">
          <CerevLogo />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-2xl font-bold text-slate-800">
            {ptwTemplate?.name ?? "-"}
          </p>
          <p className=" text-slate-500">Work Permit</p>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <FormProvider {...methods}>
          <h3 className="text-xl font-bold mt-6">General</h3>
          <AppTextField
            name="name"
            label="Name"
            description="Company Name, or your name"
          />
          <AppTextField
            name="email"
            label="Email"
            description="Your email address to receive notification"
          />
          <AppTextField
            name="icNo"
            label="IC / Passport No"
            description="Your IC or Passport number"
          />
          <AppTextField
            name="location"
            label="Work Location"
            description="Provide details of the location of your work"
          />
          <h3 className="text-xl font-bold mt-8">Work Permit Fields</h3>
          <PtwFormatRenderer arrayFields={arrayFields} />
        </FormProvider>
        <AppButton
          label="Submit"
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={isPending}
        />
      </div>
    </PublicPageWrapper>
  );
}
