import { ColumnDef, Row } from "@tanstack/react-table";
import { Project } from "../../../../../api/model";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Badge } from "../../../../../components/ui/badge";
import { cn } from "../../../../../lib/utils";
import { useProjectControllerFindOneProject } from "../../../../../api/projects/projects";

const ProjectNameNav = ({ row }: { row: Row<Project> }) => {
  const nav = useNavigate();
  const projectId = row.getValue("id");

  const { data: project } = useProjectControllerFindOneProject(
    projectId as string,
    {
      query: {
        select: (res) => res.data.data,
        enabled: !!projectId,
      },
    }
  );

  return (
    <div className="flex gap-4">
      <div
        className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
        onClick={() => {
          nav(`/home/super-admin/projects/${row.getValue("id")}`);
        }}
      >
        {row.getValue("name") ?? "-"}
      </div>
      <Badge
        className={cn("bg-slate-400", {
          "bg-green-500": project?.environmentType === "PRODUCTION",
        })}
      >
        {project?.environmentType ?? "-"}
      </Badge>
    </div>
  );
};

export const projectColumns: ColumnDef<Project>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "Project Name",
    accessorKey: "name",
    cell: ({ row }) => <ProjectNameNav row={row} />,
  },
  {
    header: "Code",
    accessorKey: "code",
  },
  {
    header: "Type",
    accessorKey: "subscriptionType",
  },
  {
    header: "User Quota",
    accessorKey: "userQuota",
  },
];
