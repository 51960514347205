/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type NotificationControllerGetNotificationsFilter =
  (typeof NotificationControllerGetNotificationsFilter)[keyof typeof NotificationControllerGetNotificationsFilter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationControllerGetNotificationsFilter = {
  All: "All",
  Unread: "Unread",
} as const;
