import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import AppButton from "../AppButton";
import AppTextField from "../AppTextField";
import {
  useRolesControllerCreateRoleMutation,
  useRolesControllerGetOneRoleQuery,
  useRolesControllerUpdateRoleStatusMutation,
} from "../../redux/slices/OpenApi/cerevApi";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DRAWER_UPDATE_STATE } from "./AppDrawer";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import { setOpenSnackBar, SnackBarType } from "../../redux/slices/RootSlice";

const RoleFormSchema = z.object({
  name: z.string().min(1, { message: "Please input a name" }),
});

export type RoleForm = z.infer<typeof RoleFormSchema>;

export default function RoleFormDrawer() {
  const dispatch = useAppDispatch();
  const [searchParam, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<RoleForm>({
    resolver: zodResolver(RoleFormSchema),
  });

  const [createRole, { isLoading, isSuccess, isError: createRoleIsError }] =
    useRolesControllerCreateRoleMutation();

  const {
    data: editRole,
    isLoading: editRoleIsLoading,
    isError: editRoleIsError,
  } = useRolesControllerGetOneRoleQuery(
    {
      roleId: searchParam.get("roleId") ?? "",
    },
    {
      skip: !searchParam.get("roleId"),
    }
  );

  const [
    updateRole,
    {
      isLoading: updateRoleIsLoading,
      isSuccess: updateRoleIsSuccess,
      isError: updateRoleIsError,
    },
  ] = useRolesControllerUpdateRoleStatusMutation();

  const onSubmit: SubmitHandler<RoleForm> = async (data: RoleForm) => {
    if (!activeProj) return;
    if (searchParam.get("drawerState") === DRAWER_UPDATE_STATE && editRole) {
      return updateRole({
        roleId: editRole.id,
        updateRoleDto: {
          name: data.name,
        },
      });
    }
    createRole({
      createRoleDto: {
        name: data.name,
        projectId: activeProj?.id ?? 0,
      },
    });
  };

  // Is edit mode
  useEffect(() => {
    if (searchParam.get("drawerState") === DRAWER_UPDATE_STATE && editRole) {
      methods.setValue("name", editRole.name);
    }
  }, [searchParam, editRole]);

  // IsSuccess close dialog
  useEffect(() => {
    if (isSuccess || updateRoleIsSuccess) {
      setSearchParams((param) => {
        param.delete("drawerType");
        param.delete("drawerState");
        param.delete("roleId");
        return { ...param };
      });
    }
  }, [isSuccess, setSearchParams, updateRoleIsSuccess]);

  // Is Error mode
  useEffect(() => {
    if (updateRoleIsError || editRoleIsError || createRoleIsError) {
      dispatch(
        setOpenSnackBar({
          type: SnackBarType.Error,
          msg: "Something went wrong",
        })
      );
    }
  }, [updateRoleIsError, editRoleIsError, createRoleIsError]);

  if (editRoleIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {searchParam.get("drawerState") === DRAWER_UPDATE_STATE
            ? "Update"
            : "Create"}{" "}
          Role
        </p>
        <AppTextField label="Name" name="name" />
        <div className="flex">
          <AppButton
            label={
              searchParam.get("drawerState") === DRAWER_UPDATE_STATE
                ? "Update"
                : "Create"
            }
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isLoading || updateRoleIsLoading}
          />
        </div>
      </div>
    </FormProvider>
  );
}
