import { useRef, useState } from "react";
import AppButton from "./AppButton";
import { FileImage, Image, Trash } from "lucide-react";
import { Button } from "./ui/button";
import { cn } from "../lib/utils";

interface AppCameraFieldProp {
  photos: (ArrayBuffer | string | null)[];
  label: string;
  onChange: (files: string[]) => void;
  onDelete: (file: string | ArrayBuffer | null) => void;
  error?: boolean;
  helperText?: string;
}

export default function AppCameraField({
  photos,
  label,
  onChange,
  onDelete,
  error,
  helperText,
}: AppCameraFieldProp) {
  const cameraRef = useRef(null);
  const galleryRef = useRef(null);

  const onGalleryClicked = () => {
    (galleryRef.current as any).click();
  };

  const onCameraClicked = () => {
    (cameraRef.current as any).click();
  };

  const onObtainedPhotos = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileArray = [];
    const fileList = event.target.files;
    if (!fileList) return;
    for (let i = 0; i < fileList.length; i++) {
      fileArray.push(URL.createObjectURL(fileList[i]));
    }
    onChange(fileArray);
  };

  const onDeletePhoto = (fileUrl: string | ArrayBuffer | null) => {
    onDelete(fileUrl);
  };

  return (
    <div className="flex flex-col gap-4">
      <p>{label}</p>
      {photos && photos.length > 0 && (
        <div className="flex flex-col gap-6">
          {photos.map((pho, idx) => {
            return (
              <div className="relative" key={idx}>
                <img
                  className="w-full rounded-xl object-cover shadow-xl"
                  height="300"
                  src={pho as string}
                  key={idx}
                />
                <Button
                  className="absolute top-4 right-4 rounded-full bg-neutral-900"
                  onClick={() => {
                    onDeletePhoto(pho);
                  }}
                >
                  <Trash className="text-red-500" />
                </Button>
              </div>
            );
          })}
        </div>
      )}
      <input
        ref={cameraRef}
        type="file"
        capture="user"
        onChange={onObtainedPhotos}
        className="hidden"
        multiple
        accept=".jpg, .jpeg, .png"
      />
      <input
        ref={galleryRef}
        type="file"
        onChange={onObtainedPhotos}
        className="hidden"
        multiple
        accept=".jpg, .jpeg, .png"
      />
      {photos?.length === 0 || !photos ? (
        <div
          className={cn(
            "flex gap-2 border-slate-400 border-2 border-dashed rounded-xl p-4 flex-col justify-center items-center hover:cursor-pointer",
            {
              "border-red-500": !!error,
            }
          )}
          onClick={onGalleryClicked}
        >
          <Image className="text-primary-900 h-14 w-14" />
          <p className="text-slate-500">Click to add a photo here</p>
        </div>
      ) : (
        <AppButton
          onClick={onGalleryClicked}
          label="Add more"
          variant="outline"
          icon={<Image />}
        />
      )}
      {error && (
        <div className="text-red-500 font-sans text-xs">{helperText}</div>
      )}
    </div>
  );
}
