interface DaySettingProps {
  day: string;
  hourRange: string;
}

export default function DaySetting({ day, hourRange }: DaySettingProps) {
  return (
    <div className="flex items-center gap-4">
      <div className="min-w-[150px]">
        <p>{day}</p>
      </div>
      <p>{hourRange}</p>
    </div>
  );
}
