/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type WorkPermitsControllerGetWorkPermitCountWorkPermitStatus =
  (typeof WorkPermitsControllerGetWorkPermitCountWorkPermitStatus)[keyof typeof WorkPermitsControllerGetWorkPermitCountWorkPermitStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPermitsControllerGetWorkPermitCountWorkPermitStatus = {
  NEW: "NEW",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
} as const;
