/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateEscalationRequestDto,
  EscalationControllerDeleteEscalationParams,
  EscalationControllerGetEscalationsParams,
  EscalationControllerGetOneEscalationParams,
  GetEscalationHistoryLogResponseDto,
  GetEscalationsResponseDto,
  GetOneEscalationResponseDto,
  UpdateEscalationRequestDto,
} from ".././model";

export const escalationControllerCreateEscalation = (
  createEscalationRequestDto: CreateEscalationRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/escalation`, createEscalationRequestDto, options);
};

export const getEscalationControllerCreateEscalationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof escalationControllerCreateEscalation>>,
    TError,
    { data: CreateEscalationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof escalationControllerCreateEscalation>>,
  TError,
  { data: CreateEscalationRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof escalationControllerCreateEscalation>>,
    { data: CreateEscalationRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return escalationControllerCreateEscalation(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EscalationControllerCreateEscalationMutationResult = NonNullable<
  Awaited<ReturnType<typeof escalationControllerCreateEscalation>>
>;
export type EscalationControllerCreateEscalationMutationBody =
  CreateEscalationRequestDto;
export type EscalationControllerCreateEscalationMutationError =
  AxiosError<unknown>;

export const useEscalationControllerCreateEscalation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof escalationControllerCreateEscalation>>,
    TError,
    { data: CreateEscalationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof escalationControllerCreateEscalation>>,
  TError,
  { data: CreateEscalationRequestDto },
  TContext
> => {
  const mutationOptions =
    getEscalationControllerCreateEscalationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const escalationControllerGetEscalations = (
  params: EscalationControllerGetEscalationsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetEscalationsResponseDto>> => {
  return axios.get(`/escalation`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getEscalationControllerGetEscalationsQueryKey = (
  params: EscalationControllerGetEscalationsParams
) => {
  return [`/escalation`, ...(params ? [params] : [])] as const;
};

export const getEscalationControllerGetEscalationsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
    EscalationControllerGetEscalationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: EscalationControllerGetEscalationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
        QueryKey,
        EscalationControllerGetEscalationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEscalationControllerGetEscalationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
    QueryKey,
    EscalationControllerGetEscalationsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    escalationControllerGetEscalations(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
    TError,
    TData,
    Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
    QueryKey,
    EscalationControllerGetEscalationsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type EscalationControllerGetEscalationsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof escalationControllerGetEscalations>>
>;
export type EscalationControllerGetEscalationsInfiniteQueryError =
  AxiosError<unknown>;

export const useEscalationControllerGetEscalationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
    EscalationControllerGetEscalationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: EscalationControllerGetEscalationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
        QueryKey,
        EscalationControllerGetEscalationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getEscalationControllerGetEscalationsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEscalationControllerGetEscalationsQueryOptions = <
  TData = Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
  TError = AxiosError<unknown>
>(
  params: EscalationControllerGetEscalationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEscalationControllerGetEscalationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof escalationControllerGetEscalations>>
  > = ({ signal }) =>
    escalationControllerGetEscalations(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EscalationControllerGetEscalationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof escalationControllerGetEscalations>>
>;
export type EscalationControllerGetEscalationsQueryError = AxiosError<unknown>;

export const useEscalationControllerGetEscalations = <
  TData = Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
  TError = AxiosError<unknown>
>(
  params: EscalationControllerGetEscalationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetEscalations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getEscalationControllerGetEscalationsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const escalationControllerUpdateEscalation = (
  escalationId: string,
  updateEscalationRequestDto: UpdateEscalationRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/escalation/${escalationId}`,
    updateEscalationRequestDto,
    options
  );
};

export const getEscalationControllerUpdateEscalationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof escalationControllerUpdateEscalation>>,
    TError,
    { escalationId: string; data: UpdateEscalationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof escalationControllerUpdateEscalation>>,
  TError,
  { escalationId: string; data: UpdateEscalationRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof escalationControllerUpdateEscalation>>,
    { escalationId: string; data: UpdateEscalationRequestDto }
  > = (props) => {
    const { escalationId, data } = props ?? {};

    return escalationControllerUpdateEscalation(
      escalationId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type EscalationControllerUpdateEscalationMutationResult = NonNullable<
  Awaited<ReturnType<typeof escalationControllerUpdateEscalation>>
>;
export type EscalationControllerUpdateEscalationMutationBody =
  UpdateEscalationRequestDto;
export type EscalationControllerUpdateEscalationMutationError =
  AxiosError<unknown>;

export const useEscalationControllerUpdateEscalation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof escalationControllerUpdateEscalation>>,
    TError,
    { escalationId: string; data: UpdateEscalationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof escalationControllerUpdateEscalation>>,
  TError,
  { escalationId: string; data: UpdateEscalationRequestDto },
  TContext
> => {
  const mutationOptions =
    getEscalationControllerUpdateEscalationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const escalationControllerGetOneEscalation = (
  escalationId: string,
  params: EscalationControllerGetOneEscalationParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneEscalationResponseDto>> => {
  return axios.get(`/escalation/${escalationId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getEscalationControllerGetOneEscalationQueryKey = (
  escalationId: string,
  params: EscalationControllerGetOneEscalationParams
) => {
  return [`/escalation/${escalationId}`, ...(params ? [params] : [])] as const;
};

export const getEscalationControllerGetOneEscalationInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
    EscalationControllerGetOneEscalationParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  escalationId: string,
  params: EscalationControllerGetOneEscalationParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
        TError,
        TData,
        Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
        QueryKey,
        EscalationControllerGetOneEscalationParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEscalationControllerGetOneEscalationQueryKey(escalationId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
    QueryKey,
    EscalationControllerGetOneEscalationParams["cursorId"]
  > = ({ signal, pageParam }) =>
    escalationControllerGetOneEscalation(
      escalationId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!escalationId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
    TError,
    TData,
    Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
    QueryKey,
    EscalationControllerGetOneEscalationParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type EscalationControllerGetOneEscalationInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>>;
export type EscalationControllerGetOneEscalationInfiniteQueryError =
  AxiosError<unknown>;

export const useEscalationControllerGetOneEscalationInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
    EscalationControllerGetOneEscalationParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  escalationId: string,
  params: EscalationControllerGetOneEscalationParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
        TError,
        TData,
        Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
        QueryKey,
        EscalationControllerGetOneEscalationParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getEscalationControllerGetOneEscalationInfiniteQueryOptions(
      escalationId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEscalationControllerGetOneEscalationQueryOptions = <
  TData = Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
  TError = AxiosError<unknown>
>(
  escalationId: string,
  params: EscalationControllerGetOneEscalationParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEscalationControllerGetOneEscalationQueryKey(escalationId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>
  > = ({ signal }) =>
    escalationControllerGetOneEscalation(escalationId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!escalationId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EscalationControllerGetOneEscalationQueryResult = NonNullable<
  Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>
>;
export type EscalationControllerGetOneEscalationQueryError =
  AxiosError<unknown>;

export const useEscalationControllerGetOneEscalation = <
  TData = Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
  TError = AxiosError<unknown>
>(
  escalationId: string,
  params: EscalationControllerGetOneEscalationParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof escalationControllerGetOneEscalation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getEscalationControllerGetOneEscalationQueryOptions(
    escalationId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const escalationControllerDeleteEscalation = (
  escalationId: string,
  params: EscalationControllerDeleteEscalationParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/escalation/${escalationId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getEscalationControllerDeleteEscalationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof escalationControllerDeleteEscalation>>,
    TError,
    {
      escalationId: string;
      params: EscalationControllerDeleteEscalationParams;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof escalationControllerDeleteEscalation>>,
  TError,
  { escalationId: string; params: EscalationControllerDeleteEscalationParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof escalationControllerDeleteEscalation>>,
    { escalationId: string; params: EscalationControllerDeleteEscalationParams }
  > = (props) => {
    const { escalationId, params } = props ?? {};

    return escalationControllerDeleteEscalation(
      escalationId,
      params,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type EscalationControllerDeleteEscalationMutationResult = NonNullable<
  Awaited<ReturnType<typeof escalationControllerDeleteEscalation>>
>;

export type EscalationControllerDeleteEscalationMutationError =
  AxiosError<unknown>;

export const useEscalationControllerDeleteEscalation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof escalationControllerDeleteEscalation>>,
    TError,
    {
      escalationId: string;
      params: EscalationControllerDeleteEscalationParams;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof escalationControllerDeleteEscalation>>,
  TError,
  { escalationId: string; params: EscalationControllerDeleteEscalationParams },
  TContext
> => {
  const mutationOptions =
    getEscalationControllerDeleteEscalationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const escalationControllerGetEscalationHistoryLogs = (
  escalationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetEscalationHistoryLogResponseDto[]>> => {
  return axios.get(`/escalation/${escalationId}/history-logs`, options);
};

export const getEscalationControllerGetEscalationHistoryLogsQueryKey = (
  escalationId: string
) => {
  return [`/escalation/${escalationId}/history-logs`] as const;
};

export const getEscalationControllerGetEscalationHistoryLogsInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>
    >,
    TError = AxiosError<unknown>
  >(
    escalationId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof escalationControllerGetEscalationHistoryLogs>
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getEscalationControllerGetEscalationHistoryLogsQueryKey(escalationId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>
    > = ({ signal }) =>
      escalationControllerGetEscalationHistoryLogs(escalationId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!escalationId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type EscalationControllerGetEscalationHistoryLogsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>
  >;
export type EscalationControllerGetEscalationHistoryLogsInfiniteQueryError =
  AxiosError<unknown>;

export const useEscalationControllerGetEscalationHistoryLogsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>
  >,
  TError = AxiosError<unknown>
>(
  escalationId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof escalationControllerGetEscalationHistoryLogs>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getEscalationControllerGetEscalationHistoryLogsInfiniteQueryOptions(
      escalationId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEscalationControllerGetEscalationHistoryLogsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof escalationControllerGetEscalationHistoryLogs>
  >,
  TError = AxiosError<unknown>
>(
  escalationId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof escalationControllerGetEscalationHistoryLogs>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEscalationControllerGetEscalationHistoryLogsQueryKey(escalationId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>
  > = ({ signal }) =>
    escalationControllerGetEscalationHistoryLogs(escalationId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!escalationId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EscalationControllerGetEscalationHistoryLogsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof escalationControllerGetEscalationHistoryLogs>>
  >;
export type EscalationControllerGetEscalationHistoryLogsQueryError =
  AxiosError<unknown>;

export const useEscalationControllerGetEscalationHistoryLogs = <
  TData = Awaited<
    ReturnType<typeof escalationControllerGetEscalationHistoryLogs>
  >,
  TError = AxiosError<unknown>
>(
  escalationId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof escalationControllerGetEscalationHistoryLogs>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getEscalationControllerGetEscalationHistoryLogsQueryOptions(
      escalationId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
