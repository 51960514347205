import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppNotification, NotificationTypeEnum } from "../../../api/model";
import {
  useNotificationControllerToggleReadDone
} from "../../../api/notification/notification";
import {
  DRAWER_VIEW_STATE,
  WORK_ORDER_DETAIL_DRAWER,
  WORK_PERMIT_DETAIL_DRAWER,
  WORK_REQUEST_DETAIL_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { Badge } from "../../../components/ui/badge";
import { Separator } from "../../../components/ui/separator";
import useCurrentUser from "../../../hooks/useCurrentUser/useCurrentUser";
import { CerevLogo } from "../../../icons/CerevLogo";
import LegalComplianceLogo from "../../../icons/LegalComplianceLogo";
import PreventiveMaintenanceLogo from "../../../icons/PreventiveMaintenanceLogo";
import { WorkOrderLogo } from "../../../icons/WorkOrderLogo";
import WorkPermitLogo from "../../../icons/WorkPermitLogo";
import WorkRequestLogo from "../../../icons/WorkRequestLogo";
import { cn } from "../../../lib/utils";

interface AppNotificationProps {
  notification: AppNotification;
}

export default function AppNotificationItem({
  notification,
}: AppNotificationProps) {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const user = useCurrentUser();
  const qc = useQueryClient();

  // Do a reverse case, do not want a notification to be marked as read if it is not read
  const hasRead =
    notification.userNotifications.find(
      (unot) => unot.userId === user?.data?.id
    )?.hasRead ?? true;

  const { mutate: notificationRead } = useNotificationControllerToggleReadDone({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("notification"),
        });
      },
    },
  });

  const getNotificationLogo = () => {
    switch (notification.type) {
      case NotificationTypeEnum.WORK_ORDER:
        return <WorkOrderLogo />;
      case NotificationTypeEnum.PM:
        return <PreventiveMaintenanceLogo />;
      case NotificationTypeEnum.WORK_PERMIT:
        return <WorkPermitLogo />;
      case NotificationTypeEnum.LEGAL_COMPLIANCE:
        return <LegalComplianceLogo />;
      case NotificationTypeEnum.WORK_REQUEST:
        return <WorkRequestLogo size={50}/>;
      default:
        return <CerevLogo />;
    }
  };

  const navigateTo = () => {
    switch (notification.type) {
      case NotificationTypeEnum.WORK_ORDER:
        return setSearchParams((p) => {
          const { workOrderId } = notification.metaData;
          if (!workOrderId) return p;
          p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("workOrderId", workOrderId);
          p.set("workOrderTabIdx", "0");
          return p;
        });
      case NotificationTypeEnum.PM:
        return navigate("/home/pm");
      case NotificationTypeEnum.WORK_PERMIT:
        return setSearchParams((p) => {
          const { workPermitId } = notification.metaData;
          if (!workPermitId) return p;
          p.set("drawerType", WORK_PERMIT_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("workPermitId", workPermitId);
          return p;
        });
      case NotificationTypeEnum.LEGAL_COMPLIANCE:
      case NotificationTypeEnum.WORK_REQUEST:
        return setSearchParams((p) => {
          const { workRequestId } = notification.metaData;
          if (!workRequestId) return p;
          p.set("drawerType", WORK_REQUEST_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("workRequestId", workRequestId);
          return p;
        });
      default:
        return <CerevLogo />;
    }
  };

  return (
    <div
      className={cn("pt-6 rounded-md hover:cursor-pointer hover:bg-slate-50", {
        "bg-slate-50": !hasRead,
      })}
      onClick={() => {
        if (!hasRead) {
          notificationRead({
            notificationId: notification.id.toString(),
          });
        }
        navigateTo();
      }}
    >
      <div
        key={notification.id}
        className="flex ml-4  gap-6 justify-between items-center"
      >
        <div className="flex gap-4">
          <div className="h-12 w-12">{getNotificationLogo()}</div>
          <div className="flex flex-col">
            <div className="text-primary-900 font-bold">
              {notification.title}
            </div>
            <div className="text-slate-400">{notification.description}</div>
          </div>
        </div>
        {!hasRead && (
          <div className="flex flex-col mr-4">
            <Badge className="bg-red-400">New</Badge>
          </div>
        )}
      </div>
      <Separator className="mt-6" />
    </div>
  );
}
