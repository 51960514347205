import { CircleDollarSign, Pencil } from "lucide-react";
import AppButton from "../../../../components/AppButton";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import AppTextField from "../../../../components/AppTextField";
import DaySetting from "./components/DaySetting";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_CREATE_STATE,
  MAN_HOUR_SETTING_FORM_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import AppProjectSelect from "../../../../components/AppProjectSelect";
import { useAppSelector } from "../../../../redux/store";
import { useManHourSettingControllerGetManHourSetting } from "../../../../api/man-hour-setting/man-hour-setting";
import { Separator } from "../../../../components/ui/separator";
import AppItemInfo from "../../../../components/AppItemInfo";
import DrawerFormSkeleton from "../../../../components/skeletons/DrawerFormSkeleton";
import { format, parse } from "date-fns";

export default function ManHourSettingTab() {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data, isLoading } = useManHourSettingControllerGetManHourSetting(
    Number(activeProj?.id),
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <AppTitle title="Man Hours" className="mb-0" />
        <AppButton
          label="Edit"
          icon={<Pencil />}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerState", DRAWER_CREATE_STATE);
              p.set("drawerType", MAN_HOUR_SETTING_FORM_DRAWER);
              return p;
            });
          }}
        />
      </div>
      <div className="flex gap-4 items-center my-1">
        <p>Project :</p>
        <AppProjectSelect />
      </div>
      {isLoading ? (
        <DrawerFormSkeleton />
      ) : (
        <>
          <div className="space-y-1">
            <h3 className="text-lg font-bold">Working Hours</h3>
            <p className="text-sm text-slate-600">
              Any hours outside of working hours will be considered overtime
              hours
            </p>
          </div>
          <DaySetting
            day="Monday"
            hourRange={
              format(
                parse(
                  data?.monNormStart?.toString() +
                    ":" +
                    data?.monNormStartMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "hh:mm"
              ) +
              " - " +
              format(
                parse(
                  data?.monNormEnd?.toString() +
                    ":" +
                    data?.monNormEndMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "k:mm"
              )
            }
          />
          <DaySetting
            day="Tuesday"
            hourRange={
              format(
                parse(
                  data?.tueNormStart?.toString() +
                    ":" +
                    data?.tueNormStartMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "hh:mm"
              ) +
              " - " +
              format(
                parse(
                  data?.tueNormEnd?.toString() +
                    ":" +
                    data?.tueNormEndMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "k:mm"
              )
            }
          />
          <DaySetting
            day="Wednesday"
            hourRange={
              format(
                parse(
                  data?.wedNormStart?.toString() +
                    ":" +
                    data?.wedNormStartMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "hh:mm"
              ) +
              " - " +
              format(
                parse(
                  data?.wedNormEnd?.toString() +
                    ":" +
                    data?.wedNormEndMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "k:mm"
              )
            }
          />
          <DaySetting
            day="Thursday"
            hourRange={
              format(
                parse(
                  data?.thuNormStart?.toString() +
                    ":" +
                    data?.thuNormStartMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "hh:mm"
              ) +
              " - " +
              format(
                parse(
                  data?.thuNormEnd?.toString() +
                    ":" +
                    data?.thuNormEndMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "k:mm"
              )
            }
          />
          <DaySetting
            day="Friday"
            hourRange={
              format(
                parse(
                  data?.friNormStart?.toString() +
                    ":" +
                    data?.friNormStartMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "hh:mm"
              ) +
              " - " +
              format(
                parse(
                  data?.friNormEnd?.toString() +
                    ":" +
                    data?.friNormEndMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "k:mm"
              )
            }
          />
          <DaySetting
            day="Saturday"
            hourRange={
              format(
                parse(
                  data?.satNormStart?.toString() +
                    ":" +
                    data?.satNormStartMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "hh:mm"
              ) +
              " - " +
              format(
                parse(
                  data?.satNormEnd?.toString() +
                    ":" +
                    data?.satNormEndMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "k:mm"
              )
            }
          />
          <DaySetting
            day="Sunday"
            hourRange={
              format(
                parse(
                  data?.sunNormStart?.toString() +
                    ":" +
                    data?.sunNormStartMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "hh:mm"
              ) +
              " - " +
              format(
                parse(
                  data?.sunNormEnd?.toString() +
                    ":" +
                    data?.sunNormEndMin?.toString(),
                  "H:m",
                  new Date()
                ),
                "k:mm"
              )
            }
          />
          <Separator className="my-5" />
          <div className="space-y-1">
            <h3 className="text-lg font-bold">Hourly Rates</h3>
            <p className="text-sm text-slate-600">
              Hourly rates for normal working hour and overtime hours
            </p>
          </div>
          <AppItemInfo
            label="Normal Rate"
            icon={<CircleDollarSign />}
            content={<p>{data?.normalRate}</p>}
          />
          <AppItemInfo
            label="Overtime Rate"
            icon={<CircleDollarSign />}
            content={<p>{data?.overtimeRate}</p>}
          />
        </>
      )}
    </div>
  );
}
