import { useQueryClient } from "@tanstack/react-query";
import {
  useUserControllerGetOneUser,
  useUserControllerUpdateUserRole,
} from "../../../../api/users/users";
import AppProjectSelect from "../../../AppProjectSelect";
import { useAppSelector } from "../../../../redux/store";
import { useRolesControllerGetRoles } from "../../../../api/roles/roles";
import { Badge } from "../../../ui/badge";
import { Check, UserCircle } from "lucide-react";
import { Separator } from "../../../ui/separator";
import useCurrentUser from "../../../../hooks/useCurrentUser/useCurrentUser";
import { useCallback } from "react";
import { cn } from "../../../../utils/util";
import SettingSelectProj from "../components/SettingSelectProj";
import { useSelectedSettingProjectId } from "../../../../stores/settingStore/settingStore";
import { useSearchParams } from "react-router-dom";

export default function RolesTab() {
  const [searchParams] = useSearchParams();
  const qc = useQueryClient();
  const selectedProjId = useSelectedSettingProjectId();
  const firebaseUserId = searchParams.get("firebaseId") as string;

  const { data: user, isLoading } = useUserControllerGetOneUser(
    firebaseUserId ?? "",
    {
      query: {
        enabled: !!firebaseUserId,
        select: (res) => res.data,
      },
    }
  );

  const { data: roles } = useRolesControllerGetRoles(
    {
      projectId: selectedProjId ?? "",
    },
    {
      query: {
        enabled: !!selectedProjId,
        select: (res) => res.data,
      },
    }
  );

  const isRoleImplemented = useCallback(
    (roleId: number) => {
      return user?.roles?.some((r) => r.id === roleId);
    },
    [user?.roles]
  );

  const { mutate } = useUserControllerUpdateUserRole({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) =>
            (q.queryKey[0] as string).includes("user") ||
            (q.queryKey[0] as string).includes("defect-status"),
        });
      },
    },
  });

  const onClickUpdate = (roleId: number) => {
    if (!user?.id || !selectedProjId) return;
    mutate({
      userId: user.id,
      data: {
        projectId: Number(selectedProjId) ?? 0,
        roleId,
      },
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-6">
        <p className="text-sm text-slate-500">Current Project: </p>
        <SettingSelectProj />
      </div>
      {roles?.map((r) => {
        return (
          <div
            key={r.id}
            className="p-6 rounded-lg ring-2 ring-transparent bg-slate-100 flex flex-col gap-6 hover:cursor-pointer hover:ring-slate-300 transition-all duration-200"
            onClick={() => {
              onClickUpdate(r.id);
            }}
          >
            <div className="flex items-center gap-2">
              <div
                className={cn(
                  "p-2 rounded-full border border-slate-300 border-solid",
                  {
                    "bg-green-500": isRoleImplemented(r.id),
                  }
                )}
              >
                <Check
                  data-testid={`role-${r.name}-${
                    isRoleImplemented(r.id) && "selected"
                  }`}
                  className={cn("text-transparent", {
                    "text-white": isRoleImplemented(r.id),
                  })}
                />
              </div>
              <div className="bg-primary-200 rounded-xl p-3">
                <UserCircle className="text-primary-900" />
              </div>
              <p className="font-bold text-lg">{r?.name?.toUpperCase()} Role</p>
              {/* {isRoleImplemented(r.id) && (
                <Badge className="bg-green-500 flex items-center gap-2">
                  <Check className="text-white h-4 w-4" />
                  Selected
                </Badge>
              )} */}
            </div>
            <Separator />
            <div className="flex flex-col gap-2">
              <p className="font-bold">Work Orders</p>
              <p className="text-sm text-slate-400">
                Statuses that the user have access to for Work Order Module
              </p>
              <div className="flex gap-2">
                {r.defectStatuses.map((d) => (
                  <Badge
                    key={d.id}
                    className="p-1 bg-primary-900 rounded-lg"
                    style={{ backgroundColor: `#${d.colorHex}` }}
                  >
                    <p className="text-white text-sm font-bold">{d.name}</p>
                  </Badge>
                ))}
              </div>
            </div>
            <Separator />
            <div className="flex flex-col gap-2">
              <p className="font-bold">Preventive Maintenance</p>
              <p className="text-sm text-slate-400">
                Statuses that the user have access to for PM Module
              </p>
              <div className="flex gap-2">
                {r.pmStatuses.map((pm) => (
                  <div
                    key={pm.id}
                    className="p-1 bg-primary-900 rounded-lg"
                    style={{ backgroundColor: `#${pm.colorHex}` }}
                  >
                    <p className="text-white text-sm font-bold">{pm.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
