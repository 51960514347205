import { create } from "zustand";
import { Project } from "../../api/model";

interface SettingStoreState {
  selectedProjectId: string | null | undefined;
  projectList: Project[];
  actions: {
    setProjectList: (projects: Project[]) => void;
    setSelectedProject: (projId: string) => void;
    cleanUp: () => void;
  };
}

export const useSettingStore = create<SettingStoreState>((set) => ({
  selectedProjectId: null,
  projectList: [],
  actions: {
    setProjectList: (projects) => {
      set(() => ({ projectList: projects }));
    },
    setSelectedProject: (projId) => {
      set(() => ({ selectedProjectId: projId }));
    },
    cleanUp: () => {
      set(() => ({ selectedProject: null, projectList: [] }));
    },
  },
}));

export const useSettingProjectLists = () =>
  useSettingStore((state) => state.projectList);
export const useSelectedSettingProjectId = () =>
  useSettingStore((state) => state.selectedProjectId);
export const useSettingProjectActions = () =>
  useSettingStore((state) => state.actions);
