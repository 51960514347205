import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import { useEscalationControllerGetOneEscalation } from "../../../../api/escalation/escalation";
import AppItemInfo from "../../../AppItemInfo";
import { Bookmark } from "lucide-react";
import { EscalationType } from "../../../../api/model";
import { escalationWorkRequestStatus } from "../components/EscalationSelectStatusField";
import SettingSummary from "../components/SettingSummary";

const escalationTypeMapper = {
  [EscalationType.WORK_ORDER]: "Work Order",
  [EscalationType.PM]: "Preventive Maintenance",
  [EscalationType.WORK_REQUEST]: "Work Request",
};

export default function EscalationInfoTab() {
  const [searchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const escalationId = searchParams.get("escalationId");

  const { data: escalation, isLoading } =
    useEscalationControllerGetOneEscalation(
      escalationId ?? "-",
      {
        projectId: activeProj?.id ?? 0,
      },
      {
        query: {
          enabled: !!escalationId && !!activeProj,
          select: (res) => res.data.data,
        },
      }
    );

  return (
    <div className="space-y-5 my-4">
      <AppItemInfo
        icon={<Bookmark className="text-primary-900" />}
        label="Name"
        content={<p>{escalation?.name ?? "-"}</p>}
      />
      <AppItemInfo
        icon={<Bookmark className="text-primary-900" />}
        label="Escalation Type"
        content={
          <p>
            {escalation?.escalationType
              ? escalationTypeMapper[escalation.escalationType]
              : "-"}
          </p>
        }
      />
      {escalation?.escalationType === EscalationType.WORK_ORDER && (
        <AppItemInfo
          icon={<Bookmark className="text-primary-900" />}
          label="Affected Status"
          content={
            <p>
              {escalation?.defectStatus?.map((def) => def.name).join(", ") ??
                "-"}
            </p>
          }
        />
      )}
      {escalation?.escalationType === EscalationType.PM && (
        <AppItemInfo
          icon={<Bookmark className="text-primary-900" />}
          label="Affected Status"
          content={
            <p>
              {escalation?.pmStatus?.map((pm) => pm.name).join(", ") ?? "-"}
            </p>
          }
        />
      )}
      {escalation?.escalationType === EscalationType.WORK_REQUEST && (
        <AppItemInfo
          icon={<Bookmark className="text-primary-900" />}
          label="Affected Status"
          content={
            <p>
              {escalation?.workRequestStatus
                ?.map(
                  (wr) =>
                    escalationWorkRequestStatus.find(
                      (ews) => ews.value === wr.workRequestStatus
                    )?.name
                )
                .join(", ") ?? "-"}
            </p>
          }
        />
      )}
      <AppItemInfo
        label="Number of days"
        icon={<Bookmark className="text-primary-900" />}
        content={<p>{escalation?.numberOfDays ?? 0}</p>}
      />
      <AppItemInfo
        label="Users to Notify"
        icon={<Bookmark className="text-primary-900" />}
        content={
          <p>{escalation?.notifyUsers?.map((u) => u.email).join(", ")}</p>
        }
      />
      <AppItemInfo
        label="Summary"
        icon={<Bookmark className="text-primary-900" />}
        content={
          <SettingSummary
            escalationType={
              escalation?.escalationType ?? EscalationType.WORK_ORDER
            }
            statusStrs={
              escalation?.escalationType === EscalationType.WORK_ORDER
                ? escalation?.defectStatus?.map((s) => s.name ?? "-")
                : escalation?.escalationType === EscalationType.PM
                ? escalation?.pmStatus?.map((s) => s.name ?? "-")
                : escalation?.workRequestStatus?.map(
                    (s) =>
                      escalationWorkRequestStatus.find(
                        (ews) => ews.value === s.workRequestStatus
                      )?.name ?? "-"
                  ) ?? []
            }
            noOfDays={escalation?.numberOfDays ?? 0}
            notifyUsersString={
              escalation?.notifyUsers?.map((s) => s.name ?? "-") ?? []
            }
          />
        }
      />
    </div>
  );
}
