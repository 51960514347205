import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  useLegalComplianceControllerGetLegalComplianceCount,
  useLegalComplianceControllerGetLegalCompliances,
} from "../../api/legal-compliance/legal-compliance";
import {
  CerevModule,
  LegalComplianceControllerGetLegalComplianceCountParams,
  LegalComplianceControllerGetLegalCompliancesParams,
} from "../../api/model";
import AppButton from "../../components/AppButton";
import {
  DRAWER_CREATE_STATE,
  LEGAL_COMPLIANCE_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import { DataTable } from "../../components/react-table/DataTable";
import useDate from "../../hooks/useDate";
import useDebounce from "../../hooks/useDebounce";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import { legalComplianceColumns } from "./components/LegalComplianceColumns";
import AppTextField from "../../components/AppTextField";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { trpc } from "../../lib/trpc";

export default function LegalComplianceScreen() {
  const { formatDateToLocal, diffInDays } = useDate();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const search = useWatch({ control: methods.control, name: "search" });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg =
    useDebounce<LegalComplianceControllerGetLegalCompliancesParams>({
      value: {
        projectId: activeProj?.id ?? 0,
        search,
        rowsPerPage: pagination.pageSize,
        page: pagination.pageIndex,
      },
      delay: 500,
    });

  const queryCountArg =
    useDebounce<LegalComplianceControllerGetLegalComplianceCountParams>({
      value: {
        projectId: activeProj?.id ?? 0,
        search,
      },
      delay: 500,
    });

  const {
    data: legals,
    isLoading,
    isFetching,
  } = useLegalComplianceControllerGetLegalCompliances(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const { data: legalsCountData, isLoading: legalsCountDataIsLoading } =
    useLegalComplianceControllerGetLegalComplianceCount(queryCountArg, {
      query: {
        enabled: !!activeProj,
      },
    });

  const table = useReactTable({
    data: legals?.data ?? [],
    columns: legalComplianceColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: legalsCountData?.data?.count
      ? Math.ceil(legalsCountData?.data?.count / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <ModuleAccessGuard module={CerevModule.LEGAL}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4">
          <AppTitle title="Legal Compliances" />
          <AppButton
            label="+ New"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", LEGAL_COMPLIANCE_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
        <FormProvider {...methods}>
          <AppTextField
            name="search"
            placeholder="Search Name, Description..."
            className="w-[400px]"
          />
          <DataTable
            isFetching={isFetching}
            isLoading={isLoading}
            table={table}
            totalCount={legalsCountData?.data?.count ?? 0}
            rowsPerPage={pagination.pageSize}
            pageIdx={pagination.pageIndex}
          />
        </FormProvider>
      </div>
    </ModuleAccessGuard>
  );
}
