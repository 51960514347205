import {Alert, Snackbar} from "@mui/material";
import {setCloseSnackBar, SnackBarType} from "../../redux/slices/RootSlice";
import {useAppDispatch, useAppSelector} from "../../redux/store";

export default function GlobalSnackbar() {
  const open = useAppSelector((state) => state.root.showSnackbar);
  const message = useAppSelector((state) => state.root.snackBarMsg);
  const snackBarType = useAppSelector((state) => state.root.snackbarStatus);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setCloseSnackBar());
  };
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
    >
      <Alert
        onClose={handleClose}
        severity={snackBarType}
        elevation={6}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
