import { useParams, useSearchParams } from "react-router-dom";
import {
  useCompaniesControllerGetOneCompany,
  useCompaniesControllerGetProjectsUnderCompany,
} from "../../../../../../api/companies/companies";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { projectColumns } from "../projectColumns";
import { DataTable } from "../../../../../../components/react-table/DataTable";
import AppButton from "../../../../../../components/AppButton";
import {
  DRAWER_CREATE_STATE,
  PROJECT_FORM_DRAWER,
} from "../../../../../../components/drawers/AppDrawer";

export default function ProjectSection() {
  const { companyId } = useParams();
  const { data: companyData } = useCompaniesControllerGetOneCompany(
    companyId as string,
    {
      query: {
        enabled: !!companyId,
        select: (res) => res.data,
      },
    }
  );
  const [, setSearchParams] = useSearchParams();
  const {
    data: projectList,
    isLoading,
    isFetching,
  } = useCompaniesControllerGetProjectsUnderCompany(companyId as string, {
    query: {
      enabled: !!companyId,
      select: (res) => res.data,
    },
  });

  const table = useReactTable({
    data: projectList ?? [],
    columns: projectColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <>
      <div className="flex gap-6 items-center">
        <h3 className="text-2xl font-bold">Projects</h3>
        <AppButton
          label="+ Add"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerState", DRAWER_CREATE_STATE);
              p.set("drawerType", PROJECT_FORM_DRAWER);
              // Pre select company
              p.set("companyId", companyId as string);
              p.set("companyName", companyData?.name ?? "-");
              return p;
            });
          }}
        />
      </div>
      <DataTable
        table={table}
        totalCount={projectList?.length ?? 0}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </>
  );
}
