import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Divider } from "./Divider";
import { ItemSection } from "./ItemSection";

export default function WorkAssessmentSection() {
  return (
    <View style={{ gap: 2 }}>
      <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
        C. Work Assessment
      </Text>
      <Divider />
      <View style={{ display: "flex", flexDirection: "row" }}>
        <ItemSection label="Asset Group Code" value="" />
        <ItemSection label="Fault Item" value="" />
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <ItemSection label="Fault Code" value="" />
        <ItemSection label="Sub Item" value="" />
      </View>
      <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <ItemSection
          valueWidth="80%"
          labelWidth="20%"
          label="Corrective Action (attach separate document if necessary)"
          value=""
        />
      </View>
      <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <ItemSection
          valueWidth="80%"
          labelWidth="20%"
          label="Root Cause (attach separate document if necessary)"
          value=""
        />
      </View>
    </View>
  );
}
