import { useAuthControllerGetCurrentUser } from "../../api/auth/auth";
import {
  PolicyControllerCheckOneUserPolicyPolicyName,
  UpdateUserPolicyRequestDtoPolicyStatus,
} from "../../api/model";
import { usePolicyControllerCheckOneUserPolicy } from "../../api/policy/policy";
import { useAppSelector } from "../../redux/store";

interface usePoliciesProps {
  policyName: PolicyControllerCheckOneUserPolicyPolicyName;
}

export default function usePolicies({ policyName }: usePoliciesProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: user } = useAuthControllerGetCurrentUser({
    query: {
      select: (res) => res.data,
    },
  });

  const { data: policyStatus } = usePolicyControllerCheckOneUserPolicy(
    user?.id?.toString() ?? "",
    {
      policyName: policyName,
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        select: (res) => res.data.data,
      },
    }
  );

  return policyStatus === UpdateUserPolicyRequestDtoPolicyStatus.ALLOWED;
}

export const useHasInventoryAdjustmentPolicy = () =>
  usePolicies({
    policyName: PolicyControllerCheckOneUserPolicyPolicyName.ADJUST_INVENTORY,
  });

export const useHasWorkOrderPricingPolicy = () =>
  usePolicies({
    policyName:
      PolicyControllerCheckOneUserPolicyPolicyName.CHECK_WORK_ORDER_PRICING,
  });

export const useHasWorkOrderEditAfterClosePolicy = () =>
  usePolicies({
    policyName:
      PolicyControllerCheckOneUserPolicyPolicyName.WORK_ORDER_EDIT_AFTER_CLOSE,
  });

export const useHasViewPrivateAttachmentPolicy = () =>
  usePolicies({
    policyName:
      PolicyControllerCheckOneUserPolicyPolicyName.VIEW_PRIVATE_ATTACHMENT,
  });
