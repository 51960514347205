import { Location } from './../OpenApi/cerevApi';
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedLocation: null
} as {
    selectedLocation: Location | null;
}

export const AssetSlice = createSlice({
    name: "asset",
    initialState,
    reducers: {
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload;
        }
    }
})

export const { setSelectedLocation } = AssetSlice.actions;