import { z } from "zod";
import useIsUpdateDrawerState from "../../hooks/useDrawerState";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  useDefectTypeControllerCreateDefectTypeMutation,
  useDefectTypeControllerGetOneDefectTypeQuery,
  useDefectTypeControllerUpdateDefectTypeMutation,
} from "../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../redux/store";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useError from "../../hooks/useError";
import useSuccess from "../../hooks/useSuccess";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import { useGetUserDataQuery } from "../../redux/slices/Auth/AuthApi";
import { Typography } from "@mui/material";
import AppTextField from "../AppTextField";
import AppButton from "../AppButton";
import {
  useDefectTypeControllerCreateDefectType,
  useDefectTypeControllerGetOneDefectType,
  useDefectTypeControllerUpdateDefectType,
} from "../../api/defect-type/defect-type";
import { useQueryClient } from "@tanstack/react-query";

const WorkOrderTypeFormSchema = z.object({
  name: z.string().min(1),
});

type WorkOrderTypeForm = z.infer<typeof WorkOrderTypeFormSchema>;

export default function WorkOrderTypeFormDrawer() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const workOrderTypeParentId = searchParams.get("workOrderTypeParentId");
  const isUpdateDrawer = useIsUpdateDrawerState();
  const { data: user } = useGetUserDataQuery();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<WorkOrderTypeForm>({
    resolver: zodResolver(WorkOrderTypeFormSchema),
    defaultValues: {
      name: "",
    },
  });

  const {
    mutate: createWorkOrderType,
    isPending: createWorkOrderTypeIsPending,
  } = useDefectTypeControllerCreateDefectType({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("defect-type"),
        });
        setSearchParams(new URLSearchParams());
      },
    },
  });

  const {
    mutate: updateWorkOrderType,
    isPending: updateWorkOrderTypeIsPending,
  } = useDefectTypeControllerUpdateDefectType({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("defect-type"),
        });
        setSearchParams(new URLSearchParams());
      },
    },
  });

  const { data: editWorkOrderType, isLoading: editWorkOrderTypeIsLoading } =
    useDefectTypeControllerGetOneDefectType(
      searchParams.get("defectTypeId") ?? "",
      {
        query: {
          enabled: isUpdateDrawer && !!activeComp && !!activeProj,
          select: (res) => res.data,
        },
      }
    );

  // isEdit Mode
  useEffect(() => {
    if (isUpdateDrawer && editWorkOrderType) {
      methods.setValue("name", editWorkOrderType.name);
    }
  }, [searchParams, editWorkOrderType]);

  const onSubmit: SubmitHandler<WorkOrderTypeForm> = (data) => {
    if (!activeComp || !user || !activeProj || !activeProj.id) return;

    if (isUpdateDrawer && editWorkOrderType) {
      return updateWorkOrderType({
        data: {
          name: data.name,
          updatedById: user.id,
        },
        defectTypeId: editWorkOrderType.id.toString(),
      });
    }

    createWorkOrderType({
      data: {
        name: data.name,
        companyId: activeComp.id,
        createdById: user.id,
        projectId: activeProj.id,
      },
    });
  };

  if (editWorkOrderTypeIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Work Order Type
        </p>
        <AppTextField name="name" label="Name" />
        <div className="flex">
          <AppButton
            isLoading={
              createWorkOrderTypeIsPending || updateWorkOrderTypeIsPending
            }
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </FormProvider>
  );
}
