import { useSearchParams } from "react-router-dom";
import { useDefectControllerGetOneDefect } from "../../../../../api/work-orders-defects/work-orders-defects";
import AppItemInfo from "../../../../AppItemInfo";
import { Calendar, CircleDollarSign, Hash, Text, User } from "lucide-react";
import useDate from "../../../../../hooks/useDate";
import ExtraFieldFormDialog from "./ExtraFieldFormDialog";

const remedialExpensesMapper = {
  INHOUSE_REMEDIAL_EXPENSES: "Inhouse Remedial Expenses",
  OUTSOURCE_REMEDIAL_EXPENSES: "Outsource Remedial Expenses",
};

export default function ExtraFieldTab() {
  const [searchParam, ] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");
  const { formatDateToLocal } = useDate();

  const { data: extraExpensesField } = useDefectControllerGetOneDefect(
    activeWoId ?? "",
    {
      query: {
        enabled: !!activeWoId,
        select: (res) => res.data.extraExpensesField,
      },
    }
  );

  return (
    <div className="grid grid-cols-2 gap-4">
      <AppItemInfo
        label="Remedial Category"
        content={
          <p>
            {extraExpensesField?.remedialCategory
              ? remedialExpensesMapper[extraExpensesField.remedialCategory]
              : "-"}
          </p>
        }
        icon={<Text className="text-primary-900" />}
      />
      <AppItemInfo
        label="Material Cost Purchased (RM)"
        content={<p>{extraExpensesField?.materialCostPurchased ?? "-"}</p>}
        icon={<CircleDollarSign className="text-primary-900" />}
      />
      <AppItemInfo
        label="Overtime Cost (RM)"
        content={<p>{extraExpensesField?.overtimeCost ?? "-"}</p>}
        icon={<CircleDollarSign className="text-primary-900" />}
      />
      <AppItemInfo
        label="Transportation Cost (RM)"
        content={<p>{extraExpensesField?.transportationCost ?? "-"}</p>}
        icon={<CircleDollarSign className="text-primary-900" />}
      />
      <AppItemInfo
        label="Rental of Equipment Cost (RM)"
        content={<p>{extraExpensesField?.rentalOfEquipmentCost ?? "-"}</p>}
        icon={<CircleDollarSign className="text-primary-900" />}
      />
      <AppItemInfo
        label="Start Date"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p>
            {extraExpensesField?.startDate
              ? formatDateToLocal({
                  dateStr: extraExpensesField.startDate,
                })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Completed Date"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p>
            {extraExpensesField?.completionDate
              ? formatDateToLocal({
                  dateStr: extraExpensesField.completionDate,
                })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Total Cost (RM)"
        icon={<CircleDollarSign className="text-primary-900" />}
        content={<p>{extraExpensesField?.totalCost ?? "-"}</p>}
      />
      <AppItemInfo
        label="Vendor Appointed"
        icon={<User className="text-primary-900" />}
        content={<p>{extraExpensesField?.vendorAppointed ?? "-"}</p>}
      />
      <AppItemInfo
        label="LOA Reference"
        icon={<Hash className="text-primary-900" />}
        content={<p>{extraExpensesField?.loaReference ?? "-"}</p>}
      />
      <AppItemInfo
        label="PO Reference"
        icon={<Hash className="text-primary-900" />}
        content={<p>{extraExpensesField?.poReference ?? "-"}</p>}
      />
      <div className="col-span-2">
        <ExtraFieldFormDialog />
      </div>
    </div>
  );
}
