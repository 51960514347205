import { RootApi as api } from "../RootApi";
export const addTagTypes = [
  "Users",
  "Administration",
  "Work Request",
  "Meter",
  "Assets",
  "Asset Type",
  "Attachments",
  "Auth",
  "Auth V2",
  "Defect Status",
  "Comments",
  "Companies",
  "Work Orders / Defects",
  "Projects",
  "Storage",
  "Preventive Maintenance Checklist Status",
  "Preventive Maintenance Checklists",
  "Location",
  "Defect Type",
  "Preventive Maintenance Template",
  "Preventive Maintenance Format Type",
  "Work Order / Defect V2",
  "Stock / Inventory Types",
  "Stocks / Inventory",
  "Vendor",
  "Workscope",
  "Defect Status V2",
  "Roles",
  "Work permits",
  "Meter Reading",
  "Notification",
  "General Analytics",
  "Meter Reading V2",
  "Iot Meters",
  "Iot Meter Readings",
  "Legal Compliance",
  "Cron",
  "Account Type",
  "Tnb Bill",
  "Escalation",
  "Settings Imports",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      appControllerTesting: build.query<
        AppControllerTestingApiResponse,
        AppControllerTestingApiArg
      >({
        query: () => ({ url: `/api/test` }),
      }),
      userControllerGetUsers: build.query<
        UserControllerGetUsersApiResponse,
        UserControllerGetUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/users`,
          params: {
            companyId: queryArg.companyId,
            projectId: queryArg.projectId,
            userId: queryArg.userId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Users"],
      }),
      userControllerGetUserCount: build.query<
        UserControllerGetUserCountApiResponse,
        UserControllerGetUserCountApiArg
      >({
        query: (queryArg) => ({
          url: `/users/count`,
          params: {
            companyId: queryArg.companyId,
            projectId: queryArg.projectId,
            userId: queryArg.userId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Users"],
      }),
      userControllerGetOneUser: build.query<
        UserControllerGetOneUserApiResponse,
        UserControllerGetOneUserApiArg
      >({
        query: (queryArg) => ({ url: `/users/${queryArg.firebaseUserId}` }),
        providesTags: ["Users"],
      }),
      userControllerDeleteAttachment: build.mutation<
        UserControllerDeleteAttachmentApiResponse,
        UserControllerDeleteAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.userId}/attachments/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Users"],
      }),
      userControllerUpdateUser: build.mutation<
        UserControllerUpdateUserApiResponse,
        UserControllerUpdateUserApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.userId}`,
          method: "PATCH",
          body: queryArg.updateUserV2Dto,
        }),
        invalidatesTags: ["Users"],
      }),
      userControllerUpdateUserVendor: build.mutation<
        UserControllerUpdateUserVendorApiResponse,
        UserControllerUpdateUserVendorApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.userId}/vendor`,
          method: "PATCH",
          body: queryArg.updateUserVendorDto,
        }),
        invalidatesTags: ["Users"],
      }),
      userControllerRemoveUserVendor: build.mutation<
        UserControllerRemoveUserVendorApiResponse,
        UserControllerRemoveUserVendorApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.userId}/vendor`,
          method: "DELETE",
        }),
        invalidatesTags: ["Users"],
      }),
      userControllerUpdateUserProjects: build.mutation<
        UserControllerUpdateUserProjectsApiResponse,
        UserControllerUpdateUserProjectsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.userId}/projects`,
          method: "PATCH",
          body: queryArg.updateUserProjectDto,
        }),
        invalidatesTags: ["Users"],
      }),
      adminControllerFirstTimePasswordChange: build.mutation<
        AdminControllerFirstTimePasswordChangeApiResponse,
        AdminControllerFirstTimePasswordChangeApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/firstTimePassword`,
          method: "PATCH",
          body: queryArg.firstTimeUserPasswordDto,
        }),
        invalidatesTags: ["Administration"],
      }),
      workRequestControllerCreateWorkRequestInternally: build.mutation<
        WorkRequestControllerCreateWorkRequestInternallyApiResponse,
        WorkRequestControllerCreateWorkRequestInternallyApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request/internal`,
          method: "POST",
          body: queryArg.createWorkRequestInternalDto,
        }),
        invalidatesTags: ["Work Request"],
      }),
      workRequestControllerCreateWorkRequest: build.mutation<
        WorkRequestControllerCreateWorkRequestApiResponse,
        WorkRequestControllerCreateWorkRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request`,
          method: "POST",
          body: queryArg.createWorkRequestDto,
        }),
        invalidatesTags: ["Work Request"],
      }),
      workRequestControllerGetWorkRequests: build.query<
        WorkRequestControllerGetWorkRequestsApiResponse,
        WorkRequestControllerGetWorkRequestsApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request`,
          params: {
            workRequestStatus: queryArg.workRequestStatus,
            locFilterId: queryArg.locFilterId,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
            projectId: queryArg.projectId,
            showUnlinkedOnly: queryArg.showUnlinkedOnly,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Work Request"],
      }),
      workRequestControllerGetWorkRequestBasedFromCode: build.query<
        WorkRequestControllerGetWorkRequestBasedFromCodeApiResponse,
        WorkRequestControllerGetWorkRequestBasedFromCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request/workRequestCode/${queryArg.workRequestCode}`,
        }),
        providesTags: ["Work Request"],
      }),
      workRequestControllerDeleteWorkRequest: build.mutation<
        WorkRequestControllerDeleteWorkRequestApiResponse,
        WorkRequestControllerDeleteWorkRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request/work-request-code/${queryArg.workRequestCode}`,
          method: "DELETE",
          body: queryArg.deleteWorkRequestDto,
        }),
        invalidatesTags: ["Work Request"],
      }),
      workRequestControllerGetWorkRequestStatusCount: build.query<
        WorkRequestControllerGetWorkRequestStatusCountApiResponse,
        WorkRequestControllerGetWorkRequestStatusCountApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request/status-count`,
          params: {
            projectId: queryArg.projectId,
            search: queryArg.search,
            locFilterId: queryArg.locFilterId,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
          },
        }),
        providesTags: ["Work Request"],
      }),
      workRequestControllerGetWorkRequestQrCode: build.query<
        WorkRequestControllerGetWorkRequestQrCodeApiResponse,
        WorkRequestControllerGetWorkRequestQrCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request/qrcode`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Work Request"],
      }),
      workRequestControllerGetOneWorkRequest: build.query<
        WorkRequestControllerGetOneWorkRequestApiResponse,
        WorkRequestControllerGetOneWorkRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/work-request/${queryArg.workRequestId}`,
        }),
        providesTags: ["Work Request"],
      }),
      analyticMeterControllerGetMeterReading: build.query<
        AnalyticMeterControllerGetMeterReadingApiResponse,
        AnalyticMeterControllerGetMeterReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics/meter/meterTrend`,
          params: {
            meterId: queryArg.meterId,
            monthNum: queryArg.monthNum,
            yearNum: queryArg.yearNum,
          },
        }),
      }),
      meterControllerGetMeters: build.query<
        MeterControllerGetMetersApiResponse,
        MeterControllerGetMetersApiArg
      >({
        query: (queryArg) => ({
          url: `/meter`,
          params: { projectId: queryArg.projectId, search: queryArg.search },
        }),
        providesTags: ["Meter"],
      }),
      meterControllerCreateMeter: build.mutation<
        MeterControllerCreateMeterApiResponse,
        MeterControllerCreateMeterApiArg
      >({
        query: (queryArg) => ({
          url: `/meter`,
          method: "POST",
          body: queryArg.createMeterDto,
        }),
        invalidatesTags: ["Meter"],
      }),
      meterControllerGetOneMeter: build.query<
        MeterControllerGetOneMeterApiResponse,
        MeterControllerGetOneMeterApiArg
      >({
        query: (queryArg) => ({ url: `/meter/${queryArg.meterId}` }),
        providesTags: ["Meter"],
      }),
      meterControllerUpdateMeter: build.mutation<
        MeterControllerUpdateMeterApiResponse,
        MeterControllerUpdateMeterApiArg
      >({
        query: (queryArg) => ({
          url: `/meter/${queryArg.meterId}`,
          method: "PATCH",
          body: queryArg.updateMeterDto,
        }),
        invalidatesTags: ["Meter"],
      }),
      meterControllerDeleteMeter: build.mutation<
        MeterControllerDeleteMeterApiResponse,
        MeterControllerDeleteMeterApiArg
      >({
        query: (queryArg) => ({
          url: `/meter/${queryArg.meterId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Meter"],
      }),
      assetControllerGetFullAssets: build.query<
        AssetControllerGetFullAssetsApiResponse,
        AssetControllerGetFullAssetsApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/getFullAssets`,
          params: {
            projectId: queryArg.projectId,
            search: queryArg.search,
            assetTypeIds: queryArg.assetTypeIds,
          },
        }),
        providesTags: ["Assets"],
      }),
      assetControllerGetAssets: build.query<
        AssetControllerGetAssetsApiResponse,
        AssetControllerGetAssetsApiArg
      >({
        query: (queryArg) => ({
          url: `/asset`,
          params: {
            projectId: queryArg.projectId,
            locationId: queryArg.locationId,
            search: queryArg.search,
            assetTypeIds: queryArg.assetTypeIds,
          },
        }),
        providesTags: ["Assets"],
      }),
      assetControllerCreateAsset: build.mutation<
        AssetControllerCreateAssetApiResponse,
        AssetControllerCreateAssetApiArg
      >({
        query: (queryArg) => ({
          url: `/asset`,
          method: "POST",
          body: queryArg.createAssetDto,
        }),
        invalidatesTags: ["Assets"],
      }),
      assetControllerGetOneAsset: build.query<
        AssetControllerGetOneAssetApiResponse,
        AssetControllerGetOneAssetApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/${queryArg.assetId}`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Assets"],
      }),
      assetControllerUpdateAsset: build.mutation<
        AssetControllerUpdateAssetApiResponse,
        AssetControllerUpdateAssetApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/${queryArg.assetId}`,
          method: "PATCH",
          body: queryArg.updateAssetDto,
        }),
        invalidatesTags: ["Assets"],
      }),
      assetControllerDeleteAsset: build.mutation<
        AssetControllerDeleteAssetApiResponse,
        AssetControllerDeleteAssetApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/${queryArg.assetId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Assets"],
      }),
      assetControllerDeleteAttachment: build.mutation<
        AssetControllerDeleteAttachmentApiResponse,
        AssetControllerDeleteAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/${queryArg.assetId}/additionalDocuments/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Assets"],
      }),
      assetControllerDeletePhoto: build.mutation<
        AssetControllerDeletePhotoApiResponse,
        AssetControllerDeletePhotoApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/${queryArg.assetId}/photos/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Assets"],
      }),
      assetControllerGetAssetWorkOrders: build.query<
        AssetControllerGetAssetWorkOrdersApiResponse,
        AssetControllerGetAssetWorkOrdersApiArg
      >({
        query: (queryArg) => ({ url: `/asset/${queryArg.assetId}/defects` }),
        providesTags: ["Assets"],
      }),
      assetControllerGetAssetPm: build.query<
        AssetControllerGetAssetPmApiResponse,
        AssetControllerGetAssetPmApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/${queryArg.assetId}/pm`,
          params: { cursorId: queryArg.cursorId },
        }),
        providesTags: ["Assets"],
      }),
      assetControllerGenerateQrCode: build.query<
        AssetControllerGenerateQrCodeApiResponse,
        AssetControllerGenerateQrCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/project/${queryArg.projectId}/generate-qr-code`,
        }),
        providesTags: ["Assets"],
      }),
      assetControllerGetQrCode: build.query<
        AssetControllerGetQrCodeApiResponse,
        AssetControllerGetQrCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/asset/project/${queryArg.projectId}/get-qr-code`,
        }),
        providesTags: ["Assets"],
      }),
      assetTypeControllerGetAssetType: build.query<
        AssetTypeControllerGetAssetTypeApiResponse,
        AssetTypeControllerGetAssetTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/asset-type`,
          params: {
            companyId: queryArg.companyId,
            projectId: queryArg.projectId,
            search: queryArg.search,
          },
        }),
        providesTags: ["Asset Type"],
      }),
      assetTypeControllerCreateAssetType: build.mutation<
        AssetTypeControllerCreateAssetTypeApiResponse,
        AssetTypeControllerCreateAssetTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/asset-type`,
          method: "POST",
          body: queryArg.createAssetTypeDto,
        }),
        invalidatesTags: ["Asset Type"],
      }),
      assetTypeControllerGetAssetTypeCount: build.query<
        AssetTypeControllerGetAssetTypeCountApiResponse,
        AssetTypeControllerGetAssetTypeCountApiArg
      >({
        query: (queryArg) => ({
          url: `/asset-type/count`,
          params: {
            companyId: queryArg.companyId,
            projectId: queryArg.projectId,
            search: queryArg.search,
          },
        }),
        providesTags: ["Asset Type"],
      }),
      assetTypeControllerGetOneAssetType: build.query<
        AssetTypeControllerGetOneAssetTypeApiResponse,
        AssetTypeControllerGetOneAssetTypeApiArg
      >({
        query: (queryArg) => ({ url: `/asset-type/${queryArg.assetTypeId}` }),
        providesTags: ["Asset Type"],
      }),
      assetTypeControllerUpdateAssetType: build.mutation<
        AssetTypeControllerUpdateAssetTypeApiResponse,
        AssetTypeControllerUpdateAssetTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/asset-type/${queryArg.assetTypeId}`,
          method: "PATCH",
          body: queryArg.updateAssetTypeDto,
        }),
        invalidatesTags: ["Asset Type"],
      }),
      assetTypeControllerDeleteAssetType: build.mutation<
        AssetTypeControllerDeleteAssetTypeApiResponse,
        AssetTypeControllerDeleteAssetTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/asset-type/${queryArg.assetTypeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Asset Type"],
      }),
      attachmentControllerGetSignedUrl: build.query<
        AttachmentControllerGetSignedUrlApiResponse,
        AttachmentControllerGetSignedUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/attachment/${queryArg.attachmentId}/getSignedUrl`,
        }),
        providesTags: ["Attachments"],
      }),
      authControllerGetCurrentUser: build.query<
        AuthControllerGetCurrentUserApiResponse,
        AuthControllerGetCurrentUserApiArg
      >({
        query: () => ({ url: `/auth/user` }),
        providesTags: ["Auth"],
      }),
      authControllerUpdateUser: build.mutation<
        AuthControllerUpdateUserApiResponse,
        AuthControllerUpdateUserApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/user`,
          method: "PATCH",
          body: queryArg.updateUserDto,
        }),
        invalidatesTags: ["Auth"],
      }),
      authControllerDeleteUser: build.mutation<
        AuthControllerDeleteUserApiResponse,
        AuthControllerDeleteUserApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/user`,
          method: "DELETE",
          body: queryArg.deleteUserDto,
        }),
        invalidatesTags: ["Auth"],
      }),
      authControllerUpdateToken: build.mutation<
        AuthControllerUpdateTokenApiResponse,
        AuthControllerUpdateTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/user/token`,
          method: "PATCH",
          body: queryArg.updateDeviceTokenDto,
        }),
        invalidatesTags: ["Auth"],
      }),
      authControllerForgotPassword: build.mutation<
        AuthControllerForgotPasswordApiResponse,
        AuthControllerForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/forgotPassword`,
          method: "POST",
          params: { email: queryArg.email },
        }),
        invalidatesTags: ["Auth"],
      }),
      authV2ControllerGetUsers: build.query<
        AuthV2ControllerGetUsersApiResponse,
        AuthV2ControllerGetUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/auth`,
          params: {
            projectId: queryArg.projectId,
            companyId: queryArg.companyId,
            search: queryArg.search,
          },
        }),
        providesTags: ["Auth V2"],
      }),
      authV2ControllerCreateNewUser: build.mutation<
        AuthV2ControllerCreateNewUserApiResponse,
        AuthV2ControllerCreateNewUserApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/auth`,
          method: "POST",
          body: queryArg.createNewUserDto,
        }),
        invalidatesTags: ["Auth V2"],
      }),
      authV2ControllerUpdateUser: build.mutation<
        AuthV2ControllerUpdateUserApiResponse,
        AuthV2ControllerUpdateUserApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/auth/${queryArg.userId}`,
          method: "PATCH",
          body: queryArg.updateUserV2Dto,
        }),
        invalidatesTags: ["Auth V2"],
      }),
      defectStatusControllerGetDefectStatus: build.query<
        DefectStatusControllerGetDefectStatusApiResponse,
        DefectStatusControllerGetDefectStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-status`,
          params: { companyId: queryArg.companyId },
        }),
        providesTags: ["Defect Status"],
      }),
      defectStatusControllerCreateProjectBasedDefectStatus: build.mutation<
        DefectStatusControllerCreateProjectBasedDefectStatusApiResponse,
        DefectStatusControllerCreateProjectBasedDefectStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-status`,
          method: "POST",
          body: queryArg.createProjectDefectStatusDto,
        }),
        invalidatesTags: ["Defect Status"],
      }),
      defectStatusControllerGetAllWorkOrderStatus: build.query<
        DefectStatusControllerGetAllWorkOrderStatusApiResponse,
        DefectStatusControllerGetAllWorkOrderStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-status/all`,
          params: { companyId: queryArg.companyId },
        }),
        providesTags: ["Defect Status"],
      }),
      commentControllerGetComments: build.query<
        CommentControllerGetCommentsApiResponse,
        CommentControllerGetCommentsApiArg
      >({
        query: (queryArg) => ({
          url: `/comment`,
          params: { defectId: queryArg.defectId },
        }),
        providesTags: ["Comments"],
      }),
      commentControllerCreateComment: build.mutation<
        CommentControllerCreateCommentApiResponse,
        CommentControllerCreateCommentApiArg
      >({
        query: (queryArg) => ({
          url: `/comment`,
          method: "POST",
          body: queryArg.createCommentDto,
        }),
        invalidatesTags: ["Comments"],
      }),
      companiesControllerGetAllCompanies: build.query<
        CompaniesControllerGetAllCompaniesApiResponse,
        CompaniesControllerGetAllCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/companies`,
          params: { page: queryArg.page, rowsPerPage: queryArg.rowsPerPage },
        }),
        providesTags: ["Companies"],
      }),
      companiesControllerCreateCompany: build.mutation<
        CompaniesControllerCreateCompanyApiResponse,
        CompaniesControllerCreateCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/companies`,
          method: "POST",
          body: queryArg.createCompanyDto,
        }),
        invalidatesTags: ["Companies"],
      }),
      companiesControllerGetOneCompany: build.query<
        CompaniesControllerGetOneCompanyApiResponse,
        CompaniesControllerGetOneCompanyApiArg
      >({
        query: (queryArg) => ({ url: `/companies/${queryArg.companyId}` }),
        providesTags: ["Companies"],
      }),
      companiesControllerUpdateCompany: build.mutation<
        CompaniesControllerUpdateCompanyApiResponse,
        CompaniesControllerUpdateCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}`,
          method: "PATCH",
          body: queryArg.updateCompanyDto,
        }),
        invalidatesTags: ["Companies"],
      }),
      companiesControllerGetProjectsUnderCompany: build.query<
        CompaniesControllerGetProjectsUnderCompanyApiResponse,
        CompaniesControllerGetProjectsUnderCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/projects`,
        }),
        providesTags: ["Companies"],
      }),
      companiesControllerCreateProject: build.mutation<
        CompaniesControllerCreateProjectApiResponse,
        CompaniesControllerCreateProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/projects`,
          method: "POST",
          body: queryArg.createProjectDto,
        }),
        invalidatesTags: ["Companies"],
      }),
      companiesControllerGetRolesUnderCompany: build.query<
        CompaniesControllerGetRolesUnderCompanyApiResponse,
        CompaniesControllerGetRolesUnderCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/roles`,
        }),
        providesTags: ["Companies"],
      }),
      companiesControllerDeleteProject: build.mutation<
        CompaniesControllerDeleteProjectApiResponse,
        CompaniesControllerDeleteProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/projects/${queryArg.projectId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Companies"],
      }),
      companiesControllerGetOneProject: build.query<
        CompaniesControllerGetOneProjectApiResponse,
        CompaniesControllerGetOneProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/projects/${queryArg.projectId}`,
        }),
        providesTags: ["Companies"],
      }),
      companiesControllerUpdateProject: build.mutation<
        CompaniesControllerUpdateProjectApiResponse,
        CompaniesControllerUpdateProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/projects/${queryArg.projectId}`,
          method: "PATCH",
          body: queryArg.updateProjectDto,
        }),
        invalidatesTags: ["Companies"],
      }),
      companiesControllerDeleteProjectPhoto: build.mutation<
        CompaniesControllerDeleteProjectPhotoApiResponse,
        CompaniesControllerDeleteProjectPhotoApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/projects/${queryArg.projectId}/attachment/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Companies"],
      }),
      companiesControllerDeletePhoto: build.mutation<
        CompaniesControllerDeletePhotoApiResponse,
        CompaniesControllerDeletePhotoApiArg
      >({
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/attachment/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Companies"],
      }),
      defectControllerGetDefects: build.query<
        DefectControllerGetDefectsApiResponse,
        DefectControllerGetDefectsApiArg
      >({
        query: (queryArg) => ({
          url: `/defect`,
          params: {
            locationId: queryArg.locationId,
            defectStatusId: queryArg.defectStatusId,
            projectId: queryArg.projectId,
            vendorId: queryArg.vendorId,
            locFilterId: queryArg.locFilterId,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
            filterAssetTypeIds: queryArg.filterAssetTypeIds,
            filterRaisedByIds: queryArg.filterRaisedByIds,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Work Orders / Defects"],
      }),
      defectControllerCreateDefect: build.mutation<
        DefectControllerCreateDefectApiResponse,
        DefectControllerCreateDefectApiArg
      >({
        query: (queryArg) => ({
          url: `/defect`,
          method: "POST",
          body: queryArg.createWorkOrderDto,
        }),
        invalidatesTags: ["Work Orders / Defects"],
      }),
      defectControllerGetOneDefect: build.query<
        DefectControllerGetOneDefectApiResponse,
        DefectControllerGetOneDefectApiArg
      >({
        query: (queryArg) => ({ url: `/defect/${queryArg.defectId}` }),
        providesTags: ["Work Orders / Defects"],
      }),
      defectControllerUpdateDefect: build.mutation<
        DefectControllerUpdateDefectApiResponse,
        DefectControllerUpdateDefectApiArg
      >({
        query: (queryArg) => ({
          url: `/defect/${queryArg.defectId}`,
          method: "PATCH",
          body: queryArg.patchDefectDto,
        }),
        invalidatesTags: ["Work Orders / Defects"],
      }),
      defectControllerGetWorkOrderCount: build.query<
        DefectControllerGetWorkOrderCountApiResponse,
        DefectControllerGetWorkOrderCountApiArg
      >({
        query: (queryArg) => ({
          url: `/defect/status/${queryArg.workStatusId}/count`,
          params: {
            search: queryArg.search,
            projectId: queryArg.projectId,
            locFilterId: queryArg.locFilterId,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
            filterAssetTypeIds: queryArg.filterAssetTypeIds,
            filterRaisedByIds: queryArg.filterRaisedByIds,
          },
        }),
        providesTags: ["Work Orders / Defects"],
      }),
      defectControllerGetCurrentDefectStatus: build.query<
        DefectControllerGetCurrentDefectStatusApiResponse,
        DefectControllerGetCurrentDefectStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/defect/${queryArg.defectId}/defectStatus`,
        }),
        providesTags: ["Work Orders / Defects"],
      }),
      projectControllerGetProjects: build.query<
        ProjectControllerGetProjectsApiResponse,
        ProjectControllerGetProjectsApiArg
      >({
        query: (queryArg) => ({
          url: `/projects`,
          params: { userId: queryArg.userId, companyId: queryArg.companyId },
        }),
        providesTags: ["Projects"],
      }),
      storageControllerGetMbQuota: build.query<
        StorageControllerGetMbQuotaApiResponse,
        StorageControllerGetMbQuotaApiArg
      >({
        query: (queryArg) => ({
          url: `/storage`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Storage"],
      }),
      pmChecklistStatusControllerGetPmChecklistStatus: build.query<
        PmChecklistStatusControllerGetPmChecklistStatusApiResponse,
        PmChecklistStatusControllerGetPmChecklistStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist-status`,
          params: { companyId: queryArg.companyId, roleIds: queryArg.roleIds },
        }),
        providesTags: ["Preventive Maintenance Checklist Status"],
      }),
      pmChecklistStatusControllerGetAllPmChecklistStatus: build.query<
        PmChecklistStatusControllerGetAllPmChecklistStatusApiResponse,
        PmChecklistStatusControllerGetAllPmChecklistStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist-status/all`,
          params: { companyId: queryArg.companyId },
        }),
        providesTags: ["Preventive Maintenance Checklist Status"],
      }),
      pmChecklistControllerGetPmChecklist: build.query<
        PmChecklistControllerGetPmChecklistApiResponse,
        PmChecklistControllerGetPmChecklistApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist`,
          params: {
            projectId: queryArg.projectId,
            pmStatusId: queryArg.pmStatusId,
            assetId: queryArg.assetId,
            locationId: queryArg.locationId,
            assetTypeIds: queryArg.assetTypeIds,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerCreatePmChecklist: build.mutation<
        PmChecklistControllerCreatePmChecklistApiResponse,
        PmChecklistControllerCreatePmChecklistApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist`,
          method: "POST",
          body: queryArg.createPmChecklistDto,
        }),
        invalidatesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerGetAllPmChecklistsOpen: build.query<
        PmChecklistControllerGetAllPmChecklistsOpenApiResponse,
        PmChecklistControllerGetAllPmChecklistsOpenApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/status/openAll`,
          params: {
            companyId: queryArg.companyId,
            projectId: queryArg.projectId,
          },
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerGetPmChecklistStatusCount: build.query<
        PmChecklistControllerGetPmChecklistStatusCountApiResponse,
        PmChecklistControllerGetPmChecklistStatusCountApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/status/${queryArg.pmStatusId}/count`,
          params: {
            projectId: queryArg.projectId,
            assetTypeId: queryArg.assetTypeId,
            assetId: queryArg.assetId,
            locationId: queryArg.locationId,
            assetTypeIds: queryArg.assetTypeIds,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerGetPmChecklistTable: build.query<
        PmChecklistControllerGetPmChecklistTableApiResponse,
        PmChecklistControllerGetPmChecklistTableApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/fullTable`,
          params: {
            pmChecklistStatusId: queryArg.pmChecklistStatusId,
            projectId: queryArg.projectId,
            assetId: queryArg.assetId,
            locFilterId: queryArg.locFilterId,
            pmTemplateId: queryArg.pmTemplateId,
            assetTypeIds: queryArg.assetTypeIds,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerGetLocationsWithPmCounts: build.query<
        PmChecklistControllerGetLocationsWithPmCountsApiResponse,
        PmChecklistControllerGetLocationsWithPmCountsApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/location`,
          params: {
            projectId: queryArg.projectId,
            parentId: queryArg.parentId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            companyId: queryArg.companyId,
          },
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerGetAssetWithPmCount: build.query<
        PmChecklistControllerGetAssetWithPmCountApiResponse,
        PmChecklistControllerGetAssetWithPmCountApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/asset`,
          params: {
            projectId: queryArg.projectId,
            locationId: queryArg.locationId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            companyId: queryArg.companyId,
          },
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerGetPmChecklistCounts: build.query<
        PmChecklistControllerGetPmChecklistCountsApiResponse,
        PmChecklistControllerGetPmChecklistCountsApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/status/count`,
          params: {
            companyId: queryArg.companyId,
            locationId: queryArg.locationId,
            assetId: queryArg.assetId,
            assetTypeId: queryArg.assetTypeId,
          },
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerGetOnePmChecklist: build.query<
        PmChecklistControllerGetOnePmChecklistApiResponse,
        PmChecklistControllerGetOnePmChecklistApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/${queryArg.pmChecklistId}`,
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerUpdatePmChecklist: build.mutation<
        PmChecklistControllerUpdatePmChecklistApiResponse,
        PmChecklistControllerUpdatePmChecklistApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/${queryArg.pmChecklistId}`,
          method: "PATCH",
          body: queryArg.updatePmChecklistDto,
        }),
        invalidatesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerDownloadedForOfflineUse: build.mutation<
        PmChecklistControllerDownloadedForOfflineUseApiResponse,
        PmChecklistControllerDownloadedForOfflineUseApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/${queryArg.pmChecklistId}/downloaded`,
          method: "PATCH",
        }),
        invalidatesTags: ["Preventive Maintenance Checklists"],
      }),
      pmChecklistControllerCheckIsDownloaded: build.query<
        PmChecklistControllerCheckIsDownloadedApiResponse,
        PmChecklistControllerCheckIsDownloadedApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-checklist/${queryArg.pmChecklistId}/checkIsDownloaded`,
        }),
        providesTags: ["Preventive Maintenance Checklists"],
      }),
      locationControllerGetTopLocations: build.query<
        LocationControllerGetTopLocationsApiResponse,
        LocationControllerGetTopLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/location/topLocations`,
          params: { projectCode: queryArg.projectCode },
        }),
        providesTags: ["Location"],
      }),
      locationControllerGetFullLocation: build.query<
        LocationControllerGetFullLocationApiResponse,
        LocationControllerGetFullLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/location/full`,
          params: { locationId: queryArg.locationId },
        }),
        providesTags: ["Location"],
      }),
      locationControllerGetLocation: build.query<
        LocationControllerGetLocationApiResponse,
        LocationControllerGetLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/location`,
          params: {
            projectId: queryArg.projectId,
            locationId: queryArg.locationId,
            search: queryArg.search,
          },
        }),
        providesTags: ["Location"],
      }),
      locationControllerCreateLocation: build.mutation<
        LocationControllerCreateLocationApiResponse,
        LocationControllerCreateLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/location`,
          method: "POST",
          body: queryArg.createLocationDto,
        }),
        invalidatesTags: ["Location"],
      }),
      locationControllerDownloadFullLocations: build.query<
        LocationControllerDownloadFullLocationsApiResponse,
        LocationControllerDownloadFullLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/location/download`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Location"],
      }),
      locationControllerGetOneLocation: build.query<
        LocationControllerGetOneLocationApiResponse,
        LocationControllerGetOneLocationApiArg
      >({
        query: (queryArg) => ({ url: `/location/${queryArg.locationId}` }),
        providesTags: ["Location"],
      }),
      locationControllerDeleteLocation: build.mutation<
        LocationControllerDeleteLocationApiResponse,
        LocationControllerDeleteLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/location/${queryArg.locationId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Location"],
      }),
      locationControllerUpdateLocation: build.mutation<
        LocationControllerUpdateLocationApiResponse,
        LocationControllerUpdateLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/location/${queryArg.locationId}`,
          method: "PATCH",
          body: queryArg.updateLocationDto,
        }),
        invalidatesTags: ["Location"],
      }),
      locationControllerDeletePhoto: build.mutation<
        LocationControllerDeletePhotoApiResponse,
        LocationControllerDeletePhotoApiArg
      >({
        query: (queryArg) => ({
          url: `/location/${queryArg.locationId}/photo/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Location"],
      }),
      locationControllerDeleteFile: build.mutation<
        LocationControllerDeleteFileApiResponse,
        LocationControllerDeleteFileApiArg
      >({
        query: (queryArg) => ({
          url: `/location/${queryArg.locationId}/additionalDocuments/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Location"],
      }),
      defectTypeControllerGetFullDefectType: build.query<
        DefectTypeControllerGetFullDefectTypeApiResponse,
        DefectTypeControllerGetFullDefectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-type/${queryArg.defectTypeId}/full`,
        }),
        providesTags: ["Defect Type"],
      }),
      defectTypeControllerGetDefectTypes: build.query<
        DefectTypeControllerGetDefectTypesApiResponse,
        DefectTypeControllerGetDefectTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-type`,
          params: {
            companyId: queryArg.companyId,
            defectTypeId: queryArg.defectTypeId,
            search: queryArg.search,
          },
        }),
        providesTags: ["Defect Type"],
      }),
      defectTypeControllerCreateDefectType: build.mutation<
        DefectTypeControllerCreateDefectTypeApiResponse,
        DefectTypeControllerCreateDefectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-type`,
          method: "POST",
          body: queryArg.createDefectTypeDto,
        }),
        invalidatesTags: ["Defect Type"],
      }),
      defectTypeControllerDownloadDefectTypes: build.query<
        DefectTypeControllerDownloadDefectTypesApiResponse,
        DefectTypeControllerDownloadDefectTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-type/download`,
          params: { companyId: queryArg.companyId },
        }),
        providesTags: ["Defect Type"],
      }),
      defectTypeControllerGetOneDefectType: build.query<
        DefectTypeControllerGetOneDefectTypeApiResponse,
        DefectTypeControllerGetOneDefectTypeApiArg
      >({
        query: (queryArg) => ({ url: `/defect-type/${queryArg.defectTypeId}` }),
        providesTags: ["Defect Type"],
      }),
      defectTypeControllerUpdateDefectType: build.mutation<
        DefectTypeControllerUpdateDefectTypeApiResponse,
        DefectTypeControllerUpdateDefectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-type/${queryArg.defectTypeId}`,
          method: "PATCH",
          body: queryArg.updateDefectTypeDto,
        }),
        invalidatesTags: ["Defect Type"],
      }),
      defectTypeControllerDeleteDefectType: build.mutation<
        DefectTypeControllerDeleteDefectTypeApiResponse,
        DefectTypeControllerDeleteDefectTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/defect-type/${queryArg.defectTypeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Defect Type"],
      }),
      pmTemplateControllerGetPmTemplate: build.query<
        PmTemplateControllerGetPmTemplateApiResponse,
        PmTemplateControllerGetPmTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-template`,
          params: {
            companyId: queryArg.companyId,
            projectId: queryArg.projectId,
          },
        }),
        providesTags: ["Preventive Maintenance Template"],
      }),
      pmTemplateControllerAddPmTemplate: build.mutation<
        PmTemplateControllerAddPmTemplateApiResponse,
        PmTemplateControllerAddPmTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-template`,
          method: "POST",
          body: queryArg.addPmTemplateDto,
        }),
        invalidatesTags: ["Preventive Maintenance Template"],
      }),
      pmTemplateControllerGetPmTemplateTable: build.query<
        PmTemplateControllerGetPmTemplateTableApiResponse,
        PmTemplateControllerGetPmTemplateTableApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-template/fullTable`,
          params: {
            projectId: queryArg.projectId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Preventive Maintenance Template"],
      }),
      pmTemplateControllerGetOnePmTemplate: build.query<
        PmTemplateControllerGetOnePmTemplateApiResponse,
        PmTemplateControllerGetOnePmTemplateApiArg
      >({
        query: (queryArg) => ({ url: `/pm-template/${queryArg.pmTemplateId}` }),
        providesTags: ["Preventive Maintenance Template"],
      }),
      pmTemplateControllerUpdatePmTemplate: build.mutation<
        PmTemplateControllerUpdatePmTemplateApiResponse,
        PmTemplateControllerUpdatePmTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-template/${queryArg.pmTemplateId}`,
          method: "PATCH",
          body: queryArg.updatePmTemplateDto,
        }),
        invalidatesTags: ["Preventive Maintenance Template"],
      }),
      pmTemplateControllerDeletePmTemplate: build.mutation<
        PmTemplateControllerDeletePmTemplateApiResponse,
        PmTemplateControllerDeletePmTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/pm-template/${queryArg.pmTemplateId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Preventive Maintenance Template"],
      }),
      pmFormatTypeControllerGetPmFormatTypes: build.query<
        PmFormatTypeControllerGetPmFormatTypesApiResponse,
        PmFormatTypeControllerGetPmFormatTypesApiArg
      >({
        query: () => ({ url: `/pm-format-type` }),
        providesTags: ["Preventive Maintenance Format Type"],
      }),
      workOrderV2ControllerGetWorkOrderStatusWithCount: build.query<
        WorkOrderV2ControllerGetWorkOrderStatusWithCountApiResponse,
        WorkOrderV2ControllerGetWorkOrderStatusWithCountApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order/status/count`,
          params: {
            companyId: queryArg.companyId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
            locationId: queryArg.locationId,
            projectId: queryArg.projectId,
            vendorId: queryArg.vendorId,
            locFilterId: queryArg.locFilterId,
            filterStartDateISO: queryArg.filterStartDateIso,
            filterEndDateISO: queryArg.filterEndDateIso,
            filterAssetTypeIds: queryArg.filterAssetTypeIds,
            filterRaisedByIds: queryArg.filterRaisedByIds,
          },
        }),
        providesTags: ["Work Order / Defect V2"],
      }),
      workOrderV2ControllerGetWorkOrders: build.query<
        WorkOrderV2ControllerGetWorkOrdersApiResponse,
        WorkOrderV2ControllerGetWorkOrdersApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order`,
          params: {
            vendorId: queryArg.vendorId,
            cursorId: queryArg.cursorId,
            firebaseUserId: queryArg.firebaseUserId,
            defectStatusId: queryArg.defectStatusId,
          },
        }),
        providesTags: ["Work Order / Defect V2"],
      }),
      workOrderV2ControllerGetPdfReport: build.query<
        WorkOrderV2ControllerGetPdfReportApiResponse,
        WorkOrderV2ControllerGetPdfReportApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order/${queryArg.workOrderId}/pdf`,
        }),
        providesTags: ["Work Order / Defect V2"],
      }),
      workOrderV2ControllerPostPdfReport: build.mutation<
        WorkOrderV2ControllerPostPdfReportApiResponse,
        WorkOrderV2ControllerPostPdfReportApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order/${queryArg.workOrderId}/pdf`,
          method: "POST",
        }),
        invalidatesTags: ["Work Order / Defect V2"],
      }),
      workOrderV2ControllerUpdateWorkOrder: build.mutation<
        WorkOrderV2ControllerUpdateWorkOrderApiResponse,
        WorkOrderV2ControllerUpdateWorkOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order/${queryArg.workOrderId}`,
          method: "PATCH",
          body: queryArg.patchWorkOrderDto,
        }),
        invalidatesTags: ["Work Order / Defect V2"],
      }),
      workOrderV2ControllerUpdateInventory: build.mutation<
        WorkOrderV2ControllerUpdateInventoryApiResponse,
        WorkOrderV2ControllerUpdateInventoryApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order/${queryArg.workOrderId}/stock`,
          method: "PATCH",
          body: queryArg.updateWorkOrderStockDto,
        }),
        invalidatesTags: ["Work Order / Defect V2"],
      }),
      workOrderV2ControllerDeleteInventory: build.mutation<
        WorkOrderV2ControllerDeleteInventoryApiResponse,
        WorkOrderV2ControllerDeleteInventoryApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order/${queryArg.workOrderId}/stock`,
          method: "DELETE",
          body: queryArg.deleteWorkOrderStockDto,
        }),
        invalidatesTags: ["Work Order / Defect V2"],
      }),
      workOrderV2ControllerUpdateWorkOrderPricingRefNo: build.mutation<
        WorkOrderV2ControllerUpdateWorkOrderPricingRefNoApiResponse,
        WorkOrderV2ControllerUpdateWorkOrderPricingRefNoApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/work-order/${queryArg.workOrderId}/pricing`,
          method: "PATCH",
          body: queryArg.updateWorkOrderPricingRefNoDto,
        }),
        invalidatesTags: ["Work Order / Defect V2"],
      }),
      stockTypesControllerGetStockTypes: build.query<
        StockTypesControllerGetStockTypesApiResponse,
        StockTypesControllerGetStockTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-types`,
          params: {
            projectId: queryArg.projectId,
            searchName: queryArg.searchName,
          },
        }),
        providesTags: ["Stock / Inventory Types"],
      }),
      stockTypesControllerCreateStockType: build.mutation<
        StockTypesControllerCreateStockTypeApiResponse,
        StockTypesControllerCreateStockTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-types`,
          method: "POST",
          body: queryArg.createStockTypeDto,
        }),
        invalidatesTags: ["Stock / Inventory Types"],
      }),
      stockTypesControllerGetOneStockType: build.query<
        StockTypesControllerGetOneStockTypeApiResponse,
        StockTypesControllerGetOneStockTypeApiArg
      >({
        query: (queryArg) => ({ url: `/stock-types/${queryArg.stockTypeId}` }),
        providesTags: ["Stock / Inventory Types"],
      }),
      stockTypesControllerUpdateStockType: build.mutation<
        StockTypesControllerUpdateStockTypeApiResponse,
        StockTypesControllerUpdateStockTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-types/${queryArg.stockTypeId}`,
          method: "PUT",
          body: queryArg.updateStockTypeDto,
        }),
        invalidatesTags: ["Stock / Inventory Types"],
      }),
      stockTypesControllerDeleteStockType: build.mutation<
        StockTypesControllerDeleteStockTypeApiResponse,
        StockTypesControllerDeleteStockTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-types/${queryArg.stockTypeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Stock / Inventory Types"],
      }),
      stocksControllerGetStocksCount: build.query<
        StocksControllerGetStocksCountApiResponse,
        StocksControllerGetStocksCountApiArg
      >({
        query: (queryArg) => ({
          url: `/stocks/count`,
          params: {
            projectId: queryArg.projectId,
            stockTypeId: queryArg.stockTypeId,
            search: queryArg.search,
          },
        }),
        providesTags: ["Stocks / Inventory"],
      }),
      stocksControllerGetStocks: build.query<
        StocksControllerGetStocksApiResponse,
        StocksControllerGetStocksApiArg
      >({
        query: (queryArg) => ({
          url: `/stocks`,
          params: {
            projectId: queryArg.projectId,
            stockTypeId: queryArg.stockTypeId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Stocks / Inventory"],
      }),
      stocksControllerCreateStock: build.mutation<
        StocksControllerCreateStockApiResponse,
        StocksControllerCreateStockApiArg
      >({
        query: (queryArg) => ({
          url: `/stocks`,
          method: "POST",
          body: queryArg.createStockDto,
        }),
        invalidatesTags: ["Stocks / Inventory"],
      }),
      stocksControllerGetOneStockWithLedger: build.query<
        StocksControllerGetOneStockWithLedgerApiResponse,
        StocksControllerGetOneStockWithLedgerApiArg
      >({
        query: (queryArg) => ({ url: `/stocks/${queryArg.stockId}/ledgers` }),
        providesTags: ["Stocks / Inventory"],
      }),
      stocksControllerUpdateStock: build.mutation<
        StocksControllerUpdateStockApiResponse,
        StocksControllerUpdateStockApiArg
      >({
        query: (queryArg) => ({
          url: `/stocks/${queryArg.stockId}`,
          method: "PUT",
          body: queryArg.updateStockDto,
        }),
        invalidatesTags: ["Stocks / Inventory"],
      }),
      stocksControllerDeleteInventory: build.mutation<
        StocksControllerDeleteInventoryApiResponse,
        StocksControllerDeleteInventoryApiArg
      >({
        query: (queryArg) => ({
          url: `/stocks/${queryArg.stockId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Stocks / Inventory"],
      }),
      stocksControllerDeletePhoto: build.mutation<
        StocksControllerDeletePhotoApiResponse,
        StocksControllerDeletePhotoApiArg
      >({
        query: (queryArg) => ({
          url: `/stocks/${queryArg.stockId}/attachment/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Stocks / Inventory"],
      }),
      vendorControllerGetVendorsCount: build.query<
        VendorControllerGetVendorsCountApiResponse,
        VendorControllerGetVendorsCountApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor/count`,
          params: {
            companyId: queryArg.companyId,
            workscopeIds: queryArg.workscopeIds,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Vendor"],
      }),
      vendorControllerGetVendors: build.query<
        VendorControllerGetVendorsApiResponse,
        VendorControllerGetVendorsApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor`,
          params: {
            companyId: queryArg.companyId,
            workscopeIds: queryArg.workscopeIds,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Vendor"],
      }),
      vendorControllerCreateVendor: build.mutation<
        VendorControllerCreateVendorApiResponse,
        VendorControllerCreateVendorApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor`,
          method: "POST",
          body: queryArg.createVendorDto,
        }),
        invalidatesTags: ["Vendor"],
      }),
      vendorControllerGetOneVendor: build.query<
        VendorControllerGetOneVendorApiResponse,
        VendorControllerGetOneVendorApiArg
      >({
        query: (queryArg) => ({ url: `/vendor/${queryArg.vendorId}` }),
        providesTags: ["Vendor"],
      }),
      vendorControllerUpdateOneVendor: build.mutation<
        VendorControllerUpdateOneVendorApiResponse,
        VendorControllerUpdateOneVendorApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor/${queryArg.vendorId}`,
          method: "PATCH",
          body: queryArg.updateVendorDto,
        }),
        invalidatesTags: ["Vendor"],
      }),
      vendorControllerDeleteVendor: build.mutation<
        VendorControllerDeleteVendorApiResponse,
        VendorControllerDeleteVendorApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor/${queryArg.vendorId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Vendor"],
      }),
      vendorControllerDeleteAttachment: build.mutation<
        VendorControllerDeleteAttachmentApiResponse,
        VendorControllerDeleteAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor/${queryArg.vendorId}/additionalDocuments/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Vendor"],
      }),
      workscopeControllerGetWorkScopes: build.query<
        WorkscopeControllerGetWorkScopesApiResponse,
        WorkscopeControllerGetWorkScopesApiArg
      >({
        query: (queryArg) => ({
          url: `/workscope`,
          params: { companyId: queryArg.companyId, search: queryArg.search },
        }),
        providesTags: ["Workscope"],
      }),
      workscopeControllerCreateWorkScope: build.mutation<
        WorkscopeControllerCreateWorkScopeApiResponse,
        WorkscopeControllerCreateWorkScopeApiArg
      >({
        query: (queryArg) => ({
          url: `/workscope`,
          method: "POST",
          body: queryArg.createWorkScopeDto,
        }),
        invalidatesTags: ["Workscope"],
      }),
      workscopeControllerUpdateWorkScope: build.mutation<
        WorkscopeControllerUpdateWorkScopeApiResponse,
        WorkscopeControllerUpdateWorkScopeApiArg
      >({
        query: (queryArg) => ({
          url: `/workscope/${queryArg.workscopeId}`,
          method: "PATCH",
          body: queryArg.updateWorkscopeDto,
        }),
        invalidatesTags: ["Workscope"],
      }),
      workscopeControllerDeleteWorkScope: build.mutation<
        WorkscopeControllerDeleteWorkScopeApiResponse,
        WorkscopeControllerDeleteWorkScopeApiArg
      >({
        query: (queryArg) => ({
          url: `/workscope/${queryArg.workscopeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Workscope"],
      }),
      workscopeControllerGetOneWorkscope: build.query<
        WorkscopeControllerGetOneWorkscopeApiResponse,
        WorkscopeControllerGetOneWorkscopeApiArg
      >({
        query: (queryArg) => ({ url: `/workscope/${queryArg.workscopeId}` }),
        providesTags: ["Workscope"],
      }),
      defectStatusV2ControllerGetProjectBasedWorkOrderStatus: build.query<
        DefectStatusV2ControllerGetProjectBasedWorkOrderStatusApiResponse,
        DefectStatusV2ControllerGetProjectBasedWorkOrderStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/defect-status`,
          params: { roleId: queryArg.roleId },
        }),
        providesTags: ["Defect Status V2"],
      }),
      rolesControllerGetAllRoles: build.query<
        RolesControllerGetAllRolesApiResponse,
        RolesControllerGetAllRolesApiArg
      >({
        query: (queryArg) => ({ url: `/roles/all/${queryArg.companyId}` }),
        providesTags: ["Roles"],
      }),
      rolesControllerGetRoles: build.query<
        RolesControllerGetRolesApiResponse,
        RolesControllerGetRolesApiArg
      >({
        query: (queryArg) => ({
          url: `/roles`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Roles"],
      }),
      rolesControllerCreateRole: build.mutation<
        RolesControllerCreateRoleApiResponse,
        RolesControllerCreateRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/roles`,
          method: "POST",
          body: queryArg.createRoleDto,
        }),
        invalidatesTags: ["Roles"],
      }),
      rolesControllerGetOneRole: build.query<
        RolesControllerGetOneRoleApiResponse,
        RolesControllerGetOneRoleApiArg
      >({
        query: (queryArg) => ({ url: `/roles/${queryArg.roleId}` }),
        providesTags: ["Roles"],
      }),
      rolesControllerUpdateRoleStatus: build.mutation<
        RolesControllerUpdateRoleStatusApiResponse,
        RolesControllerUpdateRoleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/roles/${queryArg.roleId}`,
          method: "PATCH",
          body: queryArg.updateRoleDto,
        }),
        invalidatesTags: ["Roles"],
      }),
      workPermitsControllerGetWorkPermitCount: build.query<
        WorkPermitsControllerGetWorkPermitCountApiResponse,
        WorkPermitsControllerGetWorkPermitCountApiArg
      >({
        query: (queryArg) => ({
          url: `/work-permits/count`,
          params: {
            workPermitStatus: queryArg.workPermitStatus,
            projectId: queryArg.projectId,
            startDateFilter: queryArg.startDateFilter,
            endDateFilter: queryArg.endDateFilter,
            search: queryArg.search,
          },
        }),
        providesTags: ["Work permits"],
      }),
      workPermitsControllerCreateWorkPermit: build.mutation<
        WorkPermitsControllerCreateWorkPermitApiResponse,
        WorkPermitsControllerCreateWorkPermitApiArg
      >({
        query: (queryArg) => ({
          url: `/work-permits`,
          method: "POST",
          body: queryArg.createWorkPermitDto,
        }),
        invalidatesTags: ["Work permits"],
      }),
      workPermitsControllerGetWorkPermits: build.query<
        WorkPermitsControllerGetWorkPermitsApiResponse,
        WorkPermitsControllerGetWorkPermitsApiArg
      >({
        query: (queryArg) => ({
          url: `/work-permits`,
          params: {
            workPermitStatus: queryArg.workPermitStatus,
            projectId: queryArg.projectId,
            startDateFilter: queryArg.startDateFilter,
            endDateFilter: queryArg.endDateFilter,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Work permits"],
      }),
      workPermitsControllerGetWorkPermitQrCodeRequest: build.query<
        WorkPermitsControllerGetWorkPermitQrCodeRequestApiResponse,
        WorkPermitsControllerGetWorkPermitQrCodeRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/work-permits/qrCode`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Work permits"],
      }),
      workPermitsControllerGetOneWorkPermit: build.query<
        WorkPermitsControllerGetOneWorkPermitApiResponse,
        WorkPermitsControllerGetOneWorkPermitApiArg
      >({
        query: (queryArg) => ({
          url: `/work-permits/${queryArg.workPermitId}`,
        }),
        providesTags: ["Work permits"],
      }),
      workPermitsControllerDeleteWorkPermit: build.mutation<
        WorkPermitsControllerDeleteWorkPermitApiResponse,
        WorkPermitsControllerDeleteWorkPermitApiArg
      >({
        query: (queryArg) => ({
          url: `/work-permits/${queryArg.workPermitId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Work permits"],
      }),
      workPermitsControllerUpdateWorkPermitStatus: build.mutation<
        WorkPermitsControllerUpdateWorkPermitStatusApiResponse,
        WorkPermitsControllerUpdateWorkPermitStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/work-permits/${queryArg.workPermitId}/status`,
          method: "PATCH",
          body: queryArg.updateWorkPermitStatusDto,
        }),
        invalidatesTags: ["Work permits"],
      }),
      testControllerTesting: build.query<
        TestControllerTestingApiResponse,
        TestControllerTestingApiArg
      >({
        query: () => ({ url: `/test` }),
      }),
      meterReadingControllerGetMeterReadings: build.query<
        MeterReadingControllerGetMeterReadingsApiResponse,
        MeterReadingControllerGetMeterReadingsApiArg
      >({
        query: (queryArg) => ({
          url: `/meter-reading`,
          params: {
            meterId: queryArg.meterId,
            cursorId: queryArg.cursorId,
            take: queryArg.take,
          },
        }),
        providesTags: ["Meter Reading"],
      }),
      meterReadingControllerCreateMeterReading: build.mutation<
        MeterReadingControllerCreateMeterReadingApiResponse,
        MeterReadingControllerCreateMeterReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/meter-reading`,
          method: "POST",
          body: queryArg.createMeterReadingDto,
        }),
        invalidatesTags: ["Meter Reading"],
      }),
      meterReadingControllerGetOneMeterReading: build.query<
        MeterReadingControllerGetOneMeterReadingApiResponse,
        MeterReadingControllerGetOneMeterReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/meter-reading/${queryArg.meterReadingId}`,
        }),
        providesTags: ["Meter Reading"],
      }),
      meterReadingControllerUpdateMeterReading: build.mutation<
        MeterReadingControllerUpdateMeterReadingApiResponse,
        MeterReadingControllerUpdateMeterReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/meter-reading/${queryArg.meterReadingId}`,
          method: "PATCH",
          body: queryArg.updateMeterReadingDto,
        }),
        invalidatesTags: ["Meter Reading"],
      }),
      meterReadingControllerDeleteMeterReading: build.mutation<
        MeterReadingControllerDeleteMeterReadingApiResponse,
        MeterReadingControllerDeleteMeterReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/meter-reading/${queryArg.meterReadingId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Meter Reading"],
      }),
      meterReadingControllerPostMeterReadingIoT: build.mutation<
        MeterReadingControllerPostMeterReadingIoTApiResponse,
        MeterReadingControllerPostMeterReadingIoTApiArg
      >({
        query: (queryArg) => ({
          url: `/meter-reading/device`,
          method: "POST",
          body: queryArg.postMeterReadingIotDto,
        }),
        invalidatesTags: ["Meter Reading"],
      }),
      meterReadingControllerDeleteMeterReadingPhoto: build.mutation<
        MeterReadingControllerDeleteMeterReadingPhotoApiResponse,
        MeterReadingControllerDeleteMeterReadingPhotoApiArg
      >({
        query: (queryArg) => ({
          url: `/meter-reading/${queryArg.meterReadingId}/photo/${queryArg.photoId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Meter Reading"],
      }),
      notificationControllerGetNotifications: build.query<
        NotificationControllerGetNotificationsApiResponse,
        NotificationControllerGetNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/notification`,
          params: {
            userId: queryArg.userId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Notification"],
      }),
      stockAdjustmentControllerCreateStockAdjustment: build.mutation<
        StockAdjustmentControllerCreateStockAdjustmentApiResponse,
        StockAdjustmentControllerCreateStockAdjustmentApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-adjustment`,
          method: "POST",
          body: queryArg.createStockAdjustmentRequestDto,
        }),
        invalidatesTags: ["Stocks / Inventory"],
      }),
      stockAdjustmentControllerGetAdjustmentList: build.query<
        StockAdjustmentControllerGetAdjustmentListApiResponse,
        StockAdjustmentControllerGetAdjustmentListApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-adjustment`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Stocks / Inventory"],
      }),
      stockAdjustmentControllerGetOneAdjustment: build.query<
        StockAdjustmentControllerGetOneAdjustmentApiResponse,
        StockAdjustmentControllerGetOneAdjustmentApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-adjustment/${queryArg.stockAdjustmentId}`,
        }),
        providesTags: ["Stocks / Inventory"],
      }),
      stockAdjustmentControllerUpdateAdjustment: build.mutation<
        StockAdjustmentControllerUpdateAdjustmentApiResponse,
        StockAdjustmentControllerUpdateAdjustmentApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-adjustment/${queryArg.stockAdjustmentId}`,
          method: "PUT",
          body: queryArg.updateStockAdjustmentDto,
        }),
        invalidatesTags: ["Stocks / Inventory"],
      }),
      stockAdjustmentControllerDeleteAdjustment: build.mutation<
        StockAdjustmentControllerDeleteAdjustmentApiResponse,
        StockAdjustmentControllerDeleteAdjustmentApiArg
      >({
        query: (queryArg) => ({
          url: `/stock-adjustment/${queryArg.stockAdjustmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Stocks / Inventory"],
      }),
      analyticControllerGetAnalytics: build.query<
        AnalyticControllerGetAnalyticsApiResponse,
        AnalyticControllerGetAnalyticsApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["General Analytics"],
      }),
      analyticControllerGetAnalyticsSettings: build.query<
        AnalyticControllerGetAnalyticsSettingsApiResponse,
        AnalyticControllerGetAnalyticsSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics/settings`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["General Analytics"],
      }),
      analyticControllerCreateAnalytic: build.mutation<
        AnalyticControllerCreateAnalyticApiResponse,
        AnalyticControllerCreateAnalyticApiArg
      >({
        query: (queryArg) => ({
          url: `/analytics/settings`,
          method: "POST",
          body: queryArg.createMetabaseAnalyticDto,
        }),
        invalidatesTags: ["General Analytics"],
      }),
      oldMeterReadingControllerGetMeterReadings: build.query<
        OldMeterReadingControllerGetMeterReadingsApiResponse,
        OldMeterReadingControllerGetMeterReadingsApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/meterReading`,
          params: { meterId: queryArg.meterId, cursorId: queryArg.cursorId },
        }),
        providesTags: ["Meter Reading V2"],
      }),
      iotMeterControllerGetIotMeters: build.query<
        IotMeterControllerGetIotMetersApiResponse,
        IotMeterControllerGetIotMetersApiArg
      >({
        query: (queryArg) => ({
          url: `/iot-meter`,
          params: { projectId: queryArg.projectId },
        }),
        providesTags: ["Iot Meters"],
      }),
      iotMeterControllerCreateIotMeter: build.mutation<
        IotMeterControllerCreateIotMeterApiResponse,
        IotMeterControllerCreateIotMeterApiArg
      >({
        query: (queryArg) => ({
          url: `/iot-meter`,
          method: "POST",
          body: queryArg.createIotMeterDto,
        }),
        invalidatesTags: ["Iot Meters"],
      }),
      iotMeterControllerGetOneIotMeter: build.query<
        IotMeterControllerGetOneIotMeterApiResponse,
        IotMeterControllerGetOneIotMeterApiArg
      >({
        query: (queryArg) => ({ url: `/iot-meter/${queryArg.iotMeterId}` }),
        providesTags: ["Iot Meters"],
      }),
      iotMeterControllerUpdateIotMeter: build.mutation<
        IotMeterControllerUpdateIotMeterApiResponse,
        IotMeterControllerUpdateIotMeterApiArg
      >({
        query: (queryArg) => ({
          url: `/iot-meter/${queryArg.iotMeterId}`,
          method: "PATCH",
          body: queryArg.updateIotMeterDto,
        }),
        invalidatesTags: ["Iot Meters"],
      }),
      iotMeterControllerDeleteIotMeter: build.mutation<
        IotMeterControllerDeleteIotMeterApiResponse,
        IotMeterControllerDeleteIotMeterApiArg
      >({
        query: (queryArg) => ({
          url: `/iot-meter/${queryArg.iotMeterId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Iot Meters"],
      }),
      iotMeterReadingControllerCreateIotMeterReading: build.mutation<
        IotMeterReadingControllerCreateIotMeterReadingApiResponse,
        IotMeterReadingControllerCreateIotMeterReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/iot-meter-reading`,
          method: "POST",
          body: queryArg.createIotMeterReadingDto,
        }),
        invalidatesTags: ["Iot Meter Readings"],
      }),
      iotMeterReadingControllerGetIotMeterReadings: build.query<
        IotMeterReadingControllerGetIotMeterReadingsApiResponse,
        IotMeterReadingControllerGetIotMeterReadingsApiArg
      >({
        query: (queryArg) => ({
          url: `/iot-meter-reading`,
          params: {
            deviceId: queryArg.deviceId,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
          },
        }),
        providesTags: ["Iot Meter Readings"],
      }),
      legalComplianceControllerGetLegalCompliances: build.query<
        LegalComplianceControllerGetLegalCompliancesApiResponse,
        LegalComplianceControllerGetLegalCompliancesApiArg
      >({
        query: (queryArg) => ({
          url: `/legal-compliance`,
          params: {
            projectId: queryArg.projectId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Legal Compliance"],
      }),
      legalComplianceControllerCreateLegalCompliance: build.mutation<
        LegalComplianceControllerCreateLegalComplianceApiResponse,
        LegalComplianceControllerCreateLegalComplianceApiArg
      >({
        query: (queryArg) => ({
          url: `/legal-compliance`,
          method: "POST",
          body: queryArg.createLegalComplianceDto,
        }),
        invalidatesTags: ["Legal Compliance"],
      }),
      legalComplianceControllerGetLegalComplianceCount: build.query<
        LegalComplianceControllerGetLegalComplianceCountApiResponse,
        LegalComplianceControllerGetLegalComplianceCountApiArg
      >({
        query: (queryArg) => ({
          url: `/legal-compliance/count`,
          params: {
            projectId: queryArg.projectId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Legal Compliance"],
      }),
      legalComplianceControllerGetOneLegalCompliance: build.query<
        LegalComplianceControllerGetOneLegalComplianceApiResponse,
        LegalComplianceControllerGetOneLegalComplianceApiArg
      >({
        query: (queryArg) => ({
          url: `/legal-compliance/${queryArg.legalComplianceId}`,
        }),
        providesTags: ["Legal Compliance"],
      }),
      legalComplianceControllerUpdateLegalCompliance: build.mutation<
        LegalComplianceControllerUpdateLegalComplianceApiResponse,
        LegalComplianceControllerUpdateLegalComplianceApiArg
      >({
        query: (queryArg) => ({
          url: `/legal-compliance/${queryArg.legalComplianceId}`,
          method: "PATCH",
          body: queryArg.updateLegalComplianceDto,
        }),
        invalidatesTags: ["Legal Compliance"],
      }),
      legalComplianceControllerDeleteLegalCompliance: build.mutation<
        LegalComplianceControllerDeleteLegalComplianceApiResponse,
        LegalComplianceControllerDeleteLegalComplianceApiArg
      >({
        query: (queryArg) => ({
          url: `/legal-compliance/${queryArg.legalComplianceId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Legal Compliance"],
      }),
      legalComplianceControllerDeleteAttachment: build.mutation<
        LegalComplianceControllerDeleteAttachmentApiResponse,
        LegalComplianceControllerDeleteAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/legal-compliance/${queryArg.legalComplianceId}/document/${queryArg.documentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Legal Compliance"],
      }),
      cronControllerRunReminderLegalCompliance: build.mutation<
        CronControllerRunReminderLegalComplianceApiResponse,
        CronControllerRunReminderLegalComplianceApiArg
      >({
        query: () => ({ url: `/cron/legal`, method: "POST" }),
        invalidatesTags: ["Cron"],
      }),
      cronControllerGeneratePmChecklist: build.mutation<
        CronControllerGeneratePmChecklistApiResponse,
        CronControllerGeneratePmChecklistApiArg
      >({
        query: () => ({ url: `/cron/generatePmChecklist`, method: "POST" }),
        invalidatesTags: ["Cron"],
      }),
      accountTypeControllerCreateAccountType: build.mutation<
        AccountTypeControllerCreateAccountTypeApiResponse,
        AccountTypeControllerCreateAccountTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/account-type`,
          method: "POST",
          body: queryArg.createAccountTypeDto,
        }),
        invalidatesTags: ["Account Type"],
      }),
      accountTypeControllerUpdateAccountType: build.mutation<
        AccountTypeControllerUpdateAccountTypeApiResponse,
        AccountTypeControllerUpdateAccountTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/account-type/${queryArg.accountTypeId}`,
          method: "PATCH",
          body: queryArg.updateAccountTypeDto,
        }),
        invalidatesTags: ["Account Type"],
      }),
      accountTypeControllerDeleteAccountType: build.mutation<
        AccountTypeControllerDeleteAccountTypeApiResponse,
        AccountTypeControllerDeleteAccountTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/account-type/${queryArg.accountTypeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Account Type"],
      }),
      tnbBillControllerGetTnbReadings: build.query<
        TnbBillControllerGetTnbReadingsApiResponse,
        TnbBillControllerGetTnbReadingsApiArg
      >({
        query: (queryArg) => ({
          url: `/tnb-bill`,
          params: {
            projectId: queryArg.projectId,
            dateStartIsoFilter: queryArg.dateStartIsoFilter,
            dateEndIsoFilter: queryArg.dateEndIsoFilter,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Tnb Bill"],
      }),
      tnbBillControllerCreateTnbReading: build.mutation<
        TnbBillControllerCreateTnbReadingApiResponse,
        TnbBillControllerCreateTnbReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/tnb-bill`,
          method: "POST",
          body: queryArg.createTnbReadingDto,
        }),
        invalidatesTags: ["Tnb Bill"],
      }),
      tnbBillControllerUpdateTnb: build.mutation<
        TnbBillControllerUpdateTnbApiResponse,
        TnbBillControllerUpdateTnbApiArg
      >({
        query: (queryArg) => ({
          url: `/tnb-bill/${queryArg.tnbReadingId}`,
          method: "PATCH",
          body: queryArg.updateTnbReadingDto,
        }),
        invalidatesTags: ["Tnb Bill"],
      }),
      tnbBillControllerDeleteTnbReading: build.mutation<
        TnbBillControllerDeleteTnbReadingApiResponse,
        TnbBillControllerDeleteTnbReadingApiArg
      >({
        query: (queryArg) => ({
          url: `/tnb-bill/${queryArg.tnbReadingId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Tnb Bill"],
      }),
      tnbBillControllerGetOneTnbReading: build.query<
        TnbBillControllerGetOneTnbReadingApiResponse,
        TnbBillControllerGetOneTnbReadingApiArg
      >({
        query: (queryArg) => ({ url: `/tnb-bill/${queryArg.tnbReadingId}` }),
        providesTags: ["Tnb Bill"],
      }),
      tnbBillControllerDeleteAttachment: build.mutation<
        TnbBillControllerDeleteAttachmentApiResponse,
        TnbBillControllerDeleteAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/tnb-bill/${queryArg.tnbReadingId}/attachment/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Tnb Bill"],
      }),
      tnbBillControllerGetTnbAnalytic: build.query<
        TnbBillControllerGetTnbAnalyticApiResponse,
        TnbBillControllerGetTnbAnalyticApiArg
      >({
        query: (queryArg) => ({
          url: `/tnb-bill/analytic/${queryArg.projectId}`,
        }),
        providesTags: ["Tnb Bill"],
      }),
      escalationControllerGetEscalations: build.query<
        EscalationControllerGetEscalationsApiResponse,
        EscalationControllerGetEscalationsApiArg
      >({
        query: (queryArg) => ({
          url: `/escalation`,
          params: {
            projectId: queryArg.projectId,
            search: queryArg.search,
            cursorId: queryArg.cursorId,
            page: queryArg.page,
            rowsPerPage: queryArg.rowsPerPage,
          },
        }),
        providesTags: ["Escalation"],
      }),
      escalationControllerCreateEscalation: build.mutation<
        EscalationControllerCreateEscalationApiResponse,
        EscalationControllerCreateEscalationApiArg
      >({
        query: (queryArg) => ({
          url: `/escalation`,
          method: "POST",
          body: queryArg.createEscalationRequestDto,
        }),
        invalidatesTags: ["Escalation"],
      }),
      escalationControllerGetOneEscalation: build.query<
        EscalationControllerGetOneEscalationApiResponse,
        EscalationControllerGetOneEscalationApiArg
      >({
        query: (queryArg) => ({ url: `/escalation/${queryArg.escalationId}` }),
        providesTags: ["Escalation"],
      }),
      escalationControllerUpdateEscalation: build.mutation<
        EscalationControllerUpdateEscalationApiResponse,
        EscalationControllerUpdateEscalationApiArg
      >({
        query: (queryArg) => ({
          url: `/escalation/${queryArg.escalationId}`,
          method: "PATCH",
          body: queryArg.updateEscalationRequestDto,
        }),
        invalidatesTags: ["Escalation"],
      }),
      escalationControllerDeleteEscalation: build.mutation<
        EscalationControllerDeleteEscalationApiResponse,
        EscalationControllerDeleteEscalationApiArg
      >({
        query: (queryArg) => ({
          url: `/escalation/${queryArg.escalationId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Escalation"],
      }),
      dataImportsControllerImportLocation: build.mutation<
        DataImportsControllerImportLocationApiResponse,
        DataImportsControllerImportLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/data-imports/location`,
          method: "POST",
          body: queryArg.importLocationRequestDto,
        }),
        invalidatesTags: ["Settings Imports", "Location", "Assets"],
      }),
      dataImportsControllerImportAssets: build.mutation<
        DataImportsControllerImportAssetsApiResponse,
        DataImportsControllerImportAssetsApiArg
      >({
        query: (queryArg) => ({
          url: `/data-imports/assets`,
          method: "POST",
          body: queryArg.importAssetRequest,
        }),
        invalidatesTags: ["Settings Imports", "Location", "Assets"],
      }),
      dataImportsControllerGetLocations: build.query<
        DataImportsControllerGetLocationsApiResponse,
        DataImportsControllerGetLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/data-imports/location/${queryArg.projectId}`,
        }),
        providesTags: ["Settings Imports", "Location", "Assets"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as cerevApi };
export type AppControllerTestingApiResponse = /** status 200  */ string;
export type AppControllerTestingApiArg = void;
export type UserControllerGetUsersApiResponse =
  /** status 200 Get user data */ UserEntity[];
export type UserControllerGetUsersApiArg = {
  companyId: number;
  projectId?: number;
  userId: number;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type UserControllerGetUserCountApiResponse =
  /** status 200  */ GetUserCountResponseDto;
export type UserControllerGetUserCountApiArg = {
  companyId: number;
  projectId?: number;
  userId: number;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type UserControllerGetOneUserApiResponse =
  /** status 200 Get user data */ GetOneUserResponseDto;
export type UserControllerGetOneUserApiArg = {
  firebaseUserId: string;
};
export type UserControllerDeleteAttachmentApiResponse = unknown;
export type UserControllerDeleteAttachmentApiArg = {
  userId: number;
  attachmentId: number;
};
export type UserControllerUpdateUserApiResponse = /** status 200  */ object;
export type UserControllerUpdateUserApiArg = {
  userId: number;
  updateUserV2Dto: UpdateUserV2Dto;
};
export type UserControllerUpdateUserVendorApiResponse = unknown;
export type UserControllerUpdateUserVendorApiArg = {
  userId: number;
  updateUserVendorDto: UpdateUserVendorDto;
};
export type UserControllerRemoveUserVendorApiResponse = unknown;
export type UserControllerRemoveUserVendorApiArg = {
  userId: number;
};
export type UserControllerUpdateUserProjectsApiResponse = unknown;
export type UserControllerUpdateUserProjectsApiArg = {
  userId: number;
  updateUserProjectDto: UpdateUserProjectDto;
};
export type AdminControllerFirstTimePasswordChangeApiResponse =
  /** status 200  */ FirstTimeUserPasswordResponseDto;
export type AdminControllerFirstTimePasswordChangeApiArg = {
  firstTimeUserPasswordDto: FirstTimeUserPasswordDto;
};
export type WorkRequestControllerCreateWorkRequestInternallyApiResponse =
  unknown;
export type WorkRequestControllerCreateWorkRequestInternallyApiArg = {
  createWorkRequestInternalDto: CreateWorkRequestInternalDto;
};
export type WorkRequestControllerCreateWorkRequestApiResponse =
  /** status 201  */ WorkRequest;
export type WorkRequestControllerCreateWorkRequestApiArg = {
  createWorkRequestDto: CreateWorkRequestDto;
};
export type WorkRequestControllerGetWorkRequestsApiResponse =
  /** status 200  */ GetWorkRequestsResponseDto[];
export type WorkRequestControllerGetWorkRequestsApiArg = {
  workRequestStatus?: WorkRequestStatus;
  locFilterId?: string;
  filterStartDateIso?: string;
  filterEndDateIso?: string;
  projectId: string;
  showUnlinkedOnly?: boolean;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type WorkRequestControllerGetWorkRequestBasedFromCodeApiResponse =
  /** status 200  */ GetWorkRequestBasedFromCodeResponseDto;
export type WorkRequestControllerGetWorkRequestBasedFromCodeApiArg = {
  workRequestCode: string;
};
export type WorkRequestControllerDeleteWorkRequestApiResponse = unknown;
export type WorkRequestControllerDeleteWorkRequestApiArg = {
  workRequestCode: string;
  deleteWorkRequestDto: DeleteWorkRequestDto;
};
export type WorkRequestControllerGetWorkRequestStatusCountApiResponse =
  /** status 200  */ GetWorkRequestsCountResponseDto;
export type WorkRequestControllerGetWorkRequestStatusCountApiArg = {
  projectId: string;
  search?: string;
  locFilterId?: string;
  filterStartDateIso?: string;
  filterEndDateIso?: string;
};
export type WorkRequestControllerGetWorkRequestQrCodeApiResponse =
  /** status 200  */ GetWorkRequestQrCodeResponseDto;
export type WorkRequestControllerGetWorkRequestQrCodeApiArg = {
  projectId: string;
};
export type WorkRequestControllerGetOneWorkRequestApiResponse =
  /** status 200  */ GetOneWorkRequestResponseDto;
export type WorkRequestControllerGetOneWorkRequestApiArg = {
  workRequestId: string;
};
export type AnalyticMeterControllerGetMeterReadingApiResponse =
  /** status 200  */ GetAnalyticMeterReadingResponseDto[];
export type AnalyticMeterControllerGetMeterReadingApiArg = {
  meterId: string;
  monthNum: string;
  yearNum: string;
};
export type MeterControllerGetMetersApiResponse = /** status 200  */ Meter[];
export type MeterControllerGetMetersApiArg = {
  projectId: number;
  search?: string;
};
export type MeterControllerCreateMeterApiResponse = unknown;
export type MeterControllerCreateMeterApiArg = {
  createMeterDto: CreateMeterDto;
};
export type MeterControllerGetOneMeterApiResponse = /** status 200  */ object;
export type MeterControllerGetOneMeterApiArg = {
  meterId: string;
};
export type MeterControllerUpdateMeterApiResponse = unknown;
export type MeterControllerUpdateMeterApiArg = {
  meterId: string;
  updateMeterDto: UpdateMeterDto;
};
export type MeterControllerDeleteMeterApiResponse = /** status 200  */ string;
export type MeterControllerDeleteMeterApiArg = {
  meterId: string;
};
export type AssetControllerGetFullAssetsApiResponse =
  /** status 200  */ AssetWithType[];
export type AssetControllerGetFullAssetsApiArg = {
  projectId: string;
  search?: string;
  assetTypeIds?: string[];
};
export type AssetControllerGetAssetsApiResponse =
  /** status 200  */ GetAssetsResponseDto[];
export type AssetControllerGetAssetsApiArg = {
  projectId: string;
  locationId: string;
  search?: string;
  assetTypeIds?: string[];
};
export type AssetControllerCreateAssetApiResponse = unknown;
export type AssetControllerCreateAssetApiArg = {
  createAssetDto: CreateAssetDto;
};
export type AssetControllerGetOneAssetApiResponse =
  /** status 200  */ GetOneAsset;
export type AssetControllerGetOneAssetApiArg = {
  assetId: string;
  projectId: string;
};
export type AssetControllerUpdateAssetApiResponse = unknown;
export type AssetControllerUpdateAssetApiArg = {
  assetId: string;
  updateAssetDto: UpdateAssetDto;
};
export type AssetControllerDeleteAssetApiResponse = unknown;
export type AssetControllerDeleteAssetApiArg = {
  assetId: string;
};
export type AssetControllerDeleteAttachmentApiResponse =
  /** status 200  */ string;
export type AssetControllerDeleteAttachmentApiArg = {
  assetId: string;
  attachmentId: string;
};
export type AssetControllerDeletePhotoApiResponse = /** status 200  */ string;
export type AssetControllerDeletePhotoApiArg = {
  assetId: string;
  attachmentId: string;
};
export type AssetControllerGetAssetWorkOrdersApiResponse =
  /** status 200  */ AssetWorkOrdersResponseDto[];
export type AssetControllerGetAssetWorkOrdersApiArg = {
  assetId: string;
};
export type AssetControllerGetAssetPmApiResponse =
  /** status 200  */ PmChecklistResponseDto[];
export type AssetControllerGetAssetPmApiArg = {
  assetId: string;
  cursorId?: number;
};
export type AssetControllerGenerateQrCodeApiResponse =
  /** status 200  */ GetAssetQrCodeResponseDto;
export type AssetControllerGenerateQrCodeApiArg = {
  projectId: string;
};
export type AssetControllerGetQrCodeApiResponse = unknown;
export type AssetControllerGetQrCodeApiArg = {
  projectId: string;
};
export type AssetTypeControllerGetAssetTypeApiResponse =
  /** status 200 Get all asset types */ AssetType[];
export type AssetTypeControllerGetAssetTypeApiArg = {
  companyId: string;
  projectId?: string;
  search?: string;
};
export type AssetTypeControllerCreateAssetTypeApiResponse = unknown;
export type AssetTypeControllerCreateAssetTypeApiArg = {
  createAssetTypeDto: CreateAssetTypeDto;
};
export type AssetTypeControllerGetAssetTypeCountApiResponse =
  /** status 200  */ number;
export type AssetTypeControllerGetAssetTypeCountApiArg = {
  companyId: string;
  projectId?: string;
  search?: string;
};
export type AssetTypeControllerGetOneAssetTypeApiResponse =
  /** status 200 Get one asset type */ AssetType;
export type AssetTypeControllerGetOneAssetTypeApiArg = {
  assetTypeId: string;
};
export type AssetTypeControllerUpdateAssetTypeApiResponse = unknown;
export type AssetTypeControllerUpdateAssetTypeApiArg = {
  assetTypeId: string;
  updateAssetTypeDto: UpdateAssetTypeDto;
};
export type AssetTypeControllerDeleteAssetTypeApiResponse = unknown;
export type AssetTypeControllerDeleteAssetTypeApiArg = {
  assetTypeId: string;
};
export type AttachmentControllerGetSignedUrlApiResponse = unknown;
export type AttachmentControllerGetSignedUrlApiArg = {
  attachmentId: string;
};
export type AuthControllerGetCurrentUserApiResponse =
  /** status 200 Get current user */ GetCurrentUserResponseDto;
export type AuthControllerGetCurrentUserApiArg = void;
export type AuthControllerUpdateUserApiResponse = /** status 200  */ object;
export type AuthControllerUpdateUserApiArg = {
  updateUserDto: UpdateUserDto;
};
export type AuthControllerDeleteUserApiResponse = unknown;
export type AuthControllerDeleteUserApiArg = {
  deleteUserDto: DeleteUserDto;
};
export type AuthControllerUpdateTokenApiResponse = /** status 200  */ string;
export type AuthControllerUpdateTokenApiArg = {
  updateDeviceTokenDto: UpdateDeviceTokenDto;
};
export type AuthControllerForgotPasswordApiResponse = unknown;
export type AuthControllerForgotPasswordApiArg = {
  email: string;
};
export type AuthV2ControllerGetUsersApiResponse = /** status 200  */ object[];
export type AuthV2ControllerGetUsersApiArg = {
  projectId: string;
  companyId: string;
  search: string;
};
export type AuthV2ControllerCreateNewUserApiResponse =
  /** status 201  */ object;
export type AuthV2ControllerCreateNewUserApiArg = {
  createNewUserDto: CreateNewUserDto;
};
export type AuthV2ControllerUpdateUserApiResponse = /** status 200  */ object;
export type AuthV2ControllerUpdateUserApiArg = {
  userId: string;
  updateUserV2Dto: UpdateUserV2Dto;
};
export type DefectStatusControllerGetDefectStatusApiResponse =
  /** status 200  */ DefectStatus[];
export type DefectStatusControllerGetDefectStatusApiArg = {
  companyId: string;
};
export type DefectStatusControllerCreateProjectBasedDefectStatusApiResponse =
  unknown;
export type DefectStatusControllerCreateProjectBasedDefectStatusApiArg = {
  createProjectDefectStatusDto: CreateProjectDefectStatusDto;
};
export type DefectStatusControllerGetAllWorkOrderStatusApiResponse =
  /** status 200 Get all work order status */ DefectStatus[];
export type DefectStatusControllerGetAllWorkOrderStatusApiArg = {
  companyId: string;
};
export type CommentControllerGetCommentsApiResponse =
  /** status 200  */ GetCommentsResponseDto[];
export type CommentControllerGetCommentsApiArg = {
  defectId: string;
};
export type CommentControllerCreateCommentApiResponse = unknown;
export type CommentControllerCreateCommentApiArg = {
  createCommentDto: CreateCommentDto;
};
export type CompaniesControllerGetAllCompaniesApiResponse =
  /** status 200  */ AdminGetCompaniesResponseDto;
export type CompaniesControllerGetAllCompaniesApiArg = {
  page?: number;
  rowsPerPage?: number;
};
export type CompaniesControllerCreateCompanyApiResponse = unknown;
export type CompaniesControllerCreateCompanyApiArg = {
  createCompanyDto: CreateCompanyDto;
};
export type CompaniesControllerGetOneCompanyApiResponse =
  /** status 200 Get company data */ AdminGetOneCompanyResponseDto;
export type CompaniesControllerGetOneCompanyApiArg = {
  companyId: string;
};
export type CompaniesControllerUpdateCompanyApiResponse = unknown;
export type CompaniesControllerUpdateCompanyApiArg = {
  companyId: number;
  updateCompanyDto: UpdateCompanyDto;
};
export type CompaniesControllerGetProjectsUnderCompanyApiResponse =
  /** status 200 Get projects under company */ ProjectWithAttachmentResponse[];
export type CompaniesControllerGetProjectsUnderCompanyApiArg = {
  companyId: string;
};
export type CompaniesControllerCreateProjectApiResponse =
  /** status 201  */ object;
export type CompaniesControllerCreateProjectApiArg = {
  companyId: string;
  createProjectDto: CreateProjectDto;
};
export type CompaniesControllerGetRolesUnderCompanyApiResponse =
  /** status 200 Get roles under company */ GetRolesUnderCompanyResponseDto[];
export type CompaniesControllerGetRolesUnderCompanyApiArg = {
  companyId: string;
};
export type CompaniesControllerDeleteProjectApiResponse = unknown;
export type CompaniesControllerDeleteProjectApiArg = {
  companyId: number;
  projectId: number;
};
export type CompaniesControllerGetOneProjectApiResponse =
  /** status 200 Get project data */ AdminGetOneProjectResponseDto;
export type CompaniesControllerGetOneProjectApiArg = {
  projectId: string;
  companyId: string;
};
export type CompaniesControllerUpdateProjectApiResponse = unknown;
export type CompaniesControllerUpdateProjectApiArg = {
  projectId: number;
  companyId: number;
  updateProjectDto: UpdateProjectDto;
};
export type CompaniesControllerDeleteProjectPhotoApiResponse = unknown;
export type CompaniesControllerDeleteProjectPhotoApiArg = {
  companyId: number;
  projectId: number;
  attachmentId: number;
};
export type CompaniesControllerDeletePhotoApiResponse = unknown;
export type CompaniesControllerDeletePhotoApiArg = {
  companyId: number;
  attachmentId: number;
};
export type DefectControllerGetDefectsApiResponse =
  /** status 200 Get all work orders */ GetWorkOrdersResponseDto[];
export type DefectControllerGetDefectsApiArg = {
  locationId?: string;
  defectStatusId?: string;
  projectId?: string;
  vendorId?: string;
  locFilterId?: string;
  filterStartDateIso?: string;
  filterEndDateIso?: string;
  filterAssetTypeIds?: string[];
  filterRaisedByIds?: string[];
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type DefectControllerCreateDefectApiResponse = /** status 201  */ object;
export type DefectControllerCreateDefectApiArg = {
  createWorkOrderDto: CreateWorkOrderDto;
};
export type DefectControllerGetOneDefectApiResponse =
  /** status 200 Get one work order */ GetOneWorkOrderResponseDto;
export type DefectControllerGetOneDefectApiArg = {
  defectId: string;
};
export type DefectControllerUpdateDefectApiResponse = unknown;
export type DefectControllerUpdateDefectApiArg = {
  defectId: string;
  patchDefectDto: PatchDefectDto;
};
export type DefectControllerGetWorkOrderCountApiResponse =
  /** status 200  */ string;
export type DefectControllerGetWorkOrderCountApiArg = {
  search?: string;
  projectId: string;
  locFilterId?: string;
  filterStartDateIso?: string;
  filterEndDateIso?: string;
  filterAssetTypeIds?: string[];
  filterRaisedByIds?: string[];
  workStatusId: string;
};
export type DefectControllerGetCurrentDefectStatusApiResponse = unknown;
export type DefectControllerGetCurrentDefectStatusApiArg = {
  defectId: string;
};
export type ProjectControllerGetProjectsApiResponse =
  /** status 200  */ ProjectWithAttachmentResponse[];
export type ProjectControllerGetProjectsApiArg = {
  userId: string;
  companyId: string;
};
export type StorageControllerGetMbQuotaApiResponse =
  /** status 200  */ GetMbQuotaResponseDto;
export type StorageControllerGetMbQuotaApiArg = {
  projectId: string;
};
export type PmChecklistStatusControllerGetPmChecklistStatusApiResponse =
  unknown;
export type PmChecklistStatusControllerGetPmChecklistStatusApiArg = {
  companyId: string;
  roleIds: string[];
};
export type PmChecklistStatusControllerGetAllPmChecklistStatusApiResponse =
  /** status 200 Get all pm checklist status */ PmChecklistStatus[];
export type PmChecklistStatusControllerGetAllPmChecklistStatusApiArg = {
  companyId: number;
};
export type PmChecklistControllerGetPmChecklistApiResponse =
  /** status 200 Get pm checklists */ PmChecklistResponseDto[];
export type PmChecklistControllerGetPmChecklistApiArg = {
  projectId: string;
  pmStatusId: string;
  assetId?: string;
  locationId?: string;
  assetTypeIds?: string[];
  filterStartDateIso?: string;
  filterEndDateIso?: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type PmChecklistControllerCreatePmChecklistApiResponse = unknown;
export type PmChecklistControllerCreatePmChecklistApiArg = {
  createPmChecklistDto: CreatePmChecklistDto;
};
export type PmChecklistControllerGetAllPmChecklistsOpenApiResponse =
  /** status 200  */ GetOpenPmChecklistsResponseDto;
export type PmChecklistControllerGetAllPmChecklistsOpenApiArg = {
  companyId: string;
  projectId: string;
};
export type PmChecklistControllerGetPmChecklistStatusCountApiResponse =
  /** status 200  */ string;
export type PmChecklistControllerGetPmChecklistStatusCountApiArg = {
  projectId: string;
  assetTypeId?: string;
  assetId?: string;
  locationId?: string;
  assetTypeIds?: string[];
  filterStartDateIso?: string;
  filterEndDateIso?: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
  pmStatusId: string;
};
export type PmChecklistControllerGetPmChecklistTableApiResponse =
  /** status 200  */ GetPmChecklistTableResponseDto;
export type PmChecklistControllerGetPmChecklistTableApiArg = {
  pmChecklistStatusId: number;
  projectId: number;
  assetId?: number;
  locFilterId?: number;
  pmTemplateId?: number;
  assetTypeIds?: string[];
  filterStartDateIso?: string;
  filterEndDateIso?: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type PmChecklistControllerGetLocationsWithPmCountsApiResponse =
  /** status 200 Get location with pm count */ GetLocationWithPmCountResponse[];
export type PmChecklistControllerGetLocationsWithPmCountsApiArg = {
  projectId: string;
  parentId: string;
  search?: string;
  cursorId?: string;
  companyId: string;
};
export type PmChecklistControllerGetAssetWithPmCountApiResponse =
  /** status 200 Get asset with pm count */ GetAssetsWithPmCountResponseDto[];
export type PmChecklistControllerGetAssetWithPmCountApiArg = {
  projectId: string;
  locationId: string;
  search?: string;
  cursorId?: number;
  companyId: string;
};
export type PmChecklistControllerGetPmChecklistCountsApiResponse = unknown;
export type PmChecklistControllerGetPmChecklistCountsApiArg = {
  companyId: string;
  locationId: string;
  assetId: string;
  assetTypeId: string;
};
export type PmChecklistControllerGetOnePmChecklistApiResponse =
  /** status 200 Get one pm checklist */ GetOnePmChecklistResponse;
export type PmChecklistControllerGetOnePmChecklistApiArg = {
  pmChecklistId: string;
};
export type PmChecklistControllerUpdatePmChecklistApiResponse = unknown;
export type PmChecklistControllerUpdatePmChecklistApiArg = {
  pmChecklistId: string;
  updatePmChecklistDto: UpdatePmChecklistDto;
};
export type PmChecklistControllerDownloadedForOfflineUseApiResponse = unknown;
export type PmChecklistControllerDownloadedForOfflineUseApiArg = {
  pmChecklistId: string;
};
export type PmChecklistControllerCheckIsDownloadedApiResponse =
  /** status 200  */ CheckIsDownloadedResponseDto;
export type PmChecklistControllerCheckIsDownloadedApiArg = {
  pmChecklistId: string;
};
export type LocationControllerGetTopLocationsApiResponse =
  /** status 200  */ Location[];
export type LocationControllerGetTopLocationsApiArg = {
  projectCode: string;
};
export type LocationControllerGetFullLocationApiResponse =
  /** status 200 Get full location */ Location[];
export type LocationControllerGetFullLocationApiArg = {
  locationId: string;
};
export type LocationControllerGetLocationApiResponse =
  /** status 200 Get location */ GetLocationResponseDto[];
export type LocationControllerGetLocationApiArg = {
  projectId: string;
  locationId: string;
  search?: string;
};
export type LocationControllerCreateLocationApiResponse = unknown;
export type LocationControllerCreateLocationApiArg = {
  createLocationDto: CreateLocationDto;
};
export type LocationControllerDownloadFullLocationsApiResponse =
  /** status 200 Download all location */ Location[];
export type LocationControllerDownloadFullLocationsApiArg = {
  projectId: number;
};
export type LocationControllerGetOneLocationApiResponse =
  /** status 200 Get one location */ GetOneLocationResponseDto;
export type LocationControllerGetOneLocationApiArg = {
  locationId: string;
};
export type LocationControllerDeleteLocationApiResponse = unknown;
export type LocationControllerDeleteLocationApiArg = {
  locationId: string;
};
export type LocationControllerUpdateLocationApiResponse = unknown;
export type LocationControllerUpdateLocationApiArg = {
  locationId: string;
  updateLocationDto: UpdateLocationDto;
};
export type LocationControllerDeletePhotoApiResponse = unknown;
export type LocationControllerDeletePhotoApiArg = {
  attachmentId: string;
  locationId: string;
};
export type LocationControllerDeleteFileApiResponse = /** status 200  */ string;
export type LocationControllerDeleteFileApiArg = {
  attachmentId: string;
  locationId: string;
};
export type DefectTypeControllerGetFullDefectTypeApiResponse =
  /** status 200  */ DefectType[];
export type DefectTypeControllerGetFullDefectTypeApiArg = {
  defectTypeId: string;
};
export type DefectTypeControllerGetDefectTypesApiResponse =
  /** status 200  */ DefectType[];
export type DefectTypeControllerGetDefectTypesApiArg = {
  companyId: string;
  defectTypeId: string;
  search?: string;
};
export type DefectTypeControllerCreateDefectTypeApiResponse = unknown;
export type DefectTypeControllerCreateDefectTypeApiArg = {
  createDefectTypeDto: CreateDefectTypeDto;
};
export type DefectTypeControllerDownloadDefectTypesApiResponse =
  /** status 200 Download defect types */ DefectType[];
export type DefectTypeControllerDownloadDefectTypesApiArg = {
  companyId: number;
};
export type DefectTypeControllerGetOneDefectTypeApiResponse =
  /** status 200  */ DefectType;
export type DefectTypeControllerGetOneDefectTypeApiArg = {
  defectTypeId: string;
};
export type DefectTypeControllerUpdateDefectTypeApiResponse = unknown;
export type DefectTypeControllerUpdateDefectTypeApiArg = {
  defectTypeId: string;
  updateDefectTypeDto: UpdateDefectTypeDto;
};
export type DefectTypeControllerDeleteDefectTypeApiResponse = unknown;
export type DefectTypeControllerDeleteDefectTypeApiArg = {
  defectTypeId: string;
};
export type PmTemplateControllerGetPmTemplateApiResponse =
  /** status 200  */ object[];
export type PmTemplateControllerGetPmTemplateApiArg = {
  companyId: string;
  projectId: string;
};
export type PmTemplateControllerAddPmTemplateApiResponse = unknown;
export type PmTemplateControllerAddPmTemplateApiArg = {
  addPmTemplateDto: AddPmTemplateDto;
};
export type PmTemplateControllerGetPmTemplateTableApiResponse =
  /** status 200  */ PmTemplateResponseDto;
export type PmTemplateControllerGetPmTemplateTableApiArg = {
  projectId: number;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type PmTemplateControllerGetOnePmTemplateApiResponse =
  /** status 200  */ GetOnePmTemplateResponseDto;
export type PmTemplateControllerGetOnePmTemplateApiArg = {
  pmTemplateId: string;
};
export type PmTemplateControllerUpdatePmTemplateApiResponse =
  /** status 200  */ string;
export type PmTemplateControllerUpdatePmTemplateApiArg = {
  pmTemplateId: string;
  updatePmTemplateDto: UpdatePmTemplateDto;
};
export type PmTemplateControllerDeletePmTemplateApiResponse = unknown;
export type PmTemplateControllerDeletePmTemplateApiArg = {
  pmTemplateId: number;
};
export type PmFormatTypeControllerGetPmFormatTypesApiResponse =
  /** status 200  */ PmFormatType[];
export type PmFormatTypeControllerGetPmFormatTypesApiArg = void;
export type WorkOrderV2ControllerGetWorkOrderStatusWithCountApiResponse =
  /** status 200  */ GetWorkOrderCountStatusResponse;
export type WorkOrderV2ControllerGetWorkOrderStatusWithCountApiArg = {
  companyId: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
  locationId?: string;
  projectId?: string;
  vendorId?: string;
  locFilterId?: string;
  filterStartDateIso?: string;
  filterEndDateIso?: string;
  filterAssetTypeIds?: string[];
  filterRaisedByIds?: string[];
};
export type WorkOrderV2ControllerGetWorkOrdersApiResponse =
  /** status 200  */ object[];
export type WorkOrderV2ControllerGetWorkOrdersApiArg = {
  vendorId: string;
  cursorId: string;
  firebaseUserId: string;
  defectStatusId: string;
};
export type WorkOrderV2ControllerGetPdfReportApiResponse =
  /** status 200 Get work order pdf */ Defect;
export type WorkOrderV2ControllerGetPdfReportApiArg = {
  workOrderId: string;
};
export type WorkOrderV2ControllerPostPdfReportApiResponse =
  /** status 200 Get work order pdf */ Defect | /** status 201  */ void;
export type WorkOrderV2ControllerPostPdfReportApiArg = {
  workOrderId: string;
};
export type WorkOrderV2ControllerUpdateWorkOrderApiResponse = unknown;
export type WorkOrderV2ControllerUpdateWorkOrderApiArg = {
  workOrderId: string;
  patchWorkOrderDto: PatchWorkOrderDto;
};
export type WorkOrderV2ControllerUpdateInventoryApiResponse =
  /** status 200  */ object;
export type WorkOrderV2ControllerUpdateInventoryApiArg = {
  workOrderId: string;
  updateWorkOrderStockDto: UpdateWorkOrderStockDto;
};
export type WorkOrderV2ControllerDeleteInventoryApiResponse = unknown;
export type WorkOrderV2ControllerDeleteInventoryApiArg = {
  workOrderId: string;
  deleteWorkOrderStockDto: DeleteWorkOrderStockDto;
};
export type WorkOrderV2ControllerUpdateWorkOrderPricingRefNoApiResponse =
  /** status 200  */ Defect;
export type WorkOrderV2ControllerUpdateWorkOrderPricingRefNoApiArg = {
  workOrderId: number;
  updateWorkOrderPricingRefNoDto: UpdateWorkOrderPricingRefNoDto;
};
export type StockTypesControllerGetStockTypesApiResponse =
  /** status 200 Get all stock types */ GetStockTypesDto[];
export type StockTypesControllerGetStockTypesApiArg = {
  projectId: string;
  searchName?: string;
};
export type StockTypesControllerCreateStockTypeApiResponse = unknown;
export type StockTypesControllerCreateStockTypeApiArg = {
  createStockTypeDto: CreateStockTypeDto;
};
export type StockTypesControllerGetOneStockTypeApiResponse =
  /** status 200 Get one stock type */ StockType;
export type StockTypesControllerGetOneStockTypeApiArg = {
  stockTypeId: string;
};
export type StockTypesControllerUpdateStockTypeApiResponse = unknown;
export type StockTypesControllerUpdateStockTypeApiArg = {
  stockTypeId: string;
  updateStockTypeDto: UpdateStockTypeDto;
};
export type StockTypesControllerDeleteStockTypeApiResponse = unknown;
export type StockTypesControllerDeleteStockTypeApiArg = {
  stockTypeId: string;
};
export type StocksControllerGetStocksCountApiResponse =
  /** status 200 Get stock counts only */ GetStockCountResponseDto;
export type StocksControllerGetStocksCountApiArg = {
  projectId: string;
  stockTypeId?: string;
  search?: string;
};
export type StocksControllerGetStocksApiResponse =
  /** status 200 Get all inventories */ StockEntity[];
export type StocksControllerGetStocksApiArg = {
  projectId: string;
  stockTypeId?: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type StocksControllerCreateStockApiResponse = unknown;
export type StocksControllerCreateStockApiArg = {
  createStockDto: CreateStockDto;
};
export type StocksControllerGetOneStockWithLedgerApiResponse =
  /** status 200 Get one stock with ledger */ GetOneStockWithLedgerResponseDto;
export type StocksControllerGetOneStockWithLedgerApiArg = {
  stockId: string;
};
export type StocksControllerUpdateStockApiResponse = /** status 200  */ object;
export type StocksControllerUpdateStockApiArg = {
  stockId: string;
  updateStockDto: UpdateStockDto;
};
export type StocksControllerDeleteInventoryApiResponse = unknown;
export type StocksControllerDeleteInventoryApiArg = {
  stockId: number;
};
export type StocksControllerDeletePhotoApiResponse = /** status 200  */ object;
export type StocksControllerDeletePhotoApiArg = {
  stockId: string;
  attachmentId: string;
};
export type VendorControllerGetVendorsCountApiResponse =
  /** status 200  */ number;
export type VendorControllerGetVendorsCountApiArg = {
  companyId: string;
  workscopeIds: string[];
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type VendorControllerGetVendorsApiResponse =
  /** status 200 Get all vendors */ GetVendorsResponseDto[];
export type VendorControllerGetVendorsApiArg = {
  companyId: string;
  workscopeIds: string[];
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type VendorControllerCreateVendorApiResponse = unknown;
export type VendorControllerCreateVendorApiArg = {
  createVendorDto: CreateVendorDto;
};
export type VendorControllerGetOneVendorApiResponse =
  /** status 200 Get one vendor */ GetVendorResponseDto;
export type VendorControllerGetOneVendorApiArg = {
  vendorId: string;
};
export type VendorControllerUpdateOneVendorApiResponse = unknown;
export type VendorControllerUpdateOneVendorApiArg = {
  vendorId: string;
  updateVendorDto: UpdateVendorDto;
};
export type VendorControllerDeleteVendorApiResponse = unknown;
export type VendorControllerDeleteVendorApiArg = {
  vendorId: string;
};
export type VendorControllerDeleteAttachmentApiResponse =
  /** status 200  */ string;
export type VendorControllerDeleteAttachmentApiArg = {
  vendorId: string;
  attachmentId: string;
};
export type WorkscopeControllerGetWorkScopesApiResponse =
  /** status 200 Get workscopes */ WorkScope[];
export type WorkscopeControllerGetWorkScopesApiArg = {
  companyId: string;
  search?: string;
};
export type WorkscopeControllerCreateWorkScopeApiResponse = unknown;
export type WorkscopeControllerCreateWorkScopeApiArg = {
  createWorkScopeDto: CreateWorkScopeDto;
};
export type WorkscopeControllerUpdateWorkScopeApiResponse = unknown;
export type WorkscopeControllerUpdateWorkScopeApiArg = {
  workscopeId: string;
  updateWorkscopeDto: UpdateWorkscopeDto;
};
export type WorkscopeControllerDeleteWorkScopeApiResponse = unknown;
export type WorkscopeControllerDeleteWorkScopeApiArg = {
  workscopeId: string;
};
export type WorkscopeControllerGetOneWorkscopeApiResponse =
  /** status 200 Get workscope */ WorkScope;
export type WorkscopeControllerGetOneWorkscopeApiArg = {
  workscopeId: string;
};
export type DefectStatusV2ControllerGetProjectBasedWorkOrderStatusApiResponse =
  /** status 200 Get all defect statuses based on user role */ DefectStatus[];
export type DefectStatusV2ControllerGetProjectBasedWorkOrderStatusApiArg = {
  roleId: string;
};
export type RolesControllerGetAllRolesApiResponse =
  /** status 200 Get all roles from a company */ GetProjWithRolesDto[];
export type RolesControllerGetAllRolesApiArg = {
  companyId: number;
};
export type RolesControllerGetRolesApiResponse =
  /** status 200 Get all roles */ GetRolesResponseDto[];
export type RolesControllerGetRolesApiArg = {
  projectId: string;
};
export type RolesControllerCreateRoleApiResponse =
  /** status 200 Create role */ Role | /** status 201  */ void;
export type RolesControllerCreateRoleApiArg = {
  createRoleDto: CreateRoleDto;
};
export type RolesControllerGetOneRoleApiResponse =
  /** status 200 Get one role */ Role;
export type RolesControllerGetOneRoleApiArg = {
  roleId: string;
};
export type RolesControllerUpdateRoleStatusApiResponse =
  /** status 200 Update role */ Role;
export type RolesControllerUpdateRoleStatusApiArg = {
  roleId: number;
  updateRoleDto: UpdateRoleDto;
};
export type WorkPermitsControllerGetWorkPermitCountApiResponse =
  /** status 200  */ string;
export type WorkPermitsControllerGetWorkPermitCountApiArg = {
  workPermitStatus?: "NEW" | "APPROVED" | "REJECTED" | "CANCELLED";
  projectId: number;
  startDateFilter?: string;
  endDateFilter?: string;
  search?: string;
};
export type WorkPermitsControllerCreateWorkPermitApiResponse = unknown;
export type WorkPermitsControllerCreateWorkPermitApiArg = {
  createWorkPermitDto: CreateWorkPermitDto;
};
export type WorkPermitsControllerGetWorkPermitsApiResponse =
  /** status 200 Get all work permits */ GetWorkPermitsResponseDto[];
export type WorkPermitsControllerGetWorkPermitsApiArg = {
  workPermitStatus: "NEW" | "APPROVED" | "REJECTED" | "CANCELLED";
  projectId: number;
  startDateFilter?: string;
  endDateFilter?: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type WorkPermitsControllerGetWorkPermitQrCodeRequestApiResponse =
  /** status 200  */ GetWorkPermitQrCodeResponseDto;
export type WorkPermitsControllerGetWorkPermitQrCodeRequestApiArg = {
  projectId: string;
};
export type WorkPermitsControllerGetOneWorkPermitApiResponse =
  /** status 200 Get one work permit */ WorkPermitWithWorkersResponseDto;
export type WorkPermitsControllerGetOneWorkPermitApiArg = {
  workPermitId: string;
};
export type WorkPermitsControllerDeleteWorkPermitApiResponse = unknown;
export type WorkPermitsControllerDeleteWorkPermitApiArg = {
  workPermitId: string;
};
export type WorkPermitsControllerUpdateWorkPermitStatusApiResponse = unknown;
export type WorkPermitsControllerUpdateWorkPermitStatusApiArg = {
  workPermitId: string;
  updateWorkPermitStatusDto: UpdateWorkPermitStatusDto;
};
export type TestControllerTestingApiResponse = /** status 200  */ object;
export type TestControllerTestingApiArg = void;
export type MeterReadingControllerGetMeterReadingsApiResponse =
  /** status 200  */ MeterReading[];
export type MeterReadingControllerGetMeterReadingsApiArg = {
  meterId: number;
  cursorId?: number;
  take?: number;
};
export type MeterReadingControllerCreateMeterReadingApiResponse = unknown;
export type MeterReadingControllerCreateMeterReadingApiArg = {
  createMeterReadingDto: CreateMeterReadingDto;
};
export type MeterReadingControllerGetOneMeterReadingApiResponse =
  /** status 200  */ GetOneMeterReadingResponseDto;
export type MeterReadingControllerGetOneMeterReadingApiArg = {
  meterReadingId: string;
};
export type MeterReadingControllerUpdateMeterReadingApiResponse = unknown;
export type MeterReadingControllerUpdateMeterReadingApiArg = {
  meterReadingId: string;
  updateMeterReadingDto: UpdateMeterReadingDto;
};
export type MeterReadingControllerDeleteMeterReadingApiResponse =
  /** status 200  */ string;
export type MeterReadingControllerDeleteMeterReadingApiArg = {
  meterReadingId: string;
};
export type MeterReadingControllerPostMeterReadingIoTApiResponse =
  /** status 201  */ MeterReading;
export type MeterReadingControllerPostMeterReadingIoTApiArg = {
  postMeterReadingIotDto: PostMeterReadingIotDto;
};
export type MeterReadingControllerDeleteMeterReadingPhotoApiResponse = unknown;
export type MeterReadingControllerDeleteMeterReadingPhotoApiArg = {
  meterReadingId: string;
  photoId: string;
};
export type NotificationControllerGetNotificationsApiResponse =
  /** status 200  */ AppNotification[];
export type NotificationControllerGetNotificationsApiArg = {
  userId: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type StockAdjustmentControllerCreateStockAdjustmentApiResponse = unknown;
export type StockAdjustmentControllerCreateStockAdjustmentApiArg = {
  createStockAdjustmentRequestDto: CreateStockAdjustmentRequestDto;
};
export type StockAdjustmentControllerGetAdjustmentListApiResponse = unknown;
export type StockAdjustmentControllerGetAdjustmentListApiArg = {
  projectId: string;
};
export type StockAdjustmentControllerGetOneAdjustmentApiResponse =
  /** status 200  */ object;
export type StockAdjustmentControllerGetOneAdjustmentApiArg = {
  stockAdjustmentId: string;
};
export type StockAdjustmentControllerUpdateAdjustmentApiResponse = unknown;
export type StockAdjustmentControllerUpdateAdjustmentApiArg = {
  stockAdjustmentId: string;
  updateStockAdjustmentDto: UpdateStockAdjustmentDto;
};
export type StockAdjustmentControllerDeleteAdjustmentApiResponse = unknown;
export type StockAdjustmentControllerDeleteAdjustmentApiArg = {
  stockAdjustmentId: string;
};
export type AnalyticControllerGetAnalyticsApiResponse = unknown;
export type AnalyticControllerGetAnalyticsApiArg = {
  projectId: string;
};
export type AnalyticControllerGetAnalyticsSettingsApiResponse =
  /** status 200 Returns the analytics settings */ Analytic[];
export type AnalyticControllerGetAnalyticsSettingsApiArg = {
  projectId: number;
};
export type AnalyticControllerCreateAnalyticApiResponse =
  /** status 200 Create analytics setting */ Analytic | /** status 201  */ void;
export type AnalyticControllerCreateAnalyticApiArg = {
  createMetabaseAnalyticDto: CreateMetabaseAnalyticDto;
};
export type OldMeterReadingControllerGetMeterReadingsApiResponse =
  /** status 200  */ MeterReading[];
export type OldMeterReadingControllerGetMeterReadingsApiArg = {
  meterId: number;
  cursorId: number;
};
export type IotMeterControllerGetIotMetersApiResponse =
  /** status 200  */ GetIotMeterResponseDto[];
export type IotMeterControllerGetIotMetersApiArg = {
  projectId: number;
};
export type IotMeterControllerCreateIotMeterApiResponse =
  /** status 201  */ IotMeter;
export type IotMeterControllerCreateIotMeterApiArg = {
  createIotMeterDto: CreateIotMeterDto;
};
export type IotMeterControllerGetOneIotMeterApiResponse =
  /** status 200  */ IotMeter;
export type IotMeterControllerGetOneIotMeterApiArg = {
  iotMeterId: number;
};
export type IotMeterControllerUpdateIotMeterApiResponse =
  /** status 200  */ IotMeter;
export type IotMeterControllerUpdateIotMeterApiArg = {
  iotMeterId: number;
  updateIotMeterDto: UpdateIotMeterDto;
};
export type IotMeterControllerDeleteIotMeterApiResponse =
  /** status 200  */ IotMeter;
export type IotMeterControllerDeleteIotMeterApiArg = {
  iotMeterId: number;
};
export type IotMeterReadingControllerCreateIotMeterReadingApiResponse =
  /** status 201  */ IotMeterReading;
export type IotMeterReadingControllerCreateIotMeterReadingApiArg = {
  createIotMeterReadingDto: CreateIotMeterReadingDto;
};
export type IotMeterReadingControllerGetIotMeterReadingsApiResponse =
  /** status 200  */ IotMeterReading[];
export type IotMeterReadingControllerGetIotMeterReadingsApiArg = {
  deviceId: string;
  startDate?: string;
  endDate?: string;
};
export type LegalComplianceControllerGetLegalCompliancesApiResponse =
  /** status 200 Get all legal compliances */ LegalCompliancesResponseDto[];
export type LegalComplianceControllerGetLegalCompliancesApiArg = {
  projectId: number;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type LegalComplianceControllerCreateLegalComplianceApiResponse =
  /** status 201  */ LegalCompliance;
export type LegalComplianceControllerCreateLegalComplianceApiArg = {
  createLegalComplianceDto: CreateLegalComplianceDto;
};
export type LegalComplianceControllerGetLegalComplianceCountApiResponse =
  /** status 200  */ GetLegalComplianceCountResponseDto;
export type LegalComplianceControllerGetLegalComplianceCountApiArg = {
  projectId: number;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type LegalComplianceControllerGetOneLegalComplianceApiResponse =
  /** status 200 Get one legal compliance */ LegalCompliancesResponseDto;
export type LegalComplianceControllerGetOneLegalComplianceApiArg = {
  legalComplianceId: number;
};
export type LegalComplianceControllerUpdateLegalComplianceApiResponse =
  /** status 200  */ LegalCompliance;
export type LegalComplianceControllerUpdateLegalComplianceApiArg = {
  legalComplianceId: number;
  updateLegalComplianceDto: UpdateLegalComplianceDto;
};
export type LegalComplianceControllerDeleteLegalComplianceApiResponse = unknown;
export type LegalComplianceControllerDeleteLegalComplianceApiArg = {
  legalComplianceId: number;
};
export type LegalComplianceControllerDeleteAttachmentApiResponse = unknown;
export type LegalComplianceControllerDeleteAttachmentApiArg = {
  legalComplianceId: number;
  documentId: number;
};
export type CronControllerRunReminderLegalComplianceApiResponse = unknown;
export type CronControllerRunReminderLegalComplianceApiArg = void;
export type CronControllerGeneratePmChecklistApiResponse = unknown;
export type CronControllerGeneratePmChecklistApiArg = void;
export type AccountTypeControllerCreateAccountTypeApiResponse = unknown;
export type AccountTypeControllerCreateAccountTypeApiArg = {
  createAccountTypeDto: CreateAccountTypeDto;
};
export type AccountTypeControllerUpdateAccountTypeApiResponse = unknown;
export type AccountTypeControllerUpdateAccountTypeApiArg = {
  accountTypeId: number;
  updateAccountTypeDto: UpdateAccountTypeDto;
};
export type AccountTypeControllerDeleteAccountTypeApiResponse = unknown;
export type AccountTypeControllerDeleteAccountTypeApiArg = {
  accountTypeId: number;
};
export type TnbBillControllerGetTnbReadingsApiResponse =
  /** status 200  */ GetTnbReadingsResponseDto;
export type TnbBillControllerGetTnbReadingsApiArg = {
  projectId: number;
  dateStartIsoFilter?: string;
  dateEndIsoFilter?: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type TnbBillControllerCreateTnbReadingApiResponse = unknown;
export type TnbBillControllerCreateTnbReadingApiArg = {
  createTnbReadingDto: CreateTnbReadingDto;
};
export type TnbBillControllerUpdateTnbApiResponse = unknown;
export type TnbBillControllerUpdateTnbApiArg = {
  tnbReadingId: number;
  updateTnbReadingDto: UpdateTnbReadingDto;
};
export type TnbBillControllerDeleteTnbReadingApiResponse = unknown;
export type TnbBillControllerDeleteTnbReadingApiArg = {
  tnbReadingId: number;
};
export type TnbBillControllerGetOneTnbReadingApiResponse =
  /** status 200  */ GetOneTnbReadingResponse;
export type TnbBillControllerGetOneTnbReadingApiArg = {
  tnbReadingId: number;
};
export type TnbBillControllerDeleteAttachmentApiResponse = unknown;
export type TnbBillControllerDeleteAttachmentApiArg = {
  tnbReadingId: number;
  attachmentId: number;
};
export type TnbBillControllerGetTnbAnalyticApiResponse =
  /** status 200  */ TnbAnalyticResponseDto;
export type TnbBillControllerGetTnbAnalyticApiArg = {
  projectId: number;
};
export type EscalationControllerGetEscalationsApiResponse =
  /** status 200  */ GetEscalationsResponseDto;
export type EscalationControllerGetEscalationsApiArg = {
  projectId: string;
  search?: string;
  cursorId?: string;
  page?: number;
  rowsPerPage?: number;
};
export type EscalationControllerCreateEscalationApiResponse = unknown;
export type EscalationControllerCreateEscalationApiArg = {
  createEscalationRequestDto: CreateEscalationRequestDto;
};
export type EscalationControllerGetOneEscalationApiResponse =
  /** status 200  */ GetOneEscalationResponseDto;
export type EscalationControllerGetOneEscalationApiArg = {
  escalationId: number;
};
export type EscalationControllerUpdateEscalationApiResponse = unknown;
export type EscalationControllerUpdateEscalationApiArg = {
  escalationId: number;
  updateEscalationRequestDto: UpdateEscalationRequestDto;
};
export type EscalationControllerDeleteEscalationApiResponse = unknown;
export type EscalationControllerDeleteEscalationApiArg = {
  escalationId: number;
};
export type DataImportsControllerImportLocationApiResponse =
  /** status 201  */ object;
export type DataImportsControllerImportLocationApiArg = {
  importLocationRequestDto: ImportLocationRequestDto;
};
export type DataImportsControllerImportAssetsApiResponse = unknown;
export type DataImportsControllerImportAssetsApiArg = {
  importAssetRequest: ImportAssetRequest;
};
export type DataImportsControllerGetLocationsApiResponse = unknown;
export type DataImportsControllerGetLocationsApiArg = {
  projectId: string;
};
export type Status = "DRAFT" | "ACTIVE" | "DELETED";
export type PmChecklistStatus = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name: string;
  companyId?: number;
  colorHex?: string;
  order?: number;
};
export type DefectStatus = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name: string;
  companyId?: number;
  colorHex?: string;
  order?: number;
};
export type Project = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
};
export type RoleEntity = {
  id: number;
  name: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  companyId?: number;
  projectId?: number;
  pmStatus: PmChecklistStatus[];
  defectStatus: DefectStatus[];
  project: Project;
};
export type ProjectEntity = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  roles: RoleEntity[];
};
export type Company = {
  id: number;
  companyUuid?: string;
  name?: string;
  registrationNo?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  isAssetTypeCustom: boolean;
  isWorkOrderTypeCustom: boolean;
};
export type AccountTypeEnum =
  | "SUPERADMIN"
  | "COMPANY_ADMIN"
  | "NORMAL_USER"
  | "VENDOR";
export type AccountTypeWithProject = {
  id: number;
  type: AccountTypeEnum;
  userId: number;
  projectId: number;
  project: Project;
};
export type Vendor = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name?: string;
  contactNo?: string;
  contactName?: string;
  businessAddress?: string;
  companyWebsite?: string;
  remarks?: string;
  companyId: number;
};
export type UserEntity = {
  id?: number;
  firebaseUserId?: string;
  createdOn?: string;
  name?: string;
  phoneNo?: string;
  position?: string;
  status?: Status;
  email?: string;
  deviceToken?: string;
  createdById?: number;
  updatedById?: number;
  firstTimeLogin?: boolean;
  vendorId?: number;
  projects: ProjectEntity[];
  companies: Company[];
  roles: RoleEntity[];
  accountTypes: AccountTypeWithProject[];
  vendor?: Vendor;
};
export type GetUserCountResponseDto = {
  totalCount: number;
};
export type Role = {
  id: number;
  name: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  companyId?: number;
  projectId?: number;
};
export type AccountType = {
  id: number;
  type: AccountTypeEnum;
  userId: number;
  projectId: number;
};
export type Attachment = {
  id?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  name?: string;
  gsPath?: string;
  url?: string;
  fileSizeMb?: string;
  underProjectId?: number;
};
export type GetOneUserResponseDto = {
  id?: number;
  firebaseUserId?: string;
  createdOn?: string;
  name?: string;
  phoneNo?: string;
  position?: string;
  status?: Status;
  email?: string;
  deviceToken?: string;
  createdById?: number;
  updatedById?: number;
  firstTimeLogin?: boolean;
  vendorId?: number;
  projects: Project[];
  companies: Company[];
  roles: Role[];
  accountTypes: AccountType[];
  vendor: Vendor;
  attachments: Attachment[];
};
export type AttachmentProjectType = {
  id: number;
  gsPath: string;
  url: string;
  name: string;
  underProject: Project;
  underProjectId: number;
  fileSizeMb: string;
};
export type UpdateUserV2Dto = {
  userId: number;
  name: string;
  email: string;
  phoneNo: string;
  position: string;
  attachments: AttachmentProjectType[];
  updatedById: number;
};
export type UpdateUserVendorDto = {
  vendorId: number;
};
export type UpdateUserProjectDto = {
  projectIds?: number[];
  rolesIds?: number[];
};
export type FirstTimeUserPasswordResponseDto = {
  id?: number;
  firebaseUserId?: string;
  createdOn?: string;
  name?: string;
  phoneNo?: string;
  position?: string;
  status?: Status;
  email?: string;
  deviceToken?: string;
  createdById?: number;
  updatedById?: number;
  firstTimeLogin?: boolean;
  vendorId?: number;
};
export type FirstTimeUserPasswordDto = {
  userId: string;
  newPassword: string;
  firebaseUserId: string;
};
export type CreateWorkRequestInternalDto = {
  email: string;
  description: string;
  contactNo: string;
  photos: AttachmentProjectType[];
  projectCode: string;
  title: string;
  location: string;
  dbLocationId?: string;
};
export type WorkRequestStatus =
  | "NEW"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "CANCELLED";
export type WorkRequest = {
  id: string;
  trackingCode: string;
  title?: string;
  description?: string;
  location?: string;
  email?: string;
  contactNo?: string;
  workOrderId?: number;
  createdOn: string;
  inProgressDate?: string;
  closedDate?: string;
  cancelledDate?: string;
  status: Status;
  workRequestStatus: WorkRequestStatus;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
  cancellationRemark?: string;
};
export type CreateWorkRequestDto = {
  email: string;
  description: string;
  contactNo: string;
  projectCode: string;
  title: string;
  location: string;
  dbLocationId: number;
};
export type Defect = {
  id?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  repairStartedOn?: string;
  completedOn?: string;
  subject?: string;
  description?: string;
  refNo?: string;
  locationId?: number;
  projectId?: number;
  defectTypeId?: number;
  defectStatusId?: number;
  assetId?: number;
  rating?: number;
  closingRemark?: string;
  gsPathPdf?: string;
  urlPdf?: string;
  namePdf?: string;
  costing?: number;
  costingRefNo?: string;
};
export type GetWorkRequestsResponseDto = {
  id: string;
  trackingCode: string;
  title?: string;
  description?: string;
  location?: string;
  email?: string;
  contactNo?: string;
  workOrderId?: number;
  createdOn: string;
  inProgressDate?: string;
  closedDate?: string;
  cancelledDate?: string;
  status: Status;
  workRequestStatus: WorkRequestStatus;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
  cancellationRemark?: string;
  workOrder: Defect;
  photos: Attachment[];
};
export type WorkRequestLogs = {
  id: number;
  createdOn: string;
  createdById?: number;
  description?: string;
  workRequestId: string;
  status: Status;
};
export type GetWorkRequestBasedFromCodeResponseDto = {
  id: string;
  trackingCode: string;
  title?: string;
  description?: string;
  location?: string;
  email?: string;
  contactNo?: string;
  workOrderId?: number;
  createdOn: string;
  inProgressDate?: string;
  closedDate?: string;
  cancelledDate?: string;
  status: Status;
  workRequestStatus: WorkRequestStatus;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
  cancellationRemark?: string;
  workRequestLogs: WorkRequestLogs[];
  photos: Attachment[];
};
export type DeleteWorkRequestDto = {
  cancellationRemark: string;
};
export type GetWorkRequestsCountResponseDto = {
  newCount: number;
  inProgressCount: number;
  completedCount: number;
  cancelledCount: number;
};
export type GetWorkRequestQrCodeResponseDto = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  signedUrl: string;
};
export type Location = {
  id: number;
  code?: string;
  description?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  name: string;
  parentId?: number;
  projectId?: number;
};
export type Asset = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  assetTypeId?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  projectId?: number;
  pmTemplateId?: number;
  locationId?: number;
};
export type WorkOrderWithLocationPhotoAsset = {
  id?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  repairStartedOn?: string;
  completedOn?: string;
  subject?: string;
  description?: string;
  refNo?: string;
  locationId?: number;
  projectId?: number;
  defectTypeId?: number;
  defectStatusId?: number;
  assetId?: number;
  rating?: number;
  closingRemark?: string;
  gsPathPdf?: string;
  urlPdf?: string;
  namePdf?: string;
  costing?: number;
  costingRefNo?: string;
  photos: AttachmentProjectType[];
  location: Location;
  asset: Asset;
};
export type GetOneWorkRequestResponseDto = {
  id: string;
  trackingCode: string;
  title?: string;
  description?: string;
  location?: string;
  email?: string;
  contactNo?: string;
  workOrderId?: number;
  createdOn: string;
  inProgressDate?: string;
  closedDate?: string;
  cancelledDate?: string;
  status: Status;
  workRequestStatus: WorkRequestStatus;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
  cancellationRemark?: string;
  photos: AttachmentProjectType[];
  workOrder: WorkOrderWithLocationPhotoAsset;
  dbLocation: Location;
};
export type MeterReading = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  readingDate?: string;
  status: Status;
  meterId: number;
  value: string;
};
export type GetAnalyticMeterReadingResponseDto = {
  date: string;
  meterReadings: MeterReading[];
};
export type Meter = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name: string;
  companyId?: number;
  unit: string;
  projectId?: number;
  deviceId?: string;
};
export type CreateMeterDto = {
  createdById: number;
  name: string;
  unit: string;
  projectId: number;
};
export type UpdateMeterDto = {
  name: string;
  unit: string;
  meterId: number;
  companyId: number;
};
export type AssetType = {
  id?: number;
  name?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  companyId?: number;
  projectId?: number;
};
export type AssetWithType = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  assetTypeId?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  projectId?: number;
  pmTemplateId?: number;
  locationId?: number;
  assetType: AssetType;
};
export type User = {
  id?: number;
  firebaseUserId?: string;
  createdOn?: string;
  name?: string;
  phoneNo?: string;
  position?: string;
  status?: Status;
  email?: string;
  deviceToken?: string;
  createdById?: number;
  updatedById?: number;
  firstTimeLogin?: boolean;
  vendorId?: number;
};
export type GetAssetsResponseDto = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  assetTypeId?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  projectId?: number;
  pmTemplateId?: number;
  locationId?: number;
  photos: Attachment;
  additionalDocuments: Attachment;
  assetType: AssetType;
  location: Location;
  createdBy: User;
};
export type CreateAssetDto = {
  name: string;
  assetCode: string;
  description: string;
  assetTypeId: number;
  photos: AttachmentProjectType[];
  additionalDocuments: AttachmentProjectType[];
  projectId: number;
  createdById: number;
  locationId?: number;
};
export type GetOneAsset = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  assetTypeId?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  projectId?: number;
  pmTemplateId?: number;
  locationId?: number;
  photos: Attachment[];
  additionalDocuments: Attachment[];
  assetType: AssetType;
  location: Location;
};
export type UpdateAssetDto = {
  updatedById: number;
  name: string;
  assetCode: string;
  description: string;
  assetTypeId: number;
  photos: AttachmentProjectType[];
  additionalDocuments: AttachmentProjectType[];
  projectId: number;
  locationId?: number;
};
export type AssetWorkOrdersResponseDto = {
  id?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  repairStartedOn?: string;
  completedOn?: string;
  subject?: string;
  description?: string;
  refNo?: string;
  locationId?: number;
  projectId?: number;
  defectTypeId?: number;
  defectStatusId?: number;
  assetId?: number;
  rating?: number;
  closingRemark?: string;
  gsPathPdf?: string;
  urlPdf?: string;
  namePdf?: string;
  costing?: number;
  costingRefNo?: string;
  asset: Asset;
  location: Location;
  photos: Attachment[];
};
export type AssetWithPhotosAndLoc = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  assetTypeId?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  projectId?: number;
  pmTemplateId?: number;
  locationId?: number;
  location: Location;
  photos: Attachment[];
};
export type PmTemplate = {
  id?: number;
  name?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  companyId?: number;
  frequency?: string;
  dateStart?: string;
  dateEnd?: string;
  projectId?: number;
};
export type PmChecklistResponseDto = {
  id: number;
  refNo?: string;
  pmTemplateId: number;
  assetId: number;
  projectId: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  pmStatusId: number;
  completedOn?: string;
  asset: AssetWithPhotosAndLoc;
  pmTemplate: PmTemplate;
  pmStatus: PmChecklistStatus;
  updatedBy?: User;
  downloadedBy?: User;
};
export type GetAssetQrCodeResponseDto = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  signedUrl: string;
};
export type CreateAssetTypeDto = {
  createdById: number;
  name: string;
  companyId: number;
  parentId?: number;
  projectId: number;
};
export type UpdateAssetTypeDto = {
  updatedById: number;
  name: string;
  companyId: number;
  parentId?: number;
  projectId: number;
};
export type ProjectWithAttachment = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  attachments: Attachment[];
};
export type GetCurrentUserResponseDto = {
  id?: number;
  firebaseUserId?: string;
  createdOn?: string;
  name?: string;
  phoneNo?: string;
  position?: string;
  status?: Status;
  email?: string;
  deviceToken?: string;
  createdById?: number;
  updatedById?: number;
  firstTimeLogin?: boolean;
  vendorId?: number;
  projects: ProjectWithAttachment[];
  companies: Company[];
  accountTypes: AccountType[];
  roles: Role[];
};
export type UpdateUserDto = {
  updateType: "patchInfo" | "reActivate";
  userId: number;
  name: string;
  email?: string;
  password?: string;
  phoneNo: string;
  position: string;
  attachments: AttachmentProjectType[];
};
export type DeleteUserDto = {
  id: number;
  updatedById: number;
  firebaseUserId: string;
};
export type UpdateDeviceTokenDto = {
  deviceToken: string;
  expoToken?: string;
};
export type CreateNewUserDto = {
  name: string;
  email: string;
  phoneNo: string;
  position: string;
  password: string;
  companiesId: number[];
  projectIds: number[];
  attachments: AttachmentProjectType[];
  createdById: number;
};
export type CreateProjectDefectStatusDto = {
  name: string;
  createdById: number;
};
export type CommentType = "COMMENT" | "STATUS";
export type GetCommentsResponseDto = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  description: string;
  defectId: number;
  commentType: CommentType;
  createdBy: User;
  attachments: Attachment[];
};
export type CreateCommentDto = {
  createdById: string;
  description: string;
  defectId: string;
  projectId: string;
  attachments: AttachmentProjectType[];
};
export type ProjectWithAttachmentResponse = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  attachments: Attachment[];
};
export type CompanyEntity = {
  id: number;
  companyUuid?: string;
  name?: string;
  registrationNo?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  isAssetTypeCustom: boolean;
  isWorkOrderTypeCustom: boolean;
  createdBy: User;
  projects: ProjectWithAttachmentResponse[];
  attachments: Attachment[];
  counts: number;
};
export type AdminGetCompaniesResponseDto = {
  companies: CompanyEntity[];
  totalCount: number;
};
export type CreateCompanyDto = {
  name: string;
  registrationNo: string;
  createdById: number;
  attachments: AttachmentProjectType[];
  companyUuid?: string;
  code: string;
};
export type AdminGetOneCompanyResponseDto = {
  id: number;
  companyUuid?: string;
  name?: string;
  registrationNo?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  isAssetTypeCustom: boolean;
  isWorkOrderTypeCustom: boolean;
  attachments: Attachment[];
};
export type UpdateCompanyDto = {
  updatedById: number;
  name: string;
  registrationNo: string;
  attachments: AttachmentProjectType[];
  code: string;
};
export type CreateProjectDto = {
  name: string;
  attachments: AttachmentProjectType[];
  code: string;
  createdById: number;
};
export type GetRolesUnderCompanyResponseDto = {
  id: number;
  name: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  companyId?: number;
  projectId?: number;
  project: Project;
};
export type AdminGetOneProjectResponseDto = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  attachments: Attachment[];
};
export type UpdateProjectDto = {
  name: string;
  attachments: AttachmentProjectType[];
  updatedById: number;
  code: string;
};
export type DefectType = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  companyId: number;
  projectId?: number;
  parentId?: number;
  name: string;
};
export type GetWorkOrdersResponseDto = {
  id?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  repairStartedOn?: string;
  completedOn?: string;
  subject?: string;
  description?: string;
  refNo?: string;
  locationId?: number;
  projectId?: number;
  defectTypeId?: number;
  defectStatusId?: number;
  assetId?: number;
  rating?: number;
  closingRemark?: string;
  gsPathPdf?: string;
  urlPdf?: string;
  namePdf?: string;
  costing?: number;
  costingRefNo?: string;
  defectType: DefectType;
  project: Project;
  location: Location;
  photos: Attachment[];
  defectStatus: DefectStatus;
  createdBy: User;
  asset: Asset;
  filterStartDateISO?: string;
  filterEndDateISO?: string;
};
export type CreateWorkOrderDto = {
  createdById: number;
  locationId: number;
  assetId: number;
  projectId: number;
  subject: string;
  description: string;
  photos: AttachmentProjectType[];
  defectTypeId: number;
  assignedUsersIds: number[];
  assignedVendorsIds: number[];
  workRequestIds?: string[];
};
export type AssignedUserWithVendor = {
  id?: number;
  firebaseUserId?: string;
  createdOn?: string;
  name?: string;
  phoneNo?: string;
  position?: string;
  status?: Status;
  email?: string;
  deviceToken?: string;
  createdById?: number;
  updatedById?: number;
  firstTimeLogin?: boolean;
  vendorId?: number;
  vendor?: Vendor;
};
export type StockType = {
  id: number;
  name: string;
  status: Status;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
};
export type StockWithType = {
  id: number;
  name: string;
  balance?: number;
  description?: string;
  status?: Status;
  createdOn?: string;
  updatedOn?: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  projectId?: number;
  stockTypeId?: number;
  stockType: StockType;
};
export type StockLedgerWithStockType = {
  id: number;
  date: string;
  adjustmentQty: number;
  status: Status;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
  stockId: number;
  stockAdjustmentId?: number;
  pmChecklistId?: number;
  defectId?: number;
  stock: StockWithType;
};
export type WorkRequestWithPhotos = {
  id: string;
  trackingCode: string;
  title?: string;
  description?: string;
  location?: string;
  email?: string;
  contactNo?: string;
  workOrderId?: number;
  createdOn: string;
  inProgressDate?: string;
  closedDate?: string;
  cancelledDate?: string;
  status: Status;
  workRequestStatus: WorkRequestStatus;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
  cancellationRemark?: string;
  photos: Attachment[];
};
export type GetOneWorkOrderResponseDto = {
  id?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  repairStartedOn?: string;
  completedOn?: string;
  subject?: string;
  description?: string;
  refNo?: string;
  locationId?: number;
  projectId?: number;
  defectTypeId?: number;
  defectStatusId?: number;
  assetId?: number;
  rating?: number;
  closingRemark?: string;
  gsPathPdf?: string;
  urlPdf?: string;
  namePdf?: string;
  costing?: number;
  costingRefNo?: string;
  defectType: DefectType;
  project: Project;
  location: object;
  photos: Attachment[];
  defectStatus: DefectStatus;
  createdBy: User;
  asset: Asset;
  assignedUsers: AssignedUserWithVendor[];
  assignedVendors: Vendor[];
  stockLedgers: StockLedgerWithStockType[];
  workRequests: WorkRequestWithPhotos[];
};
export type PatchDefectDto = {
  type: "CHANGE_STATUS" | "CLOSE";
  defectId: number;
  defectStatusId: number;
  updatedById: number;
  closingRemark?: string;
  rating?: number;
  projectId: number;
};
export type GetMbQuotaResponseDto = {
  mbRemaining: number;
  mbQuota: number;
};
export type CreatePmChecklistDto = {
  createdById: number;
  projectId: number;
  assetIds: number[];
  pmTemplateId: number;
};
export type AssetWithLocation = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  assetTypeId?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  projectId?: number;
  pmTemplateId?: number;
  locationId?: number;
  location: Location;
};
export type PmFormatTypeEnum =
  | "BOOL"
  | "VARCHAR"
  | "NUMBER"
  | "MULTICHOICES"
  | "ATTACHMENT";
export type PmFormatType = {
  id: number;
  name: PmFormatTypeEnum;
};
export type PmFormatWithType = {
  id: number;
  name: string;
  pmTemplateId: number;
  pmFormatTypeId: number;
  order: number;
  pmFormatType: PmFormatType;
};
export type PmTemplateWithFormat = {
  id?: number;
  name?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  companyId?: number;
  frequency?: string;
  dateStart?: string;
  dateEnd?: string;
  projectId?: number;
  pmFormat: PmFormatWithType[];
};
export type PmFormat = {
  id: number;
  name: string;
  pmTemplateId: number;
  pmFormatTypeId: number;
  order: number;
};
export type PmChecklistNumberWithFormat = {
  id: number;
  pmChecklistId?: number;
  pmFormatId: number;
  value: string;
  pmFormat: PmFormat;
};
export type PmChecklistBoolWithFormat = {
  id: number;
  pmChecklistId?: number;
  pmFormatId: number;
  value: boolean;
  pmFormat: PmFormat;
};
export type PmChecklistTextWithFormat = {
  id: number;
  pmChecklistId?: number;
  pmFormatId: number;
  value: string;
  pmFormat: PmFormat;
};
export type PmChecklistAttachmentWithFormat = {
  id: number;
  pmChecklistId?: number;
  pmFormatId: number;
  pmFormat: PmFormat;
  attachments: Attachment[];
};
export type PmChecklistWithAssetAndTemplateFormat = {
  id: number;
  refNo?: string;
  pmTemplateId: number;
  assetId: number;
  projectId: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  pmStatusId: number;
  completedOn?: string;
  asset: AssetWithLocation;
  pmTemplate: PmTemplateWithFormat;
  project: Project;
  pmChecklistNumber: PmChecklistNumberWithFormat[];
  pmChecklistBool: PmChecklistBoolWithFormat[];
  pmChecklistText: PmChecklistTextWithFormat[];
  pmChecklistAttachment: PmChecklistAttachmentWithFormat[];
  updatedBy: User;
  pmStatus: PmChecklistStatus;
};
export type GetOpenPmChecklistsResponseDto = {
  data: PmChecklistWithAssetAndTemplateFormat[];
  totalCount: number;
};
export type PmChecklistWithAssetAndPmTemplate = {
  id: number;
  refNo?: string;
  pmTemplateId: number;
  assetId: number;
  projectId: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  pmStatusId: number;
  completedOn?: string;
  asset: Asset;
  pmTemplate: PmTemplate;
  updatedBy?: User;
};
export type GetPmChecklistTableResponseDto = {
  totalCount: number;
  data: PmChecklistWithAssetAndPmTemplate[];
};
export type StatusCount = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name: string;
  companyId?: number;
  colorHex?: string;
  order?: number;
  count: number;
};
export type GetLocationWithPmCountResponse = {
  id: number;
  name: string;
  statusCounts: StatusCount[];
};
export type GetAssetsWithPmCountResponseDto = {
  id: number;
  name: string;
  statusCounts: StatusCount[];
};
export type AssetWithLocAndType = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  assetTypeId?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  projectId?: number;
  pmTemplateId?: number;
  locationId?: number;
  location: Location;
  assetType: AssetType;
};
export type PmChecklistAttachmentWithPmFormatAndAtt = {
  id: number;
  pmChecklistId?: number;
  pmFormatId: number;
  attachments: Attachment[];
  pmFormat: PmFormatWithType;
};
export type GetOnePmChecklistResponse = {
  id: number;
  refNo?: string;
  pmTemplateId: number;
  assetId: number;
  projectId: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  pmStatusId: number;
  completedOn?: string;
  pmStatus: PmChecklistStatus;
  asset: AssetWithLocAndType;
  createdBy: User;
  pmTemplate: PmTemplateWithFormat;
  pmChecklistAttachment: PmChecklistAttachmentWithPmFormatAndAtt[];
  pmChecklistBool: PmChecklistBoolWithFormat[];
  pmChecklistNumber: PmChecklistNumberWithFormat[];
  pmChecklistText: PmChecklistTextWithFormat[];
  project: Project;
};
export type PmValueDto = {
  type: "BOOL" | "VARCHAR" | "NUMBER" | "MULTICHOICES" | "ATTACHMENT";
  value: number | string | AttachmentProjectType[] | boolean;
  valueId: number;
  pmFormatId: number;
  attachments: AttachmentProjectType[];
};
export type UpdatePmChecklistDto = {
  pmStatusId: number;
  updatedById: number;
  projectId: number;
  assetId: number;
  pmTemplateId: number;
  values: PmValueDto[];
};
export type PartialUser = {
  id?: number;
  name?: string;
};
export type IsDownloadedByDto = {
  isDownloaded: boolean;
  downloadedBy: PartialUser;
};
export type CheckIsDownloadedResponseDto = {
  data: IsDownloadedByDto;
};
export type GetLocationResponseDto = {
  id: number;
  code?: string;
  description?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  name: string;
  parentId?: number;
  projectId?: number;
  children: Location[];
};
export type CreateLocationDto = {
  parentId?: number;
  name: string;
  createdById: number;
  projectId: number;
  photos: AttachmentProjectType[];
  additionalDocuments: AttachmentProjectType[];
  locationCode: string;
  description: string;
};
export type GetOneLocationResponseDto = {
  id: number;
  code?: string;
  description?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  name: string;
  parentId?: number;
  projectId?: number;
  photos: Attachment[];
  additionalDocuments: Attachment[];
};
export type UpdateLocationDto = {
  updatedById: string;
  parentId?: number;
  name: string;
  projectId: number;
  photos: AttachmentProjectType[];
  additionalDocuments: AttachmentProjectType[];
  locationCode: string;
  description: string;
};
export type CreateDefectTypeDto = {
  parentId?: number;
  name: string;
  createdById: number;
  companyId: number;
};
export type UpdateDefectTypeDto = {
  updatedById: number;
  name: string;
};
export type PmFormatTypeDto = {
  name: object;
};
export type PmFormatDtoWithoutId = {
  name: string;
  pmFormatType: PmFormatTypeDto;
  order: number;
};
export type AddPmTemplateDto = {
  name: string;
  companyId: number;
  createdById: number;
  assetIds: number[];
  pmFormats: PmFormatDtoWithoutId[];
  frequency: string;
  startDate: string;
  endDate: string;
  projectId: number;
};
export type PmTemplateWithAsset = {
  id?: number;
  name?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  companyId?: number;
  frequency?: string;
  dateStart?: string;
  dateEnd?: string;
  projectId?: number;
  asset: Asset[];
};
export type PmTemplateResponseDto = {
  data: PmTemplateWithAsset[];
  totalCount: number;
};
export type GetOnePmTemplateResponseDto = {
  id?: number;
  name?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  companyId?: number;
  frequency?: string;
  dateStart?: string;
  dateEnd?: string;
  projectId?: number;
  createdBy: User;
  asset: Asset[];
  pmFormat: PmFormatWithType[];
};
export type PmFormatDto = {
  id: number;
  pmFormatId: number;
  name: string;
  pmFormatType: PmFormatTypeDto;
  order: number;
};
export type UpdatePmTemplateDto = {
  name: string;
  companyId: number;
  updatedById: number;
  assetIds: number[];
  pmFormats: PmFormatDto[];
  frequency: string;
  startDate: string;
  endDate: string;
  projectId: number;
};
export type WorkOrderStatusesWithCount = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name: string;
  companyId?: number;
  colorHex?: string;
  order?: number;
  count: number;
};
export type GetWorkOrderCountStatusResponse = {
  workOrderStatusesWithCount: WorkOrderStatusesWithCount[];
};
export type PatchWorkOrderDto = {
  defectId: number;
  defectStatusId?: number;
  updatedById: number;
  type?: object;
  closingRemark?: string;
  rating?: number;
  assignedUsers?: number[];
};
export type PartialStockType = {
  id: number;
  name: string;
};
export type PartialAtt = {
  id?: number;
  url?: string;
};
export type StockEntity = {
  id: number;
  name: string;
  balance?: number;
  description?: string;
  status?: Status;
  createdOn?: string;
  updatedOn?: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  projectId?: number;
  stockTypeId?: number;
  stockType: PartialStockType;
  photos: PartialAtt[];
  createdBy: PartialUser;
};
export type LedgersDto = {
  id?: string;
  stockId: string;
  adjustmentQty: string;
  stock: StockEntity;
  projectId: number;
  date: string;
};
export type UpdateWorkOrderStockDto = {
  workOrderId: number;
  ledgers: LedgersDto[];
};
export type DeleteWorkOrderStockDto = {
  workOrderId: number;
  ledgerIds: number[];
};
export type UpdateWorkOrderPricingRefNoDto = {
  costing?: number;
  costingRefNo?: string;
};
export type GetStockTypesDto = {
  id: number;
  name: string;
};
export type CreateStockTypeDto = {
  name: string;
  projectId: string;
};
export type UpdateStockTypeDto = {
  name: string;
  projectId: string;
};
export type GetStockCountResponseDto = {
  totalCount: number;
};
export type CreateStockDto = {
  name: string;
  projectId: string;
  stockTypeId?: string;
  photos: AttachmentProjectType[];
  initialCount: string;
  description: string;
};
export type StockAdjustment = {
  id: number;
  date: string;
  status: Status;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
};
export type PmChecklist = {
  id: number;
  refNo?: string;
  pmTemplateId: number;
  assetId: number;
  projectId: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  updatedOn?: string;
  status: Status;
  pmStatusId: number;
  completedOn?: string;
};
export type PartialStockLedger = {
  id: number;
  date: string;
  adjustmentQty: number;
  balance: number;
  stockAdjustment: StockAdjustment;
  pmChecklist: PmChecklist;
  defect: Defect;
  createdBy: User;
};
export type GetOneStockWithLedgerResponseDto = {
  id: number;
  name: string;
  balance: number | null;
  stockType: StockType;
  ledgers: PartialStockLedger[];
  description: string;
  photos: Attachment[];
};
export type UpdateStockDto = {
  name: string;
  projectId: string;
  stockTypeId?: string;
  photos: AttachmentProjectType[];
  description: string;
};
export type WorkScope = {
  id: number;
  name: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  companyId?: number;
};
export type GetVendorsResponseDto = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name?: string;
  contactNo?: string;
  contactName?: string;
  businessAddress?: string;
  companyWebsite?: string;
  remarks?: string;
  companyId: number;
  workscope: WorkScope[];
  avgScore?: string;
  count: string;
};
export type CreateVendorDto = {
  name: string;
  contactNo: string;
  contactName: string;
  businessAddress: string;
  companyWebsite: string;
  remarks: string;
  companyId: number;
  workScopeIds: number[];
  createdById: number;
  attachments: AttachmentProjectType[];
};
export type GetVendorResponseDto = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name?: string;
  contactNo?: string;
  contactName?: string;
  businessAddress?: string;
  companyWebsite?: string;
  remarks?: string;
  companyId: number;
  avgScore: number;
  count: number;
  attachments: Attachment[];
  workscope: WorkScope[];
};
export type UpdateVendorDto = {
  updatedById: string;
  name: string;
  contactNo: string;
  contactName: string;
  businessAddress: string;
  companyWebsite: string;
  remarks: string;
  companyId: number;
  workScopeIds: number[];
  attachments: AttachmentProjectType[];
};
export type CreateWorkScopeDto = {
  createdById: number;
  companyId: number;
  name: string;
};
export type UpdateWorkscopeDto = {
  updatedById: string;
  companyId: number;
  name: string;
};
export type RoleWithStatuses = {
  id: number;
  name: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  companyId?: number;
  projectId?: number;
  defectStatuses: DefectStatus[];
  pmStatuses: PmChecklistStatus[];
};
export type GetProjWithRolesDto = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  roles: RoleWithStatuses[];
};
export type GetRolesResponseDto = {
  id: number;
  name: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  companyId?: number;
  projectId?: number;
  defectStatuses: DefectStatus[];
  pmStatuses: PmChecklistStatus[];
  project: Project;
};
export type CreateRoleDto = {
  name: string;
  projectId: number;
};
export type UpdateRoleDto = {
  name: string;
  defectStatuses?: DefectStatus[];
  pmStatuses?: PmChecklistStatus[];
};
export type WorkPermitWorker = {
  id?: number;
  workPermitId?: string;
  name: string;
  icNo: string;
};
export type CreateWorkPermitDto = {
  applierCompanyName: string;
  email: string;
  telNo: string;
  description: string;
  workStartDate: string;
  workEndDate: string;
  locationOfWork: string;
  workers: WorkPermitWorker[];
  projectCode: string;
};
export type WorkPermitStatus = "NEW" | "APPROVED" | "REJECTED" | "CANCELLED";
export type GetWorkPermitsResponseDto = {
  id: string;
  applierCompanyName: string;
  email: string;
  telNo: string;
  createdOn: string;
  description: string;
  workStartDate: string;
  workEndDate: string;
  locationOfWork: string;
  updatedOn: string;
  deletedOn?: string;
  workPermitStatus: WorkPermitStatus;
  status: Status;
  projectId: number;
  workers: WorkPermitWorker[];
};
export type GetWorkPermitQrCodeResponseDto = {
  id?: number;
  name?: string;
  code?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  updatedOn?: string;
  status?: Status;
  companyId?: number;
  grossFloorArea?: number;
  netLettableArea?: number;
  commonArea?: number;
  carParkArea?: number;
  qrCodeGsPath?: string;
  qrCodeUrl?: string;
  qrCodeName?: string;
  storageQuotaMb: number;
  workPermitCode?: string;
  workPermitUrl?: string;
  workPermitGsPath?: string;
  workRequestCode?: string;
  workRequestUrl?: string;
  workRequestGsPath?: string;
  signedUrl: string;
};
export type WorkPermitWithWorkersResponseDto = {
  id: string;
  applierCompanyName: string;
  email: string;
  telNo: string;
  createdOn: string;
  description: string;
  workStartDate: string;
  workEndDate: string;
  locationOfWork: string;
  updatedOn: string;
  deletedOn?: string;
  workPermitStatus: WorkPermitStatus;
  status: Status;
  projectId: number;
  workers: WorkPermitWorker[];
};
export type UpdateWorkPermitStatusDto = {
  workPermitStatus: WorkPermitStatus;
};
export type AttWithoutFullProj = {
  gsPath: string;
  url: string;
  name: string;
  underProjectId: number;
  fileSizeMb: string;
};
export type CreateMeterReadingDto = {
  meterId: number;
  value: number;
  readingDate: string;
  createdById: number;
  photos: AttWithoutFullProj[];
};
export type GetOneMeterReadingResponseDto = {
  id: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  readingDate?: string;
  status: Status;
  meterId: number;
  value: string;
  photo: Attachment[];
  createdBy: User;
};
export type UpdateMeterReadingDto = {
  value: number;
  readingDate: string;
  updatedById: number;
  photos: AttWithoutFullProj[];
};
export type EnergyIotDto = {
  TotalStartTime: string;
  Total: number;
  Yesterday: number;
  Today: number;
  Period: number;
  Power: number;
  ApparentPower: number;
  ReactivePower: number;
  Factor: number;
  Voltage: number;
  Current: number;
};
export type PostMeterReadingIotDto = {
  projectId: number;
  deviceId: string;
  Time: string;
  ENERGY: EnergyIotDto;
};
export type NotificationTypeEnum =
  | "GENERAL"
  | "WORK_ORDER"
  | "PM"
  | "WORK_REQUEST"
  | "WORK_PERMIT"
  | "LEGAL_COMPLIANCE";
export type AppNotification = {
  id: number;
  createdById?: number;
  createdOn: string;
  type: NotificationTypeEnum;
  title: string;
  description: string;
  metaData: object;
};
export type StockLedger = {
  id: number;
  date: string;
  adjustmentQty: number;
  status: Status;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  projectId: number;
  stockId: number;
  stockAdjustmentId?: number;
  pmChecklistId?: number;
  defectId?: number;
};
export type CreateStockAdjustmentRequestDto = {
  projectId: string;
  date: string;
  ledgers: StockLedger[];
};
export type UpdateStockAdjustmentDto = {
  projectId: string;
  date: string;
  ledgers: LedgersDto[];
};
export type Analytic = {
  id: number;
  projectId: number;
  tabName: string;
  dashboardUrl: string;
  dashboardHeight: number;
  dashboardNo: number;
  params: object;
};
export type CreateMetabaseAnalyticDto = {
  projectId: number;
  tabName: string;
  dashboardUrl: string;
  dashboardHeight: number;
  dashboardNo: number;
  params: object;
};
export type IotMeterReading = {
  id: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  reading: number;
  readingDate: string;
  iotMeterId: number;
};
export type GetIotMeterResponseDto = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  deviceId: string;
  projectId: number;
  iotMeterReadings: IotMeterReading[];
};
export type IotMeter = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  deviceId: string;
  projectId: number;
};
export type CreateIotMeterDto = {
  deviceId: string;
  projectId: number;
};
export type UpdateIotMeterDto = {
  deviceId: string;
  projectId: number;
};
export type CreateIotMeterReadingDto = {
  deviceId: string;
  Time: string;
  ENERGY: EnergyIotDto;
};
export type LegalComplianceStatus = "ACTIVE" | "EXPIRED";
export type AttachmentWithoutSize = {
  id?: number;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  createdOn?: string;
  deletedOn?: string;
  status?: Status;
  name?: string;
  gsPath?: string;
  url?: string;
  underProjectId?: number;
};
export type LegalCompliancesResponseDto = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name: string;
  description: string;
  legalComplianceStatus: LegalComplianceStatus;
  expiryDate: string;
  projectId?: number;
  daysLeft: number;
  documents: AttachmentWithoutSize[];
};
export type LegalCompliance = {
  id: number;
  createdById: number;
  updatedById?: number;
  deletedById?: number;
  createdOn: string;
  deletedOn?: string;
  status: Status;
  name: string;
  description: string;
  legalComplianceStatus: LegalComplianceStatus;
  expiryDate: string;
  projectId?: number;
};
export type CreateLegalComplianceDto = {
  createdById: number;
  name: string;
  description: string;
  projectId?: number;
  expiryDate: string;
  documents: AttWithoutFullProj[];
};
export type GetLegalComplianceCountResponseDto = {
  count: number;
};
export type UpdateLegalComplianceDto = {
  updatedById?: number;
  name: string;
  description: string;
  expiryDate: string;
  projectId?: number;
  documents: AttWithoutFullProj[];
};
export type CreateAccountTypeDto = {
  type: "SUPERADMIN" | "COMPANY_ADMIN" | "NORMAL_USER" | "VENDOR";
  userId: number;
  projectId: number;
};
export type UpdateAccountTypeDto = {
  id: number;
  type: AccountTypeEnum;
  userId: number;
  projectId: number;
};
export type TnbReadingWithAttachment = {
  id: number;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  status: Status;
  kiloWattMd: number;
  kiloWattHr: number;
  totalBillCost: number;
  billMonth: string;
  description?: string;
  attachments: Attachment[];
};
export type GetTnbReadingsResponseDto = {
  data: TnbReadingWithAttachment[];
  totalCount: number;
};
export type CreateTnbReadingDto = {
  attachments: AttachmentProjectType[];
  projectId: number;
  kiloWattMd: number;
  kiloWattHr: number;
  totalBillCost: number;
  billMonth: string;
  description?: string;
};
export type UpdateTnbReadingDto = {
  attachments: AttachmentProjectType[];
  kiloWattMd: number;
  kiloWattHr: number;
  totalBillCost: number;
  billMonth: string;
  description?: string;
};
export type GetOneTnbReadingResponse = {
  id: number;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string;
  createdById?: number;
  updatedById?: number;
  deletedById?: number;
  status: Status;
  kiloWattMd: number;
  kiloWattHr: number;
  totalBillCost: number;
  billMonth: string;
  description?: string;
  attachments: Attachment[];
};
export type TnbAnalyticResponseDto = {
  data: string;
  dashboardHeight: number;
};
export type EscalationType = "WORK_REQUEST" | "WORK_ORDER" | "PM" | "ALL";
export type EscalationWithUsers = {
  id?: number;
  description?: string;
  noOfDaysAging: number;
  createdBy?: User;
  project: Project;
  escalationType: EscalationType;
  notifiedUsers: User[];
};
export type GetEscalationsResponseDto = {
  escalations: EscalationWithUsers[];
  totalCount: number;
};
export type CreateEscalationRequestDto = {
  description: string;
  noOfDaysAging: number;
  notifiedUserIds: number[];
  projectId: number;
  frequency?: string;
};
export type GetOneEscalationResponseDto = {
  id?: number;
  description?: string;
  noOfDaysAging: number;
  createdBy?: User;
  project: Project;
  escalationType: EscalationType;
  notifiedUsers: User[];
};
export type UpdateEscalationRequestDto = {
  description: string;
  noOfDaysAging: number;
  notifiedUserIds: number[];
  frequency?: string;
};
export type LocationCsvObject = {
  LocationId: string;
  Name: string;
  Code: string;
  LocationParentId: string;
};
export type ImportLocationRequestDto = {
  importLocationCsvData: LocationCsvObject[];
  projectId: string;
};
export type ImportAssetCsvObj = {
  LocationCode: string;
  Name: string;
  Code: string;
  EquipmentType: string;
};
export type ImportAssetRequest = {
  data: ImportAssetCsvObj[];
  projectId: string;
  companyId: string;
};
export const {
  useAppControllerTestingQuery,
  useLazyAppControllerTestingQuery,
  useUserControllerGetUsersQuery,
  useLazyUserControllerGetUsersQuery,
  useUserControllerGetUserCountQuery,
  useLazyUserControllerGetUserCountQuery,
  useUserControllerGetOneUserQuery,
  useLazyUserControllerGetOneUserQuery,
  useUserControllerDeleteAttachmentMutation,
  useUserControllerUpdateUserMutation,
  useUserControllerUpdateUserVendorMutation,
  useUserControllerRemoveUserVendorMutation,
  useUserControllerUpdateUserProjectsMutation,
  useAdminControllerFirstTimePasswordChangeMutation,
  useWorkRequestControllerCreateWorkRequestInternallyMutation,
  useWorkRequestControllerCreateWorkRequestMutation,
  useWorkRequestControllerGetWorkRequestsQuery,
  useLazyWorkRequestControllerGetWorkRequestsQuery,
  useWorkRequestControllerGetWorkRequestBasedFromCodeQuery,
  useLazyWorkRequestControllerGetWorkRequestBasedFromCodeQuery,
  useWorkRequestControllerDeleteWorkRequestMutation,
  useWorkRequestControllerGetWorkRequestStatusCountQuery,
  useLazyWorkRequestControllerGetWorkRequestStatusCountQuery,
  useWorkRequestControllerGetWorkRequestQrCodeQuery,
  useLazyWorkRequestControllerGetWorkRequestQrCodeQuery,
  useWorkRequestControllerGetOneWorkRequestQuery,
  useLazyWorkRequestControllerGetOneWorkRequestQuery,
  useAnalyticMeterControllerGetMeterReadingQuery,
  useLazyAnalyticMeterControllerGetMeterReadingQuery,
  useMeterControllerGetMetersQuery,
  useLazyMeterControllerGetMetersQuery,
  useMeterControllerCreateMeterMutation,
  useMeterControllerGetOneMeterQuery,
  useLazyMeterControllerGetOneMeterQuery,
  useMeterControllerUpdateMeterMutation,
  useMeterControllerDeleteMeterMutation,
  useAssetControllerGetFullAssetsQuery,
  useLazyAssetControllerGetFullAssetsQuery,
  useAssetControllerGetAssetsQuery,
  useLazyAssetControllerGetAssetsQuery,
  useAssetControllerCreateAssetMutation,
  useAssetControllerGetOneAssetQuery,
  useLazyAssetControllerGetOneAssetQuery,
  useAssetControllerUpdateAssetMutation,
  useAssetControllerDeleteAssetMutation,
  useAssetControllerDeleteAttachmentMutation,
  useAssetControllerDeletePhotoMutation,
  useAssetControllerGetAssetWorkOrdersQuery,
  useLazyAssetControllerGetAssetWorkOrdersQuery,
  useAssetControllerGetAssetPmQuery,
  useLazyAssetControllerGetAssetPmQuery,
  useAssetControllerGenerateQrCodeQuery,
  useLazyAssetControllerGenerateQrCodeQuery,
  useAssetControllerGetQrCodeQuery,
  useLazyAssetControllerGetQrCodeQuery,
  useAssetTypeControllerGetAssetTypeQuery,
  useLazyAssetTypeControllerGetAssetTypeQuery,
  useAssetTypeControllerCreateAssetTypeMutation,
  useAssetTypeControllerGetAssetTypeCountQuery,
  useLazyAssetTypeControllerGetAssetTypeCountQuery,
  useAssetTypeControllerGetOneAssetTypeQuery,
  useLazyAssetTypeControllerGetOneAssetTypeQuery,
  useAssetTypeControllerUpdateAssetTypeMutation,
  useAssetTypeControllerDeleteAssetTypeMutation,
  useAttachmentControllerGetSignedUrlQuery,
  useLazyAttachmentControllerGetSignedUrlQuery,
  useAuthControllerGetCurrentUserQuery,
  useLazyAuthControllerGetCurrentUserQuery,
  useAuthControllerUpdateUserMutation,
  useAuthControllerDeleteUserMutation,
  useAuthControllerUpdateTokenMutation,
  useAuthControllerForgotPasswordMutation,
  useAuthV2ControllerGetUsersQuery,
  useLazyAuthV2ControllerGetUsersQuery,
  useAuthV2ControllerCreateNewUserMutation,
  useAuthV2ControllerUpdateUserMutation,
  useDefectStatusControllerGetDefectStatusQuery,
  useLazyDefectStatusControllerGetDefectStatusQuery,
  useDefectStatusControllerCreateProjectBasedDefectStatusMutation,
  useDefectStatusControllerGetAllWorkOrderStatusQuery,
  useLazyDefectStatusControllerGetAllWorkOrderStatusQuery,
  useCommentControllerGetCommentsQuery,
  useLazyCommentControllerGetCommentsQuery,
  useCommentControllerCreateCommentMutation,
  useCompaniesControllerGetAllCompaniesQuery,
  useLazyCompaniesControllerGetAllCompaniesQuery,
  useCompaniesControllerCreateCompanyMutation,
  useCompaniesControllerGetOneCompanyQuery,
  useLazyCompaniesControllerGetOneCompanyQuery,
  useCompaniesControllerUpdateCompanyMutation,
  useCompaniesControllerGetProjectsUnderCompanyQuery,
  useLazyCompaniesControllerGetProjectsUnderCompanyQuery,
  useCompaniesControllerCreateProjectMutation,
  useCompaniesControllerGetRolesUnderCompanyQuery,
  useLazyCompaniesControllerGetRolesUnderCompanyQuery,
  useCompaniesControllerDeleteProjectMutation,
  useCompaniesControllerGetOneProjectQuery,
  useLazyCompaniesControllerGetOneProjectQuery,
  useCompaniesControllerUpdateProjectMutation,
  useCompaniesControllerDeleteProjectPhotoMutation,
  useCompaniesControllerDeletePhotoMutation,
  useDefectControllerGetDefectsQuery,
  useLazyDefectControllerGetDefectsQuery,
  useDefectControllerCreateDefectMutation,
  useDefectControllerGetOneDefectQuery,
  useLazyDefectControllerGetOneDefectQuery,
  useDefectControllerUpdateDefectMutation,
  useDefectControllerGetWorkOrderCountQuery,
  useLazyDefectControllerGetWorkOrderCountQuery,
  useDefectControllerGetCurrentDefectStatusQuery,
  useLazyDefectControllerGetCurrentDefectStatusQuery,
  useProjectControllerGetProjectsQuery,
  useLazyProjectControllerGetProjectsQuery,
  useStorageControllerGetMbQuotaQuery,
  useLazyStorageControllerGetMbQuotaQuery,
  usePmChecklistStatusControllerGetPmChecklistStatusQuery,
  useLazyPmChecklistStatusControllerGetPmChecklistStatusQuery,
  usePmChecklistStatusControllerGetAllPmChecklistStatusQuery,
  useLazyPmChecklistStatusControllerGetAllPmChecklistStatusQuery,
  usePmChecklistControllerGetPmChecklistQuery,
  useLazyPmChecklistControllerGetPmChecklistQuery,
  usePmChecklistControllerCreatePmChecklistMutation,
  usePmChecklistControllerGetAllPmChecklistsOpenQuery,
  useLazyPmChecklistControllerGetAllPmChecklistsOpenQuery,
  usePmChecklistControllerGetPmChecklistStatusCountQuery,
  useLazyPmChecklistControllerGetPmChecklistStatusCountQuery,
  usePmChecklistControllerGetPmChecklistTableQuery,
  useLazyPmChecklistControllerGetPmChecklistTableQuery,
  usePmChecklistControllerGetLocationsWithPmCountsQuery,
  useLazyPmChecklistControllerGetLocationsWithPmCountsQuery,
  usePmChecklistControllerGetAssetWithPmCountQuery,
  useLazyPmChecklistControllerGetAssetWithPmCountQuery,
  usePmChecklistControllerGetPmChecklistCountsQuery,
  useLazyPmChecklistControllerGetPmChecklistCountsQuery,
  usePmChecklistControllerGetOnePmChecklistQuery,
  useLazyPmChecklistControllerGetOnePmChecklistQuery,
  usePmChecklistControllerUpdatePmChecklistMutation,
  usePmChecklistControllerDownloadedForOfflineUseMutation,
  usePmChecklistControllerCheckIsDownloadedQuery,
  useLazyPmChecklistControllerCheckIsDownloadedQuery,
  useLocationControllerGetTopLocationsQuery,
  useLazyLocationControllerGetTopLocationsQuery,
  useLocationControllerGetFullLocationQuery,
  useLazyLocationControllerGetFullLocationQuery,
  useLocationControllerGetLocationQuery,
  useLazyLocationControllerGetLocationQuery,
  useLocationControllerCreateLocationMutation,
  useLocationControllerDownloadFullLocationsQuery,
  useLazyLocationControllerDownloadFullLocationsQuery,
  useLocationControllerGetOneLocationQuery,
  useLazyLocationControllerGetOneLocationQuery,
  useLocationControllerDeleteLocationMutation,
  useLocationControllerUpdateLocationMutation,
  useLocationControllerDeletePhotoMutation,
  useLocationControllerDeleteFileMutation,
  useDefectTypeControllerGetFullDefectTypeQuery,
  useLazyDefectTypeControllerGetFullDefectTypeQuery,
  useDefectTypeControllerGetDefectTypesQuery,
  useLazyDefectTypeControllerGetDefectTypesQuery,
  useDefectTypeControllerCreateDefectTypeMutation,
  useDefectTypeControllerDownloadDefectTypesQuery,
  useLazyDefectTypeControllerDownloadDefectTypesQuery,
  useDefectTypeControllerGetOneDefectTypeQuery,
  useLazyDefectTypeControllerGetOneDefectTypeQuery,
  useDefectTypeControllerUpdateDefectTypeMutation,
  useDefectTypeControllerDeleteDefectTypeMutation,
  usePmTemplateControllerGetPmTemplateQuery,
  useLazyPmTemplateControllerGetPmTemplateQuery,
  usePmTemplateControllerAddPmTemplateMutation,
  usePmTemplateControllerGetPmTemplateTableQuery,
  useLazyPmTemplateControllerGetPmTemplateTableQuery,
  usePmTemplateControllerGetOnePmTemplateQuery,
  useLazyPmTemplateControllerGetOnePmTemplateQuery,
  usePmTemplateControllerUpdatePmTemplateMutation,
  usePmTemplateControllerDeletePmTemplateMutation,
  usePmFormatTypeControllerGetPmFormatTypesQuery,
  useLazyPmFormatTypeControllerGetPmFormatTypesQuery,
  useWorkOrderV2ControllerGetWorkOrderStatusWithCountQuery,
  useLazyWorkOrderV2ControllerGetWorkOrderStatusWithCountQuery,
  useWorkOrderV2ControllerGetWorkOrdersQuery,
  useLazyWorkOrderV2ControllerGetWorkOrdersQuery,
  useWorkOrderV2ControllerGetPdfReportQuery,
  useLazyWorkOrderV2ControllerGetPdfReportQuery,
  useWorkOrderV2ControllerPostPdfReportMutation,
  useWorkOrderV2ControllerUpdateWorkOrderMutation,
  useWorkOrderV2ControllerUpdateInventoryMutation,
  useWorkOrderV2ControllerDeleteInventoryMutation,
  useWorkOrderV2ControllerUpdateWorkOrderPricingRefNoMutation,
  useStockTypesControllerGetStockTypesQuery,
  useLazyStockTypesControllerGetStockTypesQuery,
  useStockTypesControllerCreateStockTypeMutation,
  useStockTypesControllerGetOneStockTypeQuery,
  useLazyStockTypesControllerGetOneStockTypeQuery,
  useStockTypesControllerUpdateStockTypeMutation,
  useStockTypesControllerDeleteStockTypeMutation,
  useStocksControllerGetStocksCountQuery,
  useLazyStocksControllerGetStocksCountQuery,
  useStocksControllerGetStocksQuery,
  useLazyStocksControllerGetStocksQuery,
  useStocksControllerCreateStockMutation,
  useStocksControllerGetOneStockWithLedgerQuery,
  useLazyStocksControllerGetOneStockWithLedgerQuery,
  useStocksControllerUpdateStockMutation,
  useStocksControllerDeleteInventoryMutation,
  useStocksControllerDeletePhotoMutation,
  useVendorControllerGetVendorsCountQuery,
  useLazyVendorControllerGetVendorsCountQuery,
  useVendorControllerGetVendorsQuery,
  useLazyVendorControllerGetVendorsQuery,
  useVendorControllerCreateVendorMutation,
  useVendorControllerGetOneVendorQuery,
  useLazyVendorControllerGetOneVendorQuery,
  useVendorControllerUpdateOneVendorMutation,
  useVendorControllerDeleteVendorMutation,
  useVendorControllerDeleteAttachmentMutation,
  useWorkscopeControllerGetWorkScopesQuery,
  useLazyWorkscopeControllerGetWorkScopesQuery,
  useWorkscopeControllerCreateWorkScopeMutation,
  useWorkscopeControllerUpdateWorkScopeMutation,
  useWorkscopeControllerDeleteWorkScopeMutation,
  useWorkscopeControllerGetOneWorkscopeQuery,
  useLazyWorkscopeControllerGetOneWorkscopeQuery,
  useDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQuery,
  useLazyDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQuery,
  useRolesControllerGetAllRolesQuery,
  useLazyRolesControllerGetAllRolesQuery,
  useRolesControllerGetRolesQuery,
  useLazyRolesControllerGetRolesQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerGetOneRoleQuery,
  useLazyRolesControllerGetOneRoleQuery,
  useRolesControllerUpdateRoleStatusMutation,
  useWorkPermitsControllerGetWorkPermitCountQuery,
  useLazyWorkPermitsControllerGetWorkPermitCountQuery,
  useWorkPermitsControllerCreateWorkPermitMutation,
  useWorkPermitsControllerGetWorkPermitsQuery,
  useLazyWorkPermitsControllerGetWorkPermitsQuery,
  useWorkPermitsControllerGetWorkPermitQrCodeRequestQuery,
  useLazyWorkPermitsControllerGetWorkPermitQrCodeRequestQuery,
  useWorkPermitsControllerGetOneWorkPermitQuery,
  useLazyWorkPermitsControllerGetOneWorkPermitQuery,
  useWorkPermitsControllerDeleteWorkPermitMutation,
  useWorkPermitsControllerUpdateWorkPermitStatusMutation,
  useTestControllerTestingQuery,
  useLazyTestControllerTestingQuery,
  useMeterReadingControllerGetMeterReadingsQuery,
  useLazyMeterReadingControllerGetMeterReadingsQuery,
  useMeterReadingControllerCreateMeterReadingMutation,
  useMeterReadingControllerGetOneMeterReadingQuery,
  useLazyMeterReadingControllerGetOneMeterReadingQuery,
  useMeterReadingControllerUpdateMeterReadingMutation,
  useMeterReadingControllerDeleteMeterReadingMutation,
  useMeterReadingControllerPostMeterReadingIoTMutation,
  useMeterReadingControllerDeleteMeterReadingPhotoMutation,
  useNotificationControllerGetNotificationsQuery,
  useLazyNotificationControllerGetNotificationsQuery,
  useStockAdjustmentControllerCreateStockAdjustmentMutation,
  useStockAdjustmentControllerGetAdjustmentListQuery,
  useLazyStockAdjustmentControllerGetAdjustmentListQuery,
  useStockAdjustmentControllerGetOneAdjustmentQuery,
  useLazyStockAdjustmentControllerGetOneAdjustmentQuery,
  useStockAdjustmentControllerUpdateAdjustmentMutation,
  useStockAdjustmentControllerDeleteAdjustmentMutation,
  useAnalyticControllerGetAnalyticsQuery,
  useLazyAnalyticControllerGetAnalyticsQuery,
  useAnalyticControllerGetAnalyticsSettingsQuery,
  useLazyAnalyticControllerGetAnalyticsSettingsQuery,
  useAnalyticControllerCreateAnalyticMutation,
  useOldMeterReadingControllerGetMeterReadingsQuery,
  useLazyOldMeterReadingControllerGetMeterReadingsQuery,
  useIotMeterControllerGetIotMetersQuery,
  useLazyIotMeterControllerGetIotMetersQuery,
  useIotMeterControllerCreateIotMeterMutation,
  useIotMeterControllerGetOneIotMeterQuery,
  useLazyIotMeterControllerGetOneIotMeterQuery,
  useIotMeterControllerUpdateIotMeterMutation,
  useIotMeterControllerDeleteIotMeterMutation,
  useIotMeterReadingControllerCreateIotMeterReadingMutation,
  useIotMeterReadingControllerGetIotMeterReadingsQuery,
  useLazyIotMeterReadingControllerGetIotMeterReadingsQuery,
  useLegalComplianceControllerGetLegalCompliancesQuery,
  useLazyLegalComplianceControllerGetLegalCompliancesQuery,
  useLegalComplianceControllerCreateLegalComplianceMutation,
  useLegalComplianceControllerGetLegalComplianceCountQuery,
  useLazyLegalComplianceControllerGetLegalComplianceCountQuery,
  useLegalComplianceControllerGetOneLegalComplianceQuery,
  useLazyLegalComplianceControllerGetOneLegalComplianceQuery,
  useLegalComplianceControllerUpdateLegalComplianceMutation,
  useLegalComplianceControllerDeleteLegalComplianceMutation,
  useLegalComplianceControllerDeleteAttachmentMutation,
  useCronControllerRunReminderLegalComplianceMutation,
  useCronControllerGeneratePmChecklistMutation,
  useAccountTypeControllerCreateAccountTypeMutation,
  useAccountTypeControllerUpdateAccountTypeMutation,
  useAccountTypeControllerDeleteAccountTypeMutation,
  useTnbBillControllerGetTnbReadingsQuery,
  useLazyTnbBillControllerGetTnbReadingsQuery,
  useTnbBillControllerCreateTnbReadingMutation,
  useTnbBillControllerUpdateTnbMutation,
  useTnbBillControllerDeleteTnbReadingMutation,
  useTnbBillControllerGetOneTnbReadingQuery,
  useLazyTnbBillControllerGetOneTnbReadingQuery,
  useTnbBillControllerDeleteAttachmentMutation,
  useTnbBillControllerGetTnbAnalyticQuery,
  useLazyTnbBillControllerGetTnbAnalyticQuery,
  useEscalationControllerGetEscalationsQuery,
  useLazyEscalationControllerGetEscalationsQuery,
  useEscalationControllerCreateEscalationMutation,
  useEscalationControllerGetOneEscalationQuery,
  useLazyEscalationControllerGetOneEscalationQuery,
  useEscalationControllerUpdateEscalationMutation,
  useEscalationControllerDeleteEscalationMutation,
  useDataImportsControllerImportLocationMutation,
  useDataImportsControllerImportAssetsMutation,
  useDataImportsControllerGetLocationsQuery,
  useLazyDataImportsControllerGetLocationsQuery,
} = injectedRtkApi;
