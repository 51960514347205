import {
  AlignLeft,
  Calendar,
  FileImage,
  Files,
  Hash,
  MapPin,
  Settings,
  Tag,
  Tags,
  Zap,
} from "lucide-react";
import useError from "../../../../../hooks/useError";
import useSuccess from "../../../../../hooks/useSuccess";

import AppDocument from "../../../../AppDocument";
import AppImage from "../../../../AppImage";
import AppItemInfo from "../../../../AppItemInfo";
import ConfirmDeleteBtnWithDialog from "../../../../dialogs/ConfirmDeleteWithTextDialog";
import { useAssetControllerDeleteAsset } from "../../../../../api/assets/assets";
import { GetOneAsset } from "../../../../../api/model";
import useDate from "../../../../../hooks/useDate";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import LockableDocView from "./components/LockableDocView";

interface AssetDetailTabProps {
  asset?: GetOneAsset;
}

export default function AssetDetailTab({ asset }: AssetDetailTabProps) {
  const qc = useQueryClient();
  const [, setSearchParams] = useSearchParams();
  const { mutateAsync: deleteAsset } = useAssetControllerDeleteAsset({
    mutation: {
      onSuccess: () => {
        toast.success("Equipment deleted successfully");
        qc.invalidateQueries({
          predicate: (qry) => (qry.queryKey[0] as string).includes("asset"),
        });
        setSearchParams(new URLSearchParams());
      },
    },
  });
  const { formatDateToLocal } = useDate();

  return (
    <div className="grid grid-cols-2 gap-6 mt-6">
      <AppItemInfo
        label="Name"
        icon={<Tag className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">{asset?.name ?? "-"}</p>
        }
      />
      <AppItemInfo
        label="Asset Type"
        icon={<Settings className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.assetType.name ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Code"
        icon={<Hash className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">{asset?.code ?? "-"}</p>
        }
      />
      <AppItemInfo
        label="Location"
        icon={<MapPin className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.location.name ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Model"
        icon={<Tags className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.model?.length === 0 ? "-" : asset?.model ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Serial No."
        icon={<Hash className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.serialNo?.length === 0 ? "-" : asset?.serialNo ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Inverter Model (if any)"
        icon={<Tags className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.inverterModel?.length === 0
              ? "-"
              : asset?.inverterModel ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Motor Capacity"
        icon={<Zap className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.motorCapacity?.length === 0
              ? "-"
              : asset?.motorCapacity ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Brand"
        icon={<Tags className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.brand?.length === 0 ? "-" : asset?.brand ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Installation Date"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {asset?.installationDate ? (
              formatDateToLocal({
                dateStr: asset?.installationDate,
                formatting: "d MMM yy",
              })
            ) : (
              <>-</>
            )}
          </p>
        }
      />
      <div className="col-span-2">
        <AppItemInfo
          label="Description"
          icon={<AlignLeft className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {asset?.description?.length === 0
                ? "-"
                : asset?.description ?? "-"}
            </p>
          }
        />
      </div>
      <div className="col-span-2">
        <AppItemInfo
          label="Photos"
          icon={<FileImage className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-4">
              {asset?.photos?.length ? (
                asset?.photos.map((p) => <AppImage key={p.id} att={p} />)
              ) : (
                <p className="font-sans text-neutral-900">-</p>
              )}
            </div>
          }
        />
      </div>
      <div className="col-span-2">
        <AppItemInfo
          label="Documents"
          icon={<Files className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-4">
              {asset?.additionalDocuments?.length ? (
                asset?.additionalDocuments.map((p) => (
                  <LockableDocView key={p.id} att={p} />
                ))
              ) : (
                <p className="font-sans text-neutral-900">-</p>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}
