import { FileImage, FileText, Files, Hash, MapPin, Pencil } from "lucide-react";
import AppButton from "../../../components/AppButton";
import AppDocument from "../../../components/AppDocument";
import AppImage from "../../../components/AppImage";
import AppItemInfo from "../../../components/AppItemInfo";
import {
  LOCATION_FORM_DRAWER,
  DRAWER_UPDATE_STATE,
} from "../../../components/drawers/AppDrawer";
import DrawerFormSkeleton from "../../../components/skeletons/DrawerFormSkeleton";
import { useLocationControllerGetOneLocationQuery } from "../../../redux/slices/OpenApi/cerevApi";
import { useSearchParams } from "react-router-dom";

interface LocationDetailSectionProps {
  locationId?: number;
}

export default function LocationDetailSection({
  locationId,
}: LocationDetailSectionProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: location, isLoading } =
    useLocationControllerGetOneLocationQuery(
      {
        locationId: locationId?.toString() ?? "",
      },
      {
        skip: !locationId,
      }
    );

  if (isLoading) return <DrawerFormSkeleton />;

  if (!locationId)
    return (
      <p className="font-sans text-neutral-800">Please select a location</p>
    );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-start gap-4 justify-between">
        <div className="flex flex-col gap-1">
          <p className="font-sans text-2xl font-bold">
            {location ? location.name : "-"}
          </p>
          <p className="font-sans text-neutral-600">Location</p>
        </div>
        <AppButton
          variant="outline"
          icon={<Pencil />}
          label="Edit"
          onClick={() => {
            if (!location) return;
            setSearchParams((p) => {
              p.set("drawerType", LOCATION_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("locationId", location.id.toString());
              return p;
            });
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <AppItemInfo
          label="Name"
          icon={<MapPin className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {location?.name ?? "-"}
            </p>
          }
        />
        <AppItemInfo
          label="Description"
          icon={<FileText className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {location?.description ?? "-"}
            </p>
          }
        />
        <AppItemInfo
          label="Location Code"
          icon={<Hash className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {location?.code ?? "-"}
            </p>
          }
        />
        <AppItemInfo
          className="col-span-2"
          label="Photos"
          icon={<FileImage className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-4">
              {location?.photos?.length ? (
                location?.photos.map((p) => <AppImage key={p.id} att={p} />)
              ) : (
                <p className="font-sans text-neutral-900">-</p>
              )}
            </div>
          }
        />
        <AppItemInfo
          className="col-span-2"
          label="Documents"
          icon={<Files className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-4">
              {location?.additionalDocuments?.length ? (
                location?.additionalDocuments.map((p) => (
                  <AppDocument key={p.id} att={p} />
                ))
              ) : (
                <p className="font-sans text-neutral-900">-</p>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}
