import { format, isValid, parse, parseISO } from "date-fns";
import Decimal from "decimal.js";
import { CSVLink } from "react-csv";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { useAnalyticMeterControllerGetMeterReading } from "../../../api/default/default";
import AppButton from "../../../components/AppButton";
import { Meter } from "../../../redux/slices/OpenApi/cerevApi";
interface MeterReadingGraphProps {
  meter: Meter;
  monthStr: string;
}

export default function MeterReadingGraph({
  meter,
  monthStr,
}: MeterReadingGraphProps) {
  const { data: meterAnalyticReadings } =
    useAnalyticMeterControllerGetMeterReading(
      {
        meterId: meter.id.toString(),
        yearNum: parse(monthStr, "yyyy-MM", new Date())
          .getFullYear()
          .toString(),
        monthNum: (
          parse(monthStr, "yyyy-MM", new Date()).getMonth() + 1
        ).toString(),
      },
      {
        query: {
          enabled:
            isValid(parse(monthStr, "yyyy-MM", new Date())) &&
            parse(monthStr, "yyyy-MM", new Date()).getFullYear().toString()
              .length === 4,
          select: (res) => {
            return {
              ...res.data,
              data: res.data?.map((d) => {
                return {
                  date: format(parseISO(d.date), "d MMM"),
                  value: d.meterReadings
                    .reduce(
                      (a, b) => a.add(new Decimal(b.value ?? 0)),
                      new Decimal(0)
                    )
                    .toNumber(),
                };
              }),
            };
          },
        },
      }
    );

  // turn a list of meter readings into a nested array
  // const csvData = meterAnalyticReadings?.map((d) => {
  //   return [d.date, d.meterReadings[0].value];
  // });

  return (
    <div className="overflow-hidden flex flex-col">
      <div className="flex justify-between mb-2 items-center">
        <p className="font-medium text-xl text-neutral-700">
          {`${meter.name} (${meter.unit})`}
        </p>
        {meterAnalyticReadings && (
          <CSVLink
            data={meterAnalyticReadings?.data ?? []}
            filename={meter.name + ".csv"}
            className="flex items-center gap-2 no-underline"
          >
            <AppButton
              label="Download CSV"
              variant="outline"
              className="flex items-center gap-2"
            />
          </CSVLink>
        )}
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={meterAnalyticReadings?.data ?? []}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <Tooltip />
          <Bar dataKey="value" fill="#1E99B4" radius={[10, 10, 10, 10]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
