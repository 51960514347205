import { ColumnDef } from "@tanstack/react-table";
import {
  DefectStatus,
  Escalation,
  EscalationWithStatus,
  EscalationWorkRequest,
  PmChecklistStatus,
  WorkRequestStatus,
} from "../../../api/model";
import EscalationNameCell from "./components/EscalationNameCell";
import { escalationWorkRequestStatus } from "./components/EscalationSelectStatusField";

export const escalationColumns: ColumnDef<EscalationWithStatus>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <EscalationNameCell escalation={row} />,
  },
  {
    accessorKey: "numberOfDays",
    header: "Days to Alert",
  },
  {
    id: "defectStatus",
    accessorKey: "defectStatus",
    header: "Affected Status",
    cell: ({ row }) => {
      return (row.getValue("defectStatus") as DefectStatus[])
        .map((status) => status.name)
        .join(", ");
    },
  },
  {
    id: "pmStatus",
    accessorKey: "pmStatus",
    header: "Affected Status",
    cell: ({ row }) => {
      return (row.getValue("pmStatus") as PmChecklistStatus[])
        .map((status) => status.name)
        .join(", ");
    },
  },
  {
    id: "workRequestStatus",
    accessorKey: "workRequestStatus",
    header: "Affected Status",
    cell: ({ row }) => {
      return (row.getValue("workRequestStatus") as EscalationWorkRequest[])
        .map(
          (e) =>
            escalationWorkRequestStatus.find(
              (ews) => ews.value === e.workRequestStatus
            )?.name
        )
        .join(", ");
    },
  },
];
