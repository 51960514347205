import { ColumnDef } from "@tanstack/react-table";
import { Defect } from "../../../../../../api/model";
import AppButton from "../../../../../AppButton";
import { Link, useLocation } from "react-router-dom";

export const nestedWorkOrderColumns: ColumnDef<Defect>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "refNo",
    header: "Ref No",
  },
  {
    accessorKey: "subject",
    header: "Subject",
  },
  {
    header: "Actions",
    cell: ({ row }) => {
      const workOrderId = row.getValue("id");
      const url = `${window.location.origin}/home/work-orders?drawerType=WORK_ORDER_DETAIL_DRAWER&drawerState=VIEW&workOrderId=${workOrderId}&drawerWidth=1000&workOrderTabIdx=0`;

      return (
        <Link to={url} target="_blank">
          <AppButton label="View" variant="outline" />
        </Link>
      );
    },
  },
];
