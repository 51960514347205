import { ColumnDef } from "@tanstack/react-table";
import { AssetType } from "../../../../redux/slices/OpenApi/cerevApi";
import { Checkbox } from "../../../ui/checkbox";

export const assetTypeColumns: ColumnDef<AssetType>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Name",
  },
];
