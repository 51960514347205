import {useEffect, useState} from "react";
import isEqual from "lodash/isEqual";

function useDebounce<T>({value, delay}: {value: T; delay?: number}) : T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isEqual(value, debouncedValue)) return;
      return setDebouncedValue(value);
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
