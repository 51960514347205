import { useSearchParams } from "react-router-dom";
import { useUserControllerGetOneUser } from "../../../api/users/users";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import UserGeneralTab from "./tabs/UserGeneralTab";
import AccessControlTab from "./tabs/AccessControlTab";
import AppButton from "../../AppButton";
import { DRAWER_UPDATE_STATE, USER_FORM_DRAWER } from "../AppDrawer";
import { Pen, ShieldClose } from "lucide-react";
import AccActivationBtn from "./components/AccActivationBtn";
import RolesTab from "./tabs/RolesTab";
import { useEffect } from "react";
import {
  useSelectedSettingProjectId,
  useSettingProjectActions,
} from "../../../stores/settingStore/settingStore";
import ModuleAccessTab from "./tabs/ModuleAccessTab";
import { useModuleAccessEnabled } from "../../../hooks/useFeatures";

export default function UserDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const firebaseUserId = searchParams.get("firebaseId") as string;
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "userDetailTabIdx",
    defaultValue: "0",
  });
  const { setProjectList, setSelectedProject, cleanUp } =
    useSettingProjectActions();

  const moduleAccessEnabled = useModuleAccessEnabled();

  const selectedProjectId = useSelectedSettingProjectId();

  const { data: user, isLoading } = useUserControllerGetOneUser(
    firebaseUserId ?? "",
    {
      query: {
        enabled: !!firebaseUserId,
        select: (res) => res.data,
      },
    }
  );

  useEffect(() => {
    if (user && user.projects[0]?.id) {
      setProjectList(user.projects);
      if (!selectedProjectId)
        setSelectedProject(user.projects[0].id?.toString() ?? "");
    }

    return cleanUp;
  }, [user]);

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-4 items-center">
        <p className="text-2xl font-bold">{user?.name ?? "User"} Detail</p>
        <AppButton
          variant="outline"
          label="Edit"
          icon={<Pen />}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", USER_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              return p;
            });
          }}
        />
        <AccActivationBtn />
      </div>
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <TabsList className="mb-2">
          <TabsTrigger value={"0"}>
            <p>General</p>
          </TabsTrigger>
          <TabsTrigger value={"1"}>
            <p>Access Controls</p>
          </TabsTrigger>
          {moduleAccessEnabled && (
            <TabsTrigger value={"2"}>
              <p>Module Access</p>
            </TabsTrigger>
          )}
          <TabsTrigger value={"3"}>
            <p>Roles</p>
          </TabsTrigger>
        </TabsList>
        <TabsContent value={"0"}>
          <UserGeneralTab />
        </TabsContent>
        <TabsContent value={"1"}>
          <AccessControlTab />
        </TabsContent>
        {moduleAccessEnabled && (
          <TabsContent value={"2"}>
            <ModuleAccessTab />
          </TabsContent>
        )}
        <TabsContent value={"3"}>
          <RolesTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}
