import { create } from "zustand";
import { WorkScope } from "../../api/model";

interface VendorFilterState {
  workscopes: WorkScope[];
  setWorkscopes: (workscopes: WorkScope[]) => void;
  cleanUp: () => void;
  getFilterCount: () => number;
}

export const useVendorFilterStore = create<VendorFilterState>((set, get) => ({
  workscopes: [],
  setWorkscopes: (workscopes) => set(() => ({ workscopes })),
  cleanUp: () => set(() => ({ workscopes: [] })),
  getFilterCount: () => {
    let filterCount = 0;
    if (get().workscopes.length > 0) filterCount++;
    return filterCount;
  },
}));
