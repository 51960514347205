/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CustomCsvControllerGetCustomCsvSettingsParams,
  CustomCsvSetting,
  CustomCsvSettingResponseDto,
  EquipmentCsvResponse,
  SpiResponse,
} from ".././model";

export const customCsvControllerGetCustomCsvSettings = (
  projectId: string,
  params: CustomCsvControllerGetCustomCsvSettingsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CustomCsvSettingResponseDto>> => {
  return axios.get(`/custom-csv/${projectId}/settings`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getCustomCsvControllerGetCustomCsvSettingsQueryKey = (
  projectId: string,
  params: CustomCsvControllerGetCustomCsvSettingsParams
) => {
  return [
    `/custom-csv/${projectId}/settings`,
    ...(params ? [params] : []),
  ] as const;
};

export const getCustomCsvControllerGetCustomCsvSettingsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
    CustomCsvControllerGetCustomCsvSettingsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params: CustomCsvControllerGetCustomCsvSettingsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
        TError,
        TData,
        Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
        QueryKey,
        CustomCsvControllerGetCustomCsvSettingsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCustomCsvControllerGetCustomCsvSettingsQueryKey(projectId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
    QueryKey,
    CustomCsvControllerGetCustomCsvSettingsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    customCsvControllerGetCustomCsvSettings(
      projectId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
    TError,
    TData,
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
    QueryKey,
    CustomCsvControllerGetCustomCsvSettingsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type CustomCsvControllerGetCustomCsvSettingsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>
  >;
export type CustomCsvControllerGetCustomCsvSettingsInfiniteQueryError =
  AxiosError<unknown>;

export const useCustomCsvControllerGetCustomCsvSettingsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
    CustomCsvControllerGetCustomCsvSettingsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params: CustomCsvControllerGetCustomCsvSettingsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
        TError,
        TData,
        Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
        QueryKey,
        CustomCsvControllerGetCustomCsvSettingsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getCustomCsvControllerGetCustomCsvSettingsInfiniteQueryOptions(
      projectId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomCsvControllerGetCustomCsvSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params: CustomCsvControllerGetCustomCsvSettingsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCustomCsvControllerGetCustomCsvSettingsQueryKey(projectId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>
  > = ({ signal }) =>
    customCsvControllerGetCustomCsvSettings(projectId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CustomCsvControllerGetCustomCsvSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>
>;
export type CustomCsvControllerGetCustomCsvSettingsQueryError =
  AxiosError<unknown>;

export const useCustomCsvControllerGetCustomCsvSettings = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params: CustomCsvControllerGetCustomCsvSettingsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetCustomCsvSettings>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCustomCsvControllerGetCustomCsvSettingsQueryOptions(
    projectId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const customCsvControllerGetOneCustomCsvSetting = (
  projectId: string,
  settingId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CustomCsvSetting>> => {
  return axios.get(`/custom-csv/${projectId}/settings/${settingId}`, options);
};

export const getCustomCsvControllerGetOneCustomCsvSettingQueryKey = (
  projectId: string,
  settingId: string
) => {
  return [`/custom-csv/${projectId}/settings/${settingId}`] as const;
};

export const getCustomCsvControllerGetOneCustomCsvSettingInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>
    >,
    TError = AxiosError<unknown>
  >(
    projectId: string,
    settingId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCustomCsvControllerGetOneCustomCsvSettingQueryKey(
        projectId,
        settingId
      );

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>
    > = ({ signal }) =>
      customCsvControllerGetOneCustomCsvSetting(projectId, settingId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!(projectId && settingId),
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CustomCsvControllerGetOneCustomCsvSettingInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>
  >;
export type CustomCsvControllerGetOneCustomCsvSettingInfiniteQueryError =
  AxiosError<unknown>;

export const useCustomCsvControllerGetOneCustomCsvSettingInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  settingId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getCustomCsvControllerGetOneCustomCsvSettingInfiniteQueryOptions(
      projectId,
      settingId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomCsvControllerGetOneCustomCsvSettingQueryOptions = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  settingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCustomCsvControllerGetOneCustomCsvSettingQueryKey(projectId, settingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>
  > = ({ signal }) =>
    customCsvControllerGetOneCustomCsvSetting(projectId, settingId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(projectId && settingId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CustomCsvControllerGetOneCustomCsvSettingQueryResult = NonNullable<
  Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>
>;
export type CustomCsvControllerGetOneCustomCsvSettingQueryError =
  AxiosError<unknown>;

export const useCustomCsvControllerGetOneCustomCsvSetting = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  settingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetOneCustomCsvSetting>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCustomCsvControllerGetOneCustomCsvSettingQueryOptions(
    projectId,
    settingId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const customCsvControllerGetSPIData = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SpiResponse[]>> => {
  return axios.get(`/custom-csv/${projectId}/spi`, options);
};

export const getCustomCsvControllerGetSPIDataQueryKey = (projectId: string) => {
  return [`/custom-csv/${projectId}/spi`] as const;
};

export const getCustomCsvControllerGetSPIDataInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof customCsvControllerGetSPIData>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCustomCsvControllerGetSPIDataQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof customCsvControllerGetSPIData>>
  > = ({ signal }) =>
    customCsvControllerGetSPIData(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CustomCsvControllerGetSPIDataInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof customCsvControllerGetSPIData>>
>;
export type CustomCsvControllerGetSPIDataInfiniteQueryError =
  AxiosError<unknown>;

export const useCustomCsvControllerGetSPIDataInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof customCsvControllerGetSPIData>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCustomCsvControllerGetSPIDataInfiniteQueryOptions(
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomCsvControllerGetSPIDataQueryOptions = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCustomCsvControllerGetSPIDataQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof customCsvControllerGetSPIData>>
  > = ({ signal }) =>
    customCsvControllerGetSPIData(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CustomCsvControllerGetSPIDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof customCsvControllerGetSPIData>>
>;
export type CustomCsvControllerGetSPIDataQueryError = AxiosError<unknown>;

export const useCustomCsvControllerGetSPIData = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetSPIData>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCustomCsvControllerGetSPIDataQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const customCsvControllerGetEquipmentCsv = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<EquipmentCsvResponse[]>> => {
  return axios.get(`/custom-csv/${projectId}/equipment-csv`, options);
};

export const getCustomCsvControllerGetEquipmentCsvQueryKey = (
  projectId: string
) => {
  return [`/custom-csv/${projectId}/equipment-csv`] as const;
};

export const getCustomCsvControllerGetEquipmentCsvInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCustomCsvControllerGetEquipmentCsvQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>
  > = ({ signal }) =>
    customCsvControllerGetEquipmentCsv(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CustomCsvControllerGetEquipmentCsvInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>
>;
export type CustomCsvControllerGetEquipmentCsvInfiniteQueryError =
  AxiosError<unknown>;

export const useCustomCsvControllerGetEquipmentCsvInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getCustomCsvControllerGetEquipmentCsvInfiniteQueryOptions(
      projectId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomCsvControllerGetEquipmentCsvQueryOptions = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCustomCsvControllerGetEquipmentCsvQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>
  > = ({ signal }) =>
    customCsvControllerGetEquipmentCsv(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CustomCsvControllerGetEquipmentCsvQueryResult = NonNullable<
  Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>
>;
export type CustomCsvControllerGetEquipmentCsvQueryError = AxiosError<unknown>;

export const useCustomCsvControllerGetEquipmentCsv = <
  TData = Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof customCsvControllerGetEquipmentCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCustomCsvControllerGetEquipmentCsvQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
