import { MapPin, Upload } from "lucide-react";
import { Card, CardContent } from "../../../../../components/ui/card";
import AppButton from "../../../../../components/AppButton";
import React, { useEffect } from "react";
import { useToast } from "../../../../../components/ui/use-toast";
import { useAppDispatch } from "../../../../../redux/store";
import {
  SnackBarType,
  setOpenSnackBar,
} from "../../../../../redux/slices/RootSlice";

interface ImportItemProps {
  title: string;
  description: string;
  onClick: () => void;
  icon: React.ReactNode;
}

export default function ImportItem({
  title,
  description,
  onClick,
  icon,
}: ImportItemProps) {
  return (
    <div className="py-2 ">
      <Card className="h-64">
        <CardContent className="py-10 flex flex-col gap-3">
          {icon}
          <p className="text-xl font-bold ">{title}</p>
          <p className=" text-sm">{description}</p>
          <div className="flex">
            <AppButton label="Import" icon={<Upload />} onClick={onClick} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
