import { ColumnDef, Row } from "@tanstack/react-table";
import { PartialStockLedger } from "../../../../../api/model";
import BalanceStatusBadge from "../BalanceInfo/components/BalanceStatusBadge";
import { Avatar, AvatarFallback } from "../../../../ui/avatar";
import { useSearchParams } from "react-router-dom";
import { INVENTORY_LEDGER_ITEM_DETAIL_DRAWER } from "../../../AppDrawer";

const InventoryWarningStatus = ({ row }: { row: Row<PartialStockLedger> }) => {
  return (
    <BalanceStatusBadge
      balance={row.getValue("balance")}
      warningBalance={row.getValue("warningBalance")}
    />
  );
};

export const inventoryLedgerColumns: ColumnDef<PartialStockLedger>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }) => {
      const [, setSearchParams] = useSearchParams();
      return (
        <div
          className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
          onClick={() => {
            setSearchParams((p) => {
              p.set("ledgerId", row.getValue("id"));
              p.set("drawerType", INVENTORY_LEDGER_ITEM_DETAIL_DRAWER);
              return p;
            });
          }}
        >
          {row.getValue("date")}
        </div>
      );
    },
  },
  {
    id: "locationName",
    accessorKey: "location.name",
    header: "Location"
  },
  {
    id: "description",
    accessorKey: "description",
    header: "Description",
    cell: ({ row }) => <p className="text-sm">{row.getValue("description")}</p>,
  },
  {
    id: "createdByName",
    accessorKey: "createdBy.name",
    header: "By",
    cell: ({ row }) => (
      <Avatar className="bg-primary-900 text-base">
        <AvatarFallback className="bg-transparent text-white">
          {(row.getValue("createdByName") as string)
            ?.substring(0, 2)
            .toUpperCase()}
        </AvatarFallback>
      </Avatar>
    ),
  },
  {
    accessorKey: "adjustmentQty",
    header: "Adjust",
    cell: ({ row }) => (
      <p
        className="font-sans font-bold"
        style={{
          color:
            (row.getValue("adjustmentQty") as number) > 0 ? "green" : "tomato",
        }}
      >
        {row.getValue("adjustmentQty")}
      </p>
    ),
  },
  {
    id: "balance",
    accessorKey: "balance",
    header: "Total Balance",
    cell: ({ row }) => (
      <p className="font-sans font-bold">{row.getValue("balance")}</p>
    ),
  },

  {
    header: "Status",
    cell: ({ row }) => <InventoryWarningStatus row={row} />,
  },
  {
    id: "warningBalance",
    accessorKey: "warningBalance",
  },
];
