import { ThumbsDown, ThumbsUp } from "lucide-react";
import { useCallback } from "react";
import { cn } from "../../../../../../lib/utils";

interface BalanceStatusBadgeProps {
  balance: number | null | undefined;
  warningBalance: number | undefined;
  className?: string;
}

export default function BalanceStatusBadge({
  balance,
  warningBalance,
  className,
}: BalanceStatusBadgeProps) {
  const warningCalc =
    balance !== undefined && balance !== undefined
      ? (balance ?? 0) - (warningBalance ?? 0)
      : balance ?? 0;

  const getContent = useCallback(() => {
    if (warningCalc < 0)
      return (
        <>
          <ThumbsDown className="w-8 h-8" />
          <p>Insufficient</p>
        </>
      );
    if (warningCalc >= 0)
      return (
        <>
          <ThumbsUp className="w-8 h-8" />
          <p>Sufficient</p>
        </>
      );
  }, [warningCalc]);

  return (
    <div
      className={cn(
        "font-medium  p-4 rounded-md flex flex-col justify-center items-center gap-2",
        {
          "bg-green-50 text-green-600": warningCalc >= 0,
          "bg-red-50 text-red-600": warningCalc < 0,
        },
        className
      )}
    >
      {getContent()}
    </div>
  );
}
