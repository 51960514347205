import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  isValid,
  parseISO,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export default function useDate() {
  const formatDateToLocal = ({
    dateStr,
    formatting = "d MMM yy, h:mm a",
  }: {
    dateStr: string;
    formatting?: string;
  }) => {
    const timeZone = "Asia/Singapore";
    if (!dateStr) return "-";
    return format(utcToZonedTime(parseISO(dateStr), timeZone), formatting);
  };

  const diffInDays = ({
    startDateISO,
    endDateISO,
  }: {
    startDateISO?: string;
    endDateISO?: string;
  }) => {
    if (!startDateISO || !endDateISO) return "Error";
    if (!isValid(parseISO(startDateISO)) || !isValid(parseISO(endDateISO)))
      return "Error";
    return (
      differenceInDays(
        parseISO(endDateISO),
        parseISO(startDateISO)
      ).toString() + " days"
    );
  };

  const diffDateTillNow = ({ compareISODate }: { compareISODate: string }) => {
    if (!isValid(parseISO(compareISODate))) return "-";
    const compareDate = parseISO(compareISODate);
    const today = new Date();
    const diffInDays = differenceInDays(today, compareDate);
    const diffInHours = differenceInHours(today, compareDate);
    const diffInMins = differenceInMinutes(today, compareDate);

    if (diffInDays > 0) return `${diffInDays} days ago`;
    if (diffInHours > 0) return `${diffInHours} hours ago`;
    return `${diffInMins} mins ago`;
  };

  const diffDateInHrAndMin = ({
    startDateISO,
    endDateISO,
  }: {
    startDateISO: string;
    endDateISO: string;
  }) => {
    if (!startDateISO || !endDateISO) return "Error";
    if (!isValid(parseISO(startDateISO)) || !isValid(parseISO(endDateISO)))
      return "Error";
    const diffInHours = differenceInHours(
      parseISO(endDateISO),
      parseISO(startDateISO)
    );
    const diffInMins = differenceInMinutes(
      parseISO(endDateISO),
      parseISO(startDateISO)
    );
    const remainingMins = diffInMins - diffInHours * 60;
    return `${diffInHours} hr ${remainingMins} min`;
  };

  return {
    formatDateToLocal,
    diffInDays,
    diffDateTillNow,
    diffDateInHrAndMin,
  };
}
