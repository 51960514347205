/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type ProjectSubscriptionType =
  (typeof ProjectSubscriptionType)[keyof typeof ProjectSubscriptionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectSubscriptionType = {
  STARTER: "STARTER",
  STANDARD: "STANDARD",
  ENTERPRISE: "ENTERPRISE",
} as const;
