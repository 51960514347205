import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useProjectControllerFindAllProjects } from "../../../../api/projects/projects";
import useCurrentUser from "../../../../hooks/useCurrentUser/useCurrentUser";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import { projectColumns } from "../CompanyProjectListScreen/CompanyDetailScreen/projectColumns";
import useCommonTable from "../../../../hooks/useCommonTable";
import SearchProvider from "../../../../providers/SearchProvider";
import ProjectTableContent from "./components/ProjectTableContent";
import AppButton from "../../../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import { PROJECT_FORM_DRAWER } from "../../../../components/drawers/AppDrawer";

export default function ProjectScreen() {
  const [, setSearchParams] = useSearchParams();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-4">
        <AppTitle title="Projects" />
        <AppButton
          label="+ Add"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", PROJECT_FORM_DRAWER);
              p.set("drawerState", "CREATE");
              return p;
            });
          }}
        />
      </div>
      <SearchProvider>
        <ProjectTableContent />
      </SearchProvider>
    </div>
  );
}
