import React from "react";
import { useAppSelector } from '@/redux/store';
import { useAuthControllerGetCurrentUser } from '@/api/auth/auth';
import useAccountType from '@/hooks/useAccountType';


interface RoleGuardProps {
  roleName: "user" | "manager";
  children: React.ReactNode;
}
export default function RoleGuard({ children, roleName }: RoleGuardProps) {
  const defaultProj = useAppSelector(state => state.root.activeProject);
  const { data: hasAccess } = useAuthControllerGetCurrentUser({
    query: {
      enabled: !!defaultProj,
      select: (res) => {
        const user = res.data;

        return (
          user?.roles
            ?.filter((rl) => rl.projectId === defaultProj?.id)
            ?.map((r) => r.name)
            .indexOf(roleName) >= 0
        );
      }
    }
  });

  const { hasAccess: superadminAccess, isLoading } = useAccountType({
    accType: "SUPERADMIN"
  });

  if (hasAccess || superadminAccess || isLoading) return <>{children}</>;

  return <></>;
}
