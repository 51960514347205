import { useSearchParams } from "react-router-dom";
import {
  useAssetControllerGetAssetBasedOnBimUuid,
  useAssetControllerGetOneAsset,
} from "../../../api/assets/assets";
import { useAppSelector } from "../../../redux/store";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { useEffect } from "react";
import { Inbox } from "lucide-react";
import AssetDetailDrawer from "../asset/AssetDetailDrawer";

export default function BimDrawer() {
  const [searchParam, setSearchParams] = useSearchParams();
  const activeUuid = searchParam.get("equipmentUuid");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const assetId = searchParam.get("assetId");

  const { data: assetData, isLoading } =
    useAssetControllerGetAssetBasedOnBimUuid(
      activeProj?.id?.toString() ?? "",
      activeUuid ?? "",
      {
        query: {
          enabled: !!activeProj && !!activeUuid,
          select: (res) => res.data,
        },
      }
    );

  const { isLoading: assetIsLoading } = useAssetControllerGetOneAsset(
    assetId ?? "",
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!assetId && !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  useEffect(() => {
    if (assetData?.name) {
      setSearchParams((p) => {
        p.set("assetId", assetData.id.toString());
        return p;
      });
    }
  }, [assetData]);

  return (
    <div className="flex flex-col gap-4 relative">
      {isLoading ? (
        <DrawerFormSkeleton />
      ) : assetData?.name  ? (
        <AssetDetailDrawer />
      ) : (
        <>
          <h1 className="text-2xl font-bold">Equipment Information</h1>
          <div className="flex flex-col gap-4 items-center justify-center pt-28">
            <Inbox className="text-slate-200 w-20 h-20" />
            <p className="text-slate-300">No Information Found</p>
            <p className="text-slate-300 italic">BIM ID: {activeUuid ?? "-"}</p>
          </div>
        </>
      )}
    </div>
  );
}
