import { CardContent } from "@mui/material";
import AppButton from "../../../../../../../components/AppButton";
import { Card } from "../../../../../../../components/ui/card";
import CardSummary from "../../components/CardSummary";
import UsersTabTable from "./components/UsersTabTable";
import SearchProvider from "../../../../../../../providers/SearchProvider";
import PlanSummaryCard from "../SummaryTab/components/PlanSummaryCard/PlanSummaryCard";
import UserQuotaSummaryCard from "../SummaryTab/components/UserQuotaSummaryCard/UserQuotaSummaryCard";

export default function UsersTab() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 items-center">
        <h3 className="text-xl font-bold">Users Under Project</h3>
        <AppButton label="+ Create" />
      </div>
      <div className="mr-[300px]">
        <SearchProvider>
          <UsersTabTable />
        </SearchProvider>
      </div>
      <div className="fixed top-46 right-4 w-[300px] space-y-4">
        <PlanSummaryCard />
        <UserQuotaSummaryCard />  
      </div>
    </div>
  );
}
