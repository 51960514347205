import { useSearchParams } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import { AppDateRangePicker } from "../../../components/AppDateRangePicker";
import SelectLocationFilterDialog from "../../../components/dialogs/SelectLocationFilterDialog/SelectLocationFilterDialog";
import { useSelectPmFilterStore } from "../../../stores/pm/pmFilterStore";
import AppUncontrolledWithDialog from "../../../components/dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { selectAssetTypeColumn } from "../../../components/drawers/workOrders/columns/selectAssetTypeColumns";
import { useAssetTypeControllerGetAssetType } from "../../../api/asset-type/asset-type";
import { useAppSelector } from "../../../redux/store";

export default function PmFilterDrawer() {

  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);

  const filterLocation = useSelectPmFilterStore(
    (state) => state.filterLocation
  );
  const setFilterLocation = useSelectPmFilterStore(
    (state) => state.setFilterLocation
  );

  const setDateRange = useSelectPmFilterStore(
    (state) => state.setDateRange
  );
  const dateRange = useSelectPmFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectPmFilterStore(
    (state) => state.assetTypes
  );
  const setAssetTypes = useSelectPmFilterStore(
    (state) => state.setAssetTypes
  );
  const clearAll = useSelectPmFilterStore((state) => state.cleanUp);

  const { data: assetTypes } = useAssetTypeControllerGetAssetType(
    {
      projectId: activeProj?.id?.toString() ?? "",
      companyId: activeComp?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj && !!activeComp,
      },
    }
  );

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-sans text-2xl font-bold">Filter By</h2>
      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Location</p>
        <SelectLocationFilterDialog
          currentFilter={filterLocation}
          onApplyFilter={(location) => {
            setFilterLocation(location);
          }}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Date Range</p>
        <AppDateRangePicker
          date={dateRange}
          setDate={setDateRange}
          onClear={() => {
            setDateRange(undefined);
          }}
        />
      </div>
      <AppUncontrolledWithDialog
        placeholder="Select Asset Type"
        label="Asset Type"
        items={assetTypes?.data ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Asset Type"
        columns={selectAssetTypeColumn}
        multiple
        value={assetTypesFilter}
        onChange={(value) => {
          if (Array.isArray(value)) setAssetTypes(value);
        }}
      />
      <div className="flex gap-2">
        <AppButton
          label="Confirm"
          onClick={() => {
            setSearchParams(() => {
              return new URLSearchParams();
            });
          }}
        />
        <AppButton
          label="Clear"
          variant="outline"
          onClick={() => {
            clearAll();
          }}
        />
      </div>
    </div>
  );
}
