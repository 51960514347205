import { Check, Loader2, Table } from "lucide-react";
import AppButton from "../../../../AppButton";
import { useState } from "react";
import { useCSVDownloader } from "react-papaparse";
import { useSearchParams } from "react-router-dom";
import { usePmTemplateControllerExportPmChecklistCsv } from "../../../../../api/preventive-maintenance-template/preventive-maintenance-template";

export default function ExportCsv() {
  const [visible, setVisible] = useState(false);

  return (
    <div className="flex-grow">
      {!visible && (
        <AppButton
          label="Export CSV"
          variant="outline"
          className="w-full"
          icon={<Table />}
          onClick={() => setVisible(true)}
        />
      )}
      {visible && <CSVProcessoer />}
    </div>
  );
}

function CSVProcessoer() {
  const { CSVDownloader, Type } = useCSVDownloader();

  const [searchParams, setSearchParams] = useSearchParams();
  const pmTemplateId = searchParams.get("pmTemplateId");
  const { data: pmChecklists, isLoading } =
    usePmTemplateControllerExportPmChecklistCsv(pmTemplateId as string);

  return (
    <CSVDownloader
      type={Type.Link}
      filename={"pm-checklist"}
      data={pmChecklists?.data}
    >
        <AppButton
          label="CSV is Ready"
          variant="outline"
          className="w-full"
          icon={<Check />}
          isLoading={isLoading}
        />
    </CSVDownloader>
  );
}
