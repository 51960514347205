/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type FrequencySettingFrequencySettingType =
  (typeof FrequencySettingFrequencySettingType)[keyof typeof FrequencySettingFrequencySettingType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FrequencySettingFrequencySettingType = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  BY_MONTH: "BY_MONTH",
  BY_YEAR: "BY_YEAR",
} as const;
