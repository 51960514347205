import React from "react";
import {
  Controller,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";
import { PtwFormatType, PtwOption } from "../../../api/model";
import AppTextField from "../../AppTextField";
import { PtwFormFieldType, PtwFormType } from "./PtwFormSchema";
import AppDocumentUpload from "../../AppDocumentUpload";
import { usePtwControllerDeleteAtt } from "../../../api/permit-to-work-adv/permit-to-work-adv";
import { useSearchParams } from "react-router-dom";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import AppUncontrolledWithDialog from "../../dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { Checkbox } from "../../ui/checkbox";
import { AppDatePicker } from "../../AppDatePicker";
import { addYears, format } from "date-fns";
import {
  AlignLeft,
  Calendar,
  FileImage,
  Hash,
  List,
  ToggleLeft,
} from "lucide-react";
import AppItemInfo from "../../AppItemInfo";
import AppMultiImage from "../../AppMultiImage";

interface PtwFormatRendererProps {
  arrayFields: UseFieldArrayReturn<PtwFormType>;
  isDisplayOnly?: boolean; // This is for drawers that show the details only, instead of form.
}

export default function PtwFormatRenderer({
  arrayFields,
  isDisplayOnly = false,
}: PtwFormatRendererProps) {
  const methods = useFormContext<PtwFormType>();
  const { fields } = arrayFields;
  const { mutate } = usePtwControllerDeleteAtt();
  const [searchParam] = useSearchParams();
  const ptwId = searchParam.get("ptwId");

  const renderField = (field: PtwFormFieldType, idx: number) => {
    const formValues = methods.getValues();
    switch (field.ptwFormatType) {
      case PtwFormatType.TEXT:
        const idxValue = formValues.ptwTexts.findIndex(
          (pt) => pt.ptwFormatId === field.ptwFormatId
        );

        if (isDisplayOnly)
          return (
            <AppItemInfo
              label={field?.name ?? "-"}
              icon={<AlignLeft />}
              content={<p>{formValues.ptwTexts[idxValue].value}</p>}
            />
          );

        return (
          <AppTextField
            name={`ptwTexts.${idxValue}.value`}
            label={field.name}
            key={field.ptwFormatId}
            testId={`ptw-field-${idx}`}
          />
        );

      case PtwFormatType.NUMBER:
        const idxNumber = formValues.ptwNumbers.findIndex(
          (pn) => pn.ptwFormatId === field.ptwFormatId
        );

        if (isDisplayOnly)
          return (
            <AppItemInfo
              label={field?.name ?? "-"}
              icon={<Hash />}
              content={<p>{formValues.ptwNumbers[idxNumber].value}</p>}
            />
          );

        return (
          <AppTextField
            name={`ptwNumbers.${idxNumber}.value`}
            label={field.name}
            key={field.ptwFormatId}
            type="number"
            testId={`ptw-field-${idx}`}
          />
        );

      case PtwFormatType.ATTACHMENT:
        const idxAtt = formValues.ptwAttachments.findIndex(
          (pn) => pn.ptwFormatId === field.ptwFormatId
        );

        if (isDisplayOnly)
          return (
            <AppItemInfo
              label={field?.name ?? "-"}
              icon={<FileImage />}
              content={
                <AppMultiImage
                  attachments={
                    formValues?.ptwAttachments.find(
                      (v) => v.ptwFormatId === field.ptwFormatId
                    )?.attachments
                  }
                />
              }
            />
          );

        return (
          <Controller
            control={methods.control}
            name={`ptwAttachments.${idxAtt}.newAttachments`}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <AppDocumentUpload
                  uploadedDocs={formValues.ptwAttachments[idxAtt].attachments}
                  onDeleteUploadedDoc={async (att) => {
                    if (!ptwId || !formValues.ptwAttachments[idxAtt] || !att.id)
                      return;
                    mutate({
                      ptwAttId:
                        formValues.ptwAttachments[idxAtt]?.id?.toString(),
                      attId: att?.id?.toString() ?? "",
                      ptwId: ptwId as string,
                    });

                    const newAttachments = formValues.ptwAttachments[
                      idxAtt
                    ].attachments.filter((a) => a.id !== att.id);

                    methods.setValue(
                      `ptwAttachments.${idxAtt}.attachments`,
                      newAttachments
                    );
                  }}
                  label="Videos or Documents"
                  onChange={onChange}
                  onDelete={(url) => {
                    if (!value) return;
                    const newFiles = value.filter((v) => v !== url);
                    onChange(newFiles);
                  }}
                  files={value ?? []}
                  error={!!error}
                  helperText={error?.message !== "" ? error?.message : ""}
                />
              );
            }}
          />
        );

      case PtwFormatType.IMAGE:
        const idxImg = formValues.ptwImages.findIndex(
          (pn) => pn.ptwFormatId === field.ptwFormatId
        );

        if (isDisplayOnly)
          return (
            <AppItemInfo
              label={field?.name ?? "-"}
              icon={<FileImage />}
              content={
                <AppMultiImage
                  attachments={
                    formValues?.ptwImages.find(
                      (v) => v.ptwFormatId === field.ptwFormatId
                    )?.attachments
                  }
                />
              }
            />
          );

        return (
          <Controller
            control={methods.control}
            name={`ptwImages.${idxImg}.newAttachments`}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <AppCameraFieldFile
                  uploadedPhotos={formValues.ptwImages[idxImg].attachments}
                  onDeleteUploadedPhoto={async (att) => {
                    if (!ptwId || !formValues.ptwImages[idxImg] || !att.id)
                      return;
                    mutate({
                      ptwAttId: formValues.ptwImages[idxImg]?.id?.toString(),
                      attId: att?.id?.toString() ?? "",
                      ptwId: ptwId as string,
                    });

                    const newImages = formValues.ptwImages[
                      idxImg
                    ].attachments.filter((a) => a.id !== att.id);

                    methods.setValue(
                      `ptwImages.${idxImg}.attachments`,
                      newImages
                    );
                  }}
                  label="Photos"
                  onChange={onChange}
                  onDelete={(url) => {
                    if (!value) return;
                    const newPhotos = value.filter((v) => v !== url);
                    onChange(newPhotos);
                  }}
                  photos={value ?? []}
                  error={!!error}
                  helperText={error?.message !== "" ? error?.message : ""}
                />
              );
            }}
          />
        );

      case PtwFormatType.MULTI_OPTION:
      case PtwFormatType.SINGLE_OPTION:
        const idxOption = formValues.ptwOptionValues.findIndex(
          (pn) => pn.ptwFormatId === field.ptwFormatId
        );

        if (isDisplayOnly) {
          return (
            <AppItemInfo
              label={field?.name ?? "-"}
              icon={<List />}
              content={
                <p>
                  {(
                    formValues?.ptwOptionValues.find(
                      (v) => v.ptwFormatId === field.ptwFormatId
                    )?.ptwOptions as PtwOption[]
                  )
                    ?.map((o) => o.value)
                    .join(", ")}
                </p>
              }
            />
          );
        }

        return (
          <Controller
            control={methods.control}
            name={`ptwOptionValues.${idxOption}.ptwOptions`}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <AppUncontrolledWithDialog
                  multiple={field.ptwFormatType === PtwFormatType.MULTI_OPTION}
                  label="Options"
                  items={field.ptwOptions}
                  placeholder="Select Option"
                  onResultRender={(item, idx) => (
                    <div className="font-medium">{item?.value ?? "-"}</div>
                  )}
                  onOptionsRender={(item, idx) => (
                    <div className="font-medium">{item?.value ?? "-"}</div>
                  )}
                  dialogTitle="Select Option"
                  columns={[
                    {
                      accessorKey: "id",
                    },
                    {
                      header: "",
                      id: "select",
                      cell: ({ row, table }) => (
                        <Checkbox
                          checked={row.getIsSelected()}
                          onCheckedChange={(value) => {
                            row.toggleSelected(!!value);
                          }}
                          aria-label="Select row"
                        />
                      ),
                      enableSorting: false,
                      enableHiding: false,
                    },
                    {
                      accessorKey: "value",
                      header: "Option",
                    },
                  ]}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        );

      case PtwFormatType.DATE:
        const idxDate = formValues.ptwDates.findIndex(
          (pn) => pn.ptwFormatId === field.ptwFormatId
        );

        if (isDisplayOnly) {
          return (
            <AppItemInfo
              label={field?.name ?? "-"}
              icon={<Calendar />}
              content={
                <p>{format(formValues.ptwDates[idxDate].date, "dd/MM/yyyy")}</p>
              }
            />
          );
        }

        return (
          <AppDatePicker
            name={`ptwDates.${idxDate}.date`}
            label={field.name ?? ""}
            key={field.ptwFormatId}
            disabled={(date) =>
              date > addYears(new Date(), 1) || date < new Date("1900-01-01")
            }
          />
        );

      case PtwFormatType.BOOLEAN:
        const idxBool = formValues.ptwBools.findIndex(
          (pn) => pn.ptwFormatId === field.ptwFormatId
        );

        if (isDisplayOnly)
          return (
            <AppItemInfo
              label={field?.name ?? "-"}
              icon={<ToggleLeft />}
              content={
                <p>{formValues.ptwBools[idxBool].value ? "Yes" : "No"}</p>
              }
            />
          );

        return (
          <div>
            <div className="flex gap-4 items-center">
              <ToggleLeft className="text-primary-900" />
              <Controller
                control={methods.control}
                name={`ptwBools.${idxBool}.value`}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Checkbox
                      checked={Boolean(value)}
                      onCheckedChange={(v) => {
                        onChange(!value);
                      }}
                    />
                  );
                }}
              />
              <p className="flex-1 text-slate-800">{field.name}</p>
            </div>
          </div>
        );

      case PtwFormatType.HEADING:
        return (
          <h3 key={idx} className="text-lg font-bold">
            {field.name}
          </h3>
        );

      case PtwFormatType.DESCRIPTION:
        return (
          <p key={idx} className="text-slate-600 font-sans leading-relaxed">
            {field.name}
          </p>
        );

      case PtwFormatType.DIVIDER:
        return <p>DIVIDER</p>;
    }
  };

  return fields.map((f, idx) => (
    <React.Fragment key={idx}>
      {renderField(f as PtwFormFieldType, idx)}
    </React.Fragment>
  ));
}
