import {ReactNode} from "react";

interface PublicPageWrapperProps {
  children: ReactNode;
  className?: string;
}

export default function PublicPageWrapper({
  children,
  className,
}: PublicPageWrapperProps) {
  return (
    <div className="min-h-screen w-screen bg-primary-900 flex justify-center">
      <div
        className={`bg-neutral-50 shadow-2xl shadow-neutral-900 flex flex-col rounded-xl p-6 w-[480px] ${className}`}
      >
        {children}
      </div>
    </div>
  );
}
