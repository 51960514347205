import { useState } from "react";
import AppButton from "../../AppButton";
import {
  ChevronRight,
  Clear,
  ExpandMore,
  Filter,
  Filter1,
  FilterAlt,
  FilterAltOutlined,
} from "@mui/icons-material";
import { TreeView } from "@mui/x-tree-view";
import LocationTreeItem from "../../../screens/AssetScreen/component/LocationTreeItem";
import {
  GetLocationResponseDto,
  useLocationControllerGetLocationQuery,
  Location,
} from "../../../redux/slices/OpenApi/cerevApi";
import { RootState, useAppSelector } from "../../../redux/store";
import LocationFilterTreeItem from "./components/LocationFilterTreeItem";
import { cn } from "../../../utils/util";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { DialogDescription, DialogTrigger } from "@radix-ui/react-dialog";
import { Button } from "../../ui/button";

interface SelectLocationFilterDialogProps {
  currentFilter: GetLocationResponseDto | Location | undefined | null;
  onApplyFilter: (loc: GetLocationResponseDto | Location | undefined) => void;
  showFirstLevelOnly?: boolean; // To determine whether to show the first level only
}

export default function SelectLocationFilterDialog({
  onApplyFilter,
  currentFilter,
  showFirstLevelOnly,
}: SelectLocationFilterDialogProps) {
  const [open, setOpen] = useState(false);
  const activeProj = useAppSelector(
    (state: RootState) => state.root.activeProject
  );
  const [selectedLocation, setSelectedLocation] = useState<
    GetLocationResponseDto | Location | undefined
  >();

  const { data: locations } = useLocationControllerGetLocationQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
      locationId: "",
    },
    {
      skip: !activeProj,
    }
  );

  const onOpenChange = (open: boolean) => {
    // if (!open) {
    //   methods.reset({
    //     search: "",
    //   });
    // }

    setOpen(open);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            size="lg"
            className={cn(
              "bg-slate-50 flex py-1 px-3 rounded-md border-solid border-slate-200 border-[1px] hover:cursor-pointer items-center hover:bg-slate-100 duration-300 transition-all justify-start",
              {
                "bg-primary-900 hover:bg-primary-900": currentFilter,
              }
            )}
            onClick={() => {
              setOpen(true);
            }}
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                {currentFilter ? (
                  <FilterAlt className="text-white mr-2" />
                ) : (
                  <FilterAltOutlined className="text-slate-400 mr-2" />
                )}
                <p
                  className={cn("font-sans text-slate-400", {
                    "text-white": currentFilter,
                  })}
                >
                  {currentFilter ? currentFilter.name : "By Location"}
                </p>
              </div>
              {currentFilter ? (
                <Clear
                  className={cn("ml-2 text-slate-400", {
                    "text-white": currentFilter,
                  })}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setSelectedLocation(undefined);
                    onApplyFilter(undefined);
                  }}
                />
              ) : (
                <ChevronRight className="ml-2 text-slate-400" />
              )}
            </div>
          </Button>
        </DialogTrigger>
        <DialogContent className="w-full max-w-4xl overflow-y-scroll max-h-screen">
          <DialogHeader>
            <DialogTitle>Select a location</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
          >
            {locations?.map((loc) => (
              <LocationFilterTreeItem
                loc={loc}
                key={loc.id}
                onSelect={(loc) => setSelectedLocation(loc)}
                showFirstLevelOnly={showFirstLevelOnly}
              />
            ))}
          </TreeView>
          <DialogFooter>
            <AppButton
              label="Apply Filter"
              onClick={() => {
                onApplyFilter(selectedLocation);
                setOpen(false);
              }}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
