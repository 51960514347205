import { useSearchParams } from "react-router-dom";
import { useBimControllerGetBimNodes } from "../../api/bim/bim";
import {
  BimPointerState,
  useBimActions,
  useBimPointerState,
  useLastRemoveAndSelectedBimStates,
  usePrevPointerState,
} from "../../stores/bimStore/bimStore";
import { useCallback, useEffect } from "react";
import * as THREE from "three";
import { ThreeEvent, useThree } from "@react-three/fiber";
import useBimEquipmentLink from "./useBimEquipmentLink";
import useBimSelection from "./useBimSelection";
import useOpacityListener from "./useOpacityListener";

export const materialLoader = new THREE.MaterialLoader();

// Must be used inside THREE CANVAS
export default function useBim() {
  // TODO: Multi bim workflow
  // 1. User choose which bim model to view if they have multiple
  // 2. Otherwise, if one only detected, automatically navigate to that using searchParams
  const [searchParam] = useSearchParams();
  const bimId = searchParam.get("bimId");
  const { scene: threeScene } = useThree();
  const { data: bimNodes } = useBimControllerGetBimNodes(bimId as string, {
    query: {
      enabled: !!bimId,
      select: (res) => res.data.data,
    },
  });
  const { setSelectedBimNodes, removeSelectedBimNodes, clearSelection } =
    useBimActions();
  const { lastRemovedNode, selectedBimNodes } =
    useLastRemoveAndSelectedBimStates();
  const bimPointerState = useBimPointerState();

  const bimEquipmentLinkActions = useBimEquipmentLink();
  const bimSelectionActions = useBimSelection();

  useOpacityListener();

  // side effect to allow selections of bimNodes
  useEffect(() => {
    selectedBimNodes.forEach((node) => {
      const idenMesh = threeScene.getObjectByName(node.name);
      const newMaterial = new THREE.MeshStandardMaterial();
      newMaterial.color.setColorName("red");
      (idenMesh as THREE.Mesh).material = newMaterial;
    });

    if (lastRemovedNode) {
      const idenRemoveMesh = threeScene.getObjectByName(lastRemovedNode.name);
      const newMaterial = new THREE.MeshStandardMaterial();
      const idenNode = bimNodes?.find(
        (node) => node.name === lastRemovedNode.name
      );
      (idenRemoveMesh as THREE.Mesh).material = materialLoader.parse(
        idenNode?.material
      );
    }
  }, [selectedBimNodes]);

  // side effect to update bimNodes which are already linked to assets
  useEffect(() => {
    if (!bimNodes) return;
    const linkedNodes = bimNodes.filter((node) => node.assetId);
    linkedNodes.forEach((node) => {
      const idenMesh = threeScene.getObjectByName(node.name);
      const newMaterial = new THREE.MeshStandardMaterial();
      newMaterial.color.setColorName("green");
      (idenMesh as THREE.Mesh).material = newMaterial;
    });
  }, [bimNodes]);

  const removedSelectedPoints = useCallback(() => {
    selectedBimNodes.forEach((nod) => {
      const mesh = threeScene.getObjectByName(nod.name);
      const idenNode = bimNodes?.find((node) => node.name === nod.name);

      // In case there is some linkage, then do not change the materialLoader color
      if (idenNode && !idenNode.assetId) {
        (mesh as THREE.Mesh).material = materialLoader.parse(
          idenNode?.material
        );
      }
    });
  }, [selectedBimNodes, bimNodes]);

  // When changing bim pointer state, need to clear reset all the other states
  useEffect(() => {
    // Get all the selected nodes and clear them to default color
    removedSelectedPoints();
    clearSelection();
  }, [bimPointerState]);

  // Opacity adjustment

  const onPointerOver = (e: ThreeEvent<PointerEvent>) => {
    switch (bimPointerState) {
      case BimPointerState.SELECTION:
        break;
      case BimPointerState.EQUIPMENT_LINKING:
        return bimEquipmentLinkActions.onPointerOver(e);
      case BimPointerState.OPACITY_ADJUSTMENT:
        break;
    }
  };

  const onPointerLeave = async (e: ThreeEvent<PointerEvent>) => {
    switch (bimPointerState) {
      case BimPointerState.SELECTION:
        break;
      case BimPointerState.EQUIPMENT_LINKING:
        return await bimEquipmentLinkActions.onPointerLeave(e);
      case BimPointerState.OPACITY_ADJUSTMENT:
        break;
    }
  };

  const onDoubleClick = (e: ThreeEvent<MouseEvent>) => {
    switch (bimPointerState) {
      case BimPointerState.SELECTION:
        return bimSelectionActions.onDoubleClick(e);
      case BimPointerState.EQUIPMENT_LINKING:
        return bimEquipmentLinkActions.onDoubleClick(e);
      case BimPointerState.OPACITY_ADJUSTMENT:
        break;
    }
  };

  const onPointerDown = (e: ThreeEvent<PointerEvent>) => {
    switch (bimPointerState) {
      case BimPointerState.SELECTION:
        break;
      case BimPointerState.EQUIPMENT_LINKING:
        return bimEquipmentLinkActions.onPointerDown(e);
      case BimPointerState.OPACITY_ADJUSTMENT:
        break;
    }
  };

  return {
    setSelectedBimNodes,
    removeSelectedBimNodes,
    onPointerOver,
    onPointerLeave,
    onDoubleClick,
    onPointerDown,
  };
}
