/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AssetControllerGetAssetPmParams,
  AssetControllerGetAssetsParams,
  AssetControllerGetAssetsV2Params,
  AssetControllerGetFullAssetsParams,
  AssetControllerGetOneAssetParams,
  AssetWithType,
  AssetWorkOrdersResponseDto,
  CreateAssetDto,
  CreateSubAssetDto,
  DataImportsControllerImportLocation201,
  GetAssetQrCodeResponseDto,
  GetAssetsResponseDto,
  GetAssetsV2ResponseDto,
  GetEquipmentQrCodeResponseDto,
  GetOneAsset,
  ImportAssetRequest,
  ImportLocationRequestDto,
  PmChecklistResponseDto,
  UpdateAssetDto,
} from ".././model";

export const assetControllerGetFullAssets = (
  params: AssetControllerGetFullAssetsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AssetWithType[]>> => {
  return axios.get(`/asset/getFullAssets`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAssetControllerGetFullAssetsQueryKey = (
  params: AssetControllerGetFullAssetsParams
) => {
  return [`/asset/getFullAssets`, ...(params ? [params] : [])] as const;
};

export const getAssetControllerGetFullAssetsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
    AssetControllerGetFullAssetsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetFullAssetsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
        QueryKey,
        AssetControllerGetFullAssetsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetFullAssetsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
    QueryKey,
    AssetControllerGetFullAssetsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    assetControllerGetFullAssets(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
    TError,
    TData,
    Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
    QueryKey,
    AssetControllerGetFullAssetsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AssetControllerGetFullAssetsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetFullAssets>>
>;
export type AssetControllerGetFullAssetsInfiniteQueryError =
  AxiosError<unknown>;

export const useAssetControllerGetFullAssetsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
    AssetControllerGetFullAssetsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetFullAssetsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
        QueryKey,
        AssetControllerGetFullAssetsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetFullAssetsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetFullAssetsQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetFullAssetsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetFullAssetsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetFullAssets>>
  > = ({ signal }) =>
    assetControllerGetFullAssets(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetFullAssetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetFullAssets>>
>;
export type AssetControllerGetFullAssetsQueryError = AxiosError<unknown>;

export const useAssetControllerGetFullAssets = <
  TData = Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetFullAssetsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetFullAssets>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetFullAssetsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerGetAssets = (
  params: AssetControllerGetAssetsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAssetsResponseDto[]>> => {
  return axios.get(`/asset`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAssetControllerGetAssetsQueryKey = (
  params: AssetControllerGetAssetsParams
) => {
  return [`/asset`, ...(params ? [params] : [])] as const;
};

export const getAssetControllerGetAssetsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssets>>,
    AssetControllerGetAssetsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssets>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetAssets>>,
        QueryKey,
        AssetControllerGetAssetsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetAssetsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssets>>,
    QueryKey,
    AssetControllerGetAssetsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    assetControllerGetAssets(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssets>>,
    TError,
    TData,
    Awaited<ReturnType<typeof assetControllerGetAssets>>,
    QueryKey,
    AssetControllerGetAssetsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssets>>
>;
export type AssetControllerGetAssetsInfiniteQueryError = AxiosError<unknown>;

export const useAssetControllerGetAssetsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssets>>,
    AssetControllerGetAssetsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssets>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetAssets>>,
        QueryKey,
        AssetControllerGetAssetsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetAssetsQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssets>>,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssets>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetAssetsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssets>>
  > = ({ signal }) =>
    assetControllerGetAssets(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssets>>
>;
export type AssetControllerGetAssetsQueryError = AxiosError<unknown>;

export const useAssetControllerGetAssets = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssets>>,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssets>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerCreateAsset = (
  createAssetDto: CreateAssetDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/asset`, createAssetDto, options);
};

export const getAssetControllerCreateAssetMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerCreateAsset>>,
    TError,
    { data: CreateAssetDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetControllerCreateAsset>>,
  TError,
  { data: CreateAssetDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetControllerCreateAsset>>,
    { data: CreateAssetDto }
  > = (props) => {
    const { data } = props ?? {};

    return assetControllerCreateAsset(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetControllerCreateAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerCreateAsset>>
>;
export type AssetControllerCreateAssetMutationBody = CreateAssetDto;
export type AssetControllerCreateAssetMutationError = AxiosError<unknown>;

export const useAssetControllerCreateAsset = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerCreateAsset>>,
    TError,
    { data: CreateAssetDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetControllerCreateAsset>>,
  TError,
  { data: CreateAssetDto },
  TContext
> => {
  const mutationOptions = getAssetControllerCreateAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetControllerGetAssetsV2 = (
  params: AssetControllerGetAssetsV2Params,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAssetsV2ResponseDto>> => {
  return axios.get(`/asset/v2`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAssetControllerGetAssetsV2QueryKey = (
  params: AssetControllerGetAssetsV2Params
) => {
  return [`/asset/v2`, ...(params ? [params] : [])] as const;
};

export const getAssetControllerGetAssetsV2InfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
    AssetControllerGetAssetsV2Params["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsV2Params,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
        QueryKey,
        AssetControllerGetAssetsV2Params["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetAssetsV2QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
    QueryKey,
    AssetControllerGetAssetsV2Params["cursorId"]
  > = ({ signal, pageParam }) =>
    assetControllerGetAssetsV2(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
    TError,
    TData,
    Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
    QueryKey,
    AssetControllerGetAssetsV2Params["cursorId"]
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetsV2InfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssetsV2>>
>;
export type AssetControllerGetAssetsV2InfiniteQueryError = AxiosError<unknown>;

export const useAssetControllerGetAssetsV2Infinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
    AssetControllerGetAssetsV2Params["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsV2Params,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
        QueryKey,
        AssetControllerGetAssetsV2Params["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetsV2InfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetAssetsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetAssetsV2QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetsV2>>
  > = ({ signal }) =>
    assetControllerGetAssetsV2(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetsV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssetsV2>>
>;
export type AssetControllerGetAssetsV2QueryError = AxiosError<unknown>;

export const useAssetControllerGetAssetsV2 = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
  TError = AxiosError<unknown>
>(
  params: AssetControllerGetAssetsV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetsV2>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetsV2QueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerGetOneAsset = (
  assetId: string,
  params: AssetControllerGetOneAssetParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneAsset>> => {
  return axios.get(`/asset/${assetId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAssetControllerGetOneAssetQueryKey = (
  assetId: string,
  params: AssetControllerGetOneAssetParams
) => {
  return [`/asset/${assetId}`, ...(params ? [params] : [])] as const;
};

export const getAssetControllerGetOneAssetInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
    AssetControllerGetOneAssetParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params: AssetControllerGetOneAssetParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
        QueryKey,
        AssetControllerGetOneAssetParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetOneAssetQueryKey(assetId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
    QueryKey,
    AssetControllerGetOneAssetParams["cursorId"]
  > = ({ signal, pageParam }) =>
    assetControllerGetOneAsset(
      assetId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!assetId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
    TError,
    TData,
    Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
    QueryKey,
    AssetControllerGetOneAssetParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AssetControllerGetOneAssetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetOneAsset>>
>;
export type AssetControllerGetOneAssetInfiniteQueryError = AxiosError<unknown>;

export const useAssetControllerGetOneAssetInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
    AssetControllerGetOneAssetParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params: AssetControllerGetOneAssetParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
        QueryKey,
        AssetControllerGetOneAssetParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetOneAssetInfiniteQueryOptions(
    assetId,
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetOneAssetQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params: AssetControllerGetOneAssetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetOneAssetQueryKey(assetId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetOneAsset>>
  > = ({ signal }) =>
    assetControllerGetOneAsset(assetId, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!assetId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetOneAssetQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetOneAsset>>
>;
export type AssetControllerGetOneAssetQueryError = AxiosError<unknown>;

export const useAssetControllerGetOneAsset = <
  TData = Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params: AssetControllerGetOneAssetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetOneAsset>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetOneAssetQueryOptions(
    assetId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerUpdateAsset = (
  assetId: string,
  updateAssetDto: UpdateAssetDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/asset/${assetId}`, updateAssetDto, options);
};

export const getAssetControllerUpdateAssetMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerUpdateAsset>>,
    TError,
    { assetId: string; data: UpdateAssetDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetControllerUpdateAsset>>,
  TError,
  { assetId: string; data: UpdateAssetDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetControllerUpdateAsset>>,
    { assetId: string; data: UpdateAssetDto }
  > = (props) => {
    const { assetId, data } = props ?? {};

    return assetControllerUpdateAsset(assetId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetControllerUpdateAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerUpdateAsset>>
>;
export type AssetControllerUpdateAssetMutationBody = UpdateAssetDto;
export type AssetControllerUpdateAssetMutationError = AxiosError<unknown>;

export const useAssetControllerUpdateAsset = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerUpdateAsset>>,
    TError,
    { assetId: string; data: UpdateAssetDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetControllerUpdateAsset>>,
  TError,
  { assetId: string; data: UpdateAssetDto },
  TContext
> => {
  const mutationOptions = getAssetControllerUpdateAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetControllerDeleteAsset = (
  assetId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/asset/${assetId}`, options);
};

export const getAssetControllerDeleteAssetMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerDeleteAsset>>,
    TError,
    { assetId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetControllerDeleteAsset>>,
  TError,
  { assetId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetControllerDeleteAsset>>,
    { assetId: string }
  > = (props) => {
    const { assetId } = props ?? {};

    return assetControllerDeleteAsset(assetId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetControllerDeleteAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerDeleteAsset>>
>;

export type AssetControllerDeleteAssetMutationError = AxiosError<unknown>;

export const useAssetControllerDeleteAsset = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerDeleteAsset>>,
    TError,
    { assetId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetControllerDeleteAsset>>,
  TError,
  { assetId: string },
  TContext
> => {
  const mutationOptions = getAssetControllerDeleteAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetControllerCreateSubAseet = (
  assetId: string,
  createSubAssetDto: CreateSubAssetDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/asset/${assetId}/create-sub-asset`,
    createSubAssetDto,
    options
  );
};

export const getAssetControllerCreateSubAseetMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerCreateSubAseet>>,
    TError,
    { assetId: string; data: CreateSubAssetDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetControllerCreateSubAseet>>,
  TError,
  { assetId: string; data: CreateSubAssetDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetControllerCreateSubAseet>>,
    { assetId: string; data: CreateSubAssetDto }
  > = (props) => {
    const { assetId, data } = props ?? {};

    return assetControllerCreateSubAseet(assetId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetControllerCreateSubAseetMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerCreateSubAseet>>
>;
export type AssetControllerCreateSubAseetMutationBody = CreateSubAssetDto;
export type AssetControllerCreateSubAseetMutationError = AxiosError<unknown>;

export const useAssetControllerCreateSubAseet = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerCreateSubAseet>>,
    TError,
    { assetId: string; data: CreateSubAssetDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetControllerCreateSubAseet>>,
  TError,
  { assetId: string; data: CreateSubAssetDto },
  TContext
> => {
  const mutationOptions =
    getAssetControllerCreateSubAseetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetControllerDeleteAttachment = (
  assetId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.delete(
    `/asset/${assetId}/additionalDocuments/${attachmentId}`,
    options
  );
};

export const getAssetControllerDeleteAttachmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerDeleteAttachment>>,
    TError,
    { assetId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetControllerDeleteAttachment>>,
  TError,
  { assetId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetControllerDeleteAttachment>>,
    { assetId: string; attachmentId: string }
  > = (props) => {
    const { assetId, attachmentId } = props ?? {};

    return assetControllerDeleteAttachment(assetId, attachmentId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetControllerDeleteAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerDeleteAttachment>>
>;

export type AssetControllerDeleteAttachmentMutationError = AxiosError<unknown>;

export const useAssetControllerDeleteAttachment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerDeleteAttachment>>,
    TError,
    { assetId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetControllerDeleteAttachment>>,
  TError,
  { assetId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions =
    getAssetControllerDeleteAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetControllerDeletePhoto = (
  assetId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.delete(`/asset/${assetId}/photos/${attachmentId}`, options);
};

export const getAssetControllerDeletePhotoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerDeletePhoto>>,
    TError,
    { assetId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetControllerDeletePhoto>>,
  TError,
  { assetId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetControllerDeletePhoto>>,
    { assetId: string; attachmentId: string }
  > = (props) => {
    const { assetId, attachmentId } = props ?? {};

    return assetControllerDeletePhoto(assetId, attachmentId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetControllerDeletePhotoMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerDeletePhoto>>
>;

export type AssetControllerDeletePhotoMutationError = AxiosError<unknown>;

export const useAssetControllerDeletePhoto = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerDeletePhoto>>,
    TError,
    { assetId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetControllerDeletePhoto>>,
  TError,
  { assetId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions = getAssetControllerDeletePhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetControllerGetAssetWorkOrders = (
  assetId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AssetWorkOrdersResponseDto[]>> => {
  return axios.get(`/asset/${assetId}/defects`, options);
};

export const getAssetControllerGetAssetWorkOrdersQueryKey = (
  assetId: string
) => {
  return [`/asset/${assetId}/defects`] as const;
};

export const getAssetControllerGetAssetWorkOrdersInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>
  >,
  TError = AxiosError<unknown>
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetAssetWorkOrdersQueryKey(assetId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>
  > = ({ signal }) =>
    assetControllerGetAssetWorkOrders(assetId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!assetId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetWorkOrdersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>
>;
export type AssetControllerGetAssetWorkOrdersInfiniteQueryError =
  AxiosError<unknown>;

export const useAssetControllerGetAssetWorkOrdersInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>
  >,
  TError = AxiosError<unknown>
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetWorkOrdersInfiniteQueryOptions(
    assetId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetAssetWorkOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
  TError = AxiosError<unknown>
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetAssetWorkOrdersQueryKey(assetId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>
  > = ({ signal }) =>
    assetControllerGetAssetWorkOrders(assetId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!assetId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetWorkOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>
>;
export type AssetControllerGetAssetWorkOrdersQueryError = AxiosError<unknown>;

export const useAssetControllerGetAssetWorkOrders = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
  TError = AxiosError<unknown>
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetWorkOrders>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetWorkOrdersQueryOptions(
    assetId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerGetAssetPm = (
  assetId: string,
  params?: AssetControllerGetAssetPmParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PmChecklistResponseDto[]>> => {
  return axios.get(`/asset/${assetId}/pm`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAssetControllerGetAssetPmQueryKey = (
  assetId: string,
  params?: AssetControllerGetAssetPmParams
) => {
  return [`/asset/${assetId}/pm`, ...(params ? [params] : [])] as const;
};

export const getAssetControllerGetAssetPmInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
    AssetControllerGetAssetPmParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params?: AssetControllerGetAssetPmParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
        QueryKey,
        AssetControllerGetAssetPmParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetAssetPmQueryKey(assetId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
    QueryKey,
    AssetControllerGetAssetPmParams["cursorId"]
  > = ({ signal, pageParam }) =>
    assetControllerGetAssetPm(
      assetId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!assetId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
    TError,
    TData,
    Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
    QueryKey,
    AssetControllerGetAssetPmParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetPmInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssetPm>>
>;
export type AssetControllerGetAssetPmInfiniteQueryError = AxiosError<unknown>;

export const useAssetControllerGetAssetPmInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
    AssetControllerGetAssetPmParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params?: AssetControllerGetAssetPmParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
        QueryKey,
        AssetControllerGetAssetPmParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetPmInfiniteQueryOptions(
    assetId,
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetAssetPmQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params?: AssetControllerGetAssetPmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetAssetPmQueryKey(assetId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetPm>>
  > = ({ signal }) =>
    assetControllerGetAssetPm(assetId, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!assetId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetPmQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssetPm>>
>;
export type AssetControllerGetAssetPmQueryError = AxiosError<unknown>;

export const useAssetControllerGetAssetPm = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
  TError = AxiosError<unknown>
>(
  assetId: string,
  params?: AssetControllerGetAssetPmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetPm>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetPmQueryOptions(
    assetId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerGenerateQrCode = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAssetQrCodeResponseDto>> => {
  return axios.get(`/asset/project/${projectId}/generate-qr-code`, options);
};

export const getAssetControllerGenerateQrCodeQueryKey = (projectId: string) => {
  return [`/asset/project/${projectId}/generate-qr-code`] as const;
};

export const getAssetControllerGenerateQrCodeInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGenerateQrCode>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGenerateQrCodeQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGenerateQrCode>>
  > = ({ signal }) =>
    assetControllerGenerateQrCode(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGenerateQrCodeInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGenerateQrCode>>
>;
export type AssetControllerGenerateQrCodeInfiniteQueryError =
  AxiosError<unknown>;

export const useAssetControllerGenerateQrCodeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGenerateQrCode>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGenerateQrCodeInfiniteQueryOptions(
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGenerateQrCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGenerateQrCodeQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGenerateQrCode>>
  > = ({ signal }) =>
    assetControllerGenerateQrCode(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGenerateQrCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGenerateQrCode>>
>;
export type AssetControllerGenerateQrCodeQueryError = AxiosError<unknown>;

export const useAssetControllerGenerateQrCode = <
  TData = Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGenerateQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGenerateQrCodeQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerGetQrCode = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/asset/project/${projectId}/get-qr-code`, options);
};

export const getAssetControllerGetQrCodeQueryKey = (projectId: string) => {
  return [`/asset/project/${projectId}/get-qr-code`] as const;
};

export const getAssetControllerGetQrCodeInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof assetControllerGetQrCode>>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetQrCodeQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetQrCode>>
  > = ({ signal }) =>
    assetControllerGetQrCode(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetQrCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetQrCodeInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetQrCode>>
>;
export type AssetControllerGetQrCodeInfiniteQueryError = AxiosError<unknown>;

export const useAssetControllerGetQrCodeInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof assetControllerGetQrCode>>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetQrCodeInfiniteQueryOptions(
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetQrCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetQrCode>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssetControllerGetQrCodeQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetQrCode>>
  > = ({ signal }) =>
    assetControllerGetQrCode(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetQrCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetQrCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetQrCode>>
>;
export type AssetControllerGetQrCodeQueryError = AxiosError<unknown>;

export const useAssetControllerGetQrCode = <
  TData = Awaited<ReturnType<typeof assetControllerGetQrCode>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetQrCodeQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerGetAssetBasedOnBimUuid = (
  projectId: string,
  bimUuid: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneAsset>> => {
  return axios.get(`/asset/project/${projectId}/bim/${bimUuid}`, options);
};

export const getAssetControllerGetAssetBasedOnBimUuidQueryKey = (
  projectId: string,
  bimUuid: string
) => {
  return [`/asset/project/${projectId}/bim/${bimUuid}`] as const;
};

export const getAssetControllerGetAssetBasedOnBimUuidInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  bimUuid: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetAssetBasedOnBimUuidQueryKey(projectId, bimUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>
  > = ({ signal }) =>
    assetControllerGetAssetBasedOnBimUuid(projectId, bimUuid, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(projectId && bimUuid),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetBasedOnBimUuidInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>
  >;
export type AssetControllerGetAssetBasedOnBimUuidInfiniteQueryError =
  AxiosError<unknown>;

export const useAssetControllerGetAssetBasedOnBimUuidInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  bimUuid: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAssetControllerGetAssetBasedOnBimUuidInfiniteQueryOptions(
      projectId,
      bimUuid,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetControllerGetAssetBasedOnBimUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  bimUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetControllerGetAssetBasedOnBimUuidQueryKey(projectId, bimUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>
  > = ({ signal }) =>
    assetControllerGetAssetBasedOnBimUuid(projectId, bimUuid, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(projectId && bimUuid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetControllerGetAssetBasedOnBimUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>
>;
export type AssetControllerGetAssetBasedOnBimUuidQueryError =
  AxiosError<unknown>;

export const useAssetControllerGetAssetBasedOnBimUuid = <
  TData = Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  bimUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetControllerGetAssetBasedOnBimUuid>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetControllerGetAssetBasedOnBimUuidQueryOptions(
    projectId,
    bimUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetControllerGetEquipmentQrCode = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetEquipmentQrCodeResponseDto | void>> => {
  return axios.post(
    `/asset/project/${projectId}/export-qr-code`,
    undefined,
    options
  );
};

export const getAssetControllerGetEquipmentQrCodeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerGetEquipmentQrCode>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetControllerGetEquipmentQrCode>>,
  TError,
  { projectId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetControllerGetEquipmentQrCode>>,
    { projectId: string }
  > = (props) => {
    const { projectId } = props ?? {};

    return assetControllerGetEquipmentQrCode(projectId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetControllerGetEquipmentQrCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetControllerGetEquipmentQrCode>>
>;

export type AssetControllerGetEquipmentQrCodeMutationError =
  AxiosError<unknown>;

export const useAssetControllerGetEquipmentQrCode = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetControllerGetEquipmentQrCode>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetControllerGetEquipmentQrCode>>,
  TError,
  { projectId: string },
  TContext
> => {
  const mutationOptions =
    getAssetControllerGetEquipmentQrCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const dataImportsControllerImportLocation = (
  importLocationRequestDto: ImportLocationRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DataImportsControllerImportLocation201>> => {
  return axios.post(
    `/data-imports/location`,
    importLocationRequestDto,
    options
  );
};

export const getDataImportsControllerImportLocationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
    TError,
    { data: ImportLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
  TError,
  { data: ImportLocationRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
    { data: ImportLocationRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return dataImportsControllerImportLocation(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DataImportsControllerImportLocationMutationResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerImportLocation>>
>;
export type DataImportsControllerImportLocationMutationBody =
  ImportLocationRequestDto;
export type DataImportsControllerImportLocationMutationError =
  AxiosError<unknown>;

export const useDataImportsControllerImportLocation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
    TError,
    { data: ImportLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
  TError,
  { data: ImportLocationRequestDto },
  TContext
> => {
  const mutationOptions =
    getDataImportsControllerImportLocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const dataImportsControllerImportAssets = (
  importAssetRequest: ImportAssetRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/data-imports/assets`, importAssetRequest, options);
};

export const getDataImportsControllerImportAssetsMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
    TError,
    { data: ImportAssetRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
  TError,
  { data: ImportAssetRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
    { data: ImportAssetRequest }
  > = (props) => {
    const { data } = props ?? {};

    return dataImportsControllerImportAssets(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DataImportsControllerImportAssetsMutationResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerImportAssets>>
>;
export type DataImportsControllerImportAssetsMutationBody = ImportAssetRequest;
export type DataImportsControllerImportAssetsMutationError =
  AxiosError<unknown>;

export const useDataImportsControllerImportAssets = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
    TError,
    { data: ImportAssetRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
  TError,
  { data: ImportAssetRequest },
  TContext
> => {
  const mutationOptions =
    getDataImportsControllerImportAssetsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const dataImportsControllerGetLocations = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/data-imports/location/${projectId}`, options);
};

export const getDataImportsControllerGetLocationsQueryKey = (
  projectId: string
) => {
  return [`/data-imports/location/${projectId}`] as const;
};

export const getDataImportsControllerGetLocationsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDataImportsControllerGetLocationsQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  > = ({ signal }) =>
    dataImportsControllerGetLocations(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DataImportsControllerGetLocationsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
>;
export type DataImportsControllerGetLocationsInfiniteQueryError =
  AxiosError<unknown>;

export const useDataImportsControllerGetLocationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDataImportsControllerGetLocationsInfiniteQueryOptions(
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDataImportsControllerGetLocationsQueryOptions = <
  TData = Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDataImportsControllerGetLocationsQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  > = ({ signal }) =>
    dataImportsControllerGetLocations(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DataImportsControllerGetLocationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
>;
export type DataImportsControllerGetLocationsQueryError = AxiosError<unknown>;

export const useDataImportsControllerGetLocations = <
  TData = Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDataImportsControllerGetLocationsQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
