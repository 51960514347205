/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateInvoiceRequestDto,
  GetInvoicesResponseDto,
  InvoiceControllerGetInvoicesParams,
  InvoiceControllerGetOneInvoiceParams,
  UpdateInvoiceRequestDto,
} from ".././model";

export const invoiceControllerGetInvoices = (
  params?: InvoiceControllerGetInvoicesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetInvoicesResponseDto>> => {
  return axios.get(`/invoice`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getInvoiceControllerGetInvoicesQueryKey = (
  params?: InvoiceControllerGetInvoicesParams
) => {
  return [`/invoice`, ...(params ? [params] : [])] as const;
};

export const getInvoiceControllerGetInvoicesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
    InvoiceControllerGetInvoicesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: InvoiceControllerGetInvoicesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
        TError,
        TData,
        Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
        QueryKey,
        InvoiceControllerGetInvoicesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInvoiceControllerGetInvoicesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
    QueryKey,
    InvoiceControllerGetInvoicesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    invoiceControllerGetInvoices(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
    TError,
    TData,
    Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
    QueryKey,
    InvoiceControllerGetInvoicesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type InvoiceControllerGetInvoicesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof invoiceControllerGetInvoices>>
>;
export type InvoiceControllerGetInvoicesInfiniteQueryError =
  AxiosError<unknown>;

export const useInvoiceControllerGetInvoicesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
    InvoiceControllerGetInvoicesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: InvoiceControllerGetInvoicesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
        TError,
        TData,
        Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
        QueryKey,
        InvoiceControllerGetInvoicesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInvoiceControllerGetInvoicesInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getInvoiceControllerGetInvoicesQueryOptions = <
  TData = Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
  TError = AxiosError<unknown>
>(
  params?: InvoiceControllerGetInvoicesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInvoiceControllerGetInvoicesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof invoiceControllerGetInvoices>>
  > = ({ signal }) =>
    invoiceControllerGetInvoices(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InvoiceControllerGetInvoicesQueryResult = NonNullable<
  Awaited<ReturnType<typeof invoiceControllerGetInvoices>>
>;
export type InvoiceControllerGetInvoicesQueryError = AxiosError<unknown>;

export const useInvoiceControllerGetInvoices = <
  TData = Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
  TError = AxiosError<unknown>
>(
  params?: InvoiceControllerGetInvoicesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetInvoices>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInvoiceControllerGetInvoicesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const invoiceControllerCreateInvoice = (
  createInvoiceRequestDto: CreateInvoiceRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/invoice`, createInvoiceRequestDto, options);
};

export const getInvoiceControllerCreateInvoiceMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invoiceControllerCreateInvoice>>,
    TError,
    { data: CreateInvoiceRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invoiceControllerCreateInvoice>>,
  TError,
  { data: CreateInvoiceRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invoiceControllerCreateInvoice>>,
    { data: CreateInvoiceRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return invoiceControllerCreateInvoice(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvoiceControllerCreateInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof invoiceControllerCreateInvoice>>
>;
export type InvoiceControllerCreateInvoiceMutationBody =
  CreateInvoiceRequestDto;
export type InvoiceControllerCreateInvoiceMutationError = AxiosError<unknown>;

export const useInvoiceControllerCreateInvoice = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invoiceControllerCreateInvoice>>,
    TError,
    { data: CreateInvoiceRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof invoiceControllerCreateInvoice>>,
  TError,
  { data: CreateInvoiceRequestDto },
  TContext
> => {
  const mutationOptions =
    getInvoiceControllerCreateInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const invoiceControllerGetOneInvoice = (
  invoiceId: string,
  params: InvoiceControllerGetOneInvoiceParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/invoice/${invoiceId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getInvoiceControllerGetOneInvoiceQueryKey = (
  invoiceId: string,
  params: InvoiceControllerGetOneInvoiceParams
) => {
  return [`/invoice/${invoiceId}`, ...(params ? [params] : [])] as const;
};

export const getInvoiceControllerGetOneInvoiceInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
    InvoiceControllerGetOneInvoiceParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  invoiceId: string,
  params: InvoiceControllerGetOneInvoiceParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
        TError,
        TData,
        Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
        QueryKey,
        InvoiceControllerGetOneInvoiceParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInvoiceControllerGetOneInvoiceQueryKey(invoiceId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
    QueryKey,
    InvoiceControllerGetOneInvoiceParams["cursorId"]
  > = ({ signal, pageParam }) =>
    invoiceControllerGetOneInvoice(
      invoiceId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!invoiceId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
    TError,
    TData,
    Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
    QueryKey,
    InvoiceControllerGetOneInvoiceParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type InvoiceControllerGetOneInvoiceInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>
>;
export type InvoiceControllerGetOneInvoiceInfiniteQueryError =
  AxiosError<unknown>;

export const useInvoiceControllerGetOneInvoiceInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
    InvoiceControllerGetOneInvoiceParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  invoiceId: string,
  params: InvoiceControllerGetOneInvoiceParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
        TError,
        TData,
        Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
        QueryKey,
        InvoiceControllerGetOneInvoiceParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInvoiceControllerGetOneInvoiceInfiniteQueryOptions(
    invoiceId,
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getInvoiceControllerGetOneInvoiceQueryOptions = <
  TData = Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
  TError = AxiosError<unknown>
>(
  invoiceId: string,
  params: InvoiceControllerGetOneInvoiceParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInvoiceControllerGetOneInvoiceQueryKey(invoiceId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>
  > = ({ signal }) =>
    invoiceControllerGetOneInvoice(invoiceId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!invoiceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InvoiceControllerGetOneInvoiceQueryResult = NonNullable<
  Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>
>;
export type InvoiceControllerGetOneInvoiceQueryError = AxiosError<unknown>;

export const useInvoiceControllerGetOneInvoice = <
  TData = Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
  TError = AxiosError<unknown>
>(
  invoiceId: string,
  params: InvoiceControllerGetOneInvoiceParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof invoiceControllerGetOneInvoice>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInvoiceControllerGetOneInvoiceQueryOptions(
    invoiceId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const invoiceControllerDeleteInvoice = (
  invoiceId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/invoice/${invoiceId}`, options);
};

export const getInvoiceControllerDeleteInvoiceMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invoiceControllerDeleteInvoice>>,
    TError,
    { invoiceId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invoiceControllerDeleteInvoice>>,
  TError,
  { invoiceId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invoiceControllerDeleteInvoice>>,
    { invoiceId: string }
  > = (props) => {
    const { invoiceId } = props ?? {};

    return invoiceControllerDeleteInvoice(invoiceId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvoiceControllerDeleteInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof invoiceControllerDeleteInvoice>>
>;

export type InvoiceControllerDeleteInvoiceMutationError = AxiosError<unknown>;

export const useInvoiceControllerDeleteInvoice = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invoiceControllerDeleteInvoice>>,
    TError,
    { invoiceId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof invoiceControllerDeleteInvoice>>,
  TError,
  { invoiceId: string },
  TContext
> => {
  const mutationOptions =
    getInvoiceControllerDeleteInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const invoiceControllerUpdateInvoice = (
  invoiceId: string,
  updateInvoiceRequestDto: UpdateInvoiceRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/invoice/${invoiceId}`, updateInvoiceRequestDto, options);
};

export const getInvoiceControllerUpdateInvoiceMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invoiceControllerUpdateInvoice>>,
    TError,
    { invoiceId: string; data: UpdateInvoiceRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invoiceControllerUpdateInvoice>>,
  TError,
  { invoiceId: string; data: UpdateInvoiceRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invoiceControllerUpdateInvoice>>,
    { invoiceId: string; data: UpdateInvoiceRequestDto }
  > = (props) => {
    const { invoiceId, data } = props ?? {};

    return invoiceControllerUpdateInvoice(invoiceId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvoiceControllerUpdateInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof invoiceControllerUpdateInvoice>>
>;
export type InvoiceControllerUpdateInvoiceMutationBody =
  UpdateInvoiceRequestDto;
export type InvoiceControllerUpdateInvoiceMutationError = AxiosError<unknown>;

export const useInvoiceControllerUpdateInvoice = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invoiceControllerUpdateInvoice>>,
    TError,
    { invoiceId: string; data: UpdateInvoiceRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof invoiceControllerUpdateInvoice>>,
  TError,
  { invoiceId: string; data: UpdateInvoiceRequestDto },
  TContext
> => {
  const mutationOptions =
    getInvoiceControllerUpdateInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};
