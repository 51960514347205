import { PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { JKRWorkOrderPdf } from "@cerev-cmms/pdf";
import { useDefectControllerGetOneDefect } from "../../api/work-orders-defects/work-orders-defects";

export function JKRCustomWorkOrderPdfPreview() {
  const { workOrderId } = useParams();
  const { data, isLoading } = useDefectControllerGetOneDefect(
    workOrderId as string,
    {
      query: {
        enabled: !!workOrderId,
        select: (res) => res.data,
      },
    }
  );

  if (isLoading) return <>Loading...</>; 

  if (!data) return <>Error</>;

  return (
    <>
      <PDFViewer style={{ height: "100vh", width: "100vw" }}>
        <JKRWorkOrderPdf workOrder={data} />
      </PDFViewer>
    </>
  );
}
