import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  usePtwTemplateControllerCreatePtwTemplate,
  usePtwTemplateControllerGetOnePtwTemplate,
  usePtwTemplateControllerUpdatePtwTemplate,
} from "../../../api/permit-to-work-adv/permit-to-work-adv";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppTextField from "../../AppTextField";
import PtwFieldDropdown from "./components/PtwFieldDropdown/PtwFieldDropdown";
import PtwTemplateFormatItem from "./components/PtwFormatItem/PtwTemplateFormatItem";
import {
  PtwFormatFormType,
  PtwTemplateFormSchema,
  PtwTemplateFormType,
} from "./ptwTemplateSchema";

export default function PtwTemplateDrawerForm() {
  // This state variable is to ensure that the form is only updated once.
  const [updated, setUpdated] = useState(false);
  const [searchParams] = useSearchParams();
  const nav = useNavigate();
  const qc = useQueryClient();
  const ptwTemplateId = searchParams.get("ptwTemplateId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<PtwTemplateFormType>({
    resolver: zodResolver(PtwTemplateFormSchema),
    defaultValues: {
      name: "",
      ptwFormat: [],
    },
  });

  const fieldsArray = useFieldArray<PtwTemplateFormType>({
    control: methods.control,
    name: "ptwFormat",
  });

  const { data: editPtwTemplate } = usePtwTemplateControllerGetOnePtwTemplate(
    ptwTemplateId as string,
    {
      query: {
        enabled: !!ptwTemplateId,
        select: (res) => res.data.data,
      },
    }
  );
  const { mutate: updatePtw, isPending: updateIsPending } =
    usePtwTemplateControllerUpdatePtwTemplate({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("permit-to-work"),
          });
          nav(-1);
        },
      },
    });

  const { mutate: createPtw, isPending: createIsPending } =
    usePtwTemplateControllerCreatePtwTemplate({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("permit-to-work"),
          });
          nav(-1);
        },
      },
    });

  useEffect(() => {
    if (editPtwTemplate && !updated) {
      setUpdated(true);
      methods.setValue("name", editPtwTemplate.name);
      methods.setValue("ptwFormat", editPtwTemplate.ptwFormat);
    }
  }, [editPtwTemplate, updated]);

  const onSubmit: SubmitHandler<PtwTemplateFormType> = async (data) => {
    const isUpdate = !!ptwTemplateId;
    if (!activeProj || !activeProj.id) return;

    if (isUpdate) {
      updatePtw({
        ptwTemplateId: ptwTemplateId as string,
        data: {
          name: data.name,
          projectId: activeProj.id,
          ptwFormat: data.ptwFormat.map((f, idx) => ({
            ...f,
            order: idx,
          })),
        },
      });
    } else {
      createPtw({
        data: {
          name: data.name,
          ptwFormat: data.ptwFormat.map((f, idx) => ({
            ...f,
            order: idx,
          })),
          projectId: activeProj.id,
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-sans text-2xl font-bold">
        {ptwTemplateId
          ? "Edit Permit to Work Template"
          : "Create Permit to Work Template"}
      </h3>
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6">
          <AppTextField label="Name" name="name" />
          <h4 className="text-xl font-bold">Fields</h4>
          {fieldsArray.fields.map((f, index) => (
            <PtwTemplateFormatItem
              key={f.id}
              ptw={f as PtwFormatFormType}
              index={index}
              fieldsArray={fieldsArray}
            />
          ))}
          <PtwFieldDropdown fieldsArray={fieldsArray} />
          {methods.formState.errors["ptwFormat"] && (
            <p className="text-red-500 text-sm font-medium">
              {methods.formState.errors["ptwFormat"]?.message ?? "-"}
            </p>
          )}
        </div>
        <div className="flex">
          <AppButton
            label="Confirm"
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={createIsPending || updateIsPending}
          />
        </div>
      </FormProvider>
    </div>
  );
}
