import { ColumnDef, Row } from "@tanstack/react-table";
import { PtwTemplate } from "../../api/model";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DRAWER_CREATE_STATE,
  PERMIT_TO_WORK_TEMPLATE_DETAIL_DRAWER,
} from "@/components/drawers/AppDrawer";
import AppButton from "../../components/AppButton";
import { usePtwTemplateControllerGenerateWorkPermit } from "../../api/permit-to-work-adv/permit-to-work-adv";
import { useAppSelector } from "../../redux/store";
import { Link as LinkIcon, QrCode } from "lucide-react";
import { Link } from "react-router-dom";

const PtwTemplateNameNav = ({ row }: { row: Row<PtwTemplate> }) => {
  const [, setSearchParams] = useSearchParams();
  return (
    <div className={`flex flex-col gap-1`}>
      <div
        className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
        onClick={() => {
          setSearchParams((p) => {
            p.set("drawerType", PERMIT_TO_WORK_TEMPLATE_DETAIL_DRAWER);
            p.set("drawerState", DRAWER_CREATE_STATE);
            p.set("ptwTemplateId", row.getValue("id"));
            return p;
          });
        }}
      >
        {row.getValue("name") ?? "-"}
      </div>
    </div>
  );
};

const PtwTemplateActions = ({ row }: { row: Row<PtwTemplate> }) => {
  const { mutate, isPending } = usePtwTemplateControllerGenerateWorkPermit();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const nav = useNavigate();

  return (
    <div className="flex gap-4">
      <AppButton
        isLoading={isPending}
        label="Go to Page"
        variant="outline"
        icon={<LinkIcon />}
        onClick={() => {
          nav(
            "/public/work-permit/" +
              activeProj?.workPermitCode +
              "/" +
              row.getValue("id")
          );
        }}
      />
      <AppButton
        variant="outline"
        label="Print QR Code"
        icon={<QrCode />}
        onClick={() => {
          window.open("/work-permit-pdf/" + row.getValue("id"), "_blank");
        }}
      />
    </div>
  );
};

export const ptwTemplateColumns: ColumnDef<PtwTemplate>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "name",
    cell: ({ row }) => <PtwTemplateNameNav row={row} />,
  },
  {
    header: "Actions",
    cell: ({ row }) => <PtwTemplateActions row={row} />,
  },
];
