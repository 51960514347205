import {
  GetOneAsset,
  useAssetControllerGetAssetWorkOrdersQuery,
} from "../../../../redux/slices/OpenApi/cerevApi";
import AppImage from "../../../AppImage";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import AppCenterEmpty from "../../../AppCenterEmpty";
import { useSearchParams } from "react-router-dom";
import { DRAWER_VIEW_STATE, WORK_ORDER_DETAIL_DRAWER } from "../../AppDrawer";
import { ChevronRight } from "lucide-react";
import { useAssetControllerGetAssetWorkOrders } from "../../../../api/assets/assets";

interface AssetWorkOrderTabProps {
  asset?: GetOneAsset;
}

export default function AssetWorkOrderTab({ asset }: AssetWorkOrderTabProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: workOrders, isLoading } = useAssetControllerGetAssetWorkOrders(
    asset?.id.toString() ?? "",
    {
      query: {
        enabled: !!asset,
        select: (res) => res.data,
      },
    }
  );

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      {workOrders?.length === 0 && <AppCenterEmpty />}
      {workOrders?.map((wo) => (
        <div
          className="rounded-xl w-full bg-neutral-300 flex shadow cursor-pointer"
          key={wo.id}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
              p.set("drawerState", DRAWER_VIEW_STATE);
              p.set("workOrderId", wo?.id?.toString() ?? "");
              p.set("workOrderTabIdx", "0");
              return p;
            });
          }}
        >
          <AppImage att={wo.photos[0]} />
          <div className="flex flex-col p-4 justify-center">
            <p className="font-sans font-bold text-lg">{wo.subject ?? "-"}</p>
            <p className="font-sans text-neutral-600 truncate">
              {wo.description ?? "-"}
            </p>
          </div>
          <div className="flex flex-row-reverse px-4 flex-1 items-center">
            <ChevronRight className="text-primary-900 w-8 h-8" />
          </div>
        </div>
      ))}
    </div>
  );
}
