/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetOneUserResponseDto,
  GetUserCountResponseDto,
  UpdateUserProjectDto,
  UpdateUserRoleDto,
  UpdateUserV2Dto,
  UpdateUserVendorDto,
  UserControllerGetUserCountParams,
  UserControllerGetUsersParams,
  UserControllerUpdateUser200,
  UserEntity,
} from ".././model";

export const userControllerGetUsers = (
  params: UserControllerGetUsersParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserEntity[]>> => {
  return axios.get(`/users`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getUserControllerGetUsersQueryKey = (
  params: UserControllerGetUsersParams
) => {
  return [`/users`, ...(params ? [params] : [])] as const;
};

export const getUserControllerGetUsersInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof userControllerGetUsers>>,
    UserControllerGetUsersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUsers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof userControllerGetUsers>>,
        QueryKey,
        UserControllerGetUsersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUsersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUsers>>,
    QueryKey,
    UserControllerGetUsersParams["cursorId"]
  > = ({ signal, pageParam }) =>
    userControllerGetUsers(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUsers>>,
    TError,
    TData,
    Awaited<ReturnType<typeof userControllerGetUsers>>,
    QueryKey,
    UserControllerGetUsersParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type UserControllerGetUsersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUsers>>
>;
export type UserControllerGetUsersInfiniteQueryError = AxiosError<unknown>;

export const useUserControllerGetUsersInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof userControllerGetUsers>>,
    UserControllerGetUsersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUsers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof userControllerGetUsers>>,
        QueryKey,
        UserControllerGetUsersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetUsersInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserControllerGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUsers>>,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUsersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUsers>>
  > = ({ signal }) =>
    userControllerGetUsers(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerGetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUsers>>
>;
export type UserControllerGetUsersQueryError = AxiosError<unknown>;

export const useUserControllerGetUsers = <
  TData = Awaited<ReturnType<typeof userControllerGetUsers>>,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userControllerGetUserCount = (
  params: UserControllerGetUserCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetUserCountResponseDto>> => {
  return axios.get(`/users/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getUserControllerGetUserCountQueryKey = (
  params: UserControllerGetUserCountParams
) => {
  return [`/users/count`, ...(params ? [params] : [])] as const;
};

export const getUserControllerGetUserCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof userControllerGetUserCount>>,
    UserControllerGetUserCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUserCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof userControllerGetUserCount>>,
        QueryKey,
        UserControllerGetUserCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUserCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserCount>>,
    QueryKey,
    UserControllerGetUserCountParams["cursorId"]
  > = ({ signal, pageParam }) =>
    userControllerGetUserCount(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserCount>>,
    TError,
    TData,
    Awaited<ReturnType<typeof userControllerGetUserCount>>,
    QueryKey,
    UserControllerGetUserCountParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type UserControllerGetUserCountInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserCount>>
>;
export type UserControllerGetUserCountInfiniteQueryError = AxiosError<unknown>;

export const useUserControllerGetUserCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof userControllerGetUserCount>>,
    UserControllerGetUserCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUserCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof userControllerGetUserCount>>,
        QueryKey,
        UserControllerGetUserCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetUserCountInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserControllerGetUserCountQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUserCount>>,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUserCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUserCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserCount>>
  > = ({ signal }) =>
    userControllerGetUserCount(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerGetUserCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserCount>>
>;
export type UserControllerGetUserCountQueryError = AxiosError<unknown>;

export const useUserControllerGetUserCount = <
  TData = Awaited<ReturnType<typeof userControllerGetUserCount>>,
  TError = AxiosError<unknown>
>(
  params: UserControllerGetUserCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetUserCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userControllerGetOneUser = (
  firebaseUserId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneUserResponseDto>> => {
  return axios.get(`/users/${firebaseUserId}`, options);
};

export const getUserControllerGetOneUserQueryKey = (firebaseUserId: string) => {
  return [`/users/${firebaseUserId}`] as const;
};

export const getUserControllerGetOneUserInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof userControllerGetOneUser>>>,
  TError = AxiosError<unknown>
>(
  firebaseUserId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof userControllerGetOneUser>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetOneUserQueryKey(firebaseUserId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetOneUser>>
  > = ({ signal }) =>
    userControllerGetOneUser(firebaseUserId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!firebaseUserId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof userControllerGetOneUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerGetOneUserInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetOneUser>>
>;
export type UserControllerGetOneUserInfiniteQueryError = AxiosError<unknown>;

export const useUserControllerGetOneUserInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof userControllerGetOneUser>>>,
  TError = AxiosError<unknown>
>(
  firebaseUserId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof userControllerGetOneUser>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetOneUserInfiniteQueryOptions(
    firebaseUserId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserControllerGetOneUserQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetOneUser>>,
  TError = AxiosError<unknown>
>(
  firebaseUserId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetOneUser>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetOneUserQueryKey(firebaseUserId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetOneUser>>
  > = ({ signal }) =>
    userControllerGetOneUser(firebaseUserId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!firebaseUserId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetOneUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserControllerGetOneUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetOneUser>>
>;
export type UserControllerGetOneUserQueryError = AxiosError<unknown>;

export const useUserControllerGetOneUser = <
  TData = Awaited<ReturnType<typeof userControllerGetOneUser>>,
  TError = AxiosError<unknown>
>(
  firebaseUserId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetOneUser>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserControllerGetOneUserQueryOptions(
    firebaseUserId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userControllerDeleteAttachment = (
  userId: number,
  attachmentId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/users/${userId}/attachments/${attachmentId}`, options);
};

export const getUserControllerDeleteAttachmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerDeleteAttachment>>,
    TError,
    { userId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerDeleteAttachment>>,
  TError,
  { userId: number; attachmentId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerDeleteAttachment>>,
    { userId: number; attachmentId: number }
  > = (props) => {
    const { userId, attachmentId } = props ?? {};

    return userControllerDeleteAttachment(userId, attachmentId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerDeleteAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerDeleteAttachment>>
>;

export type UserControllerDeleteAttachmentMutationError = AxiosError<unknown>;

export const useUserControllerDeleteAttachment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerDeleteAttachment>>,
    TError,
    { userId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerDeleteAttachment>>,
  TError,
  { userId: number; attachmentId: number },
  TContext
> => {
  const mutationOptions =
    getUserControllerDeleteAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const userControllerUpdateUser = (
  userId: number,
  updateUserV2Dto: UpdateUserV2Dto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserControllerUpdateUser200>> => {
  return axios.patch(`/users/${userId}`, updateUserV2Dto, options);
};

export const getUserControllerUpdateUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUser>>,
    TError,
    { userId: number; data: UpdateUserV2Dto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerUpdateUser>>,
  TError,
  { userId: number; data: UpdateUserV2Dto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateUser>>,
    { userId: number; data: UpdateUserV2Dto }
  > = (props) => {
    const { userId, data } = props ?? {};

    return userControllerUpdateUser(userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerUpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateUser>>
>;
export type UserControllerUpdateUserMutationBody = UpdateUserV2Dto;
export type UserControllerUpdateUserMutationError = AxiosError<unknown>;

export const useUserControllerUpdateUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUser>>,
    TError,
    { userId: number; data: UpdateUserV2Dto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerUpdateUser>>,
  TError,
  { userId: number; data: UpdateUserV2Dto },
  TContext
> => {
  const mutationOptions = getUserControllerUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const userControllerUpdateUserVendor = (
  userId: number,
  updateUserVendorDto: UpdateUserVendorDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/users/${userId}/vendor`, updateUserVendorDto, options);
};

export const getUserControllerUpdateUserVendorMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUserVendor>>,
    TError,
    { userId: number; data: UpdateUserVendorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerUpdateUserVendor>>,
  TError,
  { userId: number; data: UpdateUserVendorDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateUserVendor>>,
    { userId: number; data: UpdateUserVendorDto }
  > = (props) => {
    const { userId, data } = props ?? {};

    return userControllerUpdateUserVendor(userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerUpdateUserVendorMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateUserVendor>>
>;
export type UserControllerUpdateUserVendorMutationBody = UpdateUserVendorDto;
export type UserControllerUpdateUserVendorMutationError = AxiosError<unknown>;

export const useUserControllerUpdateUserVendor = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUserVendor>>,
    TError,
    { userId: number; data: UpdateUserVendorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerUpdateUserVendor>>,
  TError,
  { userId: number; data: UpdateUserVendorDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerUpdateUserVendorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const userControllerRemoveUserVendor = (
  userId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/users/${userId}/vendor`, options);
};

export const getUserControllerRemoveUserVendorMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerRemoveUserVendor>>,
    TError,
    { userId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerRemoveUserVendor>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerRemoveUserVendor>>,
    { userId: number }
  > = (props) => {
    const { userId } = props ?? {};

    return userControllerRemoveUserVendor(userId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerRemoveUserVendorMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerRemoveUserVendor>>
>;

export type UserControllerRemoveUserVendorMutationError = AxiosError<unknown>;

export const useUserControllerRemoveUserVendor = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerRemoveUserVendor>>,
    TError,
    { userId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerRemoveUserVendor>>,
  TError,
  { userId: number },
  TContext
> => {
  const mutationOptions =
    getUserControllerRemoveUserVendorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const userControllerUpdateUserProjects = (
  userId: number,
  updateUserProjectDto: UpdateUserProjectDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/users/${userId}/projects`,
    updateUserProjectDto,
    options
  );
};

export const getUserControllerUpdateUserProjectsMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUserProjects>>,
    TError,
    { userId: number; data: UpdateUserProjectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerUpdateUserProjects>>,
  TError,
  { userId: number; data: UpdateUserProjectDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateUserProjects>>,
    { userId: number; data: UpdateUserProjectDto }
  > = (props) => {
    const { userId, data } = props ?? {};

    return userControllerUpdateUserProjects(userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerUpdateUserProjectsMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateUserProjects>>
>;
export type UserControllerUpdateUserProjectsMutationBody = UpdateUserProjectDto;
export type UserControllerUpdateUserProjectsMutationError = AxiosError<unknown>;

export const useUserControllerUpdateUserProjects = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUserProjects>>,
    TError,
    { userId: number; data: UpdateUserProjectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerUpdateUserProjects>>,
  TError,
  { userId: number; data: UpdateUserProjectDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerUpdateUserProjectsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const userControllerUpdateUserRole = (
  userId: number,
  updateUserRoleDto: UpdateUserRoleDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/users/${userId}/roles`, updateUserRoleDto, options);
};

export const getUserControllerUpdateUserRoleMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUserRole>>,
    TError,
    { userId: number; data: UpdateUserRoleDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerUpdateUserRole>>,
  TError,
  { userId: number; data: UpdateUserRoleDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateUserRole>>,
    { userId: number; data: UpdateUserRoleDto }
  > = (props) => {
    const { userId, data } = props ?? {};

    return userControllerUpdateUserRole(userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerUpdateUserRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateUserRole>>
>;
export type UserControllerUpdateUserRoleMutationBody = UpdateUserRoleDto;
export type UserControllerUpdateUserRoleMutationError = AxiosError<unknown>;

export const useUserControllerUpdateUserRole = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateUserRole>>,
    TError,
    { userId: number; data: UpdateUserRoleDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerUpdateUserRole>>,
  TError,
  { userId: number; data: UpdateUserRoleDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerUpdateUserRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
