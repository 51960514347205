import { useEffect, useRef, useState } from "react";
import AppButton from "./AppButton";
import { Camera, Delete, FileImage } from "lucide-react";
import { cn } from "../lib/utils";
import { Attachment } from "../api/model";

interface AppCameraFieldFilesProps {
  uploadedPhotos: Attachment[];
  onDeleteUploadedPhoto: (att: Attachment) => Promise<void>;
  photos: File[];
  label: string;
  onChange: (files: File[]) => void;
  onDelete: (file: File) => void;
  error?: boolean;
  helperText?: string;
}

export default function AppCameraFieldFile({
  photos,
  label,
  onChange,
  onDelete,
  error,
  helperText,
  uploadedPhotos,
  onDeleteUploadedPhoto,
}: AppCameraFieldFilesProps) {
  const cameraRef = useRef(null);
  const galleryRef = useRef(null);

  const onGalleryClicked = () => {
    (galleryRef.current as any).click();
  };

  const onCameraClicked = () => {
    (cameraRef.current as any).click();
  };

  const onObtainedPhotos = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileArray = [];
    const fileList = event.target.files;
    if (!fileList) return;
    for (let i = 0; i < fileList.length; i++) {
      fileArray.push(fileList[i]);
    }
    onChange([...photos, ...fileArray]);
  };

  const onDeletePhoto = (file: File) => {
    onDelete(file);
  };

  return (
    <div className="flex flex-col gap-4">
      <p>{label}</p>
      {uploadedPhotos && uploadedPhotos.length > 0 && (
        <div className="flex flex-col gap-6">
          {uploadedPhotos.map((att, idx) => (
            <div className="relative" key={idx}>
              <img
                className="w-full rounded-xl object-cover shadow-xl"
                height="300"
                src={att.url}
                key={idx}
              />
              <AppButton
                label="Delete"
                className="absolute top-4 right-4 rounded-full bg-neutral-900"
                onClick={() => {
                  onDeleteUploadedPhoto(att);
                }}
                icon={<Delete className="text-red-600" />}
              />
            </div>
          ))}
        </div>
      )}
      {photos && photos.length > 0 && (
        <div className="flex flex-col gap-6">
          {photos.map((pho, idx) => {
            return (
              <div className="relative" key={idx}>
                <img
                  className="w-full rounded-xl object-cover shadow-xl"
                  height="300"
                  src={URL.createObjectURL(pho)}
                  key={idx}
                />
                <AppButton
                  label="Delete"
                  className="absolute top-4 right-4 rounded-full bg-neutral-900"
                  onClick={() => {
                    onDeletePhoto(pho);
                  }}
                  icon={<Delete className="text-red-600" />}
                />
              </div>
            );
          })}
        </div>
      )}
      <input
        ref={cameraRef}
        type="file"
        capture="user"
        onChange={onObtainedPhotos}
        className="hidden"
        multiple
        accept=".jpg, .jpeg, .png"
      />
      <input
        data-testid={`gallery-${label}`}
        id={`gallery-${label}`}
        ref={galleryRef}
        type="file"
        onChange={onObtainedPhotos}
        className="hidden"
        multiple
        accept=".jpg, .jpeg, .png"
      />
      <div className="flex gap-2">
        <AppButton
          className={cn("flex-grow", {
            "border-red-500 border-2 text-red-500": !!error,
          })}
          label="Camera"
          variant="outline"
          icon={<Camera />}
          onClick={onCameraClicked}
        />
        <AppButton
          className={cn("flex-grow", {
            "border-red-500 border-2 text-red-500": !!error,
          })}
          label="Gallery"
          variant="outline"
          icon={<FileImage />}
          onClick={onGalleryClicked}
        />
      </div>
      {error && (
        <div className="text-red-500 font-sans text-xs">{helperText}</div>
      )}
    </div>
  );
}
