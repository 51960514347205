import { useSearchParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import AppTitle from "../HomeScreen/components/AppTitle";
import {
  DRAWER_CREATE_STATE,
  TNB_BILL_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import TnbBillReadingsTab from "./tabs/TnbBillReadingsTab";
import TnbBillGraphTab from "./tabs/TnbBillGraphTab";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { CerevModule } from "../../api/model";

export default function TnbBillScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const { currentTabIdx, setCurrentTabIdx, handleChange } =
  //   useAppTabsNavigation({
  //     searchParamStr: "tnbBillTabIdx",
  //   });

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "tnbBillTabIdx",
    defaultValue: "0",
  });
  return (
    <ModuleAccessGuard module={CerevModule.TNB_BILLS}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4">
          <AppTitle title="TNB Bills" />
          <AppButton
            label="+ Add"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", TNB_BILL_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
        <Tabs value={currentTabIdx} onValueChange={onTabChange}>
          <TabsList className="mb-3">
            <TabsTrigger value={"0"}>
              <p>TNB Graphs</p>
            </TabsTrigger>
            <TabsTrigger value={"1"}>
              <p>Readings</p>
            </TabsTrigger>
          </TabsList>
          <TabsContent value={"0"}>
            <TnbBillGraphTab />
          </TabsContent>
          <TabsContent value={"1"}>
            <TnbBillReadingsTab />
          </TabsContent>
        </Tabs>
      </div>
    </ModuleAccessGuard>
  );
}
