import { useSearchParams } from "react-router-dom";
import { useDefectControllerGetOneDefect } from "../../../../api/work-orders-defects/work-orders-defects";
import { useHasWorkOrderEditAfterClosePolicy } from "../../../../hooks/usePolicies/usePolicies";

interface isCloseAndEditAfterWorkOrderGuardProps {
    children: React.ReactNode;
  }
  
  export default function IsCloseAndEditAfterWorkOrderGuard({
    children
  }: isCloseAndEditAfterWorkOrderGuardProps) {
    const [searchParam] = useSearchParams();
    const workOrderId = searchParam.get("workOrderId");
    const { data: workOrder } = useDefectControllerGetOneDefect(
      workOrderId as string,
      {
        query: {
          enabled: !!workOrderId
        }
      }
    );
    const isClose = workOrder?.data?.defectStatus?.name === "Close";
    const hasEditAfterClosePolicy = useHasWorkOrderEditAfterClosePolicy();
  
    if (isClose && !hasEditAfterClosePolicy) return <></>;
    return <>{children}</>;
  }