import { ColumnDef, Row } from "@tanstack/react-table";
import { GetWorkRequestsResponseDto } from "../../../../redux/slices/OpenApi/cerevApi";
import AppMultiImage from "../../../../components/AppMultiImage";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  WORK_REQUEST_DETAIL_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import useDate from "../../../../hooks/useDate";
import { AppRatings } from "../../../../components/rating/AppRatings";

const WorkRequestTitleNav = ({
  wr,
}: {
  wr: Row<GetWorkRequestsResponseDto>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", WORK_REQUEST_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("workRequestId", wr.getValue("id"));
          return p;
        });
      }}
    >
      {wr.getValue("title") ?? "-"}
    </div>
  );
};

export const workRequestColumns: ColumnDef<GetWorkRequestsResponseDto>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "title",
    header: "Title",
    cell: ({ row }) => <WorkRequestTitleNav wr={row} />,
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "contactNo",
    header: "Contact No.",
  },
  {
    accessorKey: "createdOn",
    header: "Created On",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <p>
          {formatDateToLocal({
            dateStr: row.getValue("createdOn"),
            formatting: "dd MMM yy, hh:mm a",
          })}
        </p>
      );
    },
  },
  {
    accessorKey: "photos",
    header: "Photos",
    cell: ({ row }) => (
      <AppMultiImage attachments={row.getValue("photos")} hideMoreThanThree />
    ),
  },
];

export const workRequestColumnsWithRating: ColumnDef<GetWorkRequestsResponseDto>[] =
  [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "title",
      header: "Title",
      cell: ({ row }) => <WorkRequestTitleNav wr={row} />,
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "contactNo",
      header: "Contact No.",
    },
    {
      accessorKey: "createdOn",
      header: "Created On",
      cell: ({ row }) => {
        const { formatDateToLocal } = useDate();
        return (
          <p>
            {formatDateToLocal({
              dateStr: row.getValue("createdOn"),
              formatting: "dd MMM yy, hh:mm a",
            })}
          </p>
        );
      },
    },
    {
      accessorKey: "photos",
      header: "Photos",
      cell: ({ row }) => (
        <AppMultiImage attachments={row.getValue("photos")} hideMoreThanThree />
      ),
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: ({ row }) => {
        const rating = row.getValue("rating") as string;
        if(rating){
        return <AppRatings 
          rating={rating ? +rating : 0}
        />
        } else {
          return <p className="text-xs text-slate-400">No Ratings</p>
        }
      },
    },
  ];
