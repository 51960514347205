import React, { useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser/useCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { CerevLogo } from "../../icons/CerevLogo";
import { Eye, EyeOff, Lock } from "lucide-react";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AppTextField from "../../components/AppTextField";
import AppButton from "../../components/AppButton";
import { useAdminControllerFirstTimePasswordChange } from "../../api/administration/administration";
import { auth } from "../../firebase/firebaseConfig";
import { Button } from "../../components/ui/button";
import { useQueryClient } from "@tanstack/react-query";

const firstTimeLoginSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "Must contain at least 8 characters" }),
    confirmPassword: z
      .string()
      .min(8, { message: "Must contain at least 8 characters" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  })
  .refine(
    (data) => {
      const numericRegex = /[0-9]/;
      return numericRegex.test(data.password);
    },
    {
      message: "Must contain at least ONE numeric character",
      path: ["password"],
    }
  );

export type FirstTimeLoginFormType = z.infer<typeof firstTimeLoginSchema>;

export default function FirstTimeLoginScreen() {
  const qc = useQueryClient();
  const [passVisible, setPassVisible] = useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = useState(false);

  const user = useCurrentUser();
  const location = useLocation();
  const nav = useNavigate();
  const from = location.state?.from ?? "/home";
  const methods = useForm<FirstTimeLoginFormType>({
    resolver: zodResolver(firstTimeLoginSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const { mutate, isPending } = useAdminControllerFirstTimePasswordChange({
    mutation: {
      onSuccess: async () => {
        await qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("auth"),
        });
        nav("/home");
      },
    },
  });

  const onSubmit: SubmitHandler<FirstTimeLoginFormType> = (data) => {
    if (!user?.data?.firebaseUserId || !user?.data?.id) return;
    mutate({
      data: {
        firebaseUserId: user?.data?.firebaseUserId ?? "-",
        newPassword: data.password,
        userId: user?.data?.id?.toString() ?? "-",
      },
    });
  };

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center space-y-4">
      <div className="bg-primary-200 p-4 rounded-xl">
        <Lock className="text-primary-900" />
      </div>
      <h1 className="text-3xl font-bold">New Password</h1>
      <p>Please set a new password for your account</p>
      <div className="bg-slate-50 rounded-md py-2 px-4 font-bold text-primary-900">
        {user?.data?.email ?? "-"}
      </div>
      <FormProvider {...methods}>
        <div className="relative">
          <AppTextField
            name="password"
            className="min-w-[300px]"
            label="Password"
            type={passVisible ? "text" : "password"}
          />
          <Button
            className="absolute right-0 p-4 top-8"
            variant="ghost"
            onClick={() => {
              setPassVisible((o) => !o);
            }}
          >
            {passVisible ? (
              <EyeOff className="text-primary-900" />
            ) : (
              <Eye className="text-primary-900" />
            )}
          </Button>
        </div>
        <div className="relative">
          <AppTextField
            name="confirmPassword"
            className="min-w-[300px]"
            label="Confirm Password"
            type={confirmPassVisible ? "text" : "password"}
          />
          <Button
            className="absolute right-0 p-4 top-8"
            variant="ghost"
            onClick={() => {
              setConfirmPassVisible((o) => !o);
            }}
          >
            {confirmPassVisible ? (
              <EyeOff className="text-primary-900" />
            ) : (
              <Eye className="text-primary-900" />
            )}
          </Button>
        </div>
      </FormProvider>
      <AppButton
        className="min-w-[300px]"
        label="Confirm"
        onClick={methods.handleSubmit(onSubmit)}
        isLoading={isPending}
      />
      <AppButton
        variant="outline"
        className="border-red-500 text-red-500 absolute bottom-10 hover:bg-red-50 hover:text-red-600"
        label="Not your account? Sign out here"
        onClick={() => {
          auth.signOut();
        }}
      />
    </div>
  );
}
