import { QrCode } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import {
  DRAWER_CREATE_STATE,
  WORK_REQUEST_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { useLazyWorkRequestControllerGetWorkRequestQrCodeQuery } from "../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../redux/store";
import WorkRequestTabWithCount from "./components/WorkRequestTabWithCount";
import WRSearchAndFilter from "./components/WRSearchAndFilter";
import WorkRequestTab from "./tabs/WorkRequestTab";
import WRPrintQRCode from "./components/WRPrintQRCode";
import { CerevModule } from "../../api/model";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";

export const WORK_REQUEST_NEW_STATUS = "NEW";
export const WORK_REQUEST_IN_PROGRESS_STATUS = "IN_PROGRESS";
export const WORK_REQUEST_COMPLETED_STATUS = "COMPLETED";
export const WORK_REQUEST_CANCELLED_STATUS = "CANCELLED";

// Added dummy id to be identifiable when deleting in other use cases
const allWorkRequestStatuses = [
  { name: WORK_REQUEST_NEW_STATUS, label: "New" },
  { name: WORK_REQUEST_IN_PROGRESS_STATUS, label: "In Progress" },
  { name: WORK_REQUEST_COMPLETED_STATUS, label: "Completed" },
  { name: WORK_REQUEST_CANCELLED_STATUS, label: "Cancelled" },
];

export default function WorkRequestScreen() {
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "workRequestTabIdx",
    defaultValue: allWorkRequestStatuses[0].name,
  });

  const [trigger, result] =
    useLazyWorkRequestControllerGetWorkRequestQrCodeQuery();

  return (
    <ModuleAccessGuard module={CerevModule.WORK_REQUEST}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4 w-full  items-center justify-between mb-2">
          <div className="flex gap-4">
            <h1 className="font-sans text-3xl font-bold">Work Requests</h1>
            <AppButton
              label="+ Add"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", WORK_REQUEST_FORM_DRAWER);
                  p.set("drawerState", DRAWER_CREATE_STATE);
                  return p;
                });
              }}
            />
          </div>
          <div className="flex gap-2">
            <WRPrintQRCode />
          </div>
        </div>
        <FormProvider {...methods}>
          <WRSearchAndFilter />
          <Tabs
            value={currentTabIdx}
            defaultValue={allWorkRequestStatuses[0].name}
            onValueChange={onTabChange}
            className="mb-4"
          >
            <ScrollArea className="rounded-md">
              <TabsList>
                {allWorkRequestStatuses.map((status, idx) => (
                  <TabsTrigger key={status.name} value={status.name}>
                    <WorkRequestTabWithCount workRequestStatus={status} />
                  </TabsTrigger>
                ))}
              </TabsList>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
            {allWorkRequestStatuses.map((sta, idx) => {
              return (
                <TabsContent key={sta.name} value={sta.name} className="pt-2">
                  <WorkRequestTab workRequestStatus={sta.name as any} />
                </TabsContent>
              );
            })}
          </Tabs>
        </FormProvider>
      </div>
    </ModuleAccessGuard>
  );
}
