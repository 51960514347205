import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Divider } from "./Divider";

export function SparePartDetailSection() {
  return (
    <View style={{ gap: 2 }}>
      <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
        D. Spare Parts Details
      </Text>
      <Divider />
      <View
        style={{
          border: 1,
          borderColor: "#94a3b8",
          paddingHorizontal: 4,
          paddingVertical: 8,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <View style={{ display: "flex", gap: 4, flexDirection: "row" }}>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>Part Number</Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Part Description
            </Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Issued Type (D/I)
            </Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Material Unit
            </Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Spare Part Cost
            </Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Cost (If necessary)
            </Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Special Equipment
            </Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Quantity Used
            </Text>
          </View>
          <View
            style={{
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 4,
              paddingVertical: 8,
              textAlign: "center",
              flex: 1,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 8 }}>
              Quantity Returned
            </Text>
          </View>
        </View>
        <View
          style={{
            border: 1,
            borderColor: "#94a3b8",
            flex: 1,
            marginTop: 4,
            display: "flex",
            flexDirection: "row",
            paddingHorizontal: 4,
            paddingVertical: 8,
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
            **D=Direct Issue, I=Inventory
          </Text>
        </View>
      </View>
    </View>
  );
}
