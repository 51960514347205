/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type PtwFormatType = (typeof PtwFormatType)[keyof typeof PtwFormatType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PtwFormatType = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  BOOLEAN: "BOOLEAN",
  ATTACHMENT: "ATTACHMENT",
  IMAGE: "IMAGE",
  SINGLE_OPTION: "SINGLE_OPTION",
  MULTI_OPTION: "MULTI_OPTION",
  HEADING: "HEADING",
  DESCRIPTION: "DESCRIPTION",
  DIVIDER: "DIVIDER",
  DATE: "DATE",
} as const;
