/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type AddPmTemplateDtoRepeatType =
  (typeof AddPmTemplateDtoRepeatType)[keyof typeof AddPmTemplateDtoRepeatType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddPmTemplateDtoRepeatType = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
} as const;
