import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { DataTable } from "../../../components/react-table/DataTable";
import useDebounce from "../../../hooks/useDebounce";
import { useAppSelector } from "../../../redux/store";
import { useVendorFilterStore } from "../../../stores/vendorFilterStore.ts/vendorFilterStore";
import { vendorColumns } from "./components/VendorColumns";
import AppTextField from "../../../components/AppTextField";
import {
  useVendorControllerGetVendors,
  useVendorControllerGetVendorsCount,
} from "../../../api/vendor/vendor";
import {
  VendorControllerGetVendorsCountParams,
  VendorControllerGetVendorsParams,
} from "../../../api/model";
import AppSearchAndFilter from "../../../components/searchAndFilter/AppSearchAndFilter";
import { VENDOR_FILTER_DRAWER } from "../../../components/drawers/AppDrawer";

export default function VendorTab() {
  const [, setSearchParams] = useSearchParams();
  const workscopesFilters = useVendorFilterStore((state) => state.workscopes);
  const filterCount = useVendorFilterStore((state) => state.getFilterCount);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const search = useWatch({ control: methods.control, name: "search" });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<VendorControllerGetVendorsParams>({
    value: {
      companyId: activeComp?.id.toString() ?? "",
      workscopeIds: workscopesFilters.map((ws) => ws.id.toString()),
      search,
      rowsPerPage: pagination.pageSize,
      page: pagination.pageIndex,
    },
    delay: 500,
  });

  const queryStatusArg = useDebounce<VendorControllerGetVendorsCountParams>({
    value: {
      companyId: activeComp?.id.toString() ?? "",
      workscopeIds: workscopesFilters.map((ws) => ws.id.toString()),
      search,
    },
    delay: 500,
  });

  const {
    data: vendors,
    isLoading,
    isFetching,
  } = useVendorControllerGetVendors(queryArg, {
    query: {
      enabled: !!activeComp,
    },
  });

  const { data: vendorCount } = useVendorControllerGetVendorsCount(
    queryStatusArg,
    {
      query: {
        enabled: !!activeComp,
      },
    }
  );

  const table = useReactTable({
    data: vendors?.data ?? [],
    columns: vendorColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: vendorCount?.data
      ? Math.ceil(vendorCount?.data / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <AppSearchAndFilter
          onFilterClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", VENDOR_FILTER_DRAWER);
              return p;
            });
          }}
          placeholder="Ref No, Subject, Asset..."
          filterCount={filterCount()}
        />
        <DataTable
          isLoading={isLoading}
          isFetching={isFetching}
          table={table}
          totalCount={vendorCount?.data ?? 0}
          rowsPerPage={pagination.pageSize}
          pageIdx={pagination.pageIndex}
        />
      </div>
    </FormProvider>
  );
}
