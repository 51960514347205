/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type AttachmentType =
  (typeof AttachmentType)[keyof typeof AttachmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttachmentType = {
  DEFAULT: "DEFAULT",
  PRIVATE: "PRIVATE",
} as const;
