import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Defect, EscalationLog, PmChecklist, WorkRequest } from "../../../../../../api/model";
import { format, isValid, parseISO } from "date-fns";
import AppButton from "../../../../../AppButton";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../../ui/dialog";
import { nestedWorkOrderColumns } from "./nestedWorkOrderColumns";
import { DataTable } from "../../../../../react-table/DataTable";
import { nestedPmColumns } from "./nestedPmColumns";
import { nestedWrColumns } from "./nestedWrColumns";

export const escalationLogColumns: ColumnDef<EscalationLog>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "createdOn",
    header: "Timestamp",
    cell: ({ row }) => {
      const date = row.getValue("createdOn");
      const formattedDate = isValid(parseISO(date as string))
        ? format(new Date(date as string), "MMM dd, h:mm a")
        : "-";
      return <p>{formattedDate}</p>;
    },
  },
  {
    accessorKey: "workOrders",
    header: "Highlighted work orders",
    cell: ({ row }) => {
      const noCount = (row.getValue("workOrders") as Defect[]).length;
      const [open, setOpen] = useState(false);
      const table = useReactTable({
        columns: nestedWorkOrderColumns,
        data: (row.getValue("workOrders") as Defect[]) ?? [],
        getCoreRowModel: getCoreRowModel(),
        state: {
          columnVisibility: {
            id: false,
          },
        },
      });
      return (
        <>
          <AppButton
            label={`${noCount} nos. work orders`}
            variant="outline"
            onClick={() => {
              setOpen(true);
            }}
          />
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="overflow-y-scroll max-h-screen">
              <DialogHeader>
                <DialogTitle>Work Orders</DialogTitle>
              </DialogHeader>
              <DataTable table={table} totalCount={noCount} />
            </DialogContent>
          </Dialog>
        </>
      );
    },
  },
  {
    accessorKey: "pmChecklists",
    header: "Highlighted PMs",
    cell: ({ row }) => {
      const noCount = (row.getValue("pmChecklists") as PmChecklist[]).length;
      const [open, setOpen] = useState(false);
      const table = useReactTable({
        columns: nestedPmColumns,
        data: (row.getValue("pmChecklists") as PmChecklist[]) ?? [],
        getCoreRowModel: getCoreRowModel(),
        state: {
          columnVisibility: {
            id: false,
          },
        },
      });
      return (
        <>
          <AppButton
            label={`${noCount} nos. PMs`}
            variant="outline"
            onClick={() => {
              setOpen(true);
            }}
          />
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>PMs Escalated</DialogTitle>
              </DialogHeader>
              <DataTable table={table} totalCount={noCount} />
            </DialogContent>
          </Dialog>
        </>
      );
    },
  },
  {
    accessorKey: "workRequests",
    header: "Highlighted Work Requests",
    cell: ({ row }) => {
      const noCount = (row.getValue("workRequests") as WorkRequest[]).length;
      const [open, setOpen] = useState(false);
      const table = useReactTable({
        columns: nestedWrColumns,
        data: (row.getValue("workRequests") as WorkRequest[]) ?? [],
        getCoreRowModel: getCoreRowModel(),
        state: {
          columnVisibility: {
            id: false,
          },
        },
      });
      return (
        <>
          <AppButton
            label={`${noCount} nos. Work Requests`}
            variant="outline"
            onClick={() => {
              setOpen(true);
            }}
          />
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Work Requests Escalated</DialogTitle>
              </DialogHeader>
              <DataTable table={table} totalCount={noCount} />
            </DialogContent>
          </Dialog>
        </>
      );
    },
  },
];
