import { TreeItem } from "@mui/x-tree-view";
import {
  GetLocationResponseDto,
  useLocationControllerGetLocationQuery,
  Location,
} from "../../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../../redux/store";
import { Typography } from "@mui/material";

interface LocationFilterTreeItemProps {
  loc: GetLocationResponseDto | Location;
  onSelect: (loc: GetLocationResponseDto | Location) => void;
  showFirstLevelOnly?: boolean; // To determine whether to show the first level only
}

export default function LocationFilterTreeItem({
  loc,
  onSelect,
  showFirstLevelOnly,
}: LocationFilterTreeItemProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: locations } = useLocationControllerGetLocationQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
      locationId: loc.id.toString(),
    },
    {
      skip: !activeProj,
    }
  );

  return (
    <TreeItem
      nodeId={loc.id.toString()}
      label={
        <div
          className="p-1 rounded-sm flex w-full items-between"
          onClick={() => {
            onSelect(loc);
          }}
        >
          <p>{loc.name}</p>
        </div>
      }
    >
      {showFirstLevelOnly
        ? null
        : locations?.map((child) => (
            <LocationFilterTreeItem
              loc={child}
              key={child.id}
              onSelect={onSelect}
            />
          ))}
    </TreeItem>
  );
}
