import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import AppButton from "../../components/AppButton";
import AppTextField from "../../components/AppTextField";
import { auth } from "../../firebase/firebaseConfig";
import { CerevLogo } from "../../icons/CerevLogo";
import { Button } from "../../components/ui/button";
import { Eye, EyeOff } from "lucide-react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ForgotPasswordText from "./component/ForgotPassword";

const loginSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  password: z
    .string()
    .min(6, { message: "Password must be at least 6 characters" }),
});

type SignInFormProp = z.infer<typeof loginSchema>;

export default function LoginScreen() {
  const [passVisible, setPassVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<SignInFormProp>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const getErrorMsg = (error: string) => {
    if (error.includes("invalid-email")) {
      return "The user do not have an valid email.";
    } else if (error.includes("wrong-password")) {
      return "Either email or the password is wrong.";
    } else {
      return "Either email or the password is wrong.";
    }
  };

  const onSignIn: SubmitHandler<SignInFormProp> = async (data) => {
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, data.email, data.password);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      toast.error("Error", {
        description: getErrorMsg(e.toString()),
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="w-screen h-screen bg-primary-900 flex flex-col items-center bg-login  bg-center bg-no-repeat bg-cover">
        <div className="h-full flex flex-col items-start bg-white justify-center gap-6 w-full max-w-md px-12">
          <div className="flex flex-col gap-3 mb-8">
            <div className="w-24 h-24">
              <CerevLogo />
            </div>
            <p className="text-primary font-sans text-4xl font-bold">
              Cerev CMMS
            </p>
            <p className="text-primary font-sans text-slate-400">
              CMMS that follows you
            </p>
          </div>
          <AppTextField
            name="email"
            className="w-full"
            placeholder="Email"
            data-testid="email-field"
          />
          <div className="relative w-full">
            <AppTextField
              data-testid="password-field"
              type={passVisible ? "text" : "password"}
              name="password"
              className="w-full"
              placeholder="Password"
            />
            <Button
              className="absolute right-0 p-4 top-0"
              variant="ghost"
              onClick={() => {
                setPassVisible((o) => !o);
              }}
            >
              {passVisible ? (
                <EyeOff className="text-primary-900" />
              ) : (
                <Eye className="text-primary-900" />
              )}
            </Button>
            <ForgotPasswordText />
          </div>

          <AppButton
            id="login-button"
            label="Login"
            isLoading={isLoading}
            onClick={methods.handleSubmit(onSignIn)}
            className="w-full rounded-xl font-medium text-lg font-sans py-2"
          />
        </div>
      </div>
    </FormProvider>
  );
}
