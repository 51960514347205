import { useAsync } from "react-use";
import { useEffect } from "react";

import { proxy, wrap } from "comlink";
import type { WorkerType } from "./workers/work-order-summary-pdf.worker";
import Worker from "./workers/work-order-summary-pdf.worker?worker";
import useDate from "../../hooks/useDate";

export const workOrderSummaryPdfWorkder = wrap<WorkerType>(new Worker());
workOrderSummaryPdfWorkder.onProgress(proxy((info: any) => console.log(info)));

export const useRenderWorkOrderSummaryPdf = ({
  workOrders,
  generatedDateStr
}: Parameters<WorkerType["renderWorkOrderSummaryPdfInWorker"]>[0]) => {
  const { formatDateToLocal } = useDate();
  const {
    value: url,
    loading,
    error,
  } = useAsync(async () => {
    return workOrderSummaryPdfWorkder.renderWorkOrderSummaryPdfInWorker({
      workOrders,
      generatedDateStr: generatedDateStr,
    });
  }, [workOrders]);

  useEffect(() => (url ? () => URL.revokeObjectURL(url) : undefined), [url]);
  return { url, loading, error };
};
