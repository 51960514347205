import { ColumnDef, Row } from "@tanstack/react-table";
import { WorkScope } from "../../../api/model";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_UPDATE_STATE,
  WORK_SCOPE_FORM_DRAWER,
} from "../../../components/drawers/AppDrawer";

const WorkscopeNameNav = ({ workscope }: { workscope: Row<WorkScope> }) => {
  const [, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", WORK_SCOPE_FORM_DRAWER);
          p.set("drawerState", DRAWER_UPDATE_STATE);
          p.set("workScopeId", workscope.getValue("id")?.toString() ?? "");
          return p;
        });
      }}
    >
      {workscope.getValue("name") ?? "-"}
    </div>
  );
};

export const workscopeColumn: ColumnDef<WorkScope>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <WorkscopeNameNav workscope={row} />,
  },
];
