import { Navigate } from "react-router-dom";
import DrawerFormSkeleton from "../components/skeletons/DrawerFormSkeleton";
import { useFeatures } from "../hooks/useFeatures";
import { useAppSelector } from "../redux/store";

export default function CustomPtwProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { CUSTOM_PTW, isLoading } = useFeatures({
    projectId: activeProj?.id?.toString() ?? "",
  });

  if (isLoading) return <DrawerFormSkeleton />;

  if (CUSTOM_PTW) return <>{children}</>;

  return <Navigate to="/home/403" replace />;
}
