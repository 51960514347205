import { Download, Loader2 } from "lucide-react";
import { useAssetControllerGetFullAssets } from "../../api/assets/assets";
import { useProjectControllerFindOneProject } from "../../api/projects/projects";
import AppButton from "../../components/AppButton";
import { CerevLogo } from "../../icons/CerevLogo";
import useQRCodeRender from "../../pdf/render-optimization/useQRCodeRender";
import { useRenderPdf } from "../../pdf/render-optimization/useRenderPdf";
import { PDFQrSetting } from "../../pdf/render-optimization/workers/pdf.worker";
import { useAppSelector } from "../../redux/store";
import { A9CerevPdfQrProps } from "./pdf/A9CerevPdfQr";

export default function AssetQrScreen() {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data, isLoading } = useAssetControllerGetFullAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        refetchOnWindowFocus: false,
        enabled: !!activeProj,
        select: (res) => {
          return res.data;
        },
      },
    }
  );

  const { data: projectData } = useProjectControllerFindOneProject(
    activeProj?.id?.toString() ?? "",
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  const {
    data: assetData,
    loading,
    error,
  } = useQRCodeRender({
    assetWithType: data,
    size: "A6",
    includeLocation: true, // To determine later on whether to add this dynamic toggle feature or not
  });

  if (!assetData)
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Fetching your data...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );

  return (
    <RenderedPDFDownloader
      equipmentList={assetData}
      pdfQrSetting={{
        customLogoIconUrl: projectData?.pdfLogo[0]?.url,
        isCustomPdfQrEnabled:
          projectData?.customWorkOrderQrLogoEnabled ?? false,
        customProjectName: projectData?.name,
      }}
    />
  );
}

const RenderedPDFDownloader = ({
  equipmentList,
  pdfQrSetting,
}: {
  equipmentList: A9CerevPdfQrProps[][];
  pdfQrSetting: PDFQrSetting;
}) => {
  const {
    url,
    loading: pdfLoading,
    error: pdfError,
  } = useRenderPdf({ equipmentList, size: "A6", pdfQrSetting });

  if (pdfLoading)
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Compiling your PDF...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );
  if (pdfError)
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="text-red-500">Sorry, Something went wrong.. </div>
      </div>
    );

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="h-20 w-20 mb-2">
        <CerevLogo />
      </div>
      <p className="font-bold text-2xl mb-6">Cerev</p>
      <p className="text-neutral-800">
        Your PDF is ready, click below to download
      </p>
      <a href={url} download="Cerev Equipment QR">
        <AppButton
          icon={<Download />}
          label="Download PDF"
          className="mt-4"
          variant="outline"
        />
      </a>
    </div>
  );
};
