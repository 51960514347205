import { useEffect } from "react";
import { useGetAnalyticsQuery } from "../../redux/slices/Analytic/AnalyticApi"

export default function AnalyticScreen(){

    const {data} = useGetAnalyticsQuery({projectId: '1'});

    useEffect(() => {
    }, [data])

    return <div>This is the analytic screen</div>
}