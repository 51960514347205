/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  PmChecklistStatus,
  PmChecklistStatusControllerGetAllPmChecklistStatusParams,
  PmChecklistStatusControllerGetPmChecklistStatusParams,
} from ".././model";

export const pmChecklistStatusControllerGetPmChecklistStatus = (
  params: PmChecklistStatusControllerGetPmChecklistStatusParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/pm-checklist-status`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistStatusControllerGetPmChecklistStatusQueryKey = (
  params: PmChecklistStatusControllerGetPmChecklistStatusParams
) => {
  return [`/pm-checklist-status`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistStatusControllerGetPmChecklistStatusInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
      >,
      PmChecklistStatusControllerGetPmChecklistStatusParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: PmChecklistStatusControllerGetPmChecklistStatusParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
          >,
          QueryKey,
          PmChecklistStatusControllerGetPmChecklistStatusParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistStatusControllerGetPmChecklistStatusQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
      >,
      QueryKey,
      PmChecklistStatusControllerGetPmChecklistStatusParams["cursorId"]
    > = ({ signal, pageParam }) =>
      pmChecklistStatusControllerGetPmChecklistStatus(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
      >,
      QueryKey,
      PmChecklistStatusControllerGetPmChecklistStatusParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type PmChecklistStatusControllerGetPmChecklistStatusInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>>
  >;
export type PmChecklistStatusControllerGetPmChecklistStatusInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistStatusControllerGetPmChecklistStatusInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>>,
    PmChecklistStatusControllerGetPmChecklistStatusParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistStatusControllerGetPmChecklistStatusParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
        >,
        QueryKey,
        PmChecklistStatusControllerGetPmChecklistStatusParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistStatusControllerGetPmChecklistStatusInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistStatusControllerGetPmChecklistStatusQueryOptions = <
  TData = Awaited<
    ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistStatusControllerGetPmChecklistStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistStatusControllerGetPmChecklistStatusQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>>
  > = ({ signal }) =>
    pmChecklistStatusControllerGetPmChecklistStatus(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistStatusControllerGetPmChecklistStatusQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>>
  >;
export type PmChecklistStatusControllerGetPmChecklistStatusQueryError =
  AxiosError<unknown>;

export const usePmChecklistStatusControllerGetPmChecklistStatus = <
  TData = Awaited<
    ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistStatusControllerGetPmChecklistStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistStatusControllerGetPmChecklistStatus>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistStatusControllerGetPmChecklistStatusQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistStatusControllerGetAllPmChecklistStatus = (
  params: PmChecklistStatusControllerGetAllPmChecklistStatusParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PmChecklistStatus[]>> => {
  return axios.get(`/pm-checklist-status/all`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistStatusControllerGetAllPmChecklistStatusQueryKey = (
  params: PmChecklistStatusControllerGetAllPmChecklistStatusParams
) => {
  return [`/pm-checklist-status/all`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistStatusControllerGetAllPmChecklistStatusInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
      >,
      PmChecklistStatusControllerGetAllPmChecklistStatusParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: PmChecklistStatusControllerGetAllPmChecklistStatusParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof pmChecklistStatusControllerGetAllPmChecklistStatus
            >
          >,
          TError,
          TData,
          Awaited<
            ReturnType<
              typeof pmChecklistStatusControllerGetAllPmChecklistStatus
            >
          >,
          QueryKey,
          PmChecklistStatusControllerGetAllPmChecklistStatusParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistStatusControllerGetAllPmChecklistStatusQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
      >,
      QueryKey,
      PmChecklistStatusControllerGetAllPmChecklistStatusParams["cursorId"]
    > = ({ signal, pageParam }) =>
      pmChecklistStatusControllerGetAllPmChecklistStatus(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
      >,
      QueryKey,
      PmChecklistStatusControllerGetAllPmChecklistStatusParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type PmChecklistStatusControllerGetAllPmChecklistStatusInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
    >
  >;
export type PmChecklistStatusControllerGetAllPmChecklistStatusInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistStatusControllerGetAllPmChecklistStatusInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
    >,
    PmChecklistStatusControllerGetAllPmChecklistStatusParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistStatusControllerGetAllPmChecklistStatusParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
        >,
        QueryKey,
        PmChecklistStatusControllerGetAllPmChecklistStatusParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistStatusControllerGetAllPmChecklistStatusInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistStatusControllerGetAllPmChecklistStatusQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
    >,
    TError = AxiosError<unknown>
  >(
    params: PmChecklistStatusControllerGetAllPmChecklistStatusParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof pmChecklistStatusControllerGetAllPmChecklistStatus
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistStatusControllerGetAllPmChecklistStatusQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
      >
    > = ({ signal }) =>
      pmChecklistStatusControllerGetAllPmChecklistStatus(params, {
        signal,
        ...axiosOptions,
      });

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type PmChecklistStatusControllerGetAllPmChecklistStatusQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
    >
  >;
export type PmChecklistStatusControllerGetAllPmChecklistStatusQueryError =
  AxiosError<unknown>;

export const usePmChecklistStatusControllerGetAllPmChecklistStatus = <
  TData = Awaited<
    ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistStatusControllerGetAllPmChecklistStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistStatusControllerGetAllPmChecklistStatus>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistStatusControllerGetAllPmChecklistStatusQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
