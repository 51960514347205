export const WORK_PERMIT_NEW_STATUS = "NEW";
export const WORK_PERMIT_APPROVED_STATUS = "APPROVED";
export const WORK_PERMIT_REJECTED_STATUS = "REJECTED";

export const WORK_PERMIT_STASUSES = [
    {
        name: "New",
        value: WORK_PERMIT_NEW_STATUS
    },
    {
        name: "Approved",
        value: WORK_PERMIT_APPROVED_STATUS
    },
    {
        name: "Rejected",
        value: WORK_PERMIT_REJECTED_STATUS
    }
]