/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateWorkPermitDto,
  GetWorkPermitQrCodeResponseDto,
  GetWorkPermitsResponseDto,
  UpdateWorkPermitStatusDto,
  WorkPermitWithWorkersResponseDto,
  WorkPermitsControllerGetWorkPermitCountParams,
  WorkPermitsControllerGetWorkPermitQrCodeRequestParams,
  WorkPermitsControllerGetWorkPermitsParams,
} from ".././model";

export const workPermitsControllerGetWorkPermitCount = (
  params: WorkPermitsControllerGetWorkPermitCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.get(`/work-permits/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkPermitsControllerGetWorkPermitCountQueryKey = (
  params: WorkPermitsControllerGetWorkPermitCountParams
) => {
  return [`/work-permits/count`, ...(params ? [params] : [])] as const;
};

export const getWorkPermitsControllerGetWorkPermitCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
    WorkPermitsControllerGetWorkPermitCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
        QueryKey,
        WorkPermitsControllerGetWorkPermitCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkPermitsControllerGetWorkPermitCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
    QueryKey,
    WorkPermitsControllerGetWorkPermitCountParams["cursorId"]
  > = ({ signal, pageParam }) =>
    workPermitsControllerGetWorkPermitCount(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
    TError,
    TData,
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
    QueryKey,
    WorkPermitsControllerGetWorkPermitCountParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type WorkPermitsControllerGetWorkPermitCountInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>
  >;
export type WorkPermitsControllerGetWorkPermitCountInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkPermitsControllerGetWorkPermitCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
    WorkPermitsControllerGetWorkPermitCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
        QueryKey,
        WorkPermitsControllerGetWorkPermitCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkPermitsControllerGetWorkPermitCountInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkPermitsControllerGetWorkPermitCountQueryOptions = <
  TData = Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkPermitsControllerGetWorkPermitCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>
  > = ({ signal }) =>
    workPermitsControllerGetWorkPermitCount(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkPermitsControllerGetWorkPermitCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>
>;
export type WorkPermitsControllerGetWorkPermitCountQueryError =
  AxiosError<unknown>;

export const useWorkPermitsControllerGetWorkPermitCount = <
  TData = Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkPermitsControllerGetWorkPermitCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workPermitsControllerCreateWorkPermit = (
  createWorkPermitDto: CreateWorkPermitDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/work-permits`, createWorkPermitDto, options);
};

export const getWorkPermitsControllerCreateWorkPermitMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workPermitsControllerCreateWorkPermit>>,
    TError,
    { data: CreateWorkPermitDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workPermitsControllerCreateWorkPermit>>,
  TError,
  { data: CreateWorkPermitDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workPermitsControllerCreateWorkPermit>>,
    { data: CreateWorkPermitDto }
  > = (props) => {
    const { data } = props ?? {};

    return workPermitsControllerCreateWorkPermit(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkPermitsControllerCreateWorkPermitMutationResult = NonNullable<
  Awaited<ReturnType<typeof workPermitsControllerCreateWorkPermit>>
>;
export type WorkPermitsControllerCreateWorkPermitMutationBody =
  CreateWorkPermitDto;
export type WorkPermitsControllerCreateWorkPermitMutationError =
  AxiosError<unknown>;

export const useWorkPermitsControllerCreateWorkPermit = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workPermitsControllerCreateWorkPermit>>,
    TError,
    { data: CreateWorkPermitDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workPermitsControllerCreateWorkPermit>>,
  TError,
  { data: CreateWorkPermitDto },
  TContext
> => {
  const mutationOptions =
    getWorkPermitsControllerCreateWorkPermitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workPermitsControllerGetWorkPermits = (
  params: WorkPermitsControllerGetWorkPermitsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkPermitsResponseDto[]>> => {
  return axios.get(`/work-permits`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkPermitsControllerGetWorkPermitsQueryKey = (
  params: WorkPermitsControllerGetWorkPermitsParams
) => {
  return [`/work-permits`, ...(params ? [params] : [])] as const;
};

export const getWorkPermitsControllerGetWorkPermitsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
    WorkPermitsControllerGetWorkPermitsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
        QueryKey,
        WorkPermitsControllerGetWorkPermitsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkPermitsControllerGetWorkPermitsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
    QueryKey,
    WorkPermitsControllerGetWorkPermitsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    workPermitsControllerGetWorkPermits(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
    TError,
    TData,
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
    QueryKey,
    WorkPermitsControllerGetWorkPermitsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type WorkPermitsControllerGetWorkPermitsInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>>;
export type WorkPermitsControllerGetWorkPermitsInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkPermitsControllerGetWorkPermitsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
    WorkPermitsControllerGetWorkPermitsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
        QueryKey,
        WorkPermitsControllerGetWorkPermitsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkPermitsControllerGetWorkPermitsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkPermitsControllerGetWorkPermitsQueryOptions = <
  TData = Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkPermitsControllerGetWorkPermitsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>
  > = ({ signal }) =>
    workPermitsControllerGetWorkPermits(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkPermitsControllerGetWorkPermitsQueryResult = NonNullable<
  Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>
>;
export type WorkPermitsControllerGetWorkPermitsQueryError = AxiosError<unknown>;

export const useWorkPermitsControllerGetWorkPermits = <
  TData = Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetWorkPermits>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkPermitsControllerGetWorkPermitsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workPermitsControllerGetWorkPermitQrCodeRequest = (
  params: WorkPermitsControllerGetWorkPermitQrCodeRequestParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkPermitQrCodeResponseDto>> => {
  return axios.get(`/work-permits/qrCode`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkPermitsControllerGetWorkPermitQrCodeRequestQueryKey = (
  params: WorkPermitsControllerGetWorkPermitQrCodeRequestParams
) => {
  return [`/work-permits/qrCode`, ...(params ? [params] : [])] as const;
};

export const getWorkPermitsControllerGetWorkPermitQrCodeRequestInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
      >,
      WorkPermitsControllerGetWorkPermitQrCodeRequestParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkPermitsControllerGetWorkPermitQrCodeRequestParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
          >,
          QueryKey,
          WorkPermitsControllerGetWorkPermitQrCodeRequestParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkPermitsControllerGetWorkPermitQrCodeRequestQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
      >,
      QueryKey,
      WorkPermitsControllerGetWorkPermitQrCodeRequestParams["cursorId"]
    > = ({ signal, pageParam }) =>
      workPermitsControllerGetWorkPermitQrCodeRequest(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
      >,
      QueryKey,
      WorkPermitsControllerGetWorkPermitQrCodeRequestParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type WorkPermitsControllerGetWorkPermitQrCodeRequestInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>>
  >;
export type WorkPermitsControllerGetWorkPermitQrCodeRequestInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkPermitsControllerGetWorkPermitQrCodeRequestInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>>,
    WorkPermitsControllerGetWorkPermitQrCodeRequestParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitQrCodeRequestParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
        >,
        QueryKey,
        WorkPermitsControllerGetWorkPermitQrCodeRequestParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkPermitsControllerGetWorkPermitQrCodeRequestInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkPermitsControllerGetWorkPermitQrCodeRequestQueryOptions = <
  TData = Awaited<
    ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitQrCodeRequestParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkPermitsControllerGetWorkPermitQrCodeRequestQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>>
  > = ({ signal }) =>
    workPermitsControllerGetWorkPermitQrCodeRequest(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkPermitsControllerGetWorkPermitQrCodeRequestQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>>
  >;
export type WorkPermitsControllerGetWorkPermitQrCodeRequestQueryError =
  AxiosError<unknown>;

export const useWorkPermitsControllerGetWorkPermitQrCodeRequest = <
  TData = Awaited<
    ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkPermitsControllerGetWorkPermitQrCodeRequestParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workPermitsControllerGetWorkPermitQrCodeRequest>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkPermitsControllerGetWorkPermitQrCodeRequestQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workPermitsControllerGetOneWorkPermit = (
  workPermitId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkPermitWithWorkersResponseDto>> => {
  return axios.get(`/work-permits/${workPermitId}`, options);
};

export const getWorkPermitsControllerGetOneWorkPermitQueryKey = (
  workPermitId: string
) => {
  return [`/work-permits/${workPermitId}`] as const;
};

export const getWorkPermitsControllerGetOneWorkPermitInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>
  >,
  TError = AxiosError<unknown>
>(
  workPermitId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkPermitsControllerGetOneWorkPermitQueryKey(workPermitId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>
  > = ({ signal }) =>
    workPermitsControllerGetOneWorkPermit(workPermitId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workPermitId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkPermitsControllerGetOneWorkPermitInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>
  >;
export type WorkPermitsControllerGetOneWorkPermitInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkPermitsControllerGetOneWorkPermitInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>
  >,
  TError = AxiosError<unknown>
>(
  workPermitId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkPermitsControllerGetOneWorkPermitInfiniteQueryOptions(
      workPermitId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkPermitsControllerGetOneWorkPermitQueryOptions = <
  TData = Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
  TError = AxiosError<unknown>
>(
  workPermitId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkPermitsControllerGetOneWorkPermitQueryKey(workPermitId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>
  > = ({ signal }) =>
    workPermitsControllerGetOneWorkPermit(workPermitId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workPermitId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkPermitsControllerGetOneWorkPermitQueryResult = NonNullable<
  Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>
>;
export type WorkPermitsControllerGetOneWorkPermitQueryError =
  AxiosError<unknown>;

export const useWorkPermitsControllerGetOneWorkPermit = <
  TData = Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
  TError = AxiosError<unknown>
>(
  workPermitId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workPermitsControllerGetOneWorkPermit>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkPermitsControllerGetOneWorkPermitQueryOptions(
    workPermitId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workPermitsControllerDeleteWorkPermit = (
  workPermitId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/work-permits/${workPermitId}`, options);
};

export const getWorkPermitsControllerDeleteWorkPermitMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workPermitsControllerDeleteWorkPermit>>,
    TError,
    { workPermitId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workPermitsControllerDeleteWorkPermit>>,
  TError,
  { workPermitId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workPermitsControllerDeleteWorkPermit>>,
    { workPermitId: string }
  > = (props) => {
    const { workPermitId } = props ?? {};

    return workPermitsControllerDeleteWorkPermit(workPermitId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkPermitsControllerDeleteWorkPermitMutationResult = NonNullable<
  Awaited<ReturnType<typeof workPermitsControllerDeleteWorkPermit>>
>;

export type WorkPermitsControllerDeleteWorkPermitMutationError =
  AxiosError<unknown>;

export const useWorkPermitsControllerDeleteWorkPermit = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workPermitsControllerDeleteWorkPermit>>,
    TError,
    { workPermitId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workPermitsControllerDeleteWorkPermit>>,
  TError,
  { workPermitId: string },
  TContext
> => {
  const mutationOptions =
    getWorkPermitsControllerDeleteWorkPermitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workPermitsControllerUpdateWorkPermitStatus = (
  workPermitId: string,
  updateWorkPermitStatusDto: UpdateWorkPermitStatusDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/work-permits/${workPermitId}/status`,
    updateWorkPermitStatusDto,
    options
  );
};

export const getWorkPermitsControllerUpdateWorkPermitStatusMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workPermitsControllerUpdateWorkPermitStatus>>,
    TError,
    { workPermitId: string; data: UpdateWorkPermitStatusDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workPermitsControllerUpdateWorkPermitStatus>>,
  TError,
  { workPermitId: string; data: UpdateWorkPermitStatusDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workPermitsControllerUpdateWorkPermitStatus>>,
    { workPermitId: string; data: UpdateWorkPermitStatusDto }
  > = (props) => {
    const { workPermitId, data } = props ?? {};

    return workPermitsControllerUpdateWorkPermitStatus(
      workPermitId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkPermitsControllerUpdateWorkPermitStatusMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workPermitsControllerUpdateWorkPermitStatus>>
  >;
export type WorkPermitsControllerUpdateWorkPermitStatusMutationBody =
  UpdateWorkPermitStatusDto;
export type WorkPermitsControllerUpdateWorkPermitStatusMutationError =
  AxiosError<unknown>;

export const useWorkPermitsControllerUpdateWorkPermitStatus = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workPermitsControllerUpdateWorkPermitStatus>>,
    TError,
    { workPermitId: string; data: UpdateWorkPermitStatusDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workPermitsControllerUpdateWorkPermitStatus>>,
  TError,
  { workPermitId: string; data: UpdateWorkPermitStatusDto },
  TContext
> => {
  const mutationOptions =
    getWorkPermitsControllerUpdateWorkPermitStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
