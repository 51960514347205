import { useSearchParams } from "react-router-dom";
import { useStocksControllerGetOneStockWithLedger } from "../../../../../api/stocks-inventory/stocks-inventory";
import { Avatar, AvatarFallback } from "../../../../ui/avatar";
import useDate from "../../../../../hooks/useDate";
import { DataTable } from "../../../../react-table/DataTable";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { inventoryLedgerColumns } from "./InventoryLedgerColumns";
import { Separator } from "../../../../ui/separator";
import { useInventoryLocationEnabled } from "../../../../../hooks/useFeatures";
import { truncate } from "lodash";

export default function InventoryLedgerSection() {
  const [searchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");
  const inventoryLocEnabled = useInventoryLocationEnabled();
  const {
    data: inventoryData,
    isFetching,
    isLoading,
  } = useStocksControllerGetOneStockWithLedger(inventoryId ?? "", {
    query: {
      enabled: !!inventoryId,
      select: (res) => res.data,
    },
  });

  const table = useReactTable({
    data: inventoryData?.ledgers ?? [],
    columns: inventoryLedgerColumns.filter((l) =>
      inventoryLocEnabled ? truncate : l.id !== "locationName"
    ),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
        warningBalance: false,
      },
    },
  });

  return (
    <>
      <div className="flex flex-col gap-4">
        <p className="text-xl font-semibold">Inventory Logs</p>
        <DataTable
          isLoading={isLoading}
          isFetching={isFetching}
          table={table}
          totalCount={table.getTotalSize()}
        />
      </div>
    </>
  );
}
