/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type FeatureType = (typeof FeatureType)[keyof typeof FeatureType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureType = {
  SCHEDULER: "SCHEDULER",
  NOTIFICATION: "NOTIFICATION",
  BIM: "BIM",
  SERIALIZED_INVENTORY: "SERIALIZED_INVENTORY",
  CUSTOM_PTW: "CUSTOM_PTW",
  PDF_REPORT: "PDF_REPORT",
  WR_SAVE_USER_INFO: "WR_SAVE_USER_INFO",
  WR_RATING: "WR_RATING",
  MAN_HOURS: "MAN_HOURS",
  SIGNATORY: "SIGNATORY",
  WO_PROFIT_LOSS: "WO_PROFIT_LOSS",
  INVENTORY_LOCATION: "INVENTORY_LOCATION",
  MODULE_ACCESS: "MODULE_ACCESS",
  PRIVATE_ATTACHMENT: "PRIVATE_ATTACHMENT",
  ESCALATION: "ESCALATION",
  EXTRA_FIELD_PRICING: "EXTRA_FIELD_PRICING",
  SUB_EQUIPMENT: "SUB_EQUIPMENT",
} as const;
