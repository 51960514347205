import { useSearchParams } from "react-router-dom";
import { useWorkRequestFilterStore } from "../../../stores/workRequest/workRequestFilterStore";
import AppButton from "../../AppButton";
import { AppDateRangePicker } from "../../AppDateRangePicker";
import SelectLocationFilterDialog from "../../dialogs/SelectLocationFilterDialog/SelectLocationFilterDialog";

export default function WorkRequestFilterDrawer() {
  const [, setSearchParams] = useSearchParams();
  const filterLocation = useWorkRequestFilterStore(
    (state) => state.filterLocation
  );
  const { setFilterLocation, cleanUp, setDateRange } =
    useWorkRequestFilterStore((state) => state.actions);
  const dateRange = useWorkRequestFilterStore((state) => state.dateRange);

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-sans text-2xl font-bold">Filter By</h2>
      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Location</p>
        <SelectLocationFilterDialog
          showFirstLevelOnly
          currentFilter={filterLocation}
          onApplyFilter={(location) => {
            setFilterLocation(location);
          }}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Date Range</p>
        <AppDateRangePicker
          date={dateRange}
          setDate={setDateRange}
          onClear={() => {
            setDateRange(undefined);
          }}
        />
      </div>

      <div className="flex gap-2">
        <AppButton
          label="Confirm"
          onClick={() => {
            setSearchParams(() => {
              return new URLSearchParams();
            });
          }}
        />
        <AppButton
          label="Clear"
          variant="outline"
          onClick={() => {
            cleanUp();
          }}
        />
      </div>
    </div>
  );
}
