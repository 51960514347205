import * as React from "react";
import { View, Text } from "@react-pdf/renderer";

interface ItemSectionProps {
  label: string;
  value: string;
  labelWidth?: string;
  valueWidth?: string;
}

export function ItemSection({
  label,
  value,
  labelWidth = "40%",
  valueWidth = "60%"
}: ItemSectionProps) {
  return (
    <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
      <View
        style={{
          width: labelWidth,
          border: 1,
          borderColor: "#94a3b8",
          padding: 4,
          margin: 1,
        }}
      >
        <Text style={{ fontWeight: "bold", fontSize: 8 }}>{label}</Text>
      </View>
      <View
        style={{
          width: valueWidth,
          fontSize: 8,
          border: 1,
          borderColor: "#94a3b8",
          padding: 4,
          margin: 1,
        }}
      >
        <Text>{value}</Text>
      </View>
    </View>
  );
}
