import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface WRUserInfoStoreState {
  email: string;
  contactNo: string;
  actions: {
    saveUserInfo: (dto: { email: string; contactNo: string }) => void;
    resetUserInfo: () => void;
  };
}

export const useWRUserInfoStore = create<WRUserInfoStoreState>()(
  persist(
    (set) => ({
      email: "",
      contactNo: "",
      actions: {
        saveUserInfo: (dto) => {
          return set(() => ({
            email: dto.email,
            contactNo: dto.contactNo,
          }));
        },
        resetUserInfo: () => {
          return set(() => ({
            email: "",
            contactNo: "",
          }));
        },
      },
    }),
    {
      name: "work-request-user-info", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        email: state.email,
        contactNo: state.contactNo,
      }),
    }
  )
);

export const useWRUserInfoActions = () =>
  useWRUserInfoStore((state) => state.actions);
