import { useSearchParams } from "react-router-dom";
import {
  useWorkPermitsControllerGetOneWorkPermitQuery,
  useWorkPermitsControllerUpdateWorkPermitStatusMutation,
} from "../../../redux/slices/OpenApi/cerevApi";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import AppItemInfo from "../../AppItemInfo";
import useDate from "../../../hooks/useDate";
import AppButton from "../../AppButton";
import useSuccess from "../../../hooks/useSuccess";
import useError from "../../../hooks/useError";
import {
  AlignLeft,
  Calendar,
  Mail,
  MapPin,
  Phone,
  Tag,
  Users2,
} from "lucide-react";
import AppWorkPermitStatusChip from "./components/WorkPermitStatusChip";

export default function WorkPermitDetailDrawer() {
  const { formatDateToLocal } = useDate();
  const [searchParams, setSearchParams] = useSearchParams();
  const workPermitId = searchParams.get("workPermitId");

  const { data: workPermit, isLoading } =
    useWorkPermitsControllerGetOneWorkPermitQuery(
      {
        workPermitId: workPermitId ?? "",
      },
      {
        skip: !workPermitId,
      }
    );

  const [
    updateWorkPermitStatus,
    {
      isLoading: updateWorkPermitStatusIsLoading,
      isSuccess: updateWorkPermitStatusIsSuccess,
      isError: updateWorkPmermitStatusIsError,
      error: updateWorkPermitStatusError,
    },
  ] = useWorkPermitsControllerUpdateWorkPermitStatusMutation();

  const handleApprove = () => {
    updateWorkPermitStatus({
      workPermitId: workPermitId ?? "",
      updateWorkPermitStatusDto: {
        workPermitStatus: "APPROVED",
      },
    });
  };

  const handleReject = () => {
    updateWorkPermitStatus({
      workPermitId: workPermitId ?? "",
      updateWorkPermitStatusDto: {
        workPermitStatus: "REJECTED",
      },
    });
  };

  useSuccess({ dependencies: [updateWorkPermitStatusIsSuccess] });
  useError({
    dependencies: [
      {
        isError: updateWorkPmermitStatusIsError,
        error: updateWorkPermitStatusError,
      },
    ],
  });

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <p className="font-sans text-2xl font-bold">
        {workPermit?.applierCompanyName ?? "-"}
      </p>
      <AppWorkPermitStatusChip
        workPermitStatus={workPermit?.workPermitStatus}
      />
      <AppItemInfo
        label="Company Name"
        icon={<Tag className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit?.applierCompanyName}
          </p>
        }
      />
      <AppItemInfo
        label="Email"
        icon={<Mail className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit?.email ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Tel No"
        icon={<Phone className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit?.telNo ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Description"
        icon={<AlignLeft className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit?.description ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Start Date"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit
              ? formatDateToLocal({
                  dateStr: workPermit.workStartDate,
                  formatting: "d MMM yy",
                })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="End Date"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit
              ? formatDateToLocal({
                  dateStr: workPermit.workEndDate,
                  formatting: "d MMM yy",
                })
              : "-"}
          </p>
        }
      />

      <AppItemInfo
        label="Location"
        icon={<MapPin className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit?.locationOfWork ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Workers"
        icon={<Users2 className="text-primary-900" />}
        content={
          <div className="flex flex-col gap-2">
            {workPermit?.workers?.map((worker) => (
              <p className="font-sans text-neutral-900" key={worker.id}>
                {worker?.name} - {worker?.icNo}
              </p>
            ))}
          </div>
        }
      />
      <AppItemInfo
        label="Created on"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workPermit
              ? formatDateToLocal({
                  dateStr: workPermit.workEndDate,
                  formatting: "d MMM yy",
                })
              : "-"}
          </p>
        }
      />
      <div className="flex gap-4">
        <AppButton
          isLoading={isLoading}
          label="Approve"
          onClick={handleApprove}
        />
        <AppButton
          isLoading={isLoading}
          label="Reject"
          onClick={handleReject}
          variant="destructive"
        />
      </div>
    </div>
  );
}
