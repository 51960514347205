import { useNavigate } from "react-router-dom";
import AppButton from "../../../../../components/AppButton";
import { ShieldCheck } from "lucide-react";
import { useIsSuperadmin } from "../../../../../hooks/useRoles";

export default function SuperAdminBtn() {
  const nav = useNavigate();
  const isSuperAdmin = useIsSuperadmin();

  if (!isSuperAdmin) return <></>;

  return (
    <AppButton
      data-testid="superadmin-btn"
      variant="outline"
      icon={<ShieldCheck />}
      onClick={() => {
        nav("super-admin");
      }}
    />
  );
}
