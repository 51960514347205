/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetManHourSettingResponseDto,
  UpdateManHourSettingRequestDto,
} from ".././model";

export const manHourSettingControllerGetManHourSetting = (
  projectId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetManHourSettingResponseDto>> => {
  return axios.get(`/man-hour-setting/${projectId}`, options);
};

export const getManHourSettingControllerGetManHourSettingQueryKey = (
  projectId: number
) => {
  return [`/man-hour-setting/${projectId}`] as const;
};

export const getManHourSettingControllerGetManHourSettingInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>
    >,
    TError = AxiosError<unknown>
  >(
    projectId: number,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getManHourSettingControllerGetManHourSettingQueryKey(projectId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>
    > = ({ signal }) =>
      manHourSettingControllerGetManHourSetting(projectId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!projectId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ManHourSettingControllerGetManHourSettingInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>
  >;
export type ManHourSettingControllerGetManHourSettingInfiniteQueryError =
  AxiosError<unknown>;

export const useManHourSettingControllerGetManHourSettingInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getManHourSettingControllerGetManHourSettingInfiniteQueryOptions(
      projectId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getManHourSettingControllerGetManHourSettingQueryOptions = <
  TData = Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
  TError = AxiosError<unknown>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getManHourSettingControllerGetManHourSettingQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>
  > = ({ signal }) =>
    manHourSettingControllerGetManHourSetting(projectId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ManHourSettingControllerGetManHourSettingQueryResult = NonNullable<
  Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>
>;
export type ManHourSettingControllerGetManHourSettingQueryError =
  AxiosError<unknown>;

export const useManHourSettingControllerGetManHourSetting = <
  TData = Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
  TError = AxiosError<unknown>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof manHourSettingControllerGetManHourSetting>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManHourSettingControllerGetManHourSettingQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const manHourSettingControllerUpsertManHourSetting = (
  projectId: number,
  updateManHourSettingRequestDto: UpdateManHourSettingRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/man-hour-setting/${projectId}`,
    updateManHourSettingRequestDto,
    options
  );
};

export const getManHourSettingControllerUpsertManHourSettingMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manHourSettingControllerUpsertManHourSetting>>,
    TError,
    { projectId: number; data: UpdateManHourSettingRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof manHourSettingControllerUpsertManHourSetting>>,
  TError,
  { projectId: number; data: UpdateManHourSettingRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof manHourSettingControllerUpsertManHourSetting>>,
    { projectId: number; data: UpdateManHourSettingRequestDto }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return manHourSettingControllerUpsertManHourSetting(
      projectId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ManHourSettingControllerUpsertManHourSettingMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof manHourSettingControllerUpsertManHourSetting>>
  >;
export type ManHourSettingControllerUpsertManHourSettingMutationBody =
  UpdateManHourSettingRequestDto;
export type ManHourSettingControllerUpsertManHourSettingMutationError =
  AxiosError<unknown>;

export const useManHourSettingControllerUpsertManHourSetting = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manHourSettingControllerUpsertManHourSetting>>,
    TError,
    { projectId: number; data: UpdateManHourSettingRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof manHourSettingControllerUpsertManHourSetting>>,
  TError,
  { projectId: number; data: UpdateManHourSettingRequestDto },
  TContext
> => {
  const mutationOptions =
    getManHourSettingControllerUpsertManHourSettingMutationOptions(options);

  return useMutation(mutationOptions);
};
