import { Row } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import {
  SnackBarType,
  setOpenSnackBar,
} from "../../../../redux/slices/RootSlice";
import { useAppDispatch } from "../../../../redux/store";
import { DRAWER_UPDATE_STATE, ESCALATION_DETAIL_DRAWER, ESCALATION_FORM_DRAWER } from "../../AppDrawer";

interface EscalationNameCellProps {
  escalation: Row<any>;
}

export default function EscalationNameCell({
  escalation,
}: EscalationNameCellProps) {
  const [, setSearchParams] = useSearchParams();

  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", ESCALATION_DETAIL_DRAWER);
          // p.set("drawerState", DRAWER_UPDATE_STATE);
          p.set("escalationId", escalation.getValue("id") ?? "-");
          return p;
        });
      }}
    >
      {escalation.getValue("name") ?? "-"}
    </div>
  );
}
