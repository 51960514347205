import { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";

export default function useCommonTable() {
  const methods = useFormContext<{ search: string }>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const search = useWatch({ control: methods.control, name: "search" });

  return {
    methods,
    pagination,
    setPagination,
    search,
  };
}
