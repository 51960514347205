import { PROJECT_FORM_DRAWER } from "./../../components/drawers/AppDrawer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnalyticApi } from "./Analytic/AnalyticApi";
import { AuthApi } from "./Auth/AuthApi";
import { cerevApi, Company, Project } from "./OpenApi/cerevApi";

export enum SnackBarType {
  Success = "success",
  Error = "error",
  Info = "info",
}

export enum DialogType {
  WorkOrderConfirmClose = "WorkOrderConfirmClose",
}

const initialState = {
  activeProject: null,
  activeCompany: null,
  drawerDetail: null,
  isMinimizedMainDrawer: false,
  firebaseUserId: null,
  showSnackbar: false,
  snackBarMsg: "",
  snackbarStatus: SnackBarType.Success,
  isDialogOpen: false,
  dialogType: DialogType.WorkOrderConfirmClose,
  dialogTitle: "",
} as {
  activeProject: Project | null;
  activeCompany: Company | null;
  drawerDetail: string | null;
  isMinimizedMainDrawer: boolean;
  firebaseUserId: string | null;
  showSnackbar: boolean;
  snackBarMsg: string;
  snackbarStatus: SnackBarType;
  isDialogOpen: boolean;
  dialogType: DialogType;
  dialogTitle: string;
};

export const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setActiveProject: (state, action: PayloadAction<Project | null>) => {
      state.activeProject = action.payload;
    },
    setActiveCompany: (
      state,
      action: PayloadAction<{ comp: Company | null; proj: Project | null }>
    ) => {
      state.activeCompany = action.payload.comp;
      state.activeProject = action.payload.proj;
    },
    maxOrMinimizeMainDrawer: (state) => {
      state.isMinimizedMainDrawer = !state.isMinimizedMainDrawer;
    },
    maximizeDrawer: (state) => {
      state.isMinimizedMainDrawer = true;
    },
    minimizeDrawer: (state) => {
      state.isMinimizedMainDrawer = false;
    },
    setFirebaseUserId: (state, action: PayloadAction<string | null>) => {
      state.firebaseUserId = action.payload;
    },
    clearSession: (state) => {
      state.activeProject = null;
      state.activeCompany = null;
      state.firebaseUserId = null;
    },
    setOpenSnackBar: (
      state,
      action: PayloadAction<{ msg: string; type: SnackBarType }>
    ) => {
      state.showSnackbar = true;
      state.snackBarMsg = action.payload.msg;
      state.snackbarStatus = action.payload.type;
    },
    setCloseSnackBar: (state) => {
      state.showSnackbar = false;
    },
    setOpenDialog: (
      state,
      action: PayloadAction<{ title: string; type: DialogType }>
    ) => {
      state.dialogTitle = action.payload.title;
      state.dialogType = action.payload.type;
      state.isDialogOpen = true;
    },
    setCloseDialog: (state) => {
      state.isDialogOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      AuthApi.endpoints.getUserData.matchFulfilled,
      (state, { payload }) => {
        if (!state.activeProject) state.activeProject = payload.projects[0];
      }
    );
    builder.addMatcher(
      AuthApi.endpoints.getUserData.matchFulfilled,
      (state, { payload }) => {
        if (!state.activeCompany) state.activeCompany = payload.companies[0];
      }
    );
    // builder.addMatcher(
    //   cerevApi.endpoints.companiesControllerCreateCompany.matchFulfilled,
    //   (state, action) => {
    //     state.isOpenDrawer = false;
    //   }
    // );
    // builder.addMatcher(
    //   cerevApi.endpoints.companiesControllerCreateProject.matchFulfilled,
    //   (state, action) => {
    //     state.isOpenDrawer = false;
    //   }
    // )
  },
});

export const {
  setActiveProject,
  setActiveCompany,
  maxOrMinimizeMainDrawer,
  setFirebaseUserId,
  clearSession,
  setOpenSnackBar,
  setCloseSnackBar,
  setOpenDialog,
  setCloseDialog,
  minimizeDrawer,
  maximizeDrawer
} = RootSlice.actions;
