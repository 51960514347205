import { format } from "date-fns";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CerevModule } from "../../api/model";
import AppTextField from "../../components/AppTextField";
import DrawerFormSkeleton from "../../components/skeletons/DrawerFormSkeleton";
import { Card } from "../../components/ui/card";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import useDebounce from "../../hooks/useDebounce";
import { useMeterControllerGetMetersQuery } from "../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import MeterReadingGraph from "./components/MeterReadingGraph";

export default function MeterScreen() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string; month: string }>({
    defaultValues: {
      search: "",
      month: format(new Date(), "yyyy-MM"),
    },
  });
  const search = useWatch({ control: methods.control, name: "search" });
  const month = useWatch({ control: methods.control, name: "month" });

  const queryArg = useDebounce({
    value: {
      projectId: activeProj?.id ?? 0,
      search: search,
    },
    delay: 500,
  });

  const { data: meters, isFetching } = useMeterControllerGetMetersQuery(
    queryArg,
    {
      skip: !activeProj,
    }
  );

  return (
    <ModuleAccessGuard module={CerevModule.METERS}>
      <div className="flex flex-col">
        <AppTitle title="Meters" />
        <FormProvider {...methods}>
          <Card className="flex flex-col gap-6 p-10  rounded-xl ">
            <div className="flex justify-between items-center">
              <AppTextField name="search" placeholder="Search meter name" />
              <AppTextField type="month" name="month" />
            </div>
            {isFetching ? (
              <DrawerFormSkeleton />
            ) : (
              meters?.map((m) => (
                <MeterReadingGraph key={m.id} meter={m} monthStr={month} />
              ))
            )}
          </Card>
        </FormProvider>
      </div>
    </ModuleAccessGuard>
  );
}
