import {
  CheckCircle,
  CheckCircle2,
  Loader2,
  Upload
} from "lucide-react";
import { useEffect, useState } from "react";
import AppButton from "../../../../components/AppButton";
import {
  Card,
  CardContent
} from "../../../../components/ui/card";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../../../components/ui/sheet";
import useLocationImportCheck from "../../../../hooks/useLocationImportCheck";
import {
  LocationCsvObject,
  useDataImportsControllerImportLocationMutation,
} from "../../../../redux/slices/OpenApi/cerevApi";
import {
  SnackBarType,
  setOpenSnackBar,
} from "../../../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { cn } from "../../../../utils/util";
import CsvReader from "../../../AssetScreen/component/CsvReader";
import DownloadLocationCsvTemplate from "./components/DownloadLocationCsvTemplate";
import useError from "../../../../hooks/useError";

interface ImportLocationSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ImportLocationSheet({
  open,
  setOpen,
}: ImportLocationSheetProps) {
  const dispatch = useAppDispatch();
  const [idx, setIdx] = useState(0);
  const [hasUpload, setHasUpload] = useState(false);
  const [csvData, setCsvData] = useState<LocationCsvObject[]>([]);
  const checkErrors = useLocationImportCheck({ csvData });

  const [
    importLocation,
    {
      isLoading: isImportLocationLoading,
      isError: isImportLocationIsError,
      isSuccess: isImportLocationSuccess,
      data: importLocationData,
      error: importAssetsError,
    },
  ] = useDataImportsControllerImportLocationMutation();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  useError({
    dependencies: [{ isError: isImportLocationIsError, error: importAssetsError }],
  });


  const cleanUp = () => {
    setIdx(0);
    setCsvData([]);
    setHasUpload(false);
    setOpen(false);
  };

  useEffect(() => {
    if (isImportLocationSuccess) {
      cleanUp();
      dispatch(
        setOpenSnackBar({
          type: SnackBarType.Success,
          msg: "Your locations have been imported successfully.",
        })
      );
    }
  }, [isImportLocationSuccess]);

  const getContent = () => {
    switch (idx) {
      case 0:
        return (
          <div className="flex flex-col gap-5 col-span-4">
            <Card className="w-full">
              <CardContent className="py-5 flex flex-col gap-5">
                <div className="text-lg font-bold">CSV Import</div>
                <div className="text-slate-600 leading-relaxed text-sm">
                  Download the template and fill in the fields to be imported
                  into Cerev
                </div>
                <DownloadLocationCsvTemplate />
                <div className="text-lg font-bold">Upload your CSV File</div>
                {/* <div className="text-blue-500 underline-offset-2 underline leading-relaxed text-sm hover:cursor-pointer">
                  Download Template
                </div> */}
                <div className="flex gap-10">
                  <CsvReader
                    onUploadAccepted={(res) => {
                      setHasUpload(true);
                      setCsvData(res.data);
                    }}
                  />
                </div>
                {hasUpload && (
                  <>
                    <div className="text-lg font-bold">Checking for Errors</div>
                    {checkErrors.length === 0 && (
                      <div className="py-8 flex justify-center gap-4 items-center">
                        <CheckCircle2 className="w-12 h-12 text-primary-900" />
                        <div className="font-medium text-lg text-primary-900">
                          All are in order! Press next to continue import.
                        </div>
                      </div>
                    )}
                    <ol className="list-decimal ml-6">
                      {checkErrors.map((err, idx) => (
                        <li key={idx} className="text-red-400 font-sans">
                          {err.message}
                        </li>
                      ))}
                    </ol>
                  </>
                )}
              </CardContent>
            </Card>
            <div className="flex gap-3">
              <AppButton
                label="Confirm"
                onClick={() => {
                  setIdx(1);
                  importLocation({
                    importLocationRequestDto: {
                      importLocationCsvData: csvData,
                      projectId: activeProj?.id?.toString() ?? "",
                    },
                  });
                }}
                disabled={checkErrors.length > 0 || !hasUpload}
              />
            </div>
          </div>
        );

      case 1:
        return (
          <div className="flex flex-col gap-4 col-span-4">
            <Card className="w-full">
              <CardContent className="py-5 flex flex-col gap-5 items-center">
                <p className="text-2xl text-primary-900 font-bold">
                  Uploading to Cerev Database
                </p>
                <Loader2 className="h-14 w-14 animate-spin text-primary-900" />
              </CardContent>
            </Card>
          </div>
        );
    }
  };

  return (
    <Sheet
      open={open}
      onOpenChange={(o) => {
        if (!o) {
          cleanUp();
        }
      }}
    >
      <SheetContent side="bottom" className="h-screen">
        <SheetHeader>
          <SheetTitle className="text-2xl">Import Locations</SheetTitle>
        </SheetHeader>
        <div className="flex justify-center my-16">
          <div className="max-w-5xl w-full grid grid-cols-5 gap-10">
            <div className="col-span-1">
              <ol className="relative text-gray-500 border-l border-gray-200">
                <div className="hover:cursor-pointer">
                  <li className="mb-10 ml-10">
                    <div className="absolute flex items-center justify-center w-12 h-12 bg-primary-200 rounded-full -left-6 ring-white ring-4">
                      <Upload className="text-primary-900 p-1 w-7 h-7" />
                    </div>
                    <div>
                      <div className="text-primary-900 font-medium pt-3">
                        Uploads
                      </div>
                    </div>
                  </li>
                </div>
                <div className="hover:cursor-pointer">
                  <li className="mb-10 ml-10">
                    <div
                      className={cn(
                        "transition-all duration-300 absolute flex items-center justify-center w-12 h-12 bg-primary-200 rounded-full -left-6 ring-white ring-4",
                        {
                          "bg-slate-50": idx < 1,
                        }
                      )}
                    >
                      <CheckCircle
                        className={cn(
                          "transition-all duration-300 text-primary-900 p-1 w-7 h-7",
                          {
                            "text-slate-400": idx < 1,
                          }
                        )}
                      />
                    </div>
                    <div>
                      <div
                        className={cn(
                          "transition-all duration-300 text-primary-900 font-medium pt-3",
                          {
                            "text-slate-400 font-normal": idx < 1,
                          }
                        )}
                      >
                        Results
                      </div>
                    </div>
                  </li>
                </div>
              </ol>
            </div>
            {getContent()}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
