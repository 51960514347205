import { Trash2, ChevronUp, ChevronDown } from "lucide-react";
import AppButton from "../../../../AppButton";
import { PtwTemplateFormType } from "../../ptwTemplateSchema";
import { UseFieldArrayReturn } from "react-hook-form";

interface PmFormatActionsProps {
  index: number;
  fieldsArray: UseFieldArrayReturn<PtwTemplateFormType>;
}

export default function PmFormatActions({
  index,
  fieldsArray,
}: PmFormatActionsProps) {
  return (
    <div className="flex gap-4">
      <AppButton
        variant="ghost"
        icon={<Trash2 className="text-red-500" />}
        onClick={() => {
          fieldsArray.remove(index);
        }}
      />
      {index - 1 >= 0 && (
        <AppButton
          variant="ghost"
          icon={<ChevronUp />}
          onClick={() => {
            fieldsArray.swap(index, index - 1);
          }}
        />
      )}
      {index + 1 < fieldsArray.fields.length && (
        <AppButton
          variant="ghost"
          icon={<ChevronDown />}
          onClick={() => {
            fieldsArray.swap(index, index + 1);
          }}
        />
      )}
    </div>
  );
}
