/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  FirstTimeUserPasswordDto,
  FirstTimeUserPasswordResponseDto,
} from ".././model";

export const adminControllerFirstTimePasswordChange = (
  firstTimeUserPasswordDto: FirstTimeUserPasswordDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<FirstTimeUserPasswordResponseDto>> => {
  return axios.patch(
    `/admin/firstTimePassword`,
    firstTimeUserPasswordDto,
    options
  );
};

export const getAdminControllerFirstTimePasswordChangeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminControllerFirstTimePasswordChange>>,
    TError,
    { data: FirstTimeUserPasswordDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminControllerFirstTimePasswordChange>>,
  TError,
  { data: FirstTimeUserPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminControllerFirstTimePasswordChange>>,
    { data: FirstTimeUserPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminControllerFirstTimePasswordChange(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminControllerFirstTimePasswordChangeMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminControllerFirstTimePasswordChange>>
>;
export type AdminControllerFirstTimePasswordChangeMutationBody =
  FirstTimeUserPasswordDto;
export type AdminControllerFirstTimePasswordChangeMutationError =
  AxiosError<unknown>;

export const useAdminControllerFirstTimePasswordChange = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminControllerFirstTimePasswordChange>>,
    TError,
    { data: FirstTimeUserPasswordDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminControllerFirstTimePasswordChange>>,
  TError,
  { data: FirstTimeUserPasswordDto },
  TContext
> => {
  const mutationOptions =
    getAdminControllerFirstTimePasswordChangeMutationOptions(options);

  return useMutation(mutationOptions);
};
