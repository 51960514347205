import { AppResponse } from '../../model/AppResponse';
import { RootApi } from './../RootApi';
import { AnalyticResponse } from './model/AnalyticReponse';

const AnalyticApiWithTag = RootApi.enhanceEndpoints({
    addTagTypes: ["Analytic"]
});

export const AnalyticApi = AnalyticApiWithTag.injectEndpoints({
    endpoints: builder => ({
        getAnalytics: builder.query<AnalyticResponse[], {projectId: string}>({
            query: ({projectId}) => ({
                url: '/analytics',
                method: 'GET',
                params: {
                    projectId,
                },
            }),
        })
    })
})

export const {
    useGetAnalyticsQuery
} = AnalyticApi;