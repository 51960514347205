import { useFormContext, useWatch } from "react-hook-form";
import { useWorkRequestControllerGetWorkRequestStatusCount } from "../../../api/work-request/work-request";
import { useAppSelector } from "../../../redux/store";
import { useWorkRequestFilterStore } from "../../../stores/workRequest/workRequestFilterStore";
import useDebounce from "../../../hooks/useDebounce";
import { WorkRequestControllerGetWorkRequestStatusCountParams } from "../../../api/model";

interface WorkRequestTabWithCountProps {
  workRequestStatus: { name: string; label: string };
}

export default function WorkRequestTabWithCount({
  workRequestStatus,
}: WorkRequestTabWithCountProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useFormContext();

  const search = useWatch({ control: methods.control, name: "search" });
  const locationFilter = useWorkRequestFilterStore(
    (state) => state.filterLocation
  );

  const dateRange = useWorkRequestFilterStore((state) => state.dateRange);

  const queryArg =
    useDebounce<WorkRequestControllerGetWorkRequestStatusCountParams>({
      value: {
        projectId: activeProj?.id?.toString() ?? "",
        locFilterId: locationFilter?.id?.toString(),
        search,
        filterStartDateISO: dateRange?.from?.toISOString(),
        filterEndDateISO: dateRange?.to?.toISOString(),
      },
      delay: 500,
    });

  const { data: statusCounts } =
    useWorkRequestControllerGetWorkRequestStatusCount(queryArg);

  const getCount = () => {
    switch (workRequestStatus.name) {
      case "NEW":
        return statusCounts?.data?.newCount ?? "-";
      case "IN_PROGRESS":
        return statusCounts?.data?.inProgressCount ?? "-";
      case "COMPLETED":
        return statusCounts?.data?.completedCount ?? "-";
      case "CANCELLED":
        return statusCounts?.data?.cancelledCount ?? "-";
      default:
        return "-";
    }
  };

  return <p>{`${workRequestStatus?.label} (${getCount()})`}</p>;
}
