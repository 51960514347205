import { ImportAssetCsvObj } from "../../redux/slices/OpenApi/cerevApi";

export function findDuplicateAssetCodes(assets: ImportAssetCsvObj[]) {
    const codeCount: Record<string, number> = {};
    const errors: { code: string; message: string }[] = [];
    const errorSet: Set<string> = new Set();
  
    assets.forEach((asset) => {
      const { Code } = asset;
      if (Code !== undefined) {
        codeCount[Code] = (codeCount[Code] || 0) + 1;
  
        if (codeCount[Code] > 1 && !errorSet.has(Code)) {
          errorSet.add(Code);
  
          errors.push({
            code: "DUPLICATE_CODE",
            message: `Duplicate Asset Code found: ${Code}`,
          });
        }
      }
    });
  
    return errors;
  }
  
  export function checkAssetRequiredColumns(
    assetData: ImportAssetCsvObj[]
  ): { code: string; message: string }[] {
    const requiredColumns = ["Name", "Code", "LocationCode", "EquipmentType"];
    if (assetData.length === 0) return [];
    const csvColumns = Object.keys(assetData[0]);
    const errors: { code: string; message: string }[] = [];
  
    requiredColumns.forEach((column) => {
      if (!csvColumns.includes(column)) {
        errors.push({
          code: "COLUMN_MISSING",
          message: `Column "${column}" is missing.`,
        });
      }
    });
  
    return errors;
  }
  
  export function checkAssetDataValidity(
    assets: ImportAssetCsvObj[]
  ): { code: string; message: string }[] {
    const errors: { code: string; message: string }[] = [];
  
    assets.forEach((asset, index) => {
      const { Name, Code, LocationCode, EquipmentType } = asset;
  
      if (
        (Name || Code || LocationCode || EquipmentType) &&
        (!Name ||
          !Code ||
          !LocationCode ||
          !EquipmentType ||
          Name.trim() === "" ||
          Code.trim() === "" ||
          LocationCode.trim() === "" ||
          EquipmentType.trim() === "")
      ) {
        errors.push({
          code: "INVALID_ASSET_DATA",
          message: `Row ${
            index + 2
          }: Name, Code, LocationCode, and EquipmentType must exist and not be empty when any of them is present`,
        });
      }
    });
  
    return errors;
  }
  
  export function checkAssetDataPresence(
    assetData: ImportAssetCsvObj[]
  ): { code: string; message: string }[] {
    if (assetData.length === 0) {
      return [
        {
          code: "NO_DATA",
          message: "No asset data is present in the CSV file.",
        },
      ];
    }
  
    if (assetData.length === 1) {
      const { Name, Code, LocationCode, EquipmentType } = assetData[0];
      if (
        Name.trim() === "" ||
        Code.trim() === "" ||
        LocationCode.trim() === "" ||
        EquipmentType.trim() === ""
      ) {
        return [
          {
            code: "NO_DATA",
            message: "No asset data is present in the CSV file",
          },
        ];
      }
    }
  
    return [];
  }
  
  export function checkAssetTypes(
    assetData: ImportAssetCsvObj[],
    assetTypes: { id: number; name: string }[]
  ): { code: string; message: string }[] {
    const validAssetTypeNames = assetTypes.map((assetType) => assetType.name);
  
    const errors: { code: string; message: string }[] = [];
  
    assetData.forEach((asset, index) => {
      const { EquipmentType } = asset;
  
      if (EquipmentType && !validAssetTypeNames.includes(EquipmentType)) {
        errors.push({
          code: "INVALID_ASSET_TYPE",
          message: `Row ${index + 2}: EquipmentType '${EquipmentType}' is not valid.`,
        });
      }
    });
  
    return errors;
  }