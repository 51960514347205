import { ColumnDef, Row } from "@tanstack/react-table";
import { PermitToWork, PermitToWorkWithTempAndValue } from "../../api/model";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_UPDATE_STATE,
  PERMIT_TO_WORK_DETAIL_DRAWER,
  PERMIT_TO_WORK_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import { parseISO } from "date-fns";
import useDate from "../../hooks/useDate";

const PtwNameNav = ({ row }: { row: Row<PermitToWork> }) => {
  const [, setSearchParams] = useSearchParams();
  return (
    <div className={`flex flex-col gap-1`}>
      <div
        className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
        onClick={() => {
          setSearchParams((p) => {
            p.set("drawerType", PERMIT_TO_WORK_DETAIL_DRAWER);
            // p.set("drawerState", DRAWER_UPDATE_STATE);
            p.set("ptwId", row.getValue("id"));
            return p;
          });
        }}
      >
        {row.getValue("name") ?? "-"}
      </div>
    </div>
  );
};

export const ptwColumns: ColumnDef<PermitToWork>[] = [
  {
    accessorKey: "id",
  },
  {
    id: "name",
    accessorKey: "name",
    header: "Submitter",
    cell: ({ row }) => <PtwNameNav row={row} />,
  },
  {
    id: "permitType",
    accessorKey: "ptwTemplate.name",
    header: "Work Permit Type",
  },
  {
    id: "location",
    accessorKey: "location",
    header: "Location",
  },
  {
    id: "email",
    accessorKey: "email",
    header: "Email",
  },
  {
    id: "refNo",
    accessorKey: "refNo",
    header: "Ref No.",
  },
  {
    id: "createdOn",
    header: "Submitted On",
    accessorKey: "createdOn",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <p>
          {formatDateToLocal({
            dateStr: row.getValue("createdOn"),
            formatting: "dd MMM yy, hh:mm a",
          })}
        </p>
      );
    },
  },
];
