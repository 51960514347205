import {ErrorSlice} from "./slices/Error/ErrorSlice";
import {RootSlice} from "./slices/RootSlice";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {RootApi} from "./slices/RootApi";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {AssetSlice} from "./slices/Asset/AssetSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  root: persistReducer(persistConfig, RootSlice.reducer),
  error: ErrorSlice.reducer,
  asset: AssetSlice.reducer,
  [RootApi.reducerPath]: RootApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(RootApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export const persistor = persistStore(store);
