import * as React from "react";
import { View, Text } from "@react-pdf/renderer";

export const TableCell = ({
  width,
  children,
  textAlign = "center",
}: {
  width: string;
  children: React.ReactNode;
  textAlign?: "center" | "left";
}) => (
  <View
    style={{
      width: width,
      paddingHorizontal: 2,
      borderRight: "0.5px solid lightgray",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: textAlign === "center" ? "center" : "flex-start",
    }}
  >
    <Text style={{ fontSize: 7, textAlign }}>{children}</Text>
  </View>
);
