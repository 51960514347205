import { cn } from "../utils/util";

export interface AppItemInfoProps {
  label: string;
  icon: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  testId?: string;
}

export default function AppItemInfo({
  label,
  icon,
  content,
  className,
  testId,
}: AppItemInfoProps) {
  return (
    <div className={cn("flex items-start", className)} data-testid={testId}>
      <div className="bg-primary-200 flex items-center justify-center p-3 mr-4 rounded-md text-primary-900">
        {icon}
      </div>
      <div className="flex flex-col w-full">
        <p className="font-sans font-medium text-neutral-500">{label}</p>
        {content}
      </div>
    </div>
  );
}
