export function WorkOrderLogo() {
    return (
        <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_485_179)">
                <path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#A2DAE0" />
                <path d="M24.6818 45.9785C36.6276 45.9785 46.3116 45.6427 46.3116 45.2285C46.3116 44.8143 36.6276 44.4785 24.6818 44.4785C12.736 44.4785 3.052 44.8143 3.052 45.2285C3.052 45.6427 12.736 45.9785 24.6818 45.9785Z" fill="#85B6D6" />
                <path d="M25.6209 11.0195H35.5725V38.1325H8.45947V11.0195H18.4186" fill="#FD7F0E" />
                <path d="M19.267 12.3297C19.2446 11.8429 19.3212 11.3566 19.4921 10.9003C19.663 10.4439 19.9247 10.0269 20.2613 9.67453C20.5979 9.32214 21.0025 9.04165 21.4505 8.85001C21.8986 8.65837 22.3808 8.55957 22.8682 8.55957C23.3555 8.55957 23.8377 8.65837 24.2858 8.85001C24.7338 9.04165 25.1384 9.32214 25.475 9.67453C25.8116 10.0269 26.0733 10.4439 26.2442 10.9003C26.4151 11.3566 26.4917 11.8429 26.4693 12.3297" fill="#FD7F0E" />
                <path d="M13.6323 16.8506H30.4" stroke="#FFEFB7" strokeWidth="2" strokeMiterlimit="10" />
                <path d="M13.6323 21.1758H21.8192" stroke="#FFEFB7" strokeWidth="2" strokeMiterlimit="10" />
                <path d="M13.6323 25.5605H21.8192" stroke="#FFEFB7" strokeWidth="2" strokeMiterlimit="10" />
                <path d="M32.7551 44.7442C38.9748 44.7442 44.0169 39.7021 44.0169 33.4824C44.0169 27.2628 38.9748 22.2207 32.7551 22.2207C26.5355 22.2207 21.4934 27.2628 21.4934 33.4824C21.4934 39.7021 26.5355 44.7442 32.7551 44.7442Z" fill="white" />
                <path d="M32.7553 41.7377C37.3145 41.7377 41.0104 38.0418 41.0104 33.4826C41.0104 28.9235 37.3145 25.2275 32.7553 25.2275C28.1962 25.2275 24.5002 28.9235 24.5002 33.4826C24.5002 38.0418 28.1962 41.7377 32.7553 41.7377Z" fill="#7CC796" />
                <path d="M28.4382 33.876L31.71 37.1553" stroke="#0F9F49" strokeWidth="4" strokeMiterlimit="10" />
                <path d="M37.0116 30.4072L30.2712 37.1552" stroke="#0F9F49" strokeWidth="3" strokeMiterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0_485_179">
                    <rect width="50" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
