import { ChevronLeft, ChevronRight } from "lucide-react";
import AppButton from "../../AppButton";
import { useWorkOrderScheduleFilterStore } from "../../../stores/workOrderSchedule/workOrderScheduleFilterStore";
import { endOfMonth, endOfWeek, format, startOfWeek } from "date-fns";
import {
  useCalendarDateEnd,
  useCalendarDateStart,
  useCalendarMode,
  useCalendarRef,
  useCalendarRefActions,
} from "../../../stores/calendarRefStore/calendarRefStore";

export default function CalendarDateChanger() {
  const calendarRef = useCalendarRef();

  const calendarMode = useCalendarMode();

  const { setDateStart, setDateEnd } = useCalendarRefActions();
  const dateStart = useCalendarDateStart();
  const dateEnd = useCalendarDateEnd();

  const getDateDisplay = () => {
    const currentDate = calendarRef?.getApi().getDate();
    if (currentDate) {
      switch (calendarMode) {
        case "dayGridMonth":
          return calendarRef
            ? format(calendarRef?.getApi().getDate(), "MMM yyyy")
            : format(new Date(), "MMM yyyy");
        case "timeGridWeek":
          return calendarRef
            ? `${format(dateStart, "dd MMM")} - ${format(dateEnd, "dd MMM")}`
            : `${format(startOfWeek(new Date()), "dd MMM")} - ${format(
                endOfWeek(new Date()),
                "dd MMM"
              )}`;
      }
    }
  };

  return (
    <div className="flex gap-4 items-center">
      <AppButton
        icon={<ChevronLeft />}
        onClick={() => {
          calendarRef?.getApi().prev();
          const currentDate = calendarRef?.getApi().getDate();
          const viewType = calendarRef?.getApi().view.type;
          if (currentDate) {
            setDateStart(currentDate);
            switch (viewType) {
              case "dayGridMonth":
                setDateEnd(endOfMonth(currentDate));
                break;
              case "timeGridWeek":
                setDateEnd(endOfWeek(currentDate));
                break;
            }
          }
        }}
      />
      <p className="text-primary-900 text-lg font-medium">{getDateDisplay()}</p>
      <AppButton
        icon={<ChevronRight />}
        onClick={() => {
          calendarRef?.getApi().next();
          const viewType = calendarRef?.getApi().view.type;
          const currentDate = calendarRef?.getApi().getDate();
          if (currentDate) {
            setDateStart(currentDate);
            switch (viewType) {
              case "dayGridMonth":
                setDateEnd(endOfMonth(currentDate));
                break;
              case "timeGridWeek":
                setDateEnd(endOfWeek(currentDate));
                break;
            }
          }
        }}
      />
    </div>
  );
}
