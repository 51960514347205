import { QrCode } from "lucide-react";
import { useState } from "react";
import { useWorkRequestControllerPostWorkRequestQRCodes } from "../../../api/work-request/work-request";
import AppButton from "../../../components/AppButton";
import { useAppSelector } from "../../../redux/store";

export default function WRPrintQRCode() {
  const [open, setOpen] = useState(false);
  const [isLocationRequired, setIsLocationRequired] = useState(true);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { mutate, isPending } = useWorkRequestControllerPostWorkRequestQRCodes({
    mutation: {
      onSuccess: (res) => {
        setOpen(false);
        window.open(res.data?.signedUrl, "_blank");
      },
    },
  });

  const printPdf = () => {
    if (!activeProj) return;
    mutate({
      params: {
        projectId: activeProj?.id?.toString() ?? "",
        // Params and boolean don't go well together...
        isLocationRequired: isLocationRequired ? "true" : "false",
      },
    });
  };

  //   const [trigger, result] =
  //     useLazyWorkRequestControllerGetWorkRequestQrCodeQuery();

  return (
    <>
      <AppButton
        variant="outline"
        label="Print QR Code"
        icon={<QrCode />}
        onClick={printPdf}
        isLoading={isPending}
      />
    </>
  );
}
