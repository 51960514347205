import { ColumnDef } from "@tanstack/react-table";
import { AssignedUserWithVendor } from "../../../../../../../redux/slices/OpenApi/cerevApi";
import { Checkbox } from "../../../../../../ui/checkbox";

export const updateAssignmentColumns: ColumnDef<AssignedUserWithVendor>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "position",
    header: "Position",
  },
];
