import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AppButton from "../../components/AppButton";
import AppLogoText from "../../components/AppLogoText";
import PublicPageWrapper from "../../components/PublicPageWrapper";
import {CerevLogo} from "../../icons/CerevLogo";
import {WorkOrderLogo} from "../../icons/WorkOrderLogo";
import WorkRequestLogo from "../../icons/WorkRequestLogo";

export default function PublicWorkRequestScreen() {
  const navigate = useNavigate();

  return (
    <PublicPageWrapper className="justify-between">
      <AppLogoText />
      <div className="flex flex-col items-center font-sans gap-4">
        <div className="h-20 w-20">
          <WorkRequestLogo size={85}/>
        </div>
        <p className="font-bold text-2xl">Work Request</p>
        <p className="text-neutral-500 text-center">
          See anything not right? Need technician’s assistance? Need Facilities
          Management Team’s attention? Raise a new request here.
        </p>
        <AppButton
          label="+ New Request"
          className="w-full"
          onClick={() => {
            navigate("request");
          }}
        />
      </div>
      <AppButton
        className="w-full"
        label="Check My Request"
        variant="ghost"
        onClick={() => {
          navigate("track");
        }}
      />
    </PublicPageWrapper>
  );
}
