import { Controller, useFormContext } from "react-hook-form";
import AppTextField from "../../../AppTextField";
import { Toggle } from "../../../ui/toggle";
import { useEffect } from "react";

function getMonths() {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
}

export default function PmTemplateYearlyField() {
  const months = getMonths();
  return (
    <div className="grid grid-cols-2 items-center mt-4">
      <div>Repeat every</div>
      <div className="flex gap-4 items-center">
        <AppTextField
          name="frequencySetting.yearlyEveryStepCount"
          className="flex-1 w-full"
          type="number"
        />
        <div className="mb-2">year</div>
      </div>
      <div className="grid grid-cols-4 col-span-2 gap-2 border border-slate-300 rounded-md my-4 p-4">
        <Controller
          name="frequencySetting.yearlyRepeatMonth"
          render={({ field: { onChange, value } }) => (
            <>
              {months.map((month, index) => (
                <Toggle
                  pressed={value?.includes(index.toString()) || false}
                  onClick={() => {
                    const monthIndex = index.toString();
                    onChange([monthIndex]);
                  }}
                  key={month}
                >
                  {month}
                </Toggle>
              ))}
            </>
          )}
        />
      </div>
      <div className="grid grid-cols-7 col-span-2 gap-2 border border-slate-300 rounded-md my-4 p-4">
        <Controller
          name="frequencySetting.yearlyRepeatDate"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <Toggle
                    pressed={value?.includes(day.toString()) || false}
                    onClick={() => {
                      onChange([day.toString()]);
                    }}
                    key={day.toString()}
                  >
                    {day}
                  </Toggle>
                ))}
                {error?.message && (
                  <div className="col-span-7 text-red-500 font-sans text-sm font-medium">
                    {error?.message}
                  </div>
                )}
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
