import { FormProvider, useForm } from "react-hook-form";
import { useAssetTypeControllerGetAssetType } from "../../../../api/asset-type/asset-type";
import { ScrollArea, ScrollBar } from "../../../../components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/tabs";
import useAppTabsNavigation from "../../../../hooks/useAppTabsNavigation";
import { useAppSelector } from "../../../../redux/store";
import EquipmentTab from "./EquipmentTab";
import AppTextField from "../../../../components/AppTextField";
import EquipmentTabTrigger from "./EquipmentTabTrigger";

export default function EquipmentTypeBasedView() {
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "equipmentType",
    defaultValue: "0",
  });

  const { data: equipmentTypes, isLoading } =
    useAssetTypeControllerGetAssetType(
      {
        projectId: activeProj?.id?.toString() ?? "-",
        companyId: activeComp?.id?.toString() ?? "-",
      },
      {
        query: {
          enabled: !!activeProj && !!activeComp,
          select: (res) => res.data,
        },
      }
    );

  return (
    <FormProvider {...methods}>
      <AppTextField
        name="search"
        placeholder="Search..."
        className="max-w-[400px]"
      />
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <ScrollArea className="whitespace-nowrap rounded-md max-w-6xl">
          <TabsList className="mb-2 flex w-max">
            {equipmentTypes?.map((equipmentType, index) => (
              <EquipmentTabTrigger
                key={index}
                equipmentType={equipmentType}
                index={index}
              />
            ))}
          </TabsList>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        {equipmentTypes?.map((equipmentType, index) => (
          <TabsContent key={index} value={index.toString()}>
            <EquipmentTab equipmentType={equipmentType} />
          </TabsContent>
        ))}
      </Tabs>
    </FormProvider>
  );
}
