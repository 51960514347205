import { Pen } from "lucide-react";
import AppButton from "../../../AppButton";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../ui/dialog";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AppTextField from "../../../AppTextField";
import { useStocksControllerAdjustInventoryLocationBalance } from "../../../../api/stocks-inventory/stocks-inventory";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";

const adjustLocationBalanceSchema = z.object({
  balance: z.coerce.number(),
  description: z.string().optional(),
});

type AdjustLocationBalanceForm = z.infer<typeof adjustLocationBalanceSchema>;

interface AdjustLocationBalanceBtnProps {
  locationId: number;
  currentBalance: number;
}

export default function AdjustLocationBalanceBtn({
  locationId,
  currentBalance,
}: AdjustLocationBalanceBtnProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");
  const methods = useForm<AdjustLocationBalanceForm>({
    resolver: zodResolver(adjustLocationBalanceSchema),
    defaultValues: {
      balance: currentBalance,
      description: "",
    },
  });
  const [open, setOpen] = useState(false);
  const qc = useQueryClient();

  const { mutate, isPending } =
    useStocksControllerAdjustInventoryLocationBalance({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("stock"),
          });
          setOpen(false);
        },
      },
    });

  const onSubmit: SubmitHandler<AdjustLocationBalanceForm> = (data) => {
    if (currentBalance === undefined || !activeProj) return;
    mutate({
      inventoryId: inventoryId ?? "",
      data: {
        newBalance: data.balance,
        currentBalance,
        projectId: activeProj?.id ?? 0,
        locationId,
        description: data.description ?? "",
      },
    });
  };

  return (
    <>
      <AppButton
        label="Adjust Balance"
        icon={<Pen />}
        variant="outline"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={(o) => setOpen(o)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Adjust Balance</DialogTitle>
            <DialogDescription>
              Please input the new balance for this location
            </DialogDescription>
          </DialogHeader>
          <FormProvider {...methods}>
            <div className="flex flex-col gap-4">
              <AppTextField name="description" label="Remarks" />
              <AppTextField type="number" name="balance" label="New Balance" />
            </div>
          </FormProvider>
          <DialogFooter>
            <AppButton
              label="Cancel"
              onClick={() => {
                setOpen(false);
              }}
              variant="ghost"
            />
            <AppButton
              label="Confirm"
              isLoading={isPending}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
