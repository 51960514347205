
import DrawerFormSkeleton from "../../components/skeletons/DrawerFormSkeleton";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import AppButton from "../../components/AppButton";
import NoBimDetected from "./components/NoBimDetected";
import BimModelView from "./components/BimModelView/BimModelView";
import { toast } from "sonner";
import { useGLTF } from "@react-three/drei";
import { Suspense } from "react";
import SyncBimBtn from "./components/SyncBimBtn";
import DeleteBimNodeBtn from "./components/DeleteBimNodeBtn";
import { useBimControllerGetBimModel } from "../../api/bim/bim";

export default function BimScreen() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: bimModel, isLoading } = useBimControllerGetBimModel(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  return (
    <div className="flex flex-col gap-2 flex-grow mb-6">
      <div className="flex gap-4">
        <AppTitle title="3D Building" />
        <Suspense fallback={null}>{bimModel && <SyncBimBtn />}</Suspense>
        <DeleteBimNodeBtn />
      </div>
      {isLoading ? (
        <DrawerFormSkeleton />
      ) : bimModel !== null ? (
        <BimModelView />
      ) : (
        <NoBimDetected />
      )}
    </div>
  );
}
