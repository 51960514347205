import { Cog } from "lucide-react";
import AppButton from "../../../../../components/AppButton";
import { useNavigate } from "react-router-dom";
import { useIsAdmin } from "../../../../../hooks/useRoles";

export default function SettingsBtn() {
  const nav = useNavigate();
  const isAdmin = useIsAdmin();

  if (!isAdmin) return <></>;

  return (
    <AppButton
      data-testid="settings-btn"
      variant="outline"
      icon={<Cog />}
      onClick={() => {
        nav("settings");
      }}
    />
  );
}
