/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type WorkRequestStatus =
  (typeof WorkRequestStatus)[keyof typeof WorkRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkRequestStatus = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
} as const;
