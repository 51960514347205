import { GetCommentsResponseDto } from "../../../../../../api/model";
import useDate from "../../../../../../hooks/useDate";
import { useSignatoryEnabled } from "../../../../../../hooks/useFeatures";
import { Avatar, AvatarFallback } from "../../../../../ui/avatar";
import React from "react";

interface AppCommentProps {
  comment?: GetCommentsResponseDto;
}

export default function AppComment({ comment }: AppCommentProps) {
  const { diffDateTillNow } = useDate();
  const signatoryEnabled = useSignatoryEnabled();
  return (
    <div className="bg-neutral-100 rounded-xl p-4 flex flex-col gap-4">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <Avatar className="bg-primary-900 w-12 h-12 font-bold">
            <AvatarFallback className="bg-transparent text-white">
              {comment?.createdBy.name?.substring(0, 2).toUpperCase() ?? "-"}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p className="font-sans font-bold">
              {comment?.createdBy.name ?? "-"}
            </p>
            <p className="font-sans text-neutral-500">
              {comment?.createdBy.position ?? "-"}
            </p>
          </div>
        </div>
        <p className="text-neutral-500">
          {diffDateTillNow({ compareISODate: comment?.createdOn ?? "" })}
        </p>
      </div>
      <p
        className={`font-sans ${
          comment?.commentType === "STATUS" ? `text-primary-900 font-bold` : ``
        }`}
      >
        {comment?.description
          ? comment.description.split("\n").map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))
          : "-"}
      </p>
      {comment?.attachments?.length !== 0 && (
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          {comment?.attachments.map((att) => (
            <img
              key={att.id}
              src={att.url}
              className="w-24 h-24 rounded-xl object-cover hover:cursor-pointer"
              onClick={() => {
                window.open(att.url, "_blank");
              }}
            />
          ))}
        </div>
      )}
      {comment?.signatory?.length !== 0 && signatoryEnabled && (
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          {comment?.signatory.map((sign) => (
            <div className="border border-slate-300 rounded-xl gap-4 flex flex-col p-4">
              <img
                key={sign.id}
                src={sign.url}
                className="w-32 h-32 rounded-xl object-cover hover:cursor-pointer"
                onClick={() => {
                  window.open(sign.url, "_blank");
                }}
              />
              <div className="space-y-2">
                <p className="text-sm text-slate-500">Signed by</p>
                <p className="font-bold">{comment?.signedBy ?? "-"}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
