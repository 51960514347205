/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type WorkRequestSettingLocationRequired =
  (typeof WorkRequestSettingLocationRequired)[keyof typeof WorkRequestSettingLocationRequired];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkRequestSettingLocationRequired = {
  REQUIRED: "REQUIRED",
  NOT_REQUIRED: "NOT_REQUIRED",
} as const;
