import {
  Calendar,
  ChevronDown,
  ChevronUp,
  File,
  Hash,
  Image,
  List,
  Text,
  ToggleLeft,
  Trash2,
} from "lucide-react";
import { UseFieldArrayReturn } from "react-hook-form";
import AppTextField from "../../../../AppTextField";
import {
  PtwFormatFormType,
  PtwTemplateFormType,
} from "../../ptwTemplateSchema";
import PtwOptionField from "./PtwOptionField";
import AppButton from "../../../../AppButton";
import PmFormatActions from "./PmFormatActions";
import PmFormatHeading from "./PmFormatHeading";
import { PtwFormatType } from "@/api/model";

interface PtwFormatItemProps {
  ptw: PtwFormatFormType;
  index: number;
  fieldsArray: UseFieldArrayReturn<PtwTemplateFormType>;
}

export default function PtwTemplateFormatItem({
  ptw,
  index,
  fieldsArray,
}: PtwFormatItemProps) {
  switch (ptw.ptwFormatType) {
    case PtwFormatType.TEXT:
      return (
        <PmFormatHeading
          label="Input Field"
          icon={<Text className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    case PtwFormatType.NUMBER:
      return (
        <PmFormatHeading
          label="Number"
          icon={<Hash className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    case PtwFormatType.ATTACHMENT:
      return (
        <PmFormatHeading
          label="Attachment"
          icon={<File className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    case PtwFormatType.BOOLEAN:
      return (
        <PmFormatHeading
          label="Yes / No"
          icon={<ToggleLeft className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    case PtwFormatType.DESCRIPTION:
      return (
        <PmFormatHeading
          label="Description"
          icon={<Text className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    case PtwFormatType.HEADING:
      return (
        <PmFormatHeading
          label="Heading"
          icon={<Text className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    case PtwFormatType.SINGLE_OPTION:
    case PtwFormatType.MULTI_OPTION:
      return (
        <PmFormatHeading
          label="Options"
          icon={<List className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
          <PtwOptionField ptwFormatIdx={index} fieldsArray={fieldsArray} />
        </PmFormatHeading>
      );
    case PtwFormatType.IMAGE:
      return (
        <PmFormatHeading
          label="Image"
          icon={<Image className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    case PtwFormatType.DATE:
      return (
        <PmFormatHeading
          label="Date"
          icon={<Calendar className="h-4 w-4 text-primary-900" />}
          index={index}
          fieldsArray={fieldsArray}
        >
          <AppTextField name={`ptwFormat.${index}.name`} />
        </PmFormatHeading>
      );
    default:
      return <div>Not found</div>;
  }
}
