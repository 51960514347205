import { Draggable } from "@fullcalendar/interaction";
import { useEffect } from "react";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTime } from "../../../api/work-order-defect-v2/work-order-defect-v2";
import { useAppSelector } from "../../../redux/store";
import {
  DefectWithAsset,
  GetPlannedWorkOrderResponseDto,
  WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType,
} from "../../../api/model";
import { EventInput } from "@fullcalendar/core";
import DrawerFormSkeleton from "../../../components/skeletons/DrawerFormSkeleton";
import { Defect } from "../../../redux/slices/OpenApi/cerevApi";
import AppButton from "../../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  WORK_ORDER_DETAIL_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { useWorkOrderScheduleFilterStore } from "../../../stores/workOrderSchedule/workOrderScheduleFilterStore";
import { useFormContext, useWatch } from "react-hook-form";
import { Inbox } from "lucide-react";
import AppStatusBadge from "../../../components/badge/AppStatusBadge";

export default function UnplannedWorkOrdersSection() {
  const methods = useFormContext();
  const search = useWatch({ name: "search", control: methods.control });
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const assetTypeFilterIds = useWorkOrderScheduleFilterStore(
    (state) => state.assetTypes
  );
  const filterLocation = useWorkOrderScheduleFilterStore(
    (state) => state.filterLocation
  );
  const assetFilter = useWorkOrderScheduleFilterStore(
    (state) => state.filterAsset
  );
  const {
    data: workOrders,
    isLoading,
    isFetched,
  } = useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTime(
    {
      projectId: activeProj?.id ?? 0,
      workOrderPlanType:
        WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType.UNPLANNED,
      filterAssetTypeIds: assetTypeFilterIds.map((at) => at.id!),
      filterLocationId: filterLocation?.id,
      filterAssetId: assetFilter?.id,
      search,
    },
    {
      query: {
        select: (res) => {
          return res.data.data.map(
            (wo) =>
              ({
                id: wo.id,
                title: wo.subject,
                start: wo.plannedOn,
                extendedProps: {
                  ...wo,
                },
              } as EventInput)
          );
        },
        enabled: !!activeProj,
      },
    }
  );

  useEffect(() => {
    let draggable: Draggable;
    if (workOrders && workOrders?.length > 0) {
      let draggableEl = document.getElementById("draggables");
      if (!draggableEl) return;
      draggable = new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          const wo = JSON.parse(
            eventEl.getAttribute("data-event") as string
          ) as { title: string; extendedProps: DefectWithAsset };
          return {
            title: wo.extendedProps.subject,
            id: wo.extendedProps.id,
            extendedProps: wo.extendedProps,
            startTime: wo.extendedProps.plannedOn,
            endTime: wo.extendedProps.plannedEndOn,
          };
        },
      });
    }

    // Cleaning up the prev draggable
    return () => {
      draggable?.destroy();
    };
  }, [workOrders]);

  return (
    <div className="flex flex-col gap-1">
      {isLoading ? (
        <DrawerFormSkeleton />
      ) : (
        <>
          <div className="mb-5 text-2xl font-bold font-sans">
            Unplanned Work Orders
          </div>

          <ScrollArea className="h-[768px]">
            <div id="draggables" className="flex flex-col gap-2">
              {workOrders?.length === 0 && isFetched ? (
                <div className="flex flex-col gap-4 mt-20 items-center">
                  <Inbox className="text-slate-400 w-12 h-12" />
                  <p className="text-slate-400">No Results</p>
                </div>
              ) : (
                workOrders?.map((wo) => (
                  <Card
                    className="fc-event hover:cursor-move"
                    key={wo.id}
                    data-event={JSON.stringify(wo)}
                  >
                    <CardHeader>
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                          <CardTitle>{wo.title}</CardTitle>
                          <CardDescription className="flex flex-col gap-1">
                            {(wo.extendedProps as DefectWithAsset)?.asset?.name ??
                              "-"}
                          </CardDescription>
                        </div>
                        <AppStatusBadge
                          defectStatus={
                            (wo.extendedProps as DefectWithAsset).defectStatus
                          }
                        />
                      </div>
                    </CardHeader>
                    <CardContent>
                      <div className="flex justify-start">
                        <AppButton
                          variant="outline"
                          label="View"
                          onClick={() => {
                            setSearchParams((p) => {
                              p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
                              p.set("drawerState", DRAWER_VIEW_STATE);
                              p.set("workOrderId", wo.extendedProps?.id);
                              p.set("workOrderTabIdx", "0");
                              return p;
                            });
                          }}
                        />
                      </div>
                    </CardContent>
                  </Card>
                ))
              )}
            </div>
            <ScrollBar />
          </ScrollArea>
        </>
      )}
    </div>
  );
}
