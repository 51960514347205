import {Button, LinearProgress, Typography} from "@mui/material";
import {useState} from "react";
import {Outlet} from "react-router-dom";
import {CerevLogo} from "../../icons/CerevLogo";

export default function SplashScreen({isInit}: {isInit: boolean}) {
  if (isInit)
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <p className="text-neutral-600 mb-4">
          CMMS in your pocket
        </p>
        <p className="text-neutral-500 mb-2">Loading...</p>
        <LinearProgress className="w-32" />
      </div>
    );

  return <Outlet />;
}
