import { useFormContext, useWatch } from "react-hook-form";
import {
  PmChecklistControllerGetPmChecklistStatusCountParams,
  PmChecklistStatus,
} from "../../../../api/model";
import { usePmChecklistControllerGetPmChecklistStatusCount } from "../../../../api/preventive-maintenance-checklists/preventive-maintenance-checklists";
import { useAppSelector } from "../../../../redux/store";
import { useSelectPmFilterStore } from "../../../../stores/pm/pmFilterStore";
import useDebounce from "../../../../hooks/useDebounce";

interface PmTabWithCountProps {
  pmStatus: PmChecklistStatus;
}

export default function PmTabWithCount({ pmStatus }: PmTabWithCountProps) {
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dateRange = useSelectPmFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectPmFilterStore((state) => state.assetTypes);
  const filterLocation = useSelectPmFilterStore(
    (state) => state.filterLocation
  );

  const queryArg =
    useDebounce<PmChecklistControllerGetPmChecklistStatusCountParams>({
      value: {
        projectId: activeProj?.id?.toString() ?? "",
        filterEndDateISO: dateRange?.to?.toISOString(),
        filterStartDateISO: dateRange?.from?.toISOString(),
        assetTypeIds: assetTypesFilter.map((at) => at?.id?.toString() ?? "0"),
        search,
        locationId: filterLocation?.id?.toString() ?? "",
      },
      delay: 500,
    });

  const { data: pmStatusCount } =
    usePmChecklistControllerGetPmChecklistStatusCount(
      pmStatus.id.toString(),
      queryArg,
      {
        query: {
          enabled: !!pmStatus,
        },
      }
    );

  return <p>{`${pmStatus.name} (${pmStatusCount?.data ?? "-"})`}</p>;
}
