import { Typography } from "@mui/material";
import { Attachment, PartialAtt } from "../redux/slices/OpenApi/cerevApi";

interface AppMultiImageProps {
  attachments?: Attachment[] | PartialAtt[];
  className?: string;
  hideMoreThanThree?: boolean;
}

export default function AppMultiImage({
  attachments,
  className,
  hideMoreThanThree,
}: AppMultiImageProps) {
  return (
    <>
      {attachments?.length ? (
        <div className="flex gap-2 overflow-x-auto items-center">
          {attachments.map((att, idx) => {
            if (idx > 3 && hideMoreThanThree) return null;
            if (idx === 3 && hideMoreThanThree && attachments.length > 4)
              return (
                <p className="font-sans text-primary-900" key={att.url}>
                  +{attachments.length - idx} more
                </p>
              );
            return (
              <img
                key={att.url}
                src={att.url}
                className={`w-24 h-24 rounded-xl shadow-xl object-cover ${className} hover:cursor-pointer`}
                onClick={() => {
                  window.open(att.url, "_blank");
                }}
              />
            );
          })}
        </div>
      ) : (
        <div
          className={`w-24 h-24 rounded-xl shadow-xl flex items-center justify-center ${className}`}
        >
          no image
        </div>
      )}
    </>
  );
}
