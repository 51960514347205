import { useSearchParams } from "react-router-dom";
import { useCommentControllerGetLogs } from "../../../../../../api/comments/comments";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { workOrderLogsColumn } from "./WorkOrderLogsColumn";
import { DataTable } from "../../../../../react-table/DataTable";
import { useAppSelector } from "../../../../../../redux/store";

export default function LogsSection() {
  const [searchParam] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const {
    data: logs,
    isLoading,
    isFetching,
  } = useCommentControllerGetLogs(
    {
      workOrderId: activeWoId as string,
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeWoId && !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  const table = useReactTable({
    data: logs ?? [],
    columns: workOrderLogsColumn,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <DataTable
      isLoading={isLoading}
      isFetching={isFetching}
      table={table}
      totalCount={logs ? logs.length : 0}
    />
  );
}
