import { ColumnDef, Row } from "@tanstack/react-table";
import { LegalCompliance } from "../../../redux/slices/OpenApi/cerevApi";
import useDate from "../../../hooks/useDate";
import { useSearchParams } from "react-router-dom";
import {
  LEGAL_COMPLIANCE_DETAIL_DRAWER,
  DRAWER_VIEW_STATE,
} from "../../../components/drawers/AppDrawer";

const DaysLeftCell = ({ row }: { row: Row<LegalCompliance> }) => {
  const { diffInDays } = useDate();
  return (
    <div>
      {diffInDays({
        startDateISO: new Date().toISOString(),
        endDateISO: row.getValue("expiryDate") ?? "",
      })}
    </div>
  );
};

const ExpiryDateCell = ({ row }: { row: Row<LegalCompliance> }) => {
  const { formatDateToLocal } = useDate();

  return (
    <div>
      {formatDateToLocal({
        dateStr: row.getValue("expiryDate"),
        formatting: "d MMM yyyy",
      })}
    </div>
  );
};

const LegalComplianceNameNav = ({ row }: { row: Row<LegalCompliance> }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", LEGAL_COMPLIANCE_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("legalComplianceId", row.getValue("id")?.toString() ?? "");
          return p;
        });
      }}
    >
      {row.getValue("name") ?? "-"}
    </div>
  );
};

export const legalComplianceColumns: ColumnDef<LegalCompliance>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <LegalComplianceNameNav row={row} />,
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "expiryDate",
    header: "Expiry",
    cell: ({ row }) => <ExpiryDateCell row={row} />,
  },
  {
    header: "Days Left",
    cell: ({ row }) => <DaysLeftCell row={row} />,
  },
];
