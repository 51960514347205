import { useSearchParams } from "react-router-dom";
import AppUncontrolledWithDialog from "../../../components/dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { useWorkscopeControllerGetWorkScopes } from "../../../api/workscope/workscope";
import { useAppSelector } from "../../../redux/store";
import { selectWorkscopesColumn } from "./columns/selectWorkscopesColumn";
import { useVendorFilterStore } from "../../../stores/vendorFilterStore.ts/vendorFilterStore";
import AppButton from "../../../components/AppButton";

export default function VendorFilterDrawer() {
  const [, setSearchParams] = useSearchParams();
  const clearAll = useVendorFilterStore((state) => state.cleanUp);

  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: workscopes } = useWorkscopeControllerGetWorkScopes({
    companyId: activeComp?.id.toString() ?? "",
  });
  const workscopesFilter = useVendorFilterStore(state => state.workscopes);
  const setWorkscopes = useVendorFilterStore(state => state.setWorkscopes);

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-sans text-2xl font-bold">Filter By</h2>
      <AppUncontrolledWithDialog
        placeholder="Select Workscope"
        label="Workscopes"
        items={workscopes?.data ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Workscopes"
        columns={selectWorkscopesColumn}
        multiple
        value={workscopesFilter}
        onChange={(value) => {
          if (Array.isArray(value)) setWorkscopes(value);
        }}
      />
            <div className="flex gap-2">
        <AppButton
          label="Confirm"
          onClick={() => {
            setSearchParams(() => {
              return new URLSearchParams();
            });
          }}
        />
        <AppButton
          label="Clear"
          variant="outline"
          onClick={() => {
            clearAll();
          }}
        />
      </div>
    </div>
  );
}
