import Decimal from "decimal.js";
import {
  GetOneWorkOrderResponseDto,
  StockLedgerWithStockType,
} from "../../../../../../api/model";
import InventoryUsageItem from "./InventoryUsageItem";
import { copyFile } from "fs";

interface InventoryUsageLogsProps {
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function InventoryUsageLogs({
  workOrder,
}: InventoryUsageLogsProps) {
  const ledgers = workOrder?.stockLedgers ?? [];

  const toRender = [...ledgers].reduce((prev, curr) => {
    const idenLedger = prev.find((st) => st.stock.id === curr.stock.id);
    if (idenLedger) {
      const newQuantity = new Decimal(idenLedger.adjustmentQty)
        .add(curr.adjustmentQty)
        .toNumber();

      // Need to create new instance, so that the reference is different
      const updatedLedger = {
        ...idenLedger,
        adjustmentQty: newQuantity,
      };
      prev = prev.map((st) =>
        st.stock.id === curr.stock.id ? updatedLedger : st
      );
    } else {
      prev.push(curr);
    }
    return prev;
  }, [] as StockLedgerWithStockType[]);

  if (!workOrder) return <></>;

  return (
    <>
      {toRender.map((tr) => (
        <InventoryUsageItem key={tr.id} st={tr} workOrder={workOrder} />
      ))}
    </>
  );
}
