import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { useWorkOrderV2ControllerBulkAndLinkEquipment } from "../../../../api/work-order-defect-v2/work-order-defect-v2";
import {
  useRowSelectionState,
  useWorkOrderIsMultiActionMode,
  useWorkOrderMultiSelectActions,
} from "../../../../stores/workOrderMultiActionStore/workOrderMultiActionStore";
import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AppButton from "../../../../components/AppButton";
import { Link, X } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import AppSelectWithDialog from "../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { assetColumns } from "../../../../components/drawers/workOrders/columns/assetColumns";
import { useAppSelector } from "../../../../redux/store";
import { useAssetControllerGetFullAssets } from "../../../../api/assets/assets";

const linkEquipmentSchema = z.object({
  equipment: z
    .object(
      {
        id: z.number(),
        name: z.string(),
      },
      {
        required_error: "Equipment is required",
      }
    )
    .required(),
});

type LinkEquipmentFormType = z.infer<typeof linkEquipmentSchema>;

export default function MultiLinkEquipment() {
  const qc = useQueryClient();
  const rowsSelect = useRowSelectionState();
  const linkingMode = useWorkOrderIsMultiActionMode();
  const { setIsMultiActionMode } = useWorkOrderMultiSelectActions();
  const rowCount = Object.keys(rowsSelect).length;

  const { mutate, isPending } = useWorkOrderV2ControllerBulkAndLinkEquipment({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("defect") ||
            (query.queryKey[0] as string).includes("work-order"),
        });
        setIsMultiActionMode(false);
      },
    },
  });

  const methods = useForm<LinkEquipmentFormType>({
    resolver: zodResolver(linkEquipmentSchema),
  });

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: fullAssetList } = useAssetControllerGetFullAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  // Clear selection equipment states
  useEffect(() => {
    if (!linkingMode) methods.reset();
  }, [linkingMode]);

  const onSubmit: SubmitHandler<LinkEquipmentFormType> = (data) => {
    mutate({
      equipmentId: data.equipment.id,
      data: {
        workOrderIds: Object.keys(rowsSelect).map((id) => parseInt(id)),
      },
    });
  };

  return (
    <>
      <div className=" bg-slate-900 px-4 rounded-l-2xl rounded-s-2xl text-2xl h-full flex items-center justify-center">
        <p className="font-bold text-white">{rowCount ?? 0}</p>
      </div>
      <p className="text-sm text-white">Work orders selected</p>
      <FormProvider {...methods}>
        <AppSelectWithDialog
          showArrowRight={false}
          placeholder="Select an Equipment"
          columns={assetColumns}
          defaultValue={undefined}
          control={methods.control}
          name="equipment"
          items={fullAssetList ?? []}
          onResultRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
            </div>
          )}
          onOptionsRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">
                {item?.assetType?.name ?? "-"}
              </div>
            </div>
          )}
          dialogTitle="Select an Equipment"
          error={!!methods.formState.errors.equipment?.message}
          helperText={methods.formState.errors.equipment?.message}
          errorClassName="text-red-400"
        />
      </FormProvider>
      <AppButton
        variant="outline"
        label="Link"
        icon={<Link />}
        onClick={methods.handleSubmit(onSubmit)}
        isLoading={isPending}
      />
      <Button
        variant="ghost"
        className="hover:cursor-pointer hover:bg-primary-700 hover:bg-transparent"
        onClick={() => {
          setIsMultiActionMode(false);
        }}
      >
        <X className="text-primary-900 font-bold" />
      </Button>
    </>
  );
}
