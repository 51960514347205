import { useEffect, useState } from "react";
import useIsCloseWorkOrder from "../hooks/useIsCloseWorkOrder";
import { useGetUserDataQuery } from "../redux/slices/Auth/AuthApi";
import {
  DefectStatus,
  GetOneWorkOrderResponseDto,
  useDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQuery,
} from "../redux/slices/OpenApi/cerevApi";
import { DialogType, setOpenDialog } from "../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Button } from "./ui/button";
import { Loader2, Pencil } from "lucide-react";
import { cn } from "../utils/util";
import {
  useDefectControllerGetOneDefect,
  useDefectControllerUpdateDefect,
} from "../api/work-orders-defects/work-orders-defects";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useDefectStatusV2ControllerGetProjectBasedWorkOrderStatus } from "../api/defect-status-v2/defect-status-v2";
import { useHasWorkOrderEditAfterClosePolicy } from "../hooks/usePolicies/usePolicies";

interface AppWorkOrderStatsUpdateMenuProps {
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function AppWorkOrderStatusUpdateMenu({
  workOrder,
}: AppWorkOrderStatsUpdateMenuProps) {
  const [searchParam] = useSearchParams();
  const qc = useQueryClient();
  const dispatch = useAppDispatch();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isClose = useIsCloseWorkOrder({ workOrder });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { data: user } = useGetUserDataQuery();

  const hasEditAfterClosePolicy = useHasWorkOrderEditAfterClosePolicy();

  const {
    mutateAsync: updateWorkStatus,
    isPending: updateWorkStatusIsLoading,
  } = useDefectControllerUpdateDefect({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (qry) => {
            return (
              (qry.queryKey[0] as string).includes("work-order") ||
              (qry.queryKey[0] as string).includes("defect")
            );
          },
        });
      },
    },
  });

  const activeWoId = searchParam.get("workOrderId");
  const { isFetching } = useDefectControllerGetOneDefect(activeWoId ?? "");

  const { data: workOrderStatuses } =
    useDefectStatusV2ControllerGetProjectBasedWorkOrderStatus(
      {
        roleId:
          user?.roles
            ?.filter((role) => role.projectId === activeProj?.id ?? "")[0]
            ?.id?.toString() ?? "",
      },
      {
        query: {
          enabled:
            !!activeProj &&
            !!user?.roles?.filter(
              (role) => role.projectId === activeProj?.id ?? ""
            )[0],
          select: (res) => res.data,
        },
      }
    );

  const handleClose = async (workOrderStatus: DefectStatus) => {
    // If its close, need to spawn dialog to ask user to add rating and closing remark
    if (workOrderStatus.name === "Close") {
      dispatch(
        setOpenDialog({
          title: "Close Work Order",
          type: DialogType.WorkOrderConfirmClose,
        })
      );
      return setAnchorEl(null);
    }

    if (
      workOrderStatus &&
      workOrder &&
      user &&
      activeProj &&
      workOrder.id &&
      activeProj.id
    ) {
      updateWorkStatus({
        defectId: workOrder.id?.toString() ?? "",
        data: {
          defectId: workOrder.id,
          defectStatusId: workOrderStatus.id,
          updatedById: user.id,
          type: "CHANGE_STATUS",
          projectId: activeProj.id,
        },
      });
    }
    setAnchorEl(null);
  };

  if (isClose && !hasEditAfterClosePolicy) return <></>;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" data-testid="update-workorder-status-pen">
          {updateWorkStatusIsLoading || isFetching ? (
            <Loader2 className="h-6 w-6 animate-spin text-slate-800" />
          ) : (
            <Pencil className="text-primary-900" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {workOrderStatuses
          ?.filter((sta) => sta?.id !== workOrder?.defectStatusId)
          ?.map((status) => {
            return (
              <DropdownMenuItem
                key={status.id}
                onClick={() => handleClose(status)}
              >
                <div className="flex gap-4 items-center">
                  <div
                    className={cn("h-5 w-5 rounded")}
                    style={{ backgroundColor: `#${status.colorHex}` }}
                  ></div>
                  <p className="font-sans">{status.name}</p>
                </div>
              </DropdownMenuItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
