import { ScrollText } from "lucide-react";
import AppButton from "../../../../AppButton";
import { useCallback, useState } from "react";
import { DateRange } from "react-day-picker";
import {
  Dialog,
  DialogDescription,
  DialogTitle,
  DialogContent,
  DialogFooter,
} from "../../../../ui/dialog";
import { AppDateRangePicker } from "../../../../AppDateRangePicker";
import { useStocksControllerGetInventoryMovementPdf } from "../../../../../api/stocks-inventory/stocks-inventory";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

export default function ExportInventoryPdfBtn() {
  const [open, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");
  const qc = useQueryClient();
  const [dayRange, setDayRange] = useState<DateRange | undefined>();
  const handleClose = () => {
    setIsOpen(false);
    setDayRange(undefined);
  };

  const { mutate, isPending } = useStocksControllerGetInventoryMovementPdf({
    mutation: {
      onSuccess: (res) => {
        const url = res.data?.url;
        if (url) window.open(url, "__blank");
        setIsOpen(false);
        // qc.invalidateQueries({
        //   predicate: (q) => (q.queryKey[0] as string).includes("stock"),
        // });
      },
    },
  });

  const onSubmit = useCallback(() => {
    if (!inventoryId) return;
    mutate({
      inventoryId,
      data: {
        dateStart: dayRange?.from?.toISOString(),
        dateEnd: dayRange?.to?.toISOString(),
      },
    });
  }, [dayRange]);

  return (
    <>
      <AppButton
        label="Export Pdf"
        variant="outline"
        icon={<ScrollText />}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <Dialog
        open={open}
        onOpenChange={(o) => {
          if (!o) handleClose();
        }}
      >
        <DialogContent>
          <DialogTitle>Export Inventory Movement Report</DialogTitle>
          <DialogDescription>Select a date range to report</DialogDescription>
          <AppDateRangePicker
            date={dayRange}
            setDate={setDayRange}
            onClear={() => setDayRange(undefined)}
          />
          <DialogFooter>
            <AppButton label="Cancel" variant="outline" onClick={handleClose} />
            <AppButton
              label="Export"
              onClick={onSubmit}
              isLoading={isPending}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
