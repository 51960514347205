/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  BulkLinkLocationRequestDto,
  BulkLinkWorkOrderEquipmentRequestDto,
  Defect,
  DeleteWorkOrderRequestDto,
  DeleteWorkOrderStockDto,
  GetManyWorkOrderPdfRequestDto,
  GetPdfReportResponseDto,
  GetPlannedWorkOrderResponseDto,
  GetWorkOrderCountStatusResponse,
  GetWorkOrderSummaryReportPdfResponseDto,
  PatchWorkOrderDto,
  PostPdfReportV2RequestDto,
  UpdateLinkedWorkRequestDto,
  UpdatePlannedTimeRequestDto,
  UpdateWorkOrderPricingRefNoDto,
  UpdateWorkOrderStockDto,
  UpdateWorkOrderVideoDocRequestDto,
  WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams,
  WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams,
  WorkOrderV2ControllerGetWorkOrderStatusWithCountParams,
  WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams,
  WorkOrderV2ControllerGetWorkOrders200Item,
  WorkOrderV2ControllerGetWorkOrdersParams,
  WorkOrderV2ControllerUpdateInventory200,
  WorkOrderV2ControllerUpdateWorkOrder200,
} from ".././model";

export const workOrderV2ControllerGetWorkOrderStatusWithCount = (
  params: WorkOrderV2ControllerGetWorkOrderStatusWithCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkOrderCountStatusResponse>> => {
  return axios.get(`/v2/work-order/status/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkOrderV2ControllerGetWorkOrderStatusWithCountQueryKey = (
  params: WorkOrderV2ControllerGetWorkOrderStatusWithCountParams
) => {
  return [`/v2/work-order/status/count`, ...(params ? [params] : [])] as const;
};

export const getWorkOrderV2ControllerGetWorkOrderStatusWithCountInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
      >,
      WorkOrderV2ControllerGetWorkOrderStatusWithCountParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkOrderV2ControllerGetWorkOrderStatusWithCountParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
          >,
          QueryKey,
          WorkOrderV2ControllerGetWorkOrderStatusWithCountParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkOrderV2ControllerGetWorkOrderStatusWithCountQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
      >,
      QueryKey,
      WorkOrderV2ControllerGetWorkOrderStatusWithCountParams["cursorId"]
    > = ({ signal, pageParam }) =>
      workOrderV2ControllerGetWorkOrderStatusWithCount(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
      >,
      QueryKey,
      WorkOrderV2ControllerGetWorkOrderStatusWithCountParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type WorkOrderV2ControllerGetWorkOrderStatusWithCountInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>>
  >;
export type WorkOrderV2ControllerGetWorkOrderStatusWithCountInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrderStatusWithCountInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
    >,
    WorkOrderV2ControllerGetWorkOrderStatusWithCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrderStatusWithCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
        >,
        QueryKey,
        WorkOrderV2ControllerGetWorkOrderStatusWithCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetWorkOrderStatusWithCountInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkOrderV2ControllerGetWorkOrderStatusWithCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrderStatusWithCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkOrderV2ControllerGetWorkOrderStatusWithCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>>
  > = ({ signal }) =>
    workOrderV2ControllerGetWorkOrderStatusWithCount(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkOrderV2ControllerGetWorkOrderStatusWithCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>>
  >;
export type WorkOrderV2ControllerGetWorkOrderStatusWithCountQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrderStatusWithCount = <
  TData = Awaited<
    ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrderStatusWithCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderStatusWithCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetWorkOrderStatusWithCountQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workOrderV2ControllerGetWorkOrders = (
  params: WorkOrderV2ControllerGetWorkOrdersParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkOrderV2ControllerGetWorkOrders200Item[]>> => {
  return axios.get(`/v2/work-order`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkOrderV2ControllerGetWorkOrdersQueryKey = (
  params: WorkOrderV2ControllerGetWorkOrdersParams
) => {
  return [`/v2/work-order`, ...(params ? [params] : [])] as const;
};

export const getWorkOrderV2ControllerGetWorkOrdersInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
    WorkOrderV2ControllerGetWorkOrdersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrdersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
        QueryKey,
        WorkOrderV2ControllerGetWorkOrdersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkOrderV2ControllerGetWorkOrdersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
    QueryKey,
    WorkOrderV2ControllerGetWorkOrdersParams["cursorId"]
  > = ({ signal, pageParam }) =>
    workOrderV2ControllerGetWorkOrders(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
    TError,
    TData,
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
    QueryKey,
    WorkOrderV2ControllerGetWorkOrdersParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type WorkOrderV2ControllerGetWorkOrdersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>
>;
export type WorkOrderV2ControllerGetWorkOrdersInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrdersInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
    WorkOrderV2ControllerGetWorkOrdersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrdersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
        QueryKey,
        WorkOrderV2ControllerGetWorkOrdersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetWorkOrdersInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkOrderV2ControllerGetWorkOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrdersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkOrderV2ControllerGetWorkOrdersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>
  > = ({ signal }) =>
    workOrderV2ControllerGetWorkOrders(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkOrderV2ControllerGetWorkOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>
>;
export type WorkOrderV2ControllerGetWorkOrdersQueryError = AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrders = <
  TData = Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrdersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetWorkOrders>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkOrderV2ControllerGetWorkOrdersQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workOrderV2ControllerGetPlannedWorkOrdersToday = (
  params: WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPlannedWorkOrderResponseDto>> => {
  return axios.get(`/v2/work-order/planned-today`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkOrderV2ControllerGetPlannedWorkOrdersTodayQueryKey = (
  params: WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams
) => {
  return [`/v2/work-order/planned-today`, ...(params ? [params] : [])] as const;
};

export const getWorkOrderV2ControllerGetPlannedWorkOrdersTodayInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
      >,
      WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
          >,
          QueryKey,
          WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkOrderV2ControllerGetPlannedWorkOrdersTodayQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
      >,
      QueryKey,
      WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams["cursorId"]
    > = ({ signal, pageParam }) =>
      workOrderV2ControllerGetPlannedWorkOrdersToday(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
      >,
      QueryKey,
      WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type WorkOrderV2ControllerGetPlannedWorkOrdersTodayInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>>
  >;
export type WorkOrderV2ControllerGetPlannedWorkOrdersTodayInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetPlannedWorkOrdersTodayInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>>,
    WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
        >,
        QueryKey,
        WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetPlannedWorkOrdersTodayInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkOrderV2ControllerGetPlannedWorkOrdersTodayQueryOptions = <
  TData = Awaited<
    ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkOrderV2ControllerGetPlannedWorkOrdersTodayQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>>
  > = ({ signal }) =>
    workOrderV2ControllerGetPlannedWorkOrdersToday(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkOrderV2ControllerGetPlannedWorkOrdersTodayQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>>
  >;
export type WorkOrderV2ControllerGetPlannedWorkOrdersTodayQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetPlannedWorkOrdersToday = <
  TData = Awaited<
    ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetPlannedWorkOrdersTodayParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetPlannedWorkOrdersToday>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetPlannedWorkOrdersTodayQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workOrderV2ControllerGetPdfReport = (
  workOrderId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Defect>> => {
  return axios.get(`/v2/work-order/${workOrderId}/pdf`, options);
};

export const getWorkOrderV2ControllerGetPdfReportQueryKey = (
  workOrderId: string
) => {
  return [`/v2/work-order/${workOrderId}/pdf`] as const;
};

export const getWorkOrderV2ControllerGetPdfReportInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>
  >,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkOrderV2ControllerGetPdfReportQueryKey(workOrderId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>
  > = ({ signal }) =>
    workOrderV2ControllerGetPdfReport(workOrderId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!workOrderId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkOrderV2ControllerGetPdfReportInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>
>;
export type WorkOrderV2ControllerGetPdfReportInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetPdfReportInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>
  >,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkOrderV2ControllerGetPdfReportInfiniteQueryOptions(
    workOrderId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkOrderV2ControllerGetPdfReportQueryOptions = <
  TData = Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkOrderV2ControllerGetPdfReportQueryKey(workOrderId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>
  > = ({ signal }) =>
    workOrderV2ControllerGetPdfReport(workOrderId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!workOrderId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkOrderV2ControllerGetPdfReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>
>;
export type WorkOrderV2ControllerGetPdfReportQueryError = AxiosError<unknown>;

export const useWorkOrderV2ControllerGetPdfReport = <
  TData = Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workOrderV2ControllerGetPdfReport>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkOrderV2ControllerGetPdfReportQueryOptions(
    workOrderId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workOrderV2ControllerPostPdfReport = (
  workOrderId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Defect | void>> => {
  return axios.post(`/v2/work-order/${workOrderId}/pdf`, undefined, options);
};

export const getWorkOrderV2ControllerPostPdfReportMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReport>>,
    TError,
    { workOrderId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReport>>,
  TError,
  { workOrderId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReport>>,
    { workOrderId: string }
  > = (props) => {
    const { workOrderId } = props ?? {};

    return workOrderV2ControllerPostPdfReport(workOrderId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerPostPdfReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReport>>
>;

export type WorkOrderV2ControllerPostPdfReportMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerPostPdfReport = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReport>>,
    TError,
    { workOrderId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReport>>,
  TError,
  { workOrderId: string },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerPostPdfReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerGetWorkOrderSummaryReportPdf = (
  params: WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkOrderSummaryReportPdfResponseDto[]>> => {
  return axios.get(`/v2/work-order/summary-report-pdf`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkOrderV2ControllerGetWorkOrderSummaryReportPdfQueryKey = (
  params: WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams
) => {
  return [
    `/v2/work-order/summary-report-pdf`,
    ...(params ? [params] : []),
  ] as const;
};

export const getWorkOrderV2ControllerGetWorkOrderSummaryReportPdfInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
      >,
      WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
          >,
          QueryKey,
          WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkOrderV2ControllerGetWorkOrderSummaryReportPdfQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
      >,
      QueryKey,
      WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams["cursorId"]
    > = ({ signal, pageParam }) =>
      workOrderV2ControllerGetWorkOrderSummaryReportPdf(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
      >,
      QueryKey,
      WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type WorkOrderV2ControllerGetWorkOrderSummaryReportPdfInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
    >
  >;
export type WorkOrderV2ControllerGetWorkOrderSummaryReportPdfInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrderSummaryReportPdfInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
    >,
    WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
        >,
        QueryKey,
        WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetWorkOrderSummaryReportPdfInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkOrderV2ControllerGetWorkOrderSummaryReportPdfQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkOrderV2ControllerGetWorkOrderSummaryReportPdfQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
      >
    > = ({ signal }) =>
      workOrderV2ControllerGetWorkOrderSummaryReportPdf(params, {
        signal,
        ...axiosOptions,
      });

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type WorkOrderV2ControllerGetWorkOrderSummaryReportPdfQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
    >
  >;
export type WorkOrderV2ControllerGetWorkOrderSummaryReportPdfQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrderSummaryReportPdf = <
  TData = Awaited<
    ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrderSummaryReportPdfParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderSummaryReportPdf>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetWorkOrderSummaryReportPdfQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workOrderV2ControllerPostPdfReportV2 = (
  workOrderId: string,
  postPdfReportV2RequestDto: PostPdfReportV2RequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPdfReportResponseDto | void>> => {
  return axios.post(
    `/v2/work-order/${workOrderId}/pdf/v2`,
    postPdfReportV2RequestDto,
    options
  );
};

export const getWorkOrderV2ControllerPostPdfReportV2MutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReportV2>>,
    TError,
    { workOrderId: string; data: PostPdfReportV2RequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReportV2>>,
  TError,
  { workOrderId: string; data: PostPdfReportV2RequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReportV2>>,
    { workOrderId: string; data: PostPdfReportV2RequestDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerPostPdfReportV2(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerPostPdfReportV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReportV2>>
>;
export type WorkOrderV2ControllerPostPdfReportV2MutationBody =
  PostPdfReportV2RequestDto;
export type WorkOrderV2ControllerPostPdfReportV2MutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerPostPdfReportV2 = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReportV2>>,
    TError,
    { workOrderId: string; data: PostPdfReportV2RequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerPostPdfReportV2>>,
  TError,
  { workOrderId: string; data: PostPdfReportV2RequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerPostPdfReportV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerPostMultiplePdfReport = (
  getManyWorkOrderPdfRequestDto: GetManyWorkOrderPdfRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPdfReportResponseDto | void>> => {
  return axios.post(
    `/v2/work-order/pdf/v2/multiple`,
    getManyWorkOrderPdfRequestDto,
    options
  );
};

export const getWorkOrderV2ControllerPostMultiplePdfReportMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerPostMultiplePdfReport>>,
    TError,
    { data: GetManyWorkOrderPdfRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerPostMultiplePdfReport>>,
  TError,
  { data: GetManyWorkOrderPdfRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerPostMultiplePdfReport>>,
    { data: GetManyWorkOrderPdfRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return workOrderV2ControllerPostMultiplePdfReport(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerPostMultiplePdfReportMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerPostMultiplePdfReport>>
  >;
export type WorkOrderV2ControllerPostMultiplePdfReportMutationBody =
  GetManyWorkOrderPdfRequestDto;
export type WorkOrderV2ControllerPostMultiplePdfReportMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerPostMultiplePdfReport = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerPostMultiplePdfReport>>,
    TError,
    { data: GetManyWorkOrderPdfRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerPostMultiplePdfReport>>,
  TError,
  { data: GetManyWorkOrderPdfRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerPostMultiplePdfReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerUpdateWorkOrder = (
  workOrderId: string,
  patchWorkOrderDto: PatchWorkOrderDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkOrderV2ControllerUpdateWorkOrder200>> => {
  return axios.patch(
    `/v2/work-order/${workOrderId}`,
    patchWorkOrderDto,
    options
  );
};

export const getWorkOrderV2ControllerUpdateWorkOrderMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrder>>,
    TError,
    { workOrderId: string; data: PatchWorkOrderDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrder>>,
  TError,
  { workOrderId: string; data: PatchWorkOrderDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrder>>,
    { workOrderId: string; data: PatchWorkOrderDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerUpdateWorkOrder(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerUpdateWorkOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrder>>
>;
export type WorkOrderV2ControllerUpdateWorkOrderMutationBody =
  PatchWorkOrderDto;
export type WorkOrderV2ControllerUpdateWorkOrderMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerUpdateWorkOrder = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrder>>,
    TError,
    { workOrderId: string; data: PatchWorkOrderDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrder>>,
  TError,
  { workOrderId: string; data: PatchWorkOrderDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerUpdateWorkOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerDeleteWorkOrder = (
  workOrderId: string,
  deleteWorkOrderRequestDto: DeleteWorkOrderRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/v2/work-order/${workOrderId}`, {
    data: deleteWorkOrderRequestDto,
    ...options,
  });
};

export const getWorkOrderV2ControllerDeleteWorkOrderMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrder>>,
    TError,
    { workOrderId: string; data: DeleteWorkOrderRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrder>>,
  TError,
  { workOrderId: string; data: DeleteWorkOrderRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrder>>,
    { workOrderId: string; data: DeleteWorkOrderRequestDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerDeleteWorkOrder(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerDeleteWorkOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrder>>
>;
export type WorkOrderV2ControllerDeleteWorkOrderMutationBody =
  DeleteWorkOrderRequestDto;
export type WorkOrderV2ControllerDeleteWorkOrderMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerDeleteWorkOrder = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrder>>,
    TError,
    { workOrderId: string; data: DeleteWorkOrderRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrder>>,
  TError,
  { workOrderId: string; data: DeleteWorkOrderRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerDeleteWorkOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerUpdateInventory = (
  workOrderId: string,
  updateWorkOrderStockDto: UpdateWorkOrderStockDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkOrderV2ControllerUpdateInventory200>> => {
  return axios.patch(
    `/v2/work-order/${workOrderId}/stock`,
    updateWorkOrderStockDto,
    options
  );
};

export const getWorkOrderV2ControllerUpdateInventoryMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateInventory>>,
    TError,
    { workOrderId: string; data: UpdateWorkOrderStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateInventory>>,
  TError,
  { workOrderId: string; data: UpdateWorkOrderStockDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateInventory>>,
    { workOrderId: string; data: UpdateWorkOrderStockDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerUpdateInventory(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerUpdateInventoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateInventory>>
>;
export type WorkOrderV2ControllerUpdateInventoryMutationBody =
  UpdateWorkOrderStockDto;
export type WorkOrderV2ControllerUpdateInventoryMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerUpdateInventory = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateInventory>>,
    TError,
    { workOrderId: string; data: UpdateWorkOrderStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateInventory>>,
  TError,
  { workOrderId: string; data: UpdateWorkOrderStockDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerUpdateInventoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerDeleteInventory = (
  workOrderId: string,
  deleteWorkOrderStockDto: DeleteWorkOrderStockDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/v2/work-order/${workOrderId}/stock`, {
    data: deleteWorkOrderStockDto,
    ...options,
  });
};

export const getWorkOrderV2ControllerDeleteInventoryMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteInventory>>,
    TError,
    { workOrderId: string; data: DeleteWorkOrderStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteInventory>>,
  TError,
  { workOrderId: string; data: DeleteWorkOrderStockDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteInventory>>,
    { workOrderId: string; data: DeleteWorkOrderStockDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerDeleteInventory(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerDeleteInventoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteInventory>>
>;
export type WorkOrderV2ControllerDeleteInventoryMutationBody =
  DeleteWorkOrderStockDto;
export type WorkOrderV2ControllerDeleteInventoryMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerDeleteInventory = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteInventory>>,
    TError,
    { workOrderId: string; data: DeleteWorkOrderStockDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteInventory>>,
  TError,
  { workOrderId: string; data: DeleteWorkOrderStockDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerDeleteInventoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerUpdateLinkedWorkRequest = (
  workOrderId: string,
  updateLinkedWorkRequestDto: UpdateLinkedWorkRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/work-order/${workOrderId}/workRequest`,
    updateLinkedWorkRequestDto,
    options
  );
};

export const getWorkOrderV2ControllerUpdateLinkedWorkRequestMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateLinkedWorkRequest>>,
    TError,
    { workOrderId: string; data: UpdateLinkedWorkRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateLinkedWorkRequest>>,
  TError,
  { workOrderId: string; data: UpdateLinkedWorkRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateLinkedWorkRequest>>,
    { workOrderId: string; data: UpdateLinkedWorkRequestDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerUpdateLinkedWorkRequest(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerUpdateLinkedWorkRequestMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateLinkedWorkRequest>>
  >;
export type WorkOrderV2ControllerUpdateLinkedWorkRequestMutationBody =
  UpdateLinkedWorkRequestDto;
export type WorkOrderV2ControllerUpdateLinkedWorkRequestMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerUpdateLinkedWorkRequest = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateLinkedWorkRequest>>,
    TError,
    { workOrderId: string; data: UpdateLinkedWorkRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateLinkedWorkRequest>>,
  TError,
  { workOrderId: string; data: UpdateLinkedWorkRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerUpdateLinkedWorkRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerRemoveLinkedWorkRequest = (
  workOrderId: string,
  workRequestId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/work-order/${workOrderId}/workRequest/${workRequestId}`,
    undefined,
    options
  );
};

export const getWorkOrderV2ControllerRemoveLinkedWorkRequestMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerRemoveLinkedWorkRequest>>,
    TError,
    { workOrderId: string; workRequestId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerRemoveLinkedWorkRequest>>,
  TError,
  { workOrderId: string; workRequestId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerRemoveLinkedWorkRequest>>,
    { workOrderId: string; workRequestId: string }
  > = (props) => {
    const { workOrderId, workRequestId } = props ?? {};

    return workOrderV2ControllerRemoveLinkedWorkRequest(
      workOrderId,
      workRequestId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerRemoveLinkedWorkRequestMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerRemoveLinkedWorkRequest>>
  >;

export type WorkOrderV2ControllerRemoveLinkedWorkRequestMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerRemoveLinkedWorkRequest = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerRemoveLinkedWorkRequest>>,
    TError,
    { workOrderId: string; workRequestId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerRemoveLinkedWorkRequest>>,
  TError,
  { workOrderId: string; workRequestId: string },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerRemoveLinkedWorkRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerUpdateWorkOrderPricingRefNo = (
  workOrderId: number,
  updateWorkOrderPricingRefNoDto: UpdateWorkOrderPricingRefNoDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Defect>> => {
  return axios.patch(
    `/v2/work-order/${workOrderId}/pricing`,
    updateWorkOrderPricingRefNoDto,
    options
  );
};

export const getWorkOrderV2ControllerUpdateWorkOrderPricingRefNoMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof workOrderV2ControllerUpdateWorkOrderPricingRefNo>
      >,
      TError,
      { workOrderId: number; data: UpdateWorkOrderPricingRefNoDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof workOrderV2ControllerUpdateWorkOrderPricingRefNo>
    >,
    TError,
    { workOrderId: number; data: UpdateWorkOrderPricingRefNoDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof workOrderV2ControllerUpdateWorkOrderPricingRefNo>
      >,
      { workOrderId: number; data: UpdateWorkOrderPricingRefNoDto }
    > = (props) => {
      const { workOrderId, data } = props ?? {};

      return workOrderV2ControllerUpdateWorkOrderPricingRefNo(
        workOrderId,
        data,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type WorkOrderV2ControllerUpdateWorkOrderPricingRefNoMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrderPricingRefNo>>
  >;
export type WorkOrderV2ControllerUpdateWorkOrderPricingRefNoMutationBody =
  UpdateWorkOrderPricingRefNoDto;
export type WorkOrderV2ControllerUpdateWorkOrderPricingRefNoMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerUpdateWorkOrderPricingRefNo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof workOrderV2ControllerUpdateWorkOrderPricingRefNo>
    >,
    TError,
    { workOrderId: number; data: UpdateWorkOrderPricingRefNoDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdateWorkOrderPricingRefNo>>,
  TError,
  { workOrderId: number; data: UpdateWorkOrderPricingRefNoDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerUpdateWorkOrderPricingRefNoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerRemovePricingAttachment = (
  workOrderId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `/v2/work-order/${workOrderId}/pricing/${attachmentId}`,
    options
  );
};

export const getWorkOrderV2ControllerRemovePricingAttachmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerRemovePricingAttachment>>,
    TError,
    { workOrderId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerRemovePricingAttachment>>,
  TError,
  { workOrderId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerRemovePricingAttachment>>,
    { workOrderId: string; attachmentId: string }
  > = (props) => {
    const { workOrderId, attachmentId } = props ?? {};

    return workOrderV2ControllerRemovePricingAttachment(
      workOrderId,
      attachmentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerRemovePricingAttachmentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerRemovePricingAttachment>>
  >;

export type WorkOrderV2ControllerRemovePricingAttachmentMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerRemovePricingAttachment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerRemovePricingAttachment>>,
    TError,
    { workOrderId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerRemovePricingAttachment>>,
  TError,
  { workOrderId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerRemovePricingAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerUpdatePlannedTime = (
  workOrderId: number,
  updatePlannedTimeRequestDto: UpdatePlannedTimeRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/work-order/${workOrderId}/plannedTime`,
    updatePlannedTimeRequestDto,
    options
  );
};

export const getWorkOrderV2ControllerUpdatePlannedTimeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdatePlannedTime>>,
    TError,
    { workOrderId: number; data: UpdatePlannedTimeRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdatePlannedTime>>,
  TError,
  { workOrderId: number; data: UpdatePlannedTimeRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdatePlannedTime>>,
    { workOrderId: number; data: UpdatePlannedTimeRequestDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerUpdatePlannedTime(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerUpdatePlannedTimeMutationResult = NonNullable<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdatePlannedTime>>
>;
export type WorkOrderV2ControllerUpdatePlannedTimeMutationBody =
  UpdatePlannedTimeRequestDto;
export type WorkOrderV2ControllerUpdatePlannedTimeMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerUpdatePlannedTime = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerUpdatePlannedTime>>,
    TError,
    { workOrderId: number; data: UpdatePlannedTimeRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerUpdatePlannedTime>>,
  TError,
  { workOrderId: number; data: UpdatePlannedTimeRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerUpdatePlannedTimeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerGetWorkOrderBasedOnPlannedTime = (
  params: WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPlannedWorkOrderResponseDto>> => {
  return axios.get(`/v2/work-order/scheduled`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeQueryKey = (
  params: WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams
) => {
  return [`/v2/work-order/scheduled`, ...(params ? [params] : [])] as const;
};

export const getWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
      >,
      WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime
            >
          >,
          TError,
          TData,
          Awaited<
            ReturnType<
              typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime
            >
          >,
          QueryKey,
          WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
      >,
      QueryKey,
      WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams["cursorId"]
    > = ({ signal, pageParam }) =>
      workOrderV2ControllerGetWorkOrderBasedOnPlannedTime(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
      >,
      QueryKey,
      WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
    >
  >;
export type WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
    >,
    WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
        >,
        QueryKey,
        WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
      >
    > = ({ signal }) =>
      workOrderV2ControllerGetWorkOrderBasedOnPlannedTime(params, {
        signal,
        ...axiosOptions,
      });

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
    >
  >;
export type WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeQueryError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTime = <
  TData = Awaited<
    ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workOrderV2ControllerGetWorkOrderBasedOnPlannedTime>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workOrderV2ControllerBulkAndLinkEquipment = (
  equipmentId: number,
  bulkLinkWorkOrderEquipmentRequestDto: BulkLinkWorkOrderEquipmentRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/work-order/equipment/${equipmentId}`,
    bulkLinkWorkOrderEquipmentRequestDto,
    options
  );
};

export const getWorkOrderV2ControllerBulkAndLinkEquipmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkEquipment>>,
    TError,
    { equipmentId: number; data: BulkLinkWorkOrderEquipmentRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkEquipment>>,
  TError,
  { equipmentId: number; data: BulkLinkWorkOrderEquipmentRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkEquipment>>,
    { equipmentId: number; data: BulkLinkWorkOrderEquipmentRequestDto }
  > = (props) => {
    const { equipmentId, data } = props ?? {};

    return workOrderV2ControllerBulkAndLinkEquipment(
      equipmentId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerBulkAndLinkEquipmentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkEquipment>>
  >;
export type WorkOrderV2ControllerBulkAndLinkEquipmentMutationBody =
  BulkLinkWorkOrderEquipmentRequestDto;
export type WorkOrderV2ControllerBulkAndLinkEquipmentMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerBulkAndLinkEquipment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkEquipment>>,
    TError,
    { equipmentId: number; data: BulkLinkWorkOrderEquipmentRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkEquipment>>,
  TError,
  { equipmentId: number; data: BulkLinkWorkOrderEquipmentRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerBulkAndLinkEquipmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerBulkAndLinkLocation = (
  locationId: string,
  bulkLinkLocationRequestDto: BulkLinkLocationRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/work-order/location/${locationId}`,
    bulkLinkLocationRequestDto,
    options
  );
};

export const getWorkOrderV2ControllerBulkAndLinkLocationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkLocation>>,
    TError,
    { locationId: string; data: BulkLinkLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkLocation>>,
  TError,
  { locationId: string; data: BulkLinkLocationRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkLocation>>,
    { locationId: string; data: BulkLinkLocationRequestDto }
  > = (props) => {
    const { locationId, data } = props ?? {};

    return workOrderV2ControllerBulkAndLinkLocation(
      locationId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerBulkAndLinkLocationMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkLocation>>
  >;
export type WorkOrderV2ControllerBulkAndLinkLocationMutationBody =
  BulkLinkLocationRequestDto;
export type WorkOrderV2ControllerBulkAndLinkLocationMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerBulkAndLinkLocation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkLocation>>,
    TError,
    { locationId: string; data: BulkLinkLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerBulkAndLinkLocation>>,
  TError,
  { locationId: string; data: BulkLinkLocationRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerBulkAndLinkLocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerDeleteWorkOrderVideoDoc = (
  workOrderId: number,
  docId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/v2/work-order/${workOrderId}/doc/${docId}`, options);
};

export const getWorkOrderV2ControllerDeleteWorkOrderVideoDocMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrderVideoDoc>>,
    TError,
    { workOrderId: number; docId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrderVideoDoc>>,
  TError,
  { workOrderId: number; docId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrderVideoDoc>>,
    { workOrderId: number; docId: number }
  > = (props) => {
    const { workOrderId, docId } = props ?? {};

    return workOrderV2ControllerDeleteWorkOrderVideoDoc(
      workOrderId,
      docId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerDeleteWorkOrderVideoDocMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrderVideoDoc>>
  >;

export type WorkOrderV2ControllerDeleteWorkOrderVideoDocMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerDeleteWorkOrderVideoDoc = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrderVideoDoc>>,
    TError,
    { workOrderId: number; docId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerDeleteWorkOrderVideoDoc>>,
  TError,
  { workOrderId: number; docId: number },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerDeleteWorkOrderVideoDocMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workOrderV2ControllerAddWorkOrderVideoDoc = (
  workOrderId: number,
  updateWorkOrderVideoDocRequestDto: UpdateWorkOrderVideoDocRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/work-order/${workOrderId}/doc`,
    updateWorkOrderVideoDocRequestDto,
    options
  );
};

export const getWorkOrderV2ControllerAddWorkOrderVideoDocMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerAddWorkOrderVideoDoc>>,
    TError,
    { workOrderId: number; data: UpdateWorkOrderVideoDocRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workOrderV2ControllerAddWorkOrderVideoDoc>>,
  TError,
  { workOrderId: number; data: UpdateWorkOrderVideoDocRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workOrderV2ControllerAddWorkOrderVideoDoc>>,
    { workOrderId: number; data: UpdateWorkOrderVideoDocRequestDto }
  > = (props) => {
    const { workOrderId, data } = props ?? {};

    return workOrderV2ControllerAddWorkOrderVideoDoc(
      workOrderId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkOrderV2ControllerAddWorkOrderVideoDocMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workOrderV2ControllerAddWorkOrderVideoDoc>>
  >;
export type WorkOrderV2ControllerAddWorkOrderVideoDocMutationBody =
  UpdateWorkOrderVideoDocRequestDto;
export type WorkOrderV2ControllerAddWorkOrderVideoDocMutationError =
  AxiosError<unknown>;

export const useWorkOrderV2ControllerAddWorkOrderVideoDoc = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workOrderV2ControllerAddWorkOrderVideoDoc>>,
    TError,
    { workOrderId: number; data: UpdateWorkOrderVideoDocRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workOrderV2ControllerAddWorkOrderVideoDoc>>,
  TError,
  { workOrderId: number; data: UpdateWorkOrderVideoDocRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkOrderV2ControllerAddWorkOrderVideoDocMutationOptions(options);

  return useMutation(mutationOptions);
};
