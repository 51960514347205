import { ColumnDef } from "@tanstack/react-table";
import { User } from "../../../../../../../../api/model";
import useDate from "../../../../../../../../hooks/useDate";

export const userTabColumns: ColumnDef<User>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "position",
    header: "Position",
  },
  {
    id: "createdOn",
    accessorKey: "createdOn",
    header: "Created On",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <p>
          {formatDateToLocal({
            dateStr: row.getValue("createdOn"),
            formatting: "dd MMM yy, hh:mm a",
          })}
        </p>
      );
    },
  },
];
