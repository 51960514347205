import { z } from "zod";
import { EscalationType, WorkRequestStatus } from "../../../api/model";

export const EscalationFormSchema = z.object({
  name: z.string().min(1, "Name is Required"),
  numberOfDays: z.coerce
    .number()
    .int()
    .positive()
    .min(1, "Number of Days is Required"),
  notifyUsers: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array()
    .min(1, "Please select at least one user to notify"),
  escalationStatuses: z
    .object({
      escalationType: z.enum([
        EscalationType.WORK_ORDER,
        EscalationType.PM,
        EscalationType.WORK_REQUEST,
      ]),
      workOrderStatuses: z
        .object({
          id: z.number(),
          name: z.string(),
        })
        .array(),
      pmStatuses: z
        .object({
          id: z.number(),
          name: z.string(),
        })
        .array(),
      workRequestStatuses: z
        .object({
          id: z.number(),
          name: z.string(),
          value: z.enum([
            WorkRequestStatus.NEW,
            WorkRequestStatus.IN_PROGRESS,
            WorkRequestStatus.COMPLETED,
            WorkRequestStatus.CANCELLED,
          ]),
        })
        .array(),
    })
    .refine(
      (schema) => {
        if (schema.escalationType === EscalationType.WORK_ORDER) {
          return schema.workOrderStatuses.length > 0;
        }
        return true;
      },
      {
        message: "Please select at least one work order status",
        path: ["workOrderStatuses"],
      }
    )
    .refine(
      (schema) => {
        if (schema.escalationType === EscalationType.PM) {
          return schema.pmStatuses.length > 0;
        }
        return true;
      },
      {
        path: ["pmStatuses"],
        message: "Please select at least one PM status",
      }
    )
    .refine(
      (schema) => {
        if (schema.escalationType === EscalationType.WORK_REQUEST) {
          return schema.workRequestStatuses.length > 0;
        }
        return true;
      },
      {
        path: ["workRequestStatuses"],
        message: "Please select at least one work request status",
      }
    ),
});

export type EscalationForm = z.infer<typeof EscalationFormSchema>;
