
export default function PreventiveMaintenanceLogo() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <g clipPath="url(#clip0_485_158)">
        <path
          d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
          fill="#A2DAE0"
        />
        <path
          d="M24.5 45.5C36.3741 45.5 46 45.1642 46 44.75C46 44.3358 36.3741 44 24.5 44C12.6259 44 3 44.3358 3 44.75C3 45.1642 12.6259 45.5 24.5 45.5Z"
          fill="#85B6D6"
        />
        <path
          d="M14 30.5862L18.3213 24.5862L28.0166 23L34 27.9569L33.5647 32.2328L25.1436 38L19.2078 35.8621L14 30.5862Z"
          fill="#FFDE8F"
          stroke="#FFDE8F"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M35.6405 27C35.9894 28.7575 36.085 30.5577 35.9244 32.3435C35.867 32.9352 35.6751 33.5051 35.364 34.0076C34.46 34.9237 32.7789 34.6412 32.2186 34.5802C30.53 34.4122 27.8851 35.0458 24 39"
          fill="#FFDE8F"
        />
        <path
          d="M35.6405 27C35.9894 28.7575 36.085 30.5577 35.9244 32.3435C35.867 32.9352 35.6751 33.5051 35.364 34.0076C34.46 34.9237 32.7789 34.6412 32.2186 34.5802C30.53 34.4122 27.8851 35.0458 24 39"
          stroke="#FFDE8F"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M20.9004 16.8279C20.3086 17.0006 19.6854 17.044 19.0747 16.9551C18.2202 16.9057 17.3843 16.6903 16.6156 16.3215C15.8469 15.9527 15.1608 15.4377 14.5971 14.8067C14.0335 14.1757 13.6036 13.4411 13.3324 12.6458C13.0613 11.8505 12.9543 11.0103 13.0178 10.1741C13.0458 9.28351 13.2889 8.41206 13.7273 7.63129L17.6383 12.0304L21.16 8.84339L17.249 4.40187C18.0987 4.07626 19.0134 3.94577 19.9227 4.02044C20.7807 4.07076 21.6198 4.28821 22.391 4.66007C23.1621 5.03193 23.8499 5.55073 24.4139 6.1861C24.9779 6.82148 25.4069 7.56067 25.6757 8.36043C25.9445 9.16018 26.0479 10.0044 25.9796 10.8438C25.9521 11.4955 25.8207 12.139 25.5902 12.7509"
          fill="#ABABAB"
        />
        <path
          d="M24 15L31 24"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M34 27L32 25"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M27.7894 17.7476C27.6124 17.624 27.4566 17.4567 27.3309 17.2557C27.2052 17.0546 27.1122 16.8237 27.0573 16.5764C27.0024 16.3291 26.9867 16.0704 27.0112 15.8154C27.0357 15.5603 27.0998 15.314 27.1998 15.0908L28.1314 13.0044C28.2279 12.7793 28.3587 12.5811 28.5162 12.4212C28.6736 12.2613 28.8545 12.1429 29.0483 12.073C29.2421 12.0031 29.4449 11.9831 29.6448 12.0142C29.8448 12.0452 30.0379 12.1267 30.2128 12.2539C30.3892 12.3782 30.5445 12.5458 30.6697 12.747C30.795 12.9481 30.8877 13.1789 30.9425 13.4259C30.9973 13.6729 31.0131 13.9313 30.9891 14.1861C30.9651 14.4409 30.9016 14.6872 30.8024 14.9107L29.8767 17.0121C29.6762 17.4615 29.3439 17.7914 28.9525 17.9293C28.5612 18.0672 28.1429 18.0018 27.7894 17.7476Z"
          fill="#FDECAA"
          stroke="#FDECAA"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M30.0243 23.628C29.5436 23.2769 29.1988 22.7204 29.0632 22.0768C28.9276 21.4333 29.012 20.7532 29.2983 20.1812L32.0281 15.1978C32.3283 14.6356 32.8042 14.2324 33.3546 14.0739C33.9049 13.9154 34.4865 14.014 34.9757 14.3488C35.4564 14.6999 35.8012 15.2564 35.9368 15.9C36.0724 16.5435 35.988 17.2236 35.7017 17.7956L32.9283 22.796C32.7873 23.0772 32.5994 23.3225 32.3759 23.5173C32.1523 23.7121 31.8976 23.8524 31.6268 23.93C31.356 24.0076 31.0746 24.0208 30.7994 23.9689C30.5241 23.9171 30.2606 23.8011 30.0243 23.628Z"
          fill="#FDECAA"
          stroke="#FDECAA"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M24.4117 12L39.2388 28.9617C39.5005 29.2579 39.7021 29.6048 39.832 29.9819C39.9619 30.3591 40.0174 30.7592 39.9953 31.1586C39.9731 31.5581 39.8738 31.9491 39.7032 32.3087C39.5325 32.6683 39.2938 32.9893 39.0012 33.2531C37.7497 34.3524 35.833 34.2954 34.1776 32.6994L20 16.4705"
          fill="#ABABAB"
        />
        <path
          d="M30.9925 22.9973C30.97 23.2469 30.9005 23.4908 30.7874 23.7173C29.9326 25.2382 27.1889 25.4243 25.6589 24.3888C25.2742 24.1461 23.9921 23.782 23.7357 23.5798C22.5134 22.9164 21.3509 27.293 19.4619 29L14.2479 26.573L14 26.4598C19.0174 18.4912 21.197 16.7519 22.8724 17.027C24.0947 17.213 24.4109 18.3699 27.3684 19.3407C28.8471 19.8261 29.9326 19.8908 30.5224 20.9586C30.8752 21.584 31.038 22.2897 30.9925 22.9973Z"
          fill="#FDECAA"
        />
        <path
          d="M33.1524 26.631C32.611 26.2595 32.2227 25.6692 32.0706 24.9863C31.9184 24.3034 32.0144 23.5819 32.3381 22.976L34.5449 19.2671C34.8817 18.6724 35.4154 18.2459 36.0328 18.0782C36.6501 17.9105 37.3024 18.0148 37.8511 18.369C38.3903 18.7404 38.777 19.329 38.9291 20.0098C39.0812 20.6906 38.9866 21.41 38.6654 22.0151L36.4586 25.7329C36.1218 26.3276 35.5881 26.7541 34.9707 26.9218C34.3534 27.0895 33.7011 26.9852 33.1524 26.631Z"
          fill="#FDE6A0"
          stroke="#FDE6A0"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M35.829 28.5101C35.5983 28.3348 35.4072 28.1194 35.2668 27.876C35.1264 27.6326 35.0393 27.3661 35.0105 27.0916C34.9818 26.8172 35.012 26.5402 35.0993 26.2765C35.1866 26.0128 35.3294 25.7676 35.5195 25.5549L37.9701 22.7694C38.1595 22.556 38.3926 22.3791 38.6561 22.2488C38.9196 22.1185 39.2084 22.0375 39.5059 22.0103C39.8034 21.983 40.1038 22.0102 40.39 22.0901C40.6761 22.1701 40.9423 22.3012 41.1735 22.4762C41.6363 22.83 41.9293 23.3379 41.9888 23.8897C42.0483 24.4415 41.8695 24.9927 41.4913 25.4237L39.0407 28.2091C38.8546 28.4278 38.6226 28.6096 38.3586 28.7437C38.0946 28.8777 37.804 28.9613 37.5042 28.9894C37.2044 29.0175 36.9015 28.9895 36.6137 28.9072C36.3258 28.8248 36.059 28.6898 35.829 28.5101Z"
          fill="#FDE6A0"
          stroke="#FDE6A0"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M30.7724 24.3076C29.8839 25.9465 27.0319 26.1469 25.4415 25.0311C25.0417 24.7696 23.709 24.3773 23.4424 24.1594C22.1719 23.4446 20.9635 28.1606 19 30V29.4682C20.9369 27.6289 22.1452 22.9128 23.4424 23.6276C23.709 23.8281 25.0417 24.2204 25.4415 24.4994C27.0497 25.6152 29.8839 25.3711 30.7724 23.7758C30.89 23.5318 30.9622 23.269 30.9856 23C31.0338 23.4467 30.9602 23.8979 30.7724 24.3076Z"
          fill="#FFCF5F"
        />
        <path
          d="M30 23.2586C29.9771 23.4974 29.9066 23.7306 29.7917 23.9473C28.9239 25.402 26.1383 25.5799 24.585 24.5895C24.1945 24.3574 22.8928 24.0092 22.6325 23.8157C21.3915 23.1812 20.2113 27.3673 18.2935 29L13 26.6787C17.9117 19.3665 20.0812 17.7958 21.756 18.0202C22.9969 18.1981 23.318 19.3046 26.3206 20.2332C27.8218 20.6974 28.9239 20.7593 29.5227 21.7807C29.7931 22.2392 29.9556 22.7421 30 23.2586Z"
          fill="#FDECAA"
        />
        <path
          d="M12.5469 24.9627L8 28.9014L21.9429 44.9975L26.4898 41.0589L12.5469 24.9627Z"
          fill="#1E8BB4"
          stroke="#1E8BB4"
          strokeMiterlimit="10"
        />
        <path
          d="M11 28L15 32"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_485_158">
          <rect
            width="50"
            height="50"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
