/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateWoRevenueRequestDto,
  GetOneWoRevenueResponseDto,
  GetWoRevenueResponseDto,
  UpdateWoRevenueRequestDto,
} from ".././model";

export const woRevenueControllerGetOneWoRevenue = (
  woRevenueId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneWoRevenueResponseDto>> => {
  return axios.get(`/wo-revenue/${woRevenueId}`, options);
};

export const getWoRevenueControllerGetOneWoRevenueQueryKey = (
  woRevenueId: string
) => {
  return [`/wo-revenue/${woRevenueId}`] as const;
};

export const getWoRevenueControllerGetOneWoRevenueInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>
  >,
  TError = AxiosError<unknown>
>(
  woRevenueId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoRevenueControllerGetOneWoRevenueQueryKey(woRevenueId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>
  > = ({ signal }) =>
    woRevenueControllerGetOneWoRevenue(woRevenueId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!woRevenueId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WoRevenueControllerGetOneWoRevenueInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>
>;
export type WoRevenueControllerGetOneWoRevenueInfiniteQueryError =
  AxiosError<unknown>;

export const useWoRevenueControllerGetOneWoRevenueInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>
  >,
  TError = AxiosError<unknown>
>(
  woRevenueId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWoRevenueControllerGetOneWoRevenueInfiniteQueryOptions(
      woRevenueId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWoRevenueControllerGetOneWoRevenueQueryOptions = <
  TData = Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
  TError = AxiosError<unknown>
>(
  woRevenueId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoRevenueControllerGetOneWoRevenueQueryKey(woRevenueId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>
  > = ({ signal }) =>
    woRevenueControllerGetOneWoRevenue(woRevenueId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!woRevenueId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WoRevenueControllerGetOneWoRevenueQueryResult = NonNullable<
  Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>
>;
export type WoRevenueControllerGetOneWoRevenueQueryError = AxiosError<unknown>;

export const useWoRevenueControllerGetOneWoRevenue = <
  TData = Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
  TError = AxiosError<unknown>
>(
  woRevenueId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetOneWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWoRevenueControllerGetOneWoRevenueQueryOptions(
    woRevenueId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const woRevenueControllerUpdateWoRevenue = (
  woRevenueId: string,
  updateWoRevenueRequestDto: UpdateWoRevenueRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/wo-revenue/${woRevenueId}`,
    updateWoRevenueRequestDto,
    options
  );
};

export const getWoRevenueControllerUpdateWoRevenueMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woRevenueControllerUpdateWoRevenue>>,
    TError,
    { woRevenueId: string; data: UpdateWoRevenueRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof woRevenueControllerUpdateWoRevenue>>,
  TError,
  { woRevenueId: string; data: UpdateWoRevenueRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof woRevenueControllerUpdateWoRevenue>>,
    { woRevenueId: string; data: UpdateWoRevenueRequestDto }
  > = (props) => {
    const { woRevenueId, data } = props ?? {};

    return woRevenueControllerUpdateWoRevenue(woRevenueId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WoRevenueControllerUpdateWoRevenueMutationResult = NonNullable<
  Awaited<ReturnType<typeof woRevenueControllerUpdateWoRevenue>>
>;
export type WoRevenueControllerUpdateWoRevenueMutationBody =
  UpdateWoRevenueRequestDto;
export type WoRevenueControllerUpdateWoRevenueMutationError =
  AxiosError<unknown>;

export const useWoRevenueControllerUpdateWoRevenue = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woRevenueControllerUpdateWoRevenue>>,
    TError,
    { woRevenueId: string; data: UpdateWoRevenueRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof woRevenueControllerUpdateWoRevenue>>,
  TError,
  { woRevenueId: string; data: UpdateWoRevenueRequestDto },
  TContext
> => {
  const mutationOptions =
    getWoRevenueControllerUpdateWoRevenueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const woRevenueControllerDeleteWoRevenue = (
  woRevenueId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/wo-revenue/${woRevenueId}`, options);
};

export const getWoRevenueControllerDeleteWoRevenueMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woRevenueControllerDeleteWoRevenue>>,
    TError,
    { woRevenueId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof woRevenueControllerDeleteWoRevenue>>,
  TError,
  { woRevenueId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof woRevenueControllerDeleteWoRevenue>>,
    { woRevenueId: string }
  > = (props) => {
    const { woRevenueId } = props ?? {};

    return woRevenueControllerDeleteWoRevenue(woRevenueId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WoRevenueControllerDeleteWoRevenueMutationResult = NonNullable<
  Awaited<ReturnType<typeof woRevenueControllerDeleteWoRevenue>>
>;

export type WoRevenueControllerDeleteWoRevenueMutationError =
  AxiosError<unknown>;

export const useWoRevenueControllerDeleteWoRevenue = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woRevenueControllerDeleteWoRevenue>>,
    TError,
    { woRevenueId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof woRevenueControllerDeleteWoRevenue>>,
  TError,
  { woRevenueId: string },
  TContext
> => {
  const mutationOptions =
    getWoRevenueControllerDeleteWoRevenueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const woRevenueControllerCreateWoRevenue = (
  createWoRevenueRequestDto: CreateWoRevenueRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/wo-revenue`, createWoRevenueRequestDto, options);
};

export const getWoRevenueControllerCreateWoRevenueMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woRevenueControllerCreateWoRevenue>>,
    TError,
    { data: CreateWoRevenueRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof woRevenueControllerCreateWoRevenue>>,
  TError,
  { data: CreateWoRevenueRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof woRevenueControllerCreateWoRevenue>>,
    { data: CreateWoRevenueRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return woRevenueControllerCreateWoRevenue(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WoRevenueControllerCreateWoRevenueMutationResult = NonNullable<
  Awaited<ReturnType<typeof woRevenueControllerCreateWoRevenue>>
>;
export type WoRevenueControllerCreateWoRevenueMutationBody =
  CreateWoRevenueRequestDto;
export type WoRevenueControllerCreateWoRevenueMutationError =
  AxiosError<unknown>;

export const useWoRevenueControllerCreateWoRevenue = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woRevenueControllerCreateWoRevenue>>,
    TError,
    { data: CreateWoRevenueRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof woRevenueControllerCreateWoRevenue>>,
  TError,
  { data: CreateWoRevenueRequestDto },
  TContext
> => {
  const mutationOptions =
    getWoRevenueControllerCreateWoRevenueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const woRevenueControllerGetWoRevenue = (
  workOrderId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWoRevenueResponseDto>> => {
  return axios.get(`/wo-revenue/workOrder/${workOrderId}`, options);
};

export const getWoRevenueControllerGetWoRevenueQueryKey = (
  workOrderId: string
) => {
  return [`/wo-revenue/workOrder/${workOrderId}`] as const;
};

export const getWoRevenueControllerGetWoRevenueInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>
  >,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoRevenueControllerGetWoRevenueQueryKey(workOrderId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>
  > = ({ signal }) =>
    woRevenueControllerGetWoRevenue(workOrderId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!workOrderId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WoRevenueControllerGetWoRevenueInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>
>;
export type WoRevenueControllerGetWoRevenueInfiniteQueryError =
  AxiosError<unknown>;

export const useWoRevenueControllerGetWoRevenueInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>
  >,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWoRevenueControllerGetWoRevenueInfiniteQueryOptions(
    workOrderId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWoRevenueControllerGetWoRevenueQueryOptions = <
  TData = Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoRevenueControllerGetWoRevenueQueryKey(workOrderId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>
  > = ({ signal }) =>
    woRevenueControllerGetWoRevenue(workOrderId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!workOrderId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WoRevenueControllerGetWoRevenueQueryResult = NonNullable<
  Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>
>;
export type WoRevenueControllerGetWoRevenueQueryError = AxiosError<unknown>;

export const useWoRevenueControllerGetWoRevenue = <
  TData = Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
  TError = AxiosError<unknown>
>(
  workOrderId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woRevenueControllerGetWoRevenue>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWoRevenueControllerGetWoRevenueQueryOptions(
    workOrderId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
