import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useStockTypesControllerGetStockTypes } from "../../../api/stock-inventory-types/stock-inventory-types";
import { useAppSelector } from "../../../redux/store";
import { inventoryTypeColumn } from "./columns/inventoryTypeColumn";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../../../components/ui/input";
import AppButton from "../../../components/AppButton";
import {
  DRAWER_CREATE_STATE,
  INVENTORY_TYPE_FORM_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { useSearchParams } from "react-router-dom";
import { DataTable } from "../../../components/react-table/DataTable";

export default function InventoryTypeTab() {
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const {
    data: inventoryTypes,
    isLoading,
    isFetching,
  } = useStockTypesControllerGetStockTypes(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const table = useReactTable({
    data: inventoryTypes?.data ?? [],
    columns: inventoryTypeColumn,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnFilters,
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <div className="flex gap-2">
          <Input
            placeholder="Search name"
            value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
            onChange={(event) => {
              return table
                .getColumn("name")
                ?.setFilterValue(event.target.value);
            }}
            className="max-w-sm focus-visible:ring-primary-900"
          />
          <AppButton
            data-testid="add-inventory-type-btn"
            label="+ Add Inventory Type"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", INVENTORY_TYPE_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
        <DataTable
          isLoading={isLoading}
          isFetching={isFetching}
          table={table}
          totalCount={inventoryTypes?.data ? inventoryTypes.data.length : 0}
        />
      </div>
    </FormProvider>
  );
}
