import React from "react";
import { Card, CardContent } from "../../../../../../components/ui/card";

interface CardSummaryProps {
  title: string;
  content: string;
  action?: React.ReactNode;
}

export default function CardSummary({
  title,
  content,
  action,
}: CardSummaryProps) {
  return (
    <Card>
      <CardContent className="py-6 flex space-between items-center">
        <div className="space-y-2 flex-grow">
          <p>{title}</p>
          <p className="text-2xl font-bold">{content}</p>
        </div>
        {action}
      </CardContent>
    </Card>
  );
}
