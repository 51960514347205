import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {auth} from "../../firebase/firebaseConfig";
import store, {RootState} from "../store";

export const RootApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_SERVER_API_URL,
    prepareHeaders: async (headers, {getState}) => {
      const authUser = auth.currentUser;
      // if (authUser) {
      //     if (authUser.uid) {

      //         headers.set('firetoken', authUser.uid);
      //     } else {
      const firetoken = await authUser?.getIdToken();
      // store.dispatch(AuthActions.setFiretoken({ firetoken }));
      const activeProj = (getState() as RootState).root.activeProject;
      if (activeProj) headers.set("projectid", activeProj.id.toString());
      if (firetoken) headers.set("authorization", `Bearer ${firetoken}`);
      // }
      // }
      return headers;
    },
  }),
  endpoints: (build) => ({
    testing: build.query<void, void>({
      query: () => ({
        url: "/api/test",
        method: "GET",
      }),
    }),
  }),
  tagTypes: ["AuthUser"],
});

export const {useTestingQuery} = RootApi;
