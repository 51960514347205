import { useLocation } from "react-router-dom";
import { useAuthControllerGetCurrentUserQuery } from "../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../redux/store";
import {
  LayoutDashboard,
  KanbanSquare,
  ClipboardCheck,
  Wrench,
  Users2,
  Boxes,
  Computer,
  FileCheck2,
  ShieldCheck,
  UtilityPole,
  GaugeCircle,
  ListStart,
  Settings,
  UserCog,
  Calendar,
  Building,
  FileStack,
  ArrowUpToLine,
} from "lucide-react";
import { cn } from "../utils/util";
import {
  useBimEnabled,
  useCustomWorkPermitEnabled,
  useEscalationEnabled,
  usePdfReportEnabled,
  useSchedulerEnabled,
} from "./useFeatures";
import useModuleAccess from "./useModuleAccess";

export default function useGetDrawerNavigation() {
  const { pathname } = useLocation();
  const schedulerEnabled = useSchedulerEnabled();
  const bimEnabled = useBimEnabled();
  const isCustomPtwEnabled = useCustomWorkPermitEnabled();
  const isPdfReportEnabled = usePdfReportEnabled();
  const isEscalationEnabled = useEscalationEnabled();
  const {
    workOrderHasAccess,
    workRequestHasAccess,
    pmHasAccess,
    vendorHasAccess,
    assetHasAccess,
    workPermitHasAccess,
    legalComplianceHasAccess,
    inventoryHasAccess,
    dashboardHasAccess,
    tnbBillHasAccess,
    metersHasAccess,
    escalationHasAccess,
  } = useModuleAccess();

  // TODO: Change nav links with search query so user can navigate correctly
  const navigationLinks = [
    {
      name: "Dashboard",
      link: "/home/dashboard",
      icon: (
        <LayoutDashboard
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/dashboard".split("?")[0]),
          })}
        />
      ),
    },
    ...(isPdfReportEnabled
      ? [
          {
            name: "PDF Reports",
            link: "/home/pdf-report",
            icon: (
              <FileStack
                className={cn("text-slate-500 group-hover:text-slate-200", {
                  "text-white": pathname.includes(
                    "/home/pdf-report".split("?")[0]
                  ),
                })}
              />
            ),
          },
        ]
      : []),
    ...(bimEnabled
      ? [
          {
            name: "3D Building",
            link: "/home/bim",
            icon: (
              <Building
                className={cn("text-slate-500 group-hover:text-slate-200", {
                  "text-white": pathname.includes("/home/bim".split("?")[0]),
                })}
              />
            ),
          },
        ]
      : []),
    {
      name: "Work Request",
      link: "/home/work-requests",
      icon: (
        <KanbanSquare
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes(
              "/home/work-requests".split("?")[0]
            ),
          })}
        />
      ),
    },
    {
      name: "Work Order",
      link: "/home/work-orders",
      icon: (
        <ClipboardCheck
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/work-orders".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "WO Scheduler",
      link: "/home/wo-scheduler",
      icon: (
        <Calendar
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/wo-scheduler".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "PM",
      link: "/home/pm",
      icon: (
        <Wrench
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.startsWith("/home/pm".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "PM Template",
      link: "/home/template",
      icon: (
        <Wrench
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/template".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "PM Scheduler",
      link: "/home/scheduler-pm",
      icon: (
        <Calendar
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/scheduler-pm".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "Vendors",
      link: "/home/vendors",
      icon: (
        <Users2
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/vendors".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "Equipments",
      link: "/home/assets",
      icon: (
        <Computer
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/assets".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "Inventory",
      link: "/home/inventory",
      icon: (
        <Boxes
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/inventory".split("?")[0]),
          })}
        />
      ),
    },

    ...(isCustomPtwEnabled
      ? [
          {
            name: "Work Permits",
            link: "/home/ptw",
            icon: (
              <FileCheck2
                className={cn("text-slate-500 group-hover:text-slate-200", {
                  "text-white": pathname.includes("/home/ptw".split("?")[0]),
                })}
              />
            ),
          },
          {
            name: "WP Templates",
            link: "/home/wp-template",
            icon: (
              <FileCheck2
                className={cn("text-slate-500 group-hover:text-slate-200", {
                  "text-white": pathname.includes(
                    "/home/wp-template".split("?")[0]
                  ),
                })}
              />
            ),
          },
        ]
      : [
          {
            name: "Work Permit",
            link: "/home/work-permit",
            icon: (
              <FileCheck2
                className={cn("text-slate-500 group-hover:text-slate-200", {
                  "text-white": pathname.includes(
                    "/home/work-permit".split("?")[0]
                  ),
                })}
              />
            ),
          },
        ]),

    {
      name: "Legal Compliance",
      link: "/home/legal-compliance",
      icon: (
        <ShieldCheck
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes(
              "/home/legal-compliance".split("?")[0]
            ),
          })}
        />
      ),
    },
    {
      name: "Escalations",
      link: "/home/escalations",
      icon: (
        <ArrowUpToLine
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/escalations".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "TNB Bills",
      link: "/home/tnb-bills",
      icon: (
        <UtilityPole
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/tnb-bills".split("?")[0]),
          })}
        />
      ),
    },
    {
      name: "Meters",
      link: "/home/meters",
      icon: (
        <GaugeCircle
          className={cn("text-slate-500 group-hover:text-slate-200", {
            "text-white": pathname.includes("/home/meters".split("?")[0]),
          })}
        />
      ),
    },
  ].filter((lk) => {
    const conditions = [];

    // feature toggling
    conditions.push(
      schedulerEnabled ||
        (lk.name !== "WO Scheduler" && lk.name !== "PM Scheduler")
    );

    // Module access
    conditions.push(
      workOrderHasAccess ||
        (lk.link !== "/home/work-orders" && lk.link !== "/home/wo-scheduler")
    );
    conditions.push(workRequestHasAccess || lk.link !== "/home/work-requests");
    conditions.push(
      pmHasAccess ||
        (lk.link !== "/home/pm" &&
          lk.link !== "/home/template" &&
          lk.link !== "/home/scheduler-pm")
    );
    conditions.push(vendorHasAccess || lk.link !== "/home/vendors");
    conditions.push(assetHasAccess || lk.link !== "/home/assets");
    conditions.push(
      workPermitHasAccess ||
        (lk.link !== "/home/ptw" &&
          lk.link !== "/home/wp-template" &&
          lk.link !== "/home/work-permit")
    );
    conditions.push(
      legalComplianceHasAccess || lk.link !== "/home/legal-compliance"
    );
    conditions.push(inventoryHasAccess || lk.link !== "/home/inventory");
    conditions.push(dashboardHasAccess || lk.link !== "/home/dashboard");
    conditions.push(tnbBillHasAccess || lk.link !== "/home/tnb-bills");
    conditions.push(metersHasAccess || lk.link !== "/home/meters");
    conditions.push(
      (escalationHasAccess && isEscalationEnabled) ||
        lk.link !== "/home/escalations"
    );

    // Return true if all conditions are true, meaning the link should be included
    return conditions.every((condition) => condition);
  });

  const { data: user, isLoading } = useAuthControllerGetCurrentUserQuery();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  if (!user || !activeProj)
    return { isLoading: isLoading, navigationLinks: [] };

  const isAccountType = (
    accType: "SUPERADMIN" | "COMPANY_ADMIN" | "NORMAL_USER" | "VENDOR"
  ) => {
    if (
      user.accountTypes?.filter((acc) => acc.type === "SUPERADMIN")?.length > 0
    ) {
      return true;
    }
    return (
      user.accountTypes?.filter(
        (acc) => acc.type === accType && acc.projectId === activeProj.id
      )?.length > 0
    );
  };

  if (isAccountType("SUPERADMIN")) {
    return { isLoading: isLoading, navigationLinks: navigationLinks };
  }

  if (isAccountType("COMPANY_ADMIN")) {
    return {
      isLoading: isLoading,
      navigationLinks: navigationLinks.filter(
        (link) => link.name !== "Super Admin"
      ),
    };
  }
  return {
    isLoading: isLoading,
    navigationLinks: navigationLinks.filter(
      (link) => link.name !== "Super Admin" && link.name !== "Settings"
    ),
  };
}
