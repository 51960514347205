import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import AppTextField from "../AppTextField";
import AppButton from "../AppButton";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import useError from "../../hooks/useError";
import { useEffect } from "react";
import useSuccess from "../../hooks/useSuccess";
import useIsUpdateDrawerState from "../../hooks/useDrawerState";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import {
  useStockTypesControllerCreateStockType,
  useStockTypesControllerGetOneStockType,
  useStockTypesControllerUpdateStockType,
} from "../../api/stock-inventory-types/stock-inventory-types";
import { useQueryClient } from "@tanstack/react-query";

const InventoryTypeFormSchema = z.object({
  name: z.string().min(1),
});

type InventoryTypeForm = z.infer<typeof InventoryTypeFormSchema>;

export default function InventoryTypeFormDrawer() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const methods = useForm<InventoryTypeForm>({
    resolver: zodResolver(InventoryTypeFormSchema),
    defaultValues: {
      name: "",
    },
  });

  const {
    mutate: createInventoryType,
    isPending: createInventoryTypeIsLoading,
  } = useStockTypesControllerCreateStockType({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as any).includes("stock-types");
          },
        });
        setSearchParams(new URLSearchParams());
      },
    },
  });

  const {
    mutate: updateInventoryType,
    isPending: updateInventoryTypeIsLoading,
  } = useStockTypesControllerUpdateStockType({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as any).includes("stock-types");
          },
        });
        setSearchParams(new URLSearchParams());
      },
    },
  });

  const { data: editInventoryType, isLoading: editInventoryTypeIsLoading } =
    useStockTypesControllerGetOneStockType(
      searchParams.get("inventoryTypeId") ?? "",

      {
        query: {
          enabled: !!isUpdateDrawer && !!searchParams.get("inventoryTypeId"),
        },
      }
    );

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const onSubmit: SubmitHandler<InventoryTypeForm> = (data) => {
    if (!activeProj) return;
    if (isUpdateDrawer && searchParams.get("inventoryTypeId")) {
      return updateInventoryType({
        stockTypeId: searchParams.get("inventoryTypeId") ?? "",
        data: {
          name: data.name,
          projectId: activeProj.id?.toString() ?? "",
        },
      });
    }
    createInventoryType({
      data: {
        name: data.name,
        projectId: activeProj.id?.toString() ?? "",
      },
    });
  };

  //isEdit Mode
  useEffect(() => {
    if (isUpdateDrawer && editInventoryType) {
      methods.setValue("name", editInventoryType?.data?.name ?? "");
    }
  }, [searchParams, editInventoryType]);

  // useError({
  //   dependencies: [
  //     {
  //       isError: createInventoryTypeIsError,
  //       error: createInventoryError,
  //     },
  //     {
  //       isError: updateInventoryTypeIsError,
  //       error: updateInventoryError,
  //     },
  //   ],
  // });
  // useSuccess({
  //   dependencies: [createInventoryTypeIsSuccess, updateInventoryTypeIsSuccess],
  // });

  if (editInventoryTypeIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Inventory Type
        </p>
        <AppTextField label="Name" name="name" />
        <div className="flex">
          <AppButton
            isLoading={
              createInventoryTypeIsLoading || updateInventoryTypeIsLoading
            }
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </FormProvider>
  );
}
