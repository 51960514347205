import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { addMonths, format, parse, parseISO } from "date-fns";
import {
  ArrowDown,
  ArrowUp,
  Hash,
  Image,
  Text,
  ToggleLeft,
  Trash2,
} from "lucide-react";
import { useEffect } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useAssetControllerGetFullAssets } from "../../../api/assets/assets";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import {
  FrequencySettingFrequencySettingType,
  PmFormatTypeEnum,
} from "../../../api/model";
import { usePmFormatTypeControllerGetPmFormatTypes } from "../../../api/preventive-maintenance-format-type/preventive-maintenance-format-type";
import {
  usePmTemplateControllerAddPmTemplate,
  usePmTemplateControllerGetOnePmTemplate,
  usePmTemplateControllerUpdatePmTemplate,
} from "../../../api/preventive-maintenance-template/preventive-maintenance-template";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { Button } from "../../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { useToast } from "../../ui/use-toast";
import { assetColumns } from "../workOrders/columns/assetColumns";
import AppCronPicker from "./components/AppCronPicker";
import FormatType from "./components/FormatType";
import {
  PmTemplateFormSchema,
  PmTemplateFormType,
} from "./PmTemplateFormSchema";

export default function PmTemplateFormDrawer() {
  const qc = useQueryClient();
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const pmTemplateId = searchParams.get("pmTemplateId");
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: user } = useAuthControllerGetCurrentUser();
  const methods = useForm<PmTemplateFormType>({
    resolver: zodResolver(PmTemplateFormSchema),
    defaultValues: {
      name: "",
      pmFormat: [],
      assets: [],
      startDate: format(new Date(), "yyyy-MM-dd"),
      frequencySetting: {
        frequencySettingType: FrequencySettingFrequencySettingType.DAILY,
        weeklyRepeatDay: [],
        monthlyRepeatDate: [],
        monthlyRepeatDay: [],
        monthlyEveryStepCount: 1,
        yearlyRepeatDate: [],
        yearlyRepeatMonth: [],
        yearlyEveryStepCount: 1,
      },
    },
  });

  const { fields, append, remove, swap, update } = useFieldArray({
    control: methods.control,
    name: "pmFormat",
  });

  const { data: editTemplate } = usePmTemplateControllerGetOnePmTemplate(
    pmTemplateId ?? "",
    {
      query: {
        enabled: !!pmTemplateId,
        select: (res) => {
          const sortedTemplates = [...(res.data?.pmFormat ?? [])].sort(
            (a, b) => a.order - b.order
          );
          return { ...res, data: { ...res.data, pmFormat: sortedTemplates } };
        },
      },
    }
  );

   const { data: pmFormatTypesData } =
    usePmFormatTypeControllerGetPmFormatTypes();

  const {
    mutate: createPmTemplate,
    isPending: createPmTemplateIsPending,
    isError: createPmTemplateIsError,
    isSuccess: createPmTemplateIsSuccess,
  } = usePmTemplateControllerAddPmTemplate({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as string).includes("pm-template");
          },
        });

        // Navigate back to the list page
        const newParam = new URLSearchParams();
        setSearchParams(newParam);
      },
    },
  });

  const { data: fullAssetList } = useAssetControllerGetFullAssets({
    projectId: activeProj?.id?.toString() ?? "0",
  });

  const {
    mutate: updatePmTemplate,
    isPending: updatePmTemplateIsPending,
    isError: updatePmTemplateIsError,
    isSuccess: updatePmTemplateIsSuccess,
  } = usePmTemplateControllerUpdatePmTemplate({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as string).includes("pm-template");
          },
        });

        // Navigate back to the list page
        const newParam = new URLSearchParams();
        setSearchParams(newParam);
      },
    },
  });

  const onSubmit: SubmitHandler<PmTemplateFormType> = async (data) => {
    if (pmTemplateId) {
      if (!editTemplate)
        return toast({
          title: "Error",
          description: "No template is selected",
          variant: "destructive",
        });
      return updatePmTemplate({
        pmTemplateId,
        data: {
          updatedById: user?.data?.id ?? 0,
          projectId: activeProj?.id ?? 0,
          companyId: activeComp?.id ?? 0,
          assetIds: data.assets.map((ast) => ast.id),
          startDate: parse(
            data.startDate,
            "yyyy-MM-dd",
            new Date()
          ).toISOString(),
          name: data.name,
          pmFormats: data.pmFormat.map((fmt, idx) => ({
            id: +(fmt.id ?? 0),
            name: fmt.name,
            order: idx,
            pmFormatId: +(fmt.id ?? 0),
            pmFormatType: {
              name: fmt.pmFormatType.name as PmFormatTypeEnum,
            } as any,
          })),
          frequencySettingType: data.frequencySetting.frequencySettingType,
          weeklyRepeatDay: data.frequencySetting.weeklyRepeatDay,
          monthlyRepeatDate: data.frequencySetting.monthlyRepeatDate,
          monthlyRepeatDay: data.frequencySetting.monthlyRepeatDay,
          monthlyEveryStepCount: data.frequencySetting.monthlyEveryStepCount,
          yearlyRepeatDate: data.frequencySetting.yearlyRepeatDate,
          yearlyRepeatMonth: data.frequencySetting.yearlyRepeatMonth,
          yearlyEveryStepCount: data.frequencySetting.yearlyEveryStepCount,
          dateStartISO: parse(
            data.startDate,
            "yyyy-MM-dd",
            new Date()
          ).toISOString(),
        },
      });
    }
    createPmTemplate({
      data: {
        createdById: user?.data?.id ?? 0,
        projectId: activeProj?.id ?? 0,
        companyId: activeComp?.id ?? 0,
        assetIds: data.assets.map((ast) => ast.id),
        name: data.name,
        pmFormats: data.pmFormat.map((fmt, idx) => ({
          name: fmt.name,
          order: idx,
          pmFormatType: {
            name: fmt.pmFormatType.name as PmFormatTypeEnum,
          } as any,
        })),
        frequencySettingType: data.frequencySetting.frequencySettingType,
        weeklyRepeatDay: data.frequencySetting.weeklyRepeatDay,
        monthlyRepeatDate: data.frequencySetting.monthlyRepeatDate,
        monthlyRepeatDay: data.frequencySetting.monthlyRepeatDay,
        monthlyEveryStepCount: data.frequencySetting.monthlyEveryStepCount,
        yearlyRepeatDate: data.frequencySetting.yearlyRepeatDate,
        yearlyRepeatMonth: data.frequencySetting.yearlyRepeatMonth,
        yearlyEveryStepCount: data.frequencySetting.yearlyEveryStepCount,
        dateStartISO: parse(
          data.startDate,
          "yyyy-MM-dd",
          new Date()
        ).toISOString(),
      },
    });
  };

  useEffect(() => {
    if (editTemplate) {
      methods.reset({
        name: editTemplate?.data?.name ?? "",
        startDate: editTemplate?.data?.dateStart
          ? format(parseISO(editTemplate?.data?.dateStart), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        assets: editTemplate?.data?.asset,
        pmFormat: editTemplate?.data?.pmFormat.map((fmt, idx) => ({
          id: fmt.id.toString(),
          name: fmt.name,
          pmFormatType: {
            name: fmt.pmFormatType.name,
          } as any,
        })),
        frequencySetting: {
          frequencySettingType:
            editTemplate?.data?.frequencySetting?.frequencySetting,
          weeklyRepeatDay:
            editTemplate?.data?.frequencySetting?.weeklyRepeatDay?.split(","),
          monthlyRepeatDay:
            editTemplate?.data?.frequencySetting?.monthlyRepeatDay?.split(","),
          monthlyRepeatDate:
            editTemplate?.data?.frequencySetting?.monthlyRepeatDate?.split(","),
          monthlyEveryStepCount:
            editTemplate?.data?.frequencySetting?.monthlyEveryStepCount,
          yearlyRepeatMonth:
            editTemplate?.data?.frequencySetting?.yearlyRepeatMonth?.split(","),
          yearlyRepeatDate:
            editTemplate?.data?.frequencySetting?.yearlyRepeatDate?.split(","),
          yearlyEveryStepCount:
            editTemplate?.data?.frequencySetting?.yearlyEveryStepCount,
        },
      });
    }
  }, [editTemplate]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update PM Template" : "Create PM Template"}
        </p>
        <div className="flex flex-col gap-6">
          <AppTextField
            name="name"
            label="Name of the checklist"
            description="Please input the name of your checklist"
          />
          <AppTextField label="Start Date" name="startDate" type="date" />
          {/* <AppTextField label="End Date" name="endDate" type="date" /> */}
          <AppSelectWithDialog
            multiple
            label="Equipment"
            placeholder="Select an Equipment"
            columns={assetColumns}
            defaultValue={undefined}
            control={methods.control}
            name="assets"
            items={fullAssetList?.data ?? []}
            onResultRender={(item, idx) => (
              <div key={idx} className="flex flex-col">
                <div className="font-medium">{item?.name}</div>
                <div className="font-thin mt-1">
                  {item?.assetType?.name ?? "-"}
                </div>
              </div>
            )}
            onOptionsRender={(item, idx) => (
              <div key={idx} className="flex flex-col">
                <div className="font-medium">{item?.name}</div>
                <div className="font-thin mt-1">
                  {item?.assetType?.name ?? "-"}
                </div>
              </div>
            )}
            dialogTitle="Select an Equipment"
            error={!!methods.formState.errors.assets?.message}
            helperText={methods.formState.errors.assets?.message}
          />
          <AppCronPicker label="Frequency" />
          <p className="font-medium">Fields</p>
          {fields.map((field, index) => {
            return (
              <div
                className="flex flex-col gap-2 p-5 rounded-xl bg-gray-50"
                key={field.id}
              >
                <div className="flex justify-between items-center">
                  <FormatType formatType={field.pmFormatType?.name} />
                  <div className="flex items-center gap-2">
                    <Button
                      className="p-2"
                      variant="ghost"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <Trash2 className="w-5 h-5 text-red-500" />
                    </Button>
                    {index - 1 >= 0 && (
                      <Button
                        className="p-2"
                        variant="ghost"
                        onClick={() => {
                          swap(index, index - 1);
                        }}
                      >
                        <ArrowUp className="w-5 h-5" />
                      </Button>
                    )}
                    {index + 1 < fields.length && (
                      <Button
                        className="p-2"
                        variant="ghost"
                        onClick={() => {
                          swap(index, index + 1);
                        }}
                      >
                        <ArrowDown className="w-5 h-5" />
                      </Button>
                    )}
                  </div>
                </div>
                <AppTextField
                  name={`pmFormat.${index}.name`}
                  label="Question"
                  key={field.id}
                />
              </div>
            );
          })}
          {methods.formState.errors.pmFormat?.message && (
            <p className="text-red-500">
              {methods.formState.errors.pmFormat?.message}
            </p>
          )}
          <div className="flex">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  className="text-primary-900 hover:text-primary-900 border-primary-900"
                  variant="outline"
                >
                  + Insert New
                </Button>
              </PopoverTrigger>
              <PopoverContent className="flex gap-4 w-full" side="right">
                <Button
                  className="flex gap-2 items-center"
                  variant="outline"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.data?.find(
                      (fmt) => fmt.name === "BOOL"
                    );
                    if (!idenFormatType) return;
                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <ToggleLeft className="h-4 w-4 text-primary-900" />
                  Yes / No
                </Button>
                <Button
                  className="flex gap-2 items-center"
                  variant="outline"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.data?.find(
                      (fmt) => fmt.name === "VARCHAR"
                    );
                    if (!idenFormatType) return;

                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <Text className="h-4 w-4 text-primary-900" />
                  Text
                </Button>
                <Button
                  data-testid="add-image-btn"
                  className="flex gap-2 items-center"
                  variant="outline"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.data?.find(
                      (fmt) => fmt.name === "ATTACHMENT"
                    );
                    if (!idenFormatType) return;
                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <Image className="h-4 w-4 text-primary-900" />
                  Image
                </Button>
                <Button
                  variant="outline"
                  className="flex gap-2 items-center"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.data?.find(
                      (fmt) => fmt.name === "NUMBER"
                    );
                    if (!idenFormatType) return;
                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <Hash className="h-4 w-4 text-primary-900" />
                  Number
                </Button>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <AppButton
          label={isUpdateDrawer ? "Update" : "Submit"}
          isLoading={createPmTemplateIsPending || updatePmTemplateIsPending}
          onClick={methods.handleSubmit(onSubmit)}
        />
      </div>
    </FormProvider>
  );
}
