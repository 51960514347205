/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AnalyticMeterControllerGetMeterReadingParams,
  GetAnalyticMeterReadingResponseDto,
  TestControllerTesting200,
} from ".././model";

export const analyticMeterControllerGetMeterReading = (
  params: AnalyticMeterControllerGetMeterReadingParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAnalyticMeterReadingResponseDto[]>> => {
  return axios.get(`/analytics/meter/meterTrend`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAnalyticMeterControllerGetMeterReadingQueryKey = (
  params: AnalyticMeterControllerGetMeterReadingParams
) => {
  return [`/analytics/meter/meterTrend`, ...(params ? [params] : [])] as const;
};

export const getAnalyticMeterControllerGetMeterReadingInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
    AnalyticMeterControllerGetMeterReadingParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AnalyticMeterControllerGetMeterReadingParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
        TError,
        TData,
        Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
        QueryKey,
        AnalyticMeterControllerGetMeterReadingParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAnalyticMeterControllerGetMeterReadingQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
    QueryKey,
    AnalyticMeterControllerGetMeterReadingParams["cursorId"]
  > = ({ signal, pageParam }) =>
    analyticMeterControllerGetMeterReading(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
    TError,
    TData,
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
    QueryKey,
    AnalyticMeterControllerGetMeterReadingParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AnalyticMeterControllerGetMeterReadingInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>
  >;
export type AnalyticMeterControllerGetMeterReadingInfiniteQueryError =
  AxiosError<unknown>;

export const useAnalyticMeterControllerGetMeterReadingInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
    AnalyticMeterControllerGetMeterReadingParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AnalyticMeterControllerGetMeterReadingParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
        TError,
        TData,
        Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
        QueryKey,
        AnalyticMeterControllerGetMeterReadingParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAnalyticMeterControllerGetMeterReadingInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAnalyticMeterControllerGetMeterReadingQueryOptions = <
  TData = Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
  TError = AxiosError<unknown>
>(
  params: AnalyticMeterControllerGetMeterReadingParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAnalyticMeterControllerGetMeterReadingQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>
  > = ({ signal }) =>
    analyticMeterControllerGetMeterReading(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AnalyticMeterControllerGetMeterReadingQueryResult = NonNullable<
  Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>
>;
export type AnalyticMeterControllerGetMeterReadingQueryError =
  AxiosError<unknown>;

export const useAnalyticMeterControllerGetMeterReading = <
  TData = Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
  TError = AxiosError<unknown>
>(
  params: AnalyticMeterControllerGetMeterReadingParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof analyticMeterControllerGetMeterReading>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAnalyticMeterControllerGetMeterReadingQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const testControllerTesting = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TestControllerTesting200>> => {
  return axios.get(`/test`, options);
};

export const getTestControllerTestingQueryKey = () => {
  return [`/test`] as const;
};

export const getTestControllerTestingInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof testControllerTesting>>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof testControllerTesting>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTestControllerTestingQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof testControllerTesting>>
  > = ({ signal }) => testControllerTesting({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof testControllerTesting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TestControllerTestingInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof testControllerTesting>>
>;
export type TestControllerTestingInfiniteQueryError = AxiosError<unknown>;

export const useTestControllerTestingInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof testControllerTesting>>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof testControllerTesting>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTestControllerTestingInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTestControllerTestingQueryOptions = <
  TData = Awaited<ReturnType<typeof testControllerTesting>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof testControllerTesting>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTestControllerTestingQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof testControllerTesting>>
  > = ({ signal }) => testControllerTesting({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof testControllerTesting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TestControllerTestingQueryResult = NonNullable<
  Awaited<ReturnType<typeof testControllerTesting>>
>;
export type TestControllerTestingQueryError = AxiosError<unknown>;

export const useTestControllerTesting = <
  TData = Awaited<ReturnType<typeof testControllerTesting>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof testControllerTesting>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTestControllerTestingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
