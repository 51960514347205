import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export enum AssetLocationSorting {
  NONE = "NONE",
  ALPHABETICAL = "ALPHABETICAL",
}

interface AssetLocationSortingState {
  assetLocationSorting: AssetLocationSorting;
  actions: {
    setAssetLocationSorting: (
      assetLocationSorting: AssetLocationSorting
    ) => void;
  };
}

export const useAssetLocationSortingStore = create<AssetLocationSortingState>()(
  persist(
    (set) => ({
      assetLocationSorting: AssetLocationSorting.NONE,
      actions: {
        setAssetLocationSorting: (assetLocationSorting) =>
          set(() => ({ assetLocationSorting })),
      },
    }),
    {
      name: "asset-location-sorting", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        assetLocationSorting: state.assetLocationSorting,
      }),
    }
  )
);

export const useAssetLocationSorting = () =>
  useAssetLocationSortingStore((state) => state.assetLocationSorting);

export const useAssetLocationSortingActions = () =>
  useAssetLocationSortingStore((state) => state.actions);
