// Please cross check with test code as per the following link
//LINK - src/__test__/ImportAsset.test.tsx
import { useEffect, useState } from "react";
import {
  AssetType,
  ImportAssetCsvObj,
  useAssetTypeControllerGetAssetTypeQuery,
} from "../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../redux/store";
import {
  findDuplicateAssetCodes,
  checkAssetRequiredColumns,
  checkAssetDataValidity,
  checkAssetDataPresence,
  checkAssetTypes,
} from "./helpers/AssetImportHelpers";
import { useAssetTypeControllerGetAssetType } from "../api/asset-type/asset-type";

export default function useAssetImportCheck({
  assetData,
}: {
  assetData: ImportAssetCsvObj[];
}) {
  const [errors, setErrors] = useState<{ code: string; message: string }[]>([]);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: assetTypes } = useAssetTypeControllerGetAssetType(
    {
      companyId: activeComp?.id.toString() ?? "",
      projectId: activeProj?.id?.toString() ?? "",
      rowsPerPage: 1000, // Get all equipment type
    },
    {
      query: {
        enabled: !!activeComp && !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  useEffect(() => {
    const newErrors: { code: string; message: string }[] = [];

    // const duplicateAssetCodeErrors = findDuplicateAssetCodes(assetData);
    // duplicateAssetCodeErrors.forEach((error) => {
    //   newErrors.push(error);
    // });

    const requiredAssetColumnErrors = checkAssetRequiredColumns(assetData);
    requiredAssetColumnErrors.forEach((error) => {
      newErrors.push(error);
    });

    const assetDataValidityErrors = checkAssetDataValidity(assetData);
    assetDataValidityErrors.forEach((error) => {
      newErrors.push(error);
    });

    const assetDataPresenceErrors = checkAssetDataPresence(assetData);
    assetDataPresenceErrors.forEach((error) => {
      newErrors.push(error);
    });

    // const assetDataAssetTypeErrors = checkAssetTypes(
    //   assetData,
    //   assetTypes?.map((ast) => ({ id: ast.id ?? 0, name: ast.name ?? "" })) ??
    //     []
    // );
    // assetDataAssetTypeErrors.forEach((error) => {
    //   newErrors.push(error);
    // });

    setErrors(newErrors);
  }, [assetData]);

  return errors;
}
