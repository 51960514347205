import { Chip, TableCell, TableHead, TableRow } from "@mui/material";
import {
  PaginationState,
  getCoreRowModel,
  getGroupedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import AppButton from "../../../../components/AppButton";
import {
  DRAWER_UPDATE_STATE,
  ROLE_FORM_DRAWER
} from "../../../../components/drawers/AppDrawer";
import AppTable from "../../../../components/table/AppTable";
import {
  DefectStatus,
  PmChecklistStatus,
  RoleWithStatuses,
  useRolesControllerGetAllRolesQuery,
  useRolesControllerGetRolesQuery,
  useRolesControllerUpdateRoleStatusMutation
} from "../../../../redux/slices/OpenApi/cerevApi";
import {
  SnackBarType,
  setOpenSnackBar,
} from "../../../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { accessControlColumns } from "./columns/accessControlColumns";
import AddPmStatusBtn from "./components/AddPmStatusBtn";
import AddWorkOrderStatusBtn from "./components/AddWorkOrderStatusBtn";

export default function AccessControlTab() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const search = useWatch({ control: methods.control, name: "search" });

  const { data: allProjWithRoles } = useRolesControllerGetAllRolesQuery(
    {
      companyId: activeComp?.id ?? 0,
    },
    {
      skip: !activeComp,
    }
  );
  const {
    data: roles,
    isLoading,
    isError: getRolesIsError,
  } = useRolesControllerGetRolesQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    { skip: !activeProj }
  );

  const [updateRole, { isError: updateRoleIsError }] =
    useRolesControllerUpdateRoleStatusMutation();

  const onDeleteWorkOrderStatus = (
    workOrderStatus: DefectStatus,
    role: RoleWithStatuses
  ) => {
    updateRole({
      roleId: role.id,
      updateRoleDto: {
        name: role.name,
        defectStatuses: role.defectStatuses.filter(
          (d) => d.id !== workOrderStatus.id
        ),
        pmStatuses: role.pmStatuses,
      },
    });
  };

  const onDeletePmStatus = (
    pmStatus: PmChecklistStatus,
    role: RoleWithStatuses
  ) => {
    updateRole({
      roleId: role.id,
      updateRoleDto: {
        name: role.name,
        defectStatuses: role.defectStatuses,
        pmStatuses: role.pmStatuses.filter((pm) => pm.id !== pmStatus.id),
      },
    });
  };

  useEffect(() => {
    if (getRolesIsError || updateRoleIsError)
      dispatch(
        setOpenSnackBar({
          type: SnackBarType.Error,
          msg: "Something went wrong",
        })
      );
  }, [updateRoleIsError, getRolesIsError]);

  const table = useReactTable({
    data: allProjWithRoles ?? [],
    columns: accessControlColumns,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    onPaginationChange: setPagination,
    pageCount: allProjWithRoles
      ? Math.ceil(allProjWithRoles.length / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <AppTable
      isEmpty={roles?.length === 0}
      colSpan={3}
      isLoading={isLoading}
      headerTableRow={
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>
              <div className="flex flex-row-reverse">
                <AppButton
                  onClick={() => {
                    setSearchParams((p) => {
                      p.set("drawerType", ROLE_FORM_DRAWER);
                      p.set("drawerState", "CREATE");
                      return p;
                    });
                  }}
                  label="+ Add Role"
                  className="h-10"
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-xl font-bold ">
              <p className="text-md font-bold">Role</p>
            </TableCell>
            <TableCell className="text-xl font-bold ">
              <p className="text-md font-bold ">Work Order Status</p>
            </TableCell>
            <TableCell className="text-xl font-bold ">
              <p className="text-md font-bold ">
                Preventive Maintenance Status
              </p>
            </TableCell>
          </TableRow>
        </TableHead>
      }
      bodyTableRow={allProjWithRoles?.map((proj) => (
        <React.Fragment key={proj.id}>
          <TableRow key={proj.id}>
            <TableCell
              colSpan={3}
              className="font-bold font-sans bg-neutral-100"
            >
              <p className="font-bold font-sans text-xl">
              {proj.name}

              </p>
            </TableCell>
          </TableRow>
          {proj?.roles.map((r) => (
            <TableRow key={r.id}>
              <TableCell>
                <div
                  className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
                  onClick={() => {
                    setSearchParams((p) => {
                      p.set("drawerType", ROLE_FORM_DRAWER);
                      p.set("drawerState", DRAWER_UPDATE_STATE);
                      p.set("roleId", r.id.toString() ?? "");
                      return p;
                    });
                  }}
                >
                  {r.name}
                </div>
              </TableCell>
              <TableCell>
                <div className="flex-wrap flex gap-x-1 gap-y-1 items-center">
                  {r.defectStatuses?.map((d) => {
                    return (
                      <Chip
                        key={d.id}
                        label={d.name}
                        onDelete={() => {
                          onDeleteWorkOrderStatus(d, r);
                        }}
                        className="mr-1 mb-1 text text-white font-bold"
                        style={{
                          backgroundColor: "#" + d.colorHex,
                          color: "white",
                        }}
                      />
                    );
                  })}
                  <AddWorkOrderStatusBtn role={r} />
                </div>
              </TableCell>
              <TableCell>
                <div className="flex-wrap flex gap-x-1 gap-y-1 items-center">
                  {r.pmStatuses?.map((pm) => {
                    return (
                      <Chip
                        key={pm.id}
                        label={pm.name}
                        onDelete={() => {
                          onDeletePmStatus(pm, r);
                        }}
                        className="mr-1 mb-1 text text-white font-bold"
                        style={{
                          backgroundColor: "#" + pm.colorHex,
                          color: "white",
                        }}
                      />
                    );
                  })}
                  <AddPmStatusBtn role={r} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      ))}
    />
  );
}
