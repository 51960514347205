import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useAppSelector } from "../../redux/store";
import AppTextField from "../AppTextField";
import AppButton from "../AppButton";
import { useEffect, useState } from "react";
import { Delete, Trash2 } from "lucide-react";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";
import { Input } from "../ui/input";

interface ConfirmDeleteBtnWithDialogProps {
  confirmDeleteTxt: string;
  onDeleteConfirm: () => Promise<void>;
  isIconDelete?: boolean;
  className?: string;
  btnIcon?: React.ReactNode;
}

export default function ConfirmDeleteBtnWithDialog({
  confirmDeleteTxt,
  onDeleteConfirm,
  isIconDelete = false,
  className,
  btnIcon,
}: ConfirmDeleteBtnWithDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<{ deleteTxt: string }>({
    defaultValues: {
      deleteTxt: "",
    },
  });

  const onSubmit: SubmitHandler<{ deleteTxt: string }> = async (data) => {
    setIsLoading(true);
    try {
      await onDeleteConfirm();
    } catch (e) {
      console.log("error here", e);
      setIsLoading(false);
    }
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <FormProvider {...methods}>
      {isIconDelete ? (
        <AppButton
          icon={<Trash2 />}
          variant="destructive"
          onClick={(e) => {
            e?.stopPropagation();
            setIsOpen(true);
          }}
        />
      ) : (
        // <IconButton
        //   onClick={(e) => {
        //     e.stopPropagation();
        //     setIsOpen(true);
        //   }}
        //   className={className}
        // >
        //   <Delete />
        // </IconButton>
        <AppButton
          color="warning"
          variant="destructive"
          label="Delete"
          className={className}
          icon={btnIcon}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      )}
      <Dialog
        onOpenChange={() => {
          setIsOpen(false);
        }}
        open={isOpen}
      >
        <DialogContent>
          <DialogTitle>
            <p className="font-bold">Confirm Delete</p>
          </DialogTitle>
          <div className="flex flex-col gap-4">
            <p>
              Please input{" "}
              <span className="font-bold text-red-500">{confirmDeleteTxt}</span>{" "}
              to confirm that you want to delete:
            </p>
            <Input
              placeholder="Please enter here.."
              className="focus-visible:ring-primary-900 bg-slate-50"
              {...methods.register("deleteTxt", {
                validate: (v) =>
                  v === confirmDeleteTxt || "Text does not match",
              })}
            />
            {
              <p className="text-sm font-medium text-red-500">
                {methods.formState.errors.deleteTxt?.message}
              </p>
            }
            <div className="flex flex-row-reverse">
              <AppButton
                isLoading={isLoading}
                label="Confirm"
                onClick={methods.handleSubmit(onSubmit)}
                color="warning"
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </FormProvider>
  );
}
