import { ChevronRight, Pen, Plus, Trash2 } from "lucide-react";
import AppMultiImage from "../../../../../../AppMultiImage";
import {
  DRAWER_VIEW_STATE,
  WORK_REQUEST_DETAIL_DRAWER,
} from "../../../../../AppDrawer";
import { useSearchParams } from "react-router-dom";
import { useDefectControllerGetOneDefect } from "../../../../../../../api/work-orders-defects/work-orders-defects";
import AppButton from "../../../../../../AppButton";
import { useWorkRequestControllerGetWorkRequests } from "../../../../../../../api/work-request/work-request";
import { useAppSelector } from "../../../../../../../redux/store";
import {
  useWorkOrderV2ControllerRemoveLinkedWorkRequest,
  useWorkOrderV2ControllerUpdateLinkedWorkRequest,
} from "../../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../../../ui/dialog";
import DialogContentWithSearchTable from "../../../../../../dialogs/AppSelectWithDialog/components/DialogContentWithSearchTable";
import { updateAssignmentColumns } from "../UpdateAssignmentBtnDialog/updateAssignmentColumns";
import { linkWorkRequestColumns } from "../../../../columns/linkWorkRequestColumns";
import { GetWorkRequestsResponseDto } from "../../../../../../../api/model";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import RemoveBtn from "./components/RemoveBtn";
import IsCloseAndEditAfterWorkOrderGuard from "../../../../components/IsCloseAndEditAfterWorkOrderGuard";

export default function WorkRequestUpdate() {
  const qc = useQueryClient();
  const [searchParam, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [open, setOpen] = useState(false);

  const activeWoId = searchParam.get("workOrderId");

  const { data: workOrder, isLoading } = useDefectControllerGetOneDefect(
    activeWoId ?? "",
    {
      query: {
        select: (res) => res.data,
      },
    }
  );

  const { data: workRequestList } = useWorkRequestControllerGetWorkRequests(
    {
      showUnlinkedOnly: true,
      workRequestStatus: "NEW",
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        select: (res) => res.data,
        enabled: !!activeProj,
      },
    }
  );

  const { mutate: updateWorkReq, isPending } =
    useWorkOrderV2ControllerUpdateLinkedWorkRequest({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("work-order") ||
              (q.queryKey[0] as string).includes("work-request") ||
              (q.queryKey[0] as string).includes("defect") ||
              (q.queryKey[0] as string).includes("comments"),
          });
        },
      },
    });

  const handleUpdate = (wrs: GetWorkRequestsResponseDto[]) => {
    updateWorkReq({
      workOrderId: activeWoId ?? "",
      data: {
        workRequestIds: wrs.map((wr) => wr.id),
      },
    });
  };

  return (
    <div className="space-y-3">
      {workOrder?.workRequests.length === 0 ? (
        <p>-</p>
      ) : (
        workOrder?.workRequests?.map((wr) => {
          return (
            <div
              key={wr.id}
              className="flex bg-neutral-300 rounded-xl items-center gap-4 cursor-pointer justify-between"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", WORK_REQUEST_DETAIL_DRAWER);
                  p.set("drawerState", DRAWER_VIEW_STATE);
                  p.set("workRequestId", wr.id.toString());
                  return p;
                });
              }}
            >
              <div className="flex gap-4 items-center flex-1">
                <div className="w-24">
                  <AppMultiImage attachments={wr.photos} hideMoreThanThree />
                </div>
                <div className="flex-grow">
                  <p className="font-sans font-bold text-xl text-ellipsis overflow-hidden line-clamp-1">
                    {wr.title}
                  </p>
                  <p className="font-sans text-ellipsis overflow-hidden line-clamp-3">
                    {wr.description}
                  </p>
                </div>
              </div>
              <RemoveBtn wr={wr} />
            </div>
          );
        })
      )}
      <IsCloseAndEditAfterWorkOrderGuard>
        <div>
          <AppButton
            label="Add"
            icon={<Plus />}
            onClick={() => setOpen(true)}
            isLoading={isPending || isLoading}
          />
          <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="w-full max-w-4xl overflow-y-scroll max-h-screen">
              <DialogHeader>
                <DialogTitle>Link additional Work Request</DialogTitle>
              </DialogHeader>
              <DialogContentWithSearchTable
                items={workRequestList ?? []}
                value={[]}
                columns={linkWorkRequestColumns}
                setRowSelection={handleUpdate}
                onSelected={(selected) => {
                  handleUpdate(selected);
                  setOpen(false);
                }}
                closeDialog={() => {
                  setOpen(false);
                }}
                multiple
              />
            </DialogContent>
          </Dialog>
        </div>
      </IsCloseAndEditAfterWorkOrderGuard>
    </div>
  );
}
