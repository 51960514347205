import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NotFoundScreen(){
    const nav = useNavigate();

    useEffect(() => {
        // Navigate back to splash screen, if all the links are not found
        nav("/")
    }, [])

    return <div>Screen not found</div>;
}