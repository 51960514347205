import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

interface AppTableProps<T> {
  headerTableRow: React.ReactNode;
  bodyTableRow: React.ReactNode;
  isLoading: boolean;
  isEmpty: boolean;
  colSpan: number;
  rowsPerPage?: number;
  onRowsPerPageChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  page?: number;
  onPageChange?: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  count?: number;
}

export default function AppTable<T>({
  headerTableRow,
  bodyTableRow,
  isLoading,
  isEmpty,
  colSpan,
  rowsPerPage,
  onRowsPerPageChange,
  page,
  onPageChange,
  count,
}: AppTableProps<T>) {
  return (
    <TableContainer component={Paper}>
      <Table>
        {headerTableRow}
        <TableBody>
          {isLoading &&
            [1, 2, 3].map((idx) => (
              <TableRow key={idx}>
                <TableCell colSpan={colSpan}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}
          {isEmpty && (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <div className="h-60 flex justify-center items-center">
                  <div className="text-neutral-600 truncate">No Data</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {!isLoading && bodyTableRow}
        </TableBody>
        <TableFooter>
          {rowsPerPage !== undefined &&
          onRowsPerPageChange !== undefined &&
          page !== undefined &&
          onPageChange !== undefined &&
          count !== undefined ? (
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                colSpan={colSpan}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
              />
            </TableRow>
          ) : (
            <></>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
