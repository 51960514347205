import { useSearchParams } from "react-router-dom";
import AppTextField from "../AppTextField";
import { Button } from "../ui/button";
import { ArrowUpDown, Filter } from "lucide-react";
import { cn } from "../../lib/utils";
import { Badge } from "../ui/badge";

interface AppSearchAndFilterProps {
  onFilterClick: () => void;
  placeholder?: string;
  filterCount: number;
}

export default function AppSearchAndFilter({
  onFilterClick,
  placeholder,
  filterCount,
}: // onSortClick,
AppSearchAndFilterProps) {
  return (
    <div className="flex gap-4">
      <AppTextField
        name="search"
        className="w-[400px]"
        inputClassName="bg-transparent"
        placeholder={placeholder ?? "Subject, Description, Ref No."}
      />
      <Button
        className={cn("hover:cursor-pointer truncate", {
          "bg-primary-900 hover:bg-primary-900": filterCount > 0,
        })}
        variant="outline"
        onClick={onFilterClick}
        asChild
      >
        <div className="flex gap-2">
          <Filter
            className={cn("text-gray-300 mr-1", {
              "text-white": filterCount > 0,
            })}
          />
          <div
            className={cn("hover:cursor-pointer text-gray-300", {
              "text-white": filterCount > 0,
            })}
          >
            Filter by
          </div>
          {filterCount > 0 && (
            <Badge variant="destructive" className="text-white">{filterCount}</Badge>
          )}
        </div>
      </Button>
    </div>
  );
}
