import { useEffect, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../../../components/ui/sheet";
import { cn } from "../../../../utils/util";
import {
  CheckCircle,
  CheckCircle2,
  ChevronRight,
  FileSearch,
  Loader2,
  Upload,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "../../../../components/ui/card";
import AppButton from "../../../../components/AppButton";
import {
  ImportAssetCsvObj,
  useDataImportsControllerGetLocationsQuery,
  useDataImportsControllerImportAssetsMutation,
  useLazyDataImportsControllerGetLocationsQuery,
} from "../../../../redux/slices/OpenApi/cerevApi";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useCSVDownloader } from "react-papaparse";
import CsvReader from "../../../AssetScreen/component/CsvReader";
import useError from "../../../../hooks/useError";
import DownloadAssetCsvTemplate from "./components/DownloadAssetCsvTemplate";
import useAssetImportCheck from "../../../../hooks/useAssetImportCheck";
import { SnackBarType, setOpenSnackBar } from "../../../../redux/slices/RootSlice";

interface ImportAssetSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ImportAssetSheet({
  open,
  setOpen,
}: ImportAssetSheetProps) {
  const dispatch = useAppDispatch();
  const [idx, setIdx] = useState(0);
  const [hasUpload, setHasUpload] = useState(false);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const {
    data: locationsData,
    isLoading: locationsDataIsLoading,
    isError: locationsDataIsError,
    error: locationsDataError,
  } = useDataImportsControllerGetLocationsQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      skip: !activeProj,
    }
  );

  const [
    importAssets,
    {
      isLoading: importAssetsIsLoading,
      isError: importAssetsIsError,
      error: importAssetsError,
      isSuccess: importAssetsIsSuccess,
    },
  ] = useDataImportsControllerImportAssetsMutation();

  const { CSVDownloader } = useCSVDownloader();

  const [assetCsv, setAssetCsv] = useState<ImportAssetCsvObj[]>([]);
  const checkErrors = useAssetImportCheck({ assetData: assetCsv });

  useError({
    dependencies: [{ isError: importAssetsIsError, error: importAssetsError }],
  });

  useEffect(() => {
    if (importAssetsIsSuccess) {
      cleanUp();
      dispatch(
        setOpenSnackBar({
          type: SnackBarType.Success,
          msg: "Your assets have been imported successfully.",
        })
      );
    }
  }, [importAssetsIsSuccess]);


  const cleanUp = () => {
    setIdx(0);
    setAssetCsv([]);
    setHasUpload(false);
    setOpen(false);
  };

  const getContent = () => {
    switch (idx) {
      case 0:
        return (
          <div className="flex flex-col gap-5 col-span-4">
            <Card className="w-full">
              <CardContent className="py-5 flex flex-col gap-5">
                <div className="text-lg font-bold">Importing Steps</div>
                <div className="text-slate-600 leading-relaxed text-sm">
                  Follow the steps below to begin your asset import
                </div>
                <div className="text-lg font-bold">Step 1</div>
                <div className="text-slate-600 leading-relaxed text-sm">
                  Download the current Location CSV list to get your location
                  codes
                </div>
                {!locationsDataIsLoading && !locationsDataIsError && (
                  <CSVDownloader
                    bom={true}
                    data={locationsData}
                    filename="cerev_locations"
                  >
                    <AppButton variant="outline" label="Download" />
                  </CSVDownloader>
                )}
                <div className="text-lg font-bold">Step 2</div>
                <div className="text-slate-600 leading-relaxed text-sm">
                  Download the Equipment CSV template to fill up your equipments
                </div>
                <DownloadAssetCsvTemplate />
                <div className="text-lg font-bold">Step 3</div>
                <div className="text-red-600 leading-relaxed text-sm">
                  IMPORTANT: Make sure that all the necessary <strong>EQUIPMENT TYPES</strong> are
                  created
                </div>
                <div className="text-lg font-bold">Step 4</div>
                <div className="text-slate-600 leading-relaxed text-sm">
                  Fill up the CSV file with your equipments, referencing to the
                  location code to indicate their locations. Upload the CSV
                  here.
                </div>
                <div className="flex">
                  <CsvReader
                    onUploadAccepted={(res) => {
                      setHasUpload(true);
                      setAssetCsv(res.data);
                    }}
                  />
                </div>
                {hasUpload && (
                  <>
                    <div className="text-lg font-bold">Checking for Errors</div>
                    {checkErrors.length === 0 && (
                    <div className="py-8 flex justify-center gap-4 items-center">
                        <CheckCircle2 className="w-12 h-12 text-primary-900" />
                        <div className="font-medium text-lg text-primary-900">
                          All are in order! Press next to continue import.
                        </div>
                      </div>
                    )}
                    <ol className="list-decimal ml-6">
                      {checkErrors.map((err, idx) => (
                        <li key={idx} className="text-red-400 font-sans">
                          {err.message}
                        </li>
                      ))}
                    </ol>
                  </>
                )}
              </CardContent>
            </Card>
            <div className="flex gap-3">
              <AppButton
                label="Confirm"
                disabled={checkErrors.length > 0 || !hasUpload}
                onClick={() => {
                  if (!activeProj || !activeComp) return;
                  importAssets({
                    importAssetRequest: {
                      data: assetCsv,
                      projectId: activeProj.id?.toString() ?? "",
                      companyId: activeComp.id?.toString(),
                    },
                  });
                  return setIdx(1);
                }}
              />
            </div>
          </div>
        );

      case 1:
        return (
          <div className="flex flex-col gap-4 col-span-4">
            <Card className="w-full">
              <CardContent className="py-5 flex flex-col gap-5 items-center">
                <p className="text-2xl text-primary-900 font-bold">
                  Uploading to Cerev Database
                </p>
                <Loader2 className="h-14 w-14 animate-spin text-primary-900" />
              </CardContent>
            </Card>
          </div>
        );
    }
  };

  return (
    <Sheet
      open={open}
      onOpenChange={(o) => {
        if (!o) {
          cleanUp();
        }
      }}
    >
      <SheetContent side="bottom" className="h-screen overflow-y-auto">
        <SheetHeader>
          <SheetTitle className="text-2xl">Import Assets</SheetTitle>
        </SheetHeader>
        <div className="flex justify-center my-16">
          <div className="max-w-5xl w-full grid grid-cols-5 gap-10">
            <div className="col-span-1">
              <ol className="relative text-gray-500 border-l border-gray-200">
                <div className="hover:cursor-pointer">
                  <li className="mb-10 ml-10">
                    <div className="absolute flex items-center justify-center w-12 h-12 bg-primary-200 rounded-full -left-6 ring-white ring-4">
                      <Upload className="text-primary-900 p-1 w-7 h-7" />
                    </div>
                    <div>
                      <div className="text-primary-900 font-medium pt-3">
                        Uploads
                      </div>
                    </div>
                  </li>
                </div>
                <div className="hover:cursor-pointer">
                  <li className="mb-10 ml-10">
                    <div
                      className={cn(
                        "transition-all duration-300 absolute flex items-center justify-center w-12 h-12 bg-primary-200 rounded-full -left-6 ring-white ring-4",
                        {
                          "bg-slate-50": idx < 1,
                        }
                      )}
                    >
                      <CheckCircle
                        className={cn(
                          "transition-all duration-300 text-primary-900 p-1 w-7 h-7",
                          {
                            "text-slate-400": idx < 1,
                          }
                        )}
                      />
                    </div>
                    <div>
                      <div
                        className={cn(
                          "transition-all duration-300 text-primary-900 font-medium pt-3",
                          {
                            "text-slate-400 font-normal": idx < 1,
                          }
                        )}
                      >
                        Results
                      </div>
                    </div>
                  </li>
                </div>
              </ol>
            </div>
            {getContent()}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
