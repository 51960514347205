import { ShieldClose } from "lucide-react";
import AppButton from "../../../AppButton";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../ui/dialog";
import { useSearchParams } from "react-router-dom";
import { useUserControllerGetOneUser } from "../../../../api/users/users";
import { Status } from "../../../../api/model";
import {
  useAuthV2ControllerActivateUser,
  useAuthV2ControllerDeactivateUser,
} from "../../../../api/auth-v2/auth-v2";
import { useQueryClient } from "@tanstack/react-query";

export default function AccActivationBtn() {
  const qc = useQueryClient();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const firebaseUserId = searchParams.get("firebaseId") as string;

  const { data: user } = useUserControllerGetOneUser(firebaseUserId ?? "", {
    query: {
      enabled: !!firebaseUserId,
      select: (res) => res.data,
    },
  });

  const { mutate: activateUser, isPending: isPendingActivate } =
    useAuthV2ControllerActivateUser({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("user"),
          });
          setOpen(false);
        },
      },
    });

  const isActive = user?.status === Status.ACTIVE ?? true;

  const { mutate: deactivateUser, isPending } =
    useAuthV2ControllerDeactivateUser({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("user"),
          });
          setOpen(false);
        },
      },
    });

  const onSubmit = () => {
    if (!user?.id) return;
    if (isActive) {
      deactivateUser({
        userId: user?.id?.toString(),
      });
    } else {
      activateUser({
        userId: user?.id?.toString(),
      });
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogTitle>
            <p className="font-bold">
              {isActive ? "Deactivate User" : "Activate User"}
            </p>
          </DialogTitle>
          {isActive ? (
            <div className="space-y-4">
              <p>
                Are you sure you want to{" "}
                <span className="font-bold text-red-500">DEACTIVATE</span>{" "}
                {user?.name} ?
              </p>
              <p className="text-sm text-slate-400">
                The data created and updated by this user remains, but he/she
                will not be able to login Cerev CMMS.
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              <p>
                Are you sure you want to{" "}
                <span className="font-bold text-green-500">ACTIVATE</span>{" "}
                {user?.name} ?
              </p>
              <p className="text-sm text-slate-400">
                The user will be able to access Cerev CMMS.
              </p>
            </div>
          )}
          <DialogFooter>
            <AppButton
              label="Cancel"
              variant="outline"
              onClick={() => {
                setOpen(false);
              }}
            />
            <AppButton
              label="Confirm"
              isLoading={isPending || isPendingActivate}
              onClick={onSubmit}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <AppButton
        icon={<ShieldClose />}
        variant="outline"
        label={isActive ? "Deactivate" : "Activate"}
        className={
          isActive
            ? "text-red-500 border-red-500 hover:text-red-600 hover:bg-red-100"
            : "text-green-500 border-green-500 hover:text-green-600 hover:bg-green-100"
        }
        onClick={() => {
          setOpen(true);
        }}
      />
    </>
  );
}
