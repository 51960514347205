import {Skeleton} from "@mui/material";

export default function MainPageSkeleton() {
  return (
    <div className="flex flex-col gap-6 mt-4 w-full h-full flex-grow">
      <div className="flex gap-4">
        <Skeleton height="45px" variant="rounded" width="300px" />
        <Skeleton height="45px" variant="rounded" width="150px" />
      </div>
      <Skeleton variant="rounded" className="h-1/2 flex-grow mb-6" />
    </div>
  );
}
