import { ColumnDef } from "@tanstack/react-table";
import { Location } from "../../../../api/model";
import { Checkbox } from "../../../../components/ui/checkbox";
import { useLocationControllerGetFullLocation } from "../../../../api/location/location";
import { ChevronLeft } from "lucide-react";
import React from "react";

export const publicWorkRequestLocationColumns: ColumnDef<Location>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "name",
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <p className="font-bold">{row.getValue("name")}</p>,
  },
  {
    header: "Location",
    cell: ({ row }) => {
      const {
        data: locations,
        isLoading,
        isError,
      } = useLocationControllerGetFullLocation(
        {
          locationId: row.getValue("id") ?? "",
        },
        {
          query: {
            enabled: !!row.getValue("id"),
            select: (res) => res.data,
            staleTime: 1000 * 60 * 60 * 24,
          },
        }
      );

      if (isLoading || isError || !row.getValue("id"))
        return <p className="font-sans text-neutral-700">-</p>;

      return (
        <div className="flex gap-2 items-center">
          {locations?.reduce<React.ReactNode[]>((prev, curr, index) => {
            if (index === 0) return prev;
            let newElement = (
              <span
                key={index}
                className={`${index === 0 ? "font-bold" : "text-slate-400"}`}
              >
                {curr.name}
              </span>
            );

            const arrowLeft = <ChevronLeft className="text-slate-400" />;
            return index !== 1
              ? [...prev, arrowLeft, newElement]
              : [newElement];
          }, [] as React.ReactNode[])}
        </div>
      );
    },
  },
  {
    accessorKey: "code",
    header: "Code",
  },
];
