/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetUserHasAccessModuleResponseDto,
  ModuleAccess,
  ModuleAccessControllerGetAllUserModuleAccessParams,
  ModuleAccessControllerGetUserHasAccessModuleParams,
  UpdateModuleAccessRequestDto,
} from ".././model";

export const moduleAccessControllerGetAllUserModuleAccess = (
  userId: string,
  params: ModuleAccessControllerGetAllUserModuleAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModuleAccess[]>> => {
  return axios.get(`/module-access/user/${userId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getModuleAccessControllerGetAllUserModuleAccessQueryKey = (
  userId: string,
  params: ModuleAccessControllerGetAllUserModuleAccessParams
) => {
  return [
    `/module-access/user/${userId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getModuleAccessControllerGetAllUserModuleAccessInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>,
      ModuleAccessControllerGetAllUserModuleAccessParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    userId: string,
    params: ModuleAccessControllerGetAllUserModuleAccessParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
          >,
          QueryKey,
          ModuleAccessControllerGetAllUserModuleAccessParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getModuleAccessControllerGetAllUserModuleAccessQueryKey(userId, params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>,
      QueryKey,
      ModuleAccessControllerGetAllUserModuleAccessParams["cursorId"]
    > = ({ signal, pageParam }) =>
      moduleAccessControllerGetAllUserModuleAccess(
        userId,
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return {
      queryKey,
      queryFn,
      enabled: !!userId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>,
      TError,
      TData,
      Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>,
      QueryKey,
      ModuleAccessControllerGetAllUserModuleAccessParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type ModuleAccessControllerGetAllUserModuleAccessInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>
  >;
export type ModuleAccessControllerGetAllUserModuleAccessInfiniteQueryError =
  AxiosError<unknown>;

export const useModuleAccessControllerGetAllUserModuleAccessInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>,
    ModuleAccessControllerGetAllUserModuleAccessParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: ModuleAccessControllerGetAllUserModuleAccessParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
        >,
        QueryKey,
        ModuleAccessControllerGetAllUserModuleAccessParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getModuleAccessControllerGetAllUserModuleAccessInfiniteQueryOptions(
      userId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getModuleAccessControllerGetAllUserModuleAccessQueryOptions = <
  TData = Awaited<
    ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: ModuleAccessControllerGetAllUserModuleAccessParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getModuleAccessControllerGetAllUserModuleAccessQueryKey(userId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>
  > = ({ signal }) =>
    moduleAccessControllerGetAllUserModuleAccess(userId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ModuleAccessControllerGetAllUserModuleAccessQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>>
  >;
export type ModuleAccessControllerGetAllUserModuleAccessQueryError =
  AxiosError<unknown>;

export const useModuleAccessControllerGetAllUserModuleAccess = <
  TData = Awaited<
    ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: ModuleAccessControllerGetAllUserModuleAccessParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof moduleAccessControllerGetAllUserModuleAccess>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getModuleAccessControllerGetAllUserModuleAccessQueryOptions(
      userId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const moduleAccessControllerUpdateModuleAccess = (
  userId: string,
  updateModuleAccessRequestDto: UpdateModuleAccessRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/module-access/user/${userId}`,
    updateModuleAccessRequestDto,
    options
  );
};

export const getModuleAccessControllerUpdateModuleAccessMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moduleAccessControllerUpdateModuleAccess>>,
    TError,
    { userId: string; data: UpdateModuleAccessRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof moduleAccessControllerUpdateModuleAccess>>,
  TError,
  { userId: string; data: UpdateModuleAccessRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof moduleAccessControllerUpdateModuleAccess>>,
    { userId: string; data: UpdateModuleAccessRequestDto }
  > = (props) => {
    const { userId, data } = props ?? {};

    return moduleAccessControllerUpdateModuleAccess(userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ModuleAccessControllerUpdateModuleAccessMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof moduleAccessControllerUpdateModuleAccess>>
  >;
export type ModuleAccessControllerUpdateModuleAccessMutationBody =
  UpdateModuleAccessRequestDto;
export type ModuleAccessControllerUpdateModuleAccessMutationError =
  AxiosError<unknown>;

export const useModuleAccessControllerUpdateModuleAccess = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moduleAccessControllerUpdateModuleAccess>>,
    TError,
    { userId: string; data: UpdateModuleAccessRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof moduleAccessControllerUpdateModuleAccess>>,
  TError,
  { userId: string; data: UpdateModuleAccessRequestDto },
  TContext
> => {
  const mutationOptions =
    getModuleAccessControllerUpdateModuleAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
export const moduleAccessControllerGetUserHasAccessModule = (
  userId: string,
  params: ModuleAccessControllerGetUserHasAccessModuleParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetUserHasAccessModuleResponseDto>> => {
  return axios.get(`/module-access/user/${userId}/module`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getModuleAccessControllerGetUserHasAccessModuleQueryKey = (
  userId: string,
  params: ModuleAccessControllerGetUserHasAccessModuleParams
) => {
  return [
    `/module-access/user/${userId}/module`,
    ...(params ? [params] : []),
  ] as const;
};

export const getModuleAccessControllerGetUserHasAccessModuleInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>,
      ModuleAccessControllerGetUserHasAccessModuleParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    userId: string,
    params: ModuleAccessControllerGetUserHasAccessModuleParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
          >,
          QueryKey,
          ModuleAccessControllerGetUserHasAccessModuleParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getModuleAccessControllerGetUserHasAccessModuleQueryKey(userId, params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>,
      QueryKey,
      ModuleAccessControllerGetUserHasAccessModuleParams["cursorId"]
    > = ({ signal, pageParam }) =>
      moduleAccessControllerGetUserHasAccessModule(
        userId,
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return {
      queryKey,
      queryFn,
      enabled: !!userId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>,
      TError,
      TData,
      Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>,
      QueryKey,
      ModuleAccessControllerGetUserHasAccessModuleParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type ModuleAccessControllerGetUserHasAccessModuleInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>
  >;
export type ModuleAccessControllerGetUserHasAccessModuleInfiniteQueryError =
  AxiosError<unknown>;

export const useModuleAccessControllerGetUserHasAccessModuleInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>,
    ModuleAccessControllerGetUserHasAccessModuleParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: ModuleAccessControllerGetUserHasAccessModuleParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
        >,
        QueryKey,
        ModuleAccessControllerGetUserHasAccessModuleParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getModuleAccessControllerGetUserHasAccessModuleInfiniteQueryOptions(
      userId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getModuleAccessControllerGetUserHasAccessModuleQueryOptions = <
  TData = Awaited<
    ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: ModuleAccessControllerGetUserHasAccessModuleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getModuleAccessControllerGetUserHasAccessModuleQueryKey(userId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>
  > = ({ signal }) =>
    moduleAccessControllerGetUserHasAccessModule(userId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ModuleAccessControllerGetUserHasAccessModuleQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>>
  >;
export type ModuleAccessControllerGetUserHasAccessModuleQueryError =
  AxiosError<unknown>;

export const useModuleAccessControllerGetUserHasAccessModule = <
  TData = Awaited<
    ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: ModuleAccessControllerGetUserHasAccessModuleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof moduleAccessControllerGetUserHasAccessModule>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getModuleAccessControllerGetUserHasAccessModuleQueryOptions(
      userId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
