import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useStockTypesControllerGetStockTypes } from "@/api/stock-inventory-types/stock-inventory-types";
import { useAppSelector } from "@/redux/store";
import {
  useWorkOrderInventoryActions,
  useWorkOrderInventoryLedgers,
} from "@/stores/workOrderInventoryStore/workOrderInventoryStore";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import useDebounce from "../../../../../../../../hooks/useDebounce";
import { StocksControllerGetStocksCountParams, StocksControllerGetStocksParams } from "../../../../../../../../api/model";
import { useStocksControllerGetStocks, useStocksControllerGetStocksCount } from "../../../../../../../../api/stocks-inventory/stocks-inventory";
import { selectInventoryColumns } from "./SelectInventoryColumns";
import { PaginationState, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useInventoryFilterStore } from "../../../../../../../../stores/inventoryFilterStore/inventoryFilterStore";
import { DataTable } from "../../../../../../../react-table/DataTable";
import AppSearchAndFilter from "../../../../../../../searchAndFilter/AppSearchAndFilter";
import { INVENTORY_FILTER_DRAWER } from "../../../../../../AppDrawer";
import AppTextField from "../../../../../../../AppTextField";


export default function SelectInventoryUseSection() {

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    }
  });
  const search = useWatch({ control: methods.control, name: "search" });
  const filterCount = useInventoryFilterStore((state) => state.getFilterCount);

  const inventoryTypeFilter = useInventoryFilterStore(
    (state) => state.inventoryType
  );


  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: inventoryTypes } = useStockTypesControllerGetStockTypes(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const queryArg = useDebounce<StocksControllerGetStocksParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      stockTypeId: inventoryTypeFilter?.id?.toString(),
      search,
      rowsPerPage: pagination.pageSize,
      page: pagination.pageIndex,
    },
    delay: 500,
  });

  const queryCountArg = useDebounce<StocksControllerGetStocksCountParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      stockTypeId: inventoryTypeFilter?.id?.toString(),
      search,
    },
    delay: 500,
  });

  const {
    data: inventories,
    isLoading,
    isFetching,
  } = useStocksControllerGetStocks(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const { data: totalCount, isLoading: totalCountIsLoading } =
    useStocksControllerGetStocksCount(queryCountArg, {
      query: {
        enabled: !!activeProj,
      },
    });

  const table = useReactTable({
    data: inventories?.data ?? [],
    columns: selectInventoryColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: totalCount?.data?.totalCount
      ? Math.ceil(totalCount?.data?.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        warningBalance: false,
        inventoryMode: false,
        isMultiLocation: false,
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <AppTextField name="search" placeholder="Search..." />
        <DataTable
          isLoading={isLoading}
          isFetching={isFetching}
          table={table}
          totalCount={totalCount?.data?.totalCount ?? 0}
          rowsPerPage={pagination.pageSize}
          pageIdx={pagination.pageIndex}
        />
      </div>
    </FormProvider>
  );
}
