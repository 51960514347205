import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/store";
import { useAssetControllerGetOneAsset } from "../../../../../api/assets/assets";
import AppButton from "../../../../AppButton";
import { ASSET_FORM_DRAWER, DRAWER_CREATE_STATE } from "../../../AppDrawer";
import { DataTable } from "../../../../react-table/DataTable";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { subAssetColumns } from "./columns";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../ui/card";
import { ChevronLeft } from "lucide-react";

export default function SubEquipmentTab() {
  const [searchParam, setSearchParams] = useSearchParams();
  const assetId = searchParam.get("assetId");
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const {
    data: assets,
    isLoading: assetIsLoading,
    isFetching,
  } = useAssetControllerGetOneAsset(
    assetId ?? "",
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!assetId && !!activeProj,
        select: (res) => ({
          currentAsset: res.data,
          children: res.data.childrenAsset,
        }),
      },
    }
  );

  const table = useReactTable({
    data: assets?.children ?? [],
    columns: subAssetColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  if (assets?.currentAsset?.parentAssetId) {
    return (
      <Card className="mt-6">
        <CardHeader>
          <CardTitle className="font-semibold text-xl">
            Sub equipment under
          </CardTitle>
          <CardDescription className="text-base">
            {assets?.currentAsset?.parentAsset?.name}
          </CardDescription>
          <AppButton
            className="w-fit"
            variant="outline"
            label={`View`}
            icon={<ChevronLeft />}
            onClick={() => {
              setSearchParams((p) => {
                p.set(
                  "assetId",
                  assets?.currentAsset?.parentAssetId?.toString() ?? ""
                );
                p.set("assetDetailTabIdx", "3");
                return p;
              });
            }}
          />
        </CardHeader>
      </Card>
    );
  } else {
    return (
      <div className="space-y-4 pt-4">
        <DataTable
          isLoading={assetIsLoading}
          isFetching={isFetching}
          table={table}
          totalCount={assets?.children?.length ?? 0}
        />
        <AppButton
          label="+ Add"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", ASSET_FORM_DRAWER);
              p.set("drawerState", DRAWER_CREATE_STATE);
              p.set("parentAssetId", assetId ?? "");
              return p;
            });
          }}
        />
      </div>
    );
  }
}
