/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateWorkOrderDto,
  DefectControllerCreateDefect201,
  DefectControllerGetDefectsParams,
  DefectControllerGetWorkOrderCountParams,
  GetOneWorkOrderResponseDto,
  GetWorkOrdersResponseDto,
  PatchDefectDto,
  UpdateExtraExpensesFieldRequestDto,
} from ".././model";

export const defectControllerGetDefects = (
  params?: DefectControllerGetDefectsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkOrdersResponseDto[]>> => {
  return axios.get(`/defect`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDefectControllerGetDefectsQueryKey = (
  params?: DefectControllerGetDefectsParams
) => {
  return [`/defect`, ...(params ? [params] : [])] as const;
};

export const getDefectControllerGetDefectsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetDefects>>,
    DefectControllerGetDefectsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: DefectControllerGetDefectsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetDefects>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectControllerGetDefects>>,
        QueryKey,
        DefectControllerGetDefectsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDefectControllerGetDefectsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetDefects>>,
    QueryKey,
    DefectControllerGetDefectsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    defectControllerGetDefects(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetDefects>>,
    TError,
    TData,
    Awaited<ReturnType<typeof defectControllerGetDefects>>,
    QueryKey,
    DefectControllerGetDefectsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type DefectControllerGetDefectsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerGetDefects>>
>;
export type DefectControllerGetDefectsInfiniteQueryError = AxiosError<unknown>;

export const useDefectControllerGetDefectsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetDefects>>,
    DefectControllerGetDefectsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: DefectControllerGetDefectsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetDefects>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectControllerGetDefects>>,
        QueryKey,
        DefectControllerGetDefectsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectControllerGetDefectsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectControllerGetDefectsQueryOptions = <
  TData = Awaited<ReturnType<typeof defectControllerGetDefects>>,
  TError = AxiosError<unknown>
>(
  params?: DefectControllerGetDefectsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetDefects>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDefectControllerGetDefectsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetDefects>>
  > = ({ signal }) =>
    defectControllerGetDefects(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetDefects>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectControllerGetDefectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerGetDefects>>
>;
export type DefectControllerGetDefectsQueryError = AxiosError<unknown>;

export const useDefectControllerGetDefects = <
  TData = Awaited<ReturnType<typeof defectControllerGetDefects>>,
  TError = AxiosError<unknown>
>(
  params?: DefectControllerGetDefectsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetDefects>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectControllerGetDefectsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectControllerCreateDefect = (
  createWorkOrderDto: CreateWorkOrderDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectControllerCreateDefect201>> => {
  return axios.post(`/defect`, createWorkOrderDto, options);
};

export const getDefectControllerCreateDefectMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectControllerCreateDefect>>,
    TError,
    { data: CreateWorkOrderDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof defectControllerCreateDefect>>,
  TError,
  { data: CreateWorkOrderDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof defectControllerCreateDefect>>,
    { data: CreateWorkOrderDto }
  > = (props) => {
    const { data } = props ?? {};

    return defectControllerCreateDefect(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DefectControllerCreateDefectMutationResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerCreateDefect>>
>;
export type DefectControllerCreateDefectMutationBody = CreateWorkOrderDto;
export type DefectControllerCreateDefectMutationError = AxiosError<unknown>;

export const useDefectControllerCreateDefect = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectControllerCreateDefect>>,
    TError,
    { data: CreateWorkOrderDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof defectControllerCreateDefect>>,
  TError,
  { data: CreateWorkOrderDto },
  TContext
> => {
  const mutationOptions =
    getDefectControllerCreateDefectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const defectControllerGetOneDefect = (
  defectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneWorkOrderResponseDto>> => {
  return axios.get(`/defect/${defectId}`, options);
};

export const getDefectControllerGetOneDefectQueryKey = (defectId: string) => {
  return [`/defect/${defectId}`] as const;
};

export const getDefectControllerGetOneDefectInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetOneDefect>>
  >,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDefectControllerGetOneDefectQueryKey(defectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetOneDefect>>
  > = ({ signal }) =>
    defectControllerGetOneDefect(defectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!defectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectControllerGetOneDefectInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerGetOneDefect>>
>;
export type DefectControllerGetOneDefectInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectControllerGetOneDefectInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetOneDefect>>
  >,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectControllerGetOneDefectInfiniteQueryOptions(
    defectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectControllerGetOneDefectQueryOptions = <
  TData = Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDefectControllerGetOneDefectQueryKey(defectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetOneDefect>>
  > = ({ signal }) =>
    defectControllerGetOneDefect(defectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!defectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectControllerGetOneDefectQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerGetOneDefect>>
>;
export type DefectControllerGetOneDefectQueryError = AxiosError<unknown>;

export const useDefectControllerGetOneDefect = <
  TData = Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetOneDefect>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectControllerGetOneDefectQueryOptions(
    defectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectControllerUpdateDefect = (
  defectId: string,
  patchDefectDto: PatchDefectDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/defect/${defectId}`, patchDefectDto, options);
};

export const getDefectControllerUpdateDefectMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectControllerUpdateDefect>>,
    TError,
    { defectId: string; data: PatchDefectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof defectControllerUpdateDefect>>,
  TError,
  { defectId: string; data: PatchDefectDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof defectControllerUpdateDefect>>,
    { defectId: string; data: PatchDefectDto }
  > = (props) => {
    const { defectId, data } = props ?? {};

    return defectControllerUpdateDefect(defectId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DefectControllerUpdateDefectMutationResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerUpdateDefect>>
>;
export type DefectControllerUpdateDefectMutationBody = PatchDefectDto;
export type DefectControllerUpdateDefectMutationError = AxiosError<unknown>;

export const useDefectControllerUpdateDefect = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectControllerUpdateDefect>>,
    TError,
    { defectId: string; data: PatchDefectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof defectControllerUpdateDefect>>,
  TError,
  { defectId: string; data: PatchDefectDto },
  TContext
> => {
  const mutationOptions =
    getDefectControllerUpdateDefectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const defectControllerUpdateExtraFieldExpenses = (
  defectId: string,
  updateExtraExpensesFieldRequestDto: UpdateExtraExpensesFieldRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/defect/${defectId}/update-extra-expenses`,
    updateExtraExpensesFieldRequestDto,
    options
  );
};

export const getDefectControllerUpdateExtraFieldExpensesMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectControllerUpdateExtraFieldExpenses>>,
    TError,
    { defectId: string; data: UpdateExtraExpensesFieldRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof defectControllerUpdateExtraFieldExpenses>>,
  TError,
  { defectId: string; data: UpdateExtraExpensesFieldRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof defectControllerUpdateExtraFieldExpenses>>,
    { defectId: string; data: UpdateExtraExpensesFieldRequestDto }
  > = (props) => {
    const { defectId, data } = props ?? {};

    return defectControllerUpdateExtraFieldExpenses(
      defectId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DefectControllerUpdateExtraFieldExpensesMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof defectControllerUpdateExtraFieldExpenses>>
  >;
export type DefectControllerUpdateExtraFieldExpensesMutationBody =
  UpdateExtraExpensesFieldRequestDto;
export type DefectControllerUpdateExtraFieldExpensesMutationError =
  AxiosError<unknown>;

export const useDefectControllerUpdateExtraFieldExpenses = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectControllerUpdateExtraFieldExpenses>>,
    TError,
    { defectId: string; data: UpdateExtraExpensesFieldRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof defectControllerUpdateExtraFieldExpenses>>,
  TError,
  { defectId: string; data: UpdateExtraExpensesFieldRequestDto },
  TContext
> => {
  const mutationOptions =
    getDefectControllerUpdateExtraFieldExpensesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const defectControllerGetWorkOrderCount = (
  workStatusId: string,
  params: DefectControllerGetWorkOrderCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.get(`/defect/status/${workStatusId}/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDefectControllerGetWorkOrderCountQueryKey = (
  workStatusId: string,
  params: DefectControllerGetWorkOrderCountParams
) => {
  return [
    `/defect/status/${workStatusId}/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getDefectControllerGetWorkOrderCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
    DefectControllerGetWorkOrderCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  workStatusId: string,
  params: DefectControllerGetWorkOrderCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
        QueryKey,
        DefectControllerGetWorkOrderCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectControllerGetWorkOrderCountQueryKey(workStatusId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
    QueryKey,
    DefectControllerGetWorkOrderCountParams["cursorId"]
  > = ({ signal, pageParam }) =>
    defectControllerGetWorkOrderCount(
      workStatusId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!workStatusId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
    TError,
    TData,
    Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
    QueryKey,
    DefectControllerGetWorkOrderCountParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type DefectControllerGetWorkOrderCountInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>
>;
export type DefectControllerGetWorkOrderCountInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectControllerGetWorkOrderCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
    DefectControllerGetWorkOrderCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  workStatusId: string,
  params: DefectControllerGetWorkOrderCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
        QueryKey,
        DefectControllerGetWorkOrderCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectControllerGetWorkOrderCountInfiniteQueryOptions(
    workStatusId,
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectControllerGetWorkOrderCountQueryOptions = <
  TData = Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
  TError = AxiosError<unknown>
>(
  workStatusId: string,
  params: DefectControllerGetWorkOrderCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectControllerGetWorkOrderCountQueryKey(workStatusId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>
  > = ({ signal }) =>
    defectControllerGetWorkOrderCount(workStatusId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workStatusId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectControllerGetWorkOrderCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>
>;
export type DefectControllerGetWorkOrderCountQueryError = AxiosError<unknown>;

export const useDefectControllerGetWorkOrderCount = <
  TData = Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
  TError = AxiosError<unknown>
>(
  workStatusId: string,
  params: DefectControllerGetWorkOrderCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetWorkOrderCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectControllerGetWorkOrderCountQueryOptions(
    workStatusId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectControllerGetCurrentDefectStatus = (
  defectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/defect/${defectId}/defectStatus`, options);
};

export const getDefectControllerGetCurrentDefectStatusQueryKey = (
  defectId: string
) => {
  return [`/defect/${defectId}/defectStatus`] as const;
};

export const getDefectControllerGetCurrentDefectStatusInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>
  >,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectControllerGetCurrentDefectStatusQueryKey(defectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>
  > = ({ signal }) =>
    defectControllerGetCurrentDefectStatus(defectId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!defectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectControllerGetCurrentDefectStatusInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>
  >;
export type DefectControllerGetCurrentDefectStatusInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectControllerGetCurrentDefectStatusInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>
  >,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectControllerGetCurrentDefectStatusInfiniteQueryOptions(
      defectId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectControllerGetCurrentDefectStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectControllerGetCurrentDefectStatusQueryKey(defectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>
  > = ({ signal }) =>
    defectControllerGetCurrentDefectStatus(defectId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!defectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectControllerGetCurrentDefectStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>
>;
export type DefectControllerGetCurrentDefectStatusQueryError =
  AxiosError<unknown>;

export const useDefectControllerGetCurrentDefectStatus = <
  TData = Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
  TError = AxiosError<unknown>
>(
  defectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectControllerGetCurrentDefectStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectControllerGetCurrentDefectStatusQueryOptions(
    defectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
