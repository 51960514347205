import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Building, Loader2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import useMeasure from "react-use-measure";
import {
  useBimControllerGetBimModel,
  useBimControllerGetBimNodes,
} from "../../../../api/bim/bim";
import { useAppSelector } from "../../../../redux/store";
import { useBimActions } from "../../../../stores/bimStore/bimStore";
import BimNode from "./components/BimNode";
import BimToolsEquipmentLinkSection from "./components/BimToolsEquipmentLinkSection";
import BimBottomBar from "./components/BimBottomBar";
import { useEffect } from "react";

export default function BimModelView() {
  const [ref, bounds] = useMeasure();
  const [searchParams, setSearchParams] = useSearchParams();
  const bimId = searchParams.get("bimId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: bimModel, isLoading } = useBimControllerGetBimModel(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  // Set searchparam to have bimId if bim asset is detected
  useEffect(() => {
    if (bimModel?.bimAsset?.id)
      setSearchParams({ bimId: bimModel.id.toString() });
  }, [bimModel]);

  if (isLoading && !bimId)
    return (
      <div className="flex-grow flex flex-col items-center justify-center gap-4">
        <Building className="h-10 w-10 text-primary-900" />
        <p className="text-slate-500">Processing BIM Model...</p>
        <Loader2 className="h-12 w-12 animate-spin text-primary-900" />
      </div>
    );

  return (
    <div className="flex-grow flex flex-col relative">
      <div className="flex-grow" ref={ref}>
        <Canvas
          style={{ height: bounds.height }}
          className="border-2 border-slate-200 rounded-md"
        >
          <ambientLight intensity={1} color="white" />
          <directionalLight color="white" position={[0, 0, 5]} />
          <directionalLight color="white" position={[0, 0, -5]} />
          <directionalLight color="white" position={[0, 5, 5]} />
          <directionalLight color="white" position={[5, 5, 5]} />
          <BimNode bimModel={bimModel} />
          <OrbitControls />
        </Canvas>
        <BimToolsEquipmentLinkSection />
        <BimBottomBar />
      </div>
    </div>
  );
}
