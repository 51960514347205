import * as React from "react";
import { View } from "@react-pdf/renderer";

interface TableRowProps {
  header?: boolean;
  children: React.ReactNode;
}

export const TableRow = ({ header, children }: TableRowProps) => (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontWeight: header ? "bold" : "normal",
        textAlign: "center",
        border: "0.5px solid lightgray",
      }}
    >
      {children}
    </View>
  );