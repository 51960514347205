import { Computer, FileSpreadsheet, MoreHorizontal } from "lucide-react";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { useCustomCsvControllerGetCustomCsvSettings } from "../../../api/custom-csv/custom-csv";
import { useAppSelector } from "../../../redux/store";
import { CustomCsvType } from "../../../api/model";

export default function AssetMoreAction() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data } = useCustomCsvControllerGetCustomCsvSettings(
    activeProj?.id?.toString() ?? "",
    {
      customCsvType: CustomCsvType.EQUIPMENT,
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  if (data?.length === 0) return <></>;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="text-slate-400">
          <MoreHorizontal />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>More Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {data?.map((setting) => (
          <DropdownMenuItem
            className="flex gap-4"
            onClick={() => {
              window.open(`/custom-csv/${setting.id}`);
            }}
            key={setting.id}
          >
            <FileSpreadsheet className="w-4 h-4" />
            <p>Download CSV</p>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
