/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type StockEntityInventoryMode =
  (typeof StockEntityInventoryMode)[keyof typeof StockEntityInventoryMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StockEntityInventoryMode = {
  QUANTITY: "QUANTITY",
  SERIALIZED: "SERIALIZED",
} as const;
