import { ColumnDef, Row } from "@tanstack/react-table";
import {
  DRAWER_VIEW_STATE,
  INVENTORY_DETAIL_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import { useSearchParams } from "react-router-dom";
import AppMultiImage from "../../../../components/AppMultiImage";
import BalanceStatusBadge from "../../../../components/drawers/inventory/components/BalanceInfo/components/BalanceStatusBadge";
import { CreateStockDtoInventoryMode, StockEntity } from "@/api/model";
import { Badge } from "@/components/ui/badge";

const InventoryNameNav = ({ inventory }: { inventory: Row<StockEntity> }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className={`flex flex-col gap-1`}>
      <div
        className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
        onClick={() => {
          setSearchParams((p) => {
            p.set("drawerType", INVENTORY_DETAIL_DRAWER);
            p.set("drawerState", DRAWER_VIEW_STATE);
            p.set("inventoryId", inventory.getValue("id"));
            return p;
          });
        }}
      >
        {inventory.getValue("name") ?? "-"}
      </div>
      {inventory.getValue("inventoryMode") ===
        CreateStockDtoInventoryMode.SERIALIZED && (
        <div>
          <Badge className={`bg-primary-900`}>
            {inventory.getValue("inventoryMode")}
          </Badge>
        </div>
      )}
    </div>
  );
};

export const inventoryColumns: ColumnDef<StockEntity>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    id: "warningBalance",
    accessorKey: "warningBalance",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <InventoryNameNav inventory={row} />,
  },
  {
    id: "description",
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "stockType.name",
    header: "Inventory Type",
  },
  {
    accessorKey: "balance",
    header: "Balance",
  },
  {
    accessorKey: "photos",
    header: "Photos",
    cell: ({ row }) => (
      <AppMultiImage attachments={row.getValue("photos")} hideMoreThanThree />
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const warningBalance = row.getValue("warningBalance");
      const balance = row.getValue("balance");

      return (
        <BalanceStatusBadge
          warningBalance={warningBalance as number}
          balance={balance as number}
          className="h-24 w-24"
        />
      );
    },
  },
  {
    id: "inventoryMode",
    accessorKey: "inventoryMode",
  },
];
