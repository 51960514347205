import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import AppButton from "../../../../../components/AppButton";
import {
  DefectStatus,
  GetRolesResponseDto,
  PmChecklistStatus,
  RoleWithStatuses,
  useDefectStatusControllerGetAllWorkOrderStatusQuery,
  usePmChecklistStatusControllerGetAllPmChecklistStatusQuery,
  useRolesControllerUpdateRoleStatusMutation,
} from "../../../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../../../redux/store";
import { Button } from "../../../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../../../components/ui/dropdown-menu";

interface AddPmStatusBtnProps {
  role: RoleWithStatuses;
}

export default function AddPmStatusBtn({ role }: AddPmStatusBtnProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: pmStatuses, isLoading } =
    usePmChecklistStatusControllerGetAllPmChecklistStatusQuery(
      {
        companyId: activeComp?.id ?? 0,
      },
      {
        skip: !activeComp,
      }
    );
  const [updateRole, { isLoading: updateRoleIsLoading }] =
    useRolesControllerUpdateRoleStatusMutation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (sta: PmChecklistStatus) => {
    if (sta) {
      updateRole({
        roleId: role.id,
        updateRoleDto: {
          name: role.name,
          defectStatuses: role.defectStatuses,
          pmStatuses: [...role.pmStatuses, sta],
        },
      });
    }
    setAnchorEl(null);
  };

  if (updateRoleIsLoading) {
    return <CircularProgress className="text-neutral-400" size="25px" />;
  }

  if (
    pmStatuses?.filter(
      (pm) => !role.pmStatuses?.map((p) => p.id).includes(pm.id)
    ).length === 0
  )
    return <></>;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button onClick={handleClick} variant="ghost">
          + Add
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {pmStatuses
          ?.filter((pm) => !role.pmStatuses?.map((p) => p.id).includes(pm.id))
          ?.map((sta) => (
            <DropdownMenuItem
              key={sta.id}
              onClick={() => {
                handleClose(sta);
              }}
            >
              {sta.name}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
      {/* <AppButton label="+ Add" onClick={handleClick} variant="ghost" />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> 

      </Menu> */}
    </DropdownMenu>
  );
}
