/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type BimStatus = (typeof BimStatus)[keyof typeof BimStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BimStatus = {
  BIM_NODE_NOT_READY: "BIM_NODE_NOT_READY",
  READY: "READY",
} as const;
