import { Delete, KeyboardArrowRight } from "@mui/icons-material";
import { ColumnDef } from "@tanstack/react-table";
import { ReactNode, useState } from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { cn } from "../../../lib/utils";
import AppButton from "../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import DialogContentWithSearch from "./components/DialogContentWithSearch";
import DialogContentWithSearchTable from "./components/DialogContentWithSearchTable";
import { Label } from "../../ui/label";

function getNestedProperty(obj: any, path: string) {
  const keys = path.split(".");
  let current = obj;

  for (let key of keys) {
    if (current[key] === undefined) {
      return undefined; // Or any default value you wish to return
    }
    current = current[key];
  }

  return current;
}

export type Base = {
  id?: number | string;
  name?: string;
  title?: string;
};

interface AppSelectWithDialogProps<
  T extends FieldValues = FieldValues,
  B extends Base = { id: undefined; name: ""; title: string }
> {
  label?: string;
  control: Control<T>;
  name: FieldPath<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  items: B[];
  onResultRender: (item: B, idx: number) => ReactNode;
  onOptionsRender: (item: B, idx: number) => ReactNode;
  placeholder: string;
  dialogTitle: string;
  error?: boolean;
  helperText?: string;
  columns?: ColumnDef<B, any>[];
  multiple?: boolean;
  showArrowRight?: boolean;
  errorClassName?: string;
  hide?: boolean;
  description?: string;
}

export default function AppSelectWithDialog<
  T extends FieldValues,
  B extends Base
>({
  control,
  name,
  items,
  defaultValue,
  onResultRender,
  onOptionsRender,
  label,
  placeholder,
  dialogTitle,
  error,
  helperText,
  columns,
  multiple,
  showArrowRight = true,
  errorClassName,
  hide = false, // Special boolean to hide rendered component, but controller need to be rendered
  description,
}: AppSelectWithDialogProps<T, B>) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        if (hide) return <></>;
        const isError = getNestedProperty(errors, name as string);
        return (
          <div className="flex flex-col gap-2">
            <Dialog open={open} onOpenChange={handleClose}>
              <DialogContent className="w-full max-w-4xl overflow-y-scroll max-h-screen md:h-fit h-full flex flex-col justify-start">
                <DialogHeader>
                  <DialogTitle className="text-2xl font-semibold my-1">
                    {dialogTitle ?? ""}
                  </DialogTitle>
                  <DialogDescription>{description}</DialogDescription>
                </DialogHeader>
                {columns ? (
                  <DialogContentWithSearchTable
                    items={items}
                    value={value}
                    columns={columns}
                    setRowSelection={onChange}
                    onSelected={(selected) => {
                      if (multiple) {
                        onChange(selected);
                      } else {
                        onChange(selected[0]);
                      }
                      handleClose();
                    }}
                    closeDialog={handleClose}
                    multiple={multiple}
                  />
                ) : (
                  <DialogContentWithSearch
                    items={items}
                    value={value}
                    onChange={onChange}
                    setOpen={setOpen}
                    onOptionsRender={onOptionsRender}
                  />
                )}
              </DialogContent>
            </Dialog>
            {label && <Label>{label}</Label>}
            {value?.length === 0 || !value ? (
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-1">
                  <div
                    // sx={error && { border: 1, borderColor: "#B61C1C" }}
                    className={cn(
                      "bg-slate-50 px-3 py-2 rounded-md  flex justify-between hover:cursor-pointer border",
                      {
                        "ring-2 ring-offset-1 ring-red-500": isError,
                      }
                    )}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <p className="text-neutral-600 font-sans">{placeholder}</p>
                    {showArrowRight && (
                      <KeyboardArrowRight className="text-neutral-600" />
                    )}
                  </div>
                </div>
                {/* Fix the problem here, escalation.workOrderStatus does not work in square bracket */}
                {isError && (
                  <p
                    className={cn(
                      "text-red-500 font-sans text-xs",
                      errorClassName
                    )}
                  >
                    {helperText}
                  </p>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <div className="bg-primary-900 rounded-md">
                  {Array.isArray(value) ? (
                    value?.map((v: Base, i: number) => {
                      return (
                        <div
                          key={(v?.id as number) ?? i}
                          className={cn(
                            "px-3 py-2 font-sans text-white font-medium hover:cursor-pointer flex w-full justify-between items-center",
                            {
                              "ring-2 ring-offset-1 ring-red-500":
                                isError,
                            }
                          )}
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          {onResultRender(
                            items.find((itm) => itm.id === v.id)!,
                            i
                          )}
                          <AppButton
                            label="Delete"
                            icon={<Delete />}
                            onClick={(e) => {
                              e.stopPropagation();
                              onChange(
                                value.filter((curr: Base) => curr.id !== v.id)
                              );
                            }}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex flex-col gap-1">
                      <div
                        className="bg-primary-900 rounded-md px-3 py-2 font-sans text-white font-medium flex items-center justify-between hover:cursor-pointer"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <>
                          {onResultRender(
                            items.find((i) => i.id === (value as Base).id)!,
                            0
                          )}
                        </>
                        {showArrowRight && (
                          <KeyboardArrowRight className="text-white" />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {isError && (
                  <p
                    className={cn(
                      "text-red-500 font-sans text-xs",
                      errorClassName
                    )}
                  >
                    {helperText}
                  </p>
                )}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}
