import { useState } from "react";
import {
  PtwControllerGetPtwsParams,
  PtwControllerGetPtwsPtwStatus,
} from "../../api/model";
import { DataTable } from "../../components/react-table/DataTable";
import {
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { usePtwControllerGetPtws } from "../../api/permit-to-work-adv/permit-to-work-adv";
import { useAppSelector } from "../../redux/store";
import { ptwColumns } from "./ptwColumns";
import { useFormContext, useWatch } from "react-hook-form";
import useDebounce from "../../hooks/useDebounce";

interface PermitToWorkTabProps {
  status: PtwControllerGetPtwsPtwStatus;
}

export default function PermitToWorkTab({ status }: PermitToWorkTabProps) {
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });

  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<PtwControllerGetPtwsParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      ptwStatus: status,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
    },
    delay: 500,
  });

  const {
    data: ptws,
    isFetching,
    isLoading,
  } = usePtwControllerGetPtws(queryArg, {
    query: {
      enabled: !!activeProj,
      select: (res) => res.data,
    },
  });

  const table = useReactTable({
    data: ptws?.data ?? [],
    columns: ptwColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: ptws?.count ? Math.ceil(ptws?.count / pagination.pageSize) : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <DataTable
      isFetching={isFetching}
      table={table}
      isLoading={isLoading}
      totalCount={ptws?.count ?? 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );
}
