import { useFormContext, useWatch } from "react-hook-form";
import {
  PtwControllerGetPtwsParams,
  PtwControllerGetPtwsPtwStatus,
} from "../../api/model";
import { usePtwControllerGetPtws } from "../../api/permit-to-work-adv/permit-to-work-adv";
import { TabsTrigger } from "../../components/ui/tabs";
import { useAppSelector } from "../../redux/store";
import useDebounce from "../../hooks/useDebounce";

interface PtwTabTriggerProps {
  status: PtwControllerGetPtwsPtwStatus;
  tabValue: string;
}

export default function PtwTabTrigger({
  status,
  tabValue,
}: PtwTabTriggerProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });

  const queryArg = useDebounce<PtwControllerGetPtwsParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      ptwStatus: status,
      search,
    },
    delay: 500,
  });

  const { data: ptws } = usePtwControllerGetPtws(queryArg, {
    query: {
      enabled: !!activeProj,
      select: (res) => res.data,
    },
  });

  const tabText = (status: PtwControllerGetPtwsPtwStatus) => {
    switch (status) {
      case PtwControllerGetPtwsPtwStatus.NEW:
        return "New";
      case PtwControllerGetPtwsPtwStatus.APPROVED:
        return "Approved";
      case PtwControllerGetPtwsPtwStatus.REJECTED:
        return "Rejected";
      default:
        return "Error";
    }
  };

  return (
    <TabsTrigger value={tabValue}>
      <p>{tabText(status) + " " + `(${ptws?.count?.toString()})`}</p>
    </TabsTrigger>
  );
}
