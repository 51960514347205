import {
  ColumnFiltersState,
  PaginationState,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";
import { useWorkscopeControllerGetWorkScopes } from "../../../api/workscope/workscope";
import { useAppSelector } from "../../../redux/store";
import { DataTable } from "../../../components/react-table/DataTable";
import { workscopeColumn } from "./workScopeColumns";
import { useState } from "react";
import { Input } from "../../../components/ui/input";
import AppButton from "../../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import { DRAWER_CREATE_STATE, WORK_SCOPE_FORM_DRAWER } from "../../../components/drawers/AppDrawer";

export default function WorkscopeTab() {
  const [, setSearchParams] = useSearchParams();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const { data: workscopes, isLoading, isFetching } = useWorkscopeControllerGetWorkScopes({
    companyId: activeComp?.id.toString() ?? "",
  });

  const table = useReactTable({
    data: workscopes?.data ?? [],
    columns: workscopeColumn,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnFilters,
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <Input
            placeholder="Search name"
            value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
            onChange={(event) =>
              table.getColumn("name")?.setFilterValue(event.target.value)
            }
            className="max-w-sm focus-visible:ring-primary-900"
          />
          <AppButton
            label="+ Add Workscope"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", WORK_SCOPE_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
        <DataTable
          table={table}
          totalCount={table.getTotalSize()}
          rowsPerPage={table.getState().pagination.pageSize}
          pageIdx={table.getState().pagination.pageIndex}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </div>
    </FormProvider>
  );
}
