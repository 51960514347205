import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  useManHourSettingControllerGetManHourSetting,
  useManHourSettingControllerUpsertManHourSetting,
} from "../../../api/man-hour-setting/man-hour-setting";
import AppButton from "../../AppButton";
import AppTextField from "../../AppTextField";
import { Separator } from "../../ui/separator";
import DayTimeRangeInput from "./components/DayTimeRangeInput";
import {
  manHourSettingDefaultValues,
  manHourSettingSchema,
  ManHourSettingSchemaType,
} from "./manHourSettingSchema";
import { useEffect } from "react";
import { useAppSelector } from "../../../redux/store";
import { parse } from "date-fns";

export default function ManHourSettingFormDrawer() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [, setSearchParams] = useSearchParams();
  const qc = useQueryClient();
  const methods = useForm<ManHourSettingSchemaType>({
    resolver: zodResolver(manHourSettingSchema),
    defaultValues: manHourSettingDefaultValues,
  });

  const { data: editManHourSetting } =
    useManHourSettingControllerGetManHourSetting(Number(activeProj?.id), {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    });

  useEffect(() => {
    if (editManHourSetting) {
      methods.reset({
        mon: {
          monNormStart: parse(
            editManHourSetting?.monNormStart?.toString() +
              ":" +
              editManHourSetting?.monNormStartMin?.toString(),
            "H:m",
            new Date()
          ),
          monNormEnd: parse(
            editManHourSetting?.monNormEnd?.toString() +
              ":" +
              editManHourSetting?.monNormEndMin?.toString(),
            "H:m",
            new Date()
          ),
        },
        tue: {
          tueNormStart: parse(
            editManHourSetting?.tueNormStart?.toString() +
              ":" +
              editManHourSetting?.tueNormStartMin?.toString(),
            "H:m",
            new Date()
          ),
          tueNormEnd: parse(
            editManHourSetting?.tueNormEnd?.toString() +
              ":" +
              editManHourSetting?.tueNormEndMin?.toString(),
            "H:m",
            new Date()
          ),
        },
        wed: {
          wedNormStart: parse(
            editManHourSetting?.wedNormStart?.toString() +
              ":" +
              editManHourSetting?.wedNormStartMin?.toString(),
            "H:m",
            new Date()
          ),
          wedNormEnd: parse(
            editManHourSetting?.wedNormEnd?.toString() +
              ":" +
              editManHourSetting?.wedNormEndMin?.toString(),
            "H:m",
            new Date()
          ),
        },
        thu: {
          thuNormStart: parse(
            editManHourSetting?.thuNormStart?.toString() +
              ":" +
              editManHourSetting?.thuNormStartMin?.toString(),
            "H:m",
            new Date()
          ),
          thuNormEnd: parse(
            editManHourSetting?.thuNormEnd?.toString() +
              ":" +
              editManHourSetting?.thuNormEndMin?.toString(),
            "H:m",
            new Date()
          ),
        },
        fri: {
          friNormStart: parse(
            editManHourSetting?.friNormStart?.toString() +
              ":" +
              editManHourSetting?.friNormStartMin?.toString(),
            "H:m",
            new Date()
          ),
          friNormEnd: parse(
            editManHourSetting?.friNormEnd?.toString() +
              ":" +
              editManHourSetting?.friNormEndMin?.toString(),
            "H:m",
            new Date()
          ),
        },
        sat: {
          satNormStart: parse(
            editManHourSetting?.satNormStart?.toString() +
              ":" +
              editManHourSetting?.satNormStartMin?.toString(),
            "H:m",
            new Date()
          ),
          satNormEnd: parse(
            editManHourSetting?.satNormEnd?.toString() +
              ":" +
              editManHourSetting?.satNormEndMin?.toString(),
            "H:m",
            new Date()
          ),
        },
        sun: {
          sunNormStart: parse(
            editManHourSetting?.sunNormStart?.toString() +
              ":" +
              editManHourSetting?.sunNormStartMin?.toString(),
            "H:m",
            new Date()
          ),
          sunNormEnd: parse(
            editManHourSetting?.sunNormEnd?.toString() +
              ":" +
              editManHourSetting?.sunNormEndMin?.toString(),
            "H:m",
            new Date()
          ),
        },
        normalRate: editManHourSetting.normalRate,
        overtimeRate: editManHourSetting.overtimeRate,
      });
    }
  }, [editManHourSetting]);

  const { mutate, isPending } = useManHourSettingControllerUpsertManHourSetting(
    {
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("man-hour-setting"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    }
  );

  const onSubmit: SubmitHandler<ManHourSettingSchemaType> = async (data) => {
    if (!activeProj) return;
    mutate({
      projectId: Number(activeProj?.id),
      data: {
        projectId: Number(activeProj?.id),
        monNormStart: data.mon.monNormStart.getHours(),
        monNormEnd: data.mon.monNormEnd.getHours(),
        monNormStartMin: data.mon.monNormStart.getMinutes(),
        monNormEndMin: data.mon.monNormEnd.getMinutes(),
        tueNormStart: data.tue.tueNormStart.getHours(),
        tueNormEnd: data.tue.tueNormEnd.getHours(),
        tueNormStartMin: data.tue.tueNormStart.getMinutes(),
        tueNormEndMin: data.tue.tueNormEnd.getMinutes(),
        wedNormStart: data.wed.wedNormStart.getHours(),
        wedNormEnd: data.wed.wedNormEnd.getHours(),
        wedNormStartMin: data.wed.wedNormStart.getMinutes(),
        wedNormEndMin: data.wed.wedNormEnd.getMinutes(),
        thuNormStart: data.thu.thuNormStart.getHours(),
        thuNormEnd: data.thu.thuNormEnd.getHours(),
        thuNormStartMin: data.thu.thuNormStart.getMinutes(),
        thuNormEndMin: data.thu.thuNormEnd.getMinutes(),
        friNormStart: data.fri.friNormStart.getHours(),
        friNormEnd: data.fri.friNormEnd.getHours(),
        friNormStartMin: data.fri.friNormStart.getMinutes(),
        friNormEndMin: data.fri.friNormEnd.getMinutes(),
        satNormStart: data.sat.satNormStart.getHours(),
        satNormEnd: data.sat.satNormEnd.getHours(),
        satNormStartMin: data.sat.satNormStart.getMinutes(),
        satNormEndMin: data.sat.satNormEnd.getMinutes(),
        sunNormStart: data.sun.sunNormStart.getHours(),
        sunNormEnd: data.sun.sunNormEnd.getHours(),
        sunNormStartMin: data.sun.sunNormStart.getMinutes(),
        sunNormEndMin: data.sun.sunNormEnd.getMinutes(),
        normalRate: data.normalRate,
        overtimeRate: data.overtimeRate,
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-bold">Update Man Hours</h3>
      <FormProvider {...methods}>
        <div className="flex flex-col gap-1">
          <h4 className="font-medium">Normal Working Hours</h4>
          <p className="text-slate-500 text-sm">
            Please specify the normal working hours
          </p>
        </div>
        <DayTimeRangeInput dayKey="mon" />
        <DayTimeRangeInput dayKey="tue" />
        <DayTimeRangeInput dayKey="wed" />
        <DayTimeRangeInput dayKey="thu" />
        <DayTimeRangeInput dayKey="fri" />
        <DayTimeRangeInput dayKey="sat" />
        <DayTimeRangeInput dayKey="sun" />
        <Separator className="my-6" />
        <div className="flex flex-col gap-1">
          <h4 className="font-medium">Hourly Rates</h4>
          <p className="text-slate-500 text-sm">
            Please specify the hourly rates for working hours, and overtime
            hours
          </p>
        </div>
        <AppTextField name="normalRate" label="Normal Rate" />
        <AppTextField name="overtimeRate" label="Overtime Rate" />
      </FormProvider>
      <div>
        <AppButton
          label="Confirm"
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={isPending}
        />
      </div>
    </div>
  );
}
