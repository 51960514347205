import { useGLTF } from "@react-three/drei";
import { GetBimModelResponseDtoData } from "../../../../../api/model";
import useBim from "../../../../../hooks/useBim/useBim";

interface BimNodeProps {
  bimModel: GetBimModelResponseDtoData | undefined;
}

export default function BimNode({ bimModel }: BimNodeProps) {
  const { scene } = useGLTF(bimModel?.bimAsset?.url ?? "");

  const { onPointerOver, onPointerLeave, onDoubleClick, onPointerDown } =
    useBim();

  return (
    <group
      onPointerOver={onPointerOver}
      onPointerLeave={onPointerLeave}
      onDoubleClick={onDoubleClick}
      onPointerDown={onPointerDown}
    >
      <primitive object={scene} />
    </group>
  );
}
