// This is a different storage hook that leverages react query mutation
import { useMutation } from "@tanstack/react-query";
import { storage } from "../firebase/firebaseConfig";

import { Attachment, AttachmentProjectType, Project } from "../api/model";
import { useAppSelector } from "../redux/store";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Compressor from 'compressorjs';

interface UseStorageProps {
  files: File[];
  // To only cater for scenarios where form has photo form, and also document form. to  make both array available for mutation
  secondFiles?: File[];
  mutateAsync: (
    atts: AttachmentProjectType[],
    secondAtts?: AttachmentProjectType[]
  ) => Promise<any>;
  mutateData?: Record<string, any>;
}

export default function useAppStorage() {
  //   const { defaultComp, defaultProj } = useUserConfig();
  const defaultComp = useAppSelector((state) => state.root.activeCompany);
  const defaultProj = useAppSelector((state) => state.root.activeProject);

  const uploadOnePublicFile = async ({ file }: { file: File }) => {
    const customTag = crypto.randomUUID();
    const storageRef = ref(
      storage,
      `cerev/publicFiles/images/${customTag}/${file.name}`
    );
    const uploadResult = await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(uploadResult.ref);
    const gsPath = uploadResult.ref.fullPath;
    // TODO: To check typing for attachment
    return {
      url: downloadUrl,
      gsPath,
      name: file.name,
    };
  };

  const uploadMultiplePublicFile = async ({ files }: { files: File[] }) => {
    const attachmentList: Promise<Attachment | undefined>[] = files.map((fl) =>
      uploadOnePublicFile({ file: fl })
    );

    return await Promise.all(attachmentList);
  };

  const uploadSingleFile = async ({
    file,
  }: {
    file: File;
  }): Promise<AttachmentProjectType | undefined> => {
    if (!defaultComp || !defaultProj) return;
    const customTag = crypto.randomUUID();
    const storageRef = ref(
      storage,
      `cerev/${defaultComp.name}/images/${customTag}/${file.name}`
    );

    // Check if file is image, if it is, compress it
    if (file.type.includes('image')) {
      const compressedFile = await new Promise<File>((resolve) => {
        new Compressor(file, {
          quality: 0.6,
          success: (result) => {
            resolve(result as File);
          },
          error: (err) => {
            console.log(err.message);
            resolve(file);
          },
        });
      });

      file = compressedFile;
    }

    const uploadResult = await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(uploadResult.ref);
    const gsPath = uploadResult.ref.fullPath;

    // TODO: To check typing for attachment
    return {
      url: downloadUrl,
      gsPath,
      name: file.name,
      underProject: { id: defaultProj.id } as Project,
      // underProjectId: defaultProj.id,
      fileSizeMb: (uploadResult.metadata.size / 1000000).toString(),
    } as AttachmentProjectType;
  };

  const uploadMultipleFile = async ({ files }: { files: File[] }) => {
    const attachmentList: Promise<AttachmentProjectType | undefined>[] =
      files.map((fl) => uploadSingleFile({ file: fl }));

    return await Promise.all(attachmentList);
  };

  const useUploadAttachmentSimple = () => {
    return useMutation({
      mutationFn: async ({ files }: { files: File[] }) => {
        const atts = await uploadMultipleFile({ files });
        if (!atts) return;
        return atts as AttachmentProjectType[];
      },
    });
  };

  const useUploadAttachmentMutation = ({
    onSuccessMutate,
  }: {
    onSuccessMutate?: () => void;
  }) =>
    //     {
    //     mutateAsync
    //   }: {
    //     mutateAsync: (atts: AttachmentProjectType[]) => Promise<any>;
    //   }

    {
      return useMutation({
        mutationFn: async ({
          files,
          secondFiles,
          mutateAsync,
        }: UseStorageProps) => {
          const atts = await uploadMultipleFile({ files });
          const secondAtts = await uploadMultipleFile({
            files: secondFiles ?? [],
          });
          if (!atts) return;
          await mutateAsync(
            atts as AttachmentProjectType[],
            secondAtts as AttachmentProjectType[]
          );
        },
        onSuccess: () => {
          if (onSuccessMutate) onSuccessMutate();
        },
      });
    };

  return {
    uploadSingleFile,
    uploadMultipleFile,
    useUploadAttachmentMutation,
    useUploadAttachmentSimple,
    uploadMultiplePublicFile,
  };
}
