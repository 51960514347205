/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CommentControllerGetCommentsParams,
  CommentControllerGetLogsParams,
  CreateCommentDto,
  GetCommentsResponseDto,
  GetLogsResponseDto,
} from ".././model";

export const commentControllerGetComments = (
  params: CommentControllerGetCommentsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetCommentsResponseDto[]>> => {
  return axios.get(`/comment`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getCommentControllerGetCommentsQueryKey = (
  params: CommentControllerGetCommentsParams
) => {
  return [`/comment`, ...(params ? [params] : [])] as const;
};

export const getCommentControllerGetCommentsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof commentControllerGetComments>>,
    CommentControllerGetCommentsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetCommentsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetComments>>,
        TError,
        TData,
        Awaited<ReturnType<typeof commentControllerGetComments>>,
        QueryKey,
        CommentControllerGetCommentsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCommentControllerGetCommentsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof commentControllerGetComments>>,
    QueryKey,
    CommentControllerGetCommentsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    commentControllerGetComments(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof commentControllerGetComments>>,
    TError,
    TData,
    Awaited<ReturnType<typeof commentControllerGetComments>>,
    QueryKey,
    CommentControllerGetCommentsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type CommentControllerGetCommentsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof commentControllerGetComments>>
>;
export type CommentControllerGetCommentsInfiniteQueryError =
  AxiosError<unknown>;

export const useCommentControllerGetCommentsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof commentControllerGetComments>>,
    CommentControllerGetCommentsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetCommentsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetComments>>,
        TError,
        TData,
        Awaited<ReturnType<typeof commentControllerGetComments>>,
        QueryKey,
        CommentControllerGetCommentsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCommentControllerGetCommentsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCommentControllerGetCommentsQueryOptions = <
  TData = Awaited<ReturnType<typeof commentControllerGetComments>>,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetCommentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetComments>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCommentControllerGetCommentsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof commentControllerGetComments>>
  > = ({ signal }) =>
    commentControllerGetComments(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof commentControllerGetComments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CommentControllerGetCommentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof commentControllerGetComments>>
>;
export type CommentControllerGetCommentsQueryError = AxiosError<unknown>;

export const useCommentControllerGetComments = <
  TData = Awaited<ReturnType<typeof commentControllerGetComments>>,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetCommentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetComments>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCommentControllerGetCommentsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const commentControllerCreateComment = (
  createCommentDto: CreateCommentDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/comment`, createCommentDto, options);
};

export const getCommentControllerCreateCommentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof commentControllerCreateComment>>,
    TError,
    { data: CreateCommentDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof commentControllerCreateComment>>,
  TError,
  { data: CreateCommentDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof commentControllerCreateComment>>,
    { data: CreateCommentDto }
  > = (props) => {
    const { data } = props ?? {};

    return commentControllerCreateComment(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommentControllerCreateCommentMutationResult = NonNullable<
  Awaited<ReturnType<typeof commentControllerCreateComment>>
>;
export type CommentControllerCreateCommentMutationBody = CreateCommentDto;
export type CommentControllerCreateCommentMutationError = AxiosError<unknown>;

export const useCommentControllerCreateComment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof commentControllerCreateComment>>,
    TError,
    { data: CreateCommentDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof commentControllerCreateComment>>,
  TError,
  { data: CreateCommentDto },
  TContext
> => {
  const mutationOptions =
    getCommentControllerCreateCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const commentControllerGetLogs = (
  params: CommentControllerGetLogsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetLogsResponseDto>> => {
  return axios.get(`/comment/logs`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getCommentControllerGetLogsQueryKey = (
  params: CommentControllerGetLogsParams
) => {
  return [`/comment/logs`, ...(params ? [params] : [])] as const;
};

export const getCommentControllerGetLogsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof commentControllerGetLogs>>,
    CommentControllerGetLogsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetLogsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetLogs>>,
        TError,
        TData,
        Awaited<ReturnType<typeof commentControllerGetLogs>>,
        QueryKey,
        CommentControllerGetLogsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCommentControllerGetLogsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof commentControllerGetLogs>>,
    QueryKey,
    CommentControllerGetLogsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    commentControllerGetLogs(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof commentControllerGetLogs>>,
    TError,
    TData,
    Awaited<ReturnType<typeof commentControllerGetLogs>>,
    QueryKey,
    CommentControllerGetLogsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type CommentControllerGetLogsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof commentControllerGetLogs>>
>;
export type CommentControllerGetLogsInfiniteQueryError = AxiosError<unknown>;

export const useCommentControllerGetLogsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof commentControllerGetLogs>>,
    CommentControllerGetLogsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetLogsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetLogs>>,
        TError,
        TData,
        Awaited<ReturnType<typeof commentControllerGetLogs>>,
        QueryKey,
        CommentControllerGetLogsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCommentControllerGetLogsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCommentControllerGetLogsQueryOptions = <
  TData = Awaited<ReturnType<typeof commentControllerGetLogs>>,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetLogsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetLogs>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCommentControllerGetLogsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof commentControllerGetLogs>>
  > = ({ signal }) =>
    commentControllerGetLogs(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof commentControllerGetLogs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CommentControllerGetLogsQueryResult = NonNullable<
  Awaited<ReturnType<typeof commentControllerGetLogs>>
>;
export type CommentControllerGetLogsQueryError = AxiosError<unknown>;

export const useCommentControllerGetLogs = <
  TData = Awaited<ReturnType<typeof commentControllerGetLogs>>,
  TError = AxiosError<unknown>
>(
  params: CommentControllerGetLogsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof commentControllerGetLogs>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCommentControllerGetLogsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
