import {
  FieldArrayWithId,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";
import {
  PtwFormatFormType,
  PtwTemplateFormType,
} from "../../ptwTemplateSchema";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu";
import { Button } from "../../../../ui/button";
import AppButton from "../../../../AppButton";
import {
  Hash,
  Text,
  File,
  ToggleLeft,
  List,
  Image,
  Calendar,
} from "lucide-react";
import { PmFormatTypeEnum, PtwFormatType } from "../../../../../api/model";

interface PtwFieldDropdownProps {
  fieldsArray: UseFieldArrayReturn<PtwTemplateFormType>;
}
export default function PtwFieldDropdown({
  fieldsArray,
}: PtwFieldDropdownProps) {
  const { append, fields } = fieldsArray;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="text-primary-900"> Add</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Field Types</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.TEXT,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <Text className="text-primary-900 mr-2" />
            <p>Text Input Field</p>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.NUMBER,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <Hash className=" text-primary-900 mr-2" />
            <p>Number Field</p>
          </DropdownMenuItem>
          {/* <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.ATTACHMENT,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <File className=" text-primary-900 mr-2" />
            <p>Document Field</p>
          </DropdownMenuItem> */}
          <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.BOOLEAN,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <ToggleLeft className=" text-primary-900 mr-2" />
            <p>Yes / No Field</p>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.DESCRIPTION,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <Text className=" text-primary-900 mr-2" />
            <p>Description Text</p>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.HEADING,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <Text className=" text-primary-900 mr-2" />
            <p>Heading Text</p>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.SINGLE_OPTION,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <List className=" text-primary-900 mr-2" />
            <p>Options</p>
          </DropdownMenuItem>
          {/* <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.IMAGE,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <Image className=" text-primary-900 mr-2" />
            <p>Images</p>
          </DropdownMenuItem> */}
          <DropdownMenuItem
            onClick={() => {
              append({
                ptwFormatType: PtwFormatType.DATE,
                name: "",
                order: (fields?.length ?? 0) + 1,
              });
            }}
          >
            <Calendar className=" text-primary-900 mr-2" />
            <p>Date</p>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
