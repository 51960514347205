/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type NotificationTypeEnum =
  (typeof NotificationTypeEnum)[keyof typeof NotificationTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationTypeEnum = {
  GENERAL: "GENERAL",
  WORK_ORDER: "WORK_ORDER",
  PM: "PM",
  WORK_REQUEST: "WORK_REQUEST",
  WORK_PERMIT: "WORK_PERMIT",
  LEGAL_COMPLIANCE: "LEGAL_COMPLIANCE",
} as const;
