import {DeleteWorkRequestDto} from "./dto/DeleteWorkRequestDto";
import {WorkRequest} from "./model/WorkRequest";
import {RootApi} from "./../RootApi";
import {AppResponse} from "../../model/AppResponse";

const WorkRequestApiWithTag = RootApi.enhanceEndpoints({
  addTagTypes: ["WorkRequest"],
});

export const WorkRequestApi = WorkRequestApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    createWorkRequest: builder.mutation<WorkRequest, FormData>({
      query: (data) => {
        return {
          url: "/work-request",
          method: "POST",
          body: data,
        };
      },
    }),
    getPublicWorkRequest: builder.query<WorkRequest, {workRequestCode: string}>(
      {
        query: ({workRequestCode}) => ({
          url: `/work-request/workRequestCode/${workRequestCode}`,
          method: "GET",
        }),
        providesTags: ["WorkRequest"]
      },
    
    ),
    cancelWorkRequest: builder.mutation<
      void,
      {workRequestCode: string; dto: DeleteWorkRequestDto}
    >({
      query: (data) => ({
        url: `/work-request/work-request-code/${data.workRequestCode}`,
        method: "DELETE",
        body: data.dto,
      }),
      invalidatesTags: ["WorkRequest"]
    }),
  }),
});

export const {
  useCreateWorkRequestMutation,
  useGetPublicWorkRequestQuery,
  useCancelWorkRequestMutation,
} = WorkRequestApi;
