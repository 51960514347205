import { ChevronLeft } from "lucide-react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { buttonVariants } from "../../components/ui/button";
import { cn } from "../../utils/util";
import { useEffect } from "react";

const tabLocations = [
  { to: "/home/super-admin/companies", label: "Companies" },
  { to: "/home/super-admin/projects", label: "Projects" },
  // {
  //   to: "/home/super-admin/users",
  //   label: "Users",
  // },
];

export default function SuperAdminScreen() {
  const nav = useNavigate();
  const { pathname } = useLocation();

  const isDetailScreen = pathname.split("/").length > 4;

  return (
    <div className="flex flex-col gap-4 p-10">
      <div className="flex space-x-10">
        {!isDetailScreen && (
          <nav className="flex flex-col space-y-1 min-w-[175px]">
            <div className="flex gap-6 items-center mb-6">
              <AppButton
                icon={<ChevronLeft />}
                className="text-slate-800 bg-slate-50 hover:bg-slate-200"
                label="Back to Home"
                onClick={() => {
                  nav("/home");
                }}
              />
            </div>
            {tabLocations.map((tabLink) => (
              <Link
                key={tabLink.to}
                to={tabLink.to}
                className={cn(
                  buttonVariants({ variant: "ghost" }),
                  "justify-start text-slate-400 font-normal",
                  pathname === tabLink.to
                    ? "bg-muted hover:bg-muted bg-primary-200 text-primary-900 font-bold hover:text-primary-900"
                    : "hover:bg-slate-200 hover:text-slate-800"
                )}
              >
                {tabLink.label}
              </Link>
            ))}
          </nav>
        )}
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
