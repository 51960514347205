import useCurrentUser from "../hooks/useCurrentUser/useCurrentUser";
import { setSelectedLocation } from "../redux/slices/Asset/AssetSlice";
import { useGetUserDataQuery } from "../redux/slices/Auth/AuthApi";
import { Project } from "../redux/slices/OpenApi/cerevApi";
import { setActiveProject } from "../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

export default function AppProjectSelect() {
  const dispatch = useAppDispatch();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: userData } = useCurrentUser();

  const changeProject = (proj: Project) => {
    // Clear location selection
    dispatch(setSelectedLocation(null));
    dispatch(setActiveProject(proj));
  };

  return (
    <Select
      onValueChange={(v) => {
        const idenProj = userData?.projects.find((p) => p.id === parseInt(v));
        if (idenProj) return changeProject(idenProj);
      }}
      value={activeProj?.id?.toString()}
    >
      <SelectTrigger className="w-[175px]" data-testid="project-dropdown">
        <SelectValue className="text-primary-900" />
      </SelectTrigger>
      <SelectContent>
        {userData?.projects
          .filter((p) => p.companyId === activeComp?.id)
          .map((proj) => (
            <SelectItem key={proj.id} value={proj.id?.toString() ?? ""}>
              {proj.name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
}
