import { useCSVDownloader, usePapaParse } from "react-papaparse";
import AppButton from "../../../../../components/AppButton";

export default function DownloadLocationCsvTemplate() {
  const { CSVDownloader } = useCSVDownloader();
  return (
    <CSVDownloader
      filename={"Cerev_Location_Import_Template"}
      bom={true}
      data={[
        {
          LocationId: "",
          Name: "",
          Code: "",
          LocationParentId: "",
        },
      ]}
    >
      <AppButton label="Download Location Template" variant="outline" />
    </CSVDownloader>
  );
}
