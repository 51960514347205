import {Attachment} from "./../../model/Attachment";
import {RootApi} from "./../RootApi";
import {getDownloadURL, ref, uploadBytes, UploadResult} from "firebase/storage";
import {storage} from "../../../firebase/firebaseConfig";
import {AttachmentProjectType} from "../OpenApi/cerevApi";
import {RootState} from "../../store";
import {v4 as uuidv4} from "uuid";

export const AttachmentApi = RootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadAttachment: builder.mutation<
      AttachmentProjectType[],
      File[]
    >({
      queryFn: async (
        args,
        {signal, dispatch, getState},
        extraOptions,
        baseQuery
      ) => {
        const filesUploaded = args;
        const activeProj = (getState() as RootState).root.activeProject;
        if (!activeProj)
          return {error: {status: 500, message: "No active project", data: []}};
        const activeComp = (getState() as RootState).root.activeCompany;
        if (!activeComp)
          return {error: {status: 500, message: "No active company", data: []}};
        // const filesToUpload = fileUrls.map((phoUrl) => fetch(phoUrl));
        // const filesDataList = await Promise.all(filesToUpload);
        // const fileBlobs = await Promise.all(filesDataList.map((fileData) => fileData.blob()));
        // const files = fileBlobs.map(blo => new File([blo], blo.name, {type: blo.type}));

        let uploadedAtt = [] as AttachmentProjectType[];

        for (var i = 0; i < filesUploaded.length; i++) {
          const oneFile = filesUploaded[i];
          const storageRef = ref(
            storage,
            `cerev/${activeComp.name}/images/${uuidv4()}/${oneFile.name}`
          );
          const uploadResult = await uploadBytes(storageRef, oneFile);
          const downloadUrl = await getDownloadURL(uploadResult.ref);
          const gsPath = uploadResult.ref.fullPath;
          uploadedAtt.push({
            url: downloadUrl,
            gsPath,
            name: oneFile.name,
            underProject: {id: activeProj.id},
          } as AttachmentProjectType);
        }

        return {
          data: uploadedAtt,
        };
      },
    }),
  }),
});

export const {useUploadAttachmentMutation} = AttachmentApi;
