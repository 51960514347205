import { create } from "zustand";

import { Location } from "../../api/model";
import { GetLocationResponseDto } from "../../redux/slices/OpenApi/cerevApi";
import { DateRange } from "react-day-picker";
import { endOfMonth, parse, startOfMonth } from "date-fns";

const getUrlSearch = () => {
  return window.location.search.slice(1);
};

interface WorkRequestFilterState {
  filterLocation: Location | GetLocationResponseDto | null | undefined;
  dateRange: DateRange | null | undefined;
  searchCount: number | null;
  actions: {
    setDateRange: (dateRange: DateRange | undefined) => void;
    setFilterLocation: (
      loc: Location | GetLocationResponseDto | null | undefined
    ) => void;
    cleanUp: () => void;
    getFilterCount: () => number;
    getFiltersFromSearch: () => void;
  };
}

// http://localhost:5173/home/work-requests?locid=5&locname=level5
// use this markdown link [Start here](<https://www.google.com/search?q={{month}}>)

export const useWorkRequestFilterStore = create<WorkRequestFilterState>(
  (set, get) => ({
    searchCount: null,
    filterLocation: null,
    dateRange: null,
    actions: {
      getFiltersFromSearch: () => {
        // This method is to cater for navigation from metabase, and filter is from URL,
        // subsequent filters will be handle within zustand state for now
        // Check URL first
        if (getUrlSearch()) {
          const searchParams = new URLSearchParams(getUrlSearch());
          // const storedValue = searchParams.get("dateRange");
          const filterLocationId = searchParams.get("locid");
          const filterLocationName = searchParams.get("locname");
          const dateFilter = searchParams.get("datefilter");
          const parseDate = parse(dateFilter || "", "MMM yyyy", new Date());
          if (!filterLocationId || !filterLocationName || !parseDate) return;
          const startDate = startOfMonth(parseDate);
          const endDate = endOfMonth(parseDate);
          return set(() => ({
            filterLocation: {
              id: Number(filterLocationId),
              name: filterLocationName,
            },
            dateRange: {
              from: startDate,
              to: endDate,
            },
          }));
        }
      },
      setFilterLocation: (loc) => set(() => ({ filterLocation: loc })),
      setDateRange: (dateRange) => set(() => ({ dateRange })),
      cleanUp: () => set(() => ({ filterLocation: null, dateRange: null })),
      getFilterCount: () => {
        let filterCount = 0;
        if (get().filterLocation) filterCount++;
        if (get().dateRange) filterCount++;
        return filterCount;
      },
    },
  })
);
