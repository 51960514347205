import { Box } from "@mui/system";
import {useEffect} from "react";
import {DefectStatus} from "../redux/slices/OpenApi/cerevApi";

interface AppWorkOrderStatusChipProp {
  workOrderStatus?: DefectStatus;
}

export default function AppWorkOrderStatusChip({
  workOrderStatus,
}: AppWorkOrderStatusChipProp) {

  return (
    <div className="flex">
      {" "}
      {/*  To use div flex parent make the chip to hug content. */}
      <div
        className={`text-white px-8 py-2 rounded-xl font-sans text-center font-bold flex items-center`}
        style={{
          backgroundColor: `#${workOrderStatus?.colorHex}`
        }}
      >
        {workOrderStatus?.name}
      </div>
    </div>
  );
}
