/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CheckOneUserPolicyResponseDto,
  GetPolicyResponse,
  PolicyControllerCheckOneUserPolicyParams,
  UpdateUserPolicyRequestDto,
} from ".././model";

export const policyControllerGetPolicy = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPolicyResponse>> => {
  return axios.get(`/policy`, options);
};

export const getPolicyControllerGetPolicyQueryKey = () => {
  return [`/policy`] as const;
};

export const getPolicyControllerGetPolicyInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof policyControllerGetPolicy>>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof policyControllerGetPolicy>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPolicyControllerGetPolicyQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof policyControllerGetPolicy>>
  > = ({ signal }) => policyControllerGetPolicy({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof policyControllerGetPolicy>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PolicyControllerGetPolicyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof policyControllerGetPolicy>>
>;
export type PolicyControllerGetPolicyInfiniteQueryError = AxiosError<unknown>;

export const usePolicyControllerGetPolicyInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof policyControllerGetPolicy>>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof policyControllerGetPolicy>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPolicyControllerGetPolicyInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPolicyControllerGetPolicyQueryOptions = <
  TData = Awaited<ReturnType<typeof policyControllerGetPolicy>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof policyControllerGetPolicy>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPolicyControllerGetPolicyQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof policyControllerGetPolicy>>
  > = ({ signal }) => policyControllerGetPolicy({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof policyControllerGetPolicy>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PolicyControllerGetPolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof policyControllerGetPolicy>>
>;
export type PolicyControllerGetPolicyQueryError = AxiosError<unknown>;

export const usePolicyControllerGetPolicy = <
  TData = Awaited<ReturnType<typeof policyControllerGetPolicy>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof policyControllerGetPolicy>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPolicyControllerGetPolicyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const policyControllerCheckOneUserPolicy = (
  userId: string,
  params: PolicyControllerCheckOneUserPolicyParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CheckOneUserPolicyResponseDto>> => {
  return axios.get(`/policy/user/${userId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPolicyControllerCheckOneUserPolicyQueryKey = (
  userId: string,
  params: PolicyControllerCheckOneUserPolicyParams
) => {
  return [`/policy/user/${userId}`, ...(params ? [params] : [])] as const;
};

export const getPolicyControllerCheckOneUserPolicyInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
    PolicyControllerCheckOneUserPolicyParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: PolicyControllerCheckOneUserPolicyParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
        TError,
        TData,
        Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
        QueryKey,
        PolicyControllerCheckOneUserPolicyParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPolicyControllerCheckOneUserPolicyQueryKey(userId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
    QueryKey,
    PolicyControllerCheckOneUserPolicyParams["cursorId"]
  > = ({ signal, pageParam }) =>
    policyControllerCheckOneUserPolicy(
      userId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
    TError,
    TData,
    Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
    QueryKey,
    PolicyControllerCheckOneUserPolicyParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PolicyControllerCheckOneUserPolicyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>
>;
export type PolicyControllerCheckOneUserPolicyInfiniteQueryError =
  AxiosError<unknown>;

export const usePolicyControllerCheckOneUserPolicyInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
    PolicyControllerCheckOneUserPolicyParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: PolicyControllerCheckOneUserPolicyParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
        TError,
        TData,
        Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
        QueryKey,
        PolicyControllerCheckOneUserPolicyParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPolicyControllerCheckOneUserPolicyInfiniteQueryOptions(
      userId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPolicyControllerCheckOneUserPolicyQueryOptions = <
  TData = Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: PolicyControllerCheckOneUserPolicyParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPolicyControllerCheckOneUserPolicyQueryKey(userId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>
  > = ({ signal }) =>
    policyControllerCheckOneUserPolicy(userId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PolicyControllerCheckOneUserPolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>
>;
export type PolicyControllerCheckOneUserPolicyQueryError = AxiosError<unknown>;

export const usePolicyControllerCheckOneUserPolicy = <
  TData = Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
  TError = AxiosError<unknown>
>(
  userId: string,
  params: PolicyControllerCheckOneUserPolicyParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof policyControllerCheckOneUserPolicy>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPolicyControllerCheckOneUserPolicyQueryOptions(
    userId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const policyControllerUpdateUserPolicy = (
  userId: string,
  updateUserPolicyRequestDto: UpdateUserPolicyRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/policy/user/${userId}`,
    updateUserPolicyRequestDto,
    options
  );
};

export const getPolicyControllerUpdateUserPolicyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof policyControllerUpdateUserPolicy>>,
    TError,
    { userId: string; data: UpdateUserPolicyRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof policyControllerUpdateUserPolicy>>,
  TError,
  { userId: string; data: UpdateUserPolicyRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof policyControllerUpdateUserPolicy>>,
    { userId: string; data: UpdateUserPolicyRequestDto }
  > = (props) => {
    const { userId, data } = props ?? {};

    return policyControllerUpdateUserPolicy(userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PolicyControllerUpdateUserPolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof policyControllerUpdateUserPolicy>>
>;
export type PolicyControllerUpdateUserPolicyMutationBody =
  UpdateUserPolicyRequestDto;
export type PolicyControllerUpdateUserPolicyMutationError = AxiosError<unknown>;

export const usePolicyControllerUpdateUserPolicy = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof policyControllerUpdateUserPolicy>>,
    TError,
    { userId: string; data: UpdateUserPolicyRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof policyControllerUpdateUserPolicy>>,
  TError,
  { userId: string; data: UpdateUserPolicyRequestDto },
  TContext
> => {
  const mutationOptions =
    getPolicyControllerUpdateUserPolicyMutationOptions(options);

  return useMutation(mutationOptions);
};
