import { ColumnDef } from "@tanstack/react-table";
import { CommentLog } from "../../../../../../api/model";
import useDate from "../../../../../../hooks/useDate";
import { Row } from "react-day-picker";
import React from "react";

export const workOrderLogsColumn: ColumnDef<CommentLog>[] = [
  {
    accessorKey: "id",
  },
  {
    id: "createdOn",
    accessorKey: "createdOn",
    header: "Timestamp",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <p>
          {formatDateToLocal({
            dateStr: row.getValue("createdOn"),
            formatting: "dd MMM yy, hh:mm a",
          })}
        </p>
      );
    },
  },
  {
    header: "User",
    accessorKey: "createdBy.name",
  },
  {
    accessorKey: "description",
    header: "Activity",
    cell: ({ row }) =>
      (row.getValue("description") as string)?.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {line}
          <br />
        </React.Fragment>
      )),
  },
];
