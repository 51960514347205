import { useSearchParams } from "react-router-dom";
import AppButton from "../../AppButton";
import { AppDateRangePicker } from "../../AppDateRangePicker";
import SelectLocationFilterDialog from "../../dialogs/SelectLocationFilterDialog/SelectLocationFilterDialog";
import { useAssetTypeControllerGetAssetType } from "../../../api/asset-type/asset-type";
import { useAppSelector } from "../../../redux/store";
import { useUserControllerGetUsers } from "../../../api/users/users";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import AppUncontrolledWithDialog from "../../dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { useWorkOrderScheduleFilterStore } from "../../../stores/workOrderSchedule/workOrderScheduleFilterStore";
import { selectAssetTypeColumn } from "../workOrders/columns/selectAssetTypeColumns";
import {
  useAssetControllerGetAssets,
  useAssetControllerGetFullAssets,
} from "../../../api/assets/assets";
import { assetColumns } from "../workOrders/columns/assetColumns";
import { AssetWithType } from "../../../redux/slices/OpenApi/cerevApi";

export default function WorkOrderSchedulerFilterDrawer() {
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const filterLocation = useWorkOrderScheduleFilterStore(
    (state) => state.filterLocation
  );
  const assetFilter = useWorkOrderScheduleFilterStore(
    (state) => state.filterAsset
  );
  const setFilterLocation = useWorkOrderScheduleFilterStore(
    (state) => state.setFilterLocation
  );
  const clearAll = useWorkOrderScheduleFilterStore((state) => state.cleanUp);
  const assetTypesFilter = useWorkOrderScheduleFilterStore(
    (state) => state.assetTypes
  );
  const setAssetTypes = useWorkOrderScheduleFilterStore(
    (state) => state.setAssetTypes
  );

  const setAsset = useWorkOrderScheduleFilterStore((state) => state.setAsset);

  const { data: assetTypes } = useAssetTypeControllerGetAssetType(
    {
      projectId: activeProj?.id?.toString() ?? "",
      companyId: activeComp?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj && !!activeComp,
      },
    }
  );

  const { data: fullAssetsList } = useAssetControllerGetFullAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj && !!activeComp,
      },
    }
  );

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-sans text-2xl font-bold">Filter By</h2>
      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Location</p>
        <SelectLocationFilterDialog
          currentFilter={filterLocation}
          onApplyFilter={(location) => {
            setFilterLocation(location);
          }}
        />
      </div>
      <AppUncontrolledWithDialog
        placeholder="Select Equipment"
        label="Equipment"
        items={fullAssetsList?.data ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Equipment Type"
        columns={assetColumns}
        value={assetFilter}
        onChange={(value) => {
          setAsset(value as AssetWithType);
        }}
      />
      <AppUncontrolledWithDialog
        placeholder="Select Equipment Type"
        label="Equipment Type"
        items={assetTypes?.data ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Equipment Type"
        columns={selectAssetTypeColumn}
        multiple
        value={assetTypesFilter}
        onChange={(value) => {
          if (Array.isArray(value)) setAssetTypes(value);
        }}
      />

      <div className="flex gap-2">
        <AppButton
          label="Confirm"
          onClick={() => {
            setSearchParams(() => {
              return new URLSearchParams();
            });
          }}
        />
        <AppButton
          label="Clear"
          variant="outline"
          onClick={() => {
            clearAll();
          }}
        />
      </div>
    </div>
  );
}
