import {
  CustomContentGenerator,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventSourceInput,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import React, { useEffect, useRef } from "react";
import "../../screens/WOSchedulerScreen/calendar.css";

import { useQueryClient } from "@tanstack/react-query";
import { useWorkOrderV2ControllerUpdatePlannedTime } from "../../api/work-order-defect-v2/work-order-defect-v2";
import { useCalendarRefActions } from "../../stores/calendarRefStore/calendarRefStore";
import AppProgress from "../progress/AppProgress";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import CalendarDateChanger from "./components/CalendarDateChanger";
import { useWorkOrderScheduleFilterStore } from "../../stores/workOrderSchedule/workOrderScheduleFilterStore";
import {
  addHours,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import AppButton from "../AppButton";
import { ChevronDown } from "lucide-react";

interface AppCalendarProps {
  events?: EventSourceInput;
  eventClick: (arg: EventClickArg) => void;
  filterNode?: React.ReactNode;
  isLoading?: boolean;
  eventContent: CustomContentGenerator<EventContentArg>;
  eventResize: ((arg: EventResizeDoneArg) => void) | undefined;
  eventDrop: ((arg: EventDropArg) => void) | undefined;
  eventReceive: ((arg: any) => void) | undefined;
}

export default function AppCalendar({
  events = [],
  eventClick,
  filterNode,
  isLoading,
  eventContent,
  eventResize,
  eventDrop,
  eventReceive,
}: AppCalendarProps) {
  const calendarRef = useRef<FullCalendar>(null);

  const { setCalendarRef, updateCalendarMode, setDateStart, setDateEnd } =
    useCalendarRefActions();

  useEffect(() => {
    if (calendarRef.current) setCalendarRef(calendarRef.current);
  }, [calendarRef]);

  useEffect(() => {
    if (events) {
      // need to tell calendar ref to updatesize manually... so that the overflow calendar will be properly rendered
      setTimeout(() => {
        calendarRef.current?.getApi().updateSize();
      }, 500);
    }
  }, [events]);

  const getViewText = () => {
    switch (calendarRef.current?.getApi().view.type) {
      case "dayGridMonth":
        return "Month";
      case "timeGridWeek":
        return "Week";
      case "multiMonthYear":
        return "Multi Month";
    }
  };

  // const [dateStart, setDateStart] = useState<Date>(startOfMonth(new Date()));
  // const [dateEnd, setDateEnd] = useState<Date>(endOfMonth(new Date()));

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 items-center justify-between">
        <div className="flex gap-2">
          {filterNode}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="bg-primary-100 text-primary-900 truncate flex group hover:bg-primary-900 border-primary-900 border">
                <p className="group-hover:text-white">{getViewText()}</p>
                <ChevronDown className="text-primary-900 group-hover:text-white" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  calendarRef.current?.getApi().changeView("dayGridMonth");
                  const currentDate = calendarRef?.current?.getApi().getDate();
                  updateCalendarMode("dayGridMonth");
                  setDateStart(startOfMonth(currentDate ?? new Date()));
                  setDateEnd(endOfMonth(currentDate ?? new Date()));
                }}
              >
                <p>Month</p>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  calendarRef.current?.getApi().changeView("timeGridWeek");
                  const currentDate = calendarRef?.current?.getApi().getDate();
                  updateCalendarMode("timeGridWeek");
                  setDateStart(startOfWeek(currentDate ?? new Date()));
                  setDateEnd(endOfWeek(currentDate ?? new Date()));
                }}
              >
                <p>Week</p>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  calendarRef.current?.getApi().changeView("multiMonthYear");
                  const currentDate = calendarRef?.current?.getApi().getDate();
                  // updateCalendarMode("timeGridWeek");
                  setDateStart(startOfYear(currentDate ?? new Date()));
                  setDateEnd(endOfYear(currentDate ?? new Date()));
                }}
              >
                <p>Multi Month</p>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <CalendarDateChanger />
      </div>
      <div className="relative">
        <div className="absolute top-0 left-0 right-0">
          <AppProgress isLoading={isLoading} />
        </div>
        <FullCalendar
          multiMonthMinWidth={400}
          showNonCurrentDates={false}
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            multiMonthPlugin,
          ]}
          initialView="dayGridMonth"
          droppable
          editable
          dayHeaderContent={(arg) => {
            const currentView = calendarRef.current?.getApi().view.type;
            switch (currentView) {
              case "dayGridMonth":
              case "multiMonthYear":
                return <div>{format(arg.date, "iii")}</div>;
              case "timeGridWeek":
                return <div>{format(arg.date, "iii, d/MM")}</div>;
            }
          }}
          // eventDisplay="block"
          eventBackgroundColor="transparent"
          eventBorderColor="transparent"
          // eventTextColor="transparent"
          eventContent={eventContent}
          eventClick={eventClick}
          displayEventTime={false}
          eventResize={eventResize}
          eventDrop={eventDrop}
          eventReceive={eventReceive}
          headerToolbar={false}
          events={events}
          dayMaxEvents={3}
        />
      </div>
    </div>
  );
}
