import { TreeItem } from "@mui/x-tree-view";
import {
  GetLocationResponseDto,
  Location,
  useAssetControllerGetAssetsQuery,
  useLocationControllerGetLocationQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import AppButton from "../../../components/AppButton";
import { IconButton, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  ASSET_DETAIL_DRAWER,
  ASSET_FORM_DRAWER,
  DRAWER_CREATE_STATE,
  DRAWER_UPDATE_STATE,
  DRAWER_VIEW_STATE,
  LOCATION_DETAIL_DRAWER,
  LOCATION_FORM_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Add, Details, Edit, List } from "@mui/icons-material";
import { setSelectedLocation } from "../../../redux/slices/Asset/AssetSlice";
import { useLocationControllerGetLocation } from "../../../api/location/location";
import {
  AssetLocationSorting,
  useAssetLocationSorting,
} from "../../../stores/assetLocationSortingStore/assetLocationSortingStore";

interface AssetTreeItemProps {
  loc: GetLocationResponseDto | Location;
}

export default function LocationTreeItem({ loc }: AssetTreeItemProps) {
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dispatch = useAppDispatch();

  const sortingMode = useAssetLocationSorting();
  const isSorted = sortingMode === AssetLocationSorting.ALPHABETICAL;

  const { data: locations } = useLocationControllerGetLocation(
    {
      projectId: activeProj?.id?.toString() ?? "",
      locationId: loc.id.toString(),
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) =>
          res.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)),
        staleTime: Infinity,
      },
    }
  );

  return (
    <TreeItem
      classes={{
        content: "rounded-md",
      }}
      nodeId={loc.id.toString()}
      onClick={() => {
        dispatch(setSelectedLocation(loc));
      }}
      label={
        <div className="flex justify-between items-center group">
          <p className="font-sans">{loc.name}</p>
          <IconButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setSearchParams((p) => {
                p.set("drawerType", LOCATION_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                p.set("parentLocId", loc.id.toString());
                return p;
              });
            }}
            className="opacity-0 p-1 group-hover:text-neutral-700 group-hover:opacity-100"
          >
            <Add />
          </IconButton>
        </div>
      }
    >
      {locations
        ?.sort(isSorted ? (a, b) => a.name.localeCompare(b.name) : () => 0)
        .map((child) => (
          <LocationTreeItem loc={child} key={child.id} />
        ))}
    </TreeItem>
  );
}
