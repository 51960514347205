import { useFormContext, useWatch } from "react-hook-form";
import { useAssetControllerGetAssetsV2 } from "../../../../api/assets/assets";
import {
  AssetControllerGetAssetsV2Params,
  AssetType,
} from "../../../../api/model";
import { TabsTrigger } from "../../../../components/ui/tabs";
import useDebounce from "../../../../hooks/useDebounce";
import { useAppSelector } from "../../../../redux/store";

interface EquipmentTabTriggerProps {
  equipmentType: AssetType;
  index: number;
}

export default function EquipmentTabTrigger({
  equipmentType,
  index,
}: EquipmentTabTriggerProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const method = useFormContext();
  const search = useWatch({ control: method.control, name: "search" });

  const queryArg = useDebounce<AssetControllerGetAssetsV2Params>({
    value: {
      assetTypeIds: [equipmentType.id?.toString() ?? "-"],
      projectId: activeProj?.id?.toString() ?? "-",
      search,
    },
    delay: 500,
  });

  const { data: totalCount } = useAssetControllerGetAssetsV2(
    queryArg,
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.totalCount,
      },
    }
  );

  return (
    <TabsTrigger value={index.toString()}>
      <p>
        {equipmentType.name} ({totalCount ?? "-"})
      </p>
    </TabsTrigger>
  );
}
