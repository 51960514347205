/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AssetType,
  AssetTypeControllerGetAssetTypeCountParams,
  AssetTypeControllerGetAssetTypeParams,
  CreateAssetTypeDto,
  UpdateAssetTypeDto,
} from ".././model";

export const assetTypeControllerGetAssetType = (
  params: AssetTypeControllerGetAssetTypeParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AssetType[]>> => {
  return axios.get(`/asset-type`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAssetTypeControllerGetAssetTypeQueryKey = (
  params: AssetTypeControllerGetAssetTypeParams
) => {
  return [`/asset-type`, ...(params ? [params] : [])] as const;
};

export const getAssetTypeControllerGetAssetTypeInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
    AssetTypeControllerGetAssetTypeParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
        QueryKey,
        AssetTypeControllerGetAssetTypeParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetTypeControllerGetAssetTypeQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
    QueryKey,
    AssetTypeControllerGetAssetTypeParams["cursorId"]
  > = ({ signal, pageParam }) =>
    assetTypeControllerGetAssetType(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
    TError,
    TData,
    Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
    QueryKey,
    AssetTypeControllerGetAssetTypeParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AssetTypeControllerGetAssetTypeInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>
>;
export type AssetTypeControllerGetAssetTypeInfiniteQueryError =
  AxiosError<unknown>;

export const useAssetTypeControllerGetAssetTypeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
    AssetTypeControllerGetAssetTypeParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
        QueryKey,
        AssetTypeControllerGetAssetTypeParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetTypeControllerGetAssetTypeInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetTypeControllerGetAssetTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetTypeControllerGetAssetTypeQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>
  > = ({ signal }) =>
    assetTypeControllerGetAssetType(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetTypeControllerGetAssetTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>
>;
export type AssetTypeControllerGetAssetTypeQueryError = AxiosError<unknown>;

export const useAssetTypeControllerGetAssetType = <
  TData = Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetTypeControllerGetAssetTypeQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetTypeControllerCreateAssetType = (
  createAssetTypeDto: CreateAssetTypeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/asset-type`, createAssetTypeDto, options);
};

export const getAssetTypeControllerCreateAssetTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetTypeControllerCreateAssetType>>,
    TError,
    { data: CreateAssetTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetTypeControllerCreateAssetType>>,
  TError,
  { data: CreateAssetTypeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetTypeControllerCreateAssetType>>,
    { data: CreateAssetTypeDto }
  > = (props) => {
    const { data } = props ?? {};

    return assetTypeControllerCreateAssetType(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetTypeControllerCreateAssetTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerCreateAssetType>>
>;
export type AssetTypeControllerCreateAssetTypeMutationBody = CreateAssetTypeDto;
export type AssetTypeControllerCreateAssetTypeMutationError =
  AxiosError<unknown>;

export const useAssetTypeControllerCreateAssetType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetTypeControllerCreateAssetType>>,
    TError,
    { data: CreateAssetTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetTypeControllerCreateAssetType>>,
  TError,
  { data: CreateAssetTypeDto },
  TContext
> => {
  const mutationOptions =
    getAssetTypeControllerCreateAssetTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetTypeControllerGetAssetTypeCount = (
  params: AssetTypeControllerGetAssetTypeCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<number>> => {
  return axios.get(`/asset-type/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAssetTypeControllerGetAssetTypeCountQueryKey = (
  params: AssetTypeControllerGetAssetTypeCountParams
) => {
  return [`/asset-type/count`, ...(params ? [params] : [])] as const;
};

export const getAssetTypeControllerGetAssetTypeCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
    AssetTypeControllerGetAssetTypeCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
        QueryKey,
        AssetTypeControllerGetAssetTypeCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetTypeControllerGetAssetTypeCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
    QueryKey,
    AssetTypeControllerGetAssetTypeCountParams["cursorId"]
  > = ({ signal, pageParam }) =>
    assetTypeControllerGetAssetTypeCount(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
    TError,
    TData,
    Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
    QueryKey,
    AssetTypeControllerGetAssetTypeCountParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AssetTypeControllerGetAssetTypeCountInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>>;
export type AssetTypeControllerGetAssetTypeCountInfiniteQueryError =
  AxiosError<unknown>;

export const useAssetTypeControllerGetAssetTypeCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
    AssetTypeControllerGetAssetTypeCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
        QueryKey,
        AssetTypeControllerGetAssetTypeCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAssetTypeControllerGetAssetTypeCountInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetTypeControllerGetAssetTypeCountQueryOptions = <
  TData = Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetTypeControllerGetAssetTypeCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>
  > = ({ signal }) =>
    assetTypeControllerGetAssetTypeCount(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetTypeControllerGetAssetTypeCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>
>;
export type AssetTypeControllerGetAssetTypeCountQueryError =
  AxiosError<unknown>;

export const useAssetTypeControllerGetAssetTypeCount = <
  TData = Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
  TError = AxiosError<unknown>
>(
  params: AssetTypeControllerGetAssetTypeCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetAssetTypeCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetTypeControllerGetAssetTypeCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetTypeControllerGetOneAssetType = (
  assetTypeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AssetType>> => {
  return axios.get(`/asset-type/${assetTypeId}`, options);
};

export const getAssetTypeControllerGetOneAssetTypeQueryKey = (
  assetTypeId: string
) => {
  return [`/asset-type/${assetTypeId}`] as const;
};

export const getAssetTypeControllerGetOneAssetTypeInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>
  >,
  TError = AxiosError<unknown>
>(
  assetTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetTypeControllerGetOneAssetTypeQueryKey(assetTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>
  > = ({ signal }) =>
    assetTypeControllerGetOneAssetType(assetTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!assetTypeId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetTypeControllerGetOneAssetTypeInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>
>;
export type AssetTypeControllerGetOneAssetTypeInfiniteQueryError =
  AxiosError<unknown>;

export const useAssetTypeControllerGetOneAssetTypeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>
  >,
  TError = AxiosError<unknown>
>(
  assetTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAssetTypeControllerGetOneAssetTypeInfiniteQueryOptions(
      assetTypeId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetTypeControllerGetOneAssetTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
  TError = AxiosError<unknown>
>(
  assetTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssetTypeControllerGetOneAssetTypeQueryKey(assetTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>
  > = ({ signal }) =>
    assetTypeControllerGetOneAssetType(assetTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!assetTypeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetTypeControllerGetOneAssetTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>
>;
export type AssetTypeControllerGetOneAssetTypeQueryError = AxiosError<unknown>;

export const useAssetTypeControllerGetOneAssetType = <
  TData = Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
  TError = AxiosError<unknown>
>(
  assetTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof assetTypeControllerGetOneAssetType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetTypeControllerGetOneAssetTypeQueryOptions(
    assetTypeId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetTypeControllerUpdateAssetType = (
  assetTypeId: string,
  updateAssetTypeDto: UpdateAssetTypeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/asset-type/${assetTypeId}`, updateAssetTypeDto, options);
};

export const getAssetTypeControllerUpdateAssetTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetTypeControllerUpdateAssetType>>,
    TError,
    { assetTypeId: string; data: UpdateAssetTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetTypeControllerUpdateAssetType>>,
  TError,
  { assetTypeId: string; data: UpdateAssetTypeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetTypeControllerUpdateAssetType>>,
    { assetTypeId: string; data: UpdateAssetTypeDto }
  > = (props) => {
    const { assetTypeId, data } = props ?? {};

    return assetTypeControllerUpdateAssetType(assetTypeId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetTypeControllerUpdateAssetTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerUpdateAssetType>>
>;
export type AssetTypeControllerUpdateAssetTypeMutationBody = UpdateAssetTypeDto;
export type AssetTypeControllerUpdateAssetTypeMutationError =
  AxiosError<unknown>;

export const useAssetTypeControllerUpdateAssetType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetTypeControllerUpdateAssetType>>,
    TError,
    { assetTypeId: string; data: UpdateAssetTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetTypeControllerUpdateAssetType>>,
  TError,
  { assetTypeId: string; data: UpdateAssetTypeDto },
  TContext
> => {
  const mutationOptions =
    getAssetTypeControllerUpdateAssetTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const assetTypeControllerDeleteAssetType = (
  assetTypeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/asset-type/${assetTypeId}`, options);
};

export const getAssetTypeControllerDeleteAssetTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetTypeControllerDeleteAssetType>>,
    TError,
    { assetTypeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetTypeControllerDeleteAssetType>>,
  TError,
  { assetTypeId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetTypeControllerDeleteAssetType>>,
    { assetTypeId: string }
  > = (props) => {
    const { assetTypeId } = props ?? {};

    return assetTypeControllerDeleteAssetType(assetTypeId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetTypeControllerDeleteAssetTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetTypeControllerDeleteAssetType>>
>;

export type AssetTypeControllerDeleteAssetTypeMutationError =
  AxiosError<unknown>;

export const useAssetTypeControllerDeleteAssetType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetTypeControllerDeleteAssetType>>,
    TError,
    { assetTypeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetTypeControllerDeleteAssetType>>,
  TError,
  { assetTypeId: string },
  TContext
> => {
  const mutationOptions =
    getAssetTypeControllerDeleteAssetTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
