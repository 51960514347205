import { useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../redux/store";
import {
  useWorkRequestSettingControllerGetOneWorkRequestSetting,
  useWorkRequestSettingControllerUpdateWorkRequestSetting,
} from "../../../../../api/work-request-setting/work-request-setting";
import { Loader2 } from "lucide-react";
import { cn } from "../../../../../utils/util";
import { WorkRequestSettingWorkRequestShowLocationMode } from "../../../../../api/model";
import { Switch } from "../../../../../components/ui/switch";

export default function ShowLocationModeSwitch() {
  const qc = useQueryClient();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: workReqSettings, isLoading } =
    useWorkRequestSettingControllerGetOneWorkRequestSetting(
      activeProj?.id?.toString() ?? "",
      {
        query: {
          enabled: !!activeProj,
          select: (res) => res.data.data,
        },
      }
    );

  const { mutate, isPending } =
    useWorkRequestSettingControllerUpdateWorkRequestSetting({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("work-request-setting"),
          });
        },
      },
    });

  return (
    <div className="flex items-center gap-4">
      <Switch
        className="data-[state=checked]:bg-primary-900"
        checked={
          workReqSettings?.showLocationMode ===
          WorkRequestSettingWorkRequestShowLocationMode.MAIN_LOCATION_ONLY
        }
        onCheckedChange={(e) => {
          if (!workReqSettings) return;
          mutate({
            projectId: activeProj?.id?.toString() ?? "",
            data: {
              ...workReqSettings,
              showLocationMode: e
                ? WorkRequestSettingWorkRequestShowLocationMode.MAIN_LOCATION_ONLY
                : WorkRequestSettingWorkRequestShowLocationMode.ALL,
            },
          });
        }}
      />
      {isPending && (
        <Loader2 className={cn("h-6 w-6 animate-spin text-primary-900")} />
      )}
    </div>
  );
}
