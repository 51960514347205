import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  type ChartConfig,
} from "@/components/ui/chart";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from "recharts";
import { useEscalationControllerGetEscalationHistoryLogs, useEscalationControllerGetOneEscalation } from "../../../../api/escalation/escalation";
import { useAppSelector } from "../../../../redux/store";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { EscalationLog } from "../../../../api/model";
import EscalationLogDialog from "../components/dialogs/EscalationLogDialog/EscalationLogDialog";

const chartConfig = {
  date: {
    label: "Date",
    color: "#1E99B4",
  },
} satisfies ChartConfig;

export default function EscalationLogTab() {
  const [selected, setSelected] = useState("7 days");
  const [open, setOpen] = useState(false);
  const [escalationLogs, setEscalationLogs] = useState([] as EscalationLog[]);
  const [searchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const escalationId = searchParams.get("escalationId");

  const { data: escalation } =
  useEscalationControllerGetOneEscalation(
    escalationId ?? "-",
    {
      projectId: activeProj?.id ?? 0,
    },
    {
      query: {
        enabled: !!escalationId && !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  const { data, isLoading } = useEscalationControllerGetEscalationHistoryLogs(
    escalationId ?? "",
    {
      query: {
        enabled: !!escalationId,
        select: (res) =>
          res.data.map((i) => ({
            ...i,
            count: i.escalationLogs.length,
          })),
      },
    }
  );

  return (
    <div>
      <EscalationLogDialog
        open={open}
        setOpen={setOpen}
        escalationLogs={escalationLogs}
        escalationType={escalation?.escalationType}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold my-3 ">History of escalation</h2>
        {/* TODO: To add in filter dropdown in the future */}
        {/* <Select onValueChange={setSelected} value={selected}>
          <SelectTrigger className="max-w-[100px]">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="7 days">7 days</SelectItem>
            <SelectItem value="30 days">14 days</SelectItem>
            <SelectItem value="90 days">30 days</SelectItem>
          </SelectContent>
        </Select> */}
      </div>
      <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
        <BarChart accessibilityLayer data={data}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => format(parseISO(value), "MMM d")}
          />
          <YAxis
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            interval={1}
          />
          <ChartTooltip
            content={
              <ChartTooltipContent
                labelFormatter={(value) =>
                  value ? format(parseISO(value), "MMM d") : ""
                }
              />
            }
          />
          <Bar
            dataKey="count"
            fill="var(--color-date)"
            radius={4}
            onClick={(data) => {
              setEscalationLogs(data.escalationLogs);
              setOpen(true);
            }}
            className="hover:cursor-pointer"
          />
        </BarChart>
      </ChartContainer>
    </div>
  );
}
