import { FormProvider, useForm } from "react-hook-form";
import { usePmChecklistStatusControllerGetAllPmChecklistStatus } from "../../../api/preventive-maintenance-checklist-status/preventive-maintenance-checklist-status";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import { useAppSelector } from "../../../redux/store";
import { useSelectPmFilterStore } from "../../../stores/pm/pmFilterStore";
import PmTabWithCount from "./components/PmTabWithCount";
import PmTableTab from "./tabs/PmTableTab";
import AppSearchAndFilter from "../../../components/searchAndFilter/AppSearchAndFilter";
import { useSearchParams } from "react-router-dom";
import { PM_FILTER_DRAWER } from "../../../components/drawers/AppDrawer";

export default function PmTableScreen() {
  const [, setSearchParams] = useSearchParams();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "pmTableScreenTabIdx",
    defaultValue: "0",
  });

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const filterCount = useSelectPmFilterStore((state) => state.getFilterCount);

  // const { currentTabIdx, setCurrentTabIdx } = useAppTabsNavigation({
  //   searchParamStr: "pmTableScreenTabIdx",
  // });
  const { data: pmStatuses } =
    usePmChecklistStatusControllerGetAllPmChecklistStatus(
      {
        companyId: activeComp?.id ?? 0,
      },
      {
        query: {
          enabled: !!activeComp,
        },
      }
    );

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <AppSearchAndFilter
          onFilterClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", PM_FILTER_DRAWER);
              return p;
            });
          }}
          placeholder="Ref No, Subject, Asset..."
          filterCount={filterCount()}
        />
        <Tabs value={currentTabIdx} onValueChange={onTabChange} className="mb-4">
          <TabsList className="mb-2">
            {pmStatuses?.data?.map((pmStatus, idx) => (
              <TabsTrigger key={pmStatus.id} value={idx.toString()}>
                <PmTabWithCount pmStatus={pmStatus} />
              </TabsTrigger>
            ))}
          </TabsList>

          {pmStatuses?.data?.map((pmStat, idx) => (
            <TabsContent key={pmStat.id} value={idx.toString()}>
              <PmTableTab pmStatus={pmStat} />
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </FormProvider>
  );
}
