/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateWorkScopeDto,
  UpdateWorkscopeDto,
  WorkScope,
  WorkscopeControllerGetWorkScopesParams,
} from ".././model";

export const workscopeControllerGetWorkScopes = (
  params: WorkscopeControllerGetWorkScopesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkScope[]>> => {
  return axios.get(`/workscope`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkscopeControllerGetWorkScopesQueryKey = (
  params: WorkscopeControllerGetWorkScopesParams
) => {
  return [`/workscope`, ...(params ? [params] : [])] as const;
};

export const getWorkscopeControllerGetWorkScopesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
    WorkscopeControllerGetWorkScopesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkscopeControllerGetWorkScopesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
        QueryKey,
        WorkscopeControllerGetWorkScopesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkscopeControllerGetWorkScopesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
    QueryKey,
    WorkscopeControllerGetWorkScopesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    workscopeControllerGetWorkScopes(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
    TError,
    TData,
    Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
    QueryKey,
    WorkscopeControllerGetWorkScopesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type WorkscopeControllerGetWorkScopesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>
>;
export type WorkscopeControllerGetWorkScopesInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkscopeControllerGetWorkScopesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
    WorkscopeControllerGetWorkScopesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkscopeControllerGetWorkScopesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
        QueryKey,
        WorkscopeControllerGetWorkScopesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkscopeControllerGetWorkScopesInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkscopeControllerGetWorkScopesQueryOptions = <
  TData = Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
  TError = AxiosError<unknown>
>(
  params: WorkscopeControllerGetWorkScopesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkscopeControllerGetWorkScopesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>
  > = ({ signal }) =>
    workscopeControllerGetWorkScopes(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkscopeControllerGetWorkScopesQueryResult = NonNullable<
  Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>
>;
export type WorkscopeControllerGetWorkScopesQueryError = AxiosError<unknown>;

export const useWorkscopeControllerGetWorkScopes = <
  TData = Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
  TError = AxiosError<unknown>
>(
  params: WorkscopeControllerGetWorkScopesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetWorkScopes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkscopeControllerGetWorkScopesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workscopeControllerCreateWorkScope = (
  createWorkScopeDto: CreateWorkScopeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/workscope`, createWorkScopeDto, options);
};

export const getWorkscopeControllerCreateWorkScopeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workscopeControllerCreateWorkScope>>,
    TError,
    { data: CreateWorkScopeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workscopeControllerCreateWorkScope>>,
  TError,
  { data: CreateWorkScopeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workscopeControllerCreateWorkScope>>,
    { data: CreateWorkScopeDto }
  > = (props) => {
    const { data } = props ?? {};

    return workscopeControllerCreateWorkScope(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkscopeControllerCreateWorkScopeMutationResult = NonNullable<
  Awaited<ReturnType<typeof workscopeControllerCreateWorkScope>>
>;
export type WorkscopeControllerCreateWorkScopeMutationBody = CreateWorkScopeDto;
export type WorkscopeControllerCreateWorkScopeMutationError =
  AxiosError<unknown>;

export const useWorkscopeControllerCreateWorkScope = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workscopeControllerCreateWorkScope>>,
    TError,
    { data: CreateWorkScopeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workscopeControllerCreateWorkScope>>,
  TError,
  { data: CreateWorkScopeDto },
  TContext
> => {
  const mutationOptions =
    getWorkscopeControllerCreateWorkScopeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workscopeControllerUpdateWorkScope = (
  workscopeId: string,
  updateWorkscopeDto: UpdateWorkscopeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/workscope/${workscopeId}`, updateWorkscopeDto, options);
};

export const getWorkscopeControllerUpdateWorkScopeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workscopeControllerUpdateWorkScope>>,
    TError,
    { workscopeId: string; data: UpdateWorkscopeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workscopeControllerUpdateWorkScope>>,
  TError,
  { workscopeId: string; data: UpdateWorkscopeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workscopeControllerUpdateWorkScope>>,
    { workscopeId: string; data: UpdateWorkscopeDto }
  > = (props) => {
    const { workscopeId, data } = props ?? {};

    return workscopeControllerUpdateWorkScope(workscopeId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkscopeControllerUpdateWorkScopeMutationResult = NonNullable<
  Awaited<ReturnType<typeof workscopeControllerUpdateWorkScope>>
>;
export type WorkscopeControllerUpdateWorkScopeMutationBody = UpdateWorkscopeDto;
export type WorkscopeControllerUpdateWorkScopeMutationError =
  AxiosError<unknown>;

export const useWorkscopeControllerUpdateWorkScope = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workscopeControllerUpdateWorkScope>>,
    TError,
    { workscopeId: string; data: UpdateWorkscopeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workscopeControllerUpdateWorkScope>>,
  TError,
  { workscopeId: string; data: UpdateWorkscopeDto },
  TContext
> => {
  const mutationOptions =
    getWorkscopeControllerUpdateWorkScopeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workscopeControllerDeleteWorkScope = (
  workscopeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/workscope/${workscopeId}`, options);
};

export const getWorkscopeControllerDeleteWorkScopeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workscopeControllerDeleteWorkScope>>,
    TError,
    { workscopeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workscopeControllerDeleteWorkScope>>,
  TError,
  { workscopeId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workscopeControllerDeleteWorkScope>>,
    { workscopeId: string }
  > = (props) => {
    const { workscopeId } = props ?? {};

    return workscopeControllerDeleteWorkScope(workscopeId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkscopeControllerDeleteWorkScopeMutationResult = NonNullable<
  Awaited<ReturnType<typeof workscopeControllerDeleteWorkScope>>
>;

export type WorkscopeControllerDeleteWorkScopeMutationError =
  AxiosError<unknown>;

export const useWorkscopeControllerDeleteWorkScope = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workscopeControllerDeleteWorkScope>>,
    TError,
    { workscopeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workscopeControllerDeleteWorkScope>>,
  TError,
  { workscopeId: string },
  TContext
> => {
  const mutationOptions =
    getWorkscopeControllerDeleteWorkScopeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workscopeControllerGetOneWorkscope = (
  workscopeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkScope>> => {
  return axios.get(`/workscope/${workscopeId}`, options);
};

export const getWorkscopeControllerGetOneWorkscopeQueryKey = (
  workscopeId: string
) => {
  return [`/workscope/${workscopeId}`] as const;
};

export const getWorkscopeControllerGetOneWorkscopeInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>
  >,
  TError = AxiosError<unknown>
>(
  workscopeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkscopeControllerGetOneWorkscopeQueryKey(workscopeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>
  > = ({ signal }) =>
    workscopeControllerGetOneWorkscope(workscopeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workscopeId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkscopeControllerGetOneWorkscopeInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>
>;
export type WorkscopeControllerGetOneWorkscopeInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkscopeControllerGetOneWorkscopeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>
  >,
  TError = AxiosError<unknown>
>(
  workscopeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkscopeControllerGetOneWorkscopeInfiniteQueryOptions(
      workscopeId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkscopeControllerGetOneWorkscopeQueryOptions = <
  TData = Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
  TError = AxiosError<unknown>
>(
  workscopeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkscopeControllerGetOneWorkscopeQueryKey(workscopeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>
  > = ({ signal }) =>
    workscopeControllerGetOneWorkscope(workscopeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workscopeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkscopeControllerGetOneWorkscopeQueryResult = NonNullable<
  Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>
>;
export type WorkscopeControllerGetOneWorkscopeQueryError = AxiosError<unknown>;

export const useWorkscopeControllerGetOneWorkscope = <
  TData = Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
  TError = AxiosError<unknown>
>(
  workscopeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workscopeControllerGetOneWorkscope>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkscopeControllerGetOneWorkscopeQueryOptions(
    workscopeId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
