import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import useCurrentUser from "../hooks/useCurrentUser/useCurrentUser";

export default function FirstTimeLoginRouter({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const user = useCurrentUser();

  if (user?.data?.firstTimeLogin === true) {
    return (
      <Navigate to="/first-time-login" state={{ from: location.pathname }} />
    );
  }

  return <>{children}</>;
}
