import AppButton from "../../../../../AppButton";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import AppTextField from "../../../../../AppTextField";
import { useWorkOrderV2ControllerUpdateWorkOrderPricingRefNoMutation } from "../../../../../../redux/slices/WorkOrder/WorkOrderApi";
import useError from "../../../../../../hooks/useError";
import useIsCloseWorkOrder from "../../../../../../hooks/useIsCloseWorkOrder";
import { Pencil } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../../ui/dialog";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useWorkOrderV2ControllerRemovePricingAttachment,
  useWorkOrderV2ControllerUpdateWorkOrderPricingRefNo,
} from "../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import { useQueryClient } from "@tanstack/react-query";
import IsCloseAndEditAfterWorkOrderGuard from "../../../components/IsCloseAndEditAfterWorkOrderGuard";
import {
  Attachment,
  GetOneWorkOrderResponseDto,
} from "../../../../../../api/model";
import AppDocumentUpload from "../../../../../AppDocumentUpload";
import useAppStorage from "../../../../../../hooks/useAppStorage";
import AppTextAreaField from "../../../../../AppTextAreaField";

const UpdatePricingBtnDialogSchema = z.object({
  costing: z.coerce.number(),
  costingRefNo: z.string(),
  labourCosting: z.coerce.number(),
  partCosting: z.coerce.number(),
  milleageCosting: z.coerce.number(),
  costingRemarks: z.string().optional(),
  pricingAttachments: z.object({
    uploaded: z
      .object({
        id: z.number(),
        url: z.string(),
        attachmentType: z.enum(["PRIVATE", "DEFAULT"]),
      })
      .array()
      .optional(),
    attachments: z.instanceof(File).array().optional(),
  }),
});

type UpdatePricingBtnDialogForm = z.infer<typeof UpdatePricingBtnDialogSchema>;

interface UpdatePricingBtnDialogProps {
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function UpdatePricingBtnDialog({
  workOrder,
}: UpdatePricingBtnDialogProps) {
  const qc = useQueryClient();
  const { useUploadAttachmentMutation } = useAppStorage();
  const isClose = useIsCloseWorkOrder({ workOrder });
  const methods = useForm<UpdatePricingBtnDialogForm>({
    resolver: zodResolver(UpdatePricingBtnDialogSchema),
    defaultValues: {
      costing: 0,
      costingRefNo: "",
      costingRemarks: "",
    },
  });
  const [open, setOpen] = useState(false);
  const handleClose = async () => {
    setOpen(false);
  };

  const { mutateAsync: updatePricing } =
    useWorkOrderV2ControllerUpdateWorkOrderPricingRefNo();

  const { mutate: deleteAttachment } =
    useWorkOrderV2ControllerRemovePricingAttachment({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("work-order") ||
              (q.queryKey[0] as string).includes("defect"),
          });
        },
      },
    });

  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (q) =>
          (q.queryKey[0] as string).includes("work-order") ||
          (q.queryKey[0] as string).includes("defect"),
      });
      handleClose();
    },
  });

  const onDeleteUploadedAttachment = async (att: Attachment) => {
    if (!att.id || !workOrder?.id) return;
    deleteAttachment({
      attachmentId: att.id.toString() ?? "",
      workOrderId: workOrder?.id?.toString() ?? "",
    });
    methods.setValue("pricingAttachments", {
      uploaded: methods
        .getValues()
        .pricingAttachments.uploaded?.filter((v) => v.id !== att.id),
    });
  };

  const onSubmit: SubmitHandler<UpdatePricingBtnDialogForm> = (data) => {
    if (!workOrder) return;
    mutate({
      files: data.pricingAttachments.attachments ?? [],
      mutateAsync: async (atts) => {
        await updatePricing({
          workOrderId: workOrder?.id ?? 0,
          data: {
            costing: data.costing,
            costingRefNo: data.costingRefNo,
            labourCosting: data.labourCosting,
            partCosting: data.partCosting,
            milleageCosting: data.milleageCosting,
            pricingAttachment: atts,
            costingRemarks: data.costingRemarks,
          },
        });
      },
    });
  };

  // Prefill forms
  useEffect(() => {
    if (workOrder) {
      methods.reset({
        costing: workOrder?.costing ?? 0,
        costingRefNo: workOrder?.costingRefNo ?? "",
        labourCosting: workOrder?.labourCosting ?? 0,
        partCosting: workOrder?.partCosting ?? 0,
        milleageCosting: workOrder?.milleageCosting ?? 0,
        pricingAttachments: {
          uploaded: workOrder?.pricingAttachment ?? [],
        },
        costingRemarks: workOrder?.costingRemarks ?? "",
      });
    }
  }, [workOrder]);

  return (
    <>
      <IsCloseAndEditAfterWorkOrderGuard>
        <AppButton
          label="Update"
          icon={<Pencil />}
          onClick={() => {
            setOpen(true);
          }}
          data-testid="update-pricing-btn"
        />
      </IsCloseAndEditAfterWorkOrderGuard>
      <Dialog
        open={open}
        onOpenChange={(v) => {
          handleClose();
        }}
      >
        <DialogContent className="overflow-y-scroll max-h-screen">
          <DialogTitle className="text-2xl font-bold">
            Update Pricing
          </DialogTitle>
          <FormProvider {...methods}>
            <div className="flex flex-col gap-4">
              <AppTextField
                label="Revenue of this work order (RM)"
                name="costing"
                type="number"
              />
              <AppTextField label="Document Ref. No" name="costingRefNo" />
              <AppTextField
                label="Labour Costing (RM)"
                name="labourCosting"
                type="number"
              />
              <AppTextField
                label="Part Costing (RM)"
                name="partCosting"
                type="number"
              />
              <AppTextField
                label="Milleage Costing (RM)"
                name="milleageCosting"
                type="number"
              />
              <AppTextAreaField
                label="Additional Remarks"
                name="costingRemarks"
              />
              <Controller
                control={methods.control}
                name="pricingAttachments"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <AppDocumentUpload
                      uploadedDocs={value?.uploaded ?? []}
                      onDeleteUploadedDoc={onDeleteUploadedAttachment}
                      label="Documents"
                      onChange={(files) => {
                        onChange({
                          ...value,
                          attachments: files,
                        });
                      }}
                      onDelete={(url) => {
                        if (!value) return;
                        const newFiles = value.attachments?.filter(
                          (v) => v !== url
                        );
                        onChange({
                          ...value,
                          attachments: newFiles,
                        });
                      }}
                      files={value?.attachments ?? []}
                      error={!!error}
                      helperText={
                        methods.formState.errors.pricingAttachments?.attachments
                          ?.message !== ""
                          ? methods.formState.errors.pricingAttachments
                              ?.attachments?.message
                          : ""
                      }
                    />
                  );
                }}
              />
            </div>
          </FormProvider>
          <DialogFooter>
            <AppButton
              isLoading={isPending}
              variant="outline"
              label="Update"
              onClick={methods.handleSubmit(onSubmit)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
