import { Controller, useFormContext, useWatch } from "react-hook-form";
import AppCameraFieldFile from "../../../AppCameraFieldFiles";
import { WorkOrderForm } from ".";
import { Attachment } from "../../../../api/model";

export default function WOAttachmentField() {
  const methods = useFormContext<WorkOrderForm>();
  const uploadedPhotos = useWatch({
    control: methods.control,
    name: "linkedWRAttachments",
  });
  return (
    <Controller
      control={methods.control}
      name="attachments"
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <AppCameraFieldFile
            uploadedPhotos={uploadedPhotos as Attachment[]}
            onDeleteUploadedPhoto={async (a) => {
              methods.setValue(
                "linkedWRAttachments",
                uploadedPhotos?.filter((p) => p.id !== a.id)
              );
            }}
            label="Photos"
            onChange={onChange}
            onDelete={(url) => {
              if (!value) return;
              const newSetPhotos = value.filter((v) => v !== url);
              onChange(newSetPhotos);
            }}
            photos={value ?? []}
            error={!!error}
            helperText={
              methods.formState.errors.attachments?.message !== ""
                ? "At least one photo required"
                : ""
            }
          />
        );
      }}
    />
  );
}
