import {
  PaginationState,
  useReactTable,
  getExpandedRowModel,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthControllerGetCurrentUser } from "../../../../api/auth/auth";
import { useCompaniesControllerGetAllCompanies } from "../../../../api/companies/companies";
import { ProjectWithAttachmentResponse } from "../../../../api/model";
import AppButton from "../../../../components/AppButton";
import AppMultiImage from "../../../../components/AppMultiImage";
import {
  COMPANY_FORM_DRAWER,
  PROJECT_DETAIL_DRAWER,
  PROJECT_FORM_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import { DataTable } from "../../../../components/react-table/DataTable";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import { superAdminColumns } from "../../SuperAdminColumns";
import CompanyCardItem from "./components/CompanyCardItem";
import AppTextField from "../../../../components/AppTextField";
import { FormProvider, useForm, useWatch } from "react-hook-form";

export default function CompanyProjectListScreen() {
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const search = useWatch({ control: methods.control, name: "search" });
  const [, setSearchParams] = useSearchParams();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPg, setCurrentPg] = useState(0);
  const { data: userData } = useAuthControllerGetCurrentUser();
  const {
    data: companies,
    isLoading,
    isFetching,
  } = useCompaniesControllerGetAllCompanies(
    {
      rowsPerPage,
      page: currentPg,
    },
    {
      query: {
        enabled: !!userData,
        select: (res) => res.data,
      },
    }
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex space-x-6">
        <AppTitle title="Companies" />
        <AppButton
          id="create-company-btn"
          label="+ Add"
          className="h-10"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", COMPANY_FORM_DRAWER);
              p.set("drawerState", "CREATE");
              return p;
            });
          }}
        />
      </div>
      <FormProvider {...methods}>
        <div className="max-w-[400px]">
          <AppTextField name="search" placeholder="Search..." />
        </div>
      </FormProvider>
      <div className="grid grid-cols-3 gap-6">
        {companies?.companies
          ?.filter((c) => c.name?.toLowerCase().includes(search.toLowerCase()))
          .map((comp) => (
            <CompanyCardItem key={comp.id} company={comp} />
          ))}
      </div>
    </div>
  );
}
