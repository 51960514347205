/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { UpdateAttachmentAccessRequestDto } from ".././model";

export const attachmentControllerGetSignedUrl = (
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/attachment/${attachmentId}/getSignedUrl`, options);
};

export const getAttachmentControllerGetSignedUrlQueryKey = (
  attachmentId: string
) => {
  return [`/attachment/${attachmentId}/getSignedUrl`] as const;
};

export const getAttachmentControllerGetSignedUrlInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>
  >,
  TError = AxiosError<unknown>
>(
  attachmentId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAttachmentControllerGetSignedUrlQueryKey(attachmentId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>
  > = ({ signal }) =>
    attachmentControllerGetSignedUrl(attachmentId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!attachmentId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AttachmentControllerGetSignedUrlInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>
>;
export type AttachmentControllerGetSignedUrlInfiniteQueryError =
  AxiosError<unknown>;

export const useAttachmentControllerGetSignedUrlInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>
  >,
  TError = AxiosError<unknown>
>(
  attachmentId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAttachmentControllerGetSignedUrlInfiniteQueryOptions(
    attachmentId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAttachmentControllerGetSignedUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
  TError = AxiosError<unknown>
>(
  attachmentId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAttachmentControllerGetSignedUrlQueryKey(attachmentId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>
  > = ({ signal }) =>
    attachmentControllerGetSignedUrl(attachmentId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!attachmentId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AttachmentControllerGetSignedUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>
>;
export type AttachmentControllerGetSignedUrlQueryError = AxiosError<unknown>;

export const useAttachmentControllerGetSignedUrl = <
  TData = Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
  TError = AxiosError<unknown>
>(
  attachmentId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof attachmentControllerGetSignedUrl>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAttachmentControllerGetSignedUrlQueryOptions(
    attachmentId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const attachmentControllerUpdateAttachmentAccess = (
  attachmentId: string,
  updateAttachmentAccessRequestDto: UpdateAttachmentAccessRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/attachment/${attachmentId}/update-access`,
    updateAttachmentAccessRequestDto,
    options
  );
};

export const getAttachmentControllerUpdateAttachmentAccessMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof attachmentControllerUpdateAttachmentAccess>>,
    TError,
    { attachmentId: string; data: UpdateAttachmentAccessRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof attachmentControllerUpdateAttachmentAccess>>,
  TError,
  { attachmentId: string; data: UpdateAttachmentAccessRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof attachmentControllerUpdateAttachmentAccess>>,
    { attachmentId: string; data: UpdateAttachmentAccessRequestDto }
  > = (props) => {
    const { attachmentId, data } = props ?? {};

    return attachmentControllerUpdateAttachmentAccess(
      attachmentId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type AttachmentControllerUpdateAttachmentAccessMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof attachmentControllerUpdateAttachmentAccess>>
  >;
export type AttachmentControllerUpdateAttachmentAccessMutationBody =
  UpdateAttachmentAccessRequestDto;
export type AttachmentControllerUpdateAttachmentAccessMutationError =
  AxiosError<unknown>;

export const useAttachmentControllerUpdateAttachmentAccess = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof attachmentControllerUpdateAttachmentAccess>>,
    TError,
    { attachmentId: string; data: UpdateAttachmentAccessRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof attachmentControllerUpdateAttachmentAccess>>,
  TError,
  { attachmentId: string; data: UpdateAttachmentAccessRequestDto },
  TContext
> => {
  const mutationOptions =
    getAttachmentControllerUpdateAttachmentAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
