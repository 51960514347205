import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useAssetControllerGetAssetsV2 } from "../../../../api/assets/assets";
import {
  AssetControllerGetAssetsV2Params,
  AssetType,
} from "../../../../api/model";
import { DataTable } from "../../../../components/react-table/DataTable";
import { useAppSelector } from "../../../../redux/store";
import { equipmentColumns } from "./equipmentColumn";
import { useFormContext, useWatch } from "react-hook-form";
import useDebounce from "../../../../hooks/useDebounce";

interface EquipmentTabProps {
  equipmentType: AssetType;
}

export default function EquipmentTab({ equipmentType }: EquipmentTabProps) {
  const method = useFormContext();
  const search = useWatch({ control: method.control, name: "search" });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<AssetControllerGetAssetsV2Params>({
    value: {
      assetTypeIds: [equipmentType.id?.toString() ?? "-"],
      projectId: activeProj?.id?.toString() ?? "-",
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
    },
    delay: 500,
  });

  const { data, isLoading, isFetching } = useAssetControllerGetAssetsV2(
    queryArg,
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  const table = useReactTable({
    data: data?.data ?? [],
    columns: equipmentColumns,
    manualPagination: true,
    getRowId: (row) => row.id?.toString() ?? "-",
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: data?.data
      ? Math.ceil(+data.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  useEffect(() => {
    table.resetPagination();
  }, [search]);

  return (
    <DataTable
      table={table}
      isLoading={isLoading}
      isFetching={isFetching}
      totalCount={data?.totalCount ?? 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );
}
