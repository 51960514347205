// Just a component which renders the nested location, based on the location Id prop passed

import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocationControllerGetFullLocationQuery } from "../redux/slices/OpenApi/cerevApi";
import { useLocationControllerGetFullLocation } from "../api/location/location";

interface AppFullLocationProp {
  locationId?: string;
  className?: string;
}

export default function AppFullLocation({
  locationId,
  className,
}: AppFullLocationProp) {
  const {
    data: locations,
    isLoading,
    isError,
  } = useLocationControllerGetFullLocation(
    {
      locationId: locationId ?? "",
    },
    {
      query: {
        enabled: !!locationId && locationId !== "" && locationId !== "-",
        select: (res) => res.data,
        staleTime: 1000 * 60 * 60 * 24,
      },
    }
  );

  if (isLoading || isError || !locationId)
    return <p className="font-sans text-neutral-700">-</p>;

  return (
    <p className={`font-sans ${className}`}>
      {locations?.map((loc) => loc.name).join(" > ")}
    </p>
  );
}
