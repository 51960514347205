import {useEffect} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
  ASSET_FORM_DRAWER,
  PM_CHECKLIST_FORM_DRAWER,
  PM_DETAIL_DRAWER,
} from "../components/drawers/AppDrawer";

export default function useSuccess({dependencies}: {dependencies: boolean[]}) {
  const [searchParam, setSearchParams] = useSearchParams();
  const nav = useNavigate();

  useEffect(() => {
    if (dependencies.includes(true)) {
      // Special case for PM Detail Drawer
      if (
        searchParam.get("drawerType") === PM_CHECKLIST_FORM_DRAWER ||
        searchParam.get("drawerType") === ASSET_FORM_DRAWER
      ) {
        nav(-1);
        // setSearchParams(p => {
        //   p.delete("drawerType");
        //   p.delete("drawerState");
        //   p.delete("locationId");
        //   p.delete("assetId");
        //   return p;
        // })
      } else {
        const newParam = new URLSearchParams();
        setSearchParams(newParam);
      }
    }
  }, dependencies);
}
