import * as React from "react";
import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import { JKRWorkOrder } from "@cerev-cmms/zod-types";
import { TableRow } from "./components/TableRow";
import { TableCell } from "./components/TableCell";

Font.register({
  family: "Fira Sans",
  fonts: [
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Regular.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Bold.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

interface JkrApdSummaryPdfProps {
  workOrders?: JKRWorkOrder[];
}

const JkrApdSummaryContext = React.createContext<JKRWorkOrder[]>([] as any);

export const useJkrApdSummary = () =>
  React.useContext<JKRWorkOrder[]>(JkrApdSummaryContext);

export function JkrApdSummaryPdf({ workOrders }: JkrApdSummaryPdfProps) {
  return (
    <JkrApdSummaryContext.Provider value={workOrders ?? []}>
      <Document style={{ fontFamily: "Fira Sans" }}>
        <Page
          size="A4"
          style={{
            paddingHorizontal: 40,
            paddingVertical: 24,
            flexDirection: "column",
            gap: 12,
          }}
        >
          <View>
            <Text
              style={{
                fontSize: 9,
              }}
            >
              7/9/24. 12:12 PM
            </Text>
          </View>
          <Text
            style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}
          >
            Jun 2024
          </Text>
          <Text style={{ fontSize: 9 }}>
            Printed Date/Time: 09 Jul 2024 12:05:12
          </Text>
          <View style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 24,
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              <Text style={{ minWidth: 150 }}>Monthly Payment Value (MPV)</Text>
              <Text>RM 70,609.58</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 24,
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              <Text style={{ minWidth: 150 }}>Maximum APD (%)</Text>
              <Text>10</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 24,
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              <Text style={{ minWidth: 150 }}>APD Max</Text>
              <Text>RM 7,060.96</Text>
            </View>
          </View>
          <View>
            <TableRow header>
              <TableCell width="48px">No.</TableCell> // 6% of 790px
              <TableCell width="118px" textAlign="left">
                Key Performance Indicators (KPI)
              </TableCell>{" "}
              // 15% of 790px
              <TableCell width="48px">PI No.</TableCell> // 6% of 790px
              <TableCell width="118px" textAlign="left">
                Performance Indicator (PI)
              </TableCell>{" "}
              // 15% of 790px
              <TableCell width="40px">Target (%)</TableCell> // 5% of 790px
              <TableCell width="40px">Actual (%)</TableCell> // 5% of 790px
              <TableCell width="79px">Demerit Point</TableCell> // 10% of 790px
              <TableCell width="79px">Demerit Point Imposed</TableCell> // 10%
              of 790px
              <TableCell width="63px">Weightage (%)</TableCell> // 8% of 790px
              <TableCell width="79px">APD Value (RM)</TableCell> // 10% of 790px
              <TableCell width="79px">APD Value Deducted (RM)</TableCell> // 10%
              of 790px
            </TableRow>
            <TableRow>
              <TableCell width="6%">1</TableCell>
              <TableCell width="15%">
                FMM Service Delivery Related To Core Business
              </TableCell>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0,
                  width: "79%",
                }}
              >
                <TableRow>
                  <TableCell width="48px">1A</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    CUSTOMER SATISFACTION SURVEY RATING (CSS)
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">1B</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    CUSTOMER RATING IN WORK ORDER SHEET
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">1C</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    REPONSE TIME
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">1D</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    EXECUTE TIME
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">1E</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    PENDING / BACKLOG WORK ORDER COMPLETION
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">1F</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    SELF FINDING WORK ORDER &gt; 80% FROM TOTAL WORK ORDER
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">1G</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    CRITICAL SERVICES
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">1F</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    NORMAL SERVICES
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
              </View>
            </TableRow>
            <TableRow>
              <TableCell width="6%">2</TableCell>
              <TableCell width="15%">
                ASSET PERFORMANCE
              </TableCell>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0,
                  width: "79%",
                }}
              >
                <TableRow>
                  <TableCell width="48px">2A</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    PPM FOR ARCHITECTURE AND C&S ASSETS IMPLEMENTED
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">2B</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    PPM FOR MECHANICAL ASSETS IMPLEMENTED
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">2C</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    PPM FOR ELECTRICAL ASSETS IMPLEMENTED
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">2D</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    ENGINEERING/SYSTEM REPORTS & RECOMMENDATION ACTION TAKEN
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
                <TableRow>
                  <TableCell width="48px">2E</TableCell> // 6% of 790px
                  <TableCell width="118px" textAlign="left">
                    WORK-DONE AS SPECIFICATION AND ASSET QUALITY MEET STANDARDS
                  </TableCell>{" "}
                  // 15% of 790px
                  <TableCell width="40px">80</TableCell> // 5% of 790px
                  <TableCell width="40px">83.1</TableCell> // 5% of 790px
                  <TableCell width="79px">1</TableCell> // 10% of 790px
                  <TableCell width="79px">0</TableCell> // 10% of 790px
                  <TableCell width="63px">6</TableCell> // 8% of 790px
                  <TableCell width="79px">423.66</TableCell> // 10% of 790px
                  <TableCell width="79px">0.00</TableCell> // 10% of 790px
                </TableRow>
              </View>
            </TableRow>
          </View>
        </Page>
      </Document>
    </JkrApdSummaryContext.Provider>
  );
}
