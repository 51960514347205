import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import AppButton from "../../../components/AppButton";
import { Upload } from "lucide-react";
import { useBimControllerCreateBimModel } from "../../../api/bim/bim";
import { useUploadAttachmentMutation } from "../../../redux/slices/Attachment/AttachmentApi";
import useAppStorage from "../../../hooks/useAppStorage";
import { useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../components/ui/dialog";
import { useRef, useState } from "react";
import AppTextField from "../../../components/AppTextField";
import AppUploadBox from "../../../components/AppUploadBox";
import { useAppSelector } from "../../../redux/store";

const BimUploadSchema = z.object({
  name: z.string().min(1, "Please provide a name"),
  bimAsset: z.instanceof(File),
});

export type BimUploadForm = z.infer<typeof BimUploadSchema>;

export default function BimUploadDialogBtn() {
  const [open, setOpen] = useState(false);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<BimUploadForm>({
    resolver: zodResolver(BimUploadSchema),
    defaultValues: {
      name: "",
    },
  });

  const qc = useQueryClient();
  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => (query.queryKey[0] as string).includes("bim"),
      });
    },
  });

  const { mutateAsync: createBim } = useBimControllerCreateBimModel();

  const onSubmit: SubmitHandler<BimUploadForm> = (data) => {
    if (!activeProj || !activeProj.id) return;
    mutate({
      files: [data.bimAsset],
      mutateAsync: async (files) => {
        await createBim({
          data: {
            name: data.name,
            bimAsset: files[0],
            projectId: activeProj.id ?? 0,
          },
        });
      },
    });
  };

  return (
    <>
      <AppButton
        label="Upload BIM glb file"
        icon={<Upload />}
        onClick={() => setOpen(true)}
      />
      <Dialog
        open={open}
        onOpenChange={(o) => {
          setOpen(o);
          methods.reset();
        }}
      >
        <DialogContent>
          <DialogTitle>Upload Bim</DialogTitle>
          <FormProvider {...methods}>
            <AppTextField name="name" label="Name" />
            <AppUploadBox
              name="bimAsset"
              supportFormatLabel="GLB"
              label="Upload Bim (.glb extension)"
            />
                    <DialogFooter>
          <AppButton
            label="Submit"
            isLoading={isPending}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </DialogFooter>
          </FormProvider>
        </DialogContent>

      </Dialog>
    </>
  );
}
