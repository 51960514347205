import { Controller, useFormContext, useWatch } from "react-hook-form";
import { PdfSettingFormType } from "./pdfSettingSchema";
import { Attachment } from "../../../api/model";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useProjectControllerDeleteProjectPdfLogo } from "../../../api/projects/projects";
import { useQueryClient } from "@tanstack/react-query";

export default function PdfSettingAttachmentField() {
  const methods = useFormContext<PdfSettingFormType>();
  const uploadedPhotos = useWatch({
    control: methods.control,
    name: "uploadedPdfLogo",
  });
  const { projectId } = useParams();

  const { mutateAsync: deletePdfLogo } =
    useProjectControllerDeleteProjectPdfLogo();

  return (
    <Controller
      control={methods.control}
      name="pdfLogo"
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <AppCameraFieldFile
            uploadedPhotos={uploadedPhotos as Attachment[]}
            onDeleteUploadedPhoto={async (a) => {
              if (!projectId) return;
              await deletePdfLogo({
                projectId,
              });
              methods.setValue(
                "uploadedPdfLogo",
                uploadedPhotos?.filter((p) => p.id !== a.id)
              );

              // May need to invalidate the query here, because the photo has been deleted.
            }}
            label="Photos"
            onChange={onChange}
            onDelete={(url) => {
              if (!value) return;
              const newSetPhotos = value.filter((v) => v !== url);
              onChange(newSetPhotos);
            }}
            photos={value ?? []}
            error={!!error}
            helperText={
              methods.formState.errors.pdfLogo?.message !== ""
                ? "At least one photo required"
                : ""
            }
          />
        );
      }}
    />
  );
}
