import { ReactNode, useState } from "react";
import { Base } from "../AppSelectWithDialog/AppSelectWithDialog";
import { ColumnDef } from "@tanstack/react-table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Delete } from "lucide-react";
import AppButton from "../../AppButton";
import DialogContentWithSearch from "../AppSelectWithDialog/components/DialogContentWithSearch";
import DialogContentWithSearchTable from "../AppSelectWithDialog/components/DialogContentWithSearchTable";

interface AppUncontrolledWithDialogProps<
  B extends Base
> {
  label: string;
  items: B[];
  onResultRender: (item: B, idx: number) => ReactNode;
  onOptionsRender: (item: B, idx: number) => ReactNode;
  placeholder: string;
  dialogTitle: string;
  error?: boolean;
  helperText?: string;
  columns: ColumnDef<B, any>[];
  multiple?: boolean;
  value: Partial<B> | B[] | undefined | null;
  onChange: (value: B | B[]) => void;
}
// The AppSelectWithDialog but without the react hook form component
export default function AppUncontrolledWithDialog<B extends Base>({
  label,
  items,
  onResultRender,
  onOptionsRender,
  placeholder,
  dialogTitle,
  error,
  helperText,
  columns,
  multiple,
  value,
  onChange,
}: AppUncontrolledWithDialogProps<B>) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="w-full max-w-4xl overflow-y-scroll max-h-screen">
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
          </DialogHeader>
          {columns ? (
            <DialogContentWithSearchTable
              items={items}
              value={value}
              columns={columns}
              setRowSelection={onChange}
              onSelected={(selected) => {
                if (multiple) {
                  onChange(selected);
                } else {
                  onChange(selected[0]);
                }
                handleClose();
              }}
              closeDialog={handleClose}
              multiple={multiple}
            />
          ) : (
            <DialogContentWithSearch
              items={items}
              value={value}
              onChange={onChange}
              setOpen={setOpen}
              onOptionsRender={onOptionsRender}
            />
          )}
        </DialogContent>
      </Dialog>
      <p className="font-sans text-slate-400">{label}</p>
      {(Array.isArray(value) && value.length === 0) || !value ? (
        <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-1">
            <div
              // sx={error && { border: 1, borderColor: "#B61C1C" }}
              className="bg-slate-50 px-3 py-3 rounded-md  flex justify-between hover:cursor-pointer border"
              onClick={() => {
                setOpen(true);
              }}
            >
              <p className="text-neutral-600 font-sans">{placeholder}</p>
              <KeyboardArrowRight className="text-neutral-600" />
            </div>
          </div>
          {error && (
            <p className="text-red-700 font-sans text-xs">{helperText}</p>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          <div className="bg-primary-900 rounded-md">
            {Array.isArray(value) ? (
              value?.map((v: Base, i: number) => {
                return (
                  <div
                    key={(v?.id as number) ?? i}
                    className="px-3 py-2 font-sans text-white font-medium hover:cursor-pointer flex w-full justify-between items-center"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {onResultRender(items.find((itm) => itm.id === v.id)!, i)}
                    <AppButton
                      label="Delete"
                      icon={<Delete />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(
                          value.filter((curr: Base) => curr.id !== v.id)
                        );
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col gap-1">
                <div
                  className="bg-primary-900 rounded-md px-3 py-2 font-sans text-white font-medium flex items-center justify-between hover:cursor-pointer"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <>
                    {onResultRender(
                      items.find((i: B) => i.id === (value as Base).id)!,
                      0
                    )}
                  </>
                  <KeyboardArrowRight className="text-white" />
                </div>
              </div>
            )}
          </div>
          {error && (
            <p className="text-red-700 font-sans text-xs">{helperText}</p>
          )}
        </div>
      )}
      {/* <AppButton
      label="Add"
      onClick={() => {
        setOpen(true);
      }}
    /> */}
    </div>
  );
}
