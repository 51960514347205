import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { DataTable } from "../../../components/react-table/DataTable";
import useDebounce from "../../../hooks/useDebounce";
import {
  WorkPermitsControllerGetWorkPermitCountApiArg,
  WorkPermitsControllerGetWorkPermitsApiArg,
  useWorkPermitsControllerGetWorkPermitCountQuery,
  useWorkPermitsControllerGetWorkPermitsQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../redux/store";
import { workPermitColumns } from "./components/WorkPermitColumns";
import {
  useWorkPermitsControllerGetWorkPermitCount,
  useWorkPermitsControllerGetWorkPermits,
} from "../../../api/work-permits/work-permits";
import {
  WorkPermitsControllerGetWorkPermitCountParams,
  WorkPermitsControllerGetWorkPermitsParams,
} from "../../../api/model";

interface WorkPermitTabProps {
  status: "NEW" | "APPROVED" | "REJECTED" | "CANCELLED";
}

// TODO: Continue here to fix pagination issue in work permit
export default function WorkPermitTab({ status }: WorkPermitTabProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<WorkPermitsControllerGetWorkPermitsParams>({
    value: {
      projectId: activeProj?.id ?? 0,
      workPermitStatus: status,
      search,
      rowsPerPage: pagination.pageSize,
      page: pagination.pageIndex,
    },
    delay: 500,
  });

  const queryCountArg =
    useDebounce<WorkPermitsControllerGetWorkPermitCountParams>({
      value: {
        projectId: activeProj?.id ?? 0,
        workPermitStatus: status,
        search,
      },
      delay: 500,
    });

  const {
    data: workPermits,
    isLoading,
    isFetching,
  } = useWorkPermitsControllerGetWorkPermits(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const { data: workPermitsCount, isLoading: workPermitsCountIsLoading } =
    useWorkPermitsControllerGetWorkPermitCount(queryCountArg, {
      query: {
        enabled: !!activeProj,
      },
    });

  const table = useReactTable({
    data: workPermits?.data ?? [],
    columns: workPermitColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: workPermitsCount?.data
      ? Math.ceil(+workPermitsCount.data / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <DataTable
      isLoading={isLoading}
      isFetching={isFetching}
      table={table}
      totalCount={+(workPermitsCount?.data ?? 0)}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );

  // return (
  //   <AppTable
  //     isLoading={isLoading}
  //     isEmpty={workPermits?.length === 0}
  //     colSpan={3}
  //     headerTableRow={
  //       <TableHead>
  //         <TableRow>
  //           <TableCell className="font-sans text-lg font-bold">
  //             Company
  //           </TableCell>
  //           <TableCell className="font-sans text-lg font-bold">
  //             Description
  //           </TableCell>
  //           <TableCell className="font-sans text-lg font-bold">
  //             Location Of Work
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //     }
  //     bodyTableRow={workPermits?.map((workp) => (
  //       <TableRow key={workp.id}>
  //         <TableCell
  //           className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
  //           onClick={() => {
  //             setSearchParams((p) => {
  //               p.set("drawerType", WORK_PERMIT_DETAIL_DRAWER);
  //               p.set("drawerState", DRAWER_VIEW_STATE);
  //               p.set("workPermitId", workp.id.toString());
  //               return p;
  //             });
  //           }}
  //         >
  //           {workp.applierCompanyName}
  //         </TableCell>
  //         <TableCell>{workp.description}</TableCell>
  //         <TableCell>{workp.locationOfWork}</TableCell>
  //       </TableRow>
  //     ))}
  //     count={parseInt(workPermitsCount?.toString() ?? "0")}
  //     rowsPerPage={rowsPerPage}
  //     onRowsPerPageChange={(e) => {
  //       setRowsPerPage(parseInt(e.target.value, 10));
  //     }}
  //     page={currentPg}
  //     onPageChange={(e, value) => {
  //       if (!e) return;
  //       setCurrentPg(parseInt(value.toString(), 10));
  //     }}
  //   />
}
