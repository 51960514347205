import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useWoTimeLogsControllerCreateTimeLog } from "../../../../../api/wo-time-logs/wo-time-logs";
import AppButton from "../../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../ui/dialog";
import ManHourForm, { TimeLogFormType, timeLogSchema } from "./ManHourForm";

export default function CreateTimeLogBtn() {
  const qc = useQueryClient();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const activeWoId = searchParams.get("workOrderId");
  const methods = useForm<TimeLogFormType>({
    resolver: zodResolver(timeLogSchema),
  });

  const { mutate, isPending } = useWoTimeLogsControllerCreateTimeLog({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("wo-time-logs"),
        });
        setOpen(false);
      },
    },
  });

  const onSubmit: SubmitHandler<TimeLogFormType> = (data) => {
    if (!activeWoId) return;
    mutate({
      data: {
        workOrderId: Number(activeWoId),
        dateStart: data.timeRange.dateStart.toISOString(),
        dateEnd: data.timeRange.dateEnd.toISOString(),
        description: data.description,
        doneByIds: data.doneBy.map((user) => user.id),
      },
    });
  };

  useEffect(() => {
    if (!open) methods.reset();
  }, [open]);

  return (
    <>
      <AppButton
        label="Create"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogTitle className="mb-6">Create Time Log</DialogTitle>
          <FormProvider {...methods}>
            <ManHourForm />
          </FormProvider>
          <DialogFooter className="mt-6">
            <AppButton
              label="Cancel"
              variant="ghost"
              onClick={() => setOpen(false)}
            />
            <AppButton
              label="Confirm"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
