import {
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useDefectTypeControllerGetDefectTypes } from "../../../../api/defect-type/defect-type";
import { DefectTypeControllerGetDefectTypesParams } from "../../../../api/model";
import AppButton from "../../../../components/AppButton";
import AppTextField from "../../../../components/AppTextField";
import {
  DRAWER_CREATE_STATE,
  WORK_ORDER_TYPE_FORM_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import { DataTable } from "../../../../components/react-table/DataTable";
import useDebounce from "../../../../hooks/useDebounce";
import { useAppSelector } from "../../../../redux/store";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import { workOrderTypeSettingColumns } from "./columns/WorkOrderTypeSettingColumn";

export default function WorkOrderTypeTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const search = useWatch({ control: methods.control, name: "search" });

  const queryArg = useDebounce<DefectTypeControllerGetDefectTypesParams>({
    value: {
      companyId: activeComp?.id ?? 0,
      projectId: activeProj?.id ?? 0,
      search,
    },
    delay: 500,
  });

  const {
    data: workOrderTypes,
    isLoading,
    isFetching,
  } = useDefectTypeControllerGetDefectTypes(queryArg, {
    query: {
      enabled: !!activeProj && !!activeComp,
    },
  });

  const table = useReactTable({
    data: workOrderTypes?.data ?? [],
    columns: workOrderTypeSettingColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="flex gap-4">
          <AppTitle title="Work Order Types" className="mb-0" />
          <AppButton
            label="+ Add"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", WORK_ORDER_TYPE_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
      </div>
      <p className="text-sm text-slate-600">
        Add, Update, and Delete work order types here
      </p>
      <FormProvider {...methods}>
        <AppTextField
          name="search"
          className="w-[400px]"
          inputClassName="bg-transparent"
          placeholder="Search..."
        />
      </FormProvider>
      <DataTable
        isLoading={isLoading}
        isFetching={isFetching}
        table={table}
        totalCount={workOrderTypes ? +workOrderTypes.data.length : 0}
      />
    </div>
  );
}
