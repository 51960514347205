import { ColumnDef, Row } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import {
  USER_DETAIL_DRAWER
} from "../../../../../components/drawers/AppDrawer";
import {
  UserEntity
} from "../../../../../redux/slices/OpenApi/cerevApi";

const UserNameNav = ({ row }: { row: Row<UserEntity> }) => {
  const [searchParam, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", USER_DETAIL_DRAWER);
          p.set("firebaseId", row.getValue("firebaseUserId") ?? "");
          return p;
        });
      }}
    >
      {row.getValue("name")}
    </div>
  );
};

export const accountTabColumns: ColumnDef<UserEntity>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <UserNameNav row={row} />,
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "firebaseUserId",
  },
  {
    accessorKey: "position",
    header: "Position",
  },
  // {
  //   accessorKey: "projects",
  //   header: "Projects",
  //   cell: ({ row }) => {
  //     const qc = useQueryClient();

  //     const { mutate: updateUserProjects } =
  //       useUserControllerUpdateUserProjects({
  //         mutation: {
  //           onSuccess: () => {
  //             qc.invalidateQueries({
  //               predicate: (qry) =>
  //                 (qry.queryKey[0] as string).includes("auth") ||
  //                 (qry.queryKey[0] as string).includes("user"),
  //             });
  //           },
  //         },
  //       });

  //     const onDeleteProjAccess = (proj: Project, user: UserEntity) => {
  //       if (!user.id || !user.projects || !user) return;
  //       updateUserProjects({
  //         userId: user.id,
  //         data: {
  //           projectIds: user.projects
  //             .filter((p) => p.id !== proj.id)
  //             .map((p) => p?.id ?? 0),
  //           rolesIds: user.roles
  //             .filter((r) => r.projectId !== proj.id)
  //             .map((r) => r.id),
  //         },
  //       });
  //     };

  //     return (
  //       <div className="flex-wrap flex gap-x-1 gap-y-1 items-center">
  //         {(row.getValue("projects") as Project[])?.map((proj) => (
  //           <Chip
  //             key={proj.id}
  //             label={proj.name}
  //             className="mr-1 mb-1 text text-white font-bold bg-primary-900"
  //             onDelete={() => {
  //               onDeleteProjAccess(proj, row.original);
  //             }}
  //           />
  //         ))}
  //         <AddProjectBtn user={row.original} />
  //       </div>
  //     );
  //   },
  // },
  // {
  //   accessorKey: "roles",
  //   header: "Roles",
  //   cell: ({ row }) => {
  //     const qc = useQueryClient();
  //     const { mutate: updateUserProjects } =
  //       useUserControllerUpdateUserProjects({
  //         mutation: {
  //           onSuccess: () => {
  //             qc.invalidateQueries({
  //               predicate: (qry) =>
  //                 (qry.queryKey[0] as string).includes("auth") ||
  //                 (qry.queryKey[0] as string).includes("user"),
  //             });
  //           },
  //         },
  //       });

  //     const onDeleteRoleAccess = (role: Role, user: UserEntity) => {
  //       if (!user.id) return;
  //       updateUserProjects({
  //         userId: user.id,
  //         data: {
  //           rolesIds: user.roles
  //             .filter((r) => r.id !== role.id)
  //             .map((r) => r.id),
  //         },
  //       });
  //     };

  //     return (
  //       <div className="flex-wrap flex gap-x-1 gap-y-1 items-center">
  //         {row.original.roles.map((rol) => (
  //           <Chip
  //             key={rol.id}
  //             label={rol.project?.name + " - " + rol?.name}
  //             className="mr-1 mb-1 text text-white font-bold bg-primary-900"
  //             onDelete={() => {
  //               onDeleteRoleAccess(rol, row.original);
  //             }}
  //           />
  //         ))}
  //         <AddRolesBtn user={row.original} />
  //       </div>
  //     );
  //   },
  // },
  // {
  //   accessorKey: "accountTypes",
  //   header: "Account Types",
  //   cell: ({ row }) => {
  //     const { mutate: deleteAccountType } =
  //       useAccountTypeControllerDeleteAccountType();

  //     const onDeleteAccountType = (accType: AccountTypeWithProject) => {
  //       deleteAccountType({
  //         accountTypeId: accType.id,
  //       });
  //     };

  //     return (
  //       <div className="flex-wrap flex gap-x-1 gap-y-1 items-center">
  //         {row.original.accountTypes.map((accType) => (
  //           <Chip
  //             key={accType.id}
  //             label={`${accType.project?.name} - ${accType.type}`}
  //             className="mr-1 mb-1 text text-white font-bold bg-primary-900"
  //             onDelete={() => {
  //               onDeleteAccountType(accType);
  //             }}
  //           />
  //         ))}
  //         <AddAccountTypeBtn user={row.original} />
  //       </div>
  //     );
  //   },
  // },
  // {
  //   accessorKey: "vendor",
  //   header: "Linked Vendor",
  //   cell: ({ row }) => {
  //     return (
  //       <div className="flex gap-4 items-center flex-wrap">
  //         <p>{row.original.vendor?.name ?? "-"}</p>
  //         <LinkVendorBtn user={row.original} />
  //       </div>
  //     );
  //   },
  // },
];
