import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import {
  useAssetTypeControllerCreateAssetType,
  useAssetTypeControllerGetOneAssetType,
  useAssetTypeControllerUpdateAssetType,
} from "../../../api/asset-type/asset-type";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppTextField from "../../AppTextField";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";

const AssetTypeFormSchema = z.object({
  name: z.string().min(1),
});

type AssetTypeForm = z.infer<typeof AssetTypeFormSchema>;

export default function AssetTypeFormDrawer() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);

  const isUpdateDrawer = useIsUpdateDrawerState();

  const { data: user } = useGetUserDataQuery();

  const methods = useForm<AssetTypeForm>({
    resolver: zodResolver(AssetTypeFormSchema),
    defaultValues: {
      name: "",
    },
  });

  const { mutate: createAssetType, isPending: createAssetTypeIsLoading } =
    useAssetTypeControllerCreateAssetType({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("asset-type"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });
  const { mutate: updateAssetType, isPending: updateAssetTypeIsLoading } =
    useAssetTypeControllerUpdateAssetType({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("asset-type"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });

  const { data: editAssetType, isLoading: editAssetTypeIsLoading } =
    useAssetTypeControllerGetOneAssetType(
      searchParams.get("assetTypeId") as string,
      {
        query: {
          enabled: !!searchParams.get("assetTypeId"),
          select: (res) => res.data,
        },
      }
    );

  // isEdit mode
  useEffect(() => {
    if (isUpdateDrawer && editAssetType && editAssetType.name) {
      methods.setValue("name", editAssetType.name);
    }
  }, [searchParams, editAssetType]);

  // useError({
  //   dependencies: [
  //     {
  //       isError: createAssetTypeIsError,
  //       error: createAssetTypeError,
  //     },
  //     {
  //       isError: updateAssetTypeIsError,
  //       error: updateAssetTypeError,
  //     },
  //     {
  //       isError: editAssetTypeIsError,
  //       error: editAssetTypeError,
  //     },
  //   ],
  // });

  // useSuccess({
  //   dependencies: [createAssetTypeIsSuccess, updateAssetTypeIsSuccess],
  // });

  const onSubmit: SubmitHandler<AssetTypeForm> = (data) => {
    if (!activeProj || !activeComp || !user || !activeProj.id) return;
    if (isUpdateDrawer) {
      if (!searchParams.get("assetTypeId")) return;
      updateAssetType({
        assetTypeId: searchParams.get("assetTypeId") || "",
        data: {
          name: data.name,
          projectId: activeProj.id,
          companyId: activeComp.id,
          updatedById: user.id,
        },
      });
    } else {
      createAssetType({
        data: {
          name: data.name,
          projectId: activeProj.id,
          companyId: activeComp.id,
          createdById: user.id,
        },
      });
    }
  };

  if (editAssetTypeIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update Equipment Type" : "Create Equipment Type"}
        </p>
        <AppTextField label="Name" name="name" />
        <div className="flex">
          <AppButton
            isLoading={createAssetTypeIsLoading || updateAssetTypeIsLoading}
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </FormProvider>
  );
}
