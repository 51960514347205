import React from "react";
import { useManHoursEnabled } from "../hooks/useFeatures";

interface ManHourGuardProps {
  children: React.ReactNode;
}

export default function ManHourGuard({ children }: ManHourGuardProps) {
  const hasAccess = useManHoursEnabled();
  if (!hasAccess) return <></>;

  return <>{children}</>;
}
