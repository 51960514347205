import { useState } from "react";
import AppButton from "../../../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../../ui/dialog";
import WoRevenueForm from "./WoRevenueForm";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { WoRevenueFormType, woRevenueSchema } from "./woRevenueSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useWoRevenueControllerCreateWoRevenue } from "../../../../../../api/work-order-revenue/work-order-revenue";

export default function WoRevenueDialogBtn() {
  const [searchParams] = useSearchParams();
  const activeWoId = searchParams.get("workOrderId");
  const qc = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm<WoRevenueFormType>({
    resolver: zodResolver(woRevenueSchema),
    defaultValues: {
      description: "",
      amount: 0,
    },
  });

  const { mutate, isPending } = useWoRevenueControllerCreateWoRevenue({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("wo-revenue"),
        });
        setIsOpen(false);
      },
    },
  });

  const onSubmit: SubmitHandler<WoRevenueFormType> = async (data) => {
    mutate({
      data: {
        description: data.description,
        amount: data.amount,
        workOrderId: Number(activeWoId) ?? 0,
      },
    });
  };

  return (
    <>
      <AppButton
        onClick={() => {
          setIsOpen(true);
        }}
        label="+ Add"
      />
      <Dialog
        open={isOpen}
        onOpenChange={(o) => {
          setIsOpen(o);
        }}
      >
        <DialogContent>
          <DialogTitle>Add Revenue</DialogTitle>
          <FormProvider {...methods}>
            <WoRevenueForm />
          </FormProvider>
          <DialogFooter>
            <AppButton
              variant="ghost"
              label="Cancel"
              onClick={() => setIsOpen(false)}
            />
            <AppButton
              label="Save"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
