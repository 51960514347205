/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type Status = (typeof Status)[keyof typeof Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  DELETED: "DELETED",
} as const;
