import { create } from "./../../../../../apps/web/src/__test__/__mocks__/zustand";
import { z } from "zod";

const workOrder = z.object({
  refNo: z.string().optional(),
  workRequests: z.object({ trackingCode: z.string() }).array().optional(),
  defectType: z.object({
    name: z.string().optional(),
  }),
  createdOn: z.string().optional(),
  createdBy: z.object({
    name: z.string().optional(),
  }),
  photos: z
    .object({
      url: z.string().optional(),
    })
    .array()
    .optional(),
});

export type JKRWorkOrder = z.infer<typeof workOrder>;

export const getWorkOrdersSchema = z.object({
  locationId: z.string().optional(),
  workOrderStatusId: z.string().optional(),
  projectId: z.string().optional(),
  vendorId: z.string().optional(),
  locFilterId: z.string().optional(),
  filterStartDateISO: z.string().optional(),
  filterEndDateISO: z.string().optional(),
  filterAssetTypeIds: z.array(z.string()).optional(),
  filterRaisedByIds: z.array(z.string()).optional(),
  filterWorkOrderTypeIds: z.array(z.string()).optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
  cursorId: z.string().optional(),
  search: z.string().optional(),
});

export type GetWorkOrdersInput = z.infer<typeof getWorkOrdersSchema>;
