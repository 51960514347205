/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  ToggleFeatureRequestDto,
  ToggleFeatureResponseDto,
} from ".././model";

export const featureToggleControllerToggleFeature = (
  projectId: string,
  toggleFeatureRequestDto: ToggleFeatureRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/feature-toggles/${projectId}`,
    toggleFeatureRequestDto,
    options
  );
};

export const getFeatureToggleControllerToggleFeatureMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featureToggleControllerToggleFeature>>,
    TError,
    { projectId: string; data: ToggleFeatureRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof featureToggleControllerToggleFeature>>,
  TError,
  { projectId: string; data: ToggleFeatureRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof featureToggleControllerToggleFeature>>,
    { projectId: string; data: ToggleFeatureRequestDto }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return featureToggleControllerToggleFeature(projectId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FeatureToggleControllerToggleFeatureMutationResult = NonNullable<
  Awaited<ReturnType<typeof featureToggleControllerToggleFeature>>
>;
export type FeatureToggleControllerToggleFeatureMutationBody =
  ToggleFeatureRequestDto;
export type FeatureToggleControllerToggleFeatureMutationError =
  AxiosError<unknown>;

export const useFeatureToggleControllerToggleFeature = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featureToggleControllerToggleFeature>>,
    TError,
    { projectId: string; data: ToggleFeatureRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof featureToggleControllerToggleFeature>>,
  TError,
  { projectId: string; data: ToggleFeatureRequestDto },
  TContext
> => {
  const mutationOptions =
    getFeatureToggleControllerToggleFeatureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const featureToggleControllerGetFeatures = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ToggleFeatureResponseDto>> => {
  return axios.get(`/feature-toggles/${projectId}`, options);
};

export const getFeatureToggleControllerGetFeaturesQueryKey = (
  projectId: string
) => {
  return [`/feature-toggles/${projectId}`] as const;
};

export const getFeatureToggleControllerGetFeaturesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFeatureToggleControllerGetFeaturesQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>
  > = ({ signal }) =>
    featureToggleControllerGetFeatures(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FeatureToggleControllerGetFeaturesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>
>;
export type FeatureToggleControllerGetFeaturesInfiniteQueryError =
  AxiosError<unknown>;

export const useFeatureToggleControllerGetFeaturesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getFeatureToggleControllerGetFeaturesInfiniteQueryOptions(
      projectId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFeatureToggleControllerGetFeaturesQueryOptions = <
  TData = Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFeatureToggleControllerGetFeaturesQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>
  > = ({ signal }) =>
    featureToggleControllerGetFeatures(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FeatureToggleControllerGetFeaturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>
>;
export type FeatureToggleControllerGetFeaturesQueryError = AxiosError<unknown>;

export const useFeatureToggleControllerGetFeatures = <
  TData = Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof featureToggleControllerGetFeatures>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFeatureToggleControllerGetFeaturesQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
