import { useSearchParams } from "react-router-dom";
import {
  useVendorControllerDeleteVendorMutation,
  useVendorControllerGetOneVendorQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import AppItemInfo from "../../AppItemInfo";
import AppDocument from "../../AppDocument";
import AppButton from "../../AppButton";
import { DRAWER_UPDATE_STATE, VENDOR_FORM_DRAWER } from "../AppDrawer";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import useSuccess from "../../../hooks/useSuccess";
import useError from "../../../hooks/useError";
import {
  Files,
  MapPin,
  MessageSquare,
  Phone,
  Settings,
  Tag,
  User2,
} from "lucide-react";
import { useVendorControllerDeleteVendor } from "../../../api/vendor/vendor";
import { useQueryClient } from "@tanstack/react-query";

export default function VendorDetailDrawer() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId");

  const { data: vendor, isLoading } = useVendorControllerGetOneVendorQuery(
    {
      vendorId: vendorId ?? "",
    },
    { skip: !vendorId }
  );

  const { mutateAsync: deleteVendor } = useVendorControllerDeleteVendor({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => (query.queryKey[0] as any).includes("vendor"),
        });
        setSearchParams((p) => {
          p.delete("drawerType");
          p.delete("drawerState");
          p.delete("vendorId");
          return p;
        });
      },
    },
  });

  // useSuccess({ dependencies: [deleteVendorIsSuccess] });
  // useError({
  //   dependencies: [
  //     {
  //       isError: deleteVendorIsError,
  //       error: deleteVendorError,
  //     },
  //   ],
  // });

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-6">
      <p className="font-sans text-2xl font-bold">{vendor?.name ?? "-"}</p>

      <AppItemInfo
        label="Name"
        content={<p className="font-sans">{vendor?.name ?? "-"}</p>}
        icon={<Tag className="text-primary-900" />}
      />
      <AppItemInfo
        label="Work Scopes"
        content={
          <p className="font-sans">
            {vendor?.workscope.map((sc) => sc.name).join(", ") ?? "-"}
          </p>
        }
        icon={<Settings className="text-primary-900" />}
      />
      <AppItemInfo
        label="Contact Person"
        content={<p className="font-sans">{vendor?.contactName ?? "-"}</p>}
        icon={<User2 className="text-primary-900" />}
      />
      <AppItemInfo
        label="Contact No."
        content={<p className="font-sans">{vendor?.contactNo ?? "-"}</p>}
        icon={<Phone className="text-primary-900" />}
      />
      <AppItemInfo
        label="Business Address"
        content={<p className="font-sans">{vendor?.businessAddress ?? "-"}</p>}
        icon={<MapPin className="text-primary-900" />}
      />
      <AppItemInfo
        label="Remarks"
        content={<p className="font-sans">{vendor?.remarks ?? "-"}</p>}
        icon={<MessageSquare className="text-primary-900" />}
      />
      <AppItemInfo
        label="Additional Documents"
        content={
          <div className="flex flex-col gap-4">
            {vendor?.attachments?.length ? (
              vendor?.attachments.map((p) => <AppDocument key={p.id} att={p} />)
            ) : (
              <p className="font-sans text-neutral-900">-</p>
            )}
          </div>
        }
        icon={<Files className="text-primary-900" />}
      />
      <div className="flex gap-4">
        <AppButton
          variant="outline"
          label="Edit"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", VENDOR_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("vendorId", vendor?.id.toString() ?? "");
              return p;
            });
          }}
        />
        <ConfirmDeleteBtnWithDialog
          confirmDeleteTxt={vendor?.name ?? ""}
          onDeleteConfirm={async () => {
            if (vendor)
              await deleteVendor({
                vendorId: vendor.id.toString() ?? "",
              });
          }}
        />
      </div>
    </div>
  );
}
