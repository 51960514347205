import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { InvoiceFormType } from "./invoiceFormSchema";
import { useSearchParams } from "react-router-dom";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { useProjectControllerGetProjects } from "../../../api/projects/projects";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import { selectProjectColumns } from "./selectProjectColumns";
import { useEffect } from "react";
import AppButton from "../../AppButton";
import { useInvoiceControllerCreateInvoice } from "../../../api/invoice/invoice";
import { useQueryClient } from "@tanstack/react-query";
import { AppDatePicker } from "../../AppDatePicker";

export default function InvoiceFormDrawer() {
  const qc = useQueryClient();
  const [searchParam, setSearchParams] = useSearchParams();
  const invoiceId = searchParam.get("invoiceId");
  const { data: currentUser } = useAuthControllerGetCurrentUser({
    query: {
      select: (res) => res.data,
    },
  });

  const methods = useForm<InvoiceFormType>({
    defaultValues: {
      amount: 0,
      dueDate: new Date(),
      invoiceDate: new Date(),
      name: "",
    },
  });

  const { mutate: createInvoice, isPending } =
    useInvoiceControllerCreateInvoice({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("invoice"),
          });
          setSearchParams(() => {
            return new URLSearchParams();
          });
        },
      },
    });

  const onSubmit: SubmitHandler<InvoiceFormType> = (data) => {
    createInvoice({
      data: {
        amount: data.amount,
        invoiceDate: data.invoiceDate.toISOString(),
        dueDate: data.dueDate.toISOString(),
        name: data.name,
        projectId: data.project?.id,
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <h3 className="font-sans text-2xl font-bold">
        {invoiceId ? "Update Invoice" : "Create Invoice"}
      </h3>
      <FormProvider {...methods}>
        <AppTextField name="name" label="Description" />
        <AppDatePicker name="invoiceDate" label="Invoice Date" />
        <AppDatePicker name="dueDate" label="Due Date" />
        <AppTextField name="amount" label="Amount (RM)" type="number" />
        <AppSelectWithDialog
          columns={selectProjectColumns}
          label="Project"
          placeholder="Select Project"
          defaultValue={undefined}
          control={methods.control}
          name="project"
          items={currentUser?.projects ?? []}
          onResultRender={(item, idx) => {
            return <div key={idx}>{item?.name}</div>;
          }}
          onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
          dialogTitle="Select Project"
          error={!!methods.formState.errors.project?.message}
          helperText={methods.formState.errors.project?.message}
        />
        <div className="flex">
          <AppButton
            label="Confirm"
            isLoading={isPending}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </div>
      </FormProvider>
    </div>
  );
}
