import { ColumnDef } from "@tanstack/react-table";
import {
  GetProjWithRolesDto,
  RoleWithStatuses,
} from "../../../../../redux/slices/OpenApi/cerevApi";

export const accessControlColumns: ColumnDef<GetProjWithRolesDto>[] = [
  {
    header: "Name",
    columns: [
      {
        accessorKey: "roles.defectStatuses",
        header: "Work Order Statuses",
        getGroupingValue: (row) => row.name,
      },
      {
        accessorKey: "roles.pmStatuses",
        header: "Preventive Maintenance Statuses",
        getGroupingValue: (row) => row.name,
      },
    ],
  },
];
