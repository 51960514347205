import { useDefectTypeControllerGetDefectTypes } from "../../../../../api/defect-type/defect-type";
import { useAppSelector } from "../../../../../redux/store";
import { useSelectWorkOrderFilterStore } from "../../../../../stores/workOrder/workOrderFilterStore";
import AppUncontrolledWithDialog from "../../../../dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { workOrderTypeColumns } from "../../columns/workOrderTypeColumns";

export default function WorkOrderTypeFilterField() {
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: defectTypes } = useDefectTypeControllerGetDefectTypes(
    {
      projectId: activeProj?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeComp && !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  const filterWorkOrderType = useSelectWorkOrderFilterStore(
    (state) => state.workOrderType
  );
  const setFilterWorkOrderType = useSelectWorkOrderFilterStore(
    (state) => state.setWorkOrderType
  );

  return (
    <AppUncontrolledWithDialog
      placeholder="Select Work Order Type"
      label="Work Order Type"
      items={defectTypes ?? []}
      onResultRender={(item, idx) => (
        <div className="font-medium">{item?.name ?? "-"}</div>
      )}
      onOptionsRender={(item, idx) => (
        <div className="font-medium">{item?.name ?? "-"}</div>
      )}
      dialogTitle="Select Work Order Type"
      columns={workOrderTypeColumns}
      multiple
      value={filterWorkOrderType}
      onChange={(v) => {
        if (Array.isArray(v)) setFilterWorkOrderType(v);
      }}
    />
  );
}
