import { z } from "zod";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import { useUploadAttachmentMutation } from "../../../redux/slices/Attachment/AttachmentApi";
import { SnackBarType, setOpenSnackBar } from "../../../redux/slices/RootSlice";
import AppTextField from "../../AppTextField";
import AppDocumentUpload from "../../AppDocumentUpload";
import AppButton from "../../AppButton";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import useSuccess from "../../../hooks/useSuccess";
import useError from "../../../hooks/useError";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import AppTextAreaField from "../../AppTextAreaField";
import { workScopeColumns } from "./columns/workScopeColumns";
import { Attachment, AttachmentProjectType } from "../../../api/model";
import {
  useVendorControllerCreateVendor,
  useVendorControllerDeleteAttachment,
  useVendorControllerGetOneVendor,
  useVendorControllerUpdateOneVendor,
} from "../../../api/vendor/vendor";
import { useQueryClient } from "@tanstack/react-query";
import { useWorkscopeControllerGetWorkScopes } from "../../../api/workscope/workscope";
import useAppStorage from "../../../hooks/useAppStorage";

const VendorFormSchema = z.object({
  name: z.string().min(1, { message: "Vendor name is required" }),
  workScope: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array()
    .min(1, { message: "Work scope is required" }),
  contactName: z.string().min(1, { message: "Contact name is required" }),
  telNo: z.string().min(1, { message: "Telephone number is required" }),
  companyWebSite: z.string().optional(),
  businessAddress: z.string().optional(),
  remark: z.string().optional(),
  additionalDocuments: z.instanceof(File).array().optional(),
});

export type VendorForm = z.infer<typeof VendorFormSchema>;

export default function VendorFormDrawer() {
  const qc = useQueryClient();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId");
  const [uploadedDocs, setUploadedDocs] = useState<Attachment[]>([]);
  const dispatch = useAppDispatch();
  const methods = useForm<VendorForm>({
    resolver: zodResolver(VendorFormSchema),
    defaultValues: {
      name: "",
      workScope: [],
      contactName: "",
      telNo: "",
      companyWebSite: "",
      businessAddress: "",
      remark: "",
      additionalDocuments: [],
    },
  });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: user } = useGetUserDataQuery();

  const { data: editVendor, isLoading: editVendorIsLoading } =
    useVendorControllerGetOneVendor(
      vendorId ?? "",

      {
        query: {
          enabled: !!isUpdateDrawer && !!vendorId,
        },
      }
    );

  const { mutate: createVendor } =
    useVendorControllerCreateVendor({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) => {
              return (query.queryKey[0] as any).includes("vendor");
            },
          });
          setSearchParams((p) => new URLSearchParams());
        },
      },
    });

  const { mutate: updateVendor } = useVendorControllerUpdateOneVendor({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as any).includes("vendor");
          },
        });
        setSearchParams((p) => new URLSearchParams());
      },
    },
  });

  const { data: workScopes } = useWorkscopeControllerGetWorkScopes(
    {
      companyId: activeComp?.id.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeComp,
      },
    }
  );

  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => {
          return (query.queryKey[0] as any).includes("vendor");
        },
      });
      setSearchParams((p) => new URLSearchParams());
    },
  });

  // const [
  //   uploadAttachments,
  //   { isLoading: attIsLoading, isError: attIsError, error: attError },
  // ] = useUploadAttachmentMutation();

  const { mutate: deleteDocument } = useVendorControllerDeleteAttachment();

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDocs.filter((v) => v.id !== att.id);
    if (!editVendor || !att || !att.id) return;
    deleteDocument({
      attachmentId: att?.id?.toString(),
      vendorId: editVendor?.data?.id.toString(),
    });
    setUploadedDocs(newUploadedDocs);
  };

  const onSubmit: SubmitHandler<VendorForm> = async (data) => {
    let uploadedDocs = [] as AttachmentProjectType[] | null;

    if (!activeProj || !user || !activeComp) return;

    mutate({
      files: data.additionalDocuments ?? [],
      mutateAsync: async (atts) => {
        if (isUpdateDrawer) {
          if (!editVendor) return;
          await updateVendor({
            vendorId: editVendor?.data?.id?.toString(),
            data: {
              updatedById: user.id.toString(),
              name: data.name,
              contactName: data.contactName,
              contactNo: data.telNo,
              companyWebsite: data.companyWebSite ?? "",
              remarks: data.remark ?? "",
              businessAddress: data.businessAddress ?? "",
              workScopeIds: data.workScope.map((ws) => ws.id),
              attachments: atts,
              companyId: activeComp.id,
            },
          });
        } else {
          await createVendor({
            data: {
              createdById: user.id,
              name: data.name,
              contactName: data.contactName,
              contactNo: data.telNo,
              companyWebsite: data.companyWebSite ?? "",
              remarks: data.remark ?? "",
              businessAddress: data.businessAddress ?? "",
              workScopeIds: data.workScope.map((ws) => ws.id),
              attachments: atts,
              companyId: activeComp.id,
            },
          });
        }
      },
    });

    // if (data.additionalDocuments) {
    //   uploadedDocs = await uploadAttachments(data.additionalDocuments)
    //     .unwrap()
    //     .catch((e) => {
    //       dispatch(
    //         setOpenSnackBar({
    //           type: SnackBarType.Error,
    //           msg: "Something went wrong",
    //         })
    //       );
    //       return null;
    //     });
    // }

    // if (!uploadedDocs) return; // if upload failed

    // if (isUpdateDrawer) {
    //   if (!editVendor) return;
    //   updateVendor({
    //     vendorId: editVendor.id.toString(),
    //     updateVendorDto: {
    //       updatedById: user.id.toString(),
    //       name: data.name,
    //       contactName: data.contactName,
    //       contactNo: data.telNo,
    //       companyWebsite: data.companyWebSite ?? "",
    //       remarks: data.remark ?? "",
    //       businessAddress: data.businessAddress ?? "",
    //       workScopeIds: data.workScope.map((ws) => ws.id),
    //       attachments: uploadedDocs,
    //       companyId: activeComp.id,
    //     },
    //   });
    // } else {
    //   createVendor({
    //     createVendorDto: {
    //       createdById: user.id,
    //       name: data.name,
    //       contactName: data.contactName,
    //       contactNo: data.telNo,
    //       companyWebsite: data.companyWebSite ?? "",
    //       remarks: data.remark ?? "",
    //       businessAddress: data.businessAddress ?? "",
    //       workScopeIds: data.workScope.map((ws) => ws.id),
    //       attachments: uploadedDocs,
    //       companyId: activeComp.id,
    //     },
    //   });
    // }
  };

  // is Edit Mode
  useEffect(() => {
    if (isUpdateDrawer && editVendor) {
      methods.setValue("name", editVendor?.data?.name ?? "");
      methods.setValue("contactName", editVendor?.data?.contactName ?? "");
      methods.setValue("telNo", editVendor?.data?.contactNo ?? "");
      methods.setValue(
        "companyWebSite",
        editVendor?.data?.companyWebsite ?? ""
      );
      methods.setValue(
        "businessAddress",
        editVendor?.data?.businessAddress ?? ""
      );
      methods.setValue("remark", editVendor?.data?.remarks ?? "");
      methods.setValue(
        "workScope",
        editVendor?.data?.workscope.map((ws) => ({
          id: ws.id,
          name: ws.name,
        })) ?? []
      );
      setUploadedDocs(editVendor?.data?.attachments ?? []);
    }
  }, [isUpdateDrawer, editVendor]);

  if (editVendorIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Vendor
        </p>
        <AppTextField label="Name" name="name" />
        <AppSelectWithDialog
          multiple
          columns={workScopeColumns}
          label="Work Scope"
          placeholder="Select Workscopes"
          defaultValue={[]}
          control={methods.control}
          name="workScope"
          items={workScopes?.data ?? []}
          onResultRender={(item, idx) => {
            return <div key={idx}>{item?.name}</div>;
          }}
          onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
          dialogTitle="Select WorkScope"
        />
        <AppTextField label="Contact Name" name="contactName" />
        <AppTextField label="Telephone No." name="telNo" />
        <AppTextField label="Company Website" name="companyWebSite" />
        <AppTextAreaField label="Remarks" name="remark" />
        <AppTextField label="Business Address" name="businessAddress" />
        <Controller
          control={methods.control}
          name="additionalDocuments"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUpload
                uploadedDocs={uploadedDocs}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Additional Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.additionalDocuments?.message !== ""
                    ? "At least one file required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex">
          <AppButton
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isPending}
          />
        </div>
      </div>
    </FormProvider>
  );
}
