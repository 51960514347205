import { Controller, useFormContext } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import {
  AddPmTemplateDtoRepeatType,
  FrequencySettingFrequencySettingType,
} from "../../../../api/model";
import { cn } from "../../../../utils/util";

interface AppCronRepeatTypeProps {
  name: string;
}

export default function AppCronRepeatType({ name }: AppCronRepeatTypeProps) {
  const methods = useFormContext();

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, value } }) => {
        return (
          <Select
            value={value}
            onValueChange={onChange}
          >
            <SelectTrigger className="w-full truncate h-12">
              <SelectValue
                className={cn(
                  `
                          font-sans 
                          text-neutral-50 
                          transition-all 
                          text-xs
                          w-full
                          pl-0
                          text-left
                          duration-300`
                )}
              ></SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                value={FrequencySettingFrequencySettingType.DAILY}
                key={AddPmTemplateDtoRepeatType.DAILY}
              >
                Daily
              </SelectItem>
              <SelectItem
                value={FrequencySettingFrequencySettingType.WEEKLY}
                key={AddPmTemplateDtoRepeatType.WEEKLY}
              >
                Weekly
              </SelectItem>
              <SelectItem
                value={FrequencySettingFrequencySettingType.BY_MONTH}
                key={AddPmTemplateDtoRepeatType.MONTHLY}
              >
                By Month / Or months
              </SelectItem>
              <SelectItem
                value={FrequencySettingFrequencySettingType.BY_YEAR}
                key={AddPmTemplateDtoRepeatType.YEARLY}
              >
                By Year / Or years
              </SelectItem>
            </SelectContent>
          </Select>
        );
      }}
    />
  );
}
