import { PDFViewer, Document, Page, Text } from "@react-pdf/renderer";
import { Card } from "../../components/ui/card";
import {
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/ui/resizable";
import AppTitle from "../HomeScreen/components/AppTitle";
import { Filter } from "lucide-react";
import WorkOrderPdfReportViewer from "./components/pdfs/WorkOrderPdfReportViewer";
import FilterSection from "./components/FilterSection";
import ReportSection from "./components/ReportSection/ReportSection";
import ReportPickerSection from "./components/ReportPickerSection/ReportPickerSection";

export default function PdfReportScreen() {
  return (
    <div className="h-full flex-grow flex flex-col">
      <AppTitle title="PDF Reports" />
      <ResizablePanelGroup
        direction="horizontal"
        className="items-stretch w-full flex-grow"
      >
        <ResizablePanel defaultSize={15} className="max-w-[288px]">
          <ResizablePanelGroup direction="vertical">
            <ResizablePanel defaultSize={50}>
              <ReportPickerSection />
            </ResizablePanel>
            <ResizablePanel defaultSize={50}>
              <FilterSection />
            </ResizablePanel>
          </ResizablePanelGroup>
        </ResizablePanel>
        <ResizablePanel defaultSize={50}>
          <ReportSection />
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
