import { Error } from "@mui/icons-material";
import { useEffect } from "react";
import { CerevModule } from "../../api/model";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { trpc } from "../../lib/trpc";
import { useGetAnalyticsQuery } from "../../redux/slices/Analytic/AnalyticApi";
import { SnackBarType, setOpenSnackBar } from "../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import AppIFrame from "./components/AppIframe";

export default function DashboardScreen() {
  const dispatch = useAppDispatch();
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "dashboardTabIdx",
    defaultValue: "0",
  });
  // const {currentTabIdx, setCurrentTabIdx} = useAppTabsNavigation({
  //   searchParamStr: "dashboardTabIdx",
  // });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data, isLoading } = trpc.analytics.getAnalyticsReport.useQuery(
    {
      projectId: activeProj?.id ?? 0,
    },
    {
      enabled: !!activeProj,
    }
  );

  if (!data || isLoading) return <div>Loading...</div>;

  if (data.length === 0)
    return (
      <>
        <AppTitle title="Dashboard" />
        <div className="h-full w-full flex justify-center items-center">
          <div className="flex flex-col items-center">
            <Error className="text-neutral-500 w-20 h-20" />
            <p className="text-neutral-500 mt-4">
              Unfortunately, there is no analytics setup for this project
            </p>
          </div>
        </div>
      </>
    );

  return (
    <ModuleAccessGuard module={CerevModule.DASHBOARD}>
      <AppTitle title="Dashboard" />
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <TabsList>
          {data.map((an, idx) => (
            <TabsTrigger key={an.iframeUrl} value={idx.toString()}>
              <p>{an.settings.tabName}</p>
            </TabsTrigger>
          ))}
        </TabsList>

        {data.map((an, idx) => (
          <TabsContent key={an.iframeUrl} value={idx.toString()}>
            <AppIFrame
              analyticRes={an}
              tabIdx={Number(currentTabIdx)}
              idx={idx}
              key={an.iframeUrl}
            />
          </TabsContent>
        ))}
      </Tabs>
    </ModuleAccessGuard>
  );
}
