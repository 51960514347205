import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import { useAuthControllerForgotPassword } from "../../../api/auth/auth";
import { toast } from "sonner";

const forgotPasswordSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
});

type ForgotPasswordFormProp = z.infer<typeof forgotPasswordSchema>;

export default function ForgotPasswordText() {
  const [open, setOpen] = useState(false);
  const methods = useForm<ForgotPasswordFormProp>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const { mutate, isPending } = useAuthControllerForgotPassword({
    mutation: {
      onSuccess: () => {
        methods.reset();
        toast.success(
          "Email sent, please check your email for the reset password link"
        );
        setOpen(false);
      },
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormProp> = (data) => {
    mutate({
      params: {
        email: data.email,
      },
    });
  };

  return (
    <>
      <p
        className="text-end text-sm text-slate-500 hover:cursor-pointer mt-2"
        onClick={() => setOpen(true)}
      >
        Forgot Password
      </p>
      <Dialog open={open} onOpenChange={(o) => setOpen(o)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Forgot Your password?</DialogTitle>
            <DialogDescription>
              Please input the email you have used to login to Cerev CMMS
            </DialogDescription>
          </DialogHeader>
          <FormProvider {...methods}>
            <AppTextField name="email" placeholder="cerev@cerev.net" />
          </FormProvider>
          <DialogFooter>
            <AppButton
              label="Submit"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
