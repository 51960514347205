import { useState } from "react";
import AppButton from "../../../AppButton";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AppTextField from "../../../AppTextField";
import {
  useWorkRequestControllerDeleteWorkRequestMutation,
  useWorkRequestControllerGetOneWorkRequestQuery,
} from "../../../../redux/slices/OpenApi/cerevApi";
import { useSearchParams } from "react-router-dom";
import useError from "../../../../hooks/useError";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../ui/dialog";
import { Ban, Loader2 } from "lucide-react";
import AppTextAreaField from "../../../AppTextAreaField";
import { DialogDescription } from "@radix-ui/react-dialog";
import { Button } from "../../../ui/button";
import {
  useWorkRequestControllerDeleteWorkRequest,
  useWorkRequestControllerGetOneWorkRequest,
} from "../../../../api/work-request/work-request";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "../../../../lib/utils";

export default function WorkRequestCancelDialogBtn() {
  const qc = useQueryClient();
  const [searchParam, setSearchParams] = useSearchParams();
  const workRequestId = searchParam.get("workRequestId");
  const { data: workRequest } = useWorkRequestControllerGetOneWorkRequest(
    workRequestId ?? "",
    {
      query: {
        enabled: !!workRequestId,
        select: (res) => res.data,
      },
    }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<{ cancellationRemark: string }>();

  // const [
  //   cancelWorkRequest,
  //   {
  //     isSuccess: cancelWorkRequestIsSuccess,
  //     isError: cancelWorkRequestIsError,
  //     isLoading: cancelWorkRequestIsLoading,
  //     error: cancelWorkRequestError,
  //   },
  // ] = useWorkRequestControllerDeleteWorkRequestMutation();

  const { mutate: cancelWorkRequest, isPending: cancelWorkRequestIsLoading } =
    useWorkRequestControllerDeleteWorkRequest({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("work-request"),
          });
        },
      },
    });

  const onSubmit: SubmitHandler<{ cancellationRemark: string }> = async (
    data
  ) => {
    setIsLoading(true);
    if (!workRequest) return;
    try {
      await cancelWorkRequest({
        workRequestCode: workRequest.trackingCode,
        data: {
          cancellationRemark: data.cancellationRemark,
        },
      });
    } catch (e) {
      setIsLoading(false);
    }
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        onOpenChange={(v) => {
          setIsOpen(v);
        }}
        open={isOpen}
      >
        <DialogTrigger asChild>
          <Button
            // icon={<Ban />}
            onClick={(e) => {
              setIsOpen(true);
            }}
            variant="destructive"
            className={cn({
              "bg-slate-200": cancelWorkRequestIsLoading,
            })}
          >
            {cancelWorkRequestIsLoading ? (
              <Loader2 className="h-6 w-6 animate-spin text-slate-800" />
            ) : (
              "Cancel"
            )}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancelling work request</DialogTitle>
            <DialogDescription>
              Please input your cancellation reason.
            </DialogDescription>
          </DialogHeader>
          <FormProvider {...methods}>
            <div className="flex flex-col gap-4">
              <AppTextAreaField
                label="Reason"
                name="cancellationRemark"
                placeholder="Please input your reason."
              />
              <div className="flex flex-row-reverse">
                <AppButton
                  isLoading={cancelWorkRequestIsLoading}
                  label="Confirm"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
