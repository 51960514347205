import { Delete } from "@mui/icons-material";
import {
  GetOneWorkOrderResponseDto,
  StockLedgerWithStockType,
} from "../../../../../../redux/slices/OpenApi/cerevApi";
import { useWorkOrderV2ControllerDeleteInventoryMutation } from "../../../../../../redux/slices/WorkOrder/WorkOrderApi";
import useIsCloseWorkOrder from "../../../../../../hooks/useIsCloseWorkOrder";
import AppButton from "../../../../../AppButton";
import { Loader2 } from "lucide-react";
import { useWorkOrderV2ControllerDeleteInventory } from "../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import { useQueryClient } from "@tanstack/react-query";

interface InventoryUsageItemProps {
  st: StockLedgerWithStockType;
  workOrder: GetOneWorkOrderResponseDto;
}

export default function InventoryUsageItem({
  st,
  workOrder,
}: InventoryUsageItemProps) {
  const qc = useQueryClient();
  const isClose = useIsCloseWorkOrder({ workOrder });
  const { mutate: deleteInventory, isPending: deleteInventoryIsPending } =
    useWorkOrderV2ControllerDeleteInventory({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) => {
              return (
                (query.queryKey[0] as any).includes("comment") ||
                (query.queryKey[0] as any).includes("/defect/") // This is to ensure that the outer defect tables aren't triggering unnecessary refetching
              );
            },
          });
        },
      },
    });

  const handleDelete = () => {
    if (!workOrder) return;
    deleteInventory({
      workOrderId: workOrder.id?.toString() ?? "",
      data: {
        workOrderId: workOrder.id ?? 0,
        ledgerIds:
          workOrder?.stockLedgers
            ?.filter((t) => t.stock.id === st.stock.id)
            .map((sl) => sl.id) ?? [],
      },
    });
  };

  return (
    <div className="p-4 bg-neutral-300 rounded-xl flex justify-between items-center">
      <div className="flex flex-col">
        <p className="font-bold">{st.stock.name}</p>
        <p className="font-sans text-neutral-700 fonts-sm">
          {st.stock.description?.length !== 0
            ? st.stock.description
            : st.stock.stockType?.name}
        </p>
        <p className="text-primary-900 font-bold">{-st.adjustmentQty} nos.</p>
      </div>
      {!isClose &&
        (deleteInventoryIsPending ? (
          <Loader2 className="h-6 w-6 animate-spin text-red-600" />
        ) : (
          <AppButton color="warning" onClick={handleDelete} icon={<Delete />} />
        ))}
    </div>
  );
}
