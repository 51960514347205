import {
  Building,
  ChevronLeft,
  CircleDollarSign,
  Hash,
  MoveRight,
  Pen,
  PersonStanding,
  Trash2,
} from "lucide-react";
import AppButton from "../../../../../components/AppButton";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AppTitle from "../../../../HomeScreen/components/AppTitle";
import { useCompaniesControllerGetOneCompany } from "../../../../../api/companies/companies";
import AppItemInfo from "../../../../../components/AppItemInfo";
import ProjectSection from "./components/ProjectSection";
import {
  COMPANY_FORM_DRAWER,
  DRAWER_UPDATE_STATE,
} from "../../../../../components/drawers/AppDrawer";
import { Badge } from "../../../../../components/ui/badge";
import { cn } from "../../../../../lib/utils";

export default function CompanyDetailScreen() {
  const nav = useNavigate();
  const { companyId } = useParams();
  const [, setSearchParams] = useSearchParams();

  const { data: companyData } = useCompaniesControllerGetOneCompany(
    companyId as string,
    {
      query: {
        enabled: !!companyId,
        select: (res) => res.data,
      },
    }
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <AppButton
          icon={<ChevronLeft />}
          className="text-slate-800 bg-slate-50 hover:bg-slate-200"
          label="Go back"
          onClick={() => {
            nav("/home/super-admin");
          }}
        />
      </div>
      <div className="flex gap-6">
        <div>
          <AppTitle title={companyData?.name ?? "-"} className="mb-1"/>
          <Badge
            className={cn("bg-slate-400", {
              "bg-green-500": companyData?.environmentType === "PRODUCTION",
            })}
          >
            {companyData?.environmentType ?? "-"}
          </Badge>
        </div>
        <AppButton
          label="Edit"
          variant="outline"
          icon={<Pen />}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", COMPANY_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("compId", companyId as string);
              return p;
            });
          }}
        />
        {/* <AppButton label="Delete" variant="destructive" icon={<Trash2 />} /> */}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <AppItemInfo
          label="Name"
          icon={<Building />}
          content={companyData?.name ?? "-"}
        />
        <AppItemInfo
          label="Code"
          icon={<Hash />}
          content={companyData?.code ?? "-"}
        />
        <AppItemInfo
          label="Registration No"
          icon={<Hash />}
          content={companyData?.registrationNo ?? "-"}
        />
        {companyData?.urlReference && (
          <AppItemInfo
            label="Bukku Contact"
            icon={<CircleDollarSign />}
            content={
              <div className="flex">
                <AppButton
                  variant="outline"
                  icon={<MoveRight />}
                  label="View In Bukku"
                  onClick={() => {
                    window.open(companyData?.urlReference, "__blank");
                  }}
                />
              </div>
            }
          />
        )}
      </div>
      <ProjectSection />
    </div>
  );
}
