import { isAfter, isEqual } from "date-fns";
import { z } from "zod";

export const manHourSettingSchema = z.object({
  mon: z
    .object({
      monNormStart: z.coerce.date({ required_error: "Start date is required" }),
      monNormEnd: z.coerce.date({ required_error: "End date is required" }),
    })
    .refine(
      (data) =>
        isAfter(data.monNormEnd, data.monNormStart) ||
        isEqual(data.monNormStart, data.monNormEnd),
      {
        message: "Ending <h2></h2>our should be greater than starting hour.",
        path: ["monNormEnd"],
      }
    ),
  tue: z
    .object({
      tueNormStart: z.coerce.date({ required_error: "Start date is required" }),
      tueNormEnd: z.coerce.date({ required_error: "End date is required" }),
    })
    .refine(
      (data) =>
        isAfter(data.tueNormEnd, data.tueNormStart) ||
        isEqual(data.tueNormStart, data.tueNormEnd),
      {
        message: "Ending hour should be greater than starting hour.",
        path: ["tueNormEnd"],
      }
    ),

  wed: z
    .object({
      wedNormStart: z.coerce.date({ required_error: "Start date is required" }),
      wedNormEnd: z.coerce.date({ required_error: "End date is required" }),
    })
    .refine(
      (data) =>
        isAfter(data.wedNormEnd, data.wedNormStart) ||
        isEqual(data.wedNormStart, data.wedNormEnd),
      {
        message: "Ending hour should be greater than starting hour.",
        path: ["wedNormEnd"],
      }
    ),

  thu: z
    .object({
      thuNormStart: z.coerce.date({ required_error: "Start date is required" }),
      thuNormEnd: z.coerce.date({ required_error: "End date is required" }),
    })
    .refine(
      (data) =>
        isAfter(data.thuNormEnd, data.thuNormStart) ||
        isEqual(data.thuNormStart, data.thuNormEnd),
      {
        message: "Ending hour should be greater than starting hour.",
        path: ["thuNormEnd"],
      }
    ),

  fri: z
    .object({
      friNormStart: z.coerce.date({ required_error: "Start date is required" }),
      friNormEnd: z.coerce.date({ required_error: "End date is required" }),
    })  
    .refine(
      (data) =>
        isAfter(data.friNormEnd, data.friNormStart) ||
        isEqual(data.friNormStart, data.friNormEnd),
      {
        message: "Ending hour should be greater than starting hour.",
        path: ["friNormEnd"],
      }
    ),

  sat: z
    .object({
      satNormStart: z.coerce.date({ required_error: "Start date is required" }),
      satNormEnd: z.coerce.date({ required_error: "End date is required" }),
    })
    .refine(
      (data) =>
        isAfter(data.satNormEnd, data.satNormStart) ||
        isEqual(data.satNormStart, data.satNormEnd),
      {
        message: "Ending hour should be greater than starting hour.",
        path: ["satNormEnd"],
      }
    ),

  sun: z
    .object({
      sunNormStart: z.coerce.date({ required_error: "Start date is required" }),
      sunNormEnd: z.coerce.date({ required_error: "End date is required" }),
    })
    .refine(
      (data) =>
        isAfter(data.sunNormEnd, data.sunNormStart) ||
        isEqual(data.sunNormStart, data.sunNormEnd),
      {
        message: "Ending hour should be greater than starting hour.",
        path: ["sunNormEnd"],
      }
    ),

  normalRate: z.coerce.number(),
  overtimeRate: z.coerce.number(),
});

export type ManHourSettingSchemaType = z.infer<typeof manHourSettingSchema>;

// It doesn't matter the date, what is most important is the time, it is put in date structure
// to allow system to detect if end hour is greater than start hour
export const manHourSettingDefaultValues = {
  mon: {
    monNormStart: new Date("2024-01-01T09:00:00"),
    monNormEnd: new Date("2024-01-01T18:00:00"),
  },
  tue: {
    tueNormStart: new Date("2024-01-01T09:00:00"),
    tueNormEnd: new Date("2024-01-01T18:00:00"),
  },
  wed: {
    wedNormStart: new Date("2024-01-01T09:00:00"),
    wedNormEnd: new Date("2024-01-01T18:00:00"),
  },
  thu: {
    thuNormStart: new Date("2024-01-01T09:00:00"),
    thuNormEnd: new Date("2024-01-01T18:00:00"),
  },
  fri: {
    friNormStart: new Date("2024-01-01T09:00:00"),
    friNormEnd: new Date("2024-01-01T18:00:00"),
  },
  sat: {
    satNormStart: new Date("2024-01-01T00:00:00"),
    satNormEnd: new Date("2024-01-01T00:00:00"),
  },
  sun: {
    sunNormStart: new Date("2024-01-01T00:00:00"),
    sunNormEnd: new Date("2024-01-01T00:00:00"),
  },
  normalRate: 0,
  overtimeRate: 0,
} as ManHourSettingSchemaType;
