import { useEffect, useRef } from "react";
import { Progress } from "../ui/progress";
import { Skeleton } from "../ui/skeleton";
import { cn } from "../../lib/utils";

interface AppProgressProps {
  isLoading?: boolean;
}

export default function AppProgress({ isLoading }: AppProgressProps) {
  return (
    <Skeleton
      className={cn(
        "h-1 w-full bg-primary-900 transition-all ease-in-out duration-300 ",
        {
          "bg-transparent": !isLoading,
        }
      )}
    ></Skeleton>
  );
}
