import { useQueryClient } from "@tanstack/react-query";
import { usePtwControllerApprovePtw } from "../../../../../api/permit-to-work-adv/permit-to-work-adv";
import AppButton from "../../../../AppButton";
import { useSearchParams } from "react-router-dom";

export default function PtwApproveBtn() {
  const [searchParams] = useSearchParams();
  const ptwId = searchParams.get("ptwId");
  const qc = useQueryClient();

  const { mutate, isPending } = usePtwControllerApprovePtw({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("permit-to-work"),
        });
      },
    },
  });

  return (
    <AppButton
      label="Approve"
      isLoading={isPending}
      onClick={() => {
        mutate({
          ptwId: ptwId as string,
        });
      }}
    />
  );
}
