import { ColumnDef } from "@tanstack/react-table";

export const inventorySerialColumns: ColumnDef<{
  id: string;
  name: string;
  unitPricing?: number;
}>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "name",
    header: "Serial Number",
  },
  {
    accessorKey: "unitPricing",
    header: "Unit Price",
  },
];
