import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { useStocksControllerGetOneStockWithLedger } from "@/api/stocks-inventory/stocks-inventory";
import { useSearchParams } from "react-router-dom";
import Decimal from "decimal.js";
import AppTextField from "@/components/AppTextField";

export default function AmountChangeField() {
  const [searchParams] = useSearchParams();
const inventoryId = searchParams.get("inventoryId");
  const methods = useFormContext();
  const newBalance = useWatch({ control: methods.control, name: "newBalance" });

  const { data: inventory } = useStocksControllerGetOneStockWithLedger(
    inventoryId ?? "",
    {
      query: {
        enabled: !!inventoryId,
        select: (res) => res.data
      }
    }
  );

  useEffect(() => {
    if(newBalance === "")
      return methods.setValue("amountChange", new Decimal(0));
    methods.setValue("amountChange", new Decimal(newBalance ?? 0).minus(new Decimal(inventory?.balance ?? 0)).toNumber());
  }, [newBalance]);

  return (
    <AppTextField name={`amountChange`} label={`Amount Changed ( - / + ) nos.`} disabled/>
  )
}