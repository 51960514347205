export default function EmptyIcon() {
  return (
    <svg
      width="246"
      height="189"
      viewBox="0 0 246 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_57_359)">
        <path
          d="M238.318 53.359L158.414 10.6442C152.461 7.46167 145.054 9.70543 141.869 15.6558L84.0246 123.753C80.8405 129.703 83.0854 137.107 89.0387 140.289L168.943 183.004C174.896 186.187 182.303 183.943 185.487 177.993L243.332 69.8955C246.516 63.9452 244.271 56.5415 238.318 53.359Z"
          fill="#F1F3F8"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M85.8299 13.5095L7.35526 58.7939C1.50838 62.1679 -0.494905 69.6405 2.88079 75.4844L64.2054 181.648C67.5811 187.492 75.0574 189.494 80.9043 186.12L159.379 140.836C165.226 137.462 167.229 129.989 163.853 124.145L102.529 17.9817C99.1531 12.1378 91.6767 10.1355 85.8299 13.5095Z"
          fill="#F1F3F8"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M35.9141 101.566L105.175 63.1787"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M43.9454 116.029L113.198 77.6411"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M51.9684 130.491L121.221 92.1033"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M59.9916 144.953L129.244 106.566"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M68.0146 159.415L137.267 121.028"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M169.55 1.23755H78.9349C72.1835 1.23755 66.7104 6.70787 66.7104 13.4558V136.043C66.7104 142.791 72.1835 148.261 78.9349 148.261H169.55C176.301 148.261 181.774 142.791 181.774 136.043V13.4558C181.774 6.70787 176.301 1.23755 169.55 1.23755Z"
          fill="#F1F3F8"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M112.653 27.7778L135.839 47.9987"
          stroke="#CDCDCD"
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M135.839 27.7778L112.653 47.9987"
          stroke="#CDCDCD"
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.0546 65.2742H163.656"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M126.859 82.5415H148.782"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.0546 82.5415H110.969"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.0546 99.8171H136.681"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.0546 117.093H104.432"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M110.969 117.093H120.239"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M175.848 168.779C198.217 168.779 216.351 150.654 216.351 128.296C216.351 105.938 198.217 87.8135 175.848 87.8135C153.478 87.8135 135.344 105.938 135.344 128.296C135.344 150.654 153.478 168.779 175.848 168.779Z"
          fill="#F1F3F8"
        />
        <path
          d="M175.848 164.918C196.083 164.918 212.488 148.522 212.488 128.296C212.488 108.07 196.083 91.6743 175.848 91.6743C155.612 91.6743 139.207 108.07 139.207 128.296C139.207 148.522 155.612 164.918 175.848 164.918Z"
          fill="#99B5BB"
        />
        <path
          d="M175.848 164.918C196.083 164.918 212.488 148.522 212.488 128.296C212.488 108.07 196.083 91.6743 175.848 91.6743C155.612 91.6743 139.207 108.07 139.207 128.296C139.207 148.522 155.612 164.918 175.848 164.918Z"
          fill="#99B5BB"
        />
        <path
          d="M187.602 137L218.802 168.185"
          stroke="#99B5BB"
          strokeWidth="18"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M175.848 151.215C188.512 151.215 198.778 140.954 198.778 128.296C198.778 115.639 188.512 105.378 175.848 105.378C163.184 105.378 152.917 115.639 152.917 128.296C152.917 140.954 163.184 151.215 175.848 151.215Z"
          fill="#F1F3F8"
          stroke="#CDCDCD"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M187.635 115.203C185.438 112.576 182.435 110.746 179.091 109.998C175.157 109.198 171.066 109.927 167.651 112.035"
          stroke="#CDCDCD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M192.207 121.226L191.151 119.353C191 119.121 190.864 118.878 190.746 118.627"
          stroke="#CDCDCD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M29.9957 81.0319C33.4649 81.0319 36.2772 78.221 36.2772 74.7536C36.2772 71.2862 33.4649 68.4753 29.9957 68.4753C26.5266 68.4753 23.7143 71.2862 23.7143 74.7536C23.7143 78.221 26.5266 81.0319 29.9957 81.0319Z"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_57_359">
          <rect width="246" height="189" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
