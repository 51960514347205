import { create } from "zustand";
import { StockType } from "../../api/model";

interface InventoryFilterState {
  inventoryType: StockType | undefined | null;
  setInventoryType: (inventoryTypes: StockType) => void;
  getFilterCount: () => number;
  cleanUp: () => void;
}

export const useInventoryFilterStore = create<InventoryFilterState>(
  (set, get) => ({
    inventoryType: null,
    setInventoryType: (inventoryType) => set(() => ({ inventoryType })),
    getFilterCount: () => {
      let filterCount = 0;
      if (get().inventoryType) filterCount++;
      return filterCount;
    },
    cleanUp: () => set(() => ({ inventoryType: null })),
  })
);
