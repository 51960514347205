import { zodResolver } from "@hookform/resolvers/zod";
import { format, parse, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import useIsUpdateDrawerState from "../../hooks/useDrawerState";
import useError from "../../hooks/useError";
import useSuccess from "../../hooks/useSuccess";
import { useUploadAttachmentMutation } from "../../redux/slices/Attachment/AttachmentApi";
import { useGetUserDataQuery } from "../../redux/slices/Auth/AuthApi";
import { SnackBarType, setOpenSnackBar } from "../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AppButton from "../AppButton";
import AppDocumentUpload from "../AppDocumentUpload";
import AppTextAreaField from "../AppTextAreaField";
import AppTextField from "../AppTextField";
import ConfirmDeleteBtnWithDialog from "../dialogs/ConfirmDeleteWithTextDialog";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import { Attachment, AttachmentProjectType } from "../../api/model";
import {
  useLegalComplianceControllerCreateLegalCompliance,
  useLegalComplianceControllerDeleteAttachment,
  useLegalComplianceControllerDeleteLegalCompliance,
  useLegalComplianceControllerGetOneLegalCompliance,
  useLegalComplianceControllerUpdateLegalCompliance,
} from "../../api/legal-compliance/legal-compliance";
import { useAuthControllerGetCurrentUser } from "../../api/auth/auth";
import { useQueryClient } from "@tanstack/react-query";
import useAppStorage from "../../hooks/useAppStorage";

// TODO: TO CONTINUE UPDATE LEGAL COMPLIANCE FORM TO REACT QUERY
const LegalComplianceFormSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  description: z.string().optional(),
  expiryDate: z.string({ required_error: "Expiry date is required" }),
  legalDocuments: z.instanceof(File).array().optional(),
});

export type LegalComplianceForm = z.infer<typeof LegalComplianceFormSchema>;

export default function LegalComplianceFormDrawer() {
  const qc = useQueryClient();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const legalComplianceId = searchParams.get("legalComplianceId");
  const [uploadedDoc, setUploadedDoc] = useState<Attachment[]>([]);
  const dispatch = useAppDispatch();
  const methods = useForm<LegalComplianceForm>({
    resolver: zodResolver(LegalComplianceFormSchema),
    defaultValues: {
      name: "",
      description: "",
      expiryDate: format(new Date(), "yyyy-MM-dd"),
      legalDocuments: [],
    },
  });
  const { data: user } = useAuthControllerGetCurrentUser();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { mutateAsync: updateLegalCompliance } =
    useLegalComplianceControllerUpdateLegalCompliance();

  const { mutateAsync: createLegalCompliance } =
    useLegalComplianceControllerCreateLegalCompliance({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("legal"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });

  const { mutateAsync: deleteDocument } =
    useLegalComplianceControllerDeleteAttachment();

  const { data: editLegalCompliance, isLoading: editLegalComplianceIsLoading } =
    useLegalComplianceControllerGetOneLegalCompliance(
      legalComplianceId ? +legalComplianceId : 0,
      {
        query: {
          enabled: !!legalComplianceId || !!isUpdateDrawer,
        },
      }
    );

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDoc.filter((v) => v.id !== att.id);
    if (!editLegalCompliance || !att.id) return;
    deleteDocument({
      documentId: att.id,
      legalComplianceId: editLegalCompliance?.data?.id,
    });
    setUploadedDoc(newUploadedDocs);
  };

  const { useUploadAttachmentMutation } = useAppStorage();

  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => (query.queryKey[0] as string).includes("legal"),
      });
      setSearchParams(new URLSearchParams());
    },
  });

  const { mutateAsync: deleteLegalCompliance } =
    useLegalComplianceControllerDeleteLegalCompliance({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("legal"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });

  // is edit mode
  useEffect(() => {
    if (editLegalCompliance && isUpdateDrawer) {
      methods.setValue("name", editLegalCompliance?.data?.name);
      methods.setValue("description", editLegalCompliance?.data?.description);
      methods.setValue(
        "expiryDate",
        format(parseISO(editLegalCompliance?.data?.expiryDate), "yyyy-MM-dd")
      );
      setUploadedDoc(editLegalCompliance?.data?.documents);
    }
  }, [editLegalCompliance, searchParams]);

  const onSubmit: SubmitHandler<LegalComplianceForm> = async (data) => {
    mutate({
      files: data.legalDocuments ?? [],
      mutateAsync: async (atts) => {
        if (isUpdateDrawer) {
          await updateLegalCompliance({
            legalComplianceId: editLegalCompliance?.data?.id ?? 0,
            data: {
              name: data.name,
              description: data.description ?? "",
              expiryDate: parse(
                data.expiryDate,
                "yyyy-MM-dd",
                new Date()
              ).toISOString(),
              documents: atts,
              projectId: activeProj?.id,
            },
          });
        } else {
          await createLegalCompliance({
            data: {
              name: data.name,
              description: data.description ?? "",
              expiryDate: parse(
                data.expiryDate,
                "yyyy-MM-dd",
                new Date()
              ).toISOString(),
              documents: atts,
              projectId: activeProj?.id,
              createdById: user?.data?.id ?? 0,
            },
          });
        }
      },
    });
    // let uploadedDocs = [] as AttachmentProjectType[] | null;
    // if (!activeProj) return;

    // if (data.legalDocuments) {
    //   uploadedDocs = await uploadAttachments(data.legalDocuments)
    //     .unwrap()
    //     .catch((e) => {
    //       dispatch(
    //         setOpenSnackBar({
    //           type: SnackBarType.Error,
    //           msg: "Something went wrong",
    //         })
    //       );
    //       return null;
    //     });
    // }

    // if (!uploadedDocs || !user) return; // Means there is an error

    // if (isUpdateDrawer) {
    //   if (!editLegalCompliance) return;
    //   updateLegalCompliance({
    //     legalComplianceId: editLegalCompliance?.data?.id,
    //     data: {
    //       name: data.name,
    //       description: data.description ?? "",
    //       expiryDate: parse(
    //         data.expiryDate,
    //         "yyyy-MM-dd",
    //         new Date()
    //       ).toISOString(),
    //       documents: uploadedDocs,
    //     },
    //   });
    // } else {
    //   createLegalCompliance({
    //     data: {
    //       name: data.name,
    //       description: data.description ?? "",
    //       expiryDate: parse(
    //         data.expiryDate,
    //         "yyyy-MM-dd",
    //         new Date()
    //       ).toISOString(),
    //       documents: uploadedDocs,
    //       projectId: activeProj.id,
    //       createdById: user?.data?.id ?? 0,
    //     },
    //   });
    // }
  };

  if (editLegalComplianceIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Legal Compliance
        </p>
        <AppTextField label="Name" name="name" />
        <AppTextAreaField label="Description" name="description" />
        <AppTextField label="Expiry Date" name="expiryDate" type="date" />
        <Controller
          control={methods.control}
          name="legalDocuments"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUpload
                uploadedDocs={uploadedDoc}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newFiles = value.filter((v) => v !== url);
                  onChange(newFiles);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.legalDocuments?.message !== ""
                    ? "At least one file required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex gap-4">
          <AppButton
            isLoading={isPending}
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
          {isUpdateDrawer && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editLegalCompliance?.data?.name ?? "-"}
              onDeleteConfirm={async () => {
                if (editLegalCompliance) {
                  await deleteLegalCompliance({
                    legalComplianceId: editLegalCompliance?.data?.id,
                  });
                }
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
