/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AdjustUserQuotaRequestDto,
  FindAllProjectResponseDto,
  FindAllUsersResponseDto,
  FindOneProjectResponseDto,
  ProjectControllerFindAllProjectsParams,
  ProjectControllerFindAllUsersParams,
  ProjectControllerGetProjectsParams,
  ProjectWithAttachmentResponse,
  UpdateProjectPdfSettingRequestDto,
  UpdateSubscriptionTypeRequestDto,
} from ".././model";

export const projectControllerGetProjects = (
  params: ProjectControllerGetProjectsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProjectWithAttachmentResponse[]>> => {
  return axios.get(`/projects`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getProjectControllerGetProjectsQueryKey = (
  params: ProjectControllerGetProjectsParams
) => {
  return [`/projects`, ...(params ? [params] : [])] as const;
};

export const getProjectControllerGetProjectsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerGetProjects>>,
    ProjectControllerGetProjectsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: ProjectControllerGetProjectsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerGetProjects>>,
        TError,
        TData,
        Awaited<ReturnType<typeof projectControllerGetProjects>>,
        QueryKey,
        ProjectControllerGetProjectsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProjectControllerGetProjectsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerGetProjects>>,
    QueryKey,
    ProjectControllerGetProjectsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    projectControllerGetProjects(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof projectControllerGetProjects>>,
    TError,
    TData,
    Awaited<ReturnType<typeof projectControllerGetProjects>>,
    QueryKey,
    ProjectControllerGetProjectsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type ProjectControllerGetProjectsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerGetProjects>>
>;
export type ProjectControllerGetProjectsInfiniteQueryError =
  AxiosError<unknown>;

export const useProjectControllerGetProjectsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerGetProjects>>,
    ProjectControllerGetProjectsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: ProjectControllerGetProjectsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerGetProjects>>,
        TError,
        TData,
        Awaited<ReturnType<typeof projectControllerGetProjects>>,
        QueryKey,
        ProjectControllerGetProjectsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerGetProjectsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectControllerGetProjectsQueryOptions = <
  TData = Awaited<ReturnType<typeof projectControllerGetProjects>>,
  TError = AxiosError<unknown>
>(
  params: ProjectControllerGetProjectsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerGetProjects>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProjectControllerGetProjectsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerGetProjects>>
  > = ({ signal }) =>
    projectControllerGetProjects(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectControllerGetProjects>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProjectControllerGetProjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerGetProjects>>
>;
export type ProjectControllerGetProjectsQueryError = AxiosError<unknown>;

export const useProjectControllerGetProjects = <
  TData = Awaited<ReturnType<typeof projectControllerGetProjects>>,
  TError = AxiosError<unknown>
>(
  params: ProjectControllerGetProjectsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerGetProjects>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerGetProjectsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const projectControllerFindAllProjects = (
  params?: ProjectControllerFindAllProjectsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<FindAllProjectResponseDto>> => {
  return axios.get(`/projects/admin-all`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getProjectControllerFindAllProjectsQueryKey = (
  params?: ProjectControllerFindAllProjectsParams
) => {
  return [`/projects/admin-all`, ...(params ? [params] : [])] as const;
};

export const getProjectControllerFindAllProjectsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
    ProjectControllerFindAllProjectsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: ProjectControllerFindAllProjectsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
        TError,
        TData,
        Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
        QueryKey,
        ProjectControllerFindAllProjectsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectControllerFindAllProjectsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
    QueryKey,
    ProjectControllerFindAllProjectsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    projectControllerFindAllProjects(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
    TError,
    TData,
    Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
    QueryKey,
    ProjectControllerFindAllProjectsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type ProjectControllerFindAllProjectsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerFindAllProjects>>
>;
export type ProjectControllerFindAllProjectsInfiniteQueryError =
  AxiosError<unknown>;

export const useProjectControllerFindAllProjectsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
    ProjectControllerFindAllProjectsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: ProjectControllerFindAllProjectsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
        TError,
        TData,
        Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
        QueryKey,
        ProjectControllerFindAllProjectsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerFindAllProjectsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectControllerFindAllProjectsQueryOptions = <
  TData = Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
  TError = AxiosError<unknown>
>(
  params?: ProjectControllerFindAllProjectsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectControllerFindAllProjectsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerFindAllProjects>>
  > = ({ signal }) =>
    projectControllerFindAllProjects(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProjectControllerFindAllProjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerFindAllProjects>>
>;
export type ProjectControllerFindAllProjectsQueryError = AxiosError<unknown>;

export const useProjectControllerFindAllProjects = <
  TData = Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
  TError = AxiosError<unknown>
>(
  params?: ProjectControllerFindAllProjectsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllProjects>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerFindAllProjectsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const projectControllerFindOneProject = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<FindOneProjectResponseDto>> => {
  return axios.get(`/projects/${projectId}`, options);
};

export const getProjectControllerFindOneProjectQueryKey = (
  projectId: string
) => {
  return [`/projects/${projectId}`] as const;
};

export const getProjectControllerFindOneProjectInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerFindOneProject>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectControllerFindOneProjectQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerFindOneProject>>
  > = ({ signal }) =>
    projectControllerFindOneProject(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof projectControllerFindOneProject>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProjectControllerFindOneProjectInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerFindOneProject>>
>;
export type ProjectControllerFindOneProjectInfiniteQueryError =
  AxiosError<unknown>;

export const useProjectControllerFindOneProjectInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerFindOneProject>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerFindOneProjectInfiniteQueryOptions(
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectControllerFindOneProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof projectControllerFindOneProject>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectControllerFindOneProjectQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerFindOneProject>>
  > = ({ signal }) =>
    projectControllerFindOneProject(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof projectControllerFindOneProject>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProjectControllerFindOneProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerFindOneProject>>
>;
export type ProjectControllerFindOneProjectQueryError = AxiosError<unknown>;

export const useProjectControllerFindOneProject = <
  TData = Awaited<ReturnType<typeof projectControllerFindOneProject>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerFindOneProjectQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const projectControllerFindAllUsers = (
  projectId: string,
  params?: ProjectControllerFindAllUsersParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<FindAllUsersResponseDto>> => {
  return axios.get(`/projects/${projectId}/users`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getProjectControllerFindAllUsersQueryKey = (
  projectId: string,
  params?: ProjectControllerFindAllUsersParams
) => {
  return [`/projects/${projectId}/users`, ...(params ? [params] : [])] as const;
};

export const getProjectControllerFindAllUsersInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
    ProjectControllerFindAllUsersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params?: ProjectControllerFindAllUsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
        QueryKey,
        ProjectControllerFindAllUsersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectControllerFindAllUsersQueryKey(projectId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
    QueryKey,
    ProjectControllerFindAllUsersParams["cursorId"]
  > = ({ signal, pageParam }) =>
    projectControllerFindAllUsers(
      projectId,
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
    TError,
    TData,
    Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
    QueryKey,
    ProjectControllerFindAllUsersParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type ProjectControllerFindAllUsersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerFindAllUsers>>
>;
export type ProjectControllerFindAllUsersInfiniteQueryError =
  AxiosError<unknown>;

export const useProjectControllerFindAllUsersInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
    ProjectControllerFindAllUsersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params?: ProjectControllerFindAllUsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
        QueryKey,
        ProjectControllerFindAllUsersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerFindAllUsersInfiniteQueryOptions(
    projectId,
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectControllerFindAllUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params?: ProjectControllerFindAllUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectControllerFindAllUsersQueryKey(projectId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerFindAllUsers>>
  > = ({ signal }) =>
    projectControllerFindAllUsers(projectId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProjectControllerFindAllUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerFindAllUsers>>
>;
export type ProjectControllerFindAllUsersQueryError = AxiosError<unknown>;

export const useProjectControllerFindAllUsers = <
  TData = Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  params?: ProjectControllerFindAllUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof projectControllerFindAllUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectControllerFindAllUsersQueryOptions(
    projectId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const projectControllerGetCustomPdfLetterheadIsEnabled = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<boolean>> => {
  return axios.get(
    `/projects/${projectId}/pdf/letterheaed-pdf-setting`,
    options
  );
};

export const getProjectControllerGetCustomPdfLetterheadIsEnabledQueryKey = (
  projectId: string
) => {
  return [`/projects/${projectId}/pdf/letterheaed-pdf-setting`] as const;
};

export const getProjectControllerGetCustomPdfLetterheadIsEnabledInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
      >
    >,
    TError = AxiosError<unknown>
  >(
    projectId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getProjectControllerGetCustomPdfLetterheadIsEnabledQueryKey(projectId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
      >
    > = ({ signal }) =>
      projectControllerGetCustomPdfLetterheadIsEnabled(projectId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!projectId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ProjectControllerGetCustomPdfLetterheadIsEnabledInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>>
  >;
export type ProjectControllerGetCustomPdfLetterheadIsEnabledInfiniteQueryError =
  AxiosError<unknown>;

export const useProjectControllerGetCustomPdfLetterheadIsEnabledInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getProjectControllerGetCustomPdfLetterheadIsEnabledInfiniteQueryOptions(
      projectId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectControllerGetCustomPdfLetterheadIsEnabledQueryOptions = <
  TData = Awaited<
    ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProjectControllerGetCustomPdfLetterheadIsEnabledQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>>
  > = ({ signal }) =>
    projectControllerGetCustomPdfLetterheadIsEnabled(projectId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProjectControllerGetCustomPdfLetterheadIsEnabledQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>>
  >;
export type ProjectControllerGetCustomPdfLetterheadIsEnabledQueryError =
  AxiosError<unknown>;

export const useProjectControllerGetCustomPdfLetterheadIsEnabled = <
  TData = Awaited<
    ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof projectControllerGetCustomPdfLetterheadIsEnabled>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getProjectControllerGetCustomPdfLetterheadIsEnabledQueryOptions(
      projectId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const projectControllerGetCustomWorkOrderQrLogoIsEnabled = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<boolean>> => {
  return axios.get(
    `/projects/${projectId}/pdf/custom-work-order-qr-logo-setting`,
    options
  );
};

export const getProjectControllerGetCustomWorkOrderQrLogoIsEnabledQueryKey = (
  projectId: string
) => {
  return [
    `/projects/${projectId}/pdf/custom-work-order-qr-logo-setting`,
  ] as const;
};

export const getProjectControllerGetCustomWorkOrderQrLogoIsEnabledInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
      >
    >,
    TError = AxiosError<unknown>
  >(
    projectId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getProjectControllerGetCustomWorkOrderQrLogoIsEnabledQueryKey(projectId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
      >
    > = ({ signal }) =>
      projectControllerGetCustomWorkOrderQrLogoIsEnabled(projectId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!projectId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ProjectControllerGetCustomWorkOrderQrLogoIsEnabledInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
    >
  >;
export type ProjectControllerGetCustomWorkOrderQrLogoIsEnabledInfiniteQueryError =
  AxiosError<unknown>;

export const useProjectControllerGetCustomWorkOrderQrLogoIsEnabledInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
    >
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getProjectControllerGetCustomWorkOrderQrLogoIsEnabledInfiniteQueryOptions(
      projectId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectControllerGetCustomWorkOrderQrLogoIsEnabledQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
    >,
    TError = AxiosError<unknown>
  >(
    projectId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getProjectControllerGetCustomWorkOrderQrLogoIsEnabledQueryKey(projectId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
      >
    > = ({ signal }) =>
      projectControllerGetCustomWorkOrderQrLogoIsEnabled(projectId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!projectId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ProjectControllerGetCustomWorkOrderQrLogoIsEnabledQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
    >
  >;
export type ProjectControllerGetCustomWorkOrderQrLogoIsEnabledQueryError =
  AxiosError<unknown>;

export const useProjectControllerGetCustomWorkOrderQrLogoIsEnabled = <
  TData = Awaited<
    ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof projectControllerGetCustomWorkOrderQrLogoIsEnabled>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getProjectControllerGetCustomWorkOrderQrLogoIsEnabledQueryOptions(
      projectId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const projectControllerAdjustUserQuota = (
  projectId: string,
  adjustUserQuotaRequestDto: AdjustUserQuotaRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/projects/${projectId}/adjust-user-quota`,
    adjustUserQuotaRequestDto,
    options
  );
};

export const getProjectControllerAdjustUserQuotaMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerAdjustUserQuota>>,
    TError,
    { projectId: string; data: AdjustUserQuotaRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectControllerAdjustUserQuota>>,
  TError,
  { projectId: string; data: AdjustUserQuotaRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectControllerAdjustUserQuota>>,
    { projectId: string; data: AdjustUserQuotaRequestDto }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return projectControllerAdjustUserQuota(projectId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectControllerAdjustUserQuotaMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerAdjustUserQuota>>
>;
export type ProjectControllerAdjustUserQuotaMutationBody =
  AdjustUserQuotaRequestDto;
export type ProjectControllerAdjustUserQuotaMutationError = AxiosError<unknown>;

export const useProjectControllerAdjustUserQuota = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerAdjustUserQuota>>,
    TError,
    { projectId: string; data: AdjustUserQuotaRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectControllerAdjustUserQuota>>,
  TError,
  { projectId: string; data: AdjustUserQuotaRequestDto },
  TContext
> => {
  const mutationOptions =
    getProjectControllerAdjustUserQuotaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectControllerUpdateSubscriptionType = (
  projectId: string,
  updateSubscriptionTypeRequestDto: UpdateSubscriptionTypeRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/projects/${projectId}/update-subscription-type`,
    updateSubscriptionTypeRequestDto,
    options
  );
};

export const getProjectControllerUpdateSubscriptionTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerUpdateSubscriptionType>>,
    TError,
    { projectId: string; data: UpdateSubscriptionTypeRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectControllerUpdateSubscriptionType>>,
  TError,
  { projectId: string; data: UpdateSubscriptionTypeRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectControllerUpdateSubscriptionType>>,
    { projectId: string; data: UpdateSubscriptionTypeRequestDto }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return projectControllerUpdateSubscriptionType(
      projectId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectControllerUpdateSubscriptionTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerUpdateSubscriptionType>>
>;
export type ProjectControllerUpdateSubscriptionTypeMutationBody =
  UpdateSubscriptionTypeRequestDto;
export type ProjectControllerUpdateSubscriptionTypeMutationError =
  AxiosError<unknown>;

export const useProjectControllerUpdateSubscriptionType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerUpdateSubscriptionType>>,
    TError,
    { projectId: string; data: UpdateSubscriptionTypeRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectControllerUpdateSubscriptionType>>,
  TError,
  { projectId: string; data: UpdateSubscriptionTypeRequestDto },
  TContext
> => {
  const mutationOptions =
    getProjectControllerUpdateSubscriptionTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectControllerUpdateProjectPdfSettings = (
  projectId: string,
  updateProjectPdfSettingRequestDto: UpdateProjectPdfSettingRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/projects/${projectId}/update-project-logo-pdf`,
    updateProjectPdfSettingRequestDto,
    options
  );
};

export const getProjectControllerUpdateProjectPdfSettingsMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerUpdateProjectPdfSettings>>,
    TError,
    { projectId: string; data: UpdateProjectPdfSettingRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectControllerUpdateProjectPdfSettings>>,
  TError,
  { projectId: string; data: UpdateProjectPdfSettingRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectControllerUpdateProjectPdfSettings>>,
    { projectId: string; data: UpdateProjectPdfSettingRequestDto }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return projectControllerUpdateProjectPdfSettings(
      projectId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectControllerUpdateProjectPdfSettingsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof projectControllerUpdateProjectPdfSettings>>
  >;
export type ProjectControllerUpdateProjectPdfSettingsMutationBody =
  UpdateProjectPdfSettingRequestDto;
export type ProjectControllerUpdateProjectPdfSettingsMutationError =
  AxiosError<unknown>;

export const useProjectControllerUpdateProjectPdfSettings = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerUpdateProjectPdfSettings>>,
    TError,
    { projectId: string; data: UpdateProjectPdfSettingRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectControllerUpdateProjectPdfSettings>>,
  TError,
  { projectId: string; data: UpdateProjectPdfSettingRequestDto },
  TContext
> => {
  const mutationOptions =
    getProjectControllerUpdateProjectPdfSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectControllerToggleLetterheadPdfSetting = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/projects/${projectId}/pdf/toggle-letterhead-pdf-setting`,
    undefined,
    options
  );
};

export const getProjectControllerToggleLetterheadPdfSettingMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerToggleLetterheadPdfSetting>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectControllerToggleLetterheadPdfSetting>>,
  TError,
  { projectId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectControllerToggleLetterheadPdfSetting>>,
    { projectId: string }
  > = (props) => {
    const { projectId } = props ?? {};

    return projectControllerToggleLetterheadPdfSetting(projectId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectControllerToggleLetterheadPdfSettingMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof projectControllerToggleLetterheadPdfSetting>>
  >;

export type ProjectControllerToggleLetterheadPdfSettingMutationError =
  AxiosError<unknown>;

export const useProjectControllerToggleLetterheadPdfSetting = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerToggleLetterheadPdfSetting>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectControllerToggleLetterheadPdfSetting>>,
  TError,
  { projectId: string },
  TContext
> => {
  const mutationOptions =
    getProjectControllerToggleLetterheadPdfSettingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectControllerToggleWorkOrderQrLogoEnabled = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/projects/${projectId}/pdf/toggle-work-order-qr-logo-setting`,
    undefined,
    options
  );
};

export const getProjectControllerToggleWorkOrderQrLogoEnabledMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerToggleWorkOrderQrLogoEnabled>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectControllerToggleWorkOrderQrLogoEnabled>>,
  TError,
  { projectId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectControllerToggleWorkOrderQrLogoEnabled>>,
    { projectId: string }
  > = (props) => {
    const { projectId } = props ?? {};

    return projectControllerToggleWorkOrderQrLogoEnabled(
      projectId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectControllerToggleWorkOrderQrLogoEnabledMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof projectControllerToggleWorkOrderQrLogoEnabled>>
  >;

export type ProjectControllerToggleWorkOrderQrLogoEnabledMutationError =
  AxiosError<unknown>;

export const useProjectControllerToggleWorkOrderQrLogoEnabled = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerToggleWorkOrderQrLogoEnabled>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectControllerToggleWorkOrderQrLogoEnabled>>,
  TError,
  { projectId: string },
  TContext
> => {
  const mutationOptions =
    getProjectControllerToggleWorkOrderQrLogoEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectControllerDeleteProjectPdfLogo = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/projects/${projectId}/delete-logo`, options);
};

export const getProjectControllerDeleteProjectPdfLogoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerDeleteProjectPdfLogo>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectControllerDeleteProjectPdfLogo>>,
  TError,
  { projectId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectControllerDeleteProjectPdfLogo>>,
    { projectId: string }
  > = (props) => {
    const { projectId } = props ?? {};

    return projectControllerDeleteProjectPdfLogo(projectId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectControllerDeleteProjectPdfLogoMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectControllerDeleteProjectPdfLogo>>
>;

export type ProjectControllerDeleteProjectPdfLogoMutationError =
  AxiosError<unknown>;

export const useProjectControllerDeleteProjectPdfLogo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectControllerDeleteProjectPdfLogo>>,
    TError,
    { projectId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof projectControllerDeleteProjectPdfLogo>>,
  TError,
  { projectId: string },
  TContext
> => {
  const mutationOptions =
    getProjectControllerDeleteProjectPdfLogoMutationOptions(options);

  return useMutation(mutationOptions);
};
