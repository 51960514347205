import { Document, Page, Text, Font, View, Image } from "@react-pdf/renderer";

Font.register({
  family: "Fira Sans",
  fonts: [
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Regular.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Bold.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

interface WorkPermitPdfProps {
  qrCodeUrl: Promise<string>;
  projectName: string;
  ptwTemplateName: string;
}

export default function WorkPermitPdf({
  qrCodeUrl,
  projectName,
  ptwTemplateName,
}: WorkPermitPdfProps) {
  return (
    <Document>
      <Page>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            paddingVertical: 16,
            paddingHorizontal: 16,
            alignItems: "center",
            justifyContent: "center",
            gap: 24,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Image
              src="https://storage.googleapis.com/cerev-prod.appspot.com/public/cerev_logo.png"
              style={{
                height: 75,
                width: 75,
              }}
            />
            <Text
              style={{
                fontWeight: "heavy",
                fontFamily: "Fira Sans",
                fontSize: 24,
                color: "#1e99b4",
              }}
            >
              Cerev
            </Text>
          </View>
          <Image src={qrCodeUrl} style={{ height: 300, width: 300 }} />
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Fira Sans",
                fontSize: 22,
                color: "#1e99b4",
                fontWeight: "extrabold",
              }}
            >
              Scan to Submit Work Permit
            </Text>
            <Text style={{ fontFamily: "Fira Sans", fontWeight: "extrabold" }}>
              {ptwTemplateName}
            </Text>
            <Text style={{ fontFamily: "Fira Sans", color: "#64748b" }}>
              {projectName}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
