/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AuthControllerForgotPasswordParams,
  AuthControllerUpdateUser200,
  DeleteUserDto,
  GetCurrentUserResponseDto,
  UpdateDeviceTokenDto,
  UpdateUserDto,
} from ".././model";

export const authControllerGetCurrentUser = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetCurrentUserResponseDto>> => {
  return axios.get(`/auth/user`, options);
};

export const getAuthControllerGetCurrentUserQueryKey = () => {
  return [`/auth/user`] as const;
};

export const getAuthControllerGetCurrentUserInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof authControllerGetCurrentUser>>
  >,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthControllerGetCurrentUserQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authControllerGetCurrentUser>>
  > = ({ signal }) => authControllerGetCurrentUser({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthControllerGetCurrentUserInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof authControllerGetCurrentUser>>
>;
export type AuthControllerGetCurrentUserInfiniteQueryError =
  AxiosError<unknown>;

export const useAuthControllerGetCurrentUserInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof authControllerGetCurrentUser>>
  >,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAuthControllerGetCurrentUserInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAuthControllerGetCurrentUserQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthControllerGetCurrentUserQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authControllerGetCurrentUser>>
  > = ({ signal }) => authControllerGetCurrentUser({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthControllerGetCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof authControllerGetCurrentUser>>
>;
export type AuthControllerGetCurrentUserQueryError = AxiosError<unknown>;

export const useAuthControllerGetCurrentUser = <
  TData = Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof authControllerGetCurrentUser>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthControllerGetCurrentUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authControllerUpdateUser = (
  updateUserDto: UpdateUserDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AuthControllerUpdateUser200>> => {
  return axios.patch(`/auth/user`, updateUserDto, options);
};

export const getAuthControllerUpdateUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerUpdateUser>>,
    TError,
    { data: UpdateUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerUpdateUser>>,
  TError,
  { data: UpdateUserDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerUpdateUser>>,
    { data: UpdateUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerUpdateUser(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerUpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerUpdateUser>>
>;
export type AuthControllerUpdateUserMutationBody = UpdateUserDto;
export type AuthControllerUpdateUserMutationError = AxiosError<unknown>;

export const useAuthControllerUpdateUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerUpdateUser>>,
    TError,
    { data: UpdateUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerUpdateUser>>,
  TError,
  { data: UpdateUserDto },
  TContext
> => {
  const mutationOptions = getAuthControllerUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authControllerDeleteUser = (
  deleteUserDto: DeleteUserDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/auth/user`, { data: deleteUserDto, ...options });
};

export const getAuthControllerDeleteUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerDeleteUser>>,
    TError,
    { data: DeleteUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerDeleteUser>>,
  TError,
  { data: DeleteUserDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerDeleteUser>>,
    { data: DeleteUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerDeleteUser(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerDeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerDeleteUser>>
>;
export type AuthControllerDeleteUserMutationBody = DeleteUserDto;
export type AuthControllerDeleteUserMutationError = AxiosError<unknown>;

export const useAuthControllerDeleteUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerDeleteUser>>,
    TError,
    { data: DeleteUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerDeleteUser>>,
  TError,
  { data: DeleteUserDto },
  TContext
> => {
  const mutationOptions = getAuthControllerDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authControllerUpdateToken = (
  updateDeviceTokenDto: UpdateDeviceTokenDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.patch(`/auth/user/token`, updateDeviceTokenDto, options);
};

export const getAuthControllerUpdateTokenMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerUpdateToken>>,
    TError,
    { data: UpdateDeviceTokenDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerUpdateToken>>,
  TError,
  { data: UpdateDeviceTokenDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerUpdateToken>>,
    { data: UpdateDeviceTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return authControllerUpdateToken(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerUpdateTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerUpdateToken>>
>;
export type AuthControllerUpdateTokenMutationBody = UpdateDeviceTokenDto;
export type AuthControllerUpdateTokenMutationError = AxiosError<unknown>;

export const useAuthControllerUpdateToken = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerUpdateToken>>,
    TError,
    { data: UpdateDeviceTokenDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerUpdateToken>>,
  TError,
  { data: UpdateDeviceTokenDto },
  TContext
> => {
  const mutationOptions = getAuthControllerUpdateTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authControllerForgotPassword = (
  params: AuthControllerForgotPasswordParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/auth/forgotPassword`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAuthControllerForgotPasswordMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerForgotPassword>>,
    TError,
    { params: AuthControllerForgotPasswordParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerForgotPassword>>,
  TError,
  { params: AuthControllerForgotPasswordParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerForgotPassword>>,
    { params: AuthControllerForgotPasswordParams }
  > = (props) => {
    const { params } = props ?? {};

    return authControllerForgotPassword(params, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerForgotPassword>>
>;

export type AuthControllerForgotPasswordMutationError = AxiosError<unknown>;

export const useAuthControllerForgotPassword = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerForgotPassword>>,
    TError,
    { params: AuthControllerForgotPasswordParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerForgotPassword>>,
  TError,
  { params: AuthControllerForgotPasswordParams },
  TContext
> => {
  const mutationOptions =
    getAuthControllerForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
