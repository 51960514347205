export function CerevLogo() {
  return (
      <svg
        viewBox="0 0 106 106"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cerev-logo"
      >
        <g clipPath="url(#clip0_474_222)">
          <path
            d="M106 52.9952C106 54.3398 105.947 55.649 105.858 56.9936C105.161 48.3609 102.008 40.1082 96.77 33.2108C91.5323 26.3133 84.4296 21.0594 76.3012 18.07C68.1728 15.0805 59.3585 14.4805 50.8999 16.3409C42.4414 18.2012 34.6922 22.4441 28.5681 28.5681C22.4441 34.6922 18.2012 42.4414 16.3409 50.8999C14.4805 59.3585 15.0805 68.1728 18.07 76.3012C21.0594 84.4296 26.3133 91.5323 33.2108 96.77C40.1082 102.008 48.3609 105.161 56.9936 105.858C55.6755 105.947 54.3398 106 52.9952 106C42.512 106 32.2641 102.891 23.5476 97.0668C14.831 91.2426 8.03731 82.9644 4.02553 73.2792C0.0137496 63.5939 -1.03591 52.9364 1.00927 42.6546C3.05446 32.3727 8.10264 22.9282 15.5154 15.5154C22.9282 8.10264 32.3727 3.05446 42.6546 1.00927C52.9364 -1.03591 63.5939 0.0137496 73.2792 4.02553C82.9644 8.03731 91.2426 14.831 97.0668 23.5476C102.891 32.2641 106 42.512 106 52.9952Z"
            fill="#00A7B4"
          />
          <path
            d="M95.7918 60.6827C93.1585 52.7673 87.7935 46.0478 80.6576 41.7275C73.5217 37.4071 65.0808 35.768 56.8465 37.1036C48.6123 38.4393 41.1224 42.6625 35.718 49.017C30.3136 55.3715 27.3477 63.4423 27.3514 71.7842C27.3514 72.0761 27.3514 72.3592 27.3514 72.6688C25.764 67.8794 25.2274 62.8043 25.7779 57.7888C26.3284 52.7734 27.9532 47.9355 30.5416 43.6045C33.13 39.2735 36.6214 35.5511 40.7778 32.6907C44.9343 29.8304 49.6583 27.8993 54.6282 27.0289C59.5981 26.1585 64.6972 26.3693 69.5783 27.6468C74.4595 28.9244 79.008 31.2388 82.9142 34.4323C86.8205 37.6259 89.9927 41.6237 92.2149 46.1536C94.4371 50.6834 95.6571 55.6389 95.7918 60.6827Z"
            fill="#00A7B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_474_222">
            <rect width="106" height="106" fill="white" />
          </clipPath>
        </defs>
      </svg>
  );
}
