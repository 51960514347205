import { coerce, z } from "zod";
import {
  AddPmTemplateDtoRepeatType,
  FrequencySettingFrequencySettingType,
} from "../../../api/model";

export const PmTemplateFormSchema = z.object({
  name: z.string().min(1, { message: "Please give a name for your checklist" }),
  startDate: z.string().min(1, { message: "Please select a start date" }),
  assets: z
    .object(
      {
        id: z.number(),
        name: z.string(),
        locationId: z.number(),
      },
      { required_error: "Please select an asset" }
    )
    .array(),
  pmFormat: z
    .object({
      id: z.string().optional(),
      name: z.string().min(1, { message: "Please give a name for your field" }),
      pmFormatType: z.object({
        id: z.number().optional(),
        name: z.string(),
      }),
      order: z.number().optional(),
    })
    .array()
    .min(1, { message: "Please add at least one field" }),
  frequencySetting: z
    .object({
      frequencySettingType: z.nativeEnum(FrequencySettingFrequencySettingType),
      weeklyRepeatDay: z.string().array().optional(),
      monthlyRepeatDay: z.string().array().optional(),
      monthlyRepeatDate: z.string().array().optional(),
      monthlyEveryStepCount: z.coerce
        .number()
        .max(12, { message: "Please use yearly option instead" })
        .optional(),
      yearlyRepeatMonth: z.string().array().optional(),
      yearlyRepeatDate: z.string().array().optional(),
      yearlyEveryStepCount: z.coerce.number().optional(),
    })
    .refine(
      (data) => {
        if (
          data.frequencySettingType ===
          FrequencySettingFrequencySettingType.WEEKLY
        ) {
          return data.weeklyRepeatDay && data.weeklyRepeatDay.length > 0;
        }
        return true;
      },
      {
        message: "Please select at least one day",
        path: ["weeklyRepeatDay"],
      }
    )
    .refine(
      (data) => {
        if (
          data.frequencySettingType ===
          FrequencySettingFrequencySettingType.BY_MONTH
        ) {
          return data.monthlyRepeatDate && data.monthlyRepeatDate.length > 0;
        }
        return true;
      },
      {
        message: "Please select at least one date",
        path: ["monthlyRepeatDate"],
      }
    )
    .refine(
      (data) => {
        if (
          data.frequencySettingType ===
          FrequencySettingFrequencySettingType.BY_YEAR
        ) {
          return (
            data.yearlyRepeatMonth &&
            data.yearlyRepeatMonth.length > 0 &&
            data.yearlyRepeatDate &&
            data.yearlyRepeatDate.length > 0
          );
        }
        return true;
      },
      {
        message: "Please select at least one date",
        path: ["yearlyRepeatDate"],
      }
    ),
});

export type PmTemplateFormType = z.infer<typeof PmTemplateFormSchema>;
