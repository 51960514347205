import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { PtwTemplateFormType } from "../../ptwTemplateSchema";
import { List } from "lucide-react";
import { PtwFormatType } from "../../../../../api/model";
import AppTextField from "../../../../AppTextField";
import AppButton from "../../../../AppButton";
import { Switch } from "../../../../ui/switch";

interface PtwOptionFieldProps {
  ptwFormatIdx: number;
  fieldsArray: UseFieldArrayReturn<PtwTemplateFormType>;
}

export default function PtwOptionField({
  ptwFormatIdx,
  fieldsArray,
}: PtwOptionFieldProps) {
  const methods = useFormContext<PtwTemplateFormType>();
  const currentType = useWatch({
    control: methods.control,
    name: `ptwFormat.${ptwFormatIdx}.ptwFormatType`,
  });
  const { fields, append } = useFieldArray({
    control: methods.control,
    name: `ptwFormat.${ptwFormatIdx}.ptwOptions`,
  });

  return (
    <>
      {fields?.map((f, idx) => (
        <AppTextField
          key={f.id}
          name={`ptwFormat.${ptwFormatIdx}.ptwOptions.${idx}.value`}
        />
      ))}
      <div className="flex justify-between items-center">
        <AppButton
          variant="outline"
          label="+ Add"
          onClick={() => {
            append({
              value: "",
            });
          }}
        />
        <div className="flex gap-4">
          <p className="text-slate-800">Multiple Option</p>
          <Switch
            className="data-[state=checked]:bg-primary-900"
            checked={currentType === PtwFormatType.MULTI_OPTION}
            onCheckedChange={(e) => {
              if (!e) {
                methods.setValue(
                  `ptwFormat.${ptwFormatIdx}.ptwFormatType`,
                  PtwFormatType.SINGLE_OPTION
                );
              } else {
                methods.setValue(
                  `ptwFormat.${ptwFormatIdx}.ptwFormatType`,
                  PtwFormatType.MULTI_OPTION
                );
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
