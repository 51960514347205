import { useSearchParams } from "react-router-dom";
import {
  PmValueDto,
  usePmChecklistControllerGetOnePmChecklistQuery,
  usePmChecklistStatusControllerGetAllPmChecklistStatusQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import { useEffect } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import AppDocumentUpload from "../../AppDocumentUpload";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useUploadAttachmentMutation } from "../../../redux/slices/Attachment/AttachmentApi";
import { SnackBarType, setOpenSnackBar } from "../../../redux/slices/RootSlice";
import useSuccess from "../../../hooks/useSuccess";
import useError from "../../../hooks/useError";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { usePmChecklistControllerUpdatePmChecklistMutation } from "../../../redux/slices/Pm/PmApi";
import { Hash, TextCursorInput, ToggleLeft } from "lucide-react";
import { Input } from "../../ui/input";
import { Checkbox } from "../../ui/checkbox";

export const BOOL_TYPE = "BOOL";
export const VARCHAR_TYPE = "VARCHAR";
export const NUMBER_TYPE = "NUMBER";
export const ATTACHMENT_TYPE = "ATTACHMENT";
const MULTICHOICES_TYPE = "MULTICHOICES"; // Not in use, but declare here for typing reasons.

const PmChecklistFormSchema = z.object({
  pmFormat: z
    .object({
      id: z.number(),
      name: z.string(),
      pmFormatType: z.object({
        name: z.enum([
          BOOL_TYPE,
          VARCHAR_TYPE,
          NUMBER_TYPE,
          ATTACHMENT_TYPE,
          MULTICHOICES_TYPE,
        ]),
      }),
      value: z
        .union([
          z.string().min(1, { message: "varchart is required" }),
          z.number().min(1, { message: "num is required" }),
          z.boolean({ required_error: "boolean is required" }),
          z.instanceof(File).array().min(1, { message: "file is required" }),
        ])
        .optional(),
    })
    .array(),
});

type PmChecklistFormType = z.infer<typeof PmChecklistFormSchema>;

export default function PmChecklistFormDrawer() {
  const [searchParam, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const pmChecklistId = searchParam.get("pmChecklistId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);

  const { data: pmChecklist, isLoading: pmChecklistIsLoading } =
    usePmChecklistControllerGetOnePmChecklistQuery(
      {
        pmChecklistId: pmChecklistId ?? "",
      },
      {
        skip: !pmChecklistId,
      }
    );

  const { data: pmStatusList, isLoading: pmStatusIsLoading } =
    usePmChecklistStatusControllerGetAllPmChecklistStatusQuery(
      {
        companyId: activeComp?.id ?? 0,
      },
      {
        skip: !activeComp,
      }
    );

  const { control, register, handleSubmit } = useForm<PmChecklistFormType>({
    resolver: zodResolver(PmChecklistFormSchema),
  });

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "pmFormat",
  });

  const [
    updatePmChecklist,
    {
      isLoading: updatePmChecklistIsLoading,
      isSuccess: updatePmChecklistIsSuccess,
      isError: updatePmchecklistIsError,
      error: updatePmChecklistError,
    },
  ] = usePmChecklistControllerUpdatePmChecklistMutation();

  const { data: user } = useGetUserDataQuery();

  const [
    uploadAttachments,
    { isLoading: attIsLoading, isError: attIsError, error: attError },
  ] = useUploadAttachmentMutation();

  const onSubmit: SubmitHandler<PmChecklistFormType> = async (data) => {
    if (!pmChecklist || !user || !activeProj) return;

    let uploadValues: PmValueDto[] = [];

    // Loop through data.pmFormat and depending on pmFormat type, to do different logic
    for (let i = 0; i < data.pmFormat.length; i++) {
      const d = data.pmFormat[i];
      switch (d.pmFormatType.name) {
        case BOOL_TYPE:
          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistBool.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: d.value as boolean,
            type: d.pmFormatType.name,
            attachments: [],
          });
          break;
        case VARCHAR_TYPE:
          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistText.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: d.value as string,
            type: d.pmFormatType.name,
            attachments: [],
          });
          break;
        case NUMBER_TYPE:
          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistNumber.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: d.value as number,
            type: d.pmFormatType.name,
            attachments: [],
          });
          break;
        case ATTACHMENT_TYPE:
          const uploadedAtt = await uploadAttachments(d.value as File[])
            .unwrap()
            .catch((e) => {
              dispatch(
                setOpenSnackBar({
                  type: SnackBarType.Error,
                  msg: "Something went wrong",
                })
              );
              return null;
            });

          if (!uploadedAtt) return;

          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistAttachment.find(
                (b) => b.pmFormatId === d.id
              )?.id ?? 0,
            pmFormatId: d.id,
            value: uploadedAtt,
            type: d.pmFormatType.name,
            attachments: uploadedAtt,
          });
          break;
      }
    }

    await updatePmChecklist({
      pmChecklistId: pmChecklist.id.toString(),
      updatePmChecklistDto: {
        pmStatusId: pmStatusList?.find((s) => s.name === "Close")?.id ?? 0,
        updatedById: user.id,
        projectId: activeProj?.id ?? 0,
        assetId: pmChecklist.assetId,
        pmTemplateId: pmChecklist.pmTemplateId,
        values: uploadValues,
      },
    });
  };

  useSuccess({ dependencies: [updatePmChecklistIsSuccess] });
  useError({
    dependencies: [
      {
        isError: updatePmchecklistIsError,
        error: updatePmChecklistError,
      },
      {
        isError: attIsError,
        error: attError,
      },
    ],
  });

  useEffect(() => {
    if (pmChecklist) {
      const orderedList = [...pmChecklist?.pmTemplate.pmFormat].sort(
        (a, b) => a.order - b.order
      );
      orderedList.forEach((ft) => {
        append(ft);
      });
    }

    // Clean up all the fields.
    return () => {
      remove();
    };
  }, [pmChecklist, remove]);

  if (pmChecklistIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-8">
      <p className="font-sans text-2xl font-bold">PM Checklist</p>
      {fields.map((cl, idx) => {
        switch (cl.pmFormatType.name) {
          case VARCHAR_TYPE:
            return (
              <div key={cl.id} className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <TextCursorInput className="text-primary-900" />
                  <p className="font-sans">{cl.name}</p>
                </div>
                <Input
                  className="focus-visible:ring-primary-900 bg-slate-50"
                  {...register(`pmFormat.${idx}.value`)}
                />
              </div>
            );
          case NUMBER_TYPE:
            return (
              <div key={cl.id} className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <Hash className="text-primary-900" />
                  <p className="font-sans">{cl.name}</p>
                </div>
                <Input
                  className="focus-visible:ring-primary-900 bg-slate-50"
                  {...register(`pmFormat.${idx}.value`)}
                  type="number"
                />
              </div>
            );
          case BOOL_TYPE:
            return (
              <div
                key={cl.id}
                className="my-2 flex justify-between items-center"
              >
                <div className="flex gap-2">
                  <ToggleLeft className="text-primary-900" />
                  <p>{cl.name}</p>
                </div>
                <Controller
                  control={control}
                  name={`pmFormat.${idx}.value`}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Checkbox
                        checked={Boolean(value)}
                        onCheckedChange={(v) => {
                          onChange(!value);
                        }}
                        className="text-primary-900"
                      />
                    );
                  }}
                />
                {/* <CheckBox checked={false} className="text-primary-900" /> */}
              </div>
            );
          case ATTACHMENT_TYPE:
            return (
              <div key={cl.id}>
                <Controller
                  control={control}
                  name={`pmFormat.${idx}.value`}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <AppCameraFieldFile
                        uploadedPhotos={[]}
                        onDeleteUploadedPhoto={async () => {}}
                        label="Documents"
                        onChange={onChange}
                        onDelete={(url) => {
                          if (!value) return;
                          const newFiles = (value as File[]).filter(
                            (v) => v !== url
                          );
                          onChange(newFiles);
                        }}
                        photos={(value as File[]) ?? []}
                        error={!!error}
                        // helperText={
                        //   errors.legalDocuments?.message !== ""
                        //     ? "At least one file required"
                        //     : ""
                        // }
                      />
                    );
                  }}
                />
              </div>
            );
        }
        return <div>error</div>;
      })}
      <div className="flex">
        <AppButton
          isLoading={attIsLoading || updatePmChecklistIsLoading}
          label="Submit"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
}
