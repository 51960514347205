import { useGLTF } from "@react-three/drei";
import {
  useBimControllerGetBimModel,
  useBimControllerSetupBimNodes,
} from "../../../api/bim/bim";
import { useAppSelector } from "../../../redux/store";
import { BimStatus } from "../../../api/model";
import { toast } from "sonner";
import { Suspense } from "react";
import AppButton from "../../../components/AppButton";

export default function SyncBimBtn() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: bimModel, isLoading } = useBimControllerGetBimModel(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  const { nodes } = useGLTF(bimModel?.bimAsset?.url ?? "");
  const { mutateAsync } = useBimControllerSetupBimNodes();

  async function setupBimNodes() {
    // // const nodes = new THREE.Loader();
    if (nodes && bimModel?.bimStatus === BimStatus.BIM_NODE_NOT_READY) {
      // console.log("nodes will be updated");
      toast("Updating Nodes");
      const bimNodes = Object.keys(nodes).map((key) => ({
        name: key,
        uuid: nodes[key].uuid,
        hexColor: (nodes[key] as any)?.material?.color?.getHexString(),
        material: (nodes[key] as any)?.material?.toJSON(),
      }));
      await mutateAsync({
        bimId: bimModel.id,
        data: {
          bimNodes: bimNodes.map((nod) => ({
            name: nod.name,
            bimUuid: nod.uuid,
            hexColor: nod.hexColor,
            material: nod.material,
          })),
        },
      });

        toast("Updating Nodes - COMPLETED");
    }
  }

  return (
    <AppButton
      onClick={() => {
        setupBimNodes();
      }}
      label="Sync nodes"
    />
  );
}
