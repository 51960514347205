import { useCallback } from "react";
import WorkOrderPdfReportViewer from "../pdfs/WorkOrderPdfReportViewer";
import {
  PdfReportType,
  usePdfReportReportType,
} from "../../../../stores/pdfReportStore/pdfReportStore";
import { FileSearch, Filter } from "lucide-react";
import { JkrApdSummaryPdfReview } from "../../../CustomPdf/JkrApdSummaryPdfPreview";

export default function ReportSection() {
  const pdfReportType = usePdfReportReportType();

  const renderContent = useCallback(() => {
    switch (pdfReportType) {
      case PdfReportType.INITIAL:
        return (
          <div className="flex flex-col justify-center items-center h-full gap-4">
            <FileSearch className="text-gray-300 h-20 w-20" />
            <h3 className="text-lg text-gray-400">Pick a report</h3>
          </div>
        );
      case PdfReportType.WORK_ORDER_SUMMARY:
        return <WorkOrderPdfReportViewer />;
      case PdfReportType.INVENTORY_MOVEMENT_REPORT:
        return <div>Inventory Movement Report</div>;
      // case PdfReportType.JKR_APD_SUMMARY:
      //   return <JkrApdSummaryPdfReview />;
    }
  }, [pdfReportType]);

  return (
    <div className="p-2 h-full w-full">
      <div className="border border-slate-200 rounded-lg h-full">
        {renderContent()}
      </div>
    </div>
  );
}
