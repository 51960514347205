import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import AppTextField from "../../AppTextField";
import AppButton from "../../AppButton";
import { useUploadAttachmentMutation } from "../../../redux/slices/Attachment/AttachmentApi";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import AppDocumentUpload from "../../AppDocumentUpload";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { SnackBarType, setOpenSnackBar } from "../../../redux/slices/RootSlice";
import useError from "../../../hooks/useError";
import { format, parse, parseISO } from "date-fns";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import useSuccess from "../../../hooks/useSuccess";
import AppTextAreaField from "../../AppTextAreaField";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import { Attachment } from "../../../api/model";
import {
  useTnbBillControllerCreateTnbReading,
  useTnbBillControllerDeleteAttachment,
  useTnbBillControllerDeleteTnbReading,
  useTnbBillControllerGetOneTnbReading,
  useTnbBillControllerUpdateTnb,
} from "../../../api/tnb-bill/tnb-bill";
import { useQueryClient } from "@tanstack/react-query";
import useAppStorage from "../../../hooks/useAppStorage";

const TnbBillingFormSchema = z.object({
  billMonth: z.string({ required_error: "You need to enter the billing date" }),
  kiloWattMd: z.coerce
    .number()
    .min(1, { message: "You need to enter a value" }),
  kiloWattHr: z.coerce
    .number()
    .min(1, { message: "You need to enter a value" }),
  totalBillCost: z.coerce
    .number()
    .min(1, { message: "You need to enter a value" }),
  attachments: z.instanceof(File).array().optional(),
  description: z.string().optional(),
});

type TnbBillingForm = z.infer<typeof TnbBillingFormSchema>;

export default function TnbBillFormDrawer() {
  const qc = useQueryClient();
  const dispatch = useAppDispatch();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const tnbReadingId = searchParams.get("tnbReadingId");
  const [uploadedDocumentsTnb, setUploadedDocumentsTnb] = useState<
    Attachment[]
  >([]);
  const methods = useForm<TnbBillingForm>({
    resolver: zodResolver(TnbBillingFormSchema),
    defaultValues: {
      billMonth: format(new Date(), "yyyy-MM-dd"),
      kiloWattHr: 0,
      kiloWattMd: 0,
      totalBillCost: 0,
      attachments: [],
      description: "",
    },
  });

  const { useUploadAttachmentMutation } = useAppStorage();

  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => (query.queryKey[0] as string).includes("tnb"),
      });
      setSearchParams(new URLSearchParams());
    },
  });

  const { mutateAsync: createTnbReading } =
    useTnbBillControllerCreateTnbReading();

  const { mutateAsync: updateTnbReading } = useTnbBillControllerUpdateTnb();

  const {
    data: editTnbReadingData,
    isLoading: editTnbReadingDataIsLoading,
    isError: editTnbReadingDataIsError,
    error: editTnbReadingDataError,
  } = useTnbBillControllerGetOneTnbReading(parseInt(tnbReadingId ?? "0"), {
    query: {
      enabled: !!tnbReadingId,
    },
  });

  const { mutateAsync: deleteTnbReading } =
    useTnbBillControllerDeleteTnbReading({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) => (query.queryKey[0] as string).includes("tnb"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });

  const { mutateAsync: deleteDocument } =
    useTnbBillControllerDeleteAttachment();

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDocumentsTnb.filter((v) => v.id !== att.id);
    if (!editTnbReadingData) return;
    deleteDocument({
      tnbReadingId: editTnbReadingData?.data?.id,
      attachmentId: att?.id ?? 0,
    });
    setUploadedDocumentsTnb(newUploadedDocs);
  };

  // isEditMode
  useEffect(() => {
    if (editTnbReadingData && isUpdateDrawer) {
      methods.setValue(
        "billMonth",
        format(parseISO(editTnbReadingData?.data?.billMonth), "yyyy-MM-dd")
      );
      methods.setValue("kiloWattHr", editTnbReadingData?.data?.kiloWattHr);
      methods.setValue("kiloWattMd", editTnbReadingData?.data?.kiloWattMd);
      methods.setValue(
        "totalBillCost",
        editTnbReadingData?.data?.totalBillCost
      );
      methods.setValue(
        "description",
        editTnbReadingData?.data?.description ?? ""
      );
      setUploadedDocumentsTnb(editTnbReadingData?.data?.attachments ?? []);
    }
  }, [editTnbReadingData, searchParams]);

  const onSubmit: SubmitHandler<TnbBillingForm> = async (data) => {
    mutate({
      files: data.attachments ?? [],
      mutateAsync: async (atts) => {
        if (isUpdateDrawer) {
          await updateTnbReading({
            tnbReadingId: editTnbReadingData?.data?.id ?? 0,
            data: {
              billMonth: parse(
                data.billMonth,
                "yyyy-MM-dd",
                new Date()
              ).toISOString(),
              kiloWattHr: data.kiloWattHr,
              kiloWattMd: data.kiloWattMd,
              totalBillCost: data.totalBillCost,
              attachments: atts,
              description: data.description,
            },
          });
        } else {
          await createTnbReading({
            data: {
              projectId: activeProj?.id ?? 0,
              billMonth: parse(
                data.billMonth,
                "yyyy-MM-dd",
                new Date()
              ).toISOString(),
              kiloWattHr: data.kiloWattHr,
              kiloWattMd: data.kiloWattMd,
              totalBillCost: data.totalBillCost,
              attachments: atts,
              description: data.description,
            },
          });
        }
      },
    });
    // let uploadedAttacment = [] as AttachmentProjectType[] | null;
    // if (data.attachments) {
    //   uploadedAttacment = await uploadAttachments(data.attachments)
    //     .unwrap()
    //     .then((v) => v)
    //     .catch((e) => {
    //       dispatch(
    //         setOpenSnackBar({
    //           type: SnackBarType.Error,
    //           msg: "Something went wrong",
    //         })
    //       );
    //       return null;
    //     });
    // }
    // if (!uploadedAttacment || !activeProj) return;
    // if (isUpdateDrawer && editTnbReadingData) {
    //   updateTnbReading({
    //     tnbReadingId: editTnbReadingData.id,
    //     updateTnbReadingDto: {
    //       billMonth: parse(
    //         data.billMonth,
    //         "yyyy-MM-dd",
    //         new Date()
    //       ).toISOString(),
    //       kiloWattHr: data.kiloWattHr,
    //       kiloWattMd: data.kiloWattMd,
    //       totalBillCost: data.totalBillCost,
    //       attachments: uploadedAttacment,
    //       description: data.description,
    //     },
    //   });
    //   return;
    // }
    // createTnbReading({
    //   createTnbReadingDto: {
    //     projectId: activeProj.id,
    //     billMonth: parse(
    //       data.billMonth,
    //       "yyyy-MM-dd",
    //       new Date()
    //     ).toISOString(),
    //     kiloWattHr: data.kiloWattHr,
    //     kiloWattMd: data.kiloWattMd,
    //     totalBillCost: data.totalBillCost,
    //     attachments: uploadedAttacment,
    //     description: data.description,
    //   },
    // });
  };

  // useError({
  //   dependencies: [
  //     {
  //       isError: createTnbReadingIsError,
  //       error: createTnbReadingError,
  //     },
  //     {
  //       isError: deleteDocumentIsError,
  //       error: deleteDocumentError,
  //     },
  //     {
  //       isError: updateTnbReadingIsError,
  //       error: updateTnbReadingError,
  //     },
  //   ],
  // });

  // useSuccess({
  //   dependencies: [
  //     createTnbReadingIsSuccess,
  //     updateTnbReadingIsSuccess,
  //     deleteTnbReadingIsSuccess,
  //   ],
  // });

  if (editTnbReadingDataIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">Create TNB Bill</p>
        <AppTextField label="Billing Date" name="billMonth" type="date" />
        <AppTextAreaField label="Description" name="description" />
        <AppTextField label="kW Reading" name="kiloWattMd" type="number" />
        <AppTextField label="kWh Reading" name="kiloWattHr" />
        <AppTextField
          label="Total Bill (RM)"
          name="totalBillCost"
          type="number"
        />
        <Controller
          control={methods.control}
          name="attachments"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUpload
                uploadedDocs={uploadedDocumentsTnb}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newFiles = value.filter((v) => v !== url);
                  onChange(newFiles);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.attachments?.message !== ""
                    ? "At least one file required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex gap-4">
          <AppButton
            isLoading={isPending}
            onClick={methods.handleSubmit(onSubmit)}
            label="Submit"
          />
          {isUpdateDrawer && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={"Confirm"}
              onDeleteConfirm={async () => {
                if (editTnbReadingData) {
                  await deleteTnbReading({
                    tnbReadingId: editTnbReadingData?.data?.id,
                  });
                }
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
