import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AnalyticApi} from "../Analytic/AnalyticApi";

export interface ErrorState {
  errorStatus: ErrorStatus;
  errorMsg: string;
}

export const enum ErrorStatus {
  errorPresent,
  noError,
}

const initialState = {
  errorStatus: ErrorStatus.noError,
  errorMsg: "",
} as ErrorState;

export const ErrorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    errorOccured(state, action: PayloadAction<string>) {
      state.errorStatus = ErrorStatus.errorPresent;
      const errorMsg = action.payload;

      // Error interpretation

      if (errorMsg.includes("invalid-email")) {
        state.errorMsg = "The user do not have an valid email.";
      } else if (errorMsg.includes("wrong-password")) {
        state.errorMsg = "Either email or the password is wrong.";
      } else {
        state.errorMsg = "Something wrong. Please try again.";
      }
    },
    errorReset(state) {
      state.errorStatus = ErrorStatus.noError;
      state.errorMsg = "";
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      AnalyticApi.endpoints.getAnalytics.matchRejected,
      (state, {payload}) => {
        state.errorStatus = ErrorStatus.errorPresent;
        state.errorMsg =
          (payload as any)?.data?.message ??
          "Something wrong. Please try again.";
      }
    );
  },
});

export const {errorOccured, errorReset} = ErrorSlice.actions;
