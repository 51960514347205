/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type UpdateUserPolicyRequestDtoPolicyStatus =
  (typeof UpdateUserPolicyRequestDtoPolicyStatus)[keyof typeof UpdateUserPolicyRequestDtoPolicyStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserPolicyRequestDtoPolicyStatus = {
  ALLOWED: "ALLOWED",
  NOT_ALLOWED: "NOT_ALLOWED",
} as const;
