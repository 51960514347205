import {Typography} from "@mui/material";
import {CerevLogo} from "../icons/CerevLogo";

interface AppLogoTextProps {
  subtitle?: string;
  className?: string;
}

export default function AppLogoText({subtitle, className}: AppLogoTextProps) {
  return (
    <div className={`flex items-center justify-start mb-4 ${className}`}>
      <div className="h-14 w-14">
        <CerevLogo />
      </div>
      <div className="flex flex-col items-start ml-4">
        <p className="font-sans font-bold text-primary-900 text-2xl">
          Cerev
        </p>
        {subtitle && (
          <p className="font-medium text-neutral-600">
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
}
