import { useSearchParams } from "react-router-dom";
import { usePmTemplateControllerGetOnePmTemplate } from "../../../../../api/preventive-maintenance-template/preventive-maintenance-template";
import { FrequencySettingFrequencySettingType } from "../../../../../api/model";

const weekDayMapper = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const monthMapper = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };

export default function FrequencySettingDescription() {
  const [searchParams] = useSearchParams();
  const pmTemplateId = searchParams.get("pmTemplateId");

  const { data: pmTemplate } = usePmTemplateControllerGetOnePmTemplate(
    pmTemplateId as string,
    {
      query: {
        enabled: !!pmTemplateId,
        select: (res) => {
          return res.data.frequencySetting;
        },
      },
    }
  );

  switch (pmTemplate?.frequencySetting) {
    case FrequencySettingFrequencySettingType.DAILY:
      return <p>Repeats everyday</p>;
    case FrequencySettingFrequencySettingType.WEEKLY:
      return (
        <p>
          Repeats every week, on{" "}
          {pmTemplate.weeklyRepeatDay
            ?.split(",")
            .map((d) => {
              return  weekDayMapper[Number(d) as keyof typeof weekDayMapper];
            })
            .join(", ")}
        </p>
      );
    case FrequencySettingFrequencySettingType.BY_MONTH:
      return (
        <p>
          Repeats every {pmTemplate.monthlyEveryStepCount} month, on the date of{" "}
          {pmTemplate.monthlyRepeatDate}
        </p>
      );

    case FrequencySettingFrequencySettingType.BY_YEAR:
      return (
        <p>
          Repeats every {pmTemplate.yearlyEveryStepCount} year, on {" "}
          {pmTemplate.yearlyRepeatDate} of {" "}
          {pmTemplate.yearlyRepeatMonth?.split(",")
            .map((d) => {
              return  monthMapper[Number(d) as keyof typeof monthMapper];
            })
            .join(", ")}
        </p>
      );
  }

  return <>-</>;
}
