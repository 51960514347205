import {useAuthControllerGetCurrentUserQuery} from "../redux/slices/OpenApi/cerevApi";
import {useAppSelector} from "../redux/store";

export const SUPER_ADMIN_ACC_TYPE = "SUPERADMIN";
export const COMPANY_ADMIN_ACC_TYPE = "COMPANY_ADMIN";
export const NORMAL_USER_ACC_TYPE = "NORMAL_USER";
export const VENDOR_ACC_TYPE = "VENDOR";

export default function useAccountType({
  accType,
}: {
  accType: "SUPERADMIN" | "COMPANY_ADMIN" | "NORMAL_USER" | "VENDOR";
}) {
  const firebaseUserId = useAppSelector((state) => state.root.firebaseUserId);
  const {data: user, isLoading} = useAuthControllerGetCurrentUserQuery();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  if (!firebaseUserId) return {hasAccess: false, isLoading: true};

  if (!user || !activeProj) return {hasAccess: false, isLoading: isLoading};
  // SUPERADMIN always have all the access

  if (user.accountTypes.map((acc) => acc.type).includes(SUPER_ADMIN_ACC_TYPE))
    return {hasAccess: true, isLoading: isLoading};

  return {
    hasAccess:
      user.accountTypes?.filter(
        (acc) => acc.type === accType && acc.projectId === activeProj.id
      )?.length > 0,
    isLoading,
  };
}
