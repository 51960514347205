import { useSearchParams } from "react-router-dom";
import { Attachment, AttachmentProjectType } from "../../../../../../api/model";
import { useWorkOrderV2ControllerRemovePricingAttachment } from "../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import AppDocument from "../../../../../AppDocument";
import { useQueryClient } from "@tanstack/react-query";

interface AppPricingDocumentProps {
  att: AttachmentProjectType | Attachment;
}

export default function AppPricingDocument({ att }: AppPricingDocumentProps) {
  const qc = useQueryClient();
  const [searchParam] = useSearchParams();
  const wrId = searchParam.get("workOrderId");
  const { mutate, isPending } = useWorkOrderV2ControllerRemovePricingAttachment(
    {
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("work-order") ||
              (q.queryKey[0] as string).includes("defect"),
          });
        },
      },
    }
  );

  const onDelete = async (att: Attachment) => {
    if (!wrId || !att.id) return;
    mutate({
      attachmentId: att.id.toString(),
      workOrderId: wrId,
    });
  };

  return (
    <AppDocument att={att} onDelete={onDelete} onDeleteLoading={isPending} />
  );
}
