import { z } from "zod";
import { PtwFormatType } from "../../../api/model";
import { PtwTemplateFormSchema } from "../ptwTemplate/ptwTemplateSchema";

const ptwFormatTypeEnum = z.enum([
  PtwFormatType.TEXT,
  PtwFormatType.NUMBER,
  PtwFormatType.ATTACHMENT,
  PtwFormatType.BOOLEAN,
  PtwFormatType.DESCRIPTION,
  PtwFormatType.HEADING,
  PtwFormatType.SINGLE_OPTION,
  PtwFormatType.MULTI_OPTION,
  PtwFormatType.IMAGE,
  PtwFormatType.DATE,
  PtwFormatType.DIVIDER,
]);

export const PtwFormFieldSchema = z.object({
  ptwFormatId: z.number(),
  ptwFormatType: z.enum([
    PtwFormatType.TEXT,
    PtwFormatType.NUMBER,
    PtwFormatType.ATTACHMENT,
    PtwFormatType.BOOLEAN,
    PtwFormatType.DESCRIPTION,
    PtwFormatType.HEADING,
    PtwFormatType.SINGLE_OPTION,
    PtwFormatType.MULTI_OPTION,
    PtwFormatType.IMAGE,
    PtwFormatType.DATE,
    PtwFormatType.DIVIDER,
  ]),
  name: z.string().optional(),
  heading: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  ptwOptions: z
    .object({
      id: z.number(),
      value: z.string(),
    })
    .array(),
});

export const PtwFormSchema = z.object({
  name: z.string().min(1, "Name / Company Name is required"),
  email: z.string().email("Invalid email address"),
  location: z.string().min(1, "This is required"),
  icNo: z.string().min(1, "This is required"),
  refNo: z.string().optional(),
  formFields: PtwFormFieldSchema.array(),
  ptwTemplate: PtwTemplateFormSchema,
  ptwAttachments: z
    .object({
      id: z.number(),
      attachments: z.object({ id: z.number(), url: z.string() }).array(),
      newAttachments: z.instanceof(File).array(),
      ptwFormatId: z.number(),
    })
    .array(),
  ptwBools: z
    .object({ id: z.number(), value: z.boolean(), ptwFormatId: z.number() })
    .array(),
  ptwDates: z
    .object({
      id: z.number(),
      date: z.coerce.date(),
      ptwFormatId: z.number(),
    })
    .array(),
  ptwTexts: z
    .object({ id: z.number(), value: z.string(), ptwFormatId: z.number() })
    .array(),
  ptwImages: z
    .object({
      id: z.number(),
      attachments: z.object({ id: z.number(), url: z.string() }).array(),
      newAttachments: z.instanceof(File).array(),
      ptwFormatId: z.number(),
    })
    .array(),
  ptwNumbers: z
    .object({
      id: z.number(),
      value: z.coerce.number(),
      ptwFormatId: z.number(),
    })
    .array(),
  ptwOptionValues: z
    .object({
      id: z.number(),
      ptwOptions: z
        .object({
          id: z.number(),
          value: z.string(),
        })
        .array()
        .or(
          z.object({
            id: z.number(),
            value: z.string(),
          })
        ),
      ptwFormatId: z.number(),
    })
    .array(),
});

export type PtwFormType = z.infer<typeof PtwFormSchema>;
export type PtwFormFieldType = z.infer<typeof PtwFormFieldSchema>;
