import * as React from "react";
import { View, Text } from "@react-pdf/renderer";
import { ItemSection } from "./ItemSection";
import { useJKRWorkOrder } from "..";

export function ProjectInfoSection() {
  const jkr = useJKRWorkOrder();

  return (
    <View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <ItemSection label="Work Order No." value={jkr.refNo ?? "-"} />
        <ItemSection
          label="Work Request No."
          value={jkr?.workRequests[0]?.trackingCode ?? "-"}
        />
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <ItemSection label="Generated Date / Time" value="31 Jul 2024 17:27" />
        <ItemSection label="Work Type" value={jkr?.defectType?.name ?? "-"} />
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <ItemSection label="Generated By" value={jkr?.createdBy?.name ?? "-"} />
        <ItemSection label="Work Priority" value="NORMAL" />
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <ItemSection label="DPA No." value="146001MYS.090019.BD0001" />
        <ItemSection label="Work Trade" value={jkr?.defectType?.name ?? "-"} />
      </View>
    </View>
  );
}
