export default function LegalComplianceLogo() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <g clipPath="url(#clip0_149_2909)">
        <path
          d="M24.9975 49.9951C38.8033 49.9951 49.9951 38.8033 49.9951 24.9975C49.9951 11.1918 38.8033 0 24.9975 0C11.1918 0 0 11.1918 0 24.9975C0 38.8033 11.1918 49.9951 24.9975 49.9951Z"
          fill="#A2DAE0"
        />
        <path
          d="M24.9972 44.6527C38.7703 44.6527 49.9357 44.2566 49.9357 43.768C49.9357 43.2794 38.7703 42.8833 24.9972 42.8833C11.224 42.8833 0.0585938 43.2794 0.0585938 43.768C0.0585938 44.2566 11.224 44.6527 24.9972 44.6527Z"
          fill="#85B6D6"
        />
        <path
          d="M40.1653 11.8695V39.3442L13.4131 39.526V6.17303L34.2968 5.99609L40.1653 11.8695Z"
          fill="#E6E6E6"
        />
        <path
          d="M36.7786 15.2215V42.6962L10.0215 42.8781V9.52508L30.9052 9.34814L36.7786 15.2215Z"
          fill="#F2F2F2"
        />
        <path
          d="M36.7787 15.6049H36.5723L30.4728 15.5852L30.4531 9.52996V9.2793L30.7038 9.52996L36.5723 15.4034L36.7787 15.6049Z"
          fill="#CCCCCC"
        />
        <path
          d="M15.2559 19.9746H32.2617"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M15.2559 24.6289H32.2617"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M15.1875 29.2783H32.1982"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M15.2559 33.9282H32.2617"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_149_2909">
          <rect
            width="50"
            height="50"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
