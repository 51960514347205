import { Download } from "@mui/icons-material";
import { Attachment } from "../redux/slices/OpenApi/cerevApi";
import { IconButton } from "@mui/material";
import AppButton from "./AppButton";
import { Trash2 } from "lucide-react";

interface AppDocumentProps {
  att: Attachment;
  className?: string;
  onDelete?: (att: Attachment) => Promise<void>;
  onDeleteLoading?: boolean;
}

export default function AppDocument({
  att,
  className,
  onDelete,
  onDeleteLoading,
}: AppDocumentProps) {
  return (
    <div
      className={`${className} flex items-center px-4 py-2 bg-neutral-300 rounded-md justify-between`}
    >
      <div className="font-sans">{att.name}</div>
      <div className="flex gap-4">
        <IconButton
          onClick={() => {
            window.open(att.url, "_blank");
          }}
        >
          <Download />
        </IconButton>
        {onDelete && (
          <AppButton
            variant="ghost"
            isLoading={onDeleteLoading}
            icon={<Trash2 className="text-red-500" />}
            onClick={() => onDelete(att)}
          />
        )}
      </div>
    </div>
  );
}
