import {Attachment, PartialAtt} from "../redux/slices/OpenApi/cerevApi";

interface AppImageProps {
  att: Attachment | PartialAtt;
  className?: string;
}

export default function AppImage({att, className}: AppImageProps) {
  return (
    <img
      src={att?.url}
      className={`w-24 rounded-xl shadow-xl object-cover ${className} hover:cursor-pointer`}
      onClick={() => {
        window.open(att.url, "_blank")
      }}
    />
  );
}
