/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CheckIsDownloadedResponseDto,
  CreatePmChecklistDto,
  GetAssetsWithPmCountResponseDto,
  GetLocationWithPmCountResponse,
  GetOnePmChecklistResponse,
  GetOpenPmChecklistsResponseDto,
  GetPlannedChecklistResponseDto,
  GetPmChecklistTableResponseDto,
  PmChecklistControllerGetAllPmChecklistsOpenParams,
  PmChecklistControllerGetAssetWithPmCountParams,
  PmChecklistControllerGetLocationsWithPmCountsParams,
  PmChecklistControllerGetPlannedPmChecklistParams,
  PmChecklistControllerGetPmChecklistCountsParams,
  PmChecklistControllerGetPmChecklistParams,
  PmChecklistControllerGetPmChecklistStatusCountParams,
  PmChecklistControllerGetPmChecklistTableParams,
  PmChecklistResponseDto,
  UpdatePmChecklistDto,
  UpdatePmChecklistPlannedTimeRequestDto,
} from ".././model";

export const pmChecklistControllerGetPmChecklist = (
  params: PmChecklistControllerGetPmChecklistParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PmChecklistResponseDto[]>> => {
  return axios.get(`/pm-checklist`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetPmChecklistQueryKey = (
  params: PmChecklistControllerGetPmChecklistParams
) => {
  return [`/pm-checklist`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistControllerGetPmChecklistInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
    PmChecklistControllerGetPmChecklistParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
        QueryKey,
        PmChecklistControllerGetPmChecklistParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetPmChecklistQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
    QueryKey,
    PmChecklistControllerGetPmChecklistParams["cursorId"]
  > = ({ signal, pageParam }) =>
    pmChecklistControllerGetPmChecklist(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
    TError,
    TData,
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
    QueryKey,
    PmChecklistControllerGetPmChecklistParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetPmChecklistInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>>;
export type PmChecklistControllerGetPmChecklistInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklistInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
    PmChecklistControllerGetPmChecklistParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
        QueryKey,
        PmChecklistControllerGetPmChecklistParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetPmChecklistInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetPmChecklistQueryOptions = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetPmChecklistQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>
  > = ({ signal }) =>
    pmChecklistControllerGetPmChecklist(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetPmChecklistQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>
>;
export type PmChecklistControllerGetPmChecklistQueryError = AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklist = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmChecklistControllerGetPmChecklistQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerCreatePmChecklist = (
  createPmChecklistDto: CreatePmChecklistDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/pm-checklist`, createPmChecklistDto, options);
};

export const getPmChecklistControllerCreatePmChecklistMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerCreatePmChecklist>>,
    TError,
    { data: CreatePmChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof pmChecklistControllerCreatePmChecklist>>,
  TError,
  { data: CreatePmChecklistDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pmChecklistControllerCreatePmChecklist>>,
    { data: CreatePmChecklistDto }
  > = (props) => {
    const { data } = props ?? {};

    return pmChecklistControllerCreatePmChecklist(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PmChecklistControllerCreatePmChecklistMutationResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerCreatePmChecklist>>
>;
export type PmChecklistControllerCreatePmChecklistMutationBody =
  CreatePmChecklistDto;
export type PmChecklistControllerCreatePmChecklistMutationError =
  AxiosError<unknown>;

export const usePmChecklistControllerCreatePmChecklist = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerCreatePmChecklist>>,
    TError,
    { data: CreatePmChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof pmChecklistControllerCreatePmChecklist>>,
  TError,
  { data: CreatePmChecklistDto },
  TContext
> => {
  const mutationOptions =
    getPmChecklistControllerCreatePmChecklistMutationOptions(options);

  return useMutation(mutationOptions);
};
export const pmChecklistControllerGetAllPmChecklistsOpen = (
  params: PmChecklistControllerGetAllPmChecklistsOpenParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOpenPmChecklistsResponseDto>> => {
  return axios.get(`/pm-checklist/status/openAll`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetAllPmChecklistsOpenQueryKey = (
  params: PmChecklistControllerGetAllPmChecklistsOpenParams
) => {
  return [`/pm-checklist/status/openAll`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistControllerGetAllPmChecklistsOpenInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
      PmChecklistControllerGetAllPmChecklistsOpenParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: PmChecklistControllerGetAllPmChecklistsOpenParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>
          >,
          QueryKey,
          PmChecklistControllerGetAllPmChecklistsOpenParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistControllerGetAllPmChecklistsOpenQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
      QueryKey,
      PmChecklistControllerGetAllPmChecklistsOpenParams["cursorId"]
    > = ({ signal, pageParam }) =>
      pmChecklistControllerGetAllPmChecklistsOpen(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
      TError,
      TData,
      Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
      QueryKey,
      PmChecklistControllerGetAllPmChecklistsOpenParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type PmChecklistControllerGetAllPmChecklistsOpenInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>
  >;
export type PmChecklistControllerGetAllPmChecklistsOpenInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetAllPmChecklistsOpenInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
    PmChecklistControllerGetAllPmChecklistsOpenParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetAllPmChecklistsOpenParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
        QueryKey,
        PmChecklistControllerGetAllPmChecklistsOpenParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetAllPmChecklistsOpenInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetAllPmChecklistsOpenQueryOptions = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetAllPmChecklistsOpenParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetAllPmChecklistsOpenQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>
  > = ({ signal }) =>
    pmChecklistControllerGetAllPmChecklistsOpen(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetAllPmChecklistsOpenQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>
  >;
export type PmChecklistControllerGetAllPmChecklistsOpenQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetAllPmChecklistsOpen = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetAllPmChecklistsOpenParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetAllPmChecklistsOpen>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetAllPmChecklistsOpenQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerGetPlannedPmChecklist = (
  params: PmChecklistControllerGetPlannedPmChecklistParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPlannedChecklistResponseDto>> => {
  return axios.get(`/pm-checklist/planned-checklist`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetPlannedPmChecklistQueryKey = (
  params: PmChecklistControllerGetPlannedPmChecklistParams
) => {
  return [
    `/pm-checklist/planned-checklist`,
    ...(params ? [params] : []),
  ] as const;
};

export const getPmChecklistControllerGetPlannedPmChecklistInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
      PmChecklistControllerGetPlannedPmChecklistParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: PmChecklistControllerGetPlannedPmChecklistParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>
          >,
          QueryKey,
          PmChecklistControllerGetPlannedPmChecklistParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistControllerGetPlannedPmChecklistQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
      QueryKey,
      PmChecklistControllerGetPlannedPmChecklistParams["cursorId"]
    > = ({ signal, pageParam }) =>
      pmChecklistControllerGetPlannedPmChecklist(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
      TError,
      TData,
      Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
      QueryKey,
      PmChecklistControllerGetPlannedPmChecklistParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type PmChecklistControllerGetPlannedPmChecklistInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>
  >;
export type PmChecklistControllerGetPlannedPmChecklistInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPlannedPmChecklistInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
    PmChecklistControllerGetPlannedPmChecklistParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPlannedPmChecklistParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
        QueryKey,
        PmChecklistControllerGetPlannedPmChecklistParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetPlannedPmChecklistInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetPlannedPmChecklistQueryOptions = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPlannedPmChecklistParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetPlannedPmChecklistQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>
  > = ({ signal }) =>
    pmChecklistControllerGetPlannedPmChecklist(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetPlannedPmChecklistQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>
>;
export type PmChecklistControllerGetPlannedPmChecklistQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPlannedPmChecklist = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPlannedPmChecklistParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPlannedPmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetPlannedPmChecklistQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerGetPmChecklistStatusCount = (
  pmStatusId: string,
  params: PmChecklistControllerGetPmChecklistStatusCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.get(`/pm-checklist/status/${pmStatusId}/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetPmChecklistStatusCountQueryKey = (
  pmStatusId: string,
  params: PmChecklistControllerGetPmChecklistStatusCountParams
) => {
  return [
    `/pm-checklist/status/${pmStatusId}/count`,
    ...(params ? [params] : []),
  ] as const;
};

export const getPmChecklistControllerGetPmChecklistStatusCountInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
      >,
      PmChecklistControllerGetPmChecklistStatusCountParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    pmStatusId: string,
    params: PmChecklistControllerGetPmChecklistStatusCountParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
          >,
          QueryKey,
          PmChecklistControllerGetPmChecklistStatusCountParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistControllerGetPmChecklistStatusCountQueryKey(
        pmStatusId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
      >,
      QueryKey,
      PmChecklistControllerGetPmChecklistStatusCountParams["cursorId"]
    > = ({ signal, pageParam }) =>
      pmChecklistControllerGetPmChecklistStatusCount(
        pmStatusId,
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return {
      queryKey,
      queryFn,
      enabled: !!pmStatusId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
      >,
      QueryKey,
      PmChecklistControllerGetPmChecklistStatusCountParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type PmChecklistControllerGetPmChecklistStatusCountInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>>
  >;
export type PmChecklistControllerGetPmChecklistStatusCountInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklistStatusCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>>,
    PmChecklistControllerGetPmChecklistStatusCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  pmStatusId: string,
  params: PmChecklistControllerGetPmChecklistStatusCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
        >,
        QueryKey,
        PmChecklistControllerGetPmChecklistStatusCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetPmChecklistStatusCountInfiniteQueryOptions(
      pmStatusId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetPmChecklistStatusCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
  >,
  TError = AxiosError<unknown>
>(
  pmStatusId: string,
  params: PmChecklistControllerGetPmChecklistStatusCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetPmChecklistStatusCountQueryKey(
      pmStatusId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>>
  > = ({ signal }) =>
    pmChecklistControllerGetPmChecklistStatusCount(pmStatusId, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmStatusId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetPmChecklistStatusCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>>
  >;
export type PmChecklistControllerGetPmChecklistStatusCountQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklistStatusCount = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
  >,
  TError = AxiosError<unknown>
>(
  pmStatusId: string,
  params: PmChecklistControllerGetPmChecklistStatusCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistControllerGetPmChecklistStatusCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetPmChecklistStatusCountQueryOptions(
      pmStatusId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerGetPmChecklistTable = (
  params: PmChecklistControllerGetPmChecklistTableParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPmChecklistTableResponseDto>> => {
  return axios.get(`/pm-checklist/fullTable`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetPmChecklistTableQueryKey = (
  params: PmChecklistControllerGetPmChecklistTableParams
) => {
  return [`/pm-checklist/fullTable`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistControllerGetPmChecklistTableInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
    PmChecklistControllerGetPmChecklistTableParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistTableParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
        QueryKey,
        PmChecklistControllerGetPmChecklistTableParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetPmChecklistTableQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
    QueryKey,
    PmChecklistControllerGetPmChecklistTableParams["cursorId"]
  > = ({ signal, pageParam }) =>
    pmChecklistControllerGetPmChecklistTable(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
    TError,
    TData,
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
    QueryKey,
    PmChecklistControllerGetPmChecklistTableParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetPmChecklistTableInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>
  >;
export type PmChecklistControllerGetPmChecklistTableInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklistTableInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
    PmChecklistControllerGetPmChecklistTableParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistTableParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
        QueryKey,
        PmChecklistControllerGetPmChecklistTableParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetPmChecklistTableInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetPmChecklistTableQueryOptions = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistTableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetPmChecklistTableQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>
  > = ({ signal }) =>
    pmChecklistControllerGetPmChecklistTable(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetPmChecklistTableQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>
>;
export type PmChecklistControllerGetPmChecklistTableQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklistTable = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistTableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistTable>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmChecklistControllerGetPmChecklistTableQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerGetLocationsWithPmCounts = (
  params: PmChecklistControllerGetLocationsWithPmCountsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetLocationWithPmCountResponse[]>> => {
  return axios.get(`/pm-checklist/location`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetLocationsWithPmCountsQueryKey = (
  params: PmChecklistControllerGetLocationsWithPmCountsParams
) => {
  return [`/pm-checklist/location`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistControllerGetLocationsWithPmCountsInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>,
      PmChecklistControllerGetLocationsWithPmCountsParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: PmChecklistControllerGetLocationsWithPmCountsParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
          >,
          QueryKey,
          PmChecklistControllerGetLocationsWithPmCountsParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistControllerGetLocationsWithPmCountsQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>,
      QueryKey,
      PmChecklistControllerGetLocationsWithPmCountsParams["cursorId"]
    > = ({ signal, pageParam }) =>
      pmChecklistControllerGetLocationsWithPmCounts(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>,
      TError,
      TData,
      Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>,
      QueryKey,
      PmChecklistControllerGetLocationsWithPmCountsParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type PmChecklistControllerGetLocationsWithPmCountsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>
  >;
export type PmChecklistControllerGetLocationsWithPmCountsInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetLocationsWithPmCountsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>,
    PmChecklistControllerGetLocationsWithPmCountsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetLocationsWithPmCountsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
        >,
        QueryKey,
        PmChecklistControllerGetLocationsWithPmCountsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetLocationsWithPmCountsInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetLocationsWithPmCountsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetLocationsWithPmCountsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetLocationsWithPmCountsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>
  > = ({ signal }) =>
    pmChecklistControllerGetLocationsWithPmCounts(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetLocationsWithPmCountsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>>
  >;
export type PmChecklistControllerGetLocationsWithPmCountsQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetLocationsWithPmCounts = <
  TData = Awaited<
    ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetLocationsWithPmCountsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof pmChecklistControllerGetLocationsWithPmCounts>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetLocationsWithPmCountsQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerGetAssetWithPmCount = (
  params: PmChecklistControllerGetAssetWithPmCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAssetsWithPmCountResponseDto[]>> => {
  return axios.get(`/pm-checklist/asset`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetAssetWithPmCountQueryKey = (
  params: PmChecklistControllerGetAssetWithPmCountParams
) => {
  return [`/pm-checklist/asset`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistControllerGetAssetWithPmCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
    PmChecklistControllerGetAssetWithPmCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetAssetWithPmCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
        QueryKey,
        PmChecklistControllerGetAssetWithPmCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetAssetWithPmCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
    QueryKey,
    PmChecklistControllerGetAssetWithPmCountParams["cursorId"]
  > = ({ signal, pageParam }) =>
    pmChecklistControllerGetAssetWithPmCount(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
    TError,
    TData,
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
    QueryKey,
    PmChecklistControllerGetAssetWithPmCountParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetAssetWithPmCountInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>
  >;
export type PmChecklistControllerGetAssetWithPmCountInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetAssetWithPmCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
    PmChecklistControllerGetAssetWithPmCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetAssetWithPmCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
        QueryKey,
        PmChecklistControllerGetAssetWithPmCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetAssetWithPmCountInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetAssetWithPmCountQueryOptions = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetAssetWithPmCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetAssetWithPmCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>
  > = ({ signal }) =>
    pmChecklistControllerGetAssetWithPmCount(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetAssetWithPmCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>
>;
export type PmChecklistControllerGetAssetWithPmCountQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetAssetWithPmCount = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetAssetWithPmCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetAssetWithPmCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmChecklistControllerGetAssetWithPmCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerGetPmChecklistCounts = (
  params: PmChecklistControllerGetPmChecklistCountsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/pm-checklist/status/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmChecklistControllerGetPmChecklistCountsQueryKey = (
  params: PmChecklistControllerGetPmChecklistCountsParams
) => {
  return [`/pm-checklist/status/count`, ...(params ? [params] : [])] as const;
};

export const getPmChecklistControllerGetPmChecklistCountsInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
      PmChecklistControllerGetPmChecklistCountsParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: PmChecklistControllerGetPmChecklistCountsParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
          TError,
          TData,
          Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
          QueryKey,
          PmChecklistControllerGetPmChecklistCountsParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPmChecklistControllerGetPmChecklistCountsQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
      QueryKey,
      PmChecklistControllerGetPmChecklistCountsParams["cursorId"]
    > = ({ signal, pageParam }) =>
      pmChecklistControllerGetPmChecklistCounts(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
      TError,
      TData,
      Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
      QueryKey,
      PmChecklistControllerGetPmChecklistCountsParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type PmChecklistControllerGetPmChecklistCountsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>
  >;
export type PmChecklistControllerGetPmChecklistCountsInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklistCountsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
    PmChecklistControllerGetPmChecklistCountsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistCountsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
        QueryKey,
        PmChecklistControllerGetPmChecklistCountsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetPmChecklistCountsInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetPmChecklistCountsQueryOptions = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistCountsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetPmChecklistCountsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>
  > = ({ signal }) =>
    pmChecklistControllerGetPmChecklistCounts(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetPmChecklistCountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>
>;
export type PmChecklistControllerGetPmChecklistCountsQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetPmChecklistCounts = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
  TError = AxiosError<unknown>
>(
  params: PmChecklistControllerGetPmChecklistCountsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetPmChecklistCounts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmChecklistControllerGetPmChecklistCountsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerGetOnePmChecklist = (
  pmChecklistId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOnePmChecklistResponse>> => {
  return axios.get(`/pm-checklist/${pmChecklistId}`, options);
};

export const getPmChecklistControllerGetOnePmChecklistQueryKey = (
  pmChecklistId: string
) => {
  return [`/pm-checklist/${pmChecklistId}`] as const;
};

export const getPmChecklistControllerGetOnePmChecklistInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>
  >,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetOnePmChecklistQueryKey(pmChecklistId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>
  > = ({ signal }) =>
    pmChecklistControllerGetOnePmChecklist(pmChecklistId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmChecklistId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetOnePmChecklistInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>
  >;
export type PmChecklistControllerGetOnePmChecklistInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetOnePmChecklistInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>
  >,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerGetOnePmChecklistInfiniteQueryOptions(
      pmChecklistId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerGetOnePmChecklistQueryOptions = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerGetOnePmChecklistQueryKey(pmChecklistId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>
  > = ({ signal }) =>
    pmChecklistControllerGetOnePmChecklist(pmChecklistId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmChecklistId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerGetOnePmChecklistQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>
>;
export type PmChecklistControllerGetOnePmChecklistQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerGetOnePmChecklist = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerGetOnePmChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmChecklistControllerGetOnePmChecklistQueryOptions(
    pmChecklistId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmChecklistControllerUpdatePmChecklist = (
  pmChecklistId: string,
  updatePmChecklistDto: UpdatePmChecklistDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/pm-checklist/${pmChecklistId}`,
    updatePmChecklistDto,
    options
  );
};

export const getPmChecklistControllerUpdatePmChecklistMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerUpdatePmChecklist>>,
    TError,
    { pmChecklistId: string; data: UpdatePmChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof pmChecklistControllerUpdatePmChecklist>>,
  TError,
  { pmChecklistId: string; data: UpdatePmChecklistDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pmChecklistControllerUpdatePmChecklist>>,
    { pmChecklistId: string; data: UpdatePmChecklistDto }
  > = (props) => {
    const { pmChecklistId, data } = props ?? {};

    return pmChecklistControllerUpdatePmChecklist(
      pmChecklistId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PmChecklistControllerUpdatePmChecklistMutationResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerUpdatePmChecklist>>
>;
export type PmChecklistControllerUpdatePmChecklistMutationBody =
  UpdatePmChecklistDto;
export type PmChecklistControllerUpdatePmChecklistMutationError =
  AxiosError<unknown>;

export const usePmChecklistControllerUpdatePmChecklist = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerUpdatePmChecklist>>,
    TError,
    { pmChecklistId: string; data: UpdatePmChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof pmChecklistControllerUpdatePmChecklist>>,
  TError,
  { pmChecklistId: string; data: UpdatePmChecklistDto },
  TContext
> => {
  const mutationOptions =
    getPmChecklistControllerUpdatePmChecklistMutationOptions(options);

  return useMutation(mutationOptions);
};
export const pmChecklistControllerDownloadedForOfflineUse = (
  pmChecklistId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/pm-checklist/${pmChecklistId}/downloaded`,
    undefined,
    options
  );
};

export const getPmChecklistControllerDownloadedForOfflineUseMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerDownloadedForOfflineUse>>,
    TError,
    { pmChecklistId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof pmChecklistControllerDownloadedForOfflineUse>>,
  TError,
  { pmChecklistId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pmChecklistControllerDownloadedForOfflineUse>>,
    { pmChecklistId: string }
  > = (props) => {
    const { pmChecklistId } = props ?? {};

    return pmChecklistControllerDownloadedForOfflineUse(
      pmChecklistId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PmChecklistControllerDownloadedForOfflineUseMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerDownloadedForOfflineUse>>
  >;

export type PmChecklistControllerDownloadedForOfflineUseMutationError =
  AxiosError<unknown>;

export const usePmChecklistControllerDownloadedForOfflineUse = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerDownloadedForOfflineUse>>,
    TError,
    { pmChecklistId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof pmChecklistControllerDownloadedForOfflineUse>>,
  TError,
  { pmChecklistId: string },
  TContext
> => {
  const mutationOptions =
    getPmChecklistControllerDownloadedForOfflineUseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const pmChecklistControllerUpdatePlannedTime = (
  pmChecklistId: string,
  updatePmChecklistPlannedTimeRequestDto: UpdatePmChecklistPlannedTimeRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/pm-checklist/${pmChecklistId}/plannedTime`,
    updatePmChecklistPlannedTimeRequestDto,
    options
  );
};

export const getPmChecklistControllerUpdatePlannedTimeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerUpdatePlannedTime>>,
    TError,
    { pmChecklistId: string; data: UpdatePmChecklistPlannedTimeRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof pmChecklistControllerUpdatePlannedTime>>,
  TError,
  { pmChecklistId: string; data: UpdatePmChecklistPlannedTimeRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pmChecklistControllerUpdatePlannedTime>>,
    { pmChecklistId: string; data: UpdatePmChecklistPlannedTimeRequestDto }
  > = (props) => {
    const { pmChecklistId, data } = props ?? {};

    return pmChecklistControllerUpdatePlannedTime(
      pmChecklistId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PmChecklistControllerUpdatePlannedTimeMutationResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerUpdatePlannedTime>>
>;
export type PmChecklistControllerUpdatePlannedTimeMutationBody =
  UpdatePmChecklistPlannedTimeRequestDto;
export type PmChecklistControllerUpdatePlannedTimeMutationError =
  AxiosError<unknown>;

export const usePmChecklistControllerUpdatePlannedTime = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmChecklistControllerUpdatePlannedTime>>,
    TError,
    { pmChecklistId: string; data: UpdatePmChecklistPlannedTimeRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof pmChecklistControllerUpdatePlannedTime>>,
  TError,
  { pmChecklistId: string; data: UpdatePmChecklistPlannedTimeRequestDto },
  TContext
> => {
  const mutationOptions =
    getPmChecklistControllerUpdatePlannedTimeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const pmChecklistControllerCheckIsDownloaded = (
  pmChecklistId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CheckIsDownloadedResponseDto>> => {
  return axios.get(`/pm-checklist/${pmChecklistId}/checkIsDownloaded`, options);
};

export const getPmChecklistControllerCheckIsDownloadedQueryKey = (
  pmChecklistId: string
) => {
  return [`/pm-checklist/${pmChecklistId}/checkIsDownloaded`] as const;
};

export const getPmChecklistControllerCheckIsDownloadedInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>
  >,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerCheckIsDownloadedQueryKey(pmChecklistId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>
  > = ({ signal }) =>
    pmChecklistControllerCheckIsDownloaded(pmChecklistId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmChecklistId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerCheckIsDownloadedInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>
  >;
export type PmChecklistControllerCheckIsDownloadedInfiniteQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerCheckIsDownloadedInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>
  >,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmChecklistControllerCheckIsDownloadedInfiniteQueryOptions(
      pmChecklistId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmChecklistControllerCheckIsDownloadedQueryOptions = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmChecklistControllerCheckIsDownloadedQueryKey(pmChecklistId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>
  > = ({ signal }) =>
    pmChecklistControllerCheckIsDownloaded(pmChecklistId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmChecklistId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmChecklistControllerCheckIsDownloadedQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>
>;
export type PmChecklistControllerCheckIsDownloadedQueryError =
  AxiosError<unknown>;

export const usePmChecklistControllerCheckIsDownloaded = <
  TData = Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
  TError = AxiosError<unknown>
>(
  pmChecklistId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmChecklistControllerCheckIsDownloaded>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmChecklistControllerCheckIsDownloadedQueryOptions(
    pmChecklistId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
