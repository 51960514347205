import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table as TableUI,
} from "@/components/ui/table";
import { Row, Table, flexRender } from "@tanstack/react-table";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import React from "react";
import { cn } from "../../lib/utils";
import AppButton from "../AppButton";
import AppProgress from "../progress/AppProgress";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink
} from "../ui/pagination";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Skeleton } from "../ui/skeleton";

interface DataTableProps<TData> {
  table: Table<TData>;
  rowsPerPage?: number;
  totalCount: number;
  pageIdx?: number;
  isFetching?: boolean;
  isLoading?: boolean;
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement;
}

export function DataTable<TData>({
  table,
  rowsPerPage,
  totalCount,
  pageIdx,
  isFetching,
  isLoading,
  renderSubComponent,
}: DataTableProps<TData>) {
  return (
    <div className="flex flex-col gap-2">
      <div className="rounded-md border">
        <TableUI>
          <TableHeader className="font-extrabold">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isFetching && !isLoading && (
              <TableRow>
                <TableCell colSpan={table.getAllColumns().length} className="p-0">
                  <AppProgress isLoading={true} />
                </TableCell>
              </TableRow>
            )}

            {isLoading ? (
              [1, 2, 3].map((idx) => (
                <TableRow key={idx}>
                  <TableCell colSpan={table.getAllColumns().length}>
                    <Skeleton className="h-4 w-full" />
                  </TableCell>
                </TableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <React.Fragment key={row.id}>
                  <TableRow
                    key={row.id}
                    // data-state={row.getIsSelected() && "selected"}
                    // className="hover:cursor-pointer"
                    // onClick={() => {
                    //   row.toggleSelected();
                    // }}
                    className={cn({
                      "border-b-0": row.getIsExpanded(),
                    })}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.getIsExpanded() && renderSubComponent && (
                    <TableRow>
                      <TableCell colSpan={table.getAllColumns().length}>
                        <div className="px-4 py-2">
                          {renderSubComponent({ row })}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={table.getAllColumns().length}
                  className="h-64 text-center text-gray-400"
                >
                  No results found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableUI>
        {(rowsPerPage !== undefined && pageIdx !== undefined) ? (
          <div className="flex items-center justify-between px-6 py-4 gap-5">
            <div className="flex gap-5 items-center">
              <p className="text-sm font-medium">Rows per page</p>
              <Select
                value={`${rowsPerPage}`}
                onValueChange={(value) => {
                  table.setPageSize(Number(value));
                }}
              >
                <SelectTrigger className="h-8 w-[70px]">
                  <SelectValue placeholder={rowsPerPage} />
                </SelectTrigger>
                <SelectContent side="top">
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <SelectItem key={pageSize} value={`${pageSize}`}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="flex gap-2">
              <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                Page {pageIdx + 1} of {table.getPageCount()}
              </div>
              <Pagination className="w-fit">
                <PaginationContent>
                  <PaginationItem>
                    <AppButton
                      className={cn({
                        "text-slate-300 cursor-not-allowed": pageIdx === 0,
                      })}
                      variant="ghost"
                      icon={<ChevronsLeft />}
                      onClick={() => {
                        table.setPageIndex(0);
                      }}
                    />
                  </PaginationItem>
                  {pageIdx > 1 && (
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                  )}
                  {Array.from({ length: 5 }, (_, idx) => idx).map((idx) => {
                    let realignedIdx = idx - 2;
                    if (table.getPageCount() - pageIdx < 3)
                      realignedIdx =
                        idx - (5 - (table.getPageCount() - pageIdx));
                    if (pageIdx + realignedIdx + 2 <= 0) return;
                    if (pageIdx + realignedIdx + 2 > table.getPageCount())
                      return;
                    return (
                      <PaginationItem key={idx}>
                        <PaginationLink
                          className="hover:cursor-pointer"
                          onClick={() =>
                            table.setPageIndex(pageIdx + realignedIdx + 1)
                          }
                          isActive={pageIdx === pageIdx + realignedIdx + 1}
                        >
                          {pageIdx + realignedIdx + 2}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  })}
                  {table.getPageCount() - pageIdx > 4 && (
                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>
                  )}
                  <PaginationItem>
                    <AppButton
                      className={cn({
                        "text-slate-300 cursor-not-allowed":
                          pageIdx === table.getPageCount() - 1,
                      })}
                      variant="ghost"
                      icon={<ChevronsRight />}
                      onClick={() => {
                        table.setPageIndex(table.getPageCount());
                      }}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
