import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export enum AssetView {
  LOCATION_BASED_VIEW = "Location based view",
  EQUIPMENT_TYPE_BASED_VIEW = "Equipment Type based view",
}

interface AssetStoreState {
  assetView: AssetView;
  actions: {
    setAssetView: (assetView: AssetView) => void;
  };
}

export const useAssetViewStore = create<AssetStoreState>()(
  persist((set, get) => ({
    assetView: AssetView.EQUIPMENT_TYPE_BASED_VIEW,
    actions: {
      setAssetView: (assetView) => {
        return set(() => ({
          assetView,
        }));
      },
    },
  }), {
    name: "asset-view", // name of the item in the storage (must be unique)
    storage: createJSONStorage(() => localStorage),
    partialize: (state) => ({
      assetView: state.assetView,
    }),
  })
);

export const useAssetView = () => useAssetViewStore((state) => state.assetView);
export const useAssetViewActions = () =>
  useAssetViewStore((state) => state.actions);
