/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type PtwControllerGetPtwsPtwStatus =
  (typeof PtwControllerGetPtwsPtwStatus)[keyof typeof PtwControllerGetPtwsPtwStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PtwControllerGetPtwsPtwStatus = {
  NEW: "NEW",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
} as const;
