import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { PmChecklistControllerGetPmChecklistTableParams } from "../../../../api/model";
import { usePmChecklistControllerGetPmChecklistTable } from "../../../../api/preventive-maintenance-checklists/preventive-maintenance-checklists";
import { DataTable } from "../../../../components/react-table/DataTable";
import useDebounce from "../../../../hooks/useDebounce";
import { PmChecklistStatus } from "../../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../../redux/store";
import { useSelectPmFilterStore } from "../../../../stores/pm/pmFilterStore";
import { pmChecklistColumns } from "./PmChecklistColumns";

interface PmTableTabProps {
  pmStatus: PmChecklistStatus;
}

export default function PmTableTab({ pmStatus }: PmTableTabProps) {
  const [searchParam, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const dateRange = useSelectPmFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectPmFilterStore((state) => state.assetTypes);
  const locationFilter = useSelectPmFilterStore(
    (state) => state.filterLocation
  );

  const queryArg = useDebounce<PmChecklistControllerGetPmChecklistTableParams>({
    value: {
      projectId: activeProj?.id ?? 0,
      pmChecklistStatusId: pmStatus.id,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      locFilterId: locationFilter?.id,
      search,
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      assetTypeIds: assetTypesFilter.map((at) => at?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  const {
    data: pmData,
    isLoading: pmDataIsLoading,
    isFetching: pmDataIsFetching,
  } = usePmChecklistControllerGetPmChecklistTable(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const table = useReactTable({
    data: pmData?.data?.data ?? [],
    columns: pmChecklistColumns.filter((col) =>
      pmStatus.name === "Open"
        ? (col.id !== "updatedByName" && col.id !== "completedOn")
        : true
    ),
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: pmData?.data?.totalCount
      ? Math.ceil(pmData?.data?.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  // reset table pagination if filters have changed
  useEffect(() => {
    table.resetPagination();
  }, [search, dateRange, assetTypesFilter, locationFilter]);

  return (
    <DataTable
      isLoading={pmDataIsLoading}
      isFetching={pmDataIsFetching}
      table={table}
      totalCount={pmData?.data?.totalCount ?? 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );
}
