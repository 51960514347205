import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import {
  useCompaniesControllerCreateProject,
  useCompaniesControllerDeleteProject,
  useCompaniesControllerDeleteProjectPhoto,
  useCompaniesControllerGetOneProject,
  useCompaniesControllerUpdateProject,
} from "../../../api/companies/companies";
import useAppStorage from "../../../hooks/useAppStorage";
import {
  Attachment
} from "../../../redux/slices/OpenApi/cerevApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import AppSelect from "../../AppSelect";
import AppTextField from "../../AppTextField";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { DRAWER_UPDATE_STATE } from "../AppDrawer";
import CompanySelection from "./components/CompanySelection";

const ProjectFormSchema = z.object({
  name: z.string().min(1, { message: "Please input a name" }),
  code: z.string().min(1, { message: "Please input a project code" }),
  company: z.object({
    id: z.number(),
    name: z.string(),
  }),
  attachment: z.instanceof(File).array().optional(),
  environmentType: z.enum(["PRODUCTION", "TESTING"]).default("TESTING"),
  subscriptionType: z
    .enum(["STARTER", "STANDARD", "ENTERPRISE"])
    .default("STANDARD"),
  userQuota: z.coerce.number().default(50),
});

export type ProjectForm = z.infer<typeof ProjectFormSchema>;

export default function ProjectFormDrawer() {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");
  const companyName = searchParams.get("companyName");
  const [uploadedPhotos, setUploadedPhotos] = useState<Attachment[]>([]);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const dispatch = useAppDispatch();

  // Pre-select logic here for company
  useEffect(() => {
    if (companyId && companyName) {
      methods.setValue("company", {
        id: parseInt(companyId),
        name: companyName,
      });
    }
  }, [companyId, companyName]);

  const { data: user } = useAuthControllerGetCurrentUser({
    query: {
      select: (res) => res.data,
    },
  });
  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutateAsync: createProject } = useCompaniesControllerCreateProject();
  const { mutateAsync: updateProject } = useCompaniesControllerUpdateProject();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] as string).includes("companies") ||
          (query.queryKey[0] as string).includes("projects") ||
          (query.queryKey[0] as string).includes("auth")
      });
      setSearchParams((param) => {
        param.delete("drawerType");
        param.delete("drawerState");
        param.delete("compId");
        param.delete("projectId");
        // Use when pre-select company logic is in place.
        param.delete("companyId");
        param.delete("companyName");
        return { ...param };
      });
    },
  });
  const { mutate: deletePhoto } = useCompaniesControllerDeleteProjectPhoto();
  // const [deletePhoto, { isError: deletePhotoIsError }] =
  //   useCompaniesControllerDeleteProjectPhoto();
  const methods = useForm<ProjectForm>({
    resolver: zodResolver(ProjectFormSchema),
    defaultValues: {
      name: "",
      code: "",
      environmentType: "TESTING",
      subscriptionType: "STANDARD",
      userQuota: 50,
    },
  });

  const { data: editProject, isLoading: editProjectIsLoading } =
    useCompaniesControllerGetOneProject(
      searchParams.get("compId") ?? "",
      searchParams.get("projectId") ?? "",
      {
        query: {
          enabled:
            !!searchParams.get("projectId") && !!searchParams.get("compId"),
          select: (res) => res.data,
        },
      }
    );

  const { mutate: deleteProject } = useCompaniesControllerDeleteProject({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("companies") ||
            (query.queryKey[0] as string).includes("projects"),
        });
        setSearchParams((param) => {
          param.delete("drawerType");
          param.delete("drawerState");
          param.delete("compId");
          param.delete("projectId");
          return { ...param };
        });
      },
    },
  });

  const onDeleteUploadedPhoto = async (att: Attachment) => {
    const newUploadedPhotos = uploadedPhotos.filter((v) => v.id !== att.id);
    if (!editProject || !editProject.id || !att.id || !editProject.companyId)
      return;
    deletePhoto({
      attachmentId: att.id,
      projectId: editProject.id,
      companyId: editProject.companyId,
    });
    setUploadedPhotos(newUploadedPhotos);
  };

  const onSubmit: SubmitHandler<ProjectForm> = async (data: ProjectForm) => {
    const { name, code, attachment } = data;
    mutate({
      files: attachment ?? [],
      mutateAsync: async (files) => {
        if (!user || !user.id) return;
        // Update Logic
        if (
          searchParams.get("drawerState") === DRAWER_UPDATE_STATE &&
          editProject
        ) {
          if (!editProject || !editProject.id || !data.company.id) return;
          return await updateProject({
            projectId: editProject.id,
            companyId: data.company.id,
            data: {
              name,
              code,
              attachments: files,
              updatedById: user.id,
              environmentType: data.environmentType,
              subscriptionType: data.subscriptionType,
              userQuota: data.userQuota,
            },
          });
        }
        // Create Logic
        await createProject({
          companyId: data.company.id.toString(),
          data: {
            name,
            code,
            attachments: files,
            createdById: user.id,
            environmentType: data.environmentType,
            subscriptionType: data.subscriptionType,
            userQuota: data.userQuota,
          },
        });
      },
    });
  };

  // Is edit mode
  useEffect(() => {
    if (
      searchParams.get("drawerState") === DRAWER_UPDATE_STATE &&
      editProject
    ) {
      if (
        !editProject.company ||
        !editProject.company.name ||
        !editProject.company.id ||
        !editProject.environmentType
      ) {
        return;
      }
      const { name, code } = editProject;
      methods.setValue("name", name ?? "");
      methods.setValue("code", code ?? "");
      methods.setValue  ("environmentType", editProject.environmentType);
      methods.setValue("subscriptionType", editProject.subscriptionType);
      methods.setValue("userQuota", editProject.userQuota);
      setUploadedPhotos(editProject.attachments ?? []);

      methods.setValue("company", {
        id: editProject.company.id,
        name: editProject.company.name,
      });
    }
  }, [searchParams, editProject]);

  if (editProjectIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {searchParams.get("drawerState") === DRAWER_UPDATE_STATE
            ? "Update"
            : "Create"}{" "}
          Project
        </p>
        <AppTextField label="Name" name="name" />
        <AppTextField label="Project Code" name="code" />
        <AppTextField label="User Quota" name="userQuota" type="number" />
        <AppSelect
          label="Environment Type"
          name="environmentType"
          defaultValue="TESTING"
          selections={[
            { label: "Testing", value: "TESTING" },
            { label: "Production", value: "PRODUCTION" },
          ]}
        />
        <AppSelect
          label="Subscription Package"
          name="subscriptionType"
          defaultValue="STANDARD"
          selections={[
            { label: "Starter", value: "STARTER" },
            { label: "Standard", value: "STANDARD" },
            { label: "Enterprise", value: "ENTERPRISE" },
          ]}
        />
        <CompanySelection />
        <Controller
          control={methods.control}
          name="attachment"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFile
                uploadedPhotos={uploadedPhotos}
                onDeleteUploadedPhoto={onDeleteUploadedPhoto}
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.attachment?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex gap-2 items-center mt-4">
          <AppButton
            id="confirm-project-btn"
            isLoading={isPending}
            label={
              searchParams.get("drawerState") === DRAWER_UPDATE_STATE
                ? "Update"
                : "Create"
            }
            className="w-40"
            onClick={methods.handleSubmit(onSubmit)}
          />
          {searchParams.get("drawerState") === DRAWER_UPDATE_STATE && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editProject?.name ?? ""}
              onDeleteConfirm={async () => {
                if (!activeComp || !editProject || !editProject.id) return;
                deleteProject({
                  companyId: activeComp?.id,
                  projectId: editProject?.id,
                });
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
