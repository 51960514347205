import { useSearchParams } from "react-router-dom";
import { useStocksControllerGetOneStockWithLedger } from "../../../../../api/stocks-inventory/stocks-inventory";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import { cn } from "../../../../../lib/utils";
import { useCallback } from "react";
import BalanceStatusBadge from "./components/BalanceStatusBadge";

export default function BalanceInfo() {
  const [searchParams, setSearchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");

  const { data: inventory, isLoading } =
    useStocksControllerGetOneStockWithLedger(inventoryId ?? "", {
      query: {
        enabled: !!inventoryId,
      },
    });

  const warningCalc =
    inventory?.data?.balance !== undefined &&
    inventory?.data?.balance !== undefined
      ? (inventory?.data?.balance ?? 0) - (inventory?.data?.warningBalance ?? 0)
      : inventory?.data.balance ?? 0;

  const getContent = useCallback(() => {
    if (warningCalc < 0)
      return (
        <>
          <ThumbsDown className="w-8 h-8" />
          <p>Insufficient</p>
        </>
      );
    if (warningCalc > 0)
      return (
        <>
          <ThumbsUp className="w-8 h-8" />
          <p>Sufficient</p>
        </>
      );
  }, [warningCalc]);

  return (
    <div className="flex gap-4 ">
      <div className="w-full p-4 flex flex-col gap-2 bg-neutral-300 rounded-xl items-center">
        <p className="text-4xl text-primary-900 font-bold">
          {inventory?.data?.balance ?? "0"}
        </p>
        <p className="text-xl font-bold">nos. left</p>
      </div>
      {inventory?.data?.balance !== 0 && (
        <BalanceStatusBadge
          balance={inventory?.data?.balance}
          warningBalance={inventory?.data?.warningBalance}
        />
      )}
    </div>
  );
}
