// Feature toggle hook to determine if a feature is enabled or not

import { useFeatureToggleControllerGetFeatures } from "../api/feature-toggles/feature-toggles";
import { FeatureType } from "../api/model";
import { useAppSelector } from "../redux/store";

interface useFeatureProps {
  projectId?: string;
}

// Core feature toggle hook
export function useFeatures({ projectId }: useFeatureProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const queryProjectId =
    !projectId && activeProj && activeProj.id
      ? activeProj.id.toString()
      : projectId;

  const { data: featureList, isLoading } =
    useFeatureToggleControllerGetFeatures(queryProjectId ?? "", {
      query: {
        enabled: !!queryProjectId,
        select: (res) => res.data.data,
      },
    });

  return {
    [FeatureType.SCHEDULER]:
      featureList?.find((f) => f.featureType === FeatureType.SCHEDULER)
        ?.isEnabled ?? false,
    [FeatureType.BIM]:
      featureList?.find((f) => f.featureType === FeatureType.BIM)?.isEnabled ??
      false,
    [FeatureType.SERIALIZED_INVENTORY]:
      featureList?.find(
        (f) => f.featureType === FeatureType.SERIALIZED_INVENTORY
      )?.isEnabled ?? false,
    [FeatureType.CUSTOM_PTW]:
      featureList?.find((f) => f.featureType === FeatureType.CUSTOM_PTW)
        ?.isEnabled ?? false,
    isLoading,
    [FeatureType.PDF_REPORT]:
      featureList?.find((f) => f.featureType === FeatureType.PDF_REPORT)
        ?.isEnabled ?? false,
    [FeatureType.WR_SAVE_USER_INFO]:
      featureList?.find((f) => f.featureType === FeatureType.WR_SAVE_USER_INFO)
        ?.isEnabled ?? false,
    [FeatureType.WR_RATING]:
      featureList?.find((f) => f.featureType === FeatureType.WR_RATING)
        ?.isEnabled ?? false,
    [FeatureType.MAN_HOURS]:
      featureList?.find((f) => f.featureType === FeatureType.MAN_HOURS)
        ?.isEnabled ?? false,
    [FeatureType.SIGNATORY]:
      featureList?.find((f) => f.featureType === FeatureType.SIGNATORY)
        ?.isEnabled ?? false,
    [FeatureType.WO_PROFIT_LOSS]:
      featureList?.find((f) => f.featureType === FeatureType.WO_PROFIT_LOSS)
        ?.isEnabled ?? false,
    [FeatureType.INVENTORY_LOCATION]:
      featureList?.find((f) => f.featureType === FeatureType.INVENTORY_LOCATION)
        ?.isEnabled ?? false,
    [FeatureType.MODULE_ACCESS]:
      featureList?.find((f) => f.featureType === FeatureType.MODULE_ACCESS)
        ?.isEnabled ?? false,
    [FeatureType.PRIVATE_ATTACHMENT]:
      featureList?.find((f) => f.featureType === FeatureType.PRIVATE_ATTACHMENT)
        ?.isEnabled ?? false,
    [FeatureType.ESCALATION]:
      featureList?.find((f) => f.featureType === FeatureType.ESCALATION)
        ?.isEnabled ?? false,
    [FeatureType.EXTRA_FIELD_PRICING]:
      featureList?.find(
        (f) => f.featureType === FeatureType.EXTRA_FIELD_PRICING
      )?.isEnabled ?? false,
    [FeatureType.SUB_EQUIPMENT]:
      featureList?.find((f) => f.featureType === FeatureType.SUB_EQUIPMENT)
        ?.isEnabled ?? false,
  };
}

// Break out to individual hooks for each feature
export function useSchedulerEnabled(dto?: useFeatureProps) {
  const { [FeatureType.SCHEDULER]: schedulerEnabled } = useFeatures({
    projectId: dto?.projectId,
  });
  return schedulerEnabled;
}

export function useBimEnabled(dto?: useFeatureProps) {
  const { [FeatureType.BIM]: bimEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return bimEnabled;
}

export function useSerialiedInventoryEnabled(dto?: useFeatureProps) {
  const { [FeatureType.SERIALIZED_INVENTORY]: serializedInventoryEnabled } =
    useFeatures({
      projectId: dto?.projectId,
    });

  return serializedInventoryEnabled;
}

export function useCustomWorkPermitEnabled(dto?: useFeatureProps) {
  const { [FeatureType.CUSTOM_PTW]: customWorkPermitEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return customWorkPermitEnabled;
}

export function usePdfReportEnabled(dto?: useFeatureProps) {
  const { [FeatureType.PDF_REPORT]: pdfReportEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return pdfReportEnabled;
}

export function useWrSaveUserInfoEnabled(dto?: useFeatureProps) {
  const { [FeatureType.WR_SAVE_USER_INFO]: wrSaveUserInfoEnabled } =
    useFeatures({
      projectId: dto?.projectId,
    });

  return wrSaveUserInfoEnabled;
}

export function useWrRatingIsEnabled(dto?: useFeatureProps) {
  const { [FeatureType.WR_RATING]: wrRatingEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return wrRatingEnabled;
}

export function useManHoursEnabled(dto?: useFeatureProps) {
  const { [FeatureType.MAN_HOURS]: manHoursEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return manHoursEnabled;
}

export function useSignatoryEnabled(dto?: useFeatureProps) {
  const { [FeatureType.SIGNATORY]: signatoryEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return signatoryEnabled;
}

export function useWoProfitLossEnabled(dto?: useFeatureProps) {
  const { [FeatureType.WO_PROFIT_LOSS]: woProfitLossEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return woProfitLossEnabled;
}

export function useInventoryLocationEnabled(dto?: useFeatureProps) {
  const { [FeatureType.INVENTORY_LOCATION]: inventoryLocationEnabled } =
    useFeatures({
      projectId: dto?.projectId,
    });

  return inventoryLocationEnabled;
}

export function useModuleAccessEnabled(dto?: useFeatureProps) {
  const { [FeatureType.MODULE_ACCESS]: moduleAccessEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return moduleAccessEnabled;
}

export function usePrivateAttachmentEnabled(dto?: useFeatureProps) {
  const { [FeatureType.PRIVATE_ATTACHMENT]: moduleAccessEnabled } = useFeatures(
    {
      projectId: dto?.projectId,
    }
  );

  return moduleAccessEnabled;
}

export function useEscalationEnabled(dto?: useFeatureProps) {
  const { [FeatureType.ESCALATION]: moduleAccessEnabled } = useFeatures({
    projectId: dto?.projectId,
  });

  return moduleAccessEnabled;
}

export function useExtraFieldPricingEnabled(dto?: useFeatureProps) {
  const { [FeatureType.EXTRA_FIELD_PRICING]: moduleAccessEnabled } =
    useFeatures({
      projectId: dto?.projectId,
    });

  return moduleAccessEnabled;
}

export function useSubEquipmentEnabled(dto?: useFeatureProps) {
  const { [FeatureType.SUB_EQUIPMENT]: moduleAccessEnabled } =
    useFeatures({
      projectId: dto?.projectId,
    });

  return moduleAccessEnabled;
}
