import { useParams } from "react-router-dom";
import {
  useCustomCsvControllerGetCustomCsvSettings,
  useCustomCsvControllerGetOneCustomCsvSetting,
} from "../../api/custom-csv/custom-csv";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAppSelector } from "../../redux/store";
import { useCSVDownloader } from "react-papaparse";
import { CerevLogo } from "../../icons/CerevLogo";
import { Download, Loader2, XCircle } from "lucide-react";
import AppButton from "../../components/AppButton";

export default function CustomCsvDownloadingScreen() {
  const { csvSettingId } = useParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data, isLoading: settingIsLoading } =
    useCustomCsvControllerGetOneCustomCsvSetting(
      activeProj?.id?.toString() ?? "",
      csvSettingId as string,
      {
        query: {
          enabled: !!csvSettingId && !!activeProj?.id,
          select: (res) => res.data,
        },
      }
    );

  const { data: csvData, isLoading: csvDataIsLoading } = useQuery({
    queryFn: () => {
      if (data) {
        const newEndpoint = data?.endpoint.replace(
          /:projectId\//g,
          `${activeProj?.id}/`
        );
        return axios.get(`/custom-csv/` + newEndpoint);
      } else {
        return;
      }
    },
    queryKey: ["custom-csv"],
    enabled: !!activeProj && !!data,
    select: (res) => res?.data,
  });

  const { CSVDownloader, Type } = useCSVDownloader();

  if (csvDataIsLoading || settingIsLoading)
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Fetching your data...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );

  if (!data && !csvData) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <XCircle className="text-red-500  w-12 h-12" />
          <p className="text-red-500 font-bold">Something went wrong</p>

        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="h-20 w-20 mb-2">
        <CerevLogo />
      </div>
      <p className="font-bold text-2xl mb-6">Cerev</p>
      <div className="flex flex-col items-center justify-center gap-4">
        <CSVDownloader
          type={Type.Link}
          filename={data?.name}
          data={csvData ?? []}
        >
          <AppButton label="Download" variant="outline" icon={<Download />} />
        </CSVDownloader>
        <p className="text-slate-900 font-bold">
          Your data is ready to download
        </p>
      </div>
    </div>
  );
}
