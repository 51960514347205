import { AccessPermission, CerevModule } from "../api/model";
import { useModuleAccessControllerGetAllUserModuleAccess } from "../api/module-access/module-access";
import { useAppSelector } from "../redux/store";
import useCurrentUser from "./useCurrentUser/useCurrentUser";

export default function useModuleAccess() {
  const { data: user } = useCurrentUser();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: userModuleData } =
    useModuleAccessControllerGetAllUserModuleAccess(
      user?.id?.toString() ?? "",
      { projectId: activeProj?.id ?? 0 },
      {
        query: {
          enabled: !!user && !!activeProj,
          select: (res) => res.data,
        },
      }
    );

  return {
    workOrderHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.WORK_ORDER)
        ?.access === AccessPermission.ALLOWED ?? false,
    workRequestHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.WORK_REQUEST)
        ?.access === AccessPermission.ALLOWED ?? false,
    pmHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.PM)?.access ===
        AccessPermission.ALLOWED ?? false,
    vendorHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.VENDOR)?.access ===
        AccessPermission.ALLOWED ?? false,
    assetHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.EQUIPMENT)
        ?.access === AccessPermission.ALLOWED ?? false,
    inventoryHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.INVENTORY)
        ?.access === AccessPermission.ALLOWED ?? false,
    workPermitHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.WORK_PERMIT)
        ?.access === AccessPermission.ALLOWED ?? false,
    legalComplianceHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.LEGAL)?.access ===
        AccessPermission.ALLOWED ?? false,
    dashboardHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.DASHBOARD)
        ?.access === AccessPermission.ALLOWED ?? false,
    tnbBillHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.TNB_BILLS)
        ?.access === AccessPermission.ALLOWED ?? false,
    metersHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.METERS)?.access ===
        AccessPermission.ALLOWED ?? false,
    escalationHasAccess:
      userModuleData?.find((m) => m.module === CerevModule.ESCALATION)?.access ===
        AccessPermission.ALLOWED ?? false,
  };
}
