import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { cn } from "../lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

interface AppSelectProps {
  label?: string;
  className?: string;
  name: string;
  placeholder?: string;
  selections?: { label: string; value: string }[];
  defaultValue: string;
}

export default function AppSelect({
  label,
  name,
  className,
  placeholder,
  selections,
  defaultValue,
}: AppSelectProps) {
  const form = useFormContext();

  return (
    <div className={cn(`flex flex-col ${className}`)}>
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{label}</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger
                    className=" focus:ring-primary-900 bg-slate-50"
                    defaultValue={defaultValue}
                  >
                    <SelectValue placeholder={placeholder ?? "Select.."} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {selections?.map((sel) => (
                    <SelectItem value={sel.value} key={sel.value}>
                      {sel.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </div>
  );
}
