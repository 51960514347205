import { RefObject } from "@fullcalendar/core/preact";
import { ChevronLeft, ChevronRight, LogOut } from "lucide-react";
import { useState } from "react";
import { ImperativePanelHandle } from "react-resizable-panels";
import { useLocation } from "react-router-dom";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import { Company } from "../../../api/model";
import { Button } from "../../../components/ui/button";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { Separator } from "../../../components/ui/separator";
import { TooltipProvider } from "../../../components/ui/tooltip";
import { auth } from "../../../firebase/firebaseConfig";
import useGetDrawerNavigation from "../../../hooks/useGetDrawerNavigation";
import { CerevLogo } from "../../../icons/CerevLogo";
import { setActiveCompany } from "../../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { cn } from "../../../utils/util";
import AppNavLink from "./AppNavLink";

interface AppSideNavigationDrawerProps {
  panelRef: RefObject<ImperativePanelHandle>;
}

export default function AppSideNavigationDrawer({
  panelRef,
}: AppSideNavigationDrawerProps) {
  const { navigationLinks } = useGetDrawerNavigation();
  const { data: userData } = useAuthControllerGetCurrentUser({
    query: {
      select: (user) => user.data,
    },
  });
  const dispatch = useAppDispatch();
  const changeDrawer = () => {
    if (panelRef.current?.isCollapsed()) {
      panelRef.current?.expand();
    } else {
      panelRef.current?.collapse();
    }
  };
  const changeCompany = (comp: Company) => {
    dispatch(
      setActiveCompany({
        comp,
        proj:
          userData?.projects.filter((p) => p.companyId === comp.id)[0] ?? null,
      })
    );
    handleClose();
  };
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const location = useLocation();
  const isSettingScreen = location.pathname.includes("/home/settings");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenDrawer = useAppSelector(
    (state) => state.root.isMinimizedMainDrawer
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={cn(
        "bg-neutral-900 shadow-2xl flex flex-col transition-all duration-300 ease-in-out"
      )}
    >
      <ScrollArea className="h-screen">
        <div
          className={`flex items-center gap-4 mb-6 transition-all duration-300 ease-in-out pt-5 ${
            isOpenDrawer ? "px-6" : "px-2"
          }`}
        >
          <div className={`h-10 w-10 duration-300 transition-all pt-2`}>
            <CerevLogo />
          </div>
          <div className={`flex flex-col ${isOpenDrawer ? "" : "hidden"}`}>
            <p
              className={`font-sans text-neutral-50 font-medium text-2xl transition-all duration-300 truncate ${
                isOpenDrawer ? "" : "hidden"
              }`}
            >
              Cerev CMMS
            </p>
            <Select
              onValueChange={(v) => {
                const idenComp = userData?.companies.filter(
                  (c) => c.id.toString() === v
                )[0];
                if (idenComp) return changeCompany(idenComp);
              }}
              value={activeComp?.id.toString() ?? ""}
            >
              <SelectTrigger
                className="w-full text-white border-none h-10 pl-0 bg-transparent text-left"
                data-testid="company-select"
              >
                <SelectValue
                  placeholder="Select a company"
                  className={cn(
                    // "font-sans text-neutral-50 transition-all text-xs w-full text-left pl-0 bg-red-400 duration-300",
                    {
                      hidden: isOpenDrawer,
                    }
                  )}
                />
              </SelectTrigger>
              <SelectContent>
                {userData?.companies.map((comp) => (
                  <SelectItem
                    value={comp.id.toString()}
                    key={comp.id}
                    onClick={() => changeCompany(comp)}
                  >
                    {comp.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <p
              className={`font-sans text-neutral-100 font-thin text-sm transition-all duration-300 ${
                isOpenDrawer ? "" : "hidden"
              }`}
            >
              v2.7.5
            </p>
          </div>
        </div>
        <TooltipProvider delayDuration={0}>
          {navigationLinks.map((nav) => (
            <AppNavLink
              to={nav.link}
              label={nav.name}
              icon={nav.icon}
              key={nav.link}
              isMini={!isOpenDrawer}
            />
          ))}
        </TooltipProvider>

        <Separator className="bg-neutral-700 my-2" />
        <Button
          data-testid="logout-btn"
          onClick={() => {
            auth.signOut();
          }}
          variant="ghost"
          asChild
          className={cn(
            "hover:cursor-pointer hover:bg-primary-800 w-full flex gap-2 justify-start group py-6",
            {
              "gap-0 pl-3": !isOpenDrawer,
            }
          )}
        >
          <div className={cn("flex gap-2 mx-2", { "gap-0": !isOpenDrawer })}>
            <LogOut className="text-slate-300" />
            <p className="text-slate-300 text-lg">{isOpenDrawer && "Logout"}</p>
          </div>
        </Button>
        <div className="flex justify-end">
          <Button
            variant="ghost"
            asChild
            className={cn(
              "my-4 p-1 mr-4 hover:cursor-pointer hover:bg-transparent",
              {
                "gap-0 pl-1": !isOpenDrawer,
              }
            )}
            onClick={changeDrawer}
          >
            {isOpenDrawer ? (
              <ChevronLeft className="text-slate-300 h-10 w-10 hover:text-slate-200" />
            ) : (
              <ChevronRight className="text-slate-300 h-10 w-10 hover:text-slate-200" />
            )}
          </Button>
        </div>
        <ScrollBar />
      </ScrollArea>
    </div>
  );
}
