import { Trash2 } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card";
import {
  useBimActions,
  useBimEquipmentLinkingMode,
  useSelectedBimNodes,
} from "../../../../../stores/bimStore/bimStore";
import AppButton from "../../../../../components/AppButton";
import { Props, useThree } from "@react-three/fiber";
import { useBimControllerLinkBimNodesToAsset } from "../../../../../api/bim/bim";
import { useAssetControllerGetFullAssets } from "../../../../../api/assets/assets";
import { useAppSelector } from "../../../../../redux/store";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AppSelectWithDialog from "../../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { assetColumns } from "../../../../../components/drawers/workOrders/columns/assetColumns";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "../../../../../lib/utils";

const BimLinkageSchema = z.object({
  asset: z.object(
    {
      id: z.number(),
      name: z.string(),
      locationId: z.number(),
    },
    {
      required_error: "Please select an equipment",
    }
  ),
});

type BimLinkageForm = z.infer<typeof BimLinkageSchema>;

export default function BimToolsEquipmentLinkSection() {
  const bimNodes = useSelectedBimNodes();
  const isEquipmentLinkingMode = useBimEquipmentLinkingMode();
  const { removeSelectedBimNodes } = useBimActions();
  const qc = useQueryClient();
  const { mutate, isPending } = useBimControllerLinkBimNodesToAsset({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => (query.queryKey[0] as string).includes("bim"),
        });
      },
    },
  });
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: fullAssetList } = useAssetControllerGetFullAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const methods = useForm<BimLinkageForm>({
    resolver: zodResolver(BimLinkageSchema),
    defaultValues: {
      asset: undefined,
    },
  });

  const onSubmit: SubmitHandler<BimLinkageForm> = (data) => {
    mutate({
      assetId: data.asset.id,
      data: {
        bimNodeIds: bimNodes.map((node) => node.id),
      },
    });
  };

  return (
    <Card
      className={cn("absolute top-4 right-4 max-w-[300px]", {
        hidden: !isEquipmentLinkingMode,
      })}
    >
      <CardHeader>
        <CardTitle>Equipment Link Tool</CardTitle>
        <CardDescription>
          Adjust, setup equipment to link with BIM model
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-2">
          <div>Selected Bim Nodes</div>
          <div className="flex flex-col gap-2">
            {bimNodes.map((node) => (
              <div key={node.name} className="flex gap-2">
                <p className="truncate">{node.name}</p>
                <AppButton
                  variant="ghost"
                  onClick={() => removeSelectedBimNodes(node)}
                  icon={<Trash2 className="text-red-500 w-6 h-6" />}
                />
              </div>
            ))}
          </div>
          <FormProvider {...methods}>
            <AppSelectWithDialog
              label="Equipment"
              placeholder="Select an Equipment"
              columns={assetColumns}
              defaultValue={undefined}
              control={methods.control}
              name="asset"
              items={fullAssetList?.data ?? []}
              onResultRender={(item, idx) => (
                <div key={idx} className="flex flex-col">
                  <div className="font-medium">{item?.name}</div>
                  <div className="font-thin mt-1">
                    {item?.assetType?.name ?? "-"}
                  </div>
                </div>
              )}
              onOptionsRender={(item, idx) => (
                <div key={idx} className="flex flex-col">
                  <div className="font-medium">{item?.name}</div>
                  <div className="font-thin mt-1">
                    {item?.assetType?.name ?? "-"}
                  </div>
                </div>
              )}
              dialogTitle="Select an Equipment"
              error={!!methods.formState.errors.asset?.message}
              helperText={methods.formState.errors.asset?.message}
            />
            <AppButton
              label="Link"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </FormProvider>
        </div>
      </CardContent>
    </Card>
  );
}
