import { useFormContext, useWatch } from "react-hook-form";
import { WorkRequestForm } from "../components/PublicWorkRequestForm";
import AppSelectWithDialog from "../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import {
  WorkRequestSetting,
  WorkRequestSettingAssetRequired,
} from "../../../../api/model";

interface AssetSelectionWithDialogProps {
  workRequestSetting?: WorkRequestSetting;
}

export default function AssetSelectionWithDialog({
  workRequestSetting,
}: AssetSelectionWithDialogProps) {
  const methods = useFormContext<WorkRequestForm>();

  const asset = useWatch({ control: methods.control, name: "asset" });

  //   Because equipment is usually sensitive, and not exposed to others, only allow it if a pre-selection is in place
  //      and not exposiing the full list for everyone to see in public page on what equipments are available
  if (!asset || !workRequestSetting) return <></>;
  return (
    <div className="space-y-1">
      <p className="font-sans font-medium text-sm">Equipment</p>
      <div className="px-3 py-2 font-sans text-white font-medium flex w-full bg-primary-900 rounded-md">
        {asset.name}
      </div>
    </div>
  );
}
