import {useEffect} from "react";
import {setOpenSnackBar, SnackBarType} from "../redux/slices/RootSlice";
import {useAppDispatch} from "../redux/store";

export default function useError({
  dependencies,
}: {
  dependencies: { isError: boolean; error: any }[];
}) {
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if (dependencies.map((d) => d.isError).includes(true)) {
        dependencies.forEach((d) => {
          if (d.isError) {
            dispatch(
              setOpenSnackBar({
                type: SnackBarType.Error,
                msg: d?.error?.data?.message?.toString() ?? "Something went wrong",
              })
            );
          }
        });
      }
    },
    dependencies.map((d) => d.isError) // only monitor isError
  );
}
