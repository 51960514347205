import { ChevronLeft, Pen, Trash2 } from "lucide-react";
import AppButton from "../../../../../components/AppButton";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AppTitle from "../../../../HomeScreen/components/AppTitle";
import { useCompaniesControllerGetOneProject } from "../../../../../api/companies/companies";
import { useEffect } from "react";
import { useProjectControllerFindOneProject } from "../../../../../api/projects/projects";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../../components/ui/tabs";
import useAppTabsNavigation from "../../../../../hooks/useAppTabsNavigation";
import SummaryTab from "./tabs/SummaryTab/SummaryTab";
import UsersTab from "./tabs/UsersTab/UsersTab";
import InvoicesTab from "./tabs/InvoicesTab";
import OthersTab from "./tabs/OthersTab";
import {
  DRAWER_UPDATE_STATE,
  PROJECT_FORM_DRAWER,
} from "../../../../../components/drawers/AppDrawer";
import { Badge } from "../../../../../components/ui/badge";
import { cn } from "../../../../../lib/utils";
import ProjectPDFTab from "./tabs/PDFTabs/ProjectPDFTab";

export default function ProjectDetailScreen() {
  const [, setSearchParams] = useSearchParams();
  const nav = useNavigate();
  const { projectId } = useParams();
  // const {data: project} = useProjectController
  const { data: project } = useProjectControllerFindOneProject(
    projectId as string,
    {
      query: {
        select: (res) => res.data.data,
      },
    }
  );

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "projectIdx",
    defaultValue: "0",
  });

  return (
    <div className="flex flex-col gap-4">
      <AppButton
        icon={<ChevronLeft />}
        className="text-slate-800 bg-slate-50 hover:bg-slate-200 w-fit"
        label="Go back"
        onClick={() => {
          nav("/home/super-admin/companies/" + project?.companyId);
        }}
      />
      <div className="flex gap-6">
        <div>
          <AppTitle title={project?.name ?? ""} className="mb-1" />
          <div className="flex gap-4">
            <Badge
              className={cn("bg-slate-400", {
                "bg-green-500": project?.environmentType === "PRODUCTION",
              })}
            >
              {project?.environmentType ?? "-"}
            </Badge>
            <p className="text-sm text-slate-500">
              Under{" "}
              <Link
                className="text-primary-900 underline hover:cursor-pointer"
                to={`/home/super-admin/companies/${project?.companyId}`}
              >
                {project?.company?.name ?? "-"}
              </Link>
            </p>
          </div>
        </div>
        <AppButton
          label="Edit"
          variant="outline"
          icon={<Pen />}
          onClick={() => {
            if (!project || !project.companyId) return;
            setSearchParams((p) => {
              p.set("drawerType", PROJECT_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("compId", project.companyId?.toString() ?? "");
              p.set("projectId", projectId as string);
              return p;
            });
          }}
        />
      </div>
      <Tabs value={currentTabIdx} onValueChange={onTabChange} className="mb-4">
        <TabsList className="mb-2">
          {[
            { name: "Summary" },
            { name: "Users" },
            { name: "PDF Settings"},
            // { name: "Invoices" },
            // { name: "Others" },
          ].map((tab, idx) => (
            <TabsTrigger
              className="flex items-center"
              key={idx}
              value={idx.toString()}
            >
              <span className="mr-2">{tab.name}</span>
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value={"0"}>
          <SummaryTab />
        </TabsContent>
        <TabsContent value={"1"}>
          <UsersTab />
        </TabsContent>
        <TabsContent value={"2"}>
          <ProjectPDFTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}
