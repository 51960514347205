import { useEffect, useState } from "react";
import { AppDateRangePicker } from "../../../../components/AppDateRangePicker";
import { DateRange } from "react-day-picker";
import AppButton from "../../../../components/AppButton";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  usePdfReportActions,
  usePdfReportDateRange,
} from "../../../../stores/pdfReportStore/pdfReportStore";
import { endOfMonth, startOfMonth } from "date-fns";

const workOrderFilterSchema = z.object({
  dateRange: z.object({
    from: z.date(),
    to: z.date(),
  }),
});

type WorkOrderFilterForm = z.infer<typeof workOrderFilterSchema>;

export default function WorkOrderSummaryFilter() {
  const { setDateRange } = usePdfReportActions();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
    reset,
  } = useForm<WorkOrderFilterForm>({
    resolver: zodResolver(workOrderFilterSchema),
    defaultValues: {
      dateRange: {
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
      },
    },
  });

  const onConfirm: SubmitHandler<WorkOrderFilterForm> = (data) => {
    setDateRange(data.dateRange as DateRange);
    reset({
      dateRange: data.dateRange,
    });
  };

  return (
    <div className="h-full w-full flex flex-col space-y-4">
      <Controller
        name="dateRange"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <AppDateRangePicker
              date={value}
              setDate={onChange}
              onClear={() => onChange(undefined)}
              hideOnClear
            />
          );
        }}
      />
      {isDirty && (
        <AppButton label="Update" onClick={handleSubmit(onConfirm)} />
      )}
    </div>
  );
}
