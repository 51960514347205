import { useSearchParams } from "react-router-dom";
import { useCommentControllerGetLogs } from "../../../../../api/comments/comments";
import { useAppSelector } from "../../../../../redux/store";
import DrawerFormSkeleton from "../../../../skeletons/DrawerFormSkeleton";
import AppTextField from "../../../../AppTextField";
import { FormProvider, useForm } from "react-hook-form";
import LogsSection from "./LogsSection/LogsSection";

export default function WorkOrderLogTab() {
  const [searchParam] = useSearchParams();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">Activity Logs</h2>
          <p className="text-sm">
            View history of changes made to the work order
          </p>
        </div>
      </div>
      <LogsSection />
    </div>
  );
}
