import { ColumnDef } from "@tanstack/react-table";
import { PmChecklist } from "../../../../../../api/model";
import { Link } from "react-router-dom";
import AppButton from "../../../../../AppButton";

export const nestedPmColumns: ColumnDef<PmChecklist>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "refNo",
    header: "Ref No",
  },
  {
    header: "Actions",
    cell: ({ row }) => {
      const pmId = row.getValue("id");
      const url = `${window.location.origin}/home/pm?drawerType=PM_CHECKLIST_DETAIL_DRAWER&drawerState=VIEW&pmChecklistId=${pmId}`;
      return (
        <Link to={url} target="_blank">
          <AppButton label="View" variant="outline" />
        </Link>
      );
    },
  },
];
