import { LedgersDto } from "../../../../../../../../../api/model";
import { useSerializedInventoryControllerGetAvailableSerialInventories } from "../../../../../../../../../api/stocks-inventory/stocks-inventory";
import { useWorkOrderInventoryActions } from "../../../../../../../../../stores/workOrderInventoryStore/workOrderInventoryStore";
import SerialItem from "./SerialItem";

interface SerialListProps {
  ledger: LedgersDto;
}

export default function SerialList({ ledger }: SerialListProps) {
  const { data: serials } =
    useSerializedInventoryControllerGetAvailableSerialInventories(
      ledger.stockId,
      {
        query: {
          select: (res) => res.data.data,
        },
      }
    );

  const { getIsSelectedAsSerial, setSerializedInventory } =
    useWorkOrderInventoryActions();

  return (
    <div className="grid grid-cols-6 gap-4">
      {serials?.map((s) => (
        <SerialItem
          key={s.serial}
          serial={s.serial}
          value={getIsSelectedAsSerial(ledger.stockId, s.serial)}
          onChange={(value) =>
            setSerializedInventory({
              stock: ledger.stock,
              serial: {
                id: s.id,
                serial: s.serial,
              },
            })
          }
        />
      ))}
    </div>
  );
}
