/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType =
  (typeof WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType)[keyof typeof WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType =
  {
    PLANNED: "PLANNED",
    UNPLANNED: "UNPLANNED",
  } as const;
