import { useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  AddPmTemplateDtoRepeatType,
  FrequencySettingFrequencySettingType,
} from "../../../../api/model";
import { cn } from "../../../../utils/util";
import { Input } from "../../../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Toggle } from "../../../ui/toggle";
import AppTextField from "../../../AppTextField";
import PmTemplateMonthField from "./PmTemplateMonthField";
import PmTemplateWeeklyField from "./PmTemplateWeeklyField";
import PmTemplateYearlyField from "./PmTemplateYearlyField";

function modifyString(input: string, valueToModify: string): string {
  const values = input.split(",");
  const index = values.indexOf(valueToModify);

  if (index !== -1) {
    values.splice(index, 1); // Remove if the value is present
  } else {
    values.push(valueToModify); // Add if the value is not present
  }

  if (values.length === 0) {
    return "*"; // Return an empty string if there are no values
  }

  return values.filter((v) => v !== "*").join(",");
}

const monthList = [
  { monthNo: 1, monthName: "January" },
  { monthNo: 2, monthName: "February" },
  { monthNo: 3, monthName: "March" },
  { monthNo: 4, monthName: "April" },
  { monthNo: 5, monthName: "May" },
  { monthNo: 6, monthName: "June" },
  { monthNo: 7, monthName: "July" },
  { monthNo: 8, monthName: "August" },
  { monthNo: 9, monthName: "September" },
  { monthNo: 10, monthName: "October" },
  { monthNo: 11, monthName: "November" },
  { monthNo: 12, monthName: "December" },
];

interface AppCronFieldProps {
  name: string;
}

export default function AppCronField({ name }: AppCronFieldProps) {
  const [yearlyMonth, setYearlyMonth] = useState<{
    monthNo: number;
    monthName: string;
  }>(monthList[0]);
  const methods = useFormContext();
  const frequencySettingType = useWatch({
    control: methods.control,
    name,
  });

  switch (frequencySettingType) {
    case FrequencySettingFrequencySettingType.DAILY:
      return (
        <div className="font-bold font-sans">
          This checklist repeats everyday
        </div>
      );
    case FrequencySettingFrequencySettingType.WEEKLY:
      return <PmTemplateWeeklyField />;
    case FrequencySettingFrequencySettingType.BY_MONTH:
      return <PmTemplateMonthField />;
    case FrequencySettingFrequencySettingType.BY_YEAR:
      return <PmTemplateYearlyField />;
  }

  // return (
  //   <Controller
  //     name={name}
  //     control={methods.control}
  //     render={({ field: { onChange, value }, fieldState: { error } }) => {
  //       switch (frequencySettingType) {
  //         // Daily
  //         case FrequencySettingFrequencySettingType.DAILY:
  //           // Occurs everyday at 12am.
  //           return (
  //             <div className="font-bold font-sans">
  //               This checklist repeats everyday
  //             </div>
  //           );

  //         // Weekly
  //         case FrequencySettingFrequencySettingType.WEEKLY:

  //         case FrequencySettingFrequencySettingType.BY_MONTH:
  //           // Continue here to troubleshoot for * regexp
  //           return (
  //             <div className="flex flex-col gap-1">
  //               <div>Repeats every month on</div>
  //               <Input
  //                 value={value[2]}
  //                 onChange={(e) => {
  //                   const userVal = e.target.value;

  //                   // Validate the input to allow only numbers and limit the range to 1-31
  //                   if (
  //                     /^[1-9]$|^[12]\d$|^3[01]$/.test(userVal) ||
  //                     userVal === ""
  //                   ) {
  //                     const copyValue = [...value];
  //                     if (userVal === "") {
  //                       copyValue[2] = "*";
  //                     } else {
  //                       copyValue[2] = userVal;
  //                     }
  //                     onChange(copyValue);
  //                   }
  //                 }}
  //                 className="focus-visible:ring-primary-900 bg-slate-50"
  //                 min="1"
  //                 max="31"
  //                 type="number"
  //               />
  //             </div>
  //           );

  //         case FrequencySettingFrequencySettingType.BY_YEAR:
  //           return (
  //             <div className="flex flex-col gap-1">
  //               <div>Repeats every year</div>
  //               <div className="grid grid-cols-2 gap-2">
  //                 <Input
  //                   value={value[2]}
  //                   onChange={(e) => {
  //                     const userVal = e.target.value;

  //                     // Validate the input to allow only numbers and limit the range to 1-31
  //                     if (
  //                       /^[1-9]$|^[12]\d$|^3[01]$/.test(userVal) ||
  //                       userVal === ""
  //                     ) {
  //                       const copyValue = [...value];
  //                       if (userVal === "") {
  //                         copyValue[2] = "*";
  //                       } else {
  //                         copyValue[2] = userVal;
  //                       }
  //                       onChange(copyValue);
  //                     }
  //                   }}
  //                   className="focus-visible:ring-primary-900 bg-slate-50"
  //                   min="1"
  //                   max="31"
  //                   type="number"
  //                 />
  //                 <Select
  //                   value={yearlyMonth.monthName}
  //                   onValueChange={(v) => {
  //                     const idenMth = monthList.find(
  //                       (mth) => mth.monthName === v
  //                     );
  //                     if (idenMth) {
  //                       const copyValue = [...value];
  //                       copyValue[3] = idenMth.monthNo;
  //                       onChange(copyValue);
  //                       setYearlyMonth(idenMth);
  //                     }
  //                   }}
  //                 >
  //                   <SelectTrigger className="w-full truncate h-10">
  //                     <SelectValue
  //                       className={cn(
  //                         `
  //                     font-sans
  //                     text-neutral-50
  //                     transition-all
  //                     text-xs
  //                     w-full
  //                     pl-0
  //                     text-left
  //                     duration-300`
  //                       )}
  //                     ></SelectValue>
  //                   </SelectTrigger>
  //                   <SelectContent>
  //                     {monthList.map((type) => (
  //                       <SelectItem
  //                         value={type.monthName}
  //                         key={type.monthName}
  //                         onClick={() => setYearlyMonth(type)}
  //                       >
  //                         {type.monthName}
  //                       </SelectItem>
  //                     ))}
  //                   </SelectContent>
  //                 </Select>
  //               </div>
  //             </div>
  //           );
  //         default:
  //           return <div>Error detected</div>;
  //       }
  //     }}
  //   />
  // );
}
