/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetOneWorkRequestSettingResponseDto,
  UpdateWorkRequestSettingRequestDto,
} from ".././model";

export const workRequestSettingControllerGetWorkRequestSettingsPublic = (
  projectWorkRequestCode: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneWorkRequestSettingResponseDto>> => {
  return axios.get(
    `/work-request-setting/project/work-request-code/${projectWorkRequestCode}`,
    options
  );
};

export const getWorkRequestSettingControllerGetWorkRequestSettingsPublicQueryKey =
  (projectWorkRequestCode: string) => {
    return [
      `/work-request-setting/project/work-request-code/${projectWorkRequestCode}`,
    ] as const;
  };

export const getWorkRequestSettingControllerGetWorkRequestSettingsPublicInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<
          typeof workRequestSettingControllerGetWorkRequestSettingsPublic
        >
      >
    >,
    TError = AxiosError<unknown>
  >(
    projectWorkRequestCode: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof workRequestSettingControllerGetWorkRequestSettingsPublic
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkRequestSettingControllerGetWorkRequestSettingsPublicQueryKey(
        projectWorkRequestCode
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof workRequestSettingControllerGetWorkRequestSettingsPublic
        >
      >
    > = ({ signal }) =>
      workRequestSettingControllerGetWorkRequestSettingsPublic(
        projectWorkRequestCode,
        { signal, ...axiosOptions }
      );

    return {
      queryKey,
      queryFn,
      enabled: !!projectWorkRequestCode,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<
          typeof workRequestSettingControllerGetWorkRequestSettingsPublic
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type WorkRequestSettingControllerGetWorkRequestSettingsPublicInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof workRequestSettingControllerGetWorkRequestSettingsPublic
      >
    >
  >;
export type WorkRequestSettingControllerGetWorkRequestSettingsPublicInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkRequestSettingControllerGetWorkRequestSettingsPublicInfinite =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<
          typeof workRequestSettingControllerGetWorkRequestSettingsPublic
        >
      >
    >,
    TError = AxiosError<unknown>
  >(
    projectWorkRequestCode: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof workRequestSettingControllerGetWorkRequestSettingsPublic
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getWorkRequestSettingControllerGetWorkRequestSettingsPublicInfiniteQueryOptions(
        projectWorkRequestCode,
        options
      );

    const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
      TData,
      TError
    > & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

export const getWorkRequestSettingControllerGetWorkRequestSettingsPublicQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof workRequestSettingControllerGetWorkRequestSettingsPublic
      >
    >,
    TError = AxiosError<unknown>
  >(
    projectWorkRequestCode: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof workRequestSettingControllerGetWorkRequestSettingsPublic
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkRequestSettingControllerGetWorkRequestSettingsPublicQueryKey(
        projectWorkRequestCode
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof workRequestSettingControllerGetWorkRequestSettingsPublic
        >
      >
    > = ({ signal }) =>
      workRequestSettingControllerGetWorkRequestSettingsPublic(
        projectWorkRequestCode,
        { signal, ...axiosOptions }
      );

    return {
      queryKey,
      queryFn,
      enabled: !!projectWorkRequestCode,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof workRequestSettingControllerGetWorkRequestSettingsPublic
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type WorkRequestSettingControllerGetWorkRequestSettingsPublicQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof workRequestSettingControllerGetWorkRequestSettingsPublic
      >
    >
  >;
export type WorkRequestSettingControllerGetWorkRequestSettingsPublicQueryError =
  AxiosError<unknown>;

export const useWorkRequestSettingControllerGetWorkRequestSettingsPublic = <
  TData = Awaited<
    ReturnType<typeof workRequestSettingControllerGetWorkRequestSettingsPublic>
  >,
  TError = AxiosError<unknown>
>(
  projectWorkRequestCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof workRequestSettingControllerGetWorkRequestSettingsPublic
          >
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestSettingControllerGetWorkRequestSettingsPublicQueryOptions(
      projectWorkRequestCode,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workRequestSettingControllerGetOneWorkRequestSetting = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneWorkRequestSettingResponseDto>> => {
  return axios.get(`/work-request-setting/project/${projectId}`, options);
};

export const getWorkRequestSettingControllerGetOneWorkRequestSettingQueryKey = (
  projectId: string
) => {
  return [`/work-request-setting/project/${projectId}`] as const;
};

export const getWorkRequestSettingControllerGetOneWorkRequestSettingInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
      >
    >,
    TError = AxiosError<unknown>
  >(
    projectId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<
              typeof workRequestSettingControllerGetOneWorkRequestSetting
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkRequestSettingControllerGetOneWorkRequestSettingQueryKey(
        projectId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
      >
    > = ({ signal }) =>
      workRequestSettingControllerGetOneWorkRequestSetting(projectId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!projectId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type WorkRequestSettingControllerGetOneWorkRequestSettingInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
    >
  >;
export type WorkRequestSettingControllerGetOneWorkRequestSettingInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkRequestSettingControllerGetOneWorkRequestSettingInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
    >
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            typeof workRequestSettingControllerGetOneWorkRequestSetting
          >
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestSettingControllerGetOneWorkRequestSettingInfiniteQueryOptions(
      projectId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkRequestSettingControllerGetOneWorkRequestSettingQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
    >,
    TError = AxiosError<unknown>
  >(
    projectId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof workRequestSettingControllerGetOneWorkRequestSetting
            >
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkRequestSettingControllerGetOneWorkRequestSettingQueryKey(
        projectId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
      >
    > = ({ signal }) =>
      workRequestSettingControllerGetOneWorkRequestSetting(projectId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!projectId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type WorkRequestSettingControllerGetOneWorkRequestSettingQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
    >
  >;
export type WorkRequestSettingControllerGetOneWorkRequestSettingQueryError =
  AxiosError<unknown>;

export const useWorkRequestSettingControllerGetOneWorkRequestSetting = <
  TData = Awaited<
    ReturnType<typeof workRequestSettingControllerGetOneWorkRequestSetting>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof workRequestSettingControllerGetOneWorkRequestSetting
          >
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestSettingControllerGetOneWorkRequestSettingQueryOptions(
      projectId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workRequestSettingControllerUpdateWorkRequestSetting = (
  projectId: string,
  updateWorkRequestSettingRequestDto: UpdateWorkRequestSettingRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/work-request-setting/project/${projectId}`,
    updateWorkRequestSettingRequestDto,
    options
  );
};

export const getWorkRequestSettingControllerUpdateWorkRequestSettingMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof workRequestSettingControllerUpdateWorkRequestSetting>
      >,
      TError,
      { projectId: string; data: UpdateWorkRequestSettingRequestDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof workRequestSettingControllerUpdateWorkRequestSetting>
    >,
    TError,
    { projectId: string; data: UpdateWorkRequestSettingRequestDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof workRequestSettingControllerUpdateWorkRequestSetting>
      >,
      { projectId: string; data: UpdateWorkRequestSettingRequestDto }
    > = (props) => {
      const { projectId, data } = props ?? {};

      return workRequestSettingControllerUpdateWorkRequestSetting(
        projectId,
        data,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type WorkRequestSettingControllerUpdateWorkRequestSettingMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof workRequestSettingControllerUpdateWorkRequestSetting>
    >
  >;
export type WorkRequestSettingControllerUpdateWorkRequestSettingMutationBody =
  UpdateWorkRequestSettingRequestDto;
export type WorkRequestSettingControllerUpdateWorkRequestSettingMutationError =
  AxiosError<unknown>;

export const useWorkRequestSettingControllerUpdateWorkRequestSetting = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof workRequestSettingControllerUpdateWorkRequestSetting>
    >,
    TError,
    { projectId: string; data: UpdateWorkRequestSettingRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof workRequestSettingControllerUpdateWorkRequestSetting>
  >,
  TError,
  { projectId: string; data: UpdateWorkRequestSettingRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkRequestSettingControllerUpdateWorkRequestSettingMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
