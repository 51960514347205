import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "./components/ui/sonner";
import { auth } from "./firebase/firebaseConfig";
import "./index.css";
import AppQueryClientProvider from "./providers/AppQueryClientProvider";
import store, { persistor } from "./redux/store";
import AppRouting from "./router/router";
import * as Sentry from "@sentry/react";

if (import.meta.env.PROD) {
  // Sentry Setup
  Sentry.init({
    dsn: "https://fe6e29a82c2b01c114f03df28d3708d0@o4506164052361216.ingest.sentry.io/4506254959575040",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "http://localhost",
      "https://app.cerev.net",
      "https://app-stag.cerev.net",
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

// axios settings
axios.defaults.baseURL = import.meta.env.VITE_SERVER_API_URL;
axios.interceptors.request.use(async (config) => {
  const token = await auth.currentUser?.getIdToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

root.render(
  <React.StrictMode>
    <AppQueryClientProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AppRouting />
            <Toaster richColors/>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </AppQueryClientProvider>
  </React.StrictMode>
);
