import { useFormContext, useWatch } from "react-hook-form";
import { Status, UserControllerGetUsersParams } from "../../../../../api/model";
import {
  useUserControllerGetUserCount,
  useUserControllerGetUsers,
} from "../../../../../api/users/users";
import useCurrentUser from "../../../../../hooks/useCurrentUser/useCurrentUser";
import useDebounce from "../../../../../hooks/useDebounce";
import { useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import {
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useGetUserDataQuery } from "../../../../../redux/slices/Auth/AuthApi";
import { accountTabColumns } from "../columns/accountTabColumns";
import { DataTable } from "../../../../../components/react-table/DataTable";

interface UserAccountTabItemProps {
  status: Status;
}

export default function UserAccountTabItem({
  status,
}: UserAccountTabItemProps) {
  const methods = useFormContext();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const currentUser = useCurrentUser();


  const search = useWatch({ control: methods.control, name: "search" });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<UserControllerGetUsersParams>({
    value: {
      companyId: activeComp?.id ?? 0,
      search,
      userId: currentUser?.data?.id ?? 0,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      status,
    },
    delay: 500,
  });

  const { data: usersListCount } = useUserControllerGetUserCount(queryArg, {
    query: {
      enabled: !!currentUser && !!activeProj && !!activeComp,
      select: (res) => res.data,
    },
  });

  const getStatusLabel = (status: Status) => {
    switch (status) {
      case Status.ACTIVE:
        return "Active";
      case Status.DELETED:
        return "In-active";
    }
  };

  return (
    <div>
      {getStatusLabel(status)} ({usersListCount?.totalCount ?? "-"})
    </div>
  );
}
