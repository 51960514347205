/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateWorkRequestDto,
  CreateWorkRequestInternalDto,
  DeleteWorkRequestDto,
  GetOneWorkRequestResponseDto,
  GetWorkRequestBasedFromCodeResponseDto,
  GetWorkRequestQrCodeResponseDto,
  GetWorkRequestsCountResponseDto,
  GetWorkRequestsResponseDto,
  WorkRequest,
  WorkRequestControllerGetWorkRequestQrCodeParams,
  WorkRequestControllerGetWorkRequestStatusCountParams,
  WorkRequestControllerGetWorkRequestsParams,
  WorkRequestControllerPostWorkRequestQRCodesParams,
} from ".././model";

export const workRequestControllerCreateWorkRequestInternally = (
  createWorkRequestInternalDto: CreateWorkRequestInternalDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/work-request/internal`,
    createWorkRequestInternalDto,
    options
  );
};

export const getWorkRequestControllerCreateWorkRequestInternallyMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof workRequestControllerCreateWorkRequestInternally>
      >,
      TError,
      { data: CreateWorkRequestInternalDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof workRequestControllerCreateWorkRequestInternally>
    >,
    TError,
    { data: CreateWorkRequestInternalDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof workRequestControllerCreateWorkRequestInternally>
      >,
      { data: CreateWorkRequestInternalDto }
    > = (props) => {
      const { data } = props ?? {};

      return workRequestControllerCreateWorkRequestInternally(
        data,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type WorkRequestControllerCreateWorkRequestInternallyMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerCreateWorkRequestInternally>>
  >;
export type WorkRequestControllerCreateWorkRequestInternallyMutationBody =
  CreateWorkRequestInternalDto;
export type WorkRequestControllerCreateWorkRequestInternallyMutationError =
  AxiosError<unknown>;

export const useWorkRequestControllerCreateWorkRequestInternally = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof workRequestControllerCreateWorkRequestInternally>
    >,
    TError,
    { data: CreateWorkRequestInternalDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workRequestControllerCreateWorkRequestInternally>>,
  TError,
  { data: CreateWorkRequestInternalDto },
  TContext
> => {
  const mutationOptions =
    getWorkRequestControllerCreateWorkRequestInternallyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workRequestControllerCreateWorkRequest = (
  createWorkRequestDto: CreateWorkRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkRequest>> => {
  return axios.post(`/work-request`, createWorkRequestDto, options);
};

export const getWorkRequestControllerCreateWorkRequestMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerCreateWorkRequest>>,
    TError,
    { data: CreateWorkRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workRequestControllerCreateWorkRequest>>,
  TError,
  { data: CreateWorkRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workRequestControllerCreateWorkRequest>>,
    { data: CreateWorkRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return workRequestControllerCreateWorkRequest(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkRequestControllerCreateWorkRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof workRequestControllerCreateWorkRequest>>
>;
export type WorkRequestControllerCreateWorkRequestMutationBody =
  CreateWorkRequestDto;
export type WorkRequestControllerCreateWorkRequestMutationError =
  AxiosError<unknown>;

export const useWorkRequestControllerCreateWorkRequest = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerCreateWorkRequest>>,
    TError,
    { data: CreateWorkRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workRequestControllerCreateWorkRequest>>,
  TError,
  { data: CreateWorkRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkRequestControllerCreateWorkRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workRequestControllerGetWorkRequests = (
  params: WorkRequestControllerGetWorkRequestsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkRequestsResponseDto[]>> => {
  return axios.get(`/work-request`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkRequestControllerGetWorkRequestsQueryKey = (
  params: WorkRequestControllerGetWorkRequestsParams
) => {
  return [`/work-request`, ...(params ? [params] : [])] as const;
};

export const getWorkRequestControllerGetWorkRequestsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
    WorkRequestControllerGetWorkRequestsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
        QueryKey,
        WorkRequestControllerGetWorkRequestsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkRequestControllerGetWorkRequestsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
    QueryKey,
    WorkRequestControllerGetWorkRequestsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    workRequestControllerGetWorkRequests(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
    TError,
    TData,
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
    QueryKey,
    WorkRequestControllerGetWorkRequestsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type WorkRequestControllerGetWorkRequestsInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>>;
export type WorkRequestControllerGetWorkRequestsInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequestsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
    WorkRequestControllerGetWorkRequestsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
        QueryKey,
        WorkRequestControllerGetWorkRequestsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestControllerGetWorkRequestsInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkRequestControllerGetWorkRequestsQueryOptions = <
  TData = Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkRequestControllerGetWorkRequestsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>
  > = ({ signal }) =>
    workRequestControllerGetWorkRequests(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkRequestControllerGetWorkRequestsQueryResult = NonNullable<
  Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>
>;
export type WorkRequestControllerGetWorkRequestsQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequests = <
  TData = Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequests>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkRequestControllerGetWorkRequestsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workRequestControllerGetWorkRequestBasedFromCode = (
  projectWorkRequestCode: string,
  workRequestCode: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkRequestBasedFromCodeResponseDto>> => {
  return axios.get(
    `/work-request/project/${projectWorkRequestCode}/workRequestCode/${workRequestCode}`,
    options
  );
};

export const getWorkRequestControllerGetWorkRequestBasedFromCodeQueryKey = (
  projectWorkRequestCode: string,
  workRequestCode: string
) => {
  return [
    `/work-request/project/${projectWorkRequestCode}/workRequestCode/${workRequestCode}`,
  ] as const;
};

export const getWorkRequestControllerGetWorkRequestBasedFromCodeInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
      >
    >,
    TError = AxiosError<unknown>
  >(
    projectWorkRequestCode: string,
    workRequestCode: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkRequestControllerGetWorkRequestBasedFromCodeQueryKey(
        projectWorkRequestCode,
        workRequestCode
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
      >
    > = ({ signal }) =>
      workRequestControllerGetWorkRequestBasedFromCode(
        projectWorkRequestCode,
        workRequestCode,
        { signal, ...axiosOptions }
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(projectWorkRequestCode && workRequestCode),
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type WorkRequestControllerGetWorkRequestBasedFromCodeInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>>
  >;
export type WorkRequestControllerGetWorkRequestBasedFromCodeInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequestBasedFromCodeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>>
  >,
  TError = AxiosError<unknown>
>(
  projectWorkRequestCode: string,
  workRequestCode: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestControllerGetWorkRequestBasedFromCodeInfiniteQueryOptions(
      projectWorkRequestCode,
      workRequestCode,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkRequestControllerGetWorkRequestBasedFromCodeQueryOptions = <
  TData = Awaited<
    ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
  >,
  TError = AxiosError<unknown>
>(
  projectWorkRequestCode: string,
  workRequestCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkRequestControllerGetWorkRequestBasedFromCodeQueryKey(
      projectWorkRequestCode,
      workRequestCode
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>>
  > = ({ signal }) =>
    workRequestControllerGetWorkRequestBasedFromCode(
      projectWorkRequestCode,
      workRequestCode,
      { signal, ...axiosOptions }
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(projectWorkRequestCode && workRequestCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkRequestControllerGetWorkRequestBasedFromCodeQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>>
  >;
export type WorkRequestControllerGetWorkRequestBasedFromCodeQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequestBasedFromCode = <
  TData = Awaited<
    ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
  >,
  TError = AxiosError<unknown>
>(
  projectWorkRequestCode: string,
  workRequestCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workRequestControllerGetWorkRequestBasedFromCode>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestControllerGetWorkRequestBasedFromCodeQueryOptions(
      projectWorkRequestCode,
      workRequestCode,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workRequestControllerDeleteWorkRequest = (
  workRequestCode: string,
  deleteWorkRequestDto: DeleteWorkRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/work-request/work-request-code/${workRequestCode}`, {
    data: deleteWorkRequestDto,
    ...options,
  });
};

export const getWorkRequestControllerDeleteWorkRequestMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerDeleteWorkRequest>>,
    TError,
    { workRequestCode: string; data: DeleteWorkRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workRequestControllerDeleteWorkRequest>>,
  TError,
  { workRequestCode: string; data: DeleteWorkRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workRequestControllerDeleteWorkRequest>>,
    { workRequestCode: string; data: DeleteWorkRequestDto }
  > = (props) => {
    const { workRequestCode, data } = props ?? {};

    return workRequestControllerDeleteWorkRequest(
      workRequestCode,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkRequestControllerDeleteWorkRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof workRequestControllerDeleteWorkRequest>>
>;
export type WorkRequestControllerDeleteWorkRequestMutationBody =
  DeleteWorkRequestDto;
export type WorkRequestControllerDeleteWorkRequestMutationError =
  AxiosError<unknown>;

export const useWorkRequestControllerDeleteWorkRequest = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerDeleteWorkRequest>>,
    TError,
    { workRequestCode: string; data: DeleteWorkRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workRequestControllerDeleteWorkRequest>>,
  TError,
  { workRequestCode: string; data: DeleteWorkRequestDto },
  TContext
> => {
  const mutationOptions =
    getWorkRequestControllerDeleteWorkRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workRequestControllerGetWorkRequestStatusCount = (
  params: WorkRequestControllerGetWorkRequestStatusCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkRequestsCountResponseDto>> => {
  return axios.get(`/work-request/status-count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkRequestControllerGetWorkRequestStatusCountQueryKey = (
  params: WorkRequestControllerGetWorkRequestStatusCountParams
) => {
  return [`/work-request/status-count`, ...(params ? [params] : [])] as const;
};

export const getWorkRequestControllerGetWorkRequestStatusCountInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
      >,
      WorkRequestControllerGetWorkRequestStatusCountParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkRequestControllerGetWorkRequestStatusCountParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
          >,
          QueryKey,
          WorkRequestControllerGetWorkRequestStatusCountParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkRequestControllerGetWorkRequestStatusCountQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
      >,
      QueryKey,
      WorkRequestControllerGetWorkRequestStatusCountParams["cursorId"]
    > = ({ signal, pageParam }) =>
      workRequestControllerGetWorkRequestStatusCount(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
      >,
      QueryKey,
      WorkRequestControllerGetWorkRequestStatusCountParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type WorkRequestControllerGetWorkRequestStatusCountInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>>
  >;
export type WorkRequestControllerGetWorkRequestStatusCountInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequestStatusCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>>,
    WorkRequestControllerGetWorkRequestStatusCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestStatusCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
        >,
        QueryKey,
        WorkRequestControllerGetWorkRequestStatusCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestControllerGetWorkRequestStatusCountInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkRequestControllerGetWorkRequestStatusCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestStatusCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkRequestControllerGetWorkRequestStatusCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>>
  > = ({ signal }) =>
    workRequestControllerGetWorkRequestStatusCount(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkRequestControllerGetWorkRequestStatusCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>>
  >;
export type WorkRequestControllerGetWorkRequestStatusCountQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequestStatusCount = <
  TData = Awaited<
    ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
  >,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestStatusCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof workRequestControllerGetWorkRequestStatusCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestControllerGetWorkRequestStatusCountQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workRequestControllerGetWorkRequestQrCode = (
  params: WorkRequestControllerGetWorkRequestQrCodeParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkRequestQrCodeResponseDto>> => {
  return axios.get(`/work-request/qrcode`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkRequestControllerGetWorkRequestQrCodeQueryKey = (
  params: WorkRequestControllerGetWorkRequestQrCodeParams
) => {
  return [`/work-request/qrcode`, ...(params ? [params] : [])] as const;
};

export const getWorkRequestControllerGetWorkRequestQrCodeInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
      WorkRequestControllerGetWorkRequestQrCodeParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: WorkRequestControllerGetWorkRequestQrCodeParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
          TError,
          TData,
          Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
          QueryKey,
          WorkRequestControllerGetWorkRequestQrCodeParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getWorkRequestControllerGetWorkRequestQrCodeQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
      QueryKey,
      WorkRequestControllerGetWorkRequestQrCodeParams["cursorId"]
    > = ({ signal, pageParam }) =>
      workRequestControllerGetWorkRequestQrCode(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
      TError,
      TData,
      Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
      QueryKey,
      WorkRequestControllerGetWorkRequestQrCodeParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type WorkRequestControllerGetWorkRequestQrCodeInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>
  >;
export type WorkRequestControllerGetWorkRequestQrCodeInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequestQrCodeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
    WorkRequestControllerGetWorkRequestQrCodeParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestQrCodeParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
        TError,
        TData,
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
        QueryKey,
        WorkRequestControllerGetWorkRequestQrCodeParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestControllerGetWorkRequestQrCodeInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkRequestControllerGetWorkRequestQrCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestQrCodeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkRequestControllerGetWorkRequestQrCodeQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>
  > = ({ signal }) =>
    workRequestControllerGetWorkRequestQrCode(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkRequestControllerGetWorkRequestQrCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>
>;
export type WorkRequestControllerGetWorkRequestQrCodeQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetWorkRequestQrCode = <
  TData = Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
  TError = AxiosError<unknown>
>(
  params: WorkRequestControllerGetWorkRequestQrCodeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetWorkRequestQrCode>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkRequestControllerGetWorkRequestQrCodeQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workRequestControllerPostWorkRequestQRCodes = (
  params: WorkRequestControllerPostWorkRequestQRCodesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkRequestQrCodeResponseDto | void>> => {
  return axios.post(`/work-request/qrcode`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWorkRequestControllerPostWorkRequestQRCodesMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerPostWorkRequestQRCodes>>,
    TError,
    { params: WorkRequestControllerPostWorkRequestQRCodesParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workRequestControllerPostWorkRequestQRCodes>>,
  TError,
  { params: WorkRequestControllerPostWorkRequestQRCodesParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workRequestControllerPostWorkRequestQRCodes>>,
    { params: WorkRequestControllerPostWorkRequestQRCodesParams }
  > = (props) => {
    const { params } = props ?? {};

    return workRequestControllerPostWorkRequestQRCodes(params, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkRequestControllerPostWorkRequestQRCodesMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerPostWorkRequestQRCodes>>
  >;

export type WorkRequestControllerPostWorkRequestQRCodesMutationError =
  AxiosError<unknown>;

export const useWorkRequestControllerPostWorkRequestQRCodes = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerPostWorkRequestQRCodes>>,
    TError,
    { params: WorkRequestControllerPostWorkRequestQRCodesParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workRequestControllerPostWorkRequestQRCodes>>,
  TError,
  { params: WorkRequestControllerPostWorkRequestQRCodesParams },
  TContext
> => {
  const mutationOptions =
    getWorkRequestControllerPostWorkRequestQRCodesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const workRequestControllerGetOneWorkRequest = (
  workRequestId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneWorkRequestResponseDto>> => {
  return axios.get(`/work-request/${workRequestId}`, options);
};

export const getWorkRequestControllerGetOneWorkRequestQueryKey = (
  workRequestId: string
) => {
  return [`/work-request/${workRequestId}`] as const;
};

export const getWorkRequestControllerGetOneWorkRequestInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>
  >,
  TError = AxiosError<unknown>
>(
  workRequestId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkRequestControllerGetOneWorkRequestQueryKey(workRequestId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>
  > = ({ signal }) =>
    workRequestControllerGetOneWorkRequest(workRequestId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workRequestId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkRequestControllerGetOneWorkRequestInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>
  >;
export type WorkRequestControllerGetOneWorkRequestInfiniteQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetOneWorkRequestInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>
  >,
  TError = AxiosError<unknown>
>(
  workRequestId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWorkRequestControllerGetOneWorkRequestInfiniteQueryOptions(
      workRequestId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWorkRequestControllerGetOneWorkRequestQueryOptions = <
  TData = Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
  TError = AxiosError<unknown>
>(
  workRequestId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWorkRequestControllerGetOneWorkRequestQueryKey(workRequestId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>
  > = ({ signal }) =>
    workRequestControllerGetOneWorkRequest(workRequestId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workRequestId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WorkRequestControllerGetOneWorkRequestQueryResult = NonNullable<
  Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>
>;
export type WorkRequestControllerGetOneWorkRequestQueryError =
  AxiosError<unknown>;

export const useWorkRequestControllerGetOneWorkRequest = <
  TData = Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
  TError = AxiosError<unknown>
>(
  workRequestId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof workRequestControllerGetOneWorkRequest>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWorkRequestControllerGetOneWorkRequestQueryOptions(
    workRequestId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const workRequestControllerUpdateRatingWorkRequest = (
  workRequestCode: string,
  rating: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/work-request/${workRequestCode}/review/${rating}`,
    undefined,
    options
  );
};

export const getWorkRequestControllerUpdateRatingWorkRequestMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerUpdateRatingWorkRequest>>,
    TError,
    { workRequestCode: string; rating: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof workRequestControllerUpdateRatingWorkRequest>>,
  TError,
  { workRequestCode: string; rating: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof workRequestControllerUpdateRatingWorkRequest>>,
    { workRequestCode: string; rating: string }
  > = (props) => {
    const { workRequestCode, rating } = props ?? {};

    return workRequestControllerUpdateRatingWorkRequest(
      workRequestCode,
      rating,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type WorkRequestControllerUpdateRatingWorkRequestMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof workRequestControllerUpdateRatingWorkRequest>>
  >;

export type WorkRequestControllerUpdateRatingWorkRequestMutationError =
  AxiosError<unknown>;

export const useWorkRequestControllerUpdateRatingWorkRequest = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof workRequestControllerUpdateRatingWorkRequest>>,
    TError,
    { workRequestCode: string; rating: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof workRequestControllerUpdateRatingWorkRequest>>,
  TError,
  { workRequestCode: string; rating: string },
  TContext
> => {
  const mutationOptions =
    getWorkRequestControllerUpdateRatingWorkRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
