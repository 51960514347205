import { useFormContext } from "react-hook-form";
import AppSelectWithDialog from "../../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { selectCompanyColumns } from "./SelectCompanyColumn";
import { useCompaniesControllerGetAllCompanies } from "../../../../api/companies/companies";
import { useAuthControllerGetCurrentUser } from "../../../../api/auth/auth";

export default function CompanySelection() {
  const methods = useFormContext();
  const { data: userData } = useAuthControllerGetCurrentUser();

  const {
    data: companies,
    isLoading,
    isFetching,
  } = useCompaniesControllerGetAllCompanies(
    {},
    {
      query: {
        enabled: !!userData,
        select: (res) => res.data,
      },
    }
  );

  return (
    <AppSelectWithDialog
      columns={selectCompanyColumns}
      label="Company"
      placeholder="Select Company"
      defaultValue={undefined}
      control={methods.control}
      name="company"
      items={companies?.companies ?? []}
      onResultRender={(item, idx) => {
        return <div key={idx}>{item?.name}</div>;
      }}
      onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
      dialogTitle="Select Company"
      error={!!methods.formState.errors.company?.message}
      helperText={methods.formState.errors.company?.message as any}
    />
  );
}
