import React from "react";
import { useWoProfitLossEnabled } from "../hooks/useFeatures";

interface WoProfitLossGuardProps {
  children: React.ReactNode;
}

export default function WoProfitLossGuard({ children }: WoProfitLossGuardProps) {
  const hasAccess = useWoProfitLossEnabled();
  if (!hasAccess) return <></>;

  return <>{children}</>;
}
