/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type WorkRequestSettingWorkRequestShowLocationMode =
  (typeof WorkRequestSettingWorkRequestShowLocationMode)[keyof typeof WorkRequestSettingWorkRequestShowLocationMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkRequestSettingWorkRequestShowLocationMode = {
  ALL: "ALL",
  MAIN_LOCATION_ONLY: "MAIN_LOCATION_ONLY",
} as const;
