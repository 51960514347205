import { ColumnDef } from "@tanstack/react-table";
import { InventoryLocationBalance } from "../../../../api/model";
import AppButton from "../../../AppButton";
import { Pen } from "lucide-react";
import AdjustLocationBalanceBtn from "../dialog/AdjustLocationBalanceBtn";

export const inventoryLocationBalanceColumns: ColumnDef<InventoryLocationBalance>[] =
  [
    {
      id: "locationId",
      accessorKey: "location.id",
    },
    {
      header: "Location",
      accessorKey: "location.name",
    },
    {
      header: "Balance",
      accessorKey: "balance",
    },
    {
      header: "Adjust",
      cell: ({ row }) => (
        <AdjustLocationBalanceBtn
          locationId={row.getValue("locationId")}
          currentBalance={row.getValue("balance")}
        />
      ),
    },
  ];
