import { Download, X } from "lucide-react";
import AppButton from "../../../../components/AppButton";
import {
  useRowSelectionState,
  useWorkOrderMultiSelectActions,
} from "../../../../stores/workOrderMultiActionStore/workOrderMultiActionStore";
import { Button } from "../../../../components/ui/button";
import { useWorkOrderV2ControllerPostMultiplePdfReport } from "../../../../api/work-order-defect-v2/work-order-defect-v2";
import { GetPdfReportResponseDto } from "../../../../api/model";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Switch } from "../../../../components/ui/switch";
import { toast } from "sonner";
import SignatoryGuard from "../../../../guards/SignatoryGuard";

export default function MultiExportPdf() {
  const [isLogViewable, setLogViewable] = useState(true);
  const [isCommentViewable, setCommentViewable] = useState(true);
  const [isSignatoryViewable, setSignatoryViewable] = useState(true);
  const [open, setOpen] = useState(false);
  const rowsSelect = useRowSelectionState();
  const rowCount = Object.keys(rowsSelect).length;
  const { setIsMultiActionMode } = useWorkOrderMultiSelectActions();
  const { mutate, isPending } = useWorkOrderV2ControllerPostMultiplePdfReport({
    mutation: {
      onSuccess: (res) => {
        const result = res.data;
        const pdfUrl = (res.data as GetPdfReportResponseDto).url;
        if (pdfUrl) window.open(pdfUrl, "__blank");

        // Close and reset the dialog
        setLogViewable(true);
        setCommentViewable(true);
        setOpen(false);
        setIsMultiActionMode(false);
      },
    },
  });

  const onConfirm = () => {
    if (Object.keys(rowsSelect).length === 0) {
      toast.error("Please select at least one work order");
      return;
    }
    mutate({
      data: {
        workOrderIds: Object.keys(rowsSelect),
        commentsViewable: isCommentViewable,
        logsViewable: isLogViewable,
        signatoryViewable: isSignatoryViewable,
      },
    });
  };

  return (
    <>
      <Dialog open={open} onOpenChange={(o) => setOpen(o)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Export Work Order PDF</DialogTitle>
            <DialogDescription>
              Indicate below which page is required in the PDF
            </DialogDescription>
          </DialogHeader>
          <SignatoryGuard>
            <div className="flex items-center gap-4 p-4 rounded-xl border-slate-200 border border-solid">
              <Switch
                className="data-[state=checked]:bg-primary-900"
                checked={isSignatoryViewable}
                onCheckedChange={(o) => setSignatoryViewable(o)}
              />
              <p className="text-sm">Signatory Section</p>
            </div>
          </SignatoryGuard>
          <div className="flex items-center gap-4 p-4 rounded-xl border-slate-200 border border-solid">
            <Switch
              className="data-[state=checked]:bg-primary-900"
              checked={isCommentViewable}
              onCheckedChange={(o) => setCommentViewable(o)}
            />
            <p className="text-sm">Comments Section</p>
          </div>
          <div className="flex items-center gap-4 p-4 rounded-xl border-slate-200 border border-solid">
            <Switch
              className="data-[state=checked]:bg-primary-900"
              checked={isLogViewable}
              onCheckedChange={(o) => setLogViewable(o)}
            />
            <p className="text-sm">Logs Section</p>
          </div>
          <DialogFooter>
            <AppButton
              label="Export PDF"
              isLoading={isPending}
              onClick={onConfirm}
            />
            <AppButton
              variant="ghost"
              label="Cancel"
              onClick={() => setOpen(false)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className=" bg-slate-900 px-4 rounded-l-2xl rounded-s-2xl text-2xl h-full flex items-center justify-center">
        <p className="font-bold text-white">{rowCount ?? 0}</p>
      </div>
      <p className="text-sm text-white">Work orders selected</p>
      <AppButton
        variant="outline"
        label="Export PDF"
        icon={<Download />}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Button
        variant="ghost"
        className="hover:cursor-pointer hover:bg-primary-700 hover:bg-transparent"
        onClick={() => {
          setIsMultiActionMode(false);
        }}
      >
        <X className="text-primary-900 font-bold" />
      </Button>
    </>
  );
}
