import { Bell } from "lucide-react";
import AppButton from "../../../../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import { NOTIFICATION_DRAWER } from "../../../../../components/drawers/AppDrawer";
import { useNotificationControllerGetUnreadCount } from "../../../../../api/notification/notification";
import { Badge } from "../../../../../components/ui/badge";

export default function NotificationBtn() {
  const [, setSearchParams] = useSearchParams();
  const { data: unreadCount } = useNotificationControllerGetUnreadCount({
    query: {
      refetchInterval:  1000 * 30, // 30 seconds refetching
    }
  });
  return (
    <div className="relative">
      <AppButton
        variant="outline"
        icon={<Bell />}
        onClick={() => {
          setSearchParams((p) => {
            p.set("drawerType", NOTIFICATION_DRAWER);
            return p;
          });
        }}
      />
      {unreadCount && unreadCount.data && unreadCount?.data?.data > 0 && (
        <Badge className="absolute -top-2 -right-4 bg-red-400 text-white">
          {unreadCount?.data?.data}
        </Badge>
      )}
    </div>
  );
}
