import { useSearchParams } from "react-router-dom";
import { useUserControllerGetOneUser } from "../../../../api/users/users";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import {
  Pen,
  Tag,
  Mail,
  Phone,
  Users2,
  Fingerprint,
  FileImage,
  Building,
  UserSquare,
  MailCheck,
} from "lucide-react";
import { AccountTypeEnum, Status } from "../../../../api/model";
import AppButton from "../../../AppButton";
import AppImage from "../../../AppImage";
import AppItemInfo from "../../../AppItemInfo";
import { USER_FORM_DRAWER, DRAWER_UPDATE_STATE } from "../../AppDrawer";
import AccountTypeBadge from "../components/AccountTypeBadge";
import { useEffect } from "react";
import { Badge } from "../../../ui/badge";
import { cn } from "../../../../utils/util";
import ResetUserPasswordBtn from "../components/ResetUserPasswordBtn";

export default function UserGeneralTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const firebaseUserId = searchParams.get("firebaseId") as string;

  const { data: user, isLoading } = useUserControllerGetOneUser(
    firebaseUserId ?? "",
    {
      query: {
        enabled: !!firebaseUserId,
        select: (res) => res.data,
      },
    }
  );

  const isActive = user?.status === Status.ACTIVE ?? true;

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-bold text-lg">General Information</h2>
      <div className="grid grid-cols-2 gap-6">
        <AppItemInfo
          label="Name"
          icon={<Tag className="text-primary-900" />}
          content={<p>{user?.name ?? "-"}</p>}
        />
        <AppItemInfo
          label="Email"
          icon={<Mail className="text-primary-900" />}
          content={<p>{user?.email ?? "-"}</p>}
        />
        <AppItemInfo
          label="Phone No"
          icon={<Phone className="text-primary-900" />}
          content={<p>{user?.phoneNo ?? "-"}</p>}
        />
        <AppItemInfo
          label="Position"
          icon={<Users2 className="text-primary-900" />}
          content={<p>{user?.position ?? "-"}</p>}
        />
        <AppItemInfo
          label="Account Type"
          icon={<Fingerprint className="text-primary-900" />}
          content={
            <AccountTypeBadge
              accountType={
                user?.accountTypes[0]?.type ?? AccountTypeEnum.NORMAL_USER
              }
            />
          }
        />
        <AppItemInfo
          label="Account Status"
          icon={<UserSquare className="text-primary-900" />}
          content={
            <div>
              <Badge
                className={cn("font-bold", {
                  "text-green-50 bg-green-500": isActive,
                  "text-red-50 bg-red-500": !isActive,
                })}
              >
                {isActive ? "Active" : "Deactivated"}
              </Badge>
            </div>
          }
        />
        <div className="col-span-2">
          <AppItemInfo
            label="Photos"
            icon={<FileImage className="text-primary-900" />}
            content={
              <div className="flex flex-col gap-4">
                {user?.attachments?.length ? (
                  user?.attachments.map((p) => <AppImage key={p.id} att={p} />)
                ) : (
                  <p className="font-sans text-neutral-900">-</p>
                )}
              </div>
            }
          />
        </div>
        <div className="col-span-2">
          <AppItemInfo
            label="Projects"
            icon={<Building />}
            content={
              <div className="flex flex-wrap gap-1">
                {user?.projects?.map((p) => (
                  <Badge
                    key={p.id}
                    className="mr-1 mb-1 text text-white font-bold bg-primary-900"
                  >
                    {p.name}
                  </Badge>
                ))}
              </div>
            }
          />
        </div>
        <ResetUserPasswordBtn />
      </div>
    </div>
  );
}
