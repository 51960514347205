interface Props {
  size?: number
}

export default function WorkRequestLogo({ size = 24 }: Props) {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        d="M23.8981 50C37.0967 50 47.7963 38.8071 47.7963 25C47.7963 11.1929 37.0967 0 23.8981 0C10.6996 0 0 11.1929 0 25C0 38.8071 10.6996 50 23.8981 50Z"
        fill="#A2DAE0"
      />
      <path
        d="M24.7768 45.8561C37.9442 45.8561 48.6185 45.0616 48.6185 44.0816C48.6185 43.1016 37.9442 42.3071 24.7768 42.3071C11.6094 42.3071 0.935059 43.1016 0.935059 44.0816C0.935059 45.0616 11.6094 45.8561 24.7768 45.8561Z"
        fill="#85B6D6"
      />
      <path
        d="M19.8666 30.0777C24.2964 30.0777 27.8874 26.3211 27.8874 21.6871C27.8874 17.053 24.2964 13.2964 19.8666 13.2964C15.4368 13.2964 11.8457 17.053 11.8457 21.6871C11.8457 26.3211 15.4368 30.0777 19.8666 30.0777Z"
        fill="#FFEDBB"
      />
      <path
        d="M32.309 44.0819H7.68726L8.65521 36.9103C8.65521 34.8375 9.44234 32.8496 10.8434 31.3839C12.2445 29.9182 14.1449 29.0947 16.1263 29.0947H23.9122C24.8934 29.0947 25.8649 29.2969 26.7713 29.6897C27.6777 30.0824 28.5013 30.6581 29.1951 31.3839C29.8889 32.1096 30.4392 32.9712 30.8146 33.9194C31.1901 34.8676 31.3833 35.8839 31.3833 36.9103L32.309 44.0819Z"
        fill="#1E99B4"
      />
      <path
        d="M28.5828 35.2441L28.0519 44.0821H12.043L11.3711 35.2441H28.5828Z"
        fill="#CCCCCC"
      />
      <path
        d="M29.0292 23.3141V19.5243C29.0292 17.0134 28.0758 14.6054 26.3786 12.83C24.6814 11.0546 22.3795 10.0571 19.9793 10.0571C17.5792 10.0571 15.2773 11.0546 13.5801 12.83C11.8829 14.6054 10.9294 17.0134 10.9294 19.5243V23.3141"
        stroke="#4D4D4D"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M30.688 23.8154H22.1409"
        stroke="#4D4D4D"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M39.6578 14.3827H47.9277C48.4423 14.4152 48.9483 14.2342 49.3362 13.8791C49.7241 13.5239 49.9625 13.0232 49.9999 12.4853V3.44091C49.9614 2.90398 49.7224 2.40448 49.3346 2.05036C48.9469 1.69625 48.4415 1.51596 47.9277 1.54846H33.7655C33.2515 1.51466 32.7454 1.69449 32.3574 2.04886C31.9694 2.40323 31.7307 2.9035 31.6934 3.44091V12.4853C31.7295 13.0236 31.9677 13.5252 32.3558 13.8806C32.744 14.236 33.2507 14.4165 33.7655 14.3827H34.5831"
        fill="#F2F2F2"
      />
      <path d="M34.5833 14.3823V18.5605L39.658 14.3823" fill="#F2F2F2" />
      <path
        d="M34.5833 10.0571H37.1206"
        stroke="#4D4D4D"
        strokeMiterlimit="10"
      />
      <path
        d="M39.8647 10.0864H42.4021"
        stroke="#4D4D4D"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6621 10.0571H47.2042"
        stroke="#4D4D4D"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
