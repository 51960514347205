import { zodResolver } from "@hookform/resolvers/zod";
import Decimal from "decimal.js";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import {
  useStockAdjustmentControllerCreateStockAdjustment,
  useStocksControllerGetOneStockWithLedger,
} from "../../../../api/stocks-inventory/stocks-inventory";
import { useAppSelector } from "../../../../redux/store";
import AppButton from "../../../AppButton";
import AppTextField from "../../../AppTextField";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../ui/dialog";
import {
  CreateStockDtoInventoryMode,
  StockLedger,
} from "../../../../api/model";
import { useQueryClient } from "@tanstack/react-query";
import InventorySerialsFormSection from "@/components/drawers/inventory/components/InventorySerialsFormSection";
import AmountChangeField from "@/components/drawers/inventory/dialog/AmountChangeField";

const StockAdjustmentSchema = z
  .object({
    newBalance: z.coerce.number().int(),
    description: z.string().optional(),
    serials: z
      .object({
        name: z.string(),
      })
      .array(),
    inventoryMode: z
      .enum([
        CreateStockDtoInventoryMode.QUANTITY,
        CreateStockDtoInventoryMode.SERIALIZED,
      ])
      .default(CreateStockDtoInventoryMode.QUANTITY),
    amountChange: z.coerce.number().int(),
  })
  .refine(
    (schema) => {
      if (
        schema.inventoryMode === CreateStockDtoInventoryMode.SERIALIZED &&
        schema.serials.length !== schema.newBalance
      ) {
        return false;
      }
      return true;
    },
    {
      path: ["serials"],
      message: "[New Balance] and the number of [Serial Numbers] do not match.",
    }
  );

export type StockAdjustmentForm = z.infer<typeof StockAdjustmentSchema>;

interface StockAdjustmentDialogProps {
  open: boolean;
  handleClose: () => void;
}

export default function StockAdjustmentDialog({
  open,
  handleClose,
}: StockAdjustmentDialogProps) {
  const qc = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const inventoryId = searchParams.get("inventoryId");

  const methods = useForm<StockAdjustmentForm>({
    resolver: zodResolver(StockAdjustmentSchema),
    defaultValues: {
      newBalance: 0,
      description: "",
      serials: [],
      amountChange: 0,
    },
  });

  const { data: inventory } = useStocksControllerGetOneStockWithLedger(
    inventoryId ?? "",
    {
      query: {
        enabled: !!inventoryId,
        select: (res) => res.data,
      },
    }
  );

  const {
    mutate: createStockAdjustment,
    isPending: createStockAdjustmentIsLoading,
  } = useStockAdjustmentControllerCreateStockAdjustment({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("stock"),
        });
        methods.reset({ newBalance: 0, description: "", serials: [] });
        handleClose();
      },
    },
  });

  const onConfirm: SubmitHandler<StockAdjustmentForm> = async (data) => {
    const diff = new Decimal(data.newBalance).minus(inventory?.balance ?? 0);
    createStockAdjustment({
      data: {
        date: new Date().toISOString(),
        projectId: activeProj?.id?.toString() ?? "0",
        serials: data.serials.map((s) => s.name),
        ledgers: [
          {
            adjustmentQty: diff.toNumber(),
            stockId: parseInt(inventoryId ?? "0"),
            description: data.description,
          },
        ] as StockLedger[],
      },
    });
  };

  const onDialogClose = (open: boolean) => {
    handleClose();
    if (!open) methods.reset();
  };

  useEffect(() => {
    if (inventory && open) {
      methods.setValue(
        "serials",
        inventory.serials.map((s) => ({ id: s.serial, name: s.serial }))
      );
      methods.setValue("newBalance", inventory.balance ?? 0);
      methods.setValue("inventoryMode", inventory.inventoryMode);
    }
  }, [inventory, open]);

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onOpenChange={onDialogClose}>
        <DialogContent className={`overflow-y-scroll max-h-screen`}>
          <DialogTitle className="text-2xl font-bold">
            Amount Adjustment
          </DialogTitle>
          <AppTextField label="Description" name="description" />
          <AppTextField
            type="number"
            label="New Balance nos."
            name="newBalance"
          />
          {inventory?.inventoryMode ===
            CreateStockDtoInventoryMode.SERIALIZED && <AmountChangeField />}
          {inventory?.inventoryMode ===
            CreateStockDtoInventoryMode.SERIALIZED && (
            <InventorySerialsFormSection />
          )}
          <DialogFooter>
            <AppButton
              isLoading={createStockAdjustmentIsLoading}
              label="Confirm"
              onClick={methods.handleSubmit(onConfirm)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </FormProvider>
  );
}
