import { ColumnDef, Row } from "@tanstack/react-table";
import { WorkOrderRevenue } from "../../../../../../api/model";
import AppButton from "../../../../../AppButton";
import { Trash2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import {
  useWoRevenueControllerDeleteWoRevenue,
  useWoRevenueControllerGetOneWoRevenue,
  useWoRevenueControllerUpdateWoRevenue,
} from "../../../../../../api/work-order-revenue/work-order-revenue";
import { WoRevenueFormType, woRevenueSchema } from "./woRevenueSchema";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../../ui/dialog";
import WoRevenueForm from "./WoRevenueForm";

const WoRevenueDeleteBtn = ({ row }: { row: Row<WorkOrderRevenue> }) => {
  const qc = useQueryClient();

  const { mutate, isPending } = useWoRevenueControllerDeleteWoRevenue({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("wo-revenue"),
        });
      },
    },
  });

  return (
    <>
      <AppButton
        variant="outline"
        icon={<Trash2 className="text-red-500" />}
        className="hover:bg-red-50"
        isLoading={isPending}
        onClick={() => {
          mutate({
            woRevenueId: row.getValue("id") as string,
          });
        }}
      />
    </>
  );
};

const WoRevenueEditName = ({ row }: { row: Row<WorkOrderRevenue> }) => {
  const [searchParams] = useSearchParams();
  const activeWoId = searchParams.get("workOrderId");
  const qc = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm<WoRevenueFormType>({
    resolver: zodResolver(woRevenueSchema),
    defaultValues: {
      description: "",
      amount: 0,
    },
  });

  const { data: editRevenue } = useWoRevenueControllerGetOneWoRevenue(
    row.getValue("id") as string,
    {
      query: {
        enabled: !!row.getValue("id"),
        select: (res) => res.data.data,
      },
    }
  );

  const { mutate, isPending } = useWoRevenueControllerUpdateWoRevenue({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("wo-revenue"),
        });
        setIsOpen(false);
      },
    },
  });

  const onSubmit: SubmitHandler<WoRevenueFormType> = async (data) => {
    if (!row.getValue("id")) return;
    mutate({
      woRevenueId: row.getValue("id"),
      data: {
        description: data.description,
        amount: data.amount,
        workOrderId: Number(activeWoId) ?? 0,
      },
    });
  };

  useEffect(() => {
    if (!editRevenue) return;
    methods.reset({
      description: editRevenue.description,
      amount: editRevenue.amount,
    });
  }, [editRevenue]);

  return (
    <>
      <p
        className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {row.getValue("description")}
      </p>
      <Dialog
        open={isOpen}
        onOpenChange={(o) => {
          setIsOpen(o);
        }}
      >
        <DialogContent>
          <DialogTitle>Edit Revenue</DialogTitle>
          <FormProvider {...methods}>
            <WoRevenueForm />
          </FormProvider>
          <DialogFooter>
            <AppButton
              variant="ghost"
              label="Cancel"
              onClick={() => setIsOpen(false)}
            />
            <AppButton
              label="Update"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const woRevenueTableColumns: ColumnDef<WorkOrderRevenue>[] = [
  {
    id: "id",
    accessorKey: "id",
  },
  {
    header: "Description",
    accessorKey: "description",
    cell: ({ row }) => <WoRevenueEditName row={row} />,
  },
  {
    header: "Revenue (RM)",
    accessorKey: "amount",
  },
  {
    header: "Actions",
    cell: ({ row }) => <WoRevenueDeleteBtn row={row} />,
  },
];
