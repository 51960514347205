import FullCalendar from "@fullcalendar/react";
import { endOfMonth, startOfMonth } from "date-fns";
import { create } from "zustand";

interface CalendarRefStoreState {
  calendarRef: FullCalendar | null;
  calendarMode: "dayGridMonth" | "timeGridWeek";
  dateStart: Date;
  dateEnd: Date;
  actions: {
    setCalendarRef: (ref: FullCalendar | null) => void;
    updateCalendarMode: (mode: "dayGridMonth" | "timeGridWeek") => void;
    setDateStart: (dateStart: Date | null | undefined) => void;
    setDateEnd: (dateEnd: Date | null | undefined) => void;
    cleanUp: () => void;
  };
}

export const useCalendarRefStore = create<CalendarRefStoreState>((set) => ({
  calendarRef: null,
  calendarMode: "dayGridMonth",
  dateStart: startOfMonth(new Date()),
  dateEnd: endOfMonth(new Date()),
  actions: {
    setCalendarRef: (ref) => set(() => ({ calendarRef: ref })),
    updateCalendarMode: (mode) => set(() => ({ calendarMode: mode })),
    setDateStart: function (dateStart) {
      if (!dateStart)
        return set(() => ({ dateStart: startOfMonth(new Date()) }));
      return set(() => ({ dateStart }));
    },
    setDateEnd: (dateEnd) => {
      if (!dateEnd) return set(() => ({ dateEnd: endOfMonth(new Date()) }));
      return set(() => ({ dateEnd }));
    },
    cleanUp: () => {
      set(() => ({
        calendarRef: null,
        calendarMode: "dayGridMonth",
        dateStart: startOfMonth(new Date()),
        dateEnd: endOfMonth(new Date()),
      }));
    }
  },
}));

export const useCalendarRefActions = () =>
  useCalendarRefStore((state) => state.actions);

export const useCalendarRef = () =>
  useCalendarRefStore((state) => state.calendarRef);

export const useCalendarDateStart = () =>
  useCalendarRefStore((state) => state.dateStart);

export const useCalendarDateEnd = () =>
  useCalendarRefStore((state) => state.dateEnd);

export const useCalendarMode = () =>
  useCalendarRefStore((state) => state.calendarMode);
