import * as React from "react";
import { Text, View } from "@react-pdf/renderer";

interface BigCheckBoxProps {
  label: string;
}

export function BigCheckBox({ label }: BigCheckBoxProps) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 20,
        flex: 1,
      }}
    >
      <View
        style={{
          border: 1,
          borderColor: "#94a3b8",
          height: 25,
          width: 25,
          borderRadius: 2,
        }}
      ></View>
      <Text style={{ fontSize: 8, fontWeight: "bold", flex: 1 }}>{label}</Text>
    </View>
  );
}
