import { useCallback } from "react";
import { cn } from "../../../../lib/utils";
import {
  useMultiActionType,
  useWorkOrderIsMultiActionMode,
  WorkOrderMultiActionType,
} from "../../../../stores/workOrderMultiActionStore/workOrderMultiActionStore";
import MultiLinkEquipment from "./MultiLinkEquipment";
import MultiExportPdf from "./MultiExportPdf";
import MultiLinkLocation from "./MultiLinkLocation";

export default function MultiSelectBottomBar() {
  const multiActionMode = useMultiActionType();
  const isOpen = useWorkOrderIsMultiActionMode();

  const getContent = useCallback(() => {
    switch (multiActionMode) {
      case WorkOrderMultiActionType.LINK_EQUIPMENT:
        return <MultiLinkEquipment />;
      case WorkOrderMultiActionType.MULTI_EXPORT_PDF:
        return <MultiExportPdf />;
      case WorkOrderMultiActionType.LINK_LOCATION:
        return <MultiLinkLocation />;
      default:
        return <></>;
    }
  }, [multiActionMode]);

  return (
    <div
      className={cn(
        "fixed bottom-4 left-0 right-0 bg-transparent flex justify-center items-center z-50 translate-y-24 transition-all ease-in-out duration-200 pointer-events-none",
        {
          "translate-y-0": isOpen,
        }
      )}
    >
      <div className="bg-neutral-900 shadow-2xl rounded-2xl flex pointer-events-auto items-center justify-center gap-4 pr-2 h-20">
        {getContent()}
      </div>
    </div>
  );
}
