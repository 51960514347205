import { useFormContext } from "react-hook-form";
import { InternalWorkRequestForm } from "../WorkRequestFormDrawer";
import AppSelectWithDialog from "../../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { assetColumns } from "../../workOrders/columns/assetColumns";
import { useAssetControllerGetFullAssets } from "../../../../api/assets/assets";
import { useAppSelector } from "../../../../redux/store";

export default function WorkRequestSelectAssetDialog() {
  const methods = useFormContext<InternalWorkRequestForm>();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: assetList } = useAssetControllerGetFullAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  return (
    <AppSelectWithDialog
      label="Select Equipment"
      columns={assetColumns}
      placeholder="Select Equipment"
      control={methods.control}
      defaultValue={undefined}
      name="asset"
      items={assetList ?? []}
      onResultRender={(item, idx) => (
        <div className="font-medium">{item?.name ?? "-"}</div>
      )}
      onOptionsRender={(item, idx) => (
        <div className="font-medium">{item?.name ?? "-"}</div>
      )}
      dialogTitle="Select Equipment"
      error={!!methods.formState.errors.asset?.message}
      helperText={methods.formState.errors.asset?.message}
    />
  );
}
