import { create } from "zustand";
import { AssetWithType, GetWorkOrdersResponseDto } from "../../api/model";
import { OnChangeFn, RowSelectionState } from "@tanstack/react-table";

export enum WorkOrderMultiActionType {
  LINK_EQUIPMENT = "LINK_EQUIPMENT",
  LINK_LOCATION = "LINK_LOCATION",
  MULTI_EXPORT_PDF = "MULTI_EXPORT_PDF",
}

interface WorkOrderMultiSelectState {
  rowSelection: {};
  multiActionType: WorkOrderMultiActionType;
  isMultiActionMode: boolean;
  selectedWorkOrders: GetWorkOrdersResponseDto[];
  equipmentLink: AssetWithType;
  actions: {
    setRowSelection: OnChangeFn<RowSelectionState>;
    setMultiActionMode: (mode: WorkOrderMultiActionType) => void;
    setIsMultiActionMode: (isOpen: boolean) => void;
    setSelectedWorkOrders: (workOrders: GetWorkOrdersResponseDto[]) => void;
    setEquipmentLink: (equipment: AssetWithType) => void;
    cleanUp: () => void;
  };
}

export const useWorkOrderMultiSelectStore = create<WorkOrderMultiSelectState>(
  (set, get) => ({
    rowSelection: {},
    multiActionType: WorkOrderMultiActionType.LINK_EQUIPMENT,
    isMultiActionMode: false,
    selectedWorkOrders: [],
    equipmentLink: {} as AssetWithType,
    actions: {
      setRowSelection: (u) => {
        if (typeof u === "function")
          return set((state) => ({ rowSelection: u(state.rowSelection) }));
        return set(() => ({ rowSelection: u }));
      },
      setMultiActionMode: (mode) =>
        set(() => ({ multiActionType: mode, isMultiActionMode: true })),
      setIsMultiActionMode: (isOpen) => {
        if (!isOpen) get().actions.cleanUp();
        return set(() => ({ isMultiActionMode: isOpen }));
      },
      setSelectedWorkOrders: (workOrders) =>
        set(() => ({ selectedWorkOrders: workOrders })),
      setEquipmentLink: (equipment) =>
        set(() => ({ equipmentLink: equipment })),
      cleanUp: () =>
        set(() => ({
          isMultiActionMode: false,
          selectedWorkOrders: [],
          equipmentLink: {} as AssetWithType,
          rowSelection: {},
        })),
    },
  })
);

export const useWorkOrderMultiSelectActions = () =>
  useWorkOrderMultiSelectStore((state) => state.actions);
export const useWorkOrderIsMultiActionMode = () =>
  useWorkOrderMultiSelectStore((state) => state.isMultiActionMode);
export const useSelectedWorkOrders = () =>
  useWorkOrderMultiSelectStore((state) => state.selectedWorkOrders);
export const useEquipmentLink = () =>
  useWorkOrderMultiSelectStore((state) => state.equipmentLink);
export const useRowSelectionState = () =>
  useWorkOrderMultiSelectStore((state) => state.rowSelection);
export const useMultiActionType = () =>
  useWorkOrderMultiSelectStore((state) => state.multiActionType);
