import { z } from "zod";
import { useWorkOrderV2ControllerBulkAndLinkLocation } from "../../../../api/work-order-defect-v2/work-order-defect-v2";
import { useQueryClient } from "@tanstack/react-query";
import {
  useRowSelectionState,
  useWorkOrderIsMultiActionMode,
  useWorkOrderMultiSelectActions,
} from "../../../../stores/workOrderMultiActionStore/workOrderMultiActionStore";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLocationControllerGetFullLocationList } from "../../../../api/location/location";
import { useAppSelector } from "../../../../redux/store";
import { useEffect } from "react";
import AppSelectWithDialog from "../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { selectLocationColumns } from "../../../../components/drawers/inventory/columns/selectLocationColumn";
import { Link, X } from "lucide-react";
import AppButton from "../../../../components/AppButton";
import { Button } from "../../../../components/ui/button";

const linkLocationSchema = z.object({
  location: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    {
      required_error: "Location is required",
    }
  ),
});

type LinkLocationFormType = z.infer<typeof linkLocationSchema>;

export default function MultiLinkLocation() {
  const qc = useQueryClient();
  const rowsSelect = useRowSelectionState();
  const linkingMode = useWorkOrderIsMultiActionMode();

  const { mutate, isPending } = useWorkOrderV2ControllerBulkAndLinkLocation({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("defect") ||
            (query.queryKey[0] as string).includes("work-order"),
        });
        setIsMultiActionMode(false);
      },
    },
  });
  const { setIsMultiActionMode } = useWorkOrderMultiSelectActions();
  const rowCount = Object.keys(rowsSelect).length;

  const methods = useForm<LinkLocationFormType>({
    resolver: zodResolver(linkLocationSchema),
  });

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: locationList } = useLocationControllerGetFullLocationList(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  // Clear selection location states
  useEffect(() => {
    if (!linkingMode) methods.reset();
  }, [linkingMode]);

  const onSubmit: SubmitHandler<LinkLocationFormType> = (data) => {
    mutate({
      locationId: data.location.id.toString(),
      data: {
        workOrderIds: Object.keys(rowsSelect).map((key) => Number(key)),
      },
    });
  };

  return (
    <>
      <div className=" bg-slate-900 px-4 rounded-l-2xl rounded-s-2xl text-2xl h-full flex items-center justify-center">
        <p className="font-bold text-white">{rowCount ?? 0}</p>
      </div>
      <p className="text-sm text-white">Work orders selected</p>
      <FormProvider {...methods}>
        <AppSelectWithDialog
          showArrowRight={false}
          placeholder="Select a Location"
          columns={selectLocationColumns}
          defaultValue={undefined}
          control={methods.control}
          name="location"
          items={locationList ?? []}
          onResultRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
            </div>
          )}
          onOptionsRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
            </div>
          )}
          dialogTitle="Select an Location"
          error={!!methods.formState.errors.location?.message}
          helperText={methods.formState.errors.location?.message}
          errorClassName="text-red-400"
        />
      </FormProvider>
      <AppButton
        variant="outline"
        label="Link"
        icon={<Link />}
        onClick={methods.handleSubmit(onSubmit)}
        isLoading={isPending}
      />
      <Button
        variant="ghost"
        className="hover:cursor-pointer hover:bg-primary-700 hover:bg-transparent"
        onClick={() => {
          setIsMultiActionMode(false);
        }}
      >
        <X className="text-primary-900 font-bold" />
      </Button>
    </>
  );
}
