/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateDefectTypeDto,
  DefectType,
  DefectTypeControllerDownloadDefectTypesParams,
  DefectTypeControllerGetDefectTypesParams,
  UpdateDefectTypeDto,
} from ".././model";

export const defectTypeControllerGetFullDefectType = (
  defectTypeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectType[]>> => {
  return axios.get(`/defect-type/${defectTypeId}/full`, options);
};

export const getDefectTypeControllerGetFullDefectTypeQueryKey = (
  defectTypeId: string
) => {
  return [`/defect-type/${defectTypeId}/full`] as const;
};

export const getDefectTypeControllerGetFullDefectTypeInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>
  >,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerGetFullDefectTypeQueryKey(defectTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>
  > = ({ signal }) =>
    defectTypeControllerGetFullDefectType(defectTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!defectTypeId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerGetFullDefectTypeInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>
  >;
export type DefectTypeControllerGetFullDefectTypeInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectTypeControllerGetFullDefectTypeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>
  >,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectTypeControllerGetFullDefectTypeInfiniteQueryOptions(
      defectTypeId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectTypeControllerGetFullDefectTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerGetFullDefectTypeQueryKey(defectTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>
  > = ({ signal }) =>
    defectTypeControllerGetFullDefectType(defectTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!defectTypeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerGetFullDefectTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>
>;
export type DefectTypeControllerGetFullDefectTypeQueryError =
  AxiosError<unknown>;

export const useDefectTypeControllerGetFullDefectType = <
  TData = Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetFullDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectTypeControllerGetFullDefectTypeQueryOptions(
    defectTypeId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectTypeControllerGetDefectTypes = (
  params: DefectTypeControllerGetDefectTypesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectType[]>> => {
  return axios.get(`/defect-type`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDefectTypeControllerGetDefectTypesQueryKey = (
  params: DefectTypeControllerGetDefectTypesParams
) => {
  return [`/defect-type`, ...(params ? [params] : [])] as const;
};

export const getDefectTypeControllerGetDefectTypesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
    DefectTypeControllerGetDefectTypesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerGetDefectTypesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
        QueryKey,
        DefectTypeControllerGetDefectTypesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerGetDefectTypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
    QueryKey,
    DefectTypeControllerGetDefectTypesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    defectTypeControllerGetDefectTypes(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
    TError,
    TData,
    Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
    QueryKey,
    DefectTypeControllerGetDefectTypesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerGetDefectTypesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>
>;
export type DefectTypeControllerGetDefectTypesInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectTypeControllerGetDefectTypesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
    DefectTypeControllerGetDefectTypesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerGetDefectTypesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
        QueryKey,
        DefectTypeControllerGetDefectTypesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectTypeControllerGetDefectTypesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectTypeControllerGetDefectTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerGetDefectTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerGetDefectTypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>
  > = ({ signal }) =>
    defectTypeControllerGetDefectTypes(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerGetDefectTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>
>;
export type DefectTypeControllerGetDefectTypesQueryError = AxiosError<unknown>;

export const useDefectTypeControllerGetDefectTypes = <
  TData = Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerGetDefectTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetDefectTypes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectTypeControllerGetDefectTypesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectTypeControllerCreateDefectType = (
  createDefectTypeDto: CreateDefectTypeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/defect-type`, createDefectTypeDto, options);
};

export const getDefectTypeControllerCreateDefectTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectTypeControllerCreateDefectType>>,
    TError,
    { data: CreateDefectTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof defectTypeControllerCreateDefectType>>,
  TError,
  { data: CreateDefectTypeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof defectTypeControllerCreateDefectType>>,
    { data: CreateDefectTypeDto }
  > = (props) => {
    const { data } = props ?? {};

    return defectTypeControllerCreateDefectType(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DefectTypeControllerCreateDefectTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerCreateDefectType>>
>;
export type DefectTypeControllerCreateDefectTypeMutationBody =
  CreateDefectTypeDto;
export type DefectTypeControllerCreateDefectTypeMutationError =
  AxiosError<unknown>;

export const useDefectTypeControllerCreateDefectType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectTypeControllerCreateDefectType>>,
    TError,
    { data: CreateDefectTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof defectTypeControllerCreateDefectType>>,
  TError,
  { data: CreateDefectTypeDto },
  TContext
> => {
  const mutationOptions =
    getDefectTypeControllerCreateDefectTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const defectTypeControllerDownloadDefectTypes = (
  params: DefectTypeControllerDownloadDefectTypesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectType[]>> => {
  return axios.get(`/defect-type/download`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDefectTypeControllerDownloadDefectTypesQueryKey = (
  params: DefectTypeControllerDownloadDefectTypesParams
) => {
  return [`/defect-type/download`, ...(params ? [params] : [])] as const;
};

export const getDefectTypeControllerDownloadDefectTypesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
    DefectTypeControllerDownloadDefectTypesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerDownloadDefectTypesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
        QueryKey,
        DefectTypeControllerDownloadDefectTypesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerDownloadDefectTypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
    QueryKey,
    DefectTypeControllerDownloadDefectTypesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    defectTypeControllerDownloadDefectTypes(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
    TError,
    TData,
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
    QueryKey,
    DefectTypeControllerDownloadDefectTypesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerDownloadDefectTypesInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>
  >;
export type DefectTypeControllerDownloadDefectTypesInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectTypeControllerDownloadDefectTypesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
    DefectTypeControllerDownloadDefectTypesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerDownloadDefectTypesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
        QueryKey,
        DefectTypeControllerDownloadDefectTypesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectTypeControllerDownloadDefectTypesInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectTypeControllerDownloadDefectTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerDownloadDefectTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerDownloadDefectTypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>
  > = ({ signal }) =>
    defectTypeControllerDownloadDefectTypes(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerDownloadDefectTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>
>;
export type DefectTypeControllerDownloadDefectTypesQueryError =
  AxiosError<unknown>;

export const useDefectTypeControllerDownloadDefectTypes = <
  TData = Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
  TError = AxiosError<unknown>
>(
  params: DefectTypeControllerDownloadDefectTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerDownloadDefectTypes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectTypeControllerDownloadDefectTypesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectTypeControllerGetOneDefectType = (
  defectTypeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DefectType>> => {
  return axios.get(`/defect-type/${defectTypeId}`, options);
};

export const getDefectTypeControllerGetOneDefectTypeQueryKey = (
  defectTypeId: string
) => {
  return [`/defect-type/${defectTypeId}`] as const;
};

export const getDefectTypeControllerGetOneDefectTypeInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>
  >,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerGetOneDefectTypeQueryKey(defectTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>
  > = ({ signal }) =>
    defectTypeControllerGetOneDefectType(defectTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!defectTypeId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerGetOneDefectTypeInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>>;
export type DefectTypeControllerGetOneDefectTypeInfiniteQueryError =
  AxiosError<unknown>;

export const useDefectTypeControllerGetOneDefectTypeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>
  >,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDefectTypeControllerGetOneDefectTypeInfiniteQueryOptions(
      defectTypeId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDefectTypeControllerGetOneDefectTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDefectTypeControllerGetOneDefectTypeQueryKey(defectTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>
  > = ({ signal }) =>
    defectTypeControllerGetOneDefectType(defectTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!defectTypeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DefectTypeControllerGetOneDefectTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>
>;
export type DefectTypeControllerGetOneDefectTypeQueryError =
  AxiosError<unknown>;

export const useDefectTypeControllerGetOneDefectType = <
  TData = Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
  TError = AxiosError<unknown>
>(
  defectTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof defectTypeControllerGetOneDefectType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDefectTypeControllerGetOneDefectTypeQueryOptions(
    defectTypeId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const defectTypeControllerUpdateDefectType = (
  defectTypeId: string,
  updateDefectTypeDto: UpdateDefectTypeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/defect-type/${defectTypeId}`,
    updateDefectTypeDto,
    options
  );
};

export const getDefectTypeControllerUpdateDefectTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectTypeControllerUpdateDefectType>>,
    TError,
    { defectTypeId: string; data: UpdateDefectTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof defectTypeControllerUpdateDefectType>>,
  TError,
  { defectTypeId: string; data: UpdateDefectTypeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof defectTypeControllerUpdateDefectType>>,
    { defectTypeId: string; data: UpdateDefectTypeDto }
  > = (props) => {
    const { defectTypeId, data } = props ?? {};

    return defectTypeControllerUpdateDefectType(
      defectTypeId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DefectTypeControllerUpdateDefectTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerUpdateDefectType>>
>;
export type DefectTypeControllerUpdateDefectTypeMutationBody =
  UpdateDefectTypeDto;
export type DefectTypeControllerUpdateDefectTypeMutationError =
  AxiosError<unknown>;

export const useDefectTypeControllerUpdateDefectType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectTypeControllerUpdateDefectType>>,
    TError,
    { defectTypeId: string; data: UpdateDefectTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof defectTypeControllerUpdateDefectType>>,
  TError,
  { defectTypeId: string; data: UpdateDefectTypeDto },
  TContext
> => {
  const mutationOptions =
    getDefectTypeControllerUpdateDefectTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const defectTypeControllerDeleteDefectType = (
  defectTypeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/defect-type/${defectTypeId}`, options);
};

export const getDefectTypeControllerDeleteDefectTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectTypeControllerDeleteDefectType>>,
    TError,
    { defectTypeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof defectTypeControllerDeleteDefectType>>,
  TError,
  { defectTypeId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof defectTypeControllerDeleteDefectType>>,
    { defectTypeId: string }
  > = (props) => {
    const { defectTypeId } = props ?? {};

    return defectTypeControllerDeleteDefectType(defectTypeId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DefectTypeControllerDeleteDefectTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof defectTypeControllerDeleteDefectType>>
>;

export type DefectTypeControllerDeleteDefectTypeMutationError =
  AxiosError<unknown>;

export const useDefectTypeControllerDeleteDefectType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof defectTypeControllerDeleteDefectType>>,
    TError,
    { defectTypeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof defectTypeControllerDeleteDefectType>>,
  TError,
  { defectTypeId: string },
  TContext
> => {
  const mutationOptions =
    getDefectTypeControllerDeleteDefectTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
