import { ColumnDef } from "@tanstack/react-table";
import {
  CreateStockDtoInventoryMode,
  StockEntity,
} from "../../../../../../../../api/model";
import { useStocksControllerGetStocks } from "../../../../../../../../api/stocks-inventory/stocks-inventory";
import { useAppSelector } from "../../../../../../../../redux/store";
import { useWorkOrderInventoryActions } from "../../../../../../../../stores/workOrderInventoryStore/workOrderInventoryStore";
import DrawerFormSkeleton from "../../../../../../../skeletons/DrawerFormSkeleton";
import { Badge } from "../../../../../../../ui/badge";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../ui/popover";
import { Button } from "../../../../../../../ui/button";
import InventoryLocationPopover from "./components/InventoryLocationPopover";

export const selectInventoryColumns: ColumnDef<StockEntity>[] = [
  {
    id: "id",
    accessorKey: "id",
  },
  {
    id: "isMultiLocation",
    accessorKey: "isMultiLocation",
  },
  {
    id: "name",
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => {
      const inventoryMode = row.getValue(
        "inventoryMode"
      ) as CreateStockDtoInventoryMode;
      return (
        <div>
          <p>{row.getValue("name")}</p>
          {inventoryMode === CreateStockDtoInventoryMode.SERIALIZED && (
            <Badge className="bg-primary-900">{inventoryMode}</Badge>
          )}
        </div>
      );
    },
  },
  {
    id: "inventoryMode",
    accessorKey: "inventoryMode",
  },
  {
    id: "description",
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "stockType.name",
    header: "Inventory Type",
  },
  {
    accessorKey: "balance",
    header: "Balance",
  },
  // {
  //   accessorKey: "photos",
  //   header: "Photos",
  //   cell: ({ row }) => (
  //     <AppMultiImage attachments={row.getValue("photos")} hideMoreThanThree />
  //   ),
  // },
  {
    header: "Actions",
    cell: ({ row }) => {
      const inventoryId = row.getValue("id") as number;
      const activeProj = useAppSelector((state) => state.root.activeProject);

      const { data: inventoryList, isLoading: inventoryListIsLoading } =
        useStocksControllerGetStocks(
          {
            projectId: activeProj?.id?.toString() ?? "",
          },
          {
            query: {
              enabled: !!activeProj,
              select: (res) => res.data,
            },
          }
        );

      const { getCurrentAmt, reduceAmt, increaseAmt } =
        useWorkOrderInventoryActions();

      // const isMultiLocation = row.getValue("isMultiLocation") ?? false;
      // To hide all multilocation for now, not used atm.
      const isMultiLocation = false;

      if (!inventoryList && inventoryListIsLoading)
        return <DrawerFormSkeleton />;

      return isMultiLocation ? (
        <>
          <InventoryLocationPopover inventoryId={row.getValue("id")} />
        </>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="flex border-primary-900 border-solid">
            <span
              className="px-2 py-1 bg-primary-900 text-white font-bold hover:cursor-pointer rounded-md"
              onClick={() => {
                if (!activeProj) return;
                reduceAmt({
                  inventoryId: inventoryId.toString(),
                  locationId: -1, // Since this is not multi location, locationId by default will be -1
                });
              }}
            >
              -
            </span>
            <span className="px-2 py-1">
              <p>
                {getCurrentAmt({
                  inventoryId: inventoryId.toString(),
                  locationId: -1, // Since this is not multi location, locationId by default will be -1
                })}
              </p>
            </span>
            <span
              className="px-2 py-1 bg-primary-900 text-white font-bold hover:cursor-pointer rounded-md"
              onClick={() => {
                if (!activeProj) return;
                increaseAmt({
                  inventoryId: inventoryId.toString(),
                  activeProj,
                  inventoryList: inventoryList ?? [],
                  locationId: -1, // Since this is not multi location, locationId by default will be -1,
                });
              }}
            >
              +
            </span>
          </div>
        </div>
      );
    },
  },
];
