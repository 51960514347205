import { create } from "zustand";
import { BimNodes, PartialBimNode } from "../../api/model";

export enum BimPointerState {
  SELECTION = "SELECTION",
  EQUIPMENT_LINKING = "EQUIPMENT_LINKING",
  OPACITY_ADJUSTMENT = "OPACITY_ADJUSTMENT",
}

interface BimStoreState {
  opacity: number;
  prevPointerState: BimPointerState | null;
  pointerState: BimPointerState;
  selectedBimNodes: BimNodes[];
  lastRemovedNode: BimNodes | null;
  actions: {
    setSelectedBimNodes: (bimNodes: BimNodes[]) => void;
    removeSelectedBimNodes: (bimNode: BimNodes) => void;
    changePointerState: (pointerState: BimPointerState) => void;
    clearSelection: () => void;
    setOpacity: (op: number) => void;
  };
}

export const useBimStore = create<BimStoreState>((set, get) => ({
  opacity: 1,
  prevPointerState: null,
  pointerState: BimPointerState.SELECTION,
  selectedBimNodes: [],
  lastRemovedNode: null,
  actions: {
    setSelectedBimNodes: (bimNodes) => {
      // Check if selected bim node is already selected
      const bimNodeAlreadySelected = get().selectedBimNodes.find(
        (selectedBimNode) => selectedBimNode.name === bimNodes[0].name
      );

      if (bimNodeAlreadySelected) {
        return set(() => ({
          selectedBimNodes: [...get().selectedBimNodes].filter(
            (selectedBimNode) => selectedBimNode.name !== bimNodes[0].name
          ),
        }));
      }

      return set(() => ({
        selectedBimNodes: [...get().selectedBimNodes, ...bimNodes],
      }));
    },
    removeSelectedBimNodes: (node) => {
      return set(() => ({
        lastRemovedNode: node,
        selectedBimNodes: get().selectedBimNodes.filter(
          (bimNode) => bimNode.name !== node.name
        ),
      }));
    },
    changePointerState: (pointerState) => {
      return set(() => ({
        pointerState,
        prevPointerState: get().pointerState,
      }));
    },
    clearSelection: () => {
      return set(() => ({
        selectedBimNodes: [],
        lastRemovedNode: null,
        prevPointerState: null,
      }));
    },
    setOpacity: (o) => {
      return set(() => ({
        opacity: o,
      }));
    },
  },
}));

export const useBimActions = () => useBimStore((state) => state.actions);

export const useSelectedBimNodes = () =>
  useBimStore((state) => state.selectedBimNodes);

export const useLastRemovedNode = () =>
  useBimStore((state) => state.lastRemovedNode);

export const useLastRemoveAndSelectedBimStates = () =>
  useBimStore((state) => ({
    lastRemovedNode: state.lastRemovedNode,
    selectedBimNodes: state.selectedBimNodes,
  }));

export const useBimPointerState = () =>
  useBimStore((state) => state.pointerState);

export const usePrevPointerState = () =>
  useBimStore((state) => state.prevPointerState);

export const useBimEquipmentLinkingMode = () =>
  useBimStore(
    (state) => state.pointerState === BimPointerState.EQUIPMENT_LINKING
  );

export const useBimOpacity = () => useBimStore((state) => state.opacity);
