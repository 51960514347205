import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  useWorkRequestControllerGetWorkRequestStatusCount,
  useWorkRequestControllerGetWorkRequests,
} from "../../../api/work-request/work-request";
import { DataTable } from "../../../components/react-table/DataTable";
import useDebounce from "../../../hooks/useDebounce";
import {
  WorkRequestControllerGetWorkRequestStatusCountApiArg,
  WorkRequestControllerGetWorkRequestsApiArg,
} from "../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../redux/store";
import { useWorkRequestFilterStore } from "../../../stores/workRequest/workRequestFilterStore";
import {
  workRequestColumns,
  workRequestColumnsWithRating,
} from "./components/WorkRequestColumns";
import { WorkRequestControllerGetWorkRequestsParams } from "../../../api/model";
import { useWrRatingIsEnabled } from "../../../hooks/useFeatures";

interface WorkRequestTabProps {
  workRequestStatus: "NEW" | "IN_PROGRESS" | "COMPLETED" | "CANCELLED";
}

export default function WorkRequestTab({
  workRequestStatus,
}: WorkRequestTabProps) {
  const wrRatingIsEnabled = useWrRatingIsEnabled();
  const locationFilter = useWorkRequestFilterStore(
    (state) => state.filterLocation
  );
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const dateRange = useWorkRequestFilterStore((state) => state.dateRange);

  const queryArg = useDebounce<WorkRequestControllerGetWorkRequestsParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      workRequestStatus: workRequestStatus,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      showUnlinkedOnly: false,
      search,
      locFilterId: locationFilter?.id.toString() ?? "",
      filterStartDateISO: dateRange?.from?.toISOString(),
      filterEndDateISO: dateRange?.to?.toISOString(),
    },
    delay: 500,
  });

  const queryStatusArg =
    useDebounce<WorkRequestControllerGetWorkRequestStatusCountApiArg>({
      value: {
        projectId: activeProj?.id?.toString() ?? "",
        search,
        locFilterId: locationFilter?.id.toString() ?? "",
      },
      delay: 500,
    });

  const {
    data: workRequestList,
    isFetching,
    isLoading,
  } = useWorkRequestControllerGetWorkRequests(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const { data: countData } = useWorkRequestControllerGetWorkRequestStatusCount(
    queryStatusArg,
    {
      query: {
        select: (resultSet) => {
          let statCount;
          switch (workRequestStatus) {
            case "NEW":
              statCount = resultSet.data?.newCount;
              break;
            case "IN_PROGRESS":
              statCount = resultSet.data?.inProgressCount;
              break;
            case "COMPLETED":
              statCount = resultSet.data?.completedCount;
              break;
            case "CANCELLED":
              statCount = resultSet.data?.cancelledCount;
              break;
          }
          return { ...resultSet, statCount };
        },
      },
    }
  );

  const table = useReactTable({
    data: workRequestList?.data ?? [],
    columns:
      workRequestStatus === "COMPLETED" && wrRatingIsEnabled
        ? workRequestColumnsWithRating
        : workRequestColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: countData?.statCount
      ? Math.ceil(countData.statCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  // reset table pagination if filters have changed
  useEffect(() => {
    table.resetPagination();
  }, [search, locationFilter, dateRange]);

  return (
    <DataTable
      isLoading={isLoading}
      isFetching={isFetching}
      table={table}
      totalCount={countData?.statCount ?? 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );
}
