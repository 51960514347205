import { useQueryClient } from "@tanstack/react-query";
import { Attachment } from "../../../../../../api/model";
import { useWorkOrderV2ControllerDeleteWorkOrderVideoDoc } from "../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import AppDocument from "../../../../../AppDocument";
import { useSearchParams } from "react-router-dom";

interface WorkOrderDocVidItemProps {
  att: Attachment;
}

export default function WorkOrderDocVidItem({ att }: WorkOrderDocVidItemProps) {
  const [searchParam] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");

  const qc = useQueryClient();
  const { mutate, isPending } = useWorkOrderV2ControllerDeleteWorkOrderVideoDoc(
    {
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (qry) =>
              (qry.queryKey[0] as string).includes("work-order") ||
              (qry.queryKey[0] as string).includes("defect") ||
              (qry.queryKey[0] as string).includes("comment"),
          });
        },
      },
    }
  );

  const onDelete = async (att: Attachment) => {
    if (!att.id) return;
    mutate({
      workOrderId: Number(activeWoId as string),
      docId: att.id,
    });
  };

  return (
    <AppDocument att={att} onDelete={onDelete} onDeleteLoading={isPending} />
  );
}
