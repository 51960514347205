/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateStockTypeDto,
  GetStockTypesDto,
  StockType,
  StockTypesControllerGetStockTypesParams,
  UpdateStockTypeDto,
} from ".././model";

export const stockTypesControllerGetStockTypes = (
  params: StockTypesControllerGetStockTypesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetStockTypesDto[]>> => {
  return axios.get(`/stock-types`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getStockTypesControllerGetStockTypesQueryKey = (
  params: StockTypesControllerGetStockTypesParams
) => {
  return [`/stock-types`, ...(params ? [params] : [])] as const;
};

export const getStockTypesControllerGetStockTypesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
    StockTypesControllerGetStockTypesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: StockTypesControllerGetStockTypesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
        QueryKey,
        StockTypesControllerGetStockTypesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStockTypesControllerGetStockTypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
    QueryKey,
    StockTypesControllerGetStockTypesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    stockTypesControllerGetStockTypes(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
    TError,
    TData,
    Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
    QueryKey,
    StockTypesControllerGetStockTypesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type StockTypesControllerGetStockTypesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>
>;
export type StockTypesControllerGetStockTypesInfiniteQueryError =
  AxiosError<unknown>;

export const useStockTypesControllerGetStockTypesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
    StockTypesControllerGetStockTypesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: StockTypesControllerGetStockTypesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
        TError,
        TData,
        Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
        QueryKey,
        StockTypesControllerGetStockTypesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStockTypesControllerGetStockTypesInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStockTypesControllerGetStockTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
  TError = AxiosError<unknown>
>(
  params: StockTypesControllerGetStockTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStockTypesControllerGetStockTypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>
  > = ({ signal }) =>
    stockTypesControllerGetStockTypes(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StockTypesControllerGetStockTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>
>;
export type StockTypesControllerGetStockTypesQueryError = AxiosError<unknown>;

export const useStockTypesControllerGetStockTypes = <
  TData = Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
  TError = AxiosError<unknown>
>(
  params: StockTypesControllerGetStockTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetStockTypes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStockTypesControllerGetStockTypesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stockTypesControllerCreateStockType = (
  createStockTypeDto: CreateStockTypeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/stock-types`, createStockTypeDto, options);
};

export const getStockTypesControllerCreateStockTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockTypesControllerCreateStockType>>,
    TError,
    { data: CreateStockTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stockTypesControllerCreateStockType>>,
  TError,
  { data: CreateStockTypeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stockTypesControllerCreateStockType>>,
    { data: CreateStockTypeDto }
  > = (props) => {
    const { data } = props ?? {};

    return stockTypesControllerCreateStockType(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StockTypesControllerCreateStockTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof stockTypesControllerCreateStockType>>
>;
export type StockTypesControllerCreateStockTypeMutationBody =
  CreateStockTypeDto;
export type StockTypesControllerCreateStockTypeMutationError =
  AxiosError<unknown>;

export const useStockTypesControllerCreateStockType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockTypesControllerCreateStockType>>,
    TError,
    { data: CreateStockTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stockTypesControllerCreateStockType>>,
  TError,
  { data: CreateStockTypeDto },
  TContext
> => {
  const mutationOptions =
    getStockTypesControllerCreateStockTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stockTypesControllerGetOneStockType = (
  stockTypeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StockType>> => {
  return axios.get(`/stock-types/${stockTypeId}`, options);
};

export const getStockTypesControllerGetOneStockTypeQueryKey = (
  stockTypeId: string
) => {
  return [`/stock-types/${stockTypeId}`] as const;
};

export const getStockTypesControllerGetOneStockTypeInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>
  >,
  TError = AxiosError<unknown>
>(
  stockTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStockTypesControllerGetOneStockTypeQueryKey(stockTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>
  > = ({ signal }) =>
    stockTypesControllerGetOneStockType(stockTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!stockTypeId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StockTypesControllerGetOneStockTypeInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>>;
export type StockTypesControllerGetOneStockTypeInfiniteQueryError =
  AxiosError<unknown>;

export const useStockTypesControllerGetOneStockTypeInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>
  >,
  TError = AxiosError<unknown>
>(
  stockTypeId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getStockTypesControllerGetOneStockTypeInfiniteQueryOptions(
      stockTypeId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getStockTypesControllerGetOneStockTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
  TError = AxiosError<unknown>
>(
  stockTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStockTypesControllerGetOneStockTypeQueryKey(stockTypeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>
  > = ({ signal }) =>
    stockTypesControllerGetOneStockType(stockTypeId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!stockTypeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StockTypesControllerGetOneStockTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>
>;
export type StockTypesControllerGetOneStockTypeQueryError = AxiosError<unknown>;

export const useStockTypesControllerGetOneStockType = <
  TData = Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
  TError = AxiosError<unknown>
>(
  stockTypeId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stockTypesControllerGetOneStockType>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getStockTypesControllerGetOneStockTypeQueryOptions(
    stockTypeId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const stockTypesControllerUpdateStockType = (
  stockTypeId: string,
  updateStockTypeDto: UpdateStockTypeDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.put(`/stock-types/${stockTypeId}`, updateStockTypeDto, options);
};

export const getStockTypesControllerUpdateStockTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockTypesControllerUpdateStockType>>,
    TError,
    { stockTypeId: string; data: UpdateStockTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stockTypesControllerUpdateStockType>>,
  TError,
  { stockTypeId: string; data: UpdateStockTypeDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stockTypesControllerUpdateStockType>>,
    { stockTypeId: string; data: UpdateStockTypeDto }
  > = (props) => {
    const { stockTypeId, data } = props ?? {};

    return stockTypesControllerUpdateStockType(stockTypeId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StockTypesControllerUpdateStockTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof stockTypesControllerUpdateStockType>>
>;
export type StockTypesControllerUpdateStockTypeMutationBody =
  UpdateStockTypeDto;
export type StockTypesControllerUpdateStockTypeMutationError =
  AxiosError<unknown>;

export const useStockTypesControllerUpdateStockType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockTypesControllerUpdateStockType>>,
    TError,
    { stockTypeId: string; data: UpdateStockTypeDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stockTypesControllerUpdateStockType>>,
  TError,
  { stockTypeId: string; data: UpdateStockTypeDto },
  TContext
> => {
  const mutationOptions =
    getStockTypesControllerUpdateStockTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stockTypesControllerDeleteStockType = (
  stockTypeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/stock-types/${stockTypeId}`, options);
};

export const getStockTypesControllerDeleteStockTypeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockTypesControllerDeleteStockType>>,
    TError,
    { stockTypeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stockTypesControllerDeleteStockType>>,
  TError,
  { stockTypeId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stockTypesControllerDeleteStockType>>,
    { stockTypeId: string }
  > = (props) => {
    const { stockTypeId } = props ?? {};

    return stockTypesControllerDeleteStockType(stockTypeId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StockTypesControllerDeleteStockTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof stockTypesControllerDeleteStockType>>
>;

export type StockTypesControllerDeleteStockTypeMutationError =
  AxiosError<unknown>;

export const useStockTypesControllerDeleteStockType = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stockTypesControllerDeleteStockType>>,
    TError,
    { stockTypeId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof stockTypesControllerDeleteStockType>>,
  TError,
  { stockTypeId: string },
  TContext
> => {
  const mutationOptions =
    getStockTypesControllerDeleteStockTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
