import { useSearchParams } from "react-router-dom";
import { useStocksControllerGetInventoryLocations } from "../../../../api/stocks-inventory/stocks-inventory";
import AppButton from "../../../AppButton";
import AddInventoryLocationDialogBtn from "../dialog/AddInventoryLocationDialogBtn";
import { useEffect } from "react";
import { DataTable } from "../../../react-table/DataTable";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { inventoryLocationBalanceColumns } from "../columns/inventoryLocationBalanceColumn";

export default function InventoryLocationTab() {
  const [searchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");
  const {
    data: inventoryLocations,
    isLoading,
    isFetching,
  } = useStocksControllerGetInventoryLocations(inventoryId ?? "", {
    query: {
      enabled: !!inventoryId,
      select: (res) => res.data.data,
    },
  });

  const table = useReactTable({
    data: inventoryLocations ?? [],
    columns: inventoryLocationBalanceColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
        locationId: false,
      },
    },
  });

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <h3 className="text-xl font-semibold">Inventory Locations</h3>
        <AddInventoryLocationDialogBtn />
      </div>
      <DataTable
        isLoading={isLoading}
        isFetching={isFetching}
        table={table}
        totalCount={table.getTotalSize()}
      />
    </div>
  );
}
