import { ChevronLeft } from "lucide-react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { buttonVariants } from "../../components/ui/button";
import {
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/ui/resizable";
import { cn } from "../../lib/utils";

const tabLocations = [
  { to: "/home/settings", label: "User Accounts" },
  // { to: "/home/settings/access-control", label: "Access Control" },
  // { to: "/home/settings/analytics", label: "Analytics" },
  { to: "/home/settings/work-order-type", label: "Work Order Type" },
  {
    to: "/home/settings/man-hour-settings",
    label: "Man Hours",
  },
  {
    to: "/home/settings/data-import",
    label: "Data Import",
  },
  {
    to: "/home/settings/work-request-settings",
    label: "Work Request Settings",
  },
];

export default function SettingScreen() {
  const nav = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col gap-4 p-10">
      <ResizablePanelGroup
        direction="horizontal"
        className="h-full min-h-screen items-stretch"
      >
        <ResizablePanel defaultSize={12.5} className="min-w-60">
          <nav className="flex flex-col space-y-3 min-w-60">
            <div className="flex gap-6 items-center ">
              <AppButton
                icon={<ChevronLeft />}
                variant="outline"
                onClick={() => {
                  nav("/home");
                }}
              />
              <h1 className="text-xl font-medium">Settings</h1>
            </div>
            {tabLocations.map((tabLink) => (
              <Link
                key={tabLink.to}
                to={tabLink.to}
                className={cn(
                  buttonVariants({ variant: "ghost" }),
                  "justify-start text-slate-400 font-normal",
                  pathname === tabLink.to
                    ? "bg-muted hover:bg-muted bg-primary-200 text-primary-900 font-bold hover:text-primary-900"
                    : "hover:bg-slate-200 hover:text-slate-800"
                )}
              >
                {tabLink.label}
              </Link>
            ))}
          </nav>
        </ResizablePanel>
        <ResizablePanel defaultSize={1}></ResizablePanel>
        <ResizablePanel className="px-2">
          <Outlet />
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
