/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  BimControllerDeleteBimNodes200,
  BimControllerGetBimModelParams,
  GetBimModelResponseDto,
  GetBimNodesResponseDto,
  LinkBimNodesToAssetRequestDto,
  SetupBimNodesRequestDto,
  UploadBimModelRequestDto,
} from ".././model";

export const bimControllerCreateBimModel = (
  uploadBimModelRequestDto: UploadBimModelRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/bim`, uploadBimModelRequestDto, options);
};

export const getBimControllerCreateBimModelMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerCreateBimModel>>,
    TError,
    { data: UploadBimModelRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bimControllerCreateBimModel>>,
  TError,
  { data: UploadBimModelRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bimControllerCreateBimModel>>,
    { data: UploadBimModelRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return bimControllerCreateBimModel(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BimControllerCreateBimModelMutationResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerCreateBimModel>>
>;
export type BimControllerCreateBimModelMutationBody = UploadBimModelRequestDto;
export type BimControllerCreateBimModelMutationError = AxiosError<unknown>;

export const useBimControllerCreateBimModel = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerCreateBimModel>>,
    TError,
    { data: UploadBimModelRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof bimControllerCreateBimModel>>,
  TError,
  { data: UploadBimModelRequestDto },
  TContext
> => {
  const mutationOptions =
    getBimControllerCreateBimModelMutationOptions(options);

  return useMutation(mutationOptions);
};
export const bimControllerGetBimModel = (
  params: BimControllerGetBimModelParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetBimModelResponseDto>> => {
  return axios.get(`/bim`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getBimControllerGetBimModelQueryKey = (
  params: BimControllerGetBimModelParams
) => {
  return [`/bim`, ...(params ? [params] : [])] as const;
};

export const getBimControllerGetBimModelInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof bimControllerGetBimModel>>,
    BimControllerGetBimModelParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: BimControllerGetBimModelParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimModel>>,
        TError,
        TData,
        Awaited<ReturnType<typeof bimControllerGetBimModel>>,
        QueryKey,
        BimControllerGetBimModelParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBimControllerGetBimModelQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof bimControllerGetBimModel>>,
    QueryKey,
    BimControllerGetBimModelParams["cursorId"]
  > = ({ signal, pageParam }) =>
    bimControllerGetBimModel(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof bimControllerGetBimModel>>,
    TError,
    TData,
    Awaited<ReturnType<typeof bimControllerGetBimModel>>,
    QueryKey,
    BimControllerGetBimModelParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type BimControllerGetBimModelInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerGetBimModel>>
>;
export type BimControllerGetBimModelInfiniteQueryError = AxiosError<unknown>;

export const useBimControllerGetBimModelInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof bimControllerGetBimModel>>,
    BimControllerGetBimModelParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: BimControllerGetBimModelParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimModel>>,
        TError,
        TData,
        Awaited<ReturnType<typeof bimControllerGetBimModel>>,
        QueryKey,
        BimControllerGetBimModelParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBimControllerGetBimModelInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBimControllerGetBimModelQueryOptions = <
  TData = Awaited<ReturnType<typeof bimControllerGetBimModel>>,
  TError = AxiosError<unknown>
>(
  params: BimControllerGetBimModelParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimModel>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBimControllerGetBimModelQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof bimControllerGetBimModel>>
  > = ({ signal }) =>
    bimControllerGetBimModel(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof bimControllerGetBimModel>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BimControllerGetBimModelQueryResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerGetBimModel>>
>;
export type BimControllerGetBimModelQueryError = AxiosError<unknown>;

export const useBimControllerGetBimModel = <
  TData = Awaited<ReturnType<typeof bimControllerGetBimModel>>,
  TError = AxiosError<unknown>
>(
  params: BimControllerGetBimModelParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimModel>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBimControllerGetBimModelQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const bimControllerSetupBimNodes = (
  bimId: string,
  setupBimNodesRequestDto: SetupBimNodesRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/bim/${bimId}`, setupBimNodesRequestDto, options);
};

export const getBimControllerSetupBimNodesMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerSetupBimNodes>>,
    TError,
    { bimId: string; data: SetupBimNodesRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bimControllerSetupBimNodes>>,
  TError,
  { bimId: string; data: SetupBimNodesRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bimControllerSetupBimNodes>>,
    { bimId: string; data: SetupBimNodesRequestDto }
  > = (props) => {
    const { bimId, data } = props ?? {};

    return bimControllerSetupBimNodes(bimId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BimControllerSetupBimNodesMutationResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerSetupBimNodes>>
>;
export type BimControllerSetupBimNodesMutationBody = SetupBimNodesRequestDto;
export type BimControllerSetupBimNodesMutationError = AxiosError<unknown>;

export const useBimControllerSetupBimNodes = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerSetupBimNodes>>,
    TError,
    { bimId: string; data: SetupBimNodesRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof bimControllerSetupBimNodes>>,
  TError,
  { bimId: string; data: SetupBimNodesRequestDto },
  TContext
> => {
  const mutationOptions = getBimControllerSetupBimNodesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const bimControllerGetBimNodes = (
  bimId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetBimNodesResponseDto>> => {
  return axios.get(`/bim/${bimId}/bimNodes`, options);
};

export const getBimControllerGetBimNodesQueryKey = (bimId: string) => {
  return [`/bim/${bimId}/bimNodes`] as const;
};

export const getBimControllerGetBimNodesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof bimControllerGetBimNodes>>>,
  TError = AxiosError<unknown>
>(
  bimId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBimControllerGetBimNodesQueryKey(bimId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof bimControllerGetBimNodes>>
  > = ({ signal }) =>
    bimControllerGetBimNodes(bimId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!bimId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BimControllerGetBimNodesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerGetBimNodes>>
>;
export type BimControllerGetBimNodesInfiniteQueryError = AxiosError<unknown>;

export const useBimControllerGetBimNodesInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof bimControllerGetBimNodes>>>,
  TError = AxiosError<unknown>
>(
  bimId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBimControllerGetBimNodesInfiniteQueryOptions(
    bimId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBimControllerGetBimNodesQueryOptions = <
  TData = Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
  TError = AxiosError<unknown>
>(
  bimId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBimControllerGetBimNodesQueryKey(bimId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof bimControllerGetBimNodes>>
  > = ({ signal }) =>
    bimControllerGetBimNodes(bimId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!bimId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BimControllerGetBimNodesQueryResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerGetBimNodes>>
>;
export type BimControllerGetBimNodesQueryError = AxiosError<unknown>;

export const useBimControllerGetBimNodes = <
  TData = Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
  TError = AxiosError<unknown>
>(
  bimId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof bimControllerGetBimNodes>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBimControllerGetBimNodesQueryOptions(bimId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const bimControllerDeleteBimNodes = (
  bimId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<BimControllerDeleteBimNodes200>> => {
  return axios.delete(`/bim/${bimId}/bimNodes`, options);
};

export const getBimControllerDeleteBimNodesMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerDeleteBimNodes>>,
    TError,
    { bimId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bimControllerDeleteBimNodes>>,
  TError,
  { bimId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bimControllerDeleteBimNodes>>,
    { bimId: string }
  > = (props) => {
    const { bimId } = props ?? {};

    return bimControllerDeleteBimNodes(bimId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BimControllerDeleteBimNodesMutationResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerDeleteBimNodes>>
>;

export type BimControllerDeleteBimNodesMutationError = AxiosError<unknown>;

export const useBimControllerDeleteBimNodes = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerDeleteBimNodes>>,
    TError,
    { bimId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof bimControllerDeleteBimNodes>>,
  TError,
  { bimId: string },
  TContext
> => {
  const mutationOptions =
    getBimControllerDeleteBimNodesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const bimControllerLinkBimNodesToAsset = (
  assetId: number,
  linkBimNodesToAssetRequestDto: LinkBimNodesToAssetRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/bim/linkBimNodes/${assetId}`,
    linkBimNodesToAssetRequestDto,
    options
  );
};

export const getBimControllerLinkBimNodesToAssetMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerLinkBimNodesToAsset>>,
    TError,
    { assetId: number; data: LinkBimNodesToAssetRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bimControllerLinkBimNodesToAsset>>,
  TError,
  { assetId: number; data: LinkBimNodesToAssetRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bimControllerLinkBimNodesToAsset>>,
    { assetId: number; data: LinkBimNodesToAssetRequestDto }
  > = (props) => {
    const { assetId, data } = props ?? {};

    return bimControllerLinkBimNodesToAsset(assetId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BimControllerLinkBimNodesToAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof bimControllerLinkBimNodesToAsset>>
>;
export type BimControllerLinkBimNodesToAssetMutationBody =
  LinkBimNodesToAssetRequestDto;
export type BimControllerLinkBimNodesToAssetMutationError = AxiosError<unknown>;

export const useBimControllerLinkBimNodesToAsset = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bimControllerLinkBimNodesToAsset>>,
    TError,
    { assetId: number; data: LinkBimNodesToAssetRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof bimControllerLinkBimNodesToAsset>>,
  TError,
  { assetId: number; data: LinkBimNodesToAssetRequestDto },
  TContext
> => {
  const mutationOptions =
    getBimControllerLinkBimNodesToAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
