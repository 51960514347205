/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type PolicyControllerCheckOneUserPolicyPolicyName =
  (typeof PolicyControllerCheckOneUserPolicyPolicyName)[keyof typeof PolicyControllerCheckOneUserPolicyPolicyName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyControllerCheckOneUserPolicyPolicyName = {
  ADJUST_INVENTORY: "ADJUST_INVENTORY",
  CHECK_WORK_ORDER_PRICING: "CHECK_WORK_ORDER_PRICING",
  WORK_ORDER_EDIT_AFTER_CLOSE: "WORK_ORDER_EDIT_AFTER_CLOSE",
  VIEW_PRIVATE_ATTACHMENT: "VIEW_PRIVATE_ATTACHMENT",
  ESCALATION_ACCESS: "ESCALATION_ACCESS",
} as const;
