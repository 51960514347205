import { Typography } from "@mui/material";
import { cn } from "../../../lib/utils";

export default function AppTitle({
  title,
  className,
}: {
  title: string;
  className?: string;
}) {
  return (
    <h1 className={cn("font-sans mb-4 text-3xl font-bold", className)}>
      {title}
    </h1>
  );
}
