import { useFormContext, useWatch } from "react-hook-form";
import { EscalationForm } from "../EscalationFormSchema";
import { useDefectStatusControllerGetDefectStatus } from "../../../../api/defect-status/defect-status";
import { useAppSelector } from "../../../../redux/store";
import { usePmChecklistStatusControllerGetAllPmChecklistStatus } from "../../../../api/preventive-maintenance-checklist-status/preventive-maintenance-checklist-status";
import AppSelectWithDialog from "../../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { EscalationType, WorkRequestStatus } from "../../../../api/model";
import { Checkbox } from "../../../ui/checkbox";

export const escalationWorkRequestStatus = [
  { id: 0, name: "New", value: WorkRequestStatus.NEW },
  { id: 1, name: "In Progress", value: WorkRequestStatus.IN_PROGRESS },
  // { id: 2, name: "Completed", value: WorkRequestStatus.COMPLETED },
  // { id: 3, name: "Cancelled", value: WorkRequestStatus.CANCELLED },
];

export default function EscalationSelectStatusField() {
  const methods = useFormContext<EscalationForm>();
  const activeComp = useAppSelector((state) => state.root.activeCompany);

  const escalationType = useWatch({
    control: methods.control,
    name: "escalationStatuses.escalationType",
  });

  const { data: workStatusesWithoutClose } =
    useDefectStatusControllerGetDefectStatus(
      {
        companyId: activeComp?.id.toString() ?? "",
      },
      {
        query: {
          enabled: !!activeComp,
          select: (res) => res.data.filter((i) => i.name !== "Close"),
        },
      }
    );

  const { data: pmStatusesWithoutClose } =
    usePmChecklistStatusControllerGetAllPmChecklistStatus(
      {
        companyId: activeComp?.id ?? 0,
      },
      {
        query: {
          enabled: !!activeComp,
          select: (res) => res.data.filter((i) => i.name !== "Close"),
        },
      }
    );

  return (
    <>
      <AppSelectWithDialog
        multiple
        label="Select Status *"
        placeholder="Select Status"
        defaultValue={[]}
        description="Please select the work order status that the escalation is to be tied to. Close status is not available for escalation feature."
        control={methods.control}
        items={workStatusesWithoutClose ?? []}
        hide={escalationType !== EscalationType.WORK_ORDER}
        columns={[
          {
            header: "",
            id: "select",
            cell: ({ row, table }) => (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                  row.toggleSelected(!!value);
                }}
                aria-label="Select row"
              />
            ),
            enableSorting: false,
            enableHiding: false,
          },
          {
            id: "id",
            accessorKey: "id",
          },
          {
            id: "name",
            accessorKey: "name",
          },
        ]}
        name="escalationStatuses.workOrderStatuses"
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Work Order Status to be notified"
        error={
          !!methods.formState.errors.escalationStatuses?.workOrderStatuses
            ?.message
        }
        helperText={
          methods.formState.errors.escalationStatuses?.workOrderStatuses
            ?.message
        }
      />
      <AppSelectWithDialog
        multiple
        description="Please select the PM status that the escalation is to be tied to. Close status is not available for escalation feature."
        label="Select Status"
        placeholder="Select Status"
        defaultValue={[]}
        control={methods.control}
        items={pmStatusesWithoutClose ?? []}
        hide={escalationType !== EscalationType.PM}
        columns={[
          {
            header: "",
            id: "select",
            cell: ({ row, table }) => (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                  row.toggleSelected(!!value);
                }}
                aria-label="Select row"
              />
            ),
            enableSorting: false,
            enableHiding: false,
          },
          {
            id: "id",
            accessorKey: "id",
          },
          {
            id: "name",
            accessorKey: "name",
          },
        ]}
        name="escalationStatuses.pmStatuses"
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select PM status to be notified"
        error={
          !!methods.formState.errors.escalationStatuses?.pmStatuses?.message
        }
        helperText={
          methods.formState.errors.escalationStatuses?.pmStatuses?.message
        }
      />
      <AppSelectWithDialog
        multiple
        description="Please select the work request status that the escalation is to be tied to. Cancelled and Completed status is not available for escalation feature."
        label="Select Status"
        placeholder="Select Status"
        defaultValue={[]}
        control={methods.control}
        items={escalationWorkRequestStatus ?? []}
        hide={escalationType !== EscalationType.WORK_REQUEST}
        columns={[
          {
            header: "",
            id: "select",
            cell: ({ row, table }) => (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                  row.toggleSelected(!!value);
                }}
                aria-label="Select row"
              />
            ),
            enableSorting: false,
            enableHiding: false,
          },
          {
            id: "id",
            accessorKey: "id",
          },
          {
            id: "name",
            accessorKey: "name",
          },
        ]}
        name="escalationStatuses.workRequestStatuses"
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Work Request Status to be notified"
        error={
          !!methods.formState.errors.escalationStatuses?.workRequestStatuses
            ?.message
        }
        helperText={
          methods.formState.errors.escalationStatuses?.workRequestStatuses
            ?.message
        }
      />
    </>
  );
}
