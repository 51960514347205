/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AdminGetCompaniesResponseDto,
  AdminGetOneCompanyResponseDto,
  AdminGetOneProjectResponseDto,
  CompaniesControllerCreateCompany201,
  CompaniesControllerCreateProject201,
  CompaniesControllerGetAllCompaniesParams,
  CreateCompanyDto,
  CreateProjectDto,
  GetRolesUnderCompanyResponseDto,
  ProjectWithAttachmentResponse,
  UpdateCompanyDto,
  UpdateProjectDto,
} from ".././model";

export const companiesControllerGetAllCompanies = (
  params?: CompaniesControllerGetAllCompaniesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AdminGetCompaniesResponseDto>> => {
  return axios.get(`/companies`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getCompaniesControllerGetAllCompaniesQueryKey = (
  params?: CompaniesControllerGetAllCompaniesParams
) => {
  return [`/companies`, ...(params ? [params] : [])] as const;
};

export const getCompaniesControllerGetAllCompaniesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
    CompaniesControllerGetAllCompaniesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: CompaniesControllerGetAllCompaniesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
        TError,
        TData,
        Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
        QueryKey,
        CompaniesControllerGetAllCompaniesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetAllCompaniesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
    QueryKey,
    CompaniesControllerGetAllCompaniesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    companiesControllerGetAllCompanies(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
    TError,
    TData,
    Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
    QueryKey,
    CompaniesControllerGetAllCompaniesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetAllCompaniesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>
>;
export type CompaniesControllerGetAllCompaniesInfiniteQueryError =
  AxiosError<unknown>;

export const useCompaniesControllerGetAllCompaniesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
    CompaniesControllerGetAllCompaniesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params?: CompaniesControllerGetAllCompaniesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
        TError,
        TData,
        Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
        QueryKey,
        CompaniesControllerGetAllCompaniesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getCompaniesControllerGetAllCompaniesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCompaniesControllerGetAllCompaniesQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
  TError = AxiosError<unknown>
>(
  params?: CompaniesControllerGetAllCompaniesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetAllCompaniesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>
  > = ({ signal }) =>
    companiesControllerGetAllCompanies(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetAllCompaniesQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>
>;
export type CompaniesControllerGetAllCompaniesQueryError = AxiosError<unknown>;

export const useCompaniesControllerGetAllCompanies = <
  TData = Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
  TError = AxiosError<unknown>
>(
  params?: CompaniesControllerGetAllCompaniesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetAllCompanies>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesControllerGetAllCompaniesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesControllerCreateCompany = (
  createCompanyDto: CreateCompanyDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompaniesControllerCreateCompany201>> => {
  return axios.post(`/companies`, createCompanyDto, options);
};

export const getCompaniesControllerCreateCompanyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerCreateCompany>>,
    TError,
    { data: CreateCompanyDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerCreateCompany>>,
  TError,
  { data: CreateCompanyDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerCreateCompany>>,
    { data: CreateCompanyDto }
  > = (props) => {
    const { data } = props ?? {};

    return companiesControllerCreateCompany(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerCreateCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerCreateCompany>>
>;
export type CompaniesControllerCreateCompanyMutationBody = CreateCompanyDto;
export type CompaniesControllerCreateCompanyMutationError = AxiosError<unknown>;

export const useCompaniesControllerCreateCompany = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerCreateCompany>>,
    TError,
    { data: CreateCompanyDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerCreateCompany>>,
  TError,
  { data: CreateCompanyDto },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerCreateCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesControllerGetOneCompany = (
  companyId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AdminGetOneCompanyResponseDto>> => {
  return axios.get(`/companies/${companyId}`, options);
};

export const getCompaniesControllerGetOneCompanyQueryKey = (
  companyId: string
) => {
  return [`/companies/${companyId}`] as const;
};

export const getCompaniesControllerGetOneCompanyInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetOneCompany>>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetOneCompanyQueryKey(companyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetOneCompany>>
  > = ({ signal }) =>
    companiesControllerGetOneCompany(companyId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!companyId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetOneCompanyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetOneCompany>>
>;
export type CompaniesControllerGetOneCompanyInfiniteQueryError =
  AxiosError<unknown>;

export const useCompaniesControllerGetOneCompanyInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetOneCompany>>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesControllerGetOneCompanyInfiniteQueryOptions(
    companyId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCompaniesControllerGetOneCompanyQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetOneCompanyQueryKey(companyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetOneCompany>>
  > = ({ signal }) =>
    companiesControllerGetOneCompany(companyId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!companyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetOneCompanyQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetOneCompany>>
>;
export type CompaniesControllerGetOneCompanyQueryError = AxiosError<unknown>;

export const useCompaniesControllerGetOneCompany = <
  TData = Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesControllerGetOneCompanyQueryOptions(
    companyId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesControllerUpdateCompany = (
  companyId: number,
  updateCompanyDto: UpdateCompanyDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/companies/${companyId}`, updateCompanyDto, options);
};

export const getCompaniesControllerUpdateCompanyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerUpdateCompany>>,
    TError,
    { companyId: number; data: UpdateCompanyDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerUpdateCompany>>,
  TError,
  { companyId: number; data: UpdateCompanyDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerUpdateCompany>>,
    { companyId: number; data: UpdateCompanyDto }
  > = (props) => {
    const { companyId, data } = props ?? {};

    return companiesControllerUpdateCompany(companyId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerUpdateCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerUpdateCompany>>
>;
export type CompaniesControllerUpdateCompanyMutationBody = UpdateCompanyDto;
export type CompaniesControllerUpdateCompanyMutationError = AxiosError<unknown>;

export const useCompaniesControllerUpdateCompany = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerUpdateCompany>>,
    TError,
    { companyId: number; data: UpdateCompanyDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerUpdateCompany>>,
  TError,
  { companyId: number; data: UpdateCompanyDto },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerUpdateCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesControllerDeleteCompany = (
  companyId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/companies/${companyId}`, options);
};

export const getCompaniesControllerDeleteCompanyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeleteCompany>>,
    TError,
    { companyId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerDeleteCompany>>,
  TError,
  { companyId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerDeleteCompany>>,
    { companyId: number }
  > = (props) => {
    const { companyId } = props ?? {};

    return companiesControllerDeleteCompany(companyId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerDeleteCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerDeleteCompany>>
>;

export type CompaniesControllerDeleteCompanyMutationError = AxiosError<unknown>;

export const useCompaniesControllerDeleteCompany = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeleteCompany>>,
    TError,
    { companyId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerDeleteCompany>>,
  TError,
  { companyId: number },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerDeleteCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesControllerGetProjectsUnderCompany = (
  companyId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProjectWithAttachmentResponse[]>> => {
  return axios.get(`/companies/${companyId}/projects`, options);
};

export const getCompaniesControllerGetProjectsUnderCompanyQueryKey = (
  companyId: string
) => {
  return [`/companies/${companyId}/projects`] as const;
};

export const getCompaniesControllerGetProjectsUnderCompanyInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>
    >,
    TError = AxiosError<unknown>
  >(
    companyId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof companiesControllerGetProjectsUnderCompany>
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCompaniesControllerGetProjectsUnderCompanyQueryKey(companyId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>
    > = ({ signal }) =>
      companiesControllerGetProjectsUnderCompany(companyId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!companyId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CompaniesControllerGetProjectsUnderCompanyInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>
  >;
export type CompaniesControllerGetProjectsUnderCompanyInfiniteQueryError =
  AxiosError<unknown>;

export const useCompaniesControllerGetProjectsUnderCompanyInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getCompaniesControllerGetProjectsUnderCompanyInfiniteQueryOptions(
      companyId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCompaniesControllerGetProjectsUnderCompanyQueryOptions = <
  TData = Awaited<
    ReturnType<typeof companiesControllerGetProjectsUnderCompany>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetProjectsUnderCompanyQueryKey(companyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>
  > = ({ signal }) =>
    companiesControllerGetProjectsUnderCompany(companyId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!companyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetProjectsUnderCompanyQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>
>;
export type CompaniesControllerGetProjectsUnderCompanyQueryError =
  AxiosError<unknown>;

export const useCompaniesControllerGetProjectsUnderCompany = <
  TData = Awaited<
    ReturnType<typeof companiesControllerGetProjectsUnderCompany>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetProjectsUnderCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getCompaniesControllerGetProjectsUnderCompanyQueryOptions(
      companyId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesControllerCreateProject = (
  companyId: string,
  createProjectDto: CreateProjectDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompaniesControllerCreateProject201>> => {
  return axios.post(
    `/companies/${companyId}/projects`,
    createProjectDto,
    options
  );
};

export const getCompaniesControllerCreateProjectMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerCreateProject>>,
    TError,
    { companyId: string; data: CreateProjectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerCreateProject>>,
  TError,
  { companyId: string; data: CreateProjectDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerCreateProject>>,
    { companyId: string; data: CreateProjectDto }
  > = (props) => {
    const { companyId, data } = props ?? {};

    return companiesControllerCreateProject(companyId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerCreateProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerCreateProject>>
>;
export type CompaniesControllerCreateProjectMutationBody = CreateProjectDto;
export type CompaniesControllerCreateProjectMutationError = AxiosError<unknown>;

export const useCompaniesControllerCreateProject = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerCreateProject>>,
    TError,
    { companyId: string; data: CreateProjectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerCreateProject>>,
  TError,
  { companyId: string; data: CreateProjectDto },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerCreateProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesControllerGetRolesUnderCompany = (
  companyId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetRolesUnderCompanyResponseDto[]>> => {
  return axios.get(`/companies/${companyId}/roles`, options);
};

export const getCompaniesControllerGetRolesUnderCompanyQueryKey = (
  companyId: string
) => {
  return [`/companies/${companyId}/roles`] as const;
};

export const getCompaniesControllerGetRolesUnderCompanyInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetRolesUnderCompanyQueryKey(companyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>
  > = ({ signal }) =>
    companiesControllerGetRolesUnderCompany(companyId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!companyId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetRolesUnderCompanyInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>
  >;
export type CompaniesControllerGetRolesUnderCompanyInfiniteQueryError =
  AxiosError<unknown>;

export const useCompaniesControllerGetRolesUnderCompanyInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getCompaniesControllerGetRolesUnderCompanyInfiniteQueryOptions(
      companyId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCompaniesControllerGetRolesUnderCompanyQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetRolesUnderCompanyQueryKey(companyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>
  > = ({ signal }) =>
    companiesControllerGetRolesUnderCompany(companyId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!companyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetRolesUnderCompanyQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>
>;
export type CompaniesControllerGetRolesUnderCompanyQueryError =
  AxiosError<unknown>;

export const useCompaniesControllerGetRolesUnderCompany = <
  TData = Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
  TError = AxiosError<unknown>
>(
  companyId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetRolesUnderCompany>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesControllerGetRolesUnderCompanyQueryOptions(
    companyId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesControllerDeleteProject = (
  companyId: number,
  projectId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/companies/${companyId}/projects/${projectId}`, options);
};

export const getCompaniesControllerDeleteProjectMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeleteProject>>,
    TError,
    { companyId: number; projectId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerDeleteProject>>,
  TError,
  { companyId: number; projectId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerDeleteProject>>,
    { companyId: number; projectId: number }
  > = (props) => {
    const { companyId, projectId } = props ?? {};

    return companiesControllerDeleteProject(companyId, projectId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerDeleteProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerDeleteProject>>
>;

export type CompaniesControllerDeleteProjectMutationError = AxiosError<unknown>;

export const useCompaniesControllerDeleteProject = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeleteProject>>,
    TError,
    { companyId: number; projectId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerDeleteProject>>,
  TError,
  { companyId: number; projectId: number },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerDeleteProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesControllerGetOneProject = (
  companyId: string,
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AdminGetOneProjectResponseDto>> => {
  return axios.get(`/companies/${companyId}/projects/${projectId}`, options);
};

export const getCompaniesControllerGetOneProjectQueryKey = (
  companyId: string,
  projectId: string
) => {
  return [`/companies/${companyId}/projects/${projectId}`] as const;
};

export const getCompaniesControllerGetOneProjectInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetOneProject>>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetOneProjectQueryKey(companyId, projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetOneProject>>
  > = ({ signal }) =>
    companiesControllerGetOneProject(companyId, projectId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(companyId && projectId),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetOneProjectInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetOneProject>>
>;
export type CompaniesControllerGetOneProjectInfiniteQueryError =
  AxiosError<unknown>;

export const useCompaniesControllerGetOneProjectInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof companiesControllerGetOneProject>>
  >,
  TError = AxiosError<unknown>
>(
  companyId: string,
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesControllerGetOneProjectInfiniteQueryOptions(
    companyId,
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCompaniesControllerGetOneProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
  TError = AxiosError<unknown>
>(
  companyId: string,
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompaniesControllerGetOneProjectQueryKey(companyId, projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof companiesControllerGetOneProject>>
  > = ({ signal }) =>
    companiesControllerGetOneProject(companyId, projectId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(companyId && projectId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompaniesControllerGetOneProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerGetOneProject>>
>;
export type CompaniesControllerGetOneProjectQueryError = AxiosError<unknown>;

export const useCompaniesControllerGetOneProject = <
  TData = Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
  TError = AxiosError<unknown>
>(
  companyId: string,
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof companiesControllerGetOneProject>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesControllerGetOneProjectQueryOptions(
    companyId,
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const companiesControllerUpdateProject = (
  companyId: number,
  projectId: number,
  updateProjectDto: UpdateProjectDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/companies/${companyId}/projects/${projectId}`,
    updateProjectDto,
    options
  );
};

export const getCompaniesControllerUpdateProjectMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerUpdateProject>>,
    TError,
    { companyId: number; projectId: number; data: UpdateProjectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerUpdateProject>>,
  TError,
  { companyId: number; projectId: number; data: UpdateProjectDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerUpdateProject>>,
    { companyId: number; projectId: number; data: UpdateProjectDto }
  > = (props) => {
    const { companyId, projectId, data } = props ?? {};

    return companiesControllerUpdateProject(
      companyId,
      projectId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerUpdateProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerUpdateProject>>
>;
export type CompaniesControllerUpdateProjectMutationBody = UpdateProjectDto;
export type CompaniesControllerUpdateProjectMutationError = AxiosError<unknown>;

export const useCompaniesControllerUpdateProject = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerUpdateProject>>,
    TError,
    { companyId: number; projectId: number; data: UpdateProjectDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerUpdateProject>>,
  TError,
  { companyId: number; projectId: number; data: UpdateProjectDto },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerUpdateProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesControllerDeleteProjectPhoto = (
  companyId: number,
  projectId: number,
  attachmentId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `/companies/${companyId}/projects/${projectId}/attachment/${attachmentId}`,
    options
  );
};

export const getCompaniesControllerDeleteProjectPhotoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeleteProjectPhoto>>,
    TError,
    { companyId: number; projectId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerDeleteProjectPhoto>>,
  TError,
  { companyId: number; projectId: number; attachmentId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerDeleteProjectPhoto>>,
    { companyId: number; projectId: number; attachmentId: number }
  > = (props) => {
    const { companyId, projectId, attachmentId } = props ?? {};

    return companiesControllerDeleteProjectPhoto(
      companyId,
      projectId,
      attachmentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerDeleteProjectPhotoMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerDeleteProjectPhoto>>
>;

export type CompaniesControllerDeleteProjectPhotoMutationError =
  AxiosError<unknown>;

export const useCompaniesControllerDeleteProjectPhoto = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeleteProjectPhoto>>,
    TError,
    { companyId: number; projectId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerDeleteProjectPhoto>>,
  TError,
  { companyId: number; projectId: number; attachmentId: number },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerDeleteProjectPhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const companiesControllerDeletePhoto = (
  companyId: number,
  attachmentId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `/companies/${companyId}/attachment/${attachmentId}`,
    options
  );
};

export const getCompaniesControllerDeletePhotoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeletePhoto>>,
    TError,
    { companyId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof companiesControllerDeletePhoto>>,
  TError,
  { companyId: number; attachmentId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof companiesControllerDeletePhoto>>,
    { companyId: number; attachmentId: number }
  > = (props) => {
    const { companyId, attachmentId } = props ?? {};

    return companiesControllerDeletePhoto(
      companyId,
      attachmentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CompaniesControllerDeletePhotoMutationResult = NonNullable<
  Awaited<ReturnType<typeof companiesControllerDeletePhoto>>
>;

export type CompaniesControllerDeletePhotoMutationError = AxiosError<unknown>;

export const useCompaniesControllerDeletePhoto = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof companiesControllerDeletePhoto>>,
    TError,
    { companyId: number; attachmentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof companiesControllerDeletePhoto>>,
  TError,
  { companyId: number; attachmentId: number },
  TContext
> => {
  const mutationOptions =
    getCompaniesControllerDeletePhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
