import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Status } from "../../../../api/model";
import AppButton from "../../../../components/AppButton";
import AppTextField from "../../../../components/AppTextField";
import { USER_FORM_DRAWER } from "../../../../components/drawers/AppDrawer";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/tabs";
import useAppTabsNavigation from "../../../../hooks/useAppTabsNavigation";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import UserAccountTabItem from "./components/UserAccountTabItem";
import UserAccountTabContent from "./components/UserAccounTabContent";

export default function AccountTab() {
  const [, setSearchParams] = useSearchParams();
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "userAccountIdx",
    defaultValue: "0",
  });

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-8 items-start">
          <AppTitle title="User Accounts" />
          <AppButton
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", USER_FORM_DRAWER);
                p.set("drawerState", "CREATE");
                return p;
              });
            }}
            label="+ Add User"
            className="h-10"
          />
        </div>
        <AppTextField
          name="search"
          className="w-[400px]"
          placeholder="Search.."
        />
        <Tabs
          value={currentTabIdx}
          onValueChange={onTabChange}
          className="mb-4"
        >
          <TabsList className="mb-2">
            {[Status.ACTIVE, Status.DELETED].map((userStatus, idx) => (
              <TabsTrigger key={userStatus} value={idx.toString()}>
                <UserAccountTabItem status={userStatus} />
              </TabsTrigger>
            ))}
          </TabsList>
          {[Status.ACTIVE, Status.DELETED].map((uStat, idx) => (
            <TabsContent key={uStat} value={idx.toString()}>
              <UserAccountTabContent status={uStat} />
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </FormProvider>
  );
}
