import { useHasInventoryAdjustmentPolicy } from '../hooks/usePolicies/usePolicies';
import React from 'react';

// HOC that determines whether the user has access to the inventory adjustment policy
// and therfore render the children if the user has access

interface InventoryPolicyGuardProps {
  children: React.ReactNode;
}

export default function InventoryPolicyGuard({
  children,
}: InventoryPolicyGuardProps) {
  const hasAccess = useHasInventoryAdjustmentPolicy();

  if (!hasAccess) return <></>;

  return <>{children}</>;
}
