import { EventInput } from "@fullcalendar/core";
import { useSearchParams } from "react-router-dom";
import {
  CerevModule,
  WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType,
} from "../../api/model";
import {
  useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTime,
  useWorkOrderV2ControllerUpdatePlannedTime,
} from "../../api/work-order-defect-v2/work-order-defect-v2";
import AppButton from "../../components/AppButton";
import AppCalendar from "../../components/calendar/AppCalendar";
import {
  DRAWER_VIEW_STATE,
  WORK_ORDER_DETAIL_DRAWER,
  WORK_ORDER_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import { useAppSelector } from "../../redux/store";
import { useWorkOrderScheduleFilterStore } from "../../stores/workOrderSchedule/workOrderScheduleFilterStore";
import AppTitle from "../HomeScreen/components/AppTitle";
import UnplannedWorkOrdersSection from "./components/UnplannedWorkOrdersSection";
import WOSchedulerFilter from "./components/WOSchedulerFilter";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import useDebounce from "../../hooks/useDebounce";
import { useQueryClient } from "@tanstack/react-query";
import { addHours } from "date-fns";
import {
  useCalendarDateEnd,
  useCalendarDateStart,
  useCalendarRefActions,
} from "../../stores/calendarRefStore/calendarRefStore";
import { useEffect } from "react";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
// import './calendar.css';

export default function WOSchedulerScreen() {
  const qc = useQueryClient();
  const [, setSearchParams] = useSearchParams();
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  // const [dateStart, setDateStart] = useState<Date>(startOfMonth(new Date()));
  // const [dateEnd, setDateEnd] = useState<Date>(endOfMonth(new Date()));
  const dateStart = useCalendarDateStart();
  const dateEnd = useCalendarDateEnd();
  const assetTypeFilterIds = useWorkOrderScheduleFilterStore(
    (state) => state.assetTypes
  );
  const filterLocation = useWorkOrderScheduleFilterStore(
    (state) => state.filterLocation
  );
  const assetFilter = useWorkOrderScheduleFilterStore(
    (state) => state.filterAsset
  );
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const search = useWatch({ control: methods.control, name: "search" });

  const { cleanUp } = useCalendarRefActions();

  useEffect(() => {
    return cleanUp;
  }, []);

  const queryArg = useDebounce({
    value: {
      projectId: activeProj?.id ?? 0,
      dateStart: dateStart.toISOString(),
      dateEnd: dateEnd.toISOString(),
      workOrderPlanType:
        WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType.PLANNED,
      filterAssetTypeIds: assetTypeFilterIds.map((at) => at.id!),
      filterLocationId: filterLocation?.id,
      filterAssetId: assetFilter?.id,
      search,
    },
    delay: 500,
  });
  const {
    data: workOrders,
    isLoading: workOrderIsLoading,
    isFetching: workOrderIsFetching,
  } = useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTime(queryArg, {
    query: {
      select: (res) =>
        res.data.data.map(
          (wo) =>
            ({
              id: wo.id,
              title: wo.subject,
              start: wo.plannedOn,
              end: wo.plannedEndOn,
              allDay: !wo.plannedEndOn,
              extendedProps: {
                ...wo,
              },
            } as EventInput)
        ),
      enabled: !!activeProj,
    },
  });

  const { mutate: updatePlannedTime } =
    useWorkOrderV2ControllerUpdatePlannedTime({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (qry) =>
              (qry.queryKey[0] as string).includes("work-order"),
          });
        },
      },
    });

  return (
    <ModuleAccessGuard module={CerevModule.WORK_ORDER}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <AppTitle title="Work Order Scheduler" />
          <AppButton
            label="+ Create"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", WORK_ORDER_FORM_DRAWER);
                p.set("drawerState", "CREATE");
                return p;
              });
            }}
          />
        </div>
        <FormProvider {...methods}>
          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-3">
              <AppCalendar
                isLoading={workOrderIsFetching}
                events={workOrders}
                eventClick={(arg) => {
                  setSearchParams((p) => {
                    p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
                    p.set("drawerState", DRAWER_VIEW_STATE);
                    p.set("workOrderId", arg.event.id);
                    p.set("workOrderTabIdx", "0");
                    return p;
                  });
                }}
                filterNode={<WOSchedulerFilter />}
                eventContent={(arg) => {
                  return (
                    <>
                      <div
                        className="truncate flex gap-2 items-center text-white w-full h-full border-solid border-2"
                        style={{
                          backgroundColor: `#${arg?.event?.extendedProps?.defectStatus?.colorHex}`,
                          borderColor: `#${arg?.event?.extendedProps?.defectStatus?.colorHex}`,
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      >
                        {/* <AppStatusBadge
                        defectStatus={
                          arg?.event?.extendedProps?.defectStatus as DefectStatus
                        }
                      /> */}
                        <p className="truncate">{arg.event.title}</p>
                      </div>
                    </>
                  );
                }}
                eventResize={(arg) => {
                  if (arg.event.start && arg.event.end && arg.event.id) {
                    updatePlannedTime({
                      workOrderId: +arg.event.id,
                      data: {
                        plannedTime: arg.event.start?.toISOString(),
                        plannedTimeEnd: arg.event.end?.toISOString(),
                      },
                    });
                  }
                }}
                eventDrop={(arg) => {
                  const isAllDay = arg.event.allDay;
                  if (arg.event.start && arg.event.id)
                    updatePlannedTime({
                      workOrderId: +arg.event.id,
                      data: {
                        plannedTime: arg.event.start?.toISOString(),
                        plannedTimeEnd: isAllDay
                          ? null
                          : arg.event.end
                          ? arg.event.end.toISOString()
                          : addHours(arg.event.start, 1).toISOString(),
                      },
                    });
                }}
                eventReceive={(arg) => {
                  if (arg.event.start && arg.event.id)
                    updatePlannedTime({
                      workOrderId: +arg.event.id,
                      data: {
                        plannedTime: arg.event.start?.toISOString(),
                      },
                    });
                }}
              />
            </div>
            <div className="col-span-1 flex flex-col gap-4">
              <UnplannedWorkOrdersSection />
            </div>
          </div>
        </FormProvider>
      </div>
    </ModuleAccessGuard>
  );
}
