import {cerevApi} from "../OpenApi/cerevApi";

export const PmApi = cerevApi.enhanceEndpoints({
  endpoints: {
    pmChecklistControllerUpdatePmChecklist(endpoint) {
      endpoint.invalidatesTags = [
        "Assets",
        "Preventive Maintenance Checklists",
      ];
    },
  },
});

export const {usePmChecklistControllerUpdatePmChecklistMutation} = PmApi;
