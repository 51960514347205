import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import AppLogoText from "../../../components/AppLogoText";
import AppTextField from "../../../components/AppTextField";
import PublicPageWrapper from "../../../components/PublicPageWrapper";

export default function PublicWorkRequestTrackingScreen() {
  const methods = useForm<{ trackingCode: string }>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<{ trackingCode: string }> = (data) => {
    navigate(data.trackingCode);
  };

  return (
    <PublicPageWrapper className="justify-between">
      <AppLogoText subtitle="Track Work Request" />
      <FormProvider {...methods}>
        <div className="flex flex-col items-center gap-4">
          <p className="font-sans">Please enter your tracking number</p>
          <AppTextField name="trackingCode" />
        </div>
      </FormProvider>

      <AppButton label="Confirm" onClick={methods.handleSubmit(onSubmit)} />
    </PublicPageWrapper>
  );
}
