import { Download, Trash2, Unlock, Lock } from "lucide-react";
import AppButton from "../../../../../AppButton";
import { Attachment, AttachmentType } from "../../../../../../api/model";
import { useAttachmentControllerUpdateAttachmentAccess } from "../../../../../../api/attachments/attachments";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "../../../../../../utils/util";
import { useAppSelector } from "../../../../../../redux/store";
import PrivateAttachmentGuard from "../../../../../../guards/PrivateAttachmentGuard";
import { toast } from "sonner";

interface LockableDocViewProps {
  att: Attachment;
  className?: string;
  onDelete?: (att: Attachment) => Promise<void>;
  onDeleteLoading?: boolean;
}

export default function LockableDocView({
  att,
  className,
  onDelete,
  onDeleteLoading,
}: LockableDocViewProps) {
  const qc = useQueryClient();

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { mutate, isPending } = useAttachmentControllerUpdateAttachmentAccess({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("asset"),
        });
        toast.success("Document access updated successfully");
      },
    },
  });

  return (
    <div
      className={`${className} flex items-center px-4 py-2 bg-neutral-300 rounded-md justify-between`}
    >
      <div className="font-sans">{att.name}</div>
      <div className="flex gap-4">
        <PrivateAttachmentGuard>
          <AppButton
            variant="ghost"
            className={cn({
              "text-slate-300 hover:text-primary-900 duration-200 transition-all":
                att.attachmentType === "DEFAULT",
            })}
            icon={att.attachmentType === "DEFAULT" ? <Unlock /> : <Lock />}
            isLoading={isPending}
            onClick={() => {
              if (!att.id || !activeProj || !activeProj.id) return;
              mutate({
                attachmentId: att.id?.toString() ?? "",
                data: {
                  attachmentType:
                    att.attachmentType === AttachmentType.DEFAULT
                      ? AttachmentType.PRIVATE
                      : AttachmentType.DEFAULT,
                  projectId: activeProj.id,
                },
              });
            }}
          />
        </PrivateAttachmentGuard>
        <AppButton
          variant="ghost"
          icon={<Download />}
          onClick={() => {
            window.open(att.url, "_blank");
          }}
        />
        {onDelete && (
          <AppButton
            variant="ghost"
            isLoading={onDeleteLoading}
            icon={<Trash2 className="text-red-500" />}
            onClick={() => onDelete(att)}
          />
        )}
      </div>
    </div>
  );
}
