import { useSearchParams } from "react-router-dom";
import { useModuleAccessControllerGetAllUserModuleAccess } from "../../../../api/module-access/module-access";
import { useSelectedSettingProjectId } from "../../../../stores/settingStore/settingStore";
import SettingSelectProj from "../components/SettingSelectProj";
import { useUserControllerGetOneUser } from "../../../../api/users/users";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import ModuleAccessItem from "../components/ModuleAccessItem";
import { CerevModule } from "../../../../api/model";

const desc = {
  [CerevModule.DASHBOARD]:
    "A dashboard to visualize data and insights of your operations",
  [CerevModule.WORK_ORDER]:
    "Work order to keep track of reactive maintenance and repair works",
  [CerevModule.PM]:
    "PM to have planned maintenance, and ensuring equipments are functional",
  [CerevModule.EQUIPMENT]:
    "To keep track a list of equipment, and trace repair history",
  [CerevModule.VENDOR]: "Manage a list of vendors in a centralized platform",
  [CerevModule.INVENTORY]:
    "Manage inventories and track usages across work orders",
  [CerevModule.LEGAL]:
    "Keep track of legal documents or contracts / warranties",
  [CerevModule.WORK_PERMIT]: "Digitalize work permit forms into Cerev CMMS",
  [CerevModule.WORK_REQUEST]:
    "A Platform to enable your tenants who may not have the app access to lodge a complain or repair request.",
  [CerevModule.TNB_BILLS]:
    "Keep track of your TNB bills and monitor your electricity consumption",
  [CerevModule.METERS]:
    "Keep track of your meters and monitor your electricity consumption",
  [CerevModule.ESCALATION]:
    "Create escalations for your work orders, preventive maintenance or work request to prevent things from not being done on time",
};

export default function ModuleAccessTab() {
  const [searchParams] = useSearchParams();
  const firebaseId = searchParams.get("firebaseId");
  const selectedProjId = useSelectedSettingProjectId();
  const { data: user } = useUserControllerGetOneUser(firebaseId ?? "", {
    query: {
      enabled: !!firebaseId,
      select: (res) => res.data,
    },
  });

  const { data: moduleAccesses, isLoading } =
    useModuleAccessControllerGetAllUserModuleAccess(
      user?.id?.toString() ?? "",
      {
        projectId: Number(selectedProjId),
      },
      {
        query: {
          enabled: !!user?.id,
          select: (res) => res.data,
        },
      }
    );

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-6">
        <p className="text-sm text-slate-500">Current Project: </p>
        <SettingSelectProj />
      </div>
      {isLoading ? (
        <DrawerFormSkeleton />
      ) : (
        <>
          {moduleAccesses?.map((ma) => (
            <ModuleAccessItem
              mod={ma}
              key={ma.module}
              description={desc[ma.module]}
            />
          ))}
        </>
      )}
    </div>
  );
}
