/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateLocationDto,
  DataImportsControllerImportLocation201,
  GetLocationResponseDto,
  GetOneLocationResponseDto,
  ImportAssetRequest,
  ImportLocationRequestDto,
  Location,
  LocationControllerDownloadFullLocationsParams,
  LocationControllerGetFullLocationListParams,
  LocationControllerGetFullLocationParams,
  LocationControllerGetLocationParams,
  LocationControllerGetTopLocationsParams,
  LocationControllerGetWorkRequestLocationsParams,
  UpdateLocationDto,
} from ".././model";

export const locationControllerPublicShowLocation = (
  workRequestProjectCode: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Location[]>> => {
  return axios.get(
    `/location/work-request-location-v2/${workRequestProjectCode}`,
    options
  );
};

export const getLocationControllerPublicShowLocationQueryKey = (
  workRequestProjectCode: string
) => {
  return [
    `/location/work-request-location-v2/${workRequestProjectCode}`,
  ] as const;
};

export const getLocationControllerPublicShowLocationInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerPublicShowLocation>>
  >,
  TError = AxiosError<unknown>
>(
  workRequestProjectCode: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerPublicShowLocationQueryKey(workRequestProjectCode);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerPublicShowLocation>>
  > = ({ signal }) =>
    locationControllerPublicShowLocation(workRequestProjectCode, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workRequestProjectCode,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerPublicShowLocationInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof locationControllerPublicShowLocation>>>;
export type LocationControllerPublicShowLocationInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerPublicShowLocationInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerPublicShowLocation>>
  >,
  TError = AxiosError<unknown>
>(
  workRequestProjectCode: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLocationControllerPublicShowLocationInfiniteQueryOptions(
      workRequestProjectCode,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerPublicShowLocationQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
  TError = AxiosError<unknown>
>(
  workRequestProjectCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerPublicShowLocationQueryKey(workRequestProjectCode);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerPublicShowLocation>>
  > = ({ signal }) =>
    locationControllerPublicShowLocation(workRequestProjectCode, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!workRequestProjectCode,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerPublicShowLocationQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerPublicShowLocation>>
>;
export type LocationControllerPublicShowLocationQueryError =
  AxiosError<unknown>;

export const useLocationControllerPublicShowLocation = <
  TData = Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
  TError = AxiosError<unknown>
>(
  workRequestProjectCode: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerPublicShowLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerPublicShowLocationQueryOptions(
    workRequestProjectCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerGetTopLocations = (
  params: LocationControllerGetTopLocationsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Location[]>> => {
  return axios.get(`/location/topLocations`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLocationControllerGetTopLocationsQueryKey = (
  params: LocationControllerGetTopLocationsParams
) => {
  return [`/location/topLocations`, ...(params ? [params] : [])] as const;
};

export const getLocationControllerGetTopLocationsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
    LocationControllerGetTopLocationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetTopLocationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
        QueryKey,
        LocationControllerGetTopLocationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetTopLocationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
    QueryKey,
    LocationControllerGetTopLocationsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    locationControllerGetTopLocations(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
    TError,
    TData,
    Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
    QueryKey,
    LocationControllerGetTopLocationsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type LocationControllerGetTopLocationsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetTopLocations>>
>;
export type LocationControllerGetTopLocationsInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetTopLocationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
    LocationControllerGetTopLocationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetTopLocationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
        QueryKey,
        LocationControllerGetTopLocationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetTopLocationsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerGetTopLocationsQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetTopLocationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetTopLocationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetTopLocations>>
  > = ({ signal }) =>
    locationControllerGetTopLocations(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerGetTopLocationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetTopLocations>>
>;
export type LocationControllerGetTopLocationsQueryError = AxiosError<unknown>;

export const useLocationControllerGetTopLocations = <
  TData = Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetTopLocationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetTopLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetTopLocationsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerGetFullLocation = (
  params: LocationControllerGetFullLocationParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Location[]>> => {
  return axios.get(`/location/full`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLocationControllerGetFullLocationQueryKey = (
  params: LocationControllerGetFullLocationParams
) => {
  return [`/location/full`, ...(params ? [params] : [])] as const;
};

export const getLocationControllerGetFullLocationInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
    LocationControllerGetFullLocationParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
        QueryKey,
        LocationControllerGetFullLocationParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetFullLocationQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
    QueryKey,
    LocationControllerGetFullLocationParams["cursorId"]
  > = ({ signal, pageParam }) =>
    locationControllerGetFullLocation(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
    TError,
    TData,
    Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
    QueryKey,
    LocationControllerGetFullLocationParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type LocationControllerGetFullLocationInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetFullLocation>>
>;
export type LocationControllerGetFullLocationInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetFullLocationInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
    LocationControllerGetFullLocationParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
        QueryKey,
        LocationControllerGetFullLocationParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetFullLocationInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerGetFullLocationQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetFullLocationQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetFullLocation>>
  > = ({ signal }) =>
    locationControllerGetFullLocation(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerGetFullLocationQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetFullLocation>>
>;
export type LocationControllerGetFullLocationQueryError = AxiosError<unknown>;

export const useLocationControllerGetFullLocation = <
  TData = Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetFullLocationQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerGetWorkRequestLocations = (
  params: LocationControllerGetWorkRequestLocationsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Location[]>> => {
  return axios.get(`/location/work-request-full-location`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLocationControllerGetWorkRequestLocationsQueryKey = (
  params: LocationControllerGetWorkRequestLocationsParams
) => {
  return [
    `/location/work-request-full-location`,
    ...(params ? [params] : []),
  ] as const;
};

export const getLocationControllerGetWorkRequestLocationsInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
      LocationControllerGetWorkRequestLocationsParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: LocationControllerGetWorkRequestLocationsParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
          TError,
          TData,
          Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
          QueryKey,
          LocationControllerGetWorkRequestLocationsParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getLocationControllerGetWorkRequestLocationsQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
      QueryKey,
      LocationControllerGetWorkRequestLocationsParams["cursorId"]
    > = ({ signal, pageParam }) =>
      locationControllerGetWorkRequestLocations(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
      TError,
      TData,
      Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
      QueryKey,
      LocationControllerGetWorkRequestLocationsParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type LocationControllerGetWorkRequestLocationsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>
  >;
export type LocationControllerGetWorkRequestLocationsInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetWorkRequestLocationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
    LocationControllerGetWorkRequestLocationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetWorkRequestLocationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
        QueryKey,
        LocationControllerGetWorkRequestLocationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLocationControllerGetWorkRequestLocationsInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerGetWorkRequestLocationsQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetWorkRequestLocationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetWorkRequestLocationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>
  > = ({ signal }) =>
    locationControllerGetWorkRequestLocations(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerGetWorkRequestLocationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>
>;
export type LocationControllerGetWorkRequestLocationsQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetWorkRequestLocations = <
  TData = Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetWorkRequestLocationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetWorkRequestLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetWorkRequestLocationsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerGetFullLocationList = (
  params: LocationControllerGetFullLocationListParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Location[]>> => {
  return axios.get(`/location/full-location-list`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLocationControllerGetFullLocationListQueryKey = (
  params: LocationControllerGetFullLocationListParams
) => {
  return [`/location/full-location-list`, ...(params ? [params] : [])] as const;
};

export const getLocationControllerGetFullLocationListInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
    LocationControllerGetFullLocationListParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationListParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
        QueryKey,
        LocationControllerGetFullLocationListParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetFullLocationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
    QueryKey,
    LocationControllerGetFullLocationListParams["cursorId"]
  > = ({ signal, pageParam }) =>
    locationControllerGetFullLocationList(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
    TError,
    TData,
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
    QueryKey,
    LocationControllerGetFullLocationListParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type LocationControllerGetFullLocationListInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>
  >;
export type LocationControllerGetFullLocationListInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetFullLocationListInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
    LocationControllerGetFullLocationListParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationListParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
        QueryKey,
        LocationControllerGetFullLocationListParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLocationControllerGetFullLocationListInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerGetFullLocationListQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetFullLocationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>
  > = ({ signal }) =>
    locationControllerGetFullLocationList(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerGetFullLocationListQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetFullLocationList>>
>;
export type LocationControllerGetFullLocationListQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetFullLocationList = <
  TData = Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetFullLocationListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetFullLocationList>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetFullLocationListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerGetLocation = (
  params: LocationControllerGetLocationParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetLocationResponseDto[]>> => {
  return axios.get(`/location`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLocationControllerGetLocationQueryKey = (
  params: LocationControllerGetLocationParams
) => {
  return [`/location`, ...(params ? [params] : [])] as const;
};

export const getLocationControllerGetLocationInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetLocation>>,
    LocationControllerGetLocationParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetLocationParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetLocation>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetLocation>>,
        QueryKey,
        LocationControllerGetLocationParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLocationControllerGetLocationQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetLocation>>,
    QueryKey,
    LocationControllerGetLocationParams["cursorId"]
  > = ({ signal, pageParam }) =>
    locationControllerGetLocation(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetLocation>>,
    TError,
    TData,
    Awaited<ReturnType<typeof locationControllerGetLocation>>,
    QueryKey,
    LocationControllerGetLocationParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type LocationControllerGetLocationInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetLocation>>
>;
export type LocationControllerGetLocationInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetLocationInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetLocation>>,
    LocationControllerGetLocationParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetLocationParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetLocation>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerGetLocation>>,
        QueryKey,
        LocationControllerGetLocationParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetLocationInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerGetLocationQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerGetLocation>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetLocationParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLocationControllerGetLocationQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetLocation>>
  > = ({ signal }) =>
    locationControllerGetLocation(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetLocation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerGetLocationQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetLocation>>
>;
export type LocationControllerGetLocationQueryError = AxiosError<unknown>;

export const useLocationControllerGetLocation = <
  TData = Awaited<ReturnType<typeof locationControllerGetLocation>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerGetLocationParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetLocationQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerCreateLocation = (
  createLocationDto: CreateLocationDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/location`, createLocationDto, options);
};

export const getLocationControllerCreateLocationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerCreateLocation>>,
    TError,
    { data: CreateLocationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationControllerCreateLocation>>,
  TError,
  { data: CreateLocationDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationControllerCreateLocation>>,
    { data: CreateLocationDto }
  > = (props) => {
    const { data } = props ?? {};

    return locationControllerCreateLocation(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationControllerCreateLocationMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerCreateLocation>>
>;
export type LocationControllerCreateLocationMutationBody = CreateLocationDto;
export type LocationControllerCreateLocationMutationError = AxiosError<unknown>;

export const useLocationControllerCreateLocation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerCreateLocation>>,
    TError,
    { data: CreateLocationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof locationControllerCreateLocation>>,
  TError,
  { data: CreateLocationDto },
  TContext
> => {
  const mutationOptions =
    getLocationControllerCreateLocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const locationControllerDownloadFullLocations = (
  params: LocationControllerDownloadFullLocationsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Location[]>> => {
  return axios.get(`/location/download`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLocationControllerDownloadFullLocationsQueryKey = (
  params: LocationControllerDownloadFullLocationsParams
) => {
  return [`/location/download`, ...(params ? [params] : [])] as const;
};

export const getLocationControllerDownloadFullLocationsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
    LocationControllerDownloadFullLocationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerDownloadFullLocationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
        QueryKey,
        LocationControllerDownloadFullLocationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerDownloadFullLocationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
    QueryKey,
    LocationControllerDownloadFullLocationsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    locationControllerDownloadFullLocations(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
    TError,
    TData,
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
    QueryKey,
    LocationControllerDownloadFullLocationsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type LocationControllerDownloadFullLocationsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>
  >;
export type LocationControllerDownloadFullLocationsInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerDownloadFullLocationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
    LocationControllerDownloadFullLocationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LocationControllerDownloadFullLocationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
        QueryKey,
        LocationControllerDownloadFullLocationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLocationControllerDownloadFullLocationsInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerDownloadFullLocationsQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerDownloadFullLocationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerDownloadFullLocationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>
  > = ({ signal }) =>
    locationControllerDownloadFullLocations(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerDownloadFullLocationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>
>;
export type LocationControllerDownloadFullLocationsQueryError =
  AxiosError<unknown>;

export const useLocationControllerDownloadFullLocations = <
  TData = Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
  TError = AxiosError<unknown>
>(
  params: LocationControllerDownloadFullLocationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerDownloadFullLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerDownloadFullLocationsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerGetOneLocation = (
  locationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOneLocationResponseDto>> => {
  return axios.get(`/location/${locationId}`, options);
};

export const getLocationControllerGetOneLocationQueryKey = (
  locationId: string
) => {
  return [`/location/${locationId}`] as const;
};

export const getLocationControllerGetOneLocationInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetOneLocation>>
  >,
  TError = AxiosError<unknown>
>(
  locationId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetOneLocationQueryKey(locationId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetOneLocation>>
  > = ({ signal }) =>
    locationControllerGetOneLocation(locationId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!locationId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerGetOneLocationInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetOneLocation>>
>;
export type LocationControllerGetOneLocationInfiniteQueryError =
  AxiosError<unknown>;

export const useLocationControllerGetOneLocationInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof locationControllerGetOneLocation>>
  >,
  TError = AxiosError<unknown>
>(
  locationId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetOneLocationInfiniteQueryOptions(
    locationId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLocationControllerGetOneLocationQueryOptions = <
  TData = Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
  TError = AxiosError<unknown>
>(
  locationId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationControllerGetOneLocationQueryKey(locationId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationControllerGetOneLocation>>
  > = ({ signal }) =>
    locationControllerGetOneLocation(locationId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!locationId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationControllerGetOneLocationQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerGetOneLocation>>
>;
export type LocationControllerGetOneLocationQueryError = AxiosError<unknown>;

export const useLocationControllerGetOneLocation = <
  TData = Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
  TError = AxiosError<unknown>
>(
  locationId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof locationControllerGetOneLocation>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationControllerGetOneLocationQueryOptions(
    locationId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const locationControllerDeleteLocation = (
  locationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/location/${locationId}`, options);
};

export const getLocationControllerDeleteLocationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerDeleteLocation>>,
    TError,
    { locationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationControllerDeleteLocation>>,
  TError,
  { locationId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationControllerDeleteLocation>>,
    { locationId: string }
  > = (props) => {
    const { locationId } = props ?? {};

    return locationControllerDeleteLocation(locationId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationControllerDeleteLocationMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerDeleteLocation>>
>;

export type LocationControllerDeleteLocationMutationError = AxiosError<unknown>;

export const useLocationControllerDeleteLocation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerDeleteLocation>>,
    TError,
    { locationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof locationControllerDeleteLocation>>,
  TError,
  { locationId: string },
  TContext
> => {
  const mutationOptions =
    getLocationControllerDeleteLocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const locationControllerUpdateLocation = (
  locationId: string,
  updateLocationDto: UpdateLocationDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/location/${locationId}`, updateLocationDto, options);
};

export const getLocationControllerUpdateLocationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerUpdateLocation>>,
    TError,
    { locationId: string; data: UpdateLocationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationControllerUpdateLocation>>,
  TError,
  { locationId: string; data: UpdateLocationDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationControllerUpdateLocation>>,
    { locationId: string; data: UpdateLocationDto }
  > = (props) => {
    const { locationId, data } = props ?? {};

    return locationControllerUpdateLocation(locationId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationControllerUpdateLocationMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerUpdateLocation>>
>;
export type LocationControllerUpdateLocationMutationBody = UpdateLocationDto;
export type LocationControllerUpdateLocationMutationError = AxiosError<unknown>;

export const useLocationControllerUpdateLocation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerUpdateLocation>>,
    TError,
    { locationId: string; data: UpdateLocationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof locationControllerUpdateLocation>>,
  TError,
  { locationId: string; data: UpdateLocationDto },
  TContext
> => {
  const mutationOptions =
    getLocationControllerUpdateLocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const locationControllerDeletePhoto = (
  locationId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/location/${locationId}/photo/${attachmentId}`, options);
};

export const getLocationControllerDeletePhotoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerDeletePhoto>>,
    TError,
    { locationId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationControllerDeletePhoto>>,
  TError,
  { locationId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationControllerDeletePhoto>>,
    { locationId: string; attachmentId: string }
  > = (props) => {
    const { locationId, attachmentId } = props ?? {};

    return locationControllerDeletePhoto(
      locationId,
      attachmentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationControllerDeletePhotoMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerDeletePhoto>>
>;

export type LocationControllerDeletePhotoMutationError = AxiosError<unknown>;

export const useLocationControllerDeletePhoto = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerDeletePhoto>>,
    TError,
    { locationId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof locationControllerDeletePhoto>>,
  TError,
  { locationId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions =
    getLocationControllerDeletePhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const locationControllerDeleteFile = (
  locationId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.delete(
    `/location/${locationId}/additionalDocuments/${attachmentId}`,
    options
  );
};

export const getLocationControllerDeleteFileMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerDeleteFile>>,
    TError,
    { locationId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationControllerDeleteFile>>,
  TError,
  { locationId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationControllerDeleteFile>>,
    { locationId: string; attachmentId: string }
  > = (props) => {
    const { locationId, attachmentId } = props ?? {};

    return locationControllerDeleteFile(locationId, attachmentId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationControllerDeleteFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationControllerDeleteFile>>
>;

export type LocationControllerDeleteFileMutationError = AxiosError<unknown>;

export const useLocationControllerDeleteFile = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationControllerDeleteFile>>,
    TError,
    { locationId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof locationControllerDeleteFile>>,
  TError,
  { locationId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions =
    getLocationControllerDeleteFileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const dataImportsControllerImportLocation = (
  importLocationRequestDto: ImportLocationRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DataImportsControllerImportLocation201>> => {
  return axios.post(
    `/data-imports/location`,
    importLocationRequestDto,
    options
  );
};

export const getDataImportsControllerImportLocationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
    TError,
    { data: ImportLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
  TError,
  { data: ImportLocationRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
    { data: ImportLocationRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return dataImportsControllerImportLocation(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DataImportsControllerImportLocationMutationResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerImportLocation>>
>;
export type DataImportsControllerImportLocationMutationBody =
  ImportLocationRequestDto;
export type DataImportsControllerImportLocationMutationError =
  AxiosError<unknown>;

export const useDataImportsControllerImportLocation = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
    TError,
    { data: ImportLocationRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof dataImportsControllerImportLocation>>,
  TError,
  { data: ImportLocationRequestDto },
  TContext
> => {
  const mutationOptions =
    getDataImportsControllerImportLocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const dataImportsControllerImportAssets = (
  importAssetRequest: ImportAssetRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/data-imports/assets`, importAssetRequest, options);
};

export const getDataImportsControllerImportAssetsMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
    TError,
    { data: ImportAssetRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
  TError,
  { data: ImportAssetRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
    { data: ImportAssetRequest }
  > = (props) => {
    const { data } = props ?? {};

    return dataImportsControllerImportAssets(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DataImportsControllerImportAssetsMutationResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerImportAssets>>
>;
export type DataImportsControllerImportAssetsMutationBody = ImportAssetRequest;
export type DataImportsControllerImportAssetsMutationError =
  AxiosError<unknown>;

export const useDataImportsControllerImportAssets = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
    TError,
    { data: ImportAssetRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof dataImportsControllerImportAssets>>,
  TError,
  { data: ImportAssetRequest },
  TContext
> => {
  const mutationOptions =
    getDataImportsControllerImportAssetsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const dataImportsControllerGetLocations = (
  projectId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/data-imports/location/${projectId}`, options);
};

export const getDataImportsControllerGetLocationsQueryKey = (
  projectId: string
) => {
  return [`/data-imports/location/${projectId}`] as const;
};

export const getDataImportsControllerGetLocationsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDataImportsControllerGetLocationsQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  > = ({ signal }) =>
    dataImportsControllerGetLocations(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DataImportsControllerGetLocationsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
>;
export type DataImportsControllerGetLocationsInfiniteQueryError =
  AxiosError<unknown>;

export const useDataImportsControllerGetLocationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  >,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDataImportsControllerGetLocationsInfiniteQueryOptions(
    projectId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDataImportsControllerGetLocationsQueryOptions = <
  TData = Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDataImportsControllerGetLocationsQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
  > = ({ signal }) =>
    dataImportsControllerGetLocations(projectId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DataImportsControllerGetLocationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataImportsControllerGetLocations>>
>;
export type DataImportsControllerGetLocationsQueryError = AxiosError<unknown>;

export const useDataImportsControllerGetLocations = <
  TData = Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
  TError = AxiosError<unknown>
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dataImportsControllerGetLocations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDataImportsControllerGetLocationsQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
