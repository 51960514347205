import { Loader2 } from "lucide-react";
import { useWorkOrderV2ControllerGetWorkOrderSummaryReportPdf } from "../../../../api/work-order-defect-v2/work-order-defect-v2";
import { useRenderWorkOrderSummaryPdf } from "../../../../pdf/render-optimization/useRenderWorkOrderSummaryPdf";
import { useAppSelector } from "../../../../redux/store";
import { usePdfReportDateRange } from "../../../../stores/pdfReportStore/pdfReportStore";
import useDate from "../../../../hooks/useDate";

interface WorkOrderPdfReportProps {}

export default function WorkOrderPdfReportViewer({}: WorkOrderPdfReportProps) {
  const { formatDateToLocal } = useDate();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dateRange = usePdfReportDateRange();
  const { data: workOrders } =
    useWorkOrderV2ControllerGetWorkOrderSummaryReportPdf(
      {
        projectId: activeProj?.id?.toString() ?? "",
        dateStartISO: dateRange?.from?.toISOString(),
        dateEndISO: dateRange?.to?.toISOString(),
      },
      {
        query: {
          enabled: !!activeProj?.id && !!dateRange,
          select: (res) => res.data,
        },
      }
    );

  const {
    url,
    loading: pdfLoading,
    error: pdfError,
  } = useRenderWorkOrderSummaryPdf({
    workOrders: workOrders ?? [],
    generatedDateStr: formatDateToLocal({
      dateStr: new Date().toISOString(),
      formatting: "d MMM yyyy, h:m a",
    }),
  });

  const src = url ? `${url}#toolbar=1` : null;

  if (pdfLoading)
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Generating your PDF...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );

  return (
    <iframe
      // @ts-ignore
      src={src}
      // @ts-ignore
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
      }}
    />
  );
}
