import { Skeleton } from "../ui/skeleton";

export default function DrawerFormSkeleton() {
  return (
    <div className="flex flex-col gap-6 mt-10">
      <Skeleton className="h-10"></Skeleton>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-6 w-[40%]"></Skeleton>
        <Skeleton className="h-10"></Skeleton>
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-6 w-[40%]"></Skeleton>
        <Skeleton className="h-10"></Skeleton>
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-6 w-[40%]"></Skeleton>
        <Skeleton className="h-10"></Skeleton>
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-6 w-[40%]"></Skeleton>
        <Skeleton className="h-10"></Skeleton>
      </div>
    </div>
  );
}
