import { useCSVReader } from "react-papaparse";
import AppButton from "../../../components/AppButton";
import { Delete, Files } from "lucide-react";

interface CsvReaderProps {
  onUploadAccepted: (results: any) => void;
}

export default function CsvReader({ onUploadAccepted }: CsvReaderProps) {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      config={{
        header: true,
      }}
      onUploadAccepted={onUploadAccepted}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <>
          <div className="flex">
            {!acceptedFile && (
              <AppButton
                className="flex-grow"
                label="Upload"
                variant="outline"
                icon={<Files />}
                {...getRootProps()}
              />
            )}
            {acceptedFile && (
              <div className="flex items-center p-4 bg-neutral-300 rounded-md justify-between gap-10">
                <div className=" font-sans">{acceptedFile.name}</div>
                <AppButton
                  variant="destructive"
                  icon={<Delete />}
                  {...getRemoveFileProps()}
                />
              </div>
            )}
          </div>
        </>
      )}
    </CSVReader>
  );
}
