import React from "react";
import { useWrRatingIsEnabled } from "../hooks/useFeatures";

interface WRRatingGuardProps {
  children: React.ReactNode;
}

export default function WRRatingGuard({ children }: WRRatingGuardProps) {
  const hasAccess = useWrRatingIsEnabled();
  if (!hasAccess) return <></>;

  return <>{children}</>;
}
