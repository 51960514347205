import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  AssetView,
  useAssetView,
  useAssetViewActions,
} from "../../../stores/assetViewStore/assetViewStore";

export default function SelectAssetView() {
  const assetView = useAssetView();
  const { setAssetView } = useAssetViewActions();

  return (
    <Select
      onValueChange={(v) => {
        setAssetView(v as AssetView);
      }}
      value={assetView}
    >
      <SelectTrigger className="w-[250px] focus:ring-primary-900">
        <SelectValue className="text-primary-900" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={AssetView.LOCATION_BASED_VIEW}>
          Location Based View
        </SelectItem>
        <SelectItem value={AssetView.EQUIPMENT_TYPE_BASED_VIEW}>
          Equipment Type Based View
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
