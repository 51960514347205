import { useSearchParams } from "react-router-dom";
import { useStocksControllerGetOneStockWithLedger } from "../../../api/stocks-inventory/stocks-inventory";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import InventoryInfoTab from "./tabs/InventoryInfoTab";
import InventoryLocationTab from "./tabs/InventoryLocationTab";
import InventoryLogTab from "./tabs/InventoryLogTab";
import InventoryOthersTab from "./tabs/InventoryOthersTab";

export default function InventoryDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");

  const { data: inventory, isLoading } =
    useStocksControllerGetOneStockWithLedger(inventoryId ?? "", {
      query: {
        enabled: !!inventoryId,
      },
    });

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "inventoryTabIdx",
    defaultValue: "0",
  });

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <h2 className="font-sans text-2xl font-bold">
        {inventory?.data?.name ?? "-"}
      </h2>
      <Tabs value={currentTabIdx} onValueChange={onTabChange} defaultValue="0">
        <TabsList className="mb-4">
          <TabsTrigger value="0">
            <p>Info</p>
          </TabsTrigger>
          <TabsTrigger value="1">
            <p>Movement Logs</p>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <InventoryInfoTab />
        </TabsContent>
        <TabsContent value="1">
          <InventoryLogTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}
