import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AppButton from "../../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../ui/dialog";
import AppTextField from "../../../../AppTextField";
import { z } from "zod";
import { ExtraExpensesFieldRemedialCategory } from "../../../../../api/model";
import {
  useDefectControllerGetOneDefect,
  useDefectControllerUpdateExtraFieldExpenses,
} from "../../../../../api/work-orders-defects/work-orders-defects";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import AppSelect from "../../../../AppSelect";
import { AppDatePicker } from "../../../../AppDatePicker";
import { parseISO } from "date-fns";

const pricingExtraFieldSchema = z.object({
  remedialCategory: z
    .enum([
      ExtraExpensesFieldRemedialCategory.INHOUSE_REMEDIAL_EXPENSES,
      ExtraExpensesFieldRemedialCategory.OUTSOURCE_REMEDIAL_EXPENSES,
    ])
    .optional(),
  materialCostPurchased: z.coerce.number().optional(),
  overtimeCost: z.coerce.number().optional(),
  transportationCost: z.coerce.number().optional(),
  rentalOfEquipmentCost: z.coerce.number().optional(),
  startDate: z.coerce.date().optional(),
  completionDate: z.coerce.date().optional(),
  totalCost: z.coerce.number().optional(),
  vendorAppointed: z.string().optional(),
  loaReference: z.string().optional(),
  poReference: z.string().optional(),
});

type PricingExtraFieldFormType = z.infer<typeof pricingExtraFieldSchema>;

export default function ExtraFieldFormDialog() {
  const qc = useQueryClient();
  const [searchParams] = useSearchParams();
  const activeWoId = searchParams.get("workOrderId");
  const methods = useForm<PricingExtraFieldFormType>({
    resolver: zodResolver(pricingExtraFieldSchema),
    defaultValues: {
      remedialCategory:
        ExtraExpensesFieldRemedialCategory.INHOUSE_REMEDIAL_EXPENSES,
      materialCostPurchased: 0,
      overtimeCost: 0,
      transportationCost: 0,
      rentalOfEquipmentCost: 0,
      totalCost: 0,
      vendorAppointed: "",
      loaReference: "",
      poReference: "",
    },
  });

  const [open, setOpen] = useState(false);

  const { data: editExtraExpensesField } = useDefectControllerGetOneDefect(
    activeWoId ?? "",
    {
      query: {
        enabled: !!activeWoId,
        select: (res) => res.data.extraExpensesField,
      },
    }
  );

  const { mutate, isPending } = useDefectControllerUpdateExtraFieldExpenses({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("defect"),
        });
        setOpen(false);
      },
    },
  });

  const onSubmit: SubmitHandler<PricingExtraFieldFormType> = (data) => {
    if (activeWoId === null) return;
    mutate({
      defectId: activeWoId,
      data: {
        remedialCategory: data.remedialCategory,
        materialCostPurchased: data.materialCostPurchased,
        overtimeCost: data.overtimeCost,
        transportationCost: data.transportationCost,
        rentalOfEquipmentCost: data.rentalOfEquipmentCost,
        startDate: data.startDate?.toISOString(),
        completionDate: data.completionDate?.toISOString(),
        totalCost: data.totalCost,
        vendorAppointed: data.vendorAppointed,
        loaReference: data.loaReference,
        poReference: data.poReference,
      },
    });
  };

  useEffect(() => {
    if (!open) methods.reset();
  }, [open]);

  useEffect(() => {
    if (!methods.formState.isDirty && editExtraExpensesField) {
      methods.reset({
        remedialCategory: editExtraExpensesField.remedialCategory,
        materialCostPurchased: editExtraExpensesField.materialCostPurchased,
        overtimeCost: editExtraExpensesField.overtimeCost,
        transportationCost: editExtraExpensesField.transportationCost,
        rentalOfEquipmentCost: editExtraExpensesField.rentalOfEquipmentCost,
        startDate: editExtraExpensesField.startDate
          ? parseISO(editExtraExpensesField.startDate)
          : undefined,
        completionDate: editExtraExpensesField.completionDate
          ? parseISO(editExtraExpensesField.completionDate)
          : undefined,
        totalCost: editExtraExpensesField.totalCost,
        vendorAppointed: editExtraExpensesField.vendorAppointed,
        loaReference: editExtraExpensesField.loaReference,
        poReference: editExtraExpensesField.poReference,
      });
    }
  }, [editExtraExpensesField, methods.formState.isDirty]);

  return (
    <>
      <AppButton
        label="Update"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="w-full max-w-4xl overflow-y-scroll max-h-screen">
          <DialogTitle className="mb-6">Update Pricing</DialogTitle>
          <FormProvider {...methods}>
            <AppSelect
              name="remedialCategory"
              label="Remedial Category"
              selections={[
                {
                  label: "Inhouse Remedial Expenses",
                  value:
                    ExtraExpensesFieldRemedialCategory.INHOUSE_REMEDIAL_EXPENSES,
                },
                {
                  label: "Outsource Remedial Expenses",
                  value:
                    ExtraExpensesFieldRemedialCategory.OUTSOURCE_REMEDIAL_EXPENSES,
                },
              ]}
              defaultValue={
                ExtraExpensesFieldRemedialCategory.INHOUSE_REMEDIAL_EXPENSES
              }
            />
            <AppTextField
              name="materialCostPurchased"
              label="Material Cost Purchased"
            />
            <AppTextField name="overtimeCost" label="Overtime Cost" />
            <AppTextField
              name="transportationCost"
              label="Transportation Cost"
            />
            <AppTextField
              name="rentalOfEquipmentCost"
              label="Rental of Equipment Cost"
            />
            <AppDatePicker name="startDate" label="Start Date" />
            <AppDatePicker name="completionDate" label="Completion Date" />
            <AppTextField name="totalCost" label="Total Cost" />
            <AppTextField name="vendorAppointed" label="Vendor Appointed" />
            <AppTextField name="loaReference" label="LOA Reference" />
            <AppTextField name="poReference" label="PO Reference" />
          </FormProvider>
          <DialogFooter className="mt-6">
            <AppButton
              label="Cancel"
              variant="ghost"
              onClick={() => setOpen(false)}
            />
            <AppButton
              label="Confirm"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
