import { Controller, useFormContext } from "react-hook-form";
import { Toggle } from "../../../ui/toggle";

export default function PmTemplateWeeklyField() {
  const methods = useFormContext();
  return (
    <Controller
      name="frequencySetting.weeklyRepeatDay"
      control={methods.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <div className="grid grid-cols-7 gap-1">
              <Toggle
                pressed={value?.includes("0")}
                onPressedChange={() => {
                  if (!value) return onChange(["0"]);
                  const newValue = value?.includes("0")
                    ? value?.filter((v: string) => v !== "0")
                    : [...value, "0"];
                  onChange(newValue);
                }}
              >
                Sun
              </Toggle>
              <Toggle
                pressed={value?.includes("1")}
                onPressedChange={() => {
                  if (!value) return onChange(["1"]);

                  const newValue = value?.includes("1")
                    ? value?.filter((v: string) => v !== "1")
                    : [...value, "1"];
                  onChange(newValue);
                }}
              >
                Mon
              </Toggle>
              <Toggle
                pressed={value?.includes("2")}
                onPressedChange={() => {
                  if (!value) return onChange(["2"]);
                  const newValue = value?.includes("2")
                    ? value?.filter((v: string) => v !== "2")
                    : [...value, "2"];
                  onChange(newValue);
                }}
              >
                Tue
              </Toggle>
              <Toggle
                pressed={value?.includes("3")}
                onPressedChange={() => {
                  if (!value) return onChange(["3"]);
                  const newValue = value?.includes("3")
                    ? value?.filter((v: string) => v !== "3")
                    : [...value, "3"];
                  onChange(newValue);
                }}
              >
                Wed
              </Toggle>
              <Toggle
                pressed={value?.includes("4")}
                onPressedChange={() => {
                  if (!value) return onChange(["4"]);
                  const newValue = value?.includes("4")
                    ? value?.filter((v: string) => v !== "4")
                    : [...value, "4"];
                  onChange(newValue);
                }}
              >
                Thu
              </Toggle>
              <Toggle
                pressed={value?.includes("5")}
                onPressedChange={() => {
                  if (!value) return onChange(["5"]);

                  const newValue = value?.includes("5")
                    ? value?.filter((v: string) => v !== "5")
                    : [...value, "5"];
                  onChange(newValue);
                }}
              >
                Fri
              </Toggle>
              <Toggle
                pressed={value?.includes("6")}
                onPressedChange={() => {
                  if (!value) return onChange(["6"]);

                  const newValue = value?.includes("6")
                    ? value?.filter((v: string) => v !== "6")
                    : [...value, "6"];
                  onChange(newValue);
                }}
              >
                Sat
              </Toggle>
            </div>
            {error?.message && (
              <div className="text-red-500 font-sans text-sm font-medium">
                {error?.message}
              </div>
            )}
          </>
        );
      }}
    />
  );
}
