import { DialogType, setCloseDialog } from "../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import ConfirmWorkOrderCloseDialog from "./ConfirmWorkOrderCloseDialog";
import ConfirmDeleteBtnWithDialog from "./ConfirmDeleteWithTextDialog";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";

export default function AppDialog() {
  const isOpen = useAppSelector((state) => state.root.isDialogOpen);
  const title = useAppSelector((state) => state.root.dialogTitle);
  const type = useAppSelector((state) => state.root.dialogType);
  const dispatch = useAppDispatch();

  const getContent = () => {
    switch (type) {
      case DialogType.WorkOrderConfirmClose:
        return <ConfirmWorkOrderCloseDialog />;
    }
  };

  return (
    <Dialog
      onOpenChange={() => {
        dispatch(setCloseDialog());
      }}
      open={isOpen}
    >
      <DialogContent>
        <DialogTitle className="text-2xl font-bold">{title}</DialogTitle>
        {getContent()}
      </DialogContent>
    </Dialog>
  );
}
