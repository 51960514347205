import { AccountTypeEnum } from "../../../../api/model";
import { Badge } from "../../../ui/badge";

interface AccountTypeBadgeProps {
  accountType: AccountTypeEnum;
}

export default function AccountTypeBadge({
  accountType,
}: AccountTypeBadgeProps) {
  const getLabel = (accountType: AccountTypeEnum) => {
    switch (accountType) {
      case AccountTypeEnum.COMPANY_ADMIN:
        return "Admin";
      case AccountTypeEnum.NORMAL_USER:
        return "User";
      case AccountTypeEnum.VENDOR:
        return "Vendor";
      case AccountTypeEnum.SUPERADMIN:
        return "Super Admin";

      default:
        return "User";
    }
  };

  return (
    <div>
      <Badge className="mr-1 mb-1 text text-white font-bold bg-primary-900">
        {getLabel(accountType)}
      </Badge>
    </div>
  );
}
