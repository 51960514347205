import { z } from "zod";

export const pdfSettingSchema = z.object({
  uploadedPdfLogo: z.object({ id: z.number(), url: z.string() }).array(),
  pdfLogo: z.instanceof(File).array(),
  pdfCompanyName: z.string(),
  pdfCompanyRegNo: z.string(),
  pdfAddress: z.string(),
  pdfTelNo: z.string(),
  pdfFaxNo: z.string().optional(),
  pdfEmail: z.string(),
});

export type PdfSettingFormType = z.infer<typeof pdfSettingSchema>;
