import { useAsync } from "react-use";
import { useEffect } from "react";

import { proxy, wrap } from "comlink";
import type { WorkerType } from "./workers/pdf.worker";
import Worker from "./workers/pdf.worker?worker";

export const pdfWorker = wrap<WorkerType>(new Worker());
pdfWorker.onProgress(proxy((info: any) => console.log(info)));

export const useRenderPdf = ({
  equipmentList,
  size,
  pdfQrSetting,
}: Parameters<WorkerType["renderPDFInWorker"]>[0]) => {
  const {
    value: url,
    loading,
    error,
  } = useAsync(async () => {
    return pdfWorker.renderPDFInWorker({ equipmentList, size, pdfQrSetting });
  }, [equipmentList.length]);

  useEffect(() => (url ? () => URL.revokeObjectURL(url) : undefined), [url]);
  return { url, loading, error };
};
