import { EscalationType } from "../../../../api/model";
import { Badge } from "../../../ui/badge";

const escalationTypeMapper = {
  [EscalationType.WORK_ORDER]: "Work Order",
  [EscalationType.PM]: "Preventive Maintenance",
  [EscalationType.WORK_REQUEST]: "Work Request",
};

interface SettingSummaryProps {
  escalationType: EscalationType;
  statusStrs: string[];
  noOfDays: number;
  notifyUsersString: string[];
}

export default function SettingSummary({
  escalationType,
  statusStrs,
  noOfDays,
  notifyUsersString,
}: SettingSummaryProps) {

  if (statusStrs.length === 0 || notifyUsersString.length === 0)
    return (
      <div className="p-4 border-slate-200 text-center rounded-md  border-2 leading-9 text-sm text-slate-400 italic">
        <p>A summary will be generated here once the above fields are completed</p>
      </div>
    );

  return (
    <div className="p-4 border-slate-200 text-center rounded-md text-primary-900 border-2 font-semibold leading-9">
      If{" "}
      <Badge className="bg-primary-900 px-4 text-sm">
        {escalationTypeMapper[escalationType]}
      </Badge>{" "}
      that have{" "}
      {statusStrs?.map((s) => (
        <Badge className="bg-primary-900 px-4 mx-0.5 text-sm" key={s}>
          {s}
        </Badge>
      ))}{" "}
      status which remains unchanged for{" "}
      <Badge className="bg-primary-900 px-4 mx-0.5 text-sm">{noOfDays}</Badge>{" "}
      days, notify{" "}
      {notifyUsersString?.map((u) => (
        <Badge className="bg-primary-900 px-4 mx-0.5 text-sm" key={u}>
          {u}
        </Badge>
      ))}
    </div>
  );
}
