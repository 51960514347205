import { File, FilePlus2, Trash2 } from "lucide-react";
import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BimUploadForm } from "../screens/BimScreen/components/BimUploadDialogBtn";
import { Card, CardContent } from "./ui/card";

interface AppUploadBoxProps {
  name: string;
  label?: string;
  supportFormatLabel?: string;
}

export default function AppUploadBox({
  name,
  label,
  supportFormatLabel,
}: AppUploadBoxProps) {
  const methods = useFormContext();
  const fileRef = useRef(null);

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => {
        return (
          <>
            {field.value ? (
              <Card>
                <CardContent className="flex justify-between py-4">
                  <div className="flex gap-4 items-center">
                    <File className="text-primary-900" />
                    <p className="text-primary-900 font-bold">
                      {field.value.name}
                    </p>
                  </div>
                  <Trash2
                    className="text-red-400 hover:cursor-pointer hover:bg-red-50 w-8 h-8 p-1 rounded-lg transition-all"
                    onClick={() => {
                      field.onChange(null);
                    }}
                  />
                </CardContent>
              </Card>
            ) : (
              <>
                <div
                  className="py-14 px-6 border-2 border-dashed border-slate-200 rounded-xl flex flex-col gap-2 items-center hover:cursor-pointer"
                  onClick={() => {
                    (fileRef.current as any).click();
                  }}
                >
                  <FilePlus2 className="w-10 h-10 text-primary-900" />
                  <p className="text-primary-900 font-bold text-lg">{label}</p>
                  <p className="text-sm">Browse and add your files here</p>
                  {supportFormatLabel && (
                    <p className="text-slate-400 text-sm">
                      Support format: {supportFormatLabel}
                    </p>
                  )}
                </div>
                <input
                  ref={fileRef}
                  type="file"
                  className="hidden"
                  accept=".glb"
                  onChange={(e) => {
                    if (!e.target.files) return;
                    field.onChange(e.target.files[0]);
                  }}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
}
