import {
  Hash,
  Tag,
  Text,
  File,
  Calendar,
  ToggleLeft,
  List,
  Image,
} from "lucide-react";
import { PtwFormatType, PtwFormatWithOption } from "../../../../../api/model";
import AppItemInfo from "../../../../AppItemInfo";

interface PtwTemplateDetailItemProps {
  ptwFormats: PtwFormatWithOption[];
}

export default function PtwTemplateDetailItem({
  ptwFormats,
}: PtwTemplateDetailItemProps) {
  const renderPtwFields = (ptwFmt: PtwFormatWithOption) => {
    switch (ptwFmt.ptwFormatType) {
      case PtwFormatType.TEXT:
        return (
          <AppItemInfo
            label="Input Field"
            icon={<Text className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}
          />
        );
      case PtwFormatType.NUMBER:
        return (
          <AppItemInfo
            label="Number"
            icon={<Hash className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );

      case PtwFormatType.ATTACHMENT:
        return (
          <AppItemInfo
            label="Attachment"
            icon={<File className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case PtwFormatType.DATE:
        return (
          <AppItemInfo
            label="Date"
            icon={<Calendar className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case PtwFormatType.BOOLEAN:
        return (
          <AppItemInfo
            label="Yes / No"
            icon={<ToggleLeft className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case PtwFormatType.DESCRIPTION:
        return (
          <AppItemInfo
            label="Description"
            icon={<Text className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case PtwFormatType.HEADING:
        return (
          <AppItemInfo
            label="Heading"
            icon={<Text className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case PtwFormatType.SINGLE_OPTION:
      case PtwFormatType.MULTI_OPTION:
        return (
          <AppItemInfo
            label="Options"
            icon={<List className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
      case PtwFormatType.IMAGE:
        return (
          <AppItemInfo
            label="Image"
            icon={<Image className="h-4 w-4 text-primary-900" />}
            content={<p className="font-sans">{ptwFmt.name}</p>}
            testId={`ptw-field-${ptwFmt.order}`}

          />
        );
    }
  };

  return (
    <>
      {ptwFormats.map((ptwFmt, idx) => {
        return renderPtwFields(ptwFmt);
      })}
    </>
  );
}
