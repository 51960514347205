import { useFormContext, useWatch } from "react-hook-form";
import {
  DefectControllerGetWorkOrderCountParams,
  DefectStatus,
} from "../../../api/model";
import { useDefectControllerGetWorkOrderCount } from "../../../api/work-orders-defects/work-orders-defects";
import { useAppSelector } from "../../../redux/store";
import { useSelectWorkOrderFilterStore } from "../../../stores/workOrder/workOrderFilterStore";
import useDebounce from "../../../hooks/useDebounce";

interface WorkOrderTabWithCountProps {
  workOrderStatus: DefectStatus;
}

export default function WorkOrderTabWithCount({
  workOrderStatus,
}: WorkOrderTabWithCountProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useFormContext();

  const search = useWatch({ control: methods.control, name: "search" });
  const locationFilter = useSelectWorkOrderFilterStore(
    (state) => state.filterLocation
  );
  const workOrderTypeFilter = useSelectWorkOrderFilterStore(
    (state) => state.workOrderType
  );
  const dateRange = useSelectWorkOrderFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectWorkOrderFilterStore(
    (state) => state.assetTypes
  );
  const usersFilter = useSelectWorkOrderFilterStore((state) => state.raisedBy);

  const queryArg = useDebounce<DefectControllerGetWorkOrderCountParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      locFilterId: locationFilter?.id.toString() ?? "",
      search,
      filterAssetTypeIds: assetTypesFilter.map(
        (at) => at?.id?.toString() ?? "0"
      ),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
    },
    delay: 500,
  });

  const { data: workOrderCount } = useDefectControllerGetWorkOrderCount(
    workOrderStatus.id.toString(),
    queryArg,
    {
      query: {
        enabled: !!workOrderStatus,
      },
    }
  );

  return <p>{`${workOrderStatus.name} (${workOrderCount?.data ?? "-"})`}</p>;
}
