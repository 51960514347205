import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { PdfSettingFormType, pdfSettingSchema } from "./pdfSettingSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import AppTextField from "../../AppTextField";
import AppTextAreaField from "../../AppTextAreaField";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import { Attachment, AttachmentProjectType } from "../../../api/model";
import PdfSettingAttachmentField from "./PdfSettingAttachmentField";
import AppButton from "../../AppButton";
import {
  useProjectControllerFindOneProject,
  useProjectControllerUpdateProjectPdfSettings,
} from "../../../api/projects/projects";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useAppStorage from "../../../hooks/useAppStorage";

export default function PdfSettingFormDrawer() {
  const [, setSearchParams] = useSearchParams();
  const qc = useQueryClient();
  const methods = useForm<PdfSettingFormType>({
    resolver: zodResolver(pdfSettingSchema),
    defaultValues: {
      pdfLogo: [],
      uploadedPdfLogo: [],
      pdfCompanyName: "",
      pdfCompanyRegNo: "",
      pdfAddress: "",
      pdfTelNo: "",
      pdfFaxNo: "",
      pdfEmail: "",
    },
  });

  const { useUploadAttachmentMutation } = useAppStorage();

  const { projectId } = useParams();

  const { data: projectData, isLoading } = useProjectControllerFindOneProject(
    projectId ?? "",
    {
      query: {
        enabled: !!projectId,
        select: (res) => res.data.data,
      },
    }
  );

  const { mutateAsync: updateProjectPdfSetting } =
    useProjectControllerUpdateProjectPdfSettings();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (q) => (q.queryKey[0] as string).includes("project"),
      });
      setSearchParams(new URLSearchParams());
    },
  });

  useEffect(() => {
    if (!projectData) return;
    methods.setValue("pdfAddress", projectData?.pdfAddress ?? "");
    methods.setValue("pdfCompanyName", projectData?.pdfCompanyName ?? "");
    methods.setValue("pdfCompanyRegNo", projectData?.pdfCompanyRegNo ?? "");
    methods.setValue("pdfAddress", projectData?.pdfAddress ?? "");
    methods.setValue("pdfTelNo", projectData?.pdfTelNo ?? "");
    methods.setValue("pdfFaxNo", projectData?.pdfFaxNo ?? "");
    methods.setValue("pdfEmail", projectData?.pdfEmail ?? "");
    methods.setValue(
      "uploadedPdfLogo",
      projectData?.pdfLogo?.map((l) => ({
        id: l.id ?? 0,
        url: l.url ?? "",
      })) ?? []
    );
  }, [projectData]);

  const onSubmit: SubmitHandler<PdfSettingFormType> = (data) => {
    mutate({
      files: data.pdfLogo,
      mutateAsync: async (atts) => {
        await updateProjectPdfSetting({
          projectId: projectId ?? "",
          data: {
            pdfAddress: data.pdfAddress,
            pdfCompanyName: data.pdfCompanyName,
            pdfCompanyRegNo: data.pdfCompanyRegNo,
            pdfTelNo: data.pdfTelNo,
            pdfFaxNo: data.pdfFaxNo,
            pdfEmail: data.pdfEmail,
            logo: atts[0] as AttachmentProjectType,
          },
        });
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-bold">Pdf Settings</h2>
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4">
          <AppTextField name="pdfCompanyName" label="Company Name" />
          <AppTextField name="pdfCompanyRegNo" label="Registration No." />
          <AppTextAreaField name="pdfAddress" label="Address" />
          <AppTextField name="pdfTelNo" label="Telephone No." />
          <AppTextField name="pdfFaxNo" label="Fax No." />
          <AppTextField name="pdfEmail" label="Email" />
          <PdfSettingAttachmentField />
          <div>
            <AppButton
              label="Save"
              onClick={methods.handleSubmit(onSubmit)}
              isLoading={isPending}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
