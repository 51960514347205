import { ThreeEvent, useThree } from "@react-three/fiber";
import { useSearchParams } from "react-router-dom";
import { useBimControllerGetBimNodes } from "../../api/bim/bim";
import * as THREE from "three";
import {
  useBimActions,
  useLastRemoveAndSelectedBimStates,
} from "../../stores/bimStore/bimStore";
import { materialLoader } from "./useBim";

export default function useBimEquipmentLink() {
  const [searchParam] = useSearchParams();
  const bimId = searchParam.get("bimId");
  const { setSelectedBimNodes } = useBimActions();
  const { data: bimNodes } = useBimControllerGetBimNodes(bimId as string, {
    query: {
      enabled: !!bimId,
      select: (res) => res.data.data,
    },
  });

  const { selectedBimNodes } = useLastRemoveAndSelectedBimStates();

  const onPointerOver = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    const newMaterial = new THREE.MeshStandardMaterial();
    newMaterial.color.setHex(0xff7dd3fc);
    (e.object as THREE.Mesh).material = newMaterial;
  };

  const onPointerLeave = async (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    const idenNode = bimNodes?.find((node) => node.name === e.object.name);

    if (idenNode) {
      const newMaterial = new THREE.MeshStandardMaterial();
      if (idenNode.assetId) {
        newMaterial.color.setColorName("green");
        return ((e.object as THREE.Mesh).material = newMaterial);
      }
      if (selectedBimNodes.map((bn) => bn.name).includes(idenNode.name)) {
        newMaterial.color.setColorName("red");
        return ((e.object as THREE.Mesh).material = newMaterial);
      }
      if (idenNode.material) {
        return ((e.object as THREE.Mesh).material = materialLoader.parse(
          idenNode.material
        ));
      }
    }
  };

  const onDoubleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    const idenNode = bimNodes?.find((node) => node.name === e.object.name);
    if (!idenNode) return;
    setSelectedBimNodes([idenNode]);
  };

  const onPointerDown = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    const idenNode = bimNodes?.find((node) => node.name === e.object.name);
    if (!idenNode) return;
    setSelectedBimNodes([idenNode]);
  };

  return {
    onPointerOver,
    onPointerLeave,
    onDoubleClick,
    onPointerDown,
  };
}
