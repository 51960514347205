import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  StocksControllerGetStocksCountParams,
  StocksControllerGetStocksParams,
} from "../../../api/model";
import {
  useStocksControllerGetStocks,
  useStocksControllerGetStocksCount,
} from "../../../api/stocks-inventory/stocks-inventory";
import { INVENTORY_FILTER_DRAWER } from "../../../components/drawers/AppDrawer";
import { DataTable } from "../../../components/react-table/DataTable";
import AppSearchAndFilter from "../../../components/searchAndFilter/AppSearchAndFilter";
import useDebounce from "../../../hooks/useDebounce";
import { useAppSelector } from "../../../redux/store";
import { useInventoryFilterStore } from "../../../stores/inventoryFilterStore/inventoryFilterStore";
import { inventoryColumns } from "./components/InventoryColumns";

export default function InventoryTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterCount = useInventoryFilterStore((state) => state.getFilterCount);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const search = useWatch({ control: methods.control, name: "search" });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const inventoryTypeFilter = useInventoryFilterStore(
    (state) => state.inventoryType
  );

  const cleanUp = useInventoryFilterStore((state) => state.cleanUp);

  const queryArg = useDebounce<StocksControllerGetStocksParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      stockTypeId: inventoryTypeFilter?.id?.toString(),
      search,
      rowsPerPage: pagination.pageSize,
      page: pagination.pageIndex,
    },
    delay: 500,
  });

  const queryCountArg = useDebounce<StocksControllerGetStocksCountParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      stockTypeId: inventoryTypeFilter?.id?.toString(),
      search,
    },
    delay: 500,
  });

  const {
    data: inventories,
    isLoading,
    isFetching,
  } = useStocksControllerGetStocks(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const { data: totalCount, isLoading: totalCountIsLoading } =
    useStocksControllerGetStocksCount(queryCountArg, {
      query: {
        enabled: !!activeProj,
      },
    });

  const table = useReactTable({
    data: inventories?.data ?? [],
    columns: inventoryColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: totalCount?.data?.totalCount
      ? Math.ceil(totalCount?.data?.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        warningBalance: false,
        inventoryMode: false,
      },
    },
  });

  useEffect(() => {
    table.resetPagination();
  }, [search, inventoryTypeFilter]);

  // filter clean up
  useEffect(() => {
    return cleanUp;
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <AppSearchAndFilter
          onFilterClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", INVENTORY_FILTER_DRAWER);
              return p;
            });
          }}
          placeholder="Search Inventory"
          filterCount={filterCount()}
        />
        <DataTable
          isLoading={isLoading}
          isFetching={isFetching}
          table={table}
          totalCount={totalCount?.data?.totalCount ?? 0}
          rowsPerPage={pagination.pageSize}
          pageIdx={pagination.pageIndex}
        />
      </div>
    </FormProvider>
  );

  // return (
  //   <AppTable
  //     isLoading={isLoading}
  //     isEmpty={inventories?.length === 0}
  //     colSpan={4}
  //     headerTableRow={
  //       <TableHead>
  //         <TableRow>
  //           <TableCell colSpan={4}>
  //             <div className="flex flex-row-reverse gap-4">

  //             </div>
  //           </TableCell>
  //         </TableRow>
  //         <TableRow>
  //           <TableCell className="font-sans text-lg font-bold">Photo</TableCell>
  //           <TableCell className="font-sans text-lg font-bold">Name</TableCell>
  //           <TableCell className="font-sans text-lg font-bold">
  //             Description
  //           </TableCell>
  //           <TableCell className="font-sans text-lg font-bold">
  //             Balance
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //     }
  //     bodyTableRow={inventories?.map((inv) => (
  //       <TableRow key={inv.id}>
  //         <TableCell>
  //           <AppMultiImage attachments={inv.photos} />
  //         </TableCell>
  //         <TableCell
  //           className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
  //           onClick={() => {
  //             setSearchParams((p) => {
  //               p.set("drawerType", INVENTORY_DETAIL_DRAWER);
  //               p.set("drawerState", DRAWER_VIEW_STATE);
  //               p.set("inventoryId", inv.id.toString());
  //               return p;
  //             });
  //           }}
  //         >
  //           {inv.name}
  //         </TableCell>
  //         <TableCell>{inv.description}</TableCell>
  //         <TableCell>{inv.balance} left</TableCell>
  //       </TableRow>
  //     ))}
  //     count={totalCount?.totalCount}
  //     rowsPerPage={rowsPerPage}
  //     page={currentPg}
  //     onRowsPerPageChange={(e) => {
  //       setRowsPerPage(parseInt(e.target.value, 10));
  //     }}
  //     onPageChange={(e, value) => {
  //       if (!e) return;
  //       setCurrentPg(parseInt(value.toString(), 10));
  //     }}
  //     // pagination={{
  //     //     rowsPerPage,
  //     //     setRowsPerPage,
  //     //     currentPg,
  //     //     setCurrentPg,
  //     //     totalRows: inventories?.length ?? 0,
  //     // }}
  //   />
}
