/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AuthV2ControllerCreateNewUser201,
  AuthV2ControllerGetUsers200Item,
  AuthV2ControllerGetUsersParams,
  AuthV2ControllerUpdateUser200,
  CreateNewUserDto,
  ResetPasswordRequestDto,
  UpdateUserV2Dto,
} from ".././model";

export const authV2ControllerGetUsers = (
  params: AuthV2ControllerGetUsersParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AuthV2ControllerGetUsers200Item[]>> => {
  return axios.get(`/v2/auth`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getAuthV2ControllerGetUsersQueryKey = (
  params: AuthV2ControllerGetUsersParams
) => {
  return [`/v2/auth`, ...(params ? [params] : [])] as const;
};

export const getAuthV2ControllerGetUsersInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
    AuthV2ControllerGetUsersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AuthV2ControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
        QueryKey,
        AuthV2ControllerGetUsersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthV2ControllerGetUsersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
    QueryKey,
    AuthV2ControllerGetUsersParams["cursorId"]
  > = ({ signal, pageParam }) =>
    authV2ControllerGetUsers(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
    TError,
    TData,
    Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
    QueryKey,
    AuthV2ControllerGetUsersParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type AuthV2ControllerGetUsersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof authV2ControllerGetUsers>>
>;
export type AuthV2ControllerGetUsersInfiniteQueryError = AxiosError<unknown>;

export const useAuthV2ControllerGetUsersInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
    AuthV2ControllerGetUsersParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: AuthV2ControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
        QueryKey,
        AuthV2ControllerGetUsersParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthV2ControllerGetUsersInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAuthV2ControllerGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
  TError = AxiosError<unknown>
>(
  params: AuthV2ControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthV2ControllerGetUsersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authV2ControllerGetUsers>>
  > = ({ signal }) =>
    authV2ControllerGetUsers(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthV2ControllerGetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof authV2ControllerGetUsers>>
>;
export type AuthV2ControllerGetUsersQueryError = AxiosError<unknown>;

export const useAuthV2ControllerGetUsers = <
  TData = Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
  TError = AxiosError<unknown>
>(
  params: AuthV2ControllerGetUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authV2ControllerGetUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthV2ControllerGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authV2ControllerCreateNewUser = (
  createNewUserDto: CreateNewUserDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AuthV2ControllerCreateNewUser201>> => {
  return axios.post(`/v2/auth`, createNewUserDto, options);
};

export const getAuthV2ControllerCreateNewUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerCreateNewUser>>,
    TError,
    { data: CreateNewUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authV2ControllerCreateNewUser>>,
  TError,
  { data: CreateNewUserDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authV2ControllerCreateNewUser>>,
    { data: CreateNewUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return authV2ControllerCreateNewUser(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthV2ControllerCreateNewUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof authV2ControllerCreateNewUser>>
>;
export type AuthV2ControllerCreateNewUserMutationBody = CreateNewUserDto;
export type AuthV2ControllerCreateNewUserMutationError = AxiosError<unknown>;

export const useAuthV2ControllerCreateNewUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerCreateNewUser>>,
    TError,
    { data: CreateNewUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authV2ControllerCreateNewUser>>,
  TError,
  { data: CreateNewUserDto },
  TContext
> => {
  const mutationOptions =
    getAuthV2ControllerCreateNewUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authV2ControllerUpdateUser = (
  userId: string,
  updateUserV2Dto: UpdateUserV2Dto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AuthV2ControllerUpdateUser200>> => {
  return axios.patch(`/v2/auth/${userId}`, updateUserV2Dto, options);
};

export const getAuthV2ControllerUpdateUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerUpdateUser>>,
    TError,
    { userId: string; data: UpdateUserV2Dto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authV2ControllerUpdateUser>>,
  TError,
  { userId: string; data: UpdateUserV2Dto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authV2ControllerUpdateUser>>,
    { userId: string; data: UpdateUserV2Dto }
  > = (props) => {
    const { userId, data } = props ?? {};

    return authV2ControllerUpdateUser(userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthV2ControllerUpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof authV2ControllerUpdateUser>>
>;
export type AuthV2ControllerUpdateUserMutationBody = UpdateUserV2Dto;
export type AuthV2ControllerUpdateUserMutationError = AxiosError<unknown>;

export const useAuthV2ControllerUpdateUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerUpdateUser>>,
    TError,
    { userId: string; data: UpdateUserV2Dto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authV2ControllerUpdateUser>>,
  TError,
  { userId: string; data: UpdateUserV2Dto },
  TContext
> => {
  const mutationOptions = getAuthV2ControllerUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authV2ControllerDeactivateUser = (
  userId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/v2/auth/${userId}`, options);
};

export const getAuthV2ControllerDeactivateUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerDeactivateUser>>,
    TError,
    { userId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authV2ControllerDeactivateUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authV2ControllerDeactivateUser>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return authV2ControllerDeactivateUser(userId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthV2ControllerDeactivateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof authV2ControllerDeactivateUser>>
>;

export type AuthV2ControllerDeactivateUserMutationError = AxiosError<unknown>;

export const useAuthV2ControllerDeactivateUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerDeactivateUser>>,
    TError,
    { userId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authV2ControllerDeactivateUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions =
    getAuthV2ControllerDeactivateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authV2ControllerActivateUser = (
  userId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/v2/auth/${userId}/activate`, undefined, options);
};

export const getAuthV2ControllerActivateUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerActivateUser>>,
    TError,
    { userId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authV2ControllerActivateUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authV2ControllerActivateUser>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return authV2ControllerActivateUser(userId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthV2ControllerActivateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof authV2ControllerActivateUser>>
>;

export type AuthV2ControllerActivateUserMutationError = AxiosError<unknown>;

export const useAuthV2ControllerActivateUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerActivateUser>>,
    TError,
    { userId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authV2ControllerActivateUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions =
    getAuthV2ControllerActivateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authV2ControllerResetUserPassword = (
  firebaseUserId: string,
  resetPasswordRequestDto: ResetPasswordRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/v2/auth/${firebaseUserId}/resetPassword`,
    resetPasswordRequestDto,
    options
  );
};

export const getAuthV2ControllerResetUserPasswordMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerResetUserPassword>>,
    TError,
    { firebaseUserId: string; data: ResetPasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authV2ControllerResetUserPassword>>,
  TError,
  { firebaseUserId: string; data: ResetPasswordRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authV2ControllerResetUserPassword>>,
    { firebaseUserId: string; data: ResetPasswordRequestDto }
  > = (props) => {
    const { firebaseUserId, data } = props ?? {};

    return authV2ControllerResetUserPassword(
      firebaseUserId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthV2ControllerResetUserPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authV2ControllerResetUserPassword>>
>;
export type AuthV2ControllerResetUserPasswordMutationBody =
  ResetPasswordRequestDto;
export type AuthV2ControllerResetUserPasswordMutationError =
  AxiosError<unknown>;

export const useAuthV2ControllerResetUserPassword = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authV2ControllerResetUserPassword>>,
    TError,
    { firebaseUserId: string; data: ResetPasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authV2ControllerResetUserPassword>>,
  TError,
  { firebaseUserId: string; data: ResetPasswordRequestDto },
  TContext
> => {
  const mutationOptions =
    getAuthV2ControllerResetUserPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
