import { Typography } from "@mui/material";
import EmptyIcon from "../icons/EmptyIcon";

export default function AppCenterEmpty() {
  return (
    <div className="h-96 flex flex-col justify-center gap-6 items-center">
      <EmptyIcon />
      <p className="text-neutral-600">No Data</p>
    </div>
  );
}
