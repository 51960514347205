import { Add, ChevronRight, ExpandMore } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import { TreeView } from "@mui/x-tree-view";
import { useSearchParams } from "react-router-dom";
import { useLocationControllerGetLocation } from "../../../api/location/location";
import {
  DRAWER_CREATE_STATE,
  LOCATION_FORM_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { useAppSelector } from "../../../redux/store";
import {
  AssetLocationSorting,
  useAssetLocationSorting,
} from "../../../stores/assetLocationSortingStore/assetLocationSortingStore";
import LocationAssetListSection from "../component/LocationAssetListSection";
import LocationDetailSection from "../component/LocationDetailSection";
import LocationTreeItem from "../component/LocationTreeItem";
import SortingToggleBtn from "../component/SortingToggleBtn";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import {
  ResizablePanel,
  ResizablePanelGroup,
} from "../../../components/ui/resizable";

export default function AssetManagementTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const selectedLocation = useAppSelector(
    (state) => state.asset.selectedLocation
  );

  const sortingMode = useAssetLocationSorting();
  const isSorted = sortingMode === AssetLocationSorting.ALPHABETICAL;

  const { data: locations } = useLocationControllerGetLocation(
    {
      projectId: activeProj?.id?.toString() ?? "",
      locationId: "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) =>
          res.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)),
        staleTime: Infinity,
      },
    }
  );

  return (
    <ResizablePanelGroup
      className="drop-shadow-md bg-white rounded-md border-1 border-neutral-100 border-solid mt-4 max-h-[100vh]"
      direction="horizontal"
    >
      <ResizablePanel defaultSize={32}>
        <ScrollArea className="flex flex-col h-full gap-4 px-8">
          <div className="flex justify-between items-center p-8">
            <p className="font-bold text-2xl font-sans">Location</p>
            <div className="flex space-x-4 items-center">
              <IconButton
                onClick={() => {
                  setSearchParams((p) => {
                    p.set("drawerType", LOCATION_FORM_DRAWER);
                    p.set("drawerState", DRAWER_CREATE_STATE);
                    return p;
                  });
                }}
              >
                <Add />
              </IconButton>
              <SortingToggleBtn />
            </div>
          </div>
          <TreeView
            // onNodeSelect={(e: React.SyntheticEvent, v: string) => {
            //   const loc = locations?.find((l) => l.id === parseInt(v));
            //   if (loc) SetSelectedLocation(loc);
            // }}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
          >
            {locations
              ?.sort(
                isSorted ? (a, b) => a.name.localeCompare(b.name) : () => 0
              )
              .map((loc) => (
                <LocationTreeItem loc={loc} key={loc.id} />
              ))}
          </TreeView>
          <ScrollBar />
        </ScrollArea>
      </ResizablePanel>
      <ResizablePanel
        defaultSize={0.05}
        className="bg-slate-300"
      ></ResizablePanel>
      <ResizablePanel>
        <ScrollArea className="h-full">
          <div className="col-span-2 flex flex-col gap-10 flex-1 p-8">
            <LocationDetailSection locationId={selectedLocation?.id} />
            <LocationAssetListSection locationId={selectedLocation?.id} />
          </div>
          <ScrollBar />
        </ScrollArea>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}
