import { ColumnDef, Row } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { GetStockTypesDto, StockType } from "../../../../api/model";
import {
  DRAWER_UPDATE_STATE,
  INVENTORY_TYPE_FORM_DRAWER,
} from "../../../../components/drawers/AppDrawer";

const InventoryTypeNav = ({
  inventoryType,
}: {
  inventoryType: Row<GetStockTypesDto>;
}) => {
  const [, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", INVENTORY_TYPE_FORM_DRAWER);
          p.set("drawerState", DRAWER_UPDATE_STATE);
          p.set(
            "inventoryTypeId",
            inventoryType.getValue("id")?.toString() ?? ""
          );
          return p;
        });
      }}
    >
      {inventoryType.getValue("name") ?? "-"}
    </div>
  );
};

export const inventoryTypeColumn: ColumnDef<GetStockTypesDto>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <InventoryTypeNav inventoryType={row} />,
  },
];
