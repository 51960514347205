/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AppNotification,
  NotificationControllerGetNotificationsParams,
  UnreadCountResponseDto,
} from ".././model";

export const notificationControllerGetNotifications = (
  params: NotificationControllerGetNotificationsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AppNotification[]>> => {
  return axios.get(`/notification`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getNotificationControllerGetNotificationsQueryKey = (
  params: NotificationControllerGetNotificationsParams
) => {
  return [`/notification`, ...(params ? [params] : [])] as const;
};

export const getNotificationControllerGetNotificationsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
    NotificationControllerGetNotificationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: NotificationControllerGetNotificationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
        TError,
        TData,
        Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
        QueryKey,
        NotificationControllerGetNotificationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationControllerGetNotificationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
    QueryKey,
    NotificationControllerGetNotificationsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    notificationControllerGetNotifications(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
    TError,
    TData,
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
    QueryKey,
    NotificationControllerGetNotificationsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type NotificationControllerGetNotificationsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>
  >;
export type NotificationControllerGetNotificationsInfiniteQueryError =
  AxiosError<unknown>;

export const useNotificationControllerGetNotificationsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
    NotificationControllerGetNotificationsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: NotificationControllerGetNotificationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
        TError,
        TData,
        Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
        QueryKey,
        NotificationControllerGetNotificationsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationControllerGetNotificationsInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationControllerGetNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
  TError = AxiosError<unknown>
>(
  params: NotificationControllerGetNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationControllerGetNotificationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>
  > = ({ signal }) =>
    notificationControllerGetNotifications(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationControllerGetNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationControllerGetNotifications>>
>;
export type NotificationControllerGetNotificationsQueryError =
  AxiosError<unknown>;

export const useNotificationControllerGetNotifications = <
  TData = Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
  TError = AxiosError<unknown>
>(
  params: NotificationControllerGetNotificationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationControllerGetNotifications>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationControllerGetNotificationsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationControllerGetUnreadCount = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UnreadCountResponseDto>> => {
  return axios.get(`/notification/unread`, options);
};

export const getNotificationControllerGetUnreadCountQueryKey = () => {
  return [`/notification/unread`] as const;
};

export const getNotificationControllerGetUnreadCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>
  >,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationControllerGetUnreadCountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>
  > = ({ signal }) =>
    notificationControllerGetUnreadCount({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationControllerGetUnreadCountInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>>;
export type NotificationControllerGetUnreadCountInfiniteQueryError =
  AxiosError<unknown>;

export const useNotificationControllerGetUnreadCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>
  >,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationControllerGetUnreadCountInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationControllerGetUnreadCountQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationControllerGetUnreadCountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>
  > = ({ signal }) =>
    notificationControllerGetUnreadCount({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationControllerGetUnreadCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>
>;
export type NotificationControllerGetUnreadCountQueryError =
  AxiosError<unknown>;

export const useNotificationControllerGetUnreadCount = <
  TData = Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof notificationControllerGetUnreadCount>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationControllerGetUnreadCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationControllerReadAll = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/notification/read-all`, undefined, options);
};

export const getNotificationControllerReadAllMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationControllerReadAll>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationControllerReadAll>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationControllerReadAll>>,
    void
  > = () => {
    return notificationControllerReadAll(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationControllerReadAllMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationControllerReadAll>>
>;

export type NotificationControllerReadAllMutationError = AxiosError<unknown>;

export const useNotificationControllerReadAll = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationControllerReadAll>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationControllerReadAll>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getNotificationControllerReadAllMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationControllerToggleReadDone = (
  notificationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/notification/${notificationId}`, undefined, options);
};

export const getNotificationControllerToggleReadDoneMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationControllerToggleReadDone>>,
    TError,
    { notificationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationControllerToggleReadDone>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationControllerToggleReadDone>>,
    { notificationId: string }
  > = (props) => {
    const { notificationId } = props ?? {};

    return notificationControllerToggleReadDone(notificationId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationControllerToggleReadDoneMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationControllerToggleReadDone>>
>;

export type NotificationControllerToggleReadDoneMutationError =
  AxiosError<unknown>;

export const useNotificationControllerToggleReadDone = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationControllerToggleReadDone>>,
    TError,
    { notificationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationControllerToggleReadDone>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationControllerToggleReadDoneMutationOptions(options);

  return useMutation(mutationOptions);
};
