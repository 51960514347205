/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreatePtwRequestDto,
  CreatePtwTemplateDto,
  GenerateWorkPermitRequestDto,
  GenerateWorkPermitResponseDto,
  GetOnePtwResponseDto,
  GetOnePtwTemplateResponseDto,
  GetPtwResponseDto,
  GetPtwTemplatesResponseDto,
  PermitToWorkResponseDto,
  PermitToWorkWithTemplate,
  PtwControllerGetPtwCursorsParams,
  PtwControllerGetPtwsParams,
  PtwTemplateControllerGetPtwTemplatesParams,
  UpdatePtwRequestDto,
  UpdatePtwTemplateRequestDto,
  UpdatePtwTemplateResponseDto,
} from ".././model";

export const ptwTemplateControllerCreatePtwTemplate = (
  createPtwTemplateDto: CreatePtwTemplateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PermitToWorkResponseDto>> => {
  return axios.post(`/permit-to-work-template`, createPtwTemplateDto, options);
};

export const getPtwTemplateControllerCreatePtwTemplateMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerCreatePtwTemplate>>,
    TError,
    { data: CreatePtwTemplateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwTemplateControllerCreatePtwTemplate>>,
  TError,
  { data: CreatePtwTemplateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerCreatePtwTemplate>>,
    { data: CreatePtwTemplateDto }
  > = (props) => {
    const { data } = props ?? {};

    return ptwTemplateControllerCreatePtwTemplate(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwTemplateControllerCreatePtwTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwTemplateControllerCreatePtwTemplate>>
>;
export type PtwTemplateControllerCreatePtwTemplateMutationBody =
  CreatePtwTemplateDto;
export type PtwTemplateControllerCreatePtwTemplateMutationError =
  AxiosError<unknown>;

export const usePtwTemplateControllerCreatePtwTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerCreatePtwTemplate>>,
    TError,
    { data: CreatePtwTemplateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwTemplateControllerCreatePtwTemplate>>,
  TError,
  { data: CreatePtwTemplateDto },
  TContext
> => {
  const mutationOptions =
    getPtwTemplateControllerCreatePtwTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwTemplateControllerGetPtwTemplates = (
  params: PtwTemplateControllerGetPtwTemplatesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPtwTemplatesResponseDto>> => {
  return axios.get(`/permit-to-work-template`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPtwTemplateControllerGetPtwTemplatesQueryKey = (
  params: PtwTemplateControllerGetPtwTemplatesParams
) => {
  return [`/permit-to-work-template`, ...(params ? [params] : [])] as const;
};

export const getPtwTemplateControllerGetPtwTemplatesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
    PtwTemplateControllerGetPtwTemplatesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PtwTemplateControllerGetPtwTemplatesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
        TError,
        TData,
        Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
        QueryKey,
        PtwTemplateControllerGetPtwTemplatesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPtwTemplateControllerGetPtwTemplatesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
    QueryKey,
    PtwTemplateControllerGetPtwTemplatesParams["cursorId"]
  > = ({ signal, pageParam }) =>
    ptwTemplateControllerGetPtwTemplates(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
    TError,
    TData,
    Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
    QueryKey,
    PtwTemplateControllerGetPtwTemplatesParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PtwTemplateControllerGetPtwTemplatesInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>>;
export type PtwTemplateControllerGetPtwTemplatesInfiniteQueryError =
  AxiosError<unknown>;

export const usePtwTemplateControllerGetPtwTemplatesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
    PtwTemplateControllerGetPtwTemplatesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PtwTemplateControllerGetPtwTemplatesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
        TError,
        TData,
        Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
        QueryKey,
        PtwTemplateControllerGetPtwTemplatesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPtwTemplateControllerGetPtwTemplatesInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPtwTemplateControllerGetPtwTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
  TError = AxiosError<unknown>
>(
  params: PtwTemplateControllerGetPtwTemplatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPtwTemplateControllerGetPtwTemplatesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>
  > = ({ signal }) =>
    ptwTemplateControllerGetPtwTemplates(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwTemplateControllerGetPtwTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>
>;
export type PtwTemplateControllerGetPtwTemplatesQueryError =
  AxiosError<unknown>;

export const usePtwTemplateControllerGetPtwTemplates = <
  TData = Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
  TError = AxiosError<unknown>
>(
  params: PtwTemplateControllerGetPtwTemplatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetPtwTemplates>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwTemplateControllerGetPtwTemplatesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ptwTemplateControllerUpdatePtwTemplate = (
  ptwTemplateId: string,
  updatePtwTemplateRequestDto: UpdatePtwTemplateRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UpdatePtwTemplateResponseDto>> => {
  return axios.patch(
    `/permit-to-work-template/${ptwTemplateId}`,
    updatePtwTemplateRequestDto,
    options
  );
};

export const getPtwTemplateControllerUpdatePtwTemplateMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerUpdatePtwTemplate>>,
    TError,
    { ptwTemplateId: string; data: UpdatePtwTemplateRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwTemplateControllerUpdatePtwTemplate>>,
  TError,
  { ptwTemplateId: string; data: UpdatePtwTemplateRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerUpdatePtwTemplate>>,
    { ptwTemplateId: string; data: UpdatePtwTemplateRequestDto }
  > = (props) => {
    const { ptwTemplateId, data } = props ?? {};

    return ptwTemplateControllerUpdatePtwTemplate(
      ptwTemplateId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwTemplateControllerUpdatePtwTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwTemplateControllerUpdatePtwTemplate>>
>;
export type PtwTemplateControllerUpdatePtwTemplateMutationBody =
  UpdatePtwTemplateRequestDto;
export type PtwTemplateControllerUpdatePtwTemplateMutationError =
  AxiosError<unknown>;

export const usePtwTemplateControllerUpdatePtwTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerUpdatePtwTemplate>>,
    TError,
    { ptwTemplateId: string; data: UpdatePtwTemplateRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwTemplateControllerUpdatePtwTemplate>>,
  TError,
  { ptwTemplateId: string; data: UpdatePtwTemplateRequestDto },
  TContext
> => {
  const mutationOptions =
    getPtwTemplateControllerUpdatePtwTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwTemplateControllerGetOnePtwTemplate = (
  ptwTemplateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOnePtwTemplateResponseDto>> => {
  return axios.get(`/permit-to-work-template/${ptwTemplateId}`, options);
};

export const getPtwTemplateControllerGetOnePtwTemplateQueryKey = (
  ptwTemplateId: string
) => {
  return [`/permit-to-work-template/${ptwTemplateId}`] as const;
};

export const getPtwTemplateControllerGetOnePtwTemplateInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>
  >,
  TError = AxiosError<unknown>
>(
  ptwTemplateId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPtwTemplateControllerGetOnePtwTemplateQueryKey(ptwTemplateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>
  > = ({ signal }) =>
    ptwTemplateControllerGetOnePtwTemplate(ptwTemplateId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!ptwTemplateId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwTemplateControllerGetOnePtwTemplateInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>
  >;
export type PtwTemplateControllerGetOnePtwTemplateInfiniteQueryError =
  AxiosError<unknown>;

export const usePtwTemplateControllerGetOnePtwTemplateInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>
  >,
  TError = AxiosError<unknown>
>(
  ptwTemplateId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPtwTemplateControllerGetOnePtwTemplateInfiniteQueryOptions(
      ptwTemplateId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPtwTemplateControllerGetOnePtwTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
  TError = AxiosError<unknown>
>(
  ptwTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPtwTemplateControllerGetOnePtwTemplateQueryKey(ptwTemplateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>
  > = ({ signal }) =>
    ptwTemplateControllerGetOnePtwTemplate(ptwTemplateId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!ptwTemplateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwTemplateControllerGetOnePtwTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>
>;
export type PtwTemplateControllerGetOnePtwTemplateQueryError =
  AxiosError<unknown>;

export const usePtwTemplateControllerGetOnePtwTemplate = <
  TData = Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
  TError = AxiosError<unknown>
>(
  ptwTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwTemplateControllerGetOnePtwTemplateQueryOptions(
    ptwTemplateId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ptwTemplateControllerDeletePtwTemplate = (
  ptwTemplateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/permit-to-work-template/${ptwTemplateId}`, options);
};

export const getPtwTemplateControllerDeletePtwTemplateMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerDeletePtwTemplate>>,
    TError,
    { ptwTemplateId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwTemplateControllerDeletePtwTemplate>>,
  TError,
  { ptwTemplateId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerDeletePtwTemplate>>,
    { ptwTemplateId: string }
  > = (props) => {
    const { ptwTemplateId } = props ?? {};

    return ptwTemplateControllerDeletePtwTemplate(ptwTemplateId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwTemplateControllerDeletePtwTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwTemplateControllerDeletePtwTemplate>>
>;

export type PtwTemplateControllerDeletePtwTemplateMutationError =
  AxiosError<unknown>;

export const usePtwTemplateControllerDeletePtwTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerDeletePtwTemplate>>,
    TError,
    { ptwTemplateId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwTemplateControllerDeletePtwTemplate>>,
  TError,
  { ptwTemplateId: string },
  TContext
> => {
  const mutationOptions =
    getPtwTemplateControllerDeletePtwTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwTemplateControllerGenerateWorkPermit = (
  ptwTemplateId: string,
  generateWorkPermitRequestDto: GenerateWorkPermitRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GenerateWorkPermitResponseDto>> => {
  return axios.post(
    `/permit-to-work-template/${ptwTemplateId}/generate`,
    generateWorkPermitRequestDto,
    options
  );
};

export const getPtwTemplateControllerGenerateWorkPermitMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerGenerateWorkPermit>>,
    TError,
    { ptwTemplateId: string; data: GenerateWorkPermitRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwTemplateControllerGenerateWorkPermit>>,
  TError,
  { ptwTemplateId: string; data: GenerateWorkPermitRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerGenerateWorkPermit>>,
    { ptwTemplateId: string; data: GenerateWorkPermitRequestDto }
  > = (props) => {
    const { ptwTemplateId, data } = props ?? {};

    return ptwTemplateControllerGenerateWorkPermit(
      ptwTemplateId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwTemplateControllerGenerateWorkPermitMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwTemplateControllerGenerateWorkPermit>>
>;
export type PtwTemplateControllerGenerateWorkPermitMutationBody =
  GenerateWorkPermitRequestDto;
export type PtwTemplateControllerGenerateWorkPermitMutationError =
  AxiosError<unknown>;

export const usePtwTemplateControllerGenerateWorkPermit = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerGenerateWorkPermit>>,
    TError,
    { ptwTemplateId: string; data: GenerateWorkPermitRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwTemplateControllerGenerateWorkPermit>>,
  TError,
  { ptwTemplateId: string; data: GenerateWorkPermitRequestDto },
  TContext
> => {
  const mutationOptions =
    getPtwTemplateControllerGenerateWorkPermitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwTemplateControllerGetOnePtwTemplatePublic = (
  ptwTemplateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOnePtwTemplateResponseDto>> => {
  return axios.get(`/permit-to-work-template/public/${ptwTemplateId}`, options);
};

export const getPtwTemplateControllerGetOnePtwTemplatePublicQueryKey = (
  ptwTemplateId: string
) => {
  return [`/permit-to-work-template/public/${ptwTemplateId}`] as const;
};

export const getPtwTemplateControllerGetOnePtwTemplatePublicInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>
    >,
    TError = AxiosError<unknown>
  >(
    ptwTemplateId: string,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPtwTemplateControllerGetOnePtwTemplatePublicQueryKey(ptwTemplateId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>
    > = ({ signal }) =>
      ptwTemplateControllerGetOnePtwTemplatePublic(ptwTemplateId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!ptwTemplateId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type PtwTemplateControllerGetOnePtwTemplatePublicInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>
  >;
export type PtwTemplateControllerGetOnePtwTemplatePublicInfiniteQueryError =
  AxiosError<unknown>;

export const usePtwTemplateControllerGetOnePtwTemplatePublicInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>
  >,
  TError = AxiosError<unknown>
>(
  ptwTemplateId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPtwTemplateControllerGetOnePtwTemplatePublicInfiniteQueryOptions(
      ptwTemplateId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPtwTemplateControllerGetOnePtwTemplatePublicQueryOptions = <
  TData = Awaited<
    ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>
  >,
  TError = AxiosError<unknown>
>(
  ptwTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPtwTemplateControllerGetOnePtwTemplatePublicQueryKey(ptwTemplateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>
  > = ({ signal }) =>
    ptwTemplateControllerGetOnePtwTemplatePublic(ptwTemplateId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!ptwTemplateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwTemplateControllerGetOnePtwTemplatePublicQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>>
  >;
export type PtwTemplateControllerGetOnePtwTemplatePublicQueryError =
  AxiosError<unknown>;

export const usePtwTemplateControllerGetOnePtwTemplatePublic = <
  TData = Awaited<
    ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>
  >,
  TError = AxiosError<unknown>
>(
  ptwTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof ptwTemplateControllerGetOnePtwTemplatePublic>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPtwTemplateControllerGetOnePtwTemplatePublicQueryOptions(
      ptwTemplateId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ptwControllerGetPtws = (
  params: PtwControllerGetPtwsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPtwResponseDto>> => {
  return axios.get(`/permit-to-work`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPtwControllerGetPtwsQueryKey = (
  params: PtwControllerGetPtwsParams
) => {
  return [`/permit-to-work`, ...(params ? [params] : [])] as const;
};

export const getPtwControllerGetPtwsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwControllerGetPtws>>,
    PtwControllerGetPtwsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtws>>,
        TError,
        TData,
        Awaited<ReturnType<typeof ptwControllerGetPtws>>,
        QueryKey,
        PtwControllerGetPtwsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPtwControllerGetPtwsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwControllerGetPtws>>,
    QueryKey,
    PtwControllerGetPtwsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    ptwControllerGetPtws(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof ptwControllerGetPtws>>,
    TError,
    TData,
    Awaited<ReturnType<typeof ptwControllerGetPtws>>,
    QueryKey,
    PtwControllerGetPtwsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PtwControllerGetPtwsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerGetPtws>>
>;
export type PtwControllerGetPtwsInfiniteQueryError = AxiosError<unknown>;

export const usePtwControllerGetPtwsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwControllerGetPtws>>,
    PtwControllerGetPtwsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtws>>,
        TError,
        TData,
        Awaited<ReturnType<typeof ptwControllerGetPtws>>,
        QueryKey,
        PtwControllerGetPtwsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwControllerGetPtwsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPtwControllerGetPtwsQueryOptions = <
  TData = Awaited<ReturnType<typeof ptwControllerGetPtws>>,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtws>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPtwControllerGetPtwsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwControllerGetPtws>>
  > = ({ signal }) => ptwControllerGetPtws(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof ptwControllerGetPtws>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwControllerGetPtwsQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerGetPtws>>
>;
export type PtwControllerGetPtwsQueryError = AxiosError<unknown>;

export const usePtwControllerGetPtws = <
  TData = Awaited<ReturnType<typeof ptwControllerGetPtws>>,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtws>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwControllerGetPtwsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ptwControllerCreatePtw = (
  createPtwRequestDto: CreatePtwRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/permit-to-work`, createPtwRequestDto, options);
};

export const getPtwControllerCreatePtwMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerCreatePtw>>,
    TError,
    { data: CreatePtwRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwControllerCreatePtw>>,
  TError,
  { data: CreatePtwRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwControllerCreatePtw>>,
    { data: CreatePtwRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return ptwControllerCreatePtw(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwControllerCreatePtwMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerCreatePtw>>
>;
export type PtwControllerCreatePtwMutationBody = CreatePtwRequestDto;
export type PtwControllerCreatePtwMutationError = AxiosError<unknown>;

export const usePtwControllerCreatePtw = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerCreatePtw>>,
    TError,
    { data: CreatePtwRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwControllerCreatePtw>>,
  TError,
  { data: CreatePtwRequestDto },
  TContext
> => {
  const mutationOptions = getPtwControllerCreatePtwMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwControllerGetPtwCursors = (
  params: PtwControllerGetPtwCursorsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PermitToWorkWithTemplate[]>> => {
  return axios.get(`/permit-to-work/infinite`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPtwControllerGetPtwCursorsQueryKey = (
  params: PtwControllerGetPtwCursorsParams
) => {
  return [`/permit-to-work/infinite`, ...(params ? [params] : [])] as const;
};

export const getPtwControllerGetPtwCursorsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
    PtwControllerGetPtwCursorsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwCursorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
        QueryKey,
        PtwControllerGetPtwCursorsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPtwControllerGetPtwCursorsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
    QueryKey,
    PtwControllerGetPtwCursorsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    ptwControllerGetPtwCursors(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
    TError,
    TData,
    Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
    QueryKey,
    PtwControllerGetPtwCursorsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PtwControllerGetPtwCursorsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>
>;
export type PtwControllerGetPtwCursorsInfiniteQueryError = AxiosError<unknown>;

export const usePtwControllerGetPtwCursorsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
    PtwControllerGetPtwCursorsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwCursorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
        QueryKey,
        PtwControllerGetPtwCursorsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwControllerGetPtwCursorsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPtwControllerGetPtwCursorsQueryOptions = <
  TData = Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwCursorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPtwControllerGetPtwCursorsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>
  > = ({ signal }) =>
    ptwControllerGetPtwCursors(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwControllerGetPtwCursorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>
>;
export type PtwControllerGetPtwCursorsQueryError = AxiosError<unknown>;

export const usePtwControllerGetPtwCursors = <
  TData = Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
  TError = AxiosError<unknown>
>(
  params: PtwControllerGetPtwCursorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetPtwCursors>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwControllerGetPtwCursorsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ptwControllerGetOnePtw = (
  ptwId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOnePtwResponseDto>> => {
  return axios.get(`/permit-to-work/${ptwId}`, options);
};

export const getPtwControllerGetOnePtwQueryKey = (ptwId: string) => {
  return [`/permit-to-work/${ptwId}`] as const;
};

export const getPtwControllerGetOnePtwInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof ptwControllerGetOnePtw>>>,
  TError = AxiosError<unknown>
>(
  ptwId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPtwControllerGetOnePtwQueryKey(ptwId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwControllerGetOnePtw>>
  > = ({ signal }) =>
    ptwControllerGetOnePtw(ptwId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!ptwId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwControllerGetOnePtwInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerGetOnePtw>>
>;
export type PtwControllerGetOnePtwInfiniteQueryError = AxiosError<unknown>;

export const usePtwControllerGetOnePtwInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof ptwControllerGetOnePtw>>>,
  TError = AxiosError<unknown>
>(
  ptwId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwControllerGetOnePtwInfiniteQueryOptions(
    ptwId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPtwControllerGetOnePtwQueryOptions = <
  TData = Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
  TError = AxiosError<unknown>
>(
  ptwId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPtwControllerGetOnePtwQueryKey(ptwId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ptwControllerGetOnePtw>>
  > = ({ signal }) =>
    ptwControllerGetOnePtw(ptwId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!ptwId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PtwControllerGetOnePtwQueryResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerGetOnePtw>>
>;
export type PtwControllerGetOnePtwQueryError = AxiosError<unknown>;

export const usePtwControllerGetOnePtw = <
  TData = Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
  TError = AxiosError<unknown>
>(
  ptwId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof ptwControllerGetOnePtw>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPtwControllerGetOnePtwQueryOptions(ptwId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ptwControllerUpdatePtw = (
  ptwId: string,
  updatePtwRequestDto: UpdatePtwRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/permit-to-work/${ptwId}`, updatePtwRequestDto, options);
};

export const getPtwControllerUpdatePtwMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerUpdatePtw>>,
    TError,
    { ptwId: string; data: UpdatePtwRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwControllerUpdatePtw>>,
  TError,
  { ptwId: string; data: UpdatePtwRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwControllerUpdatePtw>>,
    { ptwId: string; data: UpdatePtwRequestDto }
  > = (props) => {
    const { ptwId, data } = props ?? {};

    return ptwControllerUpdatePtw(ptwId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwControllerUpdatePtwMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerUpdatePtw>>
>;
export type PtwControllerUpdatePtwMutationBody = UpdatePtwRequestDto;
export type PtwControllerUpdatePtwMutationError = AxiosError<unknown>;

export const usePtwControllerUpdatePtw = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerUpdatePtw>>,
    TError,
    { ptwId: string; data: UpdatePtwRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwControllerUpdatePtw>>,
  TError,
  { ptwId: string; data: UpdatePtwRequestDto },
  TContext
> => {
  const mutationOptions = getPtwControllerUpdatePtwMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwControllerApprovePtw = (
  ptwId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/permit-to-work/${ptwId}/approve`, undefined, options);
};

export const getPtwControllerApprovePtwMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerApprovePtw>>,
    TError,
    { ptwId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwControllerApprovePtw>>,
  TError,
  { ptwId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwControllerApprovePtw>>,
    { ptwId: string }
  > = (props) => {
    const { ptwId } = props ?? {};

    return ptwControllerApprovePtw(ptwId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwControllerApprovePtwMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerApprovePtw>>
>;

export type PtwControllerApprovePtwMutationError = AxiosError<unknown>;

export const usePtwControllerApprovePtw = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerApprovePtw>>,
    TError,
    { ptwId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwControllerApprovePtw>>,
  TError,
  { ptwId: string },
  TContext
> => {
  const mutationOptions = getPtwControllerApprovePtwMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwControllerRejectPtw = (
  ptwId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/permit-to-work/${ptwId}/reject`, undefined, options);
};

export const getPtwControllerRejectPtwMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerRejectPtw>>,
    TError,
    { ptwId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwControllerRejectPtw>>,
  TError,
  { ptwId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwControllerRejectPtw>>,
    { ptwId: string }
  > = (props) => {
    const { ptwId } = props ?? {};

    return ptwControllerRejectPtw(ptwId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwControllerRejectPtwMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerRejectPtw>>
>;

export type PtwControllerRejectPtwMutationError = AxiosError<unknown>;

export const usePtwControllerRejectPtw = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerRejectPtw>>,
    TError,
    { ptwId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwControllerRejectPtw>>,
  TError,
  { ptwId: string },
  TContext
> => {
  const mutationOptions = getPtwControllerRejectPtwMutationOptions(options);

  return useMutation(mutationOptions);
};
export const ptwControllerDeleteAtt = (
  ptwId: string,
  ptwAttId: string,
  attId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `/permit-to-work/${ptwId}/attachment/${ptwAttId}/${attId}`,
    options
  );
};

export const getPtwControllerDeleteAttMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerDeleteAtt>>,
    TError,
    { ptwId: string; ptwAttId: string; attId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ptwControllerDeleteAtt>>,
  TError,
  { ptwId: string; ptwAttId: string; attId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ptwControllerDeleteAtt>>,
    { ptwId: string; ptwAttId: string; attId: string }
  > = (props) => {
    const { ptwId, ptwAttId, attId } = props ?? {};

    return ptwControllerDeleteAtt(ptwId, ptwAttId, attId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PtwControllerDeleteAttMutationResult = NonNullable<
  Awaited<ReturnType<typeof ptwControllerDeleteAtt>>
>;

export type PtwControllerDeleteAttMutationError = AxiosError<unknown>;

export const usePtwControllerDeleteAtt = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ptwControllerDeleteAtt>>,
    TError,
    { ptwId: string; ptwAttId: string; attId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof ptwControllerDeleteAtt>>,
  TError,
  { ptwId: string; ptwAttId: string; attId: string },
  TContext
> => {
  const mutationOptions = getPtwControllerDeleteAttMutationOptions(options);

  return useMutation(mutationOptions);
};
