import { useSearchParams } from "react-router-dom";
import {
  PmChecklistStatus,
  usePmChecklistControllerGetPmChecklistQuery,
} from "../../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../../redux/store";
import AppCenterEmpty from "../../../AppCenterEmpty";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import PmChecklistItem from "./components/PmChecklistItem";

interface PmDetailTabProps {
  pmStatus: PmChecklistStatus;
}

export default function PmDetailTab({pmStatus}: PmDetailTabProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const locationId = searchParams.get("locationId");
  const assetId = searchParams.get("assetId");

  const {data: pmChecklists, isLoading: pmChecklistsIsLoading} =
    usePmChecklistControllerGetPmChecklistQuery(
      {
        projectId: activeProj?.id.toString() ?? "",
        pmStatusId: pmStatus.id.toString(),
        // locationId: locationId ?? "",
        // assetId: assetId ?? "",
        ...(locationId && {locationId}),
        ...(assetId && {assetId}),
      },
      {
        skip: !activeProj,
      }
    );

  if (pmChecklistsIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-3">
      {pmChecklists?.length === 0 && <AppCenterEmpty />}
      {pmChecklists?.map((pmChecklist) => (
        <PmChecklistItem pmChecklist={pmChecklist} key={pmChecklist.id} />
      ))}
    </div>
  );
}
