import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Divider } from "./Divider";
import { ItemSection } from "./ItemSection";

export function RequestDetailsSection() {
  return (
    <>
      <View style={{ gap: 2 }}>
        <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
          B. Request Details
        </Text>
        <Divider />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection label="SKATA" value="" />
          <ItemSection label="Asset Number" value="" />
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection label="Building" value="KOMPLEKS - ZONE B" />
          <ItemSection label="Level" value="LEVEL 1" />
        </View>
        <View style={{ display: "flex", flexDirection: "row", width: "50%" }}>
          <ItemSection
            label="DAK Number (Room)"
            value="BA.01.074.RUANG MENUNGGU AWAM
(JIM. PASSPORT)"
          />
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection label="Responded By" value="" />
          <ItemSection label="Response Date / Time" value="31 Jul 2024 17:27" />
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ItemSection
            labelWidth="20%"
            valueWidth="80%"
            label="Work Request Detail"
            value="Lampu Rosak Di Ruang Menunggu awan JLM.PASSPORT ARAS 1"
          />
        </View>
      </View>
    </>
  );
}
