import { ColumnDef, Row } from "@tanstack/react-table";
import useDate from "../../../../hooks/useDate";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  PM_CHECKLIST_DETAIL_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import { PmChecklistWithAssetAndPmTemplate } from "../../../../api/model";
import { Avatar, AvatarFallback } from "../../../../components/ui/avatar";
import AppFullLocation from "../../../../components/AppFullLocation";

const PmRefNoNav = ({ pm }: { pm: Row<PmChecklistWithAssetAndPmTemplate> }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", PM_CHECKLIST_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("pmChecklistId", pm.getValue("id"));
          return p;
        });
      }}
    >
      {pm.getValue("templateName") ?? "-"}
    </div>
  );
};

const PmDateCell = ({
  row,
}: {
  row: Row<PmChecklistWithAssetAndPmTemplate>;
}) => {
  const { formatDateToLocal } = useDate();

  return (
    <div>
      {formatDateToLocal({
        dateStr: row.getValue("createdOn"),
        formatting: "d MMM yyyy",
      })}
    </div>
  );
};

export const pmChecklistColumns: ColumnDef<PmChecklistWithAssetAndPmTemplate>[] =
  [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      id: "templateName",
      accessorKey: "pmTemplate.name",
      header: "Checklist Name",
      cell: ({ row }) => <PmRefNoNav pm={row} />,
    },
    {
      accessorKey: "createdOn",
      header: "Date",
      cell: ({ row }) => <PmDateCell row={row} />,
    },
    {
      accessorKey: "asset.name",
      header: "Asset",
    },
    {
      id: "locationId",
      accessorKey: "asset.locationId",
      header: "Location",
      cell: ({ row }) => (
        <AppFullLocation locationId={row.getValue("locationId")} />
      ),
    },
    {
      id: "updatedByName",
      accessorKey: "updatedBy.name",
      header: "Completed By",
      cell: ({ row }) => (
        <div className="flex gap-2 items-center">
          <Avatar className="bg-primary-900 text-base">
            <AvatarFallback className="bg-transparent text-white">
              {(row as any)
                .getValue("updatedByName")
                ?.substring(0, 2)
                ?.toUpperCase() ?? "-"}
            </AvatarFallback>
          </Avatar>
          <p>{(row as any).getValue("updatedByName")}</p>
        </div>
      ),
    },
    {
      id: "completedOn",
      accessorKey: "completedOn",
      header: "Completed On",
      cell: ({ row }) => {
        const { formatDateToLocal } = useDate();
        return (
          <div>
            {formatDateToLocal({
              dateStr: row.getValue("completedOn"),
              formatting: "d MMM yyyy",
            })}
          </div>
        );
      },
    },
  ];
