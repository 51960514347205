/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateTimeLogRequestDto,
  ReadOneTimeLogResponseDto,
  ReadTimeLogResponseDto,
  UpdateTimeLogRequestDto,
  WoTimeLogsControllerReadTimeLogParams,
} from ".././model";

export const woTimeLogsControllerReadTimeLog = (
  params: WoTimeLogsControllerReadTimeLogParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ReadTimeLogResponseDto>> => {
  return axios.get(`/wo-time-logs`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWoTimeLogsControllerReadTimeLogQueryKey = (
  params: WoTimeLogsControllerReadTimeLogParams
) => {
  return [`/wo-time-logs`, ...(params ? [params] : [])] as const;
};

export const getWoTimeLogsControllerReadTimeLogInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
    WoTimeLogsControllerReadTimeLogParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WoTimeLogsControllerReadTimeLogParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
        TError,
        TData,
        Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
        QueryKey,
        WoTimeLogsControllerReadTimeLogParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoTimeLogsControllerReadTimeLogQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
    QueryKey,
    WoTimeLogsControllerReadTimeLogParams["cursorId"]
  > = ({ signal, pageParam }) =>
    woTimeLogsControllerReadTimeLog(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
    TError,
    TData,
    Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
    QueryKey,
    WoTimeLogsControllerReadTimeLogParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type WoTimeLogsControllerReadTimeLogInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>
>;
export type WoTimeLogsControllerReadTimeLogInfiniteQueryError =
  AxiosError<unknown>;

export const useWoTimeLogsControllerReadTimeLogInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
    WoTimeLogsControllerReadTimeLogParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: WoTimeLogsControllerReadTimeLogParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
        TError,
        TData,
        Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
        QueryKey,
        WoTimeLogsControllerReadTimeLogParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWoTimeLogsControllerReadTimeLogInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWoTimeLogsControllerReadTimeLogQueryOptions = <
  TData = Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
  TError = AxiosError<unknown>
>(
  params: WoTimeLogsControllerReadTimeLogParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoTimeLogsControllerReadTimeLogQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>
  > = ({ signal }) =>
    woTimeLogsControllerReadTimeLog(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WoTimeLogsControllerReadTimeLogQueryResult = NonNullable<
  Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>
>;
export type WoTimeLogsControllerReadTimeLogQueryError = AxiosError<unknown>;

export const useWoTimeLogsControllerReadTimeLog = <
  TData = Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
  TError = AxiosError<unknown>
>(
  params: WoTimeLogsControllerReadTimeLogParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadTimeLog>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWoTimeLogsControllerReadTimeLogQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const woTimeLogsControllerCreateTimeLog = (
  createTimeLogRequestDto: CreateTimeLogRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/wo-time-logs`, createTimeLogRequestDto, options);
};

export const getWoTimeLogsControllerCreateTimeLogMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerCreateTimeLog>>,
    TError,
    { data: CreateTimeLogRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof woTimeLogsControllerCreateTimeLog>>,
  TError,
  { data: CreateTimeLogRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof woTimeLogsControllerCreateTimeLog>>,
    { data: CreateTimeLogRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return woTimeLogsControllerCreateTimeLog(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WoTimeLogsControllerCreateTimeLogMutationResult = NonNullable<
  Awaited<ReturnType<typeof woTimeLogsControllerCreateTimeLog>>
>;
export type WoTimeLogsControllerCreateTimeLogMutationBody =
  CreateTimeLogRequestDto;
export type WoTimeLogsControllerCreateTimeLogMutationError =
  AxiosError<unknown>;

export const useWoTimeLogsControllerCreateTimeLog = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerCreateTimeLog>>,
    TError,
    { data: CreateTimeLogRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof woTimeLogsControllerCreateTimeLog>>,
  TError,
  { data: CreateTimeLogRequestDto },
  TContext
> => {
  const mutationOptions =
    getWoTimeLogsControllerCreateTimeLogMutationOptions(options);

  return useMutation(mutationOptions);
};
export const woTimeLogsControllerReadOneTimeLog = (
  timeLogId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ReadOneTimeLogResponseDto>> => {
  return axios.get(`/wo-time-logs/${timeLogId}`, options);
};

export const getWoTimeLogsControllerReadOneTimeLogQueryKey = (
  timeLogId: number
) => {
  return [`/wo-time-logs/${timeLogId}`] as const;
};

export const getWoTimeLogsControllerReadOneTimeLogInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>
  >,
  TError = AxiosError<unknown>
>(
  timeLogId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoTimeLogsControllerReadOneTimeLogQueryKey(timeLogId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>
  > = ({ signal }) =>
    woTimeLogsControllerReadOneTimeLog(timeLogId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!timeLogId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WoTimeLogsControllerReadOneTimeLogInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>
>;
export type WoTimeLogsControllerReadOneTimeLogInfiniteQueryError =
  AxiosError<unknown>;

export const useWoTimeLogsControllerReadOneTimeLogInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>
  >,
  TError = AxiosError<unknown>
>(
  timeLogId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWoTimeLogsControllerReadOneTimeLogInfiniteQueryOptions(
      timeLogId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getWoTimeLogsControllerReadOneTimeLogQueryOptions = <
  TData = Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
  TError = AxiosError<unknown>
>(
  timeLogId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWoTimeLogsControllerReadOneTimeLogQueryKey(timeLogId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>
  > = ({ signal }) =>
    woTimeLogsControllerReadOneTimeLog(timeLogId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!timeLogId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WoTimeLogsControllerReadOneTimeLogQueryResult = NonNullable<
  Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>
>;
export type WoTimeLogsControllerReadOneTimeLogQueryError = AxiosError<unknown>;

export const useWoTimeLogsControllerReadOneTimeLog = <
  TData = Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
  TError = AxiosError<unknown>
>(
  timeLogId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof woTimeLogsControllerReadOneTimeLog>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWoTimeLogsControllerReadOneTimeLogQueryOptions(
    timeLogId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const woTimeLogsControllerUpdateTimeLog = (
  timeLogId: string,
  updateTimeLogRequestDto: UpdateTimeLogRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/wo-time-logs/${timeLogId}`,
    updateTimeLogRequestDto,
    options
  );
};

export const getWoTimeLogsControllerUpdateTimeLogMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerUpdateTimeLog>>,
    TError,
    { timeLogId: string; data: UpdateTimeLogRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof woTimeLogsControllerUpdateTimeLog>>,
  TError,
  { timeLogId: string; data: UpdateTimeLogRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof woTimeLogsControllerUpdateTimeLog>>,
    { timeLogId: string; data: UpdateTimeLogRequestDto }
  > = (props) => {
    const { timeLogId, data } = props ?? {};

    return woTimeLogsControllerUpdateTimeLog(timeLogId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WoTimeLogsControllerUpdateTimeLogMutationResult = NonNullable<
  Awaited<ReturnType<typeof woTimeLogsControllerUpdateTimeLog>>
>;
export type WoTimeLogsControllerUpdateTimeLogMutationBody =
  UpdateTimeLogRequestDto;
export type WoTimeLogsControllerUpdateTimeLogMutationError =
  AxiosError<unknown>;

export const useWoTimeLogsControllerUpdateTimeLog = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerUpdateTimeLog>>,
    TError,
    { timeLogId: string; data: UpdateTimeLogRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof woTimeLogsControllerUpdateTimeLog>>,
  TError,
  { timeLogId: string; data: UpdateTimeLogRequestDto },
  TContext
> => {
  const mutationOptions =
    getWoTimeLogsControllerUpdateTimeLogMutationOptions(options);

  return useMutation(mutationOptions);
};
export const woTimeLogsControllerDeleteTimeLog = (
  timeLogId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/wo-time-logs/${timeLogId}`, options);
};

export const getWoTimeLogsControllerDeleteTimeLogMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerDeleteTimeLog>>,
    TError,
    { timeLogId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof woTimeLogsControllerDeleteTimeLog>>,
  TError,
  { timeLogId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof woTimeLogsControllerDeleteTimeLog>>,
    { timeLogId: string }
  > = (props) => {
    const { timeLogId } = props ?? {};

    return woTimeLogsControllerDeleteTimeLog(timeLogId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WoTimeLogsControllerDeleteTimeLogMutationResult = NonNullable<
  Awaited<ReturnType<typeof woTimeLogsControllerDeleteTimeLog>>
>;

export type WoTimeLogsControllerDeleteTimeLogMutationError =
  AxiosError<unknown>;

export const useWoTimeLogsControllerDeleteTimeLog = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof woTimeLogsControllerDeleteTimeLog>>,
    TError,
    { timeLogId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof woTimeLogsControllerDeleteTimeLog>>,
  TError,
  { timeLogId: string },
  TContext
> => {
  const mutationOptions =
    getWoTimeLogsControllerDeleteTimeLogMutationOptions(options);

  return useMutation(mutationOptions);
};
