import { z } from "zod";
import { PtwFormatType } from "../../../api/model";

export const PtwOptionSchema = z.object({
  id: z.number()?.optional(),
  value: z.string().min(1, "Value is required"),
});

export const PtwFormatSchema = z.object({
  id: z.number()?.optional(),
  name: z.string().min(1, "Name is required"),
  heading: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  order: z.number(),
  ptwFormatType: z.enum([
    PtwFormatType.TEXT,
    PtwFormatType.NUMBER,
    PtwFormatType.ATTACHMENT,
    PtwFormatType.BOOLEAN,
    PtwFormatType.DESCRIPTION,
    PtwFormatType.HEADING,
    PtwFormatType.SINGLE_OPTION,
    PtwFormatType.MULTI_OPTION,
    PtwFormatType.IMAGE,
    PtwFormatType.DATE,
    PtwFormatType.DIVIDER,
  ]),
  ptwOptions: PtwOptionSchema.array().optional(),
});
export const PtwTemplateFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
  ptwFormat: PtwFormatSchema.array().min(1, "Please add at least one field"),
});

export type PtwTemplateFormType = z.infer<typeof PtwTemplateFormSchema>;
export type PtwFormatFormType = z.infer<typeof PtwFormatSchema>;
export type PtwOptionSchema = z.infer<typeof PtwOptionSchema>;
