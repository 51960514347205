import { FormProvider, useForm } from "react-hook-form";
import { WoRevenueFormType, woRevenueSchema } from "./woRevenueSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import AppTextField from "../../../../../AppTextField";

export default function WoRevenueForm() {
  return (
    // <FormProvider {...methods}>
    <div className="space-y-4">
      <AppTextField name="description" label="Description" />
      <AppTextField name="amount" label="Amount" type="number" />
    </div>
    // </FormProvider>
  );
}
