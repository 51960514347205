import { zodResolver } from "@hookform/resolvers/zod";
import { Error } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import AppButton from "../../../../../components/AppButton";
import AppTextField from "../../../../../components/AppTextField";
import { WorkRequest } from "../../../../../redux/slices/WorkRequest/model/WorkRequest";
import { useCancelWorkRequestMutation } from "../../../../../redux/slices/WorkRequest/WorkRequestApi";
import AppTextAreaField from "../../../../../components/AppTextAreaField";
import { GetWorkRequestBasedFromCodeResponseDto } from "../../../../../api/model";

const CancellationWorkRequestSchema = z.object({
  cancellationRemark: z
    .string()
    .min(1, { message: "Please add a cancellation remark" }),
});

type CancellationWorkRequestForm = z.infer<
  typeof CancellationWorkRequestSchema
>;

interface CancelWorkRequestDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  wr: GetWorkRequestBasedFromCodeResponseDto;
}

export default function CancelWorkRequestDialog({
  open,
  onClose,
  wr,
}: CancelWorkRequestDialogProps) {
  const methods = useForm<CancellationWorkRequestForm>({
    resolver: zodResolver(CancellationWorkRequestSchema),
  });

  const [cancelWorkRequest, { isLoading, isError, isSuccess, reset }] =
    useCancelWorkRequestMutation();

  const onSubmit: SubmitHandler<CancellationWorkRequestForm> = (data) => {
    if (isError) {
      onClose("close");
      reset();
      methods.reset();
      return;
    }

    // console.log(data);
    cancelWorkRequest({ workRequestCode: wr.trackingCode, dto: data });
  };

  useEffect(() => {
    if (isSuccess) {
      onClose("close");
      methods.reset();
      reset();
    }
  }, [isSuccess]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <p className="font-sans text-2xl font-medium">Cancel Work Request</p>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          {isError ? (
            <div className="flex flex-col gap-3 items-center my-8">
              <Error className="w-12 h-12 rounded-xl flex flex-col justify-center items-center text-red-500" />
              <p className="font-sans text-neutral-700 text-center">
                Sorry, something went wrong. Please try again.
              </p>
            </div>
          ) : (
            <AppTextAreaField
              label="Cancellation Remark"
              name="cancellationRemark"
            />
          )}
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <AppButton
          variant="ghost"
          label="Confirm"
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
}
