/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type AccountTypeEnum =
  (typeof AccountTypeEnum)[keyof typeof AccountTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountTypeEnum = {
  SUPERADMIN: "SUPERADMIN",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  NORMAL_USER: "NORMAL_USER",
  VENDOR: "VENDOR",
} as const;
