import { ReactNode, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { cn } from "../../../utils/util";
import { Button } from "../../../components/ui/button";
import { Tooltip, TooltipContent } from "../../../components/ui/tooltip";
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { useAppSelector } from "../../../redux/store";

export default function AppNavLink({
  to,
  label,
  icon,
  isMini,
}: {
  to: string;
  label: string;
  icon: ReactNode;
  isMini: boolean;
}) {
  const { pathname } = useLocation();
  const isOpenDrawer = useAppSelector(
    (state) => state.root.isMinimizedMainDrawer
  );

  if (isOpenDrawer) {
    return (
      <Link id={to} to={to}>
        <div className="group">
          <Button
            variant="ghost"
            asChild
            className={cn(
              "hover:cursor-pointer group-hover:bg-slate-700 mx-1 flex gap-2 justify-start group py-6",
              {
                "bg-slate-600": pathname.includes(to.split("?")[0]),
                "gap-0 pl-3": isMini,
              }
            )}
          >
            <div className={cn("flex gap-2", { "gap-0": isMini })}>
              {icon}
              <p
                className={cn(
                  "text-slate-400 text-base group-hover:text-slate-200 truncate",
                  {
                    "text-slate-200 font-medium": pathname.includes(
                      to.split("?")[0]
                    ),
                  }
                )}
              >
                {!isMini && label}
              </p>
            </div>
          </Button>
        </div>
      </Link>
    );
  } else {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Link id={to} to={to}>
            <div className="group">
              <Button
                variant="ghost"
                asChild
                className={cn(
                  "hover:cursor-pointer group-hover:bg-slate-700 mx-1 flex gap-2 justify-start group py-6",
                  {
                    "bg-slate-600": pathname.includes(to.split("?")[0]),
                    "gap-0 pl-3": isMini,
                  }
                )}
              >
                <div className={cn("flex gap-2", { "gap-0": isMini })}>
                  {icon}
                  <p
                    className={cn(
                      "text-slate-400 text-base group-hover:text-slate-200 truncate",
                      {
                        "text-slate-200 font-medium": pathname.includes(
                          to.split("?")[0]
                        ),
                      }
                    )}
                  >
                    {!isMini && label}
                  </p>
                </div>
              </Button>
            </div>
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right">
          <div className="ml-auto text-muted-foreground">{label}</div>
        </TooltipContent>
      </Tooltip>
    );
  }
}
