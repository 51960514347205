import {CircularProgress, Menu, MenuItem} from "@mui/material";
import {useState} from "react";
import {
  DefectStatus,
  GetRolesResponseDto,
  RoleWithStatuses,
  useDefectStatusControllerGetAllWorkOrderStatusQuery,
  useRolesControllerUpdateRoleStatusMutation,
} from "../../../../../redux/slices/OpenApi/cerevApi";
import {useAppSelector} from "../../../../../redux/store";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../../../components/ui/dropdown-menu";
import { Button } from "../../../../../components/ui/button";

interface AddWorkOrderStatusBtnProps {
  role: RoleWithStatuses;
}

export default function AddWorkOrderStatusBtn({
  role,
}: AddWorkOrderStatusBtnProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const {data: workOrderStatuses, isLoading} =
    useDefectStatusControllerGetAllWorkOrderStatusQuery(
      {
        companyId: activeComp?.id.toString() ?? "",
      },
      {
        skip: !activeComp,
      }
    );
  const [updateRole, {isLoading: updateRoleIsLoading}] =
    useRolesControllerUpdateRoleStatusMutation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (sta: DefectStatus) => {
    if (sta) {
      updateRole({
        roleId: role.id,
        updateRoleDto: {
          name: role.name,
          defectStatuses: [...role.defectStatuses, sta],
          pmStatuses: role.pmStatuses,
        },
      });
    }
    setAnchorEl(null);
  };

  if (updateRoleIsLoading) {
    return <CircularProgress className="text-neutral-400" size="25px" />;
  }

  if (
    workOrderStatuses?.filter(
      (wo) => !role.defectStatuses?.map((p) => p.id).includes(wo.id)
    ).length === 0
  )
    return <></>;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild> 
        <Button onClick={handleClick} variant="ghost">
          + Add
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
      {workOrderStatuses
          ?.filter(
            (wo) => !role.defectStatuses?.map((p) => p.id).includes(wo.id)
          )
          ?.map((sta) => (
            <DropdownMenuItem
              key={sta.id}
              onClick={() => {
                handleClose(sta);
              }}
            >
              {sta.name}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      > */}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
       
      {/* </Menu> */}
    </DropdownMenu>
  );
}
