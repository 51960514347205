import { useEffect } from "react";
import { useBimOpacity } from "../../stores/bimStore/bimStore";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useBimControllerGetBimNodes } from "../../api/bim/bim";
import { useSearchParams } from "react-router-dom";

export default function useOpacityListener() {
  const opacity = useBimOpacity();
  const [searchParam] = useSearchParams();
  const { scene: threeScene } = useThree();
  const bimId = searchParam.get("bimId");
  const { data: bimNodes } = useBimControllerGetBimNodes(bimId as string, {
    query: {
      enabled: !!bimId,
      select: (res) => res.data.data,
    },
  });

  const adjustOpacity = () => {
    threeScene.traverse((obj) => {
      const idenNode = bimNodes?.find((node) => node.name === obj.name);
      if (obj instanceof THREE.Mesh && idenNode && !idenNode.assetId) {
        obj.material.transparent = true;
        obj.material.opacity = opacity;
      }
    });
  };

  useEffect(() => {
    adjustOpacity();
  }, [opacity]);
}
