import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../../ui/checkbox";
import AppFullLocation from "../../../AppFullLocation";
import { AssetWithType } from "../../../../api/model";

export const assetColumns: ColumnDef<AssetWithType>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "code",
    header: "Code",
  },
  {
    accessorKey: "locationId",
  },
  {
    header: "Location",
    accessorKey: "location.name",
    cell: ({ row }) => (
      <AppFullLocation locationId={row.getValue("locationId")} />
    ),
  },
  {
    accessorKey: "assetType.name",
    header: "Equipment Type",
  },
];
