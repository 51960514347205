import {
  createWorkPermitInput,
  CreateWorkPermitInput,
} from "@cerev-cmms/zod-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { trpc } from "../../../lib/trpc";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppTextAreaField from "../../AppTextAreaField";
import AppTextField from "../../AppTextField";
import { useEffect } from "react";
import { AppDatePicker } from "../../AppDatePicker";
import { addYears, isAfter } from "date-fns";

export default function WorkPermitFormDrawer() {
  // TODO: To decide where there is a need for work permit updates
  const qc = useQueryClient();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParam, setSearchParams] = useSearchParams();
  const workPermitId = searchParam.get("workPermitId");

  const methods = useForm<CreateWorkPermitInput>({
    resolver: zodResolver(createWorkPermitInput),
    defaultValues: {
      workStartDate: new Date(),
      workEndDate: new Date(),
      applierCompanyName: "",
      email: "",
      telNo: "",
      description: "",
      locationOfWork: "",
      workers: [],
      projectCode: activeProj?.workPermitCode,
    },
  });

  const { fields, insert, remove } = useFieldArray({
    control: methods.control,
    name: "workers",
  });

  const { mutate: submitWorkPermit, isPending: submitWorkPermitIsLoading } =
    trpc.workPermit.createWorkPermit.useMutation({
      onSuccess: () => {
        const workPermitKeys = getQueryKey(trpc.workPermit);
        qc.invalidateQueries({
          predicate: (qry) =>
            (qry.queryKey[0] as string).includes("work-permit") ||
            qry.queryKey[0] === workPermitKeys,
        });
        setSearchParams(new URLSearchParams());
      },
    });

  const onSubmit: SubmitHandler<CreateWorkPermitInput> = (data) => {
    if (!activeProj) return;
    submitWorkPermit({
      ...data,
      projectCode: activeProj.workPermitCode ?? "",
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">Create Work Permit</p>
        <AppTextField label="Company Name" name="applierCompanyName" />
        <AppTextField label="Email" name="email" />
        <AppTextField label="Contact No." name="telNo" />
        <AppTextAreaField label="Description" name="description" />
        <AppDatePicker
          label="Work Start Date"
          name="workStartDate"
          disabled={(date) => isAfter(date, addYears(new Date(), 3))}
        />
        <AppDatePicker
          label="Work End Date"
          name="workEndDate"
          disabled={(date) => isAfter(date, addYears(new Date(), 3))}
        />
        <AppTextField label="Location of work" name="locationOfWork" />
        {fields.map((fl, idx) => (
          <div key={fl.id}>
            <AppTextField
              label={`Worker ${idx + 1} Name`}
              name={`workers.${idx}.name`}
            />
            <AppTextField
              label={`Worker ${idx + 1} Ic No.`}
              name={`workers.${idx}.icNo`}
            />
            <AppButton
              className="rounded-xl mt-2"
              variant="destructive"
              onClick={() => {
                remove(idx);
              }}
              label="Remove"
            />
          </div>
        ))}
        <AppButton
          onClick={() => {
            insert(fields.length, { name: "", icNo: "" });
          }}
          label="Add Worker"
          variant="outline"
        />
        <div className="flex">
          <AppButton
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={submitWorkPermitIsLoading}
            label="Submit"
          />
        </div>
      </div>
    </FormProvider>
  );
}
