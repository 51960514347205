import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface useAppTabsNavigationProps {
  searchParamStr: string;
  defaultValue: string;
}

export default function useAppTabsNavigation({
  searchParamStr,
  defaultValue,
}: useAppTabsNavigationProps) {
  // TODO: When user share the link, the tab will not be the same as the link
  const [searchParam, setSearchParams] = useSearchParams();
  const [currentTabIdx, setCurrentTabIdx] = useState(defaultValue);

  useEffect(() => {
    const currentParam = searchParam.get(searchParamStr);
    const drawerType = searchParam.get("drawerType");
    if (!currentParam && !drawerType) return;
    if (!currentParam) {
      // setCurrentTabIdx(defaultValue);
      // setSearchParams((p) => {
      //   p.set(searchParamStr, defaultValue);
      //   return p;
      // });
    } else {
      setCurrentTabIdx(currentParam);
    }
  }, [
    defaultValue,
    searchParam,
    searchParamStr,
    setCurrentTabIdx,
    setSearchParams,
  ]);

  // useEffect(() => {
  //   const searchParamIdx = searchParam.get(searchParamStr);
  //   if (searchParamIdx) {
  //     setCurrentTabIdx(parseInt(searchParamIdx));
  //   }
  // }, [searchParam]);

  const onTabChange = (value: string) => {
    setSearchParams((p) => {
      p.set(searchParamStr, value);
      return p;
    });
    setCurrentTabIdx(value);
  };

  return {
    currentTabIdx,
    onTabChange,
  };
}
