import { useParams } from "react-router-dom";
import { Card, CardContent } from "../../../../../../../../components/ui/card";
import { Switch } from "../../../../../../../../components/ui/switch";
import {
  useProjectControllerFindOneProject,
  useProjectControllerToggleWorkOrderQrLogoEnabled,
} from "../../../../../../../../api/projects/projects";
import { useQueryClient } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";

export default function CustomEquipmentQrLogoCard() {
  const { projectId } = useParams();
  const qc = useQueryClient();

  const { data: isEnabled } = useProjectControllerFindOneProject(
    projectId ?? "",
    {
      query: {
        enabled: !!projectId,
        select: (res) => res.data.data.customWorkOrderQrLogoEnabled ?? false,
      },
    }
  );

  const { mutate, isPending } =
    useProjectControllerToggleWorkOrderQrLogoEnabled({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("project"),
          });
        },
      },
    });

  const onCheckChanged = (o: boolean) => {
    mutate({
      projectId: projectId as string,
    });
  };

  return (
    <Card>
      <CardContent className="p-4 flex  gap-6 items-center justify-between">
        <div className="flex flex-col">
          <p className="text-lg font-medium">Custom Equipment QR Code Logo</p>
          <p className="text-sm leading-relaxed text-slate-500">
            To allow project to have their own logo instead of Cerev on
            Equipment QR Code
          </p>
        </div>
        <Switch
          className="data-[state=checked]:bg-primary-900"
          checked={isEnabled}
          onCheckedChange={onCheckChanged}
        />
        {isPending && (
          <Loader2 className="h-6 w-6 animate-spin text-primary-900" />
        )}
      </CardContent>
    </Card>
  );
}
