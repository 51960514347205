/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type ExtraExpensesFieldRemedialCategory =
  (typeof ExtraExpensesFieldRemedialCategory)[keyof typeof ExtraExpensesFieldRemedialCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExtraExpensesFieldRemedialCategory = {
  INHOUSE_REMEDIAL_EXPENSES: "INHOUSE_REMEDIAL_EXPENSES",
  OUTSOURCE_REMEDIAL_EXPENSES: "OUTSOURCE_REMEDIAL_EXPENSES",
} as const;
