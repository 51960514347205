/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type AccessPermission =
  (typeof AccessPermission)[keyof typeof AccessPermission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessPermission = {
  ALLOWED: "ALLOWED",
  NOT_ALLOWED: "NOT_ALLOWED",
} as const;
