import {
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  CerevModule,
  PtwTemplateControllerGetPtwTemplatesParams,
} from "../../api/model";
import AppButton from "../../components/AppButton";
import AppTextField from "../../components/AppTextField";
import {
  DRAWER_CREATE_STATE,
  PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import { DataTable } from "../../components/react-table/DataTable";
import useDebounce from "../../hooks/useDebounce";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import { ptwTemplateColumns } from "./ptwTemplateColumns";
import { usePtwTemplateControllerGetPtwTemplates } from "../../api/permit-to-work-adv/permit-to-work-adv";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";

export default function PermitToWorkTemplateScreen() {
  const methods = useForm<{ search: string }>();
  const search = useWatch({ control: methods.control, name: "search" });
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<PtwTemplateControllerGetPtwTemplatesParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
    },
    delay: 500,
  });

  const {
    data: pmWorkTemplate,
    isFetching,
    isLoading,
  } = usePtwTemplateControllerGetPtwTemplates(queryArg, {
    query: {
      enabled: !!activeProj,
      select: (res) => res.data,
    },
  });

  const table = useReactTable({
    data: pmWorkTemplate?.data ?? [],
    columns: ptwTemplateColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: pmWorkTemplate?.count
      ? Math.ceil(pmWorkTemplate?.count / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <ModuleAccessGuard module={CerevModule.WORK_PERMIT}>
      <div className="flex gap-4">
        <AppTitle title="PTW Templates" />
        <AppButton
          label="+ New"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER);
              p.set("drawerState", DRAWER_CREATE_STATE);
              return p;
            });
          }}
        />
      </div>
      <FormProvider {...methods}>
        <AppTextField
          name="search"
          placeholder="Search..."
          className="w-[400px]"
        />
        <DataTable
          isFetching={isFetching}
          isLoading={isLoading}
          table={table}
          totalCount={pmWorkTemplate?.count ?? 0}
          rowsPerPage={pagination.pageSize}
          pageIdx={pagination.pageIndex}
        />
      </FormProvider>
    </ModuleAccessGuard>
  );
}
