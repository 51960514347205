import { useCallback, useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { cn } from "../../../../lib/utils";
import { Toggle } from "../../../ui/toggle";
import { Input } from "../../../ui/input";
import { AddPmTemplateDtoRepeatType } from "../../../../api/model";
import AppCronRepeatType from "./AppCronRepeatType";
import AppCronField from "./AppCronField";

interface AppCronPickerProps<T extends FieldValues = FieldValues> {
  label: string;
}

export default function AppCronPicker<T extends FieldValues>({
  label,
}: AppCronPickerProps<T>) {
  return (
    <div className="flex flex-col gap-2">
      <p>{label}</p>
      <AppCronRepeatType name="frequencySetting.frequencySettingType" />
      <AppCronField name="frequencySetting.frequencySettingType" />
    </div>
  );
}
