import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon, ChevronRight, X } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface AppDateRangePickerProps {
  date: DateRange | undefined | null;
  setDate: (DateRange: DateRange | undefined) => void;
  onClear: () => void;
  className?: string;
  hideOnClear?: boolean;
}

export function AppDateRangePicker({
  className,
  date,
  setDate,
  onClear,
  hideOnClear = false,
}: AppDateRangePickerProps) {
  //   const [date, setDate] = React.useState<DateRange | undefined>({
  //     from: new Date(2022, 0, 20),
  //     to: addDays(new Date(2022, 0, 20), 20),
  //   })

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            size="lg"
            className={cn(
              "bg-slate-50 justify-between text-left font-normal px-4 flex",
              !date && "text-muted-foreground",
              {
                "bg-primary-900 hover:bg-primary-900": date,
              }
            )}
          >
            <div className="flex gap-3 items-center">
              <CalendarIcon
                className={cn(`"h-4 w-4 text-slate-400 "`, {
                  "text-white": date,
                })}
              />
              {date?.from ? (
                date.to ? (
                  <span className={cn({ "text-white": date })}>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </span>
                ) : (
                  <span className={cn({ "text-white": date })}>
                    {format(date.from, "LLL dd, y")}
                  </span>
                )
              ) : (
                <span className="text-slate-400">Pick a date</span>
              )}
            </div>
            {date && !hideOnClear ? (
              <X
                className={cn({ "text-white": date })}
                onClick={(e) => {
                  e?.stopPropagation();
                  onClear();
                }}
              />
            ) : (
              <ChevronRight className={cn({ "text-white": date })} />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date || undefined}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
