import { useFormContext } from "react-hook-form";
import { Input, InputProps } from "./ui/input";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { cn } from "../utils/util";

interface AppTextFieldProp {
  label?: string;
  className?: string;
  inputClassName?: string;
  inputProps?: InputProps;
  name: string;
  description?: string;
  testId?: string;
}

export default function AppTextField({
  label,
  className,
  inputClassName,
  inputProps,
  name,
  description,
  testId,
  ...props
}: AppTextFieldProp & InputProps) {
  const form = useFormContext();

  return (
    <div className={`flex flex-col ${className}`} data-testid={testId}>
      <FormField
        control={form.control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <FormItem className={cn(className)}>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl>
              <Input
                {...props}
                {...field}
                className={cn(
                  "focus-visible:ring-primary-900 bg-slate-50",
                  {
                    "ring-2 ring-offset-1 ring-red-500": !!error,
                  },
                  inputClassName
                )}
              />
            </FormControl>
            <FormDescription>{description}</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
