import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AnalyticFormDrawer from "./AnalyticFormDrawer";
import CompanyFormDrawer from "./CompanyFormDrawer/CompanyFormDrawer";
import ProjectDetailDrawer from "./project/ProjectDetailDrawer";
import ProjectFormDrawer from "./ProjectFormDrawer/ProjectFormDrawer";
import RoleFormDrawer from "./RoleFormDrawer";
import UserFormDrawer from "./user/UserFormDrawer";
import WorkOrderDetailDrawer from "./workOrders/WorkOrderDetailDrawer";
import WorkOrderFormDrawer from "./workOrders/WorkOrderFormDrawer";
import useResize from "../../hooks/useResize";
import InventoryFormDrawer from "./inventory/InventoryFormDrawer";
import InventoryTypeFormDrawer from "./InventoryTypeFormDrawer";
import InventoryDetailDrawer from "./inventory/InventoryDetailDrawer";
import LocationFormDrawer from "./LocationFormDrawer";
import AssetFormDrawer from "./asset/AssetFormDrawer";
import AssetDetailDrawer from "./asset/AssetDetailDrawer";
import LocationDetailDrawer from "./LocationDetailDrawer";
import LegalComplianceFormDrawer from "./LegalComplianceFormDrawer";
import LegalComplianceDetailDrawer from "./LegalComplianceDetailDrawer";
import VendorFormDrawer from "./vendor/VendorFormDrawer";
import VendorDetailDrawer from "./vendor/VendorDetailDrawer";
import WorkScopeFormDrawer from "./WorkScopeFormDrawer";
import PmDetailDrawer from "./pm/PmDetailDrawer";
import PmChecklistFormDrawer from "./pm/PmChecklistFormDrawer";
import PmChecklistDetailDrawer from "./pm/PmChecklistDetailDrawer";
import WorkPermitFormDrawer from "./workPermit/WorkPermitFormDrawer";
import WorkPermitDetailDrawer from "./workPermit/WorkPermitDetailDrawer";
import AssetTypeFormDrawer from "./asset/AssetTypeFormDrawer";
import WorkRequestFormDrawer from "./workRequest/WorkRequestFormDrawer";
import WorkRequestDetailDrawer from "./workRequest/WorkRequestDetailDrawer";
import WorkOrderTypeFormDrawer from "./WorkOrderTypeFormDrawer";
import TnbBillFormDrawer from "./tnbBills/TnbBillFormDrawer";
import EscalationFormDrawer from "./escalation/EscalationFormDrawer";
import { Sheet, SheetContent, SheetDescription, SheetTitle } from "../ui/sheet";
import TnbBillDetailDrawer from "./tnbBills/TnbBillDetailDrawer";
import PmTemplateDetailDrawer from "./pmTemplate/PmTemplateDetailDrawer/PmTemplateDetailDrawer";
import PmTemplateFormDrawer from "./pmTemplate/PmTemplateFormDrawer";
import WorkRequestFilterDrawer from "./workRequest/WorkRequestFilterDrawer";
import WorkRequestSortDrawer from "./workRequest/WorkRequestSortDrawer";
import WorkOrderFilterDrawer from "./workOrders/WorkOrderFilterDrawer/WorkOrderFilterDrawer";
import PmFilterDrawer from "../../screens/PmScreen/PmTableScreen.tsx/PmFilterDrawer";
import VendorFilterDrawer from "../../screens/VendorScreen/VendorTab/VendorFilterDrawer";
import InventoryFilterDrawer from "../../screens/InventoryScreen/tabs/InventoryFilterDrawer";
import WorkOrderSchedulerFilterDrawer from "./workOrderScheduler/WorkOrderSchedulerFilterDrawer";
import NotificationDrawer from "../../screens/HomeScreen/NotificationDrawer";
import BimDrawer from "./bimDrawer/BimDrawer";
import InvoiceFormDrawer from "./invoice/InvoiceFormDrawer";
import UserDetailDrawer from "./user/UserDetailDrawer";
import InventoryLedgerDetailDrawer from "./inventory/InventoryLedgerDetailDrawer";
import PtwTemplateDrawerForm from "./ptwTemplate/PtwTemplateDrawerForm";
import PtwTemplateDetailDrawer from "@/components/drawers/ptwTemplate/PtwTemplateDetailDrawer";
import PtwFormDrawer from "./ptw/PtwFormDrawer";
import PtwDetailDrawer from "./ptw/PtwDetailDrawer/PtwDetailDrawer";
import { cn } from "../../utils/util";
import ManHourSettingFormDrawer from "./manHourSetting/ManHourSettingFormDrawer";
import ProjectPDFTab from "../../screens/SuperAdminScreen/subScreens/ProjectsScreen/ProjectDetailScreen/tabs/PDFTabs/ProjectPDFTab";
import PdfSettingDrawer from "./pdfSetting/PdfSettingFormDrawer";
import EscalationDetailDrawer from "./escalation/EscalationDetailDrawer";

export const COMPANY_FORM_DRAWER = "COMPANY_FORM_DRAWER";
export const PROJECT_FORM_DRAWER = "PROJECT_FORM_DRAWER";
export const PROJECT_DETAIL_DRAWER = "PROJECT_DETAIL_DRAWER";
export const USER_FORM_DRAWER = "USER_FORM_DRAWER";
export const USER_DETAIL_DRAWER = "USER_DETAIL_DRAWER";
export const ANALYTIC_FORM_DRAWER = "ANALYTIC_FORM_DRAWER";
export const ROLE_FORM_DRAWER = "ROLE_FORM_DRAWER";
export const WORK_ORDER_FORM_DRAWER = "WORK_ORDER_FORM_DRAWER";
export const WORK_ORDER_DETAIL_DRAWER = "WORK_ORDER_DETAIL_DRAWER";
export const INVENTORY_FORM_DRAWER = "INVENTORY_FORM_DRAWER";
export const INVENTORY_TYPE_FORM_DRAWER = "INVENTORY_TYPE_FORM_DRAWER";
export const INVENTORY_DETAIL_DRAWER = "INVENTORY_DETAIL_DRAWER";
export const INVENTORY_LEDGER_ITEM_DETAIL_DRAWER =
  "INVENTORY_LEDGER_ITEM_DETAIL_DRAWER";
export const LOCATION_FORM_DRAWER = "LOCATION_FORM_DRAWER";
export const ASSET_FORM_DRAWER = "ASSET_FORM_DRAWER";
export const ASSET_DETAIL_DRAWER = "ASSET_DETAIL_DRAWER";
export const LOCATION_DETAIL_DRAWER = "LOCATION_DETAIL_DRAWER";
export const LEGAL_COMPLIANCE_FORM_DRAWER = "LEGAL_COMPLIANCE_FORM_DRAWER";
export const LEGAL_COMPLIANCE_DETAIL_DRAWER = "LEGAL_COMPLIANCE_DETAIL_DRAWER";
export const VENDOR_FORM_DRAWER = "VENDOR_FORM_DRAWER";
export const VENDOR_DETAIL_DRAWER = "VENDOR_DETAIL_DRAWER";
export const WORK_SCOPE_FORM_DRAWER = "WORK_SCOPE_FORM_DRAWER";
export const PM_DETAIL_DRAWER = "PM_DETAIL_DRAWER";
export const PM_CHECKLIST_FORM_DRAWER = "PM_CHECKLIST_FORM_DRAWER";
export const PM_CHECKLIST_DETAIL_DRAWER = "PM_CHECKLIST_DETAIL_DRAWER";
export const WORK_PERMIT_FORM_DRAWER = "WORK_PERMIT_FORM_DRAWER";
export const WORK_PERMIT_DETAIL_DRAWER = "WORK_PERMIT_DETAIL_DRAWER";
export const PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER =
  "PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER";
export const ASSET_TYPE_FORM_DRAWER = "ASSET_TYPE_FORM_DRAWER";
export const WORK_REQUEST_FORM_DRAWER = "WORK_REQUEST_FORM_DRAWER";
export const WORK_REQUEST_DETAIL_DRAWER = "WORK_REQUEST_DETAIL_DRAWER";
export const WORK_ORDER_TYPE_FORM_DRAWER = "WORK_ORDER_TYPE_FORM_DRAWER";
export const TNB_BILL_FORM_DRAWER = "TNB_BILL_FORM_DRAWER";
export const TNB_BILL_DETAIL_DRAWER = "TNB_BILL_DETAIL_DRAWER";
export const ESCALATION_FORM_DRAWER = "ESCALATION_FORM_DRAWER";
export const ESCALATION_DETAIL_DRAWER = "ESCALATION_DETAIL_DRAWER";
export const PM_TEMPLATE_DRAWER = "PM_TEMPLATE_DRAWER";
export const PM_TEMPLATE_FORM_DRAWER = "PM_TEMPLATE_FORM_DRAWER";
export const NOTIFICATION_DRAWER = "NOTIFICATION_DRAWER";
export const BIM_DRAWER = "BIM_DRAWER";
export const INVOICE_FORM_DRAWER = "INVOICE_FORM_DRAWER";
export const PERMIT_TO_WORK_TEMPLATE_DETAIL_DRAWER =
  "PERMIT_TO_WORK_TEMPLATE_DETAIL_DRAWER";
export const PERMIT_TO_WORK_FORM_DRAWER = "PERMIT_TO_WORK_FORM_DRAWER";
export const PERMIT_TO_WORK_DETAIL_DRAWER = "PERMIT_TO_WORK_DETAIL_DRAWER";
export const MAN_HOUR_SETTING_FORM_DRAWER = "MAN_HOUR_SETTING_FORM_DRAWER";

// Filter Drawers
export const WORK_REQUEST_FILTER_DRAWER = "WORK_REQUEST_FILTER_DRAWER";
export const WORK_REQUEST_SORT_DRAWER = "WORK_REQUEST_SORT_DRAWER";
export const WORK_ORDER_FILTER_DRAWER = "WORK_ORDER_FILTER_DRAWER";
export const PM_FILTER_DRAWER = "PM_FILTER_DRAWER";
export const VENDOR_FILTER_DRAWER = "VENDOR_FILTER_DRAWER";
export const INVENTORY_FILTER_DRAWER = "INVENTORY_FILTER_DRAWER";
export const WORK_ORDER_SCHEDULER_FILTER_DRAWER =
  "WORK_ORDER_SCHEDULER_FILTER_DRAWER";
export const PDF_SETTING_DRAWER = "PDF_SETTING_DRAWER";

// Drawer States
export const DRAWER_UPDATE_STATE = "UPDATE";
export const DRAWER_CREATE_STATE = "CREATE";
export const DRAWER_VIEW_STATE = "VIEW";

export interface DrawerSearchParamProps {
  drawerType: "COMPANY_FORM_DRAWER" | "PROJECT_FORM_DRAWER";
  drawerState: "CREATE" | "UPDATE" | "VIEW";
}

export default function AppDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const drawerDetail = searchParams.get("drawerType");
  // const isOpenDrawer = useAppSelector((state) => state.root.isOpenDrawer);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  // Switch case depending on drawer detail
  const drawerContent = () => {
    switch (drawerDetail) {
      case COMPANY_FORM_DRAWER:
        return <CompanyFormDrawer />;
      case PROJECT_FORM_DRAWER:
        return <ProjectFormDrawer />;
      case PROJECT_DETAIL_DRAWER:
        return <ProjectDetailDrawer />;
      case USER_FORM_DRAWER:
        return <UserFormDrawer />;
      case USER_DETAIL_DRAWER:
        return <UserDetailDrawer />;
      case ANALYTIC_FORM_DRAWER:
        return <AnalyticFormDrawer />;
      case ROLE_FORM_DRAWER:
        return <RoleFormDrawer />;
      case WORK_ORDER_FORM_DRAWER:
        return <WorkOrderFormDrawer />;
      case WORK_ORDER_DETAIL_DRAWER:
        return <WorkOrderDetailDrawer />;
      case INVENTORY_FORM_DRAWER:
        return <InventoryFormDrawer />;
      case INVENTORY_TYPE_FORM_DRAWER:
        return <InventoryTypeFormDrawer />;
      case INVENTORY_DETAIL_DRAWER:
        return <InventoryDetailDrawer />;
      case INVENTORY_LEDGER_ITEM_DETAIL_DRAWER:
        return <InventoryLedgerDetailDrawer />;
      case LOCATION_FORM_DRAWER:
        return <LocationFormDrawer />;
      case ASSET_FORM_DRAWER:
        return <AssetFormDrawer />;
      case ASSET_DETAIL_DRAWER:
        return <AssetDetailDrawer />;
      case LOCATION_DETAIL_DRAWER:
        return <LocationDetailDrawer />;
      case LEGAL_COMPLIANCE_FORM_DRAWER:
        return <LegalComplianceFormDrawer />;
      case LEGAL_COMPLIANCE_DETAIL_DRAWER:
        return <LegalComplianceDetailDrawer />;
      case VENDOR_FORM_DRAWER:
        return <VendorFormDrawer />;
      case VENDOR_DETAIL_DRAWER:
        return <VendorDetailDrawer />;
      case WORK_SCOPE_FORM_DRAWER:
        return <WorkScopeFormDrawer />;
      case PM_DETAIL_DRAWER:
        return <PmDetailDrawer />;
      case PM_CHECKLIST_FORM_DRAWER:
        return <PmChecklistFormDrawer />;
      case PM_CHECKLIST_DETAIL_DRAWER:
        return <PmChecklistDetailDrawer />;
      case WORK_PERMIT_FORM_DRAWER:
        return <WorkPermitFormDrawer />;
      case WORK_PERMIT_DETAIL_DRAWER:
        return <WorkPermitDetailDrawer />;
      case PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER:
        return <PtwTemplateDrawerForm />;
      case PERMIT_TO_WORK_FORM_DRAWER:
        return <PtwFormDrawer />;
      case PERMIT_TO_WORK_DETAIL_DRAWER:
        return <PtwDetailDrawer />;
      case ASSET_TYPE_FORM_DRAWER:
        return <AssetTypeFormDrawer />;
      case WORK_REQUEST_FORM_DRAWER:
        return <WorkRequestFormDrawer />;
      case WORK_REQUEST_DETAIL_DRAWER:
        return <WorkRequestDetailDrawer />;
      case WORK_ORDER_TYPE_FORM_DRAWER:
        return <WorkOrderTypeFormDrawer />;
      case TNB_BILL_FORM_DRAWER:
        return <TnbBillFormDrawer />;
      case TNB_BILL_DETAIL_DRAWER:
        return <TnbBillDetailDrawer />;
      case ESCALATION_FORM_DRAWER:
        return <EscalationFormDrawer />;
      case ESCALATION_DETAIL_DRAWER:
        return <EscalationDetailDrawer />;
      case PM_TEMPLATE_DRAWER:
        return <PmTemplateDetailDrawer />;
      case PM_TEMPLATE_FORM_DRAWER:
        return <PmTemplateFormDrawer />;
      case BIM_DRAWER:
        return <BimDrawer />;
      case INVOICE_FORM_DRAWER:
        return <InvoiceFormDrawer />;
      case PERMIT_TO_WORK_TEMPLATE_DETAIL_DRAWER:
        return <PtwTemplateDetailDrawer />;
      case MAN_HOUR_SETTING_FORM_DRAWER:
        return <ManHourSettingFormDrawer />;
      case PDF_SETTING_DRAWER:
        return <PdfSettingDrawer />;

      // Filter Drawers
      case WORK_REQUEST_FILTER_DRAWER:
        return <WorkRequestFilterDrawer />;
      case WORK_REQUEST_SORT_DRAWER:
        return <WorkRequestSortDrawer />;
      case WORK_ORDER_FILTER_DRAWER:
        return <WorkOrderFilterDrawer />;
      case PM_FILTER_DRAWER:
        return <PmFilterDrawer />;
      case VENDOR_FILTER_DRAWER:
        return <VendorFilterDrawer />;
      case INVENTORY_FILTER_DRAWER:
        return <InventoryFilterDrawer />;
      case WORK_ORDER_SCHEDULER_FILTER_DRAWER:
        return <WorkOrderSchedulerFilterDrawer />;
      case NOTIFICATION_DRAWER:
        return <NotificationDrawer />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (drawerDetail) {
      setIsOpenDrawer(true);
    } else {
      setIsOpenDrawer(false);
    }
  }, [drawerDetail, setIsOpenDrawer]);

  return (
    <Sheet
      open={isOpenDrawer}
      onOpenChange={(open) => {
        if (!open) {
          // Clear all searchparam
          setSearchParams((param) => {
            // Only special case for pm, cause got drill down search param
            if (drawerDetail === PM_DETAIL_DRAWER) {
              param.delete("drawerType");
              param.delete("drawerState");
              param.delete("locationId");
              param.delete("assetId");
              return param;
            }

            if (drawerDetail === ASSET_DETAIL_DRAWER) {
              param.delete("drawerType");
              param.delete("drawerState");
              param.delete("assetId");
              param.delete("assetDetailTabIdx");
              return param;
            }

            return new URLSearchParams();
          });
        }
      }}
    >
      <SheetContent
        onEscapeKeyDown={(e) => {
          e.preventDefault();
        }}
        className={cn(" pt-9 pb-4 overflow-y-auto sm:max-w-2xl", {
          "min-w-[1000px]":
            drawerDetail === WORK_ORDER_DETAIL_DRAWER ||
            drawerDetail === INVENTORY_DETAIL_DRAWER ||
            drawerDetail === INVENTORY_LEDGER_ITEM_DETAIL_DRAWER,
        })}
      >
        {/* Added empty sheet title and description.. to see how to migrate to properly aria set sheet content */}
        <SheetTitle></SheetTitle>
        <SheetDescription></SheetDescription>
        {drawerContent()}
      </SheetContent>
    </Sheet>
  );
}
