/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type CustomCsvType = (typeof CustomCsvType)[keyof typeof CustomCsvType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomCsvType = {
  WORK_ORDER: "WORK_ORDER",
  EQUIPMENT: "EQUIPMENT",
} as const;
