import {GetOneWorkOrderResponseDto} from "../redux/slices/OpenApi/cerevApi";

interface useIsCloseWorkOrderProps {
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function useIsCloseWorkOrder({
  workOrder,
}: useIsCloseWorkOrderProps) {
  // To change this hook in case the name for Close status changes
  if(!workOrder) return false;
  return workOrder.defectStatus.name === "Close";
}
