import { ColumnDef, Row } from "@tanstack/react-table";
import { TnbReadingWithAttachment } from "../../../../redux/slices/OpenApi/cerevApi";
import useDate from "../../../../hooks/useDate";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_UPDATE_STATE,
  TNB_BILL_DETAIL_DRAWER,
  TNB_BILL_FORM_DRAWER,
} from "../../../../components/drawers/AppDrawer";

const MonthYearTnbCell = ({ row }: { row: Row<TnbReadingWithAttachment> }) => {
  const { formatDateToLocal } = useDate();
  const [searchParam, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("tnbReadingId", row.getValue("id"));
          p.set("drawerType", TNB_BILL_DETAIL_DRAWER);
          // p.set("drawerState", DRAWER_UPDATE_STATE);
          return p;
        });
      }}
    >
      {formatDateToLocal({
        dateStr: row.getValue("billMonth"),
        formatting: "MMM yyyy",
      })}
    </div>
  );
};

export const tnbBillColumns: ColumnDef<TnbReadingWithAttachment>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "billMonth",
    header: "Month Year",
    cell: ({ row }) => <MonthYearTnbCell row={row} />,
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "kiloWattMd",
    header: "Maximum Demand (kW)",
  },
  {
    accessorKey: "kiloWattHr",
    header: "Energy Usage (kWh)",
  },
  {
    accessorKey: "totalBillCost",
    header: "Total Charges (RM)",
  },
];
