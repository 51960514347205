import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useSelectedSettingProjectId } from "../../../../stores/settingStore/settingStore";
import { useUserControllerGetOneUser } from "../../../../api/users/users";
import { usePolicyControllerCheckOneUserPolicy, usePolicyControllerUpdateUserPolicy } from "../../../../api/policy/policy";
import { PolicyControllerCheckOneUserPolicyPolicyName, UpdateUserPolicyRequestDtoPolicyStatus } from "../../../../api/model";
import { Card, CardDescription, CardHeader, CardTitle } from "../../../ui/card";
import { Loader2 } from "lucide-react";
import { Switch } from "../../../ui/switch";

export default function PrivateAttachmentAccessPolicy(){
    const qc = useQueryClient();
    const [searchParams] = useSearchParams();
    const selectedProjId = useSelectedSettingProjectId();
    const firebaseId = searchParams.get("firebaseId");
    const { data: user } = useUserControllerGetOneUser(firebaseId ?? "", {
      query: {
        enabled: !!firebaseId,
        select: (res) => res.data,
      },
    });
    const { data: policy } = usePolicyControllerCheckOneUserPolicy(
      user?.id?.toString() ?? "",
      {
        policyName:
          PolicyControllerCheckOneUserPolicyPolicyName.VIEW_PRIVATE_ATTACHMENT,
        projectId: selectedProjId ?? "",
      },
      {
        query: {
          enabled: !!selectedProjId,
          select: (res) => res.data.data,
        },
      }
    );
  
    const { mutate, isPending } = usePolicyControllerUpdateUserPolicy({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("policy"),
          });
        },
      },
    });
  
    const onCheckChange = (checked: boolean) => {
      mutate({
        userId: user?.id?.toString() ?? "",
        data: {
          projectId: selectedProjId ?? "",
          policyName:
            PolicyControllerCheckOneUserPolicyPolicyName.VIEW_PRIVATE_ATTACHMENT,
          policyStatus: checked
            ? UpdateUserPolicyRequestDtoPolicyStatus.ALLOWED
            : UpdateUserPolicyRequestDtoPolicyStatus.NOT_ALLOWED,
        },
      });
    };
  
    return (
      <Card>
        <div className="flex items-center mr-6">
          <CardHeader className="flex-1 mr-4">
            <CardTitle className="text-lg">View Private Equipment Attachment</CardTitle>
            <CardDescription>
              Allow user to lock and view private attachments for equipments only
            </CardDescription>
          </CardHeader>
          {isPending && (
            <Loader2 className="w-6 h-6 animate-spin text-primary-900 mr-2" />
          )}
          <Switch
            className="data-[state=checked]:bg-primary-900"
            checked={policy === UpdateUserPolicyRequestDtoPolicyStatus.ALLOWED}
            onCheckedChange={onCheckChange}
          />
        </div>
      </Card>
    );
}