import {zodResolver} from "@hookform/resolvers/zod";
import {Typography} from "@mui/material";
import {useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {z} from "zod";
import {useAnalyticControllerCreateAnalyticMutation} from "../../redux/slices/OpenApi/cerevApi";
import {setOpenSnackBar, SnackBarType} from "../../redux/slices/RootSlice";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import AppButton from "../AppButton";
import AppTextField from "../AppTextField";

const AnalyticFormSchema = z.object({
  tabName: z.string().min(1, {message: "Please enter a tab name"}),
  dashboardUrl: z.string().min(1, {message: "Please enter a dashboard url"}),
  dashboardHeight: z
    .string()
    .min(1, {message: "Please enter a dashboard height"}),
  dashboardNo: z.string().min(1, {message: "Please enter a dashboard no."}),
});

export type AnalyticForm = z.infer<typeof AnalyticFormSchema>;

export default function AnalyticFormDrawer() {
  const dispatch = useAppDispatch();
  const [searchParam, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<AnalyticForm>({
    resolver: zodResolver(AnalyticFormSchema),
  });

  const [
    createAnalytic,
    {isLoading, isError: createAnalyticIsError, isSuccess},
  ] = useAnalyticControllerCreateAnalyticMutation();

  const onSubmit: SubmitHandler<AnalyticForm> = (data) => {
    if (!activeProj) return;
    createAnalytic({
      createMetabaseAnalyticDto: {
        projectId: activeProj.id,
        tabName: data.tabName,
        dashboardUrl: data.dashboardUrl,
        dashboardHeight: +data.dashboardHeight,
        dashboardNo: +data.dashboardNo,
        params: {}, // Empty for now, to be used in the future
      },
    });
  };

  // IsSuccess close dialog
  useEffect(() => {
    if (isSuccess) {
      setSearchParams((param) => {
        param.delete("drawerType");
        param.delete("drawerState");
        return {...param};
      });
    }
  }, [isSuccess, setSearchParams]);

  // isError
  useEffect(() => {
    if (createAnalyticIsError) {
      dispatch(
        setOpenSnackBar({type: SnackBarType.Error, msg: "Something went wrong"})
      );
    }
  }, [createAnalyticIsError]);

  return (
    <div className="flex flex-col gap-4">
      <p className="font-sans text-2xl font-bold">
        Create Analytic
      </p>
      <AppTextField
        label="Tab name"
        inputProps={{...register("tabName")}}
        error={!!errors.tabName?.message}
        helperText={
          errors.tabName?.message !== "" ? errors.tabName?.message : ""
        }
      />
      <AppTextField
        label="Dashboard No"
        inputProps={{...register("dashboardNo")}}
        error={!!errors.dashboardNo?.message}
        helperText={
          errors.dashboardNo?.message !== "" ? errors.dashboardNo?.message : ""
        }
      />
      <AppTextField
        label="Dashboard Height"
        inputProps={{...register("dashboardHeight")}}
        error={!!errors.dashboardHeight?.message}
        helperText={
          errors.dashboardHeight?.message !== ""
            ? errors.dashboardHeight?.message
            : ""
        }
      />
      <AppTextField
        label="Dashboard Url"
        inputProps={{...register("dashboardUrl")}}
        error={!!errors.dashboardUrl?.message}
        helperText={
          errors.dashboardUrl?.message !== ""
            ? errors.dashboardUrl?.message
            : ""
        }
      />
      <AppButton
        label="Submit"
        onClick={handleSubmit(onSubmit)}
        loading={isLoading}
      />
    </div>
  );
}
