import { Controller } from "react-hook-form";
import AppTextField from "../../../AppTextField";
import { FormLabel } from "../../../ui/form";
import { Toggle } from "../../../ui/toggle";

export default function PmTemplateMonthField() {
  return (
    <div className="grid grid-cols-2 items-center mt-4">
      <div>Repeat every</div>
      <div className="flex gap-4 items-center">
        <AppTextField
          name="frequencySetting.monthlyEveryStepCount"
          className="flex-1 w-full"
          type="number"
        />
        <div className="mb-2">months</div>
      </div>
      <div className="grid grid-cols-7 col-span-2 gap-2 border border-slate-300 rounded-md my-4 p-4">
        <Controller
          name="frequencySetting.monthlyRepeatDate"
          render={({ field: { onChange, value }, fieldState: { error }  }) => (
            <>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                <Toggle
                  pressed={value?.includes(day.toString()) || false}
                  onClick={() => {
                    onChange([day.toString()]);
                    // if (value?.includes(day.toString())) {
                    //   onChange(
                    //     value?.filter((v: string) => v !== day.toString())
                    //   );
                    // } else {
                    //   onChange([...(value || []), day.toString()]);
                    // }
                  }}
                  key={day.toString()}
                >
                  {day}
                </Toggle>
              ))}
              {error?.message && (
                <div className="text-red-500 font-sans text-sm font-medium col-span-7">
                  {error?.message}
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
}
