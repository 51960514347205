import { useEffect } from "react";
import { useWorkRequestFilterStore } from "../../../stores/workRequest/workRequestFilterStore";
import AppSearchAndFilter from "../../../components/searchAndFilter/AppSearchAndFilter";
import { useSearchParams } from "react-router-dom";
import { WORK_REQUEST_FILTER_DRAWER } from "../../../components/drawers/AppDrawer";

export default function WRSearchAndFilter() {
  const [, setSearchParams] = useSearchParams();
  const {  getFilterCount, cleanUp } =
    useWorkRequestFilterStore((state) => state.actions);
//   const filterLoc = useWorkRequestFilterStore((state) => state.filterLocation);

  useEffect(() => {
    // getFiltersFromSearch();
    return () => {
      cleanUp();
    };
  }, []);

  const filCount = getFilterCount();

  return (
    <AppSearchAndFilter
      onFilterClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", WORK_REQUEST_FILTER_DRAWER);
          return p;
        });
      }}
      placeholder="Title, Email, Contact No."
      filterCount={filCount}
    />
  );
}
