import React from "react";
import { useBimEnabled, useFeatures } from "../hooks/useFeatures";
import DrawerFormSkeleton from "../components/skeletons/DrawerFormSkeleton";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/store";

export default function BimFeatureProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { BIM, isLoading } = useFeatures({
    projectId: activeProj?.id?.toString() ?? "",
  });

  if (isLoading) return <DrawerFormSkeleton />;

  if (BIM) return <>{children}</>;

  return <Navigate to="/home/403" replace />;
}
