/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AddPmTemplateDto,
  GetOnePmTemplateResponseDto,
  PmTemplateControllerGetPmTemplate200Item,
  PmTemplateControllerGetPmTemplateParams,
  PmTemplateControllerGetPmTemplateTableParams,
  PmTemplateResponseDto,
  UpdatePmTemplateDto,
} from ".././model";

export const pmTemplateControllerGetPmTemplate = (
  params: PmTemplateControllerGetPmTemplateParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PmTemplateControllerGetPmTemplate200Item[]>> => {
  return axios.get(`/pm-template`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmTemplateControllerGetPmTemplateQueryKey = (
  params: PmTemplateControllerGetPmTemplateParams
) => {
  return [`/pm-template`, ...(params ? [params] : [])] as const;
};

export const getPmTemplateControllerGetPmTemplateInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
    PmTemplateControllerGetPmTemplateParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
        QueryKey,
        PmTemplateControllerGetPmTemplateParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerGetPmTemplateQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
    QueryKey,
    PmTemplateControllerGetPmTemplateParams["cursorId"]
  > = ({ signal, pageParam }) =>
    pmTemplateControllerGetPmTemplate(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
    TError,
    TData,
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
    QueryKey,
    PmTemplateControllerGetPmTemplateParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerGetPmTemplateInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>
>;
export type PmTemplateControllerGetPmTemplateInfiniteQueryError =
  AxiosError<unknown>;

export const usePmTemplateControllerGetPmTemplateInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
    PmTemplateControllerGetPmTemplateParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
        QueryKey,
        PmTemplateControllerGetPmTemplateParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmTemplateControllerGetPmTemplateInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmTemplateControllerGetPmTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerGetPmTemplateQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>
  > = ({ signal }) =>
    pmTemplateControllerGetPmTemplate(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerGetPmTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>
>;
export type PmTemplateControllerGetPmTemplateQueryError = AxiosError<unknown>;

export const usePmTemplateControllerGetPmTemplate = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmTemplateControllerGetPmTemplateQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmTemplateControllerAddPmTemplate = (
  addPmTemplateDto: AddPmTemplateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/pm-template`, addPmTemplateDto, options);
};

export const getPmTemplateControllerAddPmTemplateMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmTemplateControllerAddPmTemplate>>,
    TError,
    { data: AddPmTemplateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof pmTemplateControllerAddPmTemplate>>,
  TError,
  { data: AddPmTemplateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pmTemplateControllerAddPmTemplate>>,
    { data: AddPmTemplateDto }
  > = (props) => {
    const { data } = props ?? {};

    return pmTemplateControllerAddPmTemplate(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PmTemplateControllerAddPmTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerAddPmTemplate>>
>;
export type PmTemplateControllerAddPmTemplateMutationBody = AddPmTemplateDto;
export type PmTemplateControllerAddPmTemplateMutationError =
  AxiosError<unknown>;

export const usePmTemplateControllerAddPmTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmTemplateControllerAddPmTemplate>>,
    TError,
    { data: AddPmTemplateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof pmTemplateControllerAddPmTemplate>>,
  TError,
  { data: AddPmTemplateDto },
  TContext
> => {
  const mutationOptions =
    getPmTemplateControllerAddPmTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const pmTemplateControllerGetPmTemplateTable = (
  params: PmTemplateControllerGetPmTemplateTableParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PmTemplateResponseDto>> => {
  return axios.get(`/pm-template/fullTable`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPmTemplateControllerGetPmTemplateTableQueryKey = (
  params: PmTemplateControllerGetPmTemplateTableParams
) => {
  return [`/pm-template/fullTable`, ...(params ? [params] : [])] as const;
};

export const getPmTemplateControllerGetPmTemplateTableInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
    PmTemplateControllerGetPmTemplateTableParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateTableParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
        QueryKey,
        PmTemplateControllerGetPmTemplateTableParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerGetPmTemplateTableQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
    QueryKey,
    PmTemplateControllerGetPmTemplateTableParams["cursorId"]
  > = ({ signal, pageParam }) =>
    pmTemplateControllerGetPmTemplateTable(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
    TError,
    TData,
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
    QueryKey,
    PmTemplateControllerGetPmTemplateTableParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerGetPmTemplateTableInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>
  >;
export type PmTemplateControllerGetPmTemplateTableInfiniteQueryError =
  AxiosError<unknown>;

export const usePmTemplateControllerGetPmTemplateTableInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
    PmTemplateControllerGetPmTemplateTableParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateTableParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
        TError,
        TData,
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
        QueryKey,
        PmTemplateControllerGetPmTemplateTableParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmTemplateControllerGetPmTemplateTableInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmTemplateControllerGetPmTemplateTableQueryOptions = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateTableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerGetPmTemplateTableQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>
  > = ({ signal }) =>
    pmTemplateControllerGetPmTemplateTable(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerGetPmTemplateTableQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>
>;
export type PmTemplateControllerGetPmTemplateTableQueryError =
  AxiosError<unknown>;

export const usePmTemplateControllerGetPmTemplateTable = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
  TError = AxiosError<unknown>
>(
  params: PmTemplateControllerGetPmTemplateTableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetPmTemplateTable>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmTemplateControllerGetPmTemplateTableQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmTemplateControllerGetOnePmTemplate = (
  pmTemplateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetOnePmTemplateResponseDto>> => {
  return axios.get(`/pm-template/${pmTemplateId}`, options);
};

export const getPmTemplateControllerGetOnePmTemplateQueryKey = (
  pmTemplateId: string
) => {
  return [`/pm-template/${pmTemplateId}`] as const;
};

export const getPmTemplateControllerGetOnePmTemplateInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>
  >,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerGetOnePmTemplateQueryKey(pmTemplateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>
  > = ({ signal }) =>
    pmTemplateControllerGetOnePmTemplate(pmTemplateId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmTemplateId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerGetOnePmTemplateInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>>;
export type PmTemplateControllerGetOnePmTemplateInfiniteQueryError =
  AxiosError<unknown>;

export const usePmTemplateControllerGetOnePmTemplateInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>
  >,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmTemplateControllerGetOnePmTemplateInfiniteQueryOptions(
      pmTemplateId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmTemplateControllerGetOnePmTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerGetOnePmTemplateQueryKey(pmTemplateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>
  > = ({ signal }) =>
    pmTemplateControllerGetOnePmTemplate(pmTemplateId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmTemplateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerGetOnePmTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>
>;
export type PmTemplateControllerGetOnePmTemplateQueryError =
  AxiosError<unknown>;

export const usePmTemplateControllerGetOnePmTemplate = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerGetOnePmTemplate>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmTemplateControllerGetOnePmTemplateQueryOptions(
    pmTemplateId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const pmTemplateControllerUpdatePmTemplate = (
  pmTemplateId: string,
  updatePmTemplateDto: UpdatePmTemplateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.patch(
    `/pm-template/${pmTemplateId}`,
    updatePmTemplateDto,
    options
  );
};

export const getPmTemplateControllerUpdatePmTemplateMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmTemplateControllerUpdatePmTemplate>>,
    TError,
    { pmTemplateId: string; data: UpdatePmTemplateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof pmTemplateControllerUpdatePmTemplate>>,
  TError,
  { pmTemplateId: string; data: UpdatePmTemplateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pmTemplateControllerUpdatePmTemplate>>,
    { pmTemplateId: string; data: UpdatePmTemplateDto }
  > = (props) => {
    const { pmTemplateId, data } = props ?? {};

    return pmTemplateControllerUpdatePmTemplate(
      pmTemplateId,
      data,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PmTemplateControllerUpdatePmTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerUpdatePmTemplate>>
>;
export type PmTemplateControllerUpdatePmTemplateMutationBody =
  UpdatePmTemplateDto;
export type PmTemplateControllerUpdatePmTemplateMutationError =
  AxiosError<unknown>;

export const usePmTemplateControllerUpdatePmTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmTemplateControllerUpdatePmTemplate>>,
    TError,
    { pmTemplateId: string; data: UpdatePmTemplateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof pmTemplateControllerUpdatePmTemplate>>,
  TError,
  { pmTemplateId: string; data: UpdatePmTemplateDto },
  TContext
> => {
  const mutationOptions =
    getPmTemplateControllerUpdatePmTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const pmTemplateControllerDeletePmTemplate = (
  pmTemplateId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/pm-template/${pmTemplateId}`, options);
};

export const getPmTemplateControllerDeletePmTemplateMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmTemplateControllerDeletePmTemplate>>,
    TError,
    { pmTemplateId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof pmTemplateControllerDeletePmTemplate>>,
  TError,
  { pmTemplateId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pmTemplateControllerDeletePmTemplate>>,
    { pmTemplateId: number }
  > = (props) => {
    const { pmTemplateId } = props ?? {};

    return pmTemplateControllerDeletePmTemplate(pmTemplateId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PmTemplateControllerDeletePmTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerDeletePmTemplate>>
>;

export type PmTemplateControllerDeletePmTemplateMutationError =
  AxiosError<unknown>;

export const usePmTemplateControllerDeletePmTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pmTemplateControllerDeletePmTemplate>>,
    TError,
    { pmTemplateId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof pmTemplateControllerDeletePmTemplate>>,
  TError,
  { pmTemplateId: number },
  TContext
> => {
  const mutationOptions =
    getPmTemplateControllerDeletePmTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const pmTemplateControllerExportPmChecklistCsv = (
  pmTemplateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/pm-template/${pmTemplateId}/export-csv`, options);
};

export const getPmTemplateControllerExportPmChecklistCsvQueryKey = (
  pmTemplateId: string
) => {
  return [`/pm-template/${pmTemplateId}/export-csv`] as const;
};

export const getPmTemplateControllerExportPmChecklistCsvInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>
  >,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerExportPmChecklistCsvQueryKey(pmTemplateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>
  > = ({ signal }) =>
    pmTemplateControllerExportPmChecklistCsv(pmTemplateId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmTemplateId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerExportPmChecklistCsvInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>
  >;
export type PmTemplateControllerExportPmChecklistCsvInfiniteQueryError =
  AxiosError<unknown>;

export const usePmTemplateControllerExportPmChecklistCsvInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>
  >,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmTemplateControllerExportPmChecklistCsvInfiniteQueryOptions(
      pmTemplateId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmTemplateControllerExportPmChecklistCsvQueryOptions = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmTemplateControllerExportPmChecklistCsvQueryKey(pmTemplateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>
  > = ({ signal }) =>
    pmTemplateControllerExportPmChecklistCsv(pmTemplateId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!pmTemplateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmTemplateControllerExportPmChecklistCsvQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>
>;
export type PmTemplateControllerExportPmChecklistCsvQueryError =
  AxiosError<unknown>;

export const usePmTemplateControllerExportPmChecklistCsv = <
  TData = Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
  TError = AxiosError<unknown>
>(
  pmTemplateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof pmTemplateControllerExportPmChecklistCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPmTemplateControllerExportPmChecklistCsvQueryOptions(
    pmTemplateId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
