import { Banknote, CandlestickChart, Receipt } from "lucide-react";
import { Card, CardContent } from "../../../../../ui/card";

export default function SummarySection() {
  return (
    <div className="space-y-2">
      <h3 className="text-xl font-semibold">Summary</h3>
      <div className="grid grid-cols-3 gap-4">
        <Card>
          <CardContent className="py-4 flex gap-6 items-center">
            <CandlestickChart className="text-primary-900 h-10 w-10" />
            <div className="flex flex-col">
              <p className="text-2xl text-green-600">RM 1,000</p>
              <p className="text-sm text-slate-400">Net Profit</p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="py-4 flex gap-6 items-center">
            <Receipt className="text-primary-900 h-10 w-10" />
            <div className="flex flex-col">
              <p className="text-2xl">RM 1,000</p>
              <p className="text-sm text-slate-400">Cost</p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="py-4 flex gap-6 items-center">
            <Banknote className="text-primary-900 h-10 w-10" />
            <div className="flex flex-col">
              <p className="text-2xl">RM 1,000</p>
              <p className="text-sm text-slate-400">Revenue</p>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
