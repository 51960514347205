import { TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import { ANALYTIC_FORM_DRAWER } from "../../../components/drawers/AppDrawer";
import AppTable from "../../../components/table/AppTable";
import { useAnalyticControllerGetAnalyticsSettingsQuery } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Search as SearchIcon } from "@mui/icons-material";
import { setOpenSnackBar, SnackBarType } from "../../../redux/slices/RootSlice";
import { useEffect } from "react";

export default function AnalyticsTab() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProject = useAppSelector((state) => state.root.activeProject);

  const {
    data: analytics,
    isLoading,
    isError,
  } = useAnalyticControllerGetAnalyticsSettingsQuery(
    {
      projectId: activeProject?.id ?? 0,
    },
    {
      skip: !activeProject,
    }
  );

  useEffect(() => {
    if (isError)
      dispatch(
        setOpenSnackBar({
          type: SnackBarType.Error,
          msg: "Something went wrong",
        })
      );
  }, [isError]);

  return (
    <AppTable
      isEmpty={analytics?.length === 0}
      colSpan={4}
      isLoading={isLoading}
      headerTableRow={
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>
              <div className="flex flex-row-reverse">
                {/* <TextField
                    label="Search"
                    size="small"
                    InputProps={{
                      ...register("search"),
                      endAdornment: <SearchIcon />,
                      className: "rounded-lg",
                    }}
                  /> */}
                <AppButton
                  onClick={() => {
                    setSearchParams((p) => {
                      p.set("drawerType", ANALYTIC_FORM_DRAWER);
                      p.set("drawerState", "CREATE");
                      return p;
                    });
                  }}
                  label="+ Add Analytics"
                  className="h-10"
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Height</TableCell>
            <TableCell>Dashboard No.</TableCell>
          </TableRow>
        </TableHead>
      }
      bodyTableRow={analytics?.map((a) => (
        <TableRow key={a.id}>
          <TableCell>{a.id}</TableCell>
          <TableCell>{a.tabName}</TableCell>
          <TableCell>{a.dashboardHeight}</TableCell>
          <TableCell>{a.dashboardNo}</TableCell>
        </TableRow>
      ))}
    />
  );
}
