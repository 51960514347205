import { FormProvider, useForm, useFormContext } from "react-hook-form";
import AppSearchAndFilter from "../../../components/searchAndFilter/AppSearchAndFilter";
import { set } from "lodash";
import { useSearchParams } from "react-router-dom";
import { WORK_ORDER_SCHEDULER_FILTER_DRAWER } from "../../../components/drawers/AppDrawer";
import { useWorkOrderScheduleFilterStore } from "../../../stores/workOrderSchedule/workOrderScheduleFilterStore";
import { useEffect } from "react";

export default function WOSchedulerFilter() {
  const [, setSearchParams] = useSearchParams();
  const getFilterCount = useWorkOrderScheduleFilterStore(
    (state) => state.getFilterCount
  );

  const cleanUp = useWorkOrderScheduleFilterStore((state) => state.cleanUp);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  return (
    <AppSearchAndFilter
      onFilterClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", WORK_ORDER_SCHEDULER_FILTER_DRAWER);
          return p;
        });
      }}
      placeholder="Subject, Description, Ref No."
      filterCount={getFilterCount()}
    />
  );
}
