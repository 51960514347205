import { useStocksControllerGetOneStockWithLedger } from "@/api/stocks-inventory/stocks-inventory";
import AppButton from "@/components/AppButton";
import DialogContentWithSearchTable from "@/components/dialogs/AppSelectWithDialog/components/DialogContentWithSearchTable";
import { inventorySerialColumns } from "@/components/drawers/inventory/components/columns/inventorySerialColumns";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function SerialNumberViewBtn() {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");

  const { data: inventory } = useStocksControllerGetOneStockWithLedger(
    inventoryId ?? "",
    {
      query: {
        enabled: !!inventoryId,
        select: (res) => res.data,
      },
    }
  );

  return (
    <>
      <AppButton
        variant={`outline`}
        label={`View Serial No.`}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={(o) => setOpen(o)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Serial Numbers</DialogTitle>
            <DialogDescription>
              List of serial number for the inventory
            </DialogDescription>
          </DialogHeader>
          <DialogContentWithSearchTable
            items={
              inventory?.serials?.map((s) => ({
                id: s.serial,
                name: s.serial,
                unitPricing: s.unitPricing,
              })) ?? []
            }
            columns={inventorySerialColumns}
            closeDialog={() => {
              setOpen(false);
            }}
            showRowSelectionStr={false}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
