import { useSearchParams } from "react-router-dom";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import AssetManagementTab from "../tabs/AssetManagementTab";
import AssetTypeTab from "../tabs/AssetTypeTab";

export default function LocationBasedView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "assetScreenIdx",
    defaultValue: "0",
  });
  return (
    <Tabs
      value={currentTabIdx}
      onValueChange={onTabChange}
      className="flex-grow"
    >
      <TabsList className="mb-2">
        <TabsTrigger value={"0"}>
          <p>Equipment List</p>
        </TabsTrigger>
        <TabsTrigger value={"1"}>
          <p>Equipment Types</p>
        </TabsTrigger>
      </TabsList>
      <TabsContent value={"0"}>
        <AssetManagementTab />
      </TabsContent>
      <TabsContent value={"1"}>
        <AssetTypeTab />
      </TabsContent>
    </Tabs>
  );
}
