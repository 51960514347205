import {
  FieldValue,
  FieldValues,
  FormProvider,
  Path,
  PathValue,
  useForm,
  useWatch,
} from "react-hook-form";
import { Base } from "../AppSelectWithDialog";
import { ReactNode } from "react";
import AppTextField from "../../../AppTextField";
import CheckBox from "@mui/material/Checkbox";

// This component is broken down, so that it can insert
// react hook form search form in it.

interface DialogContentWithSearchProps<
  T extends FieldValues = FieldValues,
  B extends Base = { id: 1; name: "" }
> {
  items: B[];
  value: PathValue<T, Path<T>>;
  onChange: (...event: any[]) => void;
  setOpen: (open: boolean) => void;
  onOptionsRender: (item: B, idx: number) => ReactNode;
}

export default function DialogContentWithSearch<
  T extends FieldValues,
  B extends Base
>({
  items,
  value,
  onChange,
  setOpen,
  onOptionsRender,
}: DialogContentWithSearchProps<T, B>) {
  const methods = useForm<{ search: string }>();
  const search = useWatch({ control: methods.control, name: "search" });
  const isArray = Array.isArray(value);

  const isSelected = (id: string | number | undefined) => {
    if (!id) return false;
    if (Array.isArray(value)) {
      return value.map((v: Base) => v.id).includes(id);
    } else {
      return (value as Base).id === id;
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <AppTextField
          label="Search"
          name="search"
          inputProps={{ ...methods.register("search") }}
        />
        <div className="rounded-xl bg-neutral-300 flex flex-col">
          {items
            .filter((itm) => {
              if (!Array.isArray(value) && value) {
                return itm.id !== (value as Base).id;
              }
              return itm;
            })
            .filter((itm) => {
              if (search) {
                return (
                  itm.name?.toLowerCase().includes(search.toLowerCase()) ||
                  itm.title?.toLowerCase().includes(search.toLowerCase())
                );
              } else {
                return true;
              }
            })
            .map((it, index) => (
              <div
                className="p-4 font-sans hover:cursor-pointer hover:bg-neutral-400 rounded-xl"
                key={it.id}
                onClick={() => {
                  if (Array.isArray(value)) {
                    if (isSelected(it.id)) {
                      onChange(value.filter((j: Base) => j !== it.id));
                    } else {
                      onChange([...value, it]);
                    }
                  } else {
                    onChange(it);
                    setOpen(false);
                  }
                }}
              >
                {isArray ? (
                  <div className="flex gap-4 items-center">
                    <CheckBox
                      className="text-primary-900"
                      checked={isSelected(it.id)}
                    />
                    {onOptionsRender(it, index)}
                  </div>
                ) : (
                  <>{onOptionsRender(it, index)}</>
                )}
              </div>
            ))}
        </div>
      </div>
    </FormProvider>
  );
}
