import AppButton from "../../components/AppButton";
import AppTable from "../../components/table/AppTable";
import {
  useLazyWorkPermitsControllerGetWorkPermitQrCodeRequestQuery,
  useWorkPermitsControllerGetWorkPermitCountQuery,
  useWorkPermitsControllerGetWorkPermitsQuery,
} from "../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_CREATE_STATE,
  DRAWER_VIEW_STATE,
  WORK_PERMIT_DETAIL_DRAWER,
  WORK_PERMIT_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { TabPanel } from "../DashboardScreen/components/AppTabPanel";
import WorkPermitTab from "./tabs/WorkPermitTab";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import { QrCode } from "lucide-react";
import { WORK_PERMIT_STASUSES } from "../../constants/workPermitConstants";
import WorkPermitTabLabel from "./components/WorkPermitTabLabel";
import { FormProvider, useForm } from "react-hook-form";
import AppTextField from "../../components/AppTextField";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { CerevModule } from "../../api/model";

export default function WorkPermitScreen() {
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "workPermitScreenTabIdx",
    defaultValue: "0",
  });
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const [trigger, result] =
    useLazyWorkPermitsControllerGetWorkPermitQrCodeRequestQuery();

  return (
    <ModuleAccessGuard module={CerevModule.WORK_PERMIT}>
      <div className="flex gap-4 justify-between">
        <div className="flex gap-4">
          <AppTitle title="Work Permits" />
          <AppButton
            label="+ New"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", WORK_PERMIT_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
        <AppButton
          variant="outline"
          label="Print QR Code"
          icon={<QrCode />}
          isLoading={result.isLoading}
          onClick={async () => {
            const results = await trigger({
              projectId: activeProj?.id?.toString() ?? "-",
            });

            window.open(results.data?.signedUrl, "_blank");
          }}
        />
      </div>
      <FormProvider {...methods}>
        <div className="flex flex-col gap-2">
          <AppTextField
            name="search"
            className="w-[400px]"
            placeholder="Search.."
          />
          <Tabs
            value={currentTabIdx}
            onValueChange={onTabChange}
            className="mb-4"
          >
            <TabsList className="mb-4">
              {WORK_PERMIT_STASUSES.map((sta, idx) => (
                <TabsTrigger key={sta.name} value={idx.toString()}>
                  <WorkPermitTabLabel workPermitStatus={sta} />
                </TabsTrigger>
              ))}
            </TabsList>
            <TabsContent value={"0"}>
              <WorkPermitTab status="NEW" />
            </TabsContent>
            <TabsContent value={"1"}>
              <WorkPermitTab status="APPROVED" />
            </TabsContent>
            <TabsContent value={"2"}>
              <WorkPermitTab status="REJECTED" />
            </TabsContent>
          </Tabs>
        </div>
      </FormProvider>
    </ModuleAccessGuard>
  );
}
