import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import AppTextField from "../../../../../components/AppTextField";
import { DataTable } from "../../../../../components/react-table/DataTable";
import useCommonTable from "../../../../../hooks/useCommonTable";
import { useProjectControllerFindAllProjects } from "../../../../../api/projects/projects";
import { projectColumns } from "../../CompanyProjectListScreen/CompanyDetailScreen/projectColumns";
import useCurrentUser from "../../../../../hooks/useCurrentUser/useCurrentUser";
import useDebounce from "../../../../../hooks/useDebounce";
import { ProjectControllerFindAllProjectsParams } from "../../../../../api/model";

export default function ProjectTableContent() {
  const { data: user } = useCurrentUser();

  const { pagination, setPagination, search } = useCommonTable();

  const queryArg = useDebounce<ProjectControllerFindAllProjectsParams>({
    value: {
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
    },
  });

  const {
    data: projects,
    isLoading,
    isFetching,
  } = useProjectControllerFindAllProjects(queryArg, {
    query: {
      enabled: !!user,
    },
  });

  const table = useReactTable({
    data: projects?.data?.data ?? [],
    columns: projectColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: projects?.data?.totalCount
      ? Math.ceil(projects?.data?.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <>
      <AppTextField
        name="search"
        className="w-[400px]"
        placeholder="Search.."
      />
      <DataTable
        isLoading={isLoading}
        isFetching={isFetching}
        table={table}
        totalCount={projects?.data?.totalCount ?? 0}
        rowsPerPage={pagination.pageSize}
        pageIdx={pagination.pageIndex}
      />
    </>
  );
}
