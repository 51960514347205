import { zodResolver } from "@hookform/resolvers/zod";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import AppTextField from "../AppTextField";
import AppCameraFieldFile from "../AppCameraFieldFiles";
import AppDocumentUpload from "../AppDocumentUpload";
import AppButton from "../AppButton";
import { useGetUserDataQuery } from "../../redux/slices/Auth/AuthApi";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { SnackBarType, setOpenSnackBar } from "../../redux/slices/RootSlice";
import { useSearchParams } from "react-router-dom";
import useSuccess from "../../hooks/useSuccess";
import useError from "../../hooks/useError";
import useIsUpdateDrawerState from "../../hooks/useDrawerState";
import { useEffect, useState } from "react";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import ConfirmDeleteBtnWithDialog from "../dialogs/ConfirmDeleteWithTextDialog";
import { setSelectedLocation } from "../../redux/slices/Asset/AssetSlice";
import AppTextAreaField from "../AppTextAreaField";
import {
  useLocationControllerCreateLocation,
  useLocationControllerDeleteLocation,
  useLocationControllerDeletePhoto,
  useLocationControllerGetOneLocation,
  useLocationControllerUpdateLocation,
} from "../../api/location/location";
import { useQueryClient } from "@tanstack/react-query";
import useAppStorage from "../../hooks/useAppStorage";
import useCurrentUser from "../../hooks/useCurrentUser/useCurrentUser";
import { Attachment } from "../../api/model";

const LocationFormSchema = z.object({
  name: z.string().min(1, { message: "Please input a name" }),
  description: z.string().optional().default(""),
  code: z.string().min(1, { message: "Please input a location code" }),
  attachments: z.instanceof(File).array().optional(),
  documents: z.instanceof(File).array().optional(),
});

export type LocationForm = z.infer<typeof LocationFormSchema>;

export default function LocationFormDrawer() {
  const qc = useQueryClient();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const updateLocationId = searchParams.get("locationId");
  const [uploadedPhotosLoc, setUploadedPhotosLoc] = useState<Attachment[]>([]);
  const [uploadedDocsLoc, setUploadedDocsLoc] = useState<Attachment[]>([]);

  const locationParentId = searchParams.get("parentLocId");
  const methods = useForm<LocationForm>({
    resolver: zodResolver(LocationFormSchema),
    defaultValues: {
      name: "",
      description: "",
      code: "",
      attachments: [],
      documents: [],
    },
  });

  const dispatch = useAppDispatch();

  const { mutate: deletePhoto } = useLocationControllerDeletePhoto();

  const {
    data: editLocation,
    isLoading: editLocationIsLoading,
    isError: editLocationIsError,
    error: editLocationError,
  } = useLocationControllerGetOneLocation(updateLocationId ?? "", {
    query: {
      enabled: !!isUpdateDrawer && !!updateLocationId,
      select: (res) => res.data,
    },
  });

  const { mutateAsync: createLocation } = useLocationControllerCreateLocation();
  const { mutateAsync: updateLocation } = useLocationControllerUpdateLocation();
  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (q) => (q.queryKey[0] as string).includes("location"),
      });
      setSearchParams(new URLSearchParams());
    },
  });

  const { mutate: deleteLocation, isSuccess: deleteLocationIsSuccess } =
    useLocationControllerDeleteLocation({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("location"),
          });
          dispatch(setSelectedLocation(null));
          setSearchParams(new URLSearchParams());
        },
      },
    });

  const { data: user } = useCurrentUser();

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDocsLoc.filter((v) => v.id !== att.id);
    if (!editLocation) return;
    deletePhoto({
      attachmentId: att?.id?.toString() ?? "",
      locationId: editLocation.id.toString(),
    });
    setUploadedDocsLoc(newUploadedDocs);
  };

  const onDeleteUploadedPhoto = async (att: Attachment) => {
    const newUploadedPhotos = uploadedPhotosLoc.filter((v) => v.id !== att.id);
    if (!editLocation) return;
    deletePhoto({
      attachmentId: att?.id?.toString() ?? "",
      locationId: editLocation.id.toString(),
    });
    setUploadedPhotosLoc(newUploadedPhotos);
  };

  const onSubmit: SubmitHandler<LocationForm> = async (data) => {
    if (!activeProj || !user || !activeProj.id) return;

    mutate({
      files: data.attachments ?? [],
      secondFiles: data.documents ?? [],
      mutateAsync: async (att, docs) => {
        if (isUpdateDrawer) {
          return await updateLocation({
            locationId: editLocation?.id.toString() ?? "",
            data: {
              name: data.name,
              parentId: editLocation?.parentId,
              updatedById: user?.id?.toString() ?? "",
              projectId: activeProj?.id ?? 0,
              photos: att ?? [],
              additionalDocuments: docs ?? [],
              locationCode: data.code,
              description: data.description,
            },
          });
        }

        return await createLocation({
          data: {
            parentId: locationParentId ? parseInt(locationParentId) : undefined,
            name: data.name,
            createdById: user?.id ?? 0,
            projectId: activeProj?.id ?? 0,
            photos: att ?? [],
            additionalDocuments: docs ?? [],
            locationCode: data.code,
            description: data.description,
          },
        });
      },
    });
  };

  // isUpdate
  useEffect(() => {
    if (isUpdateDrawer && editLocation) {
      methods.setValue("name", editLocation.name);
      methods.setValue("description", editLocation.description ?? "");
      methods.setValue("code", editLocation.code ?? "");
      setUploadedPhotosLoc(editLocation.photos ?? []);
      setUploadedDocsLoc(editLocation.additionalDocuments ?? []);
    }
  }, [editLocation, isUpdateDrawer]);

  if (editLocationIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Location
        </p>
        <AppTextField label="Name" name="name" />
        <AppTextAreaField label="Description" name="description" />
        <AppTextField label="Location Code" name="code" />
        <Controller
          control={methods.control}
          name="attachments"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFile
                uploadedPhotos={uploadedPhotosLoc}
                onDeleteUploadedPhoto={onDeleteUploadedPhoto}
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.attachments?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
              />
            );
          }}
        />
        <Controller
          control={methods.control}
          name="documents"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUpload
                uploadedDocs={uploadedDocsLoc}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newFiles = value.filter((v) => v !== url);
                  onChange(newFiles);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.attachments?.message !== ""
                    ? "At least one file required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex gap-4">
          <AppButton
            isLoading={isPending}
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
          {isUpdateDrawer && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editLocation?.name ?? "-"}
              onDeleteConfirm={async () => {
                if (editLocation) {
                  deleteLocation({
                    locationId: editLocation.id.toString(),
                  });
                }
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
