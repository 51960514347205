import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import { useLocationControllerGetTopLocations } from "../../../api/location/location";
import { useWorkRequestControllerCreateWorkRequestInternally } from "../../../api/work-request/work-request";
import useAppStorage from "../../../hooks/useAppStorage";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { Attachment } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import AppTextAreaField from "../../AppTextAreaField";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { Checkbox } from "../../ui/checkbox";
import { DRAWER_UPDATE_STATE } from "../AppDrawer";
import WorkRequestSelectAssetDialog from "./components/WorkRequestSelectAssetDialog";

export const InternalWorkRequestSchema = z.object({
  email: z.string().email({ message: "Please input a proper email" }),
  contactNo: z.string().min(6, { message: "Please input your contact number" }),
  location: z.string().min(1, { message: "Please indicate the location" }),
  title: z.string().min(1, { message: "Please add a title" }),
  description: z
    .string()
    .min(1, { message: "Please add a description of the problem" }),
  photos: z.instanceof(File).array().optional(),
  dbLocation: z.object({
    id: z.number(),
    name: z.string(),
  }),
  asset: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
});

export type InternalWorkRequestForm = z.infer<typeof InternalWorkRequestSchema>;

export default function WorkRequestFormDrawer() {
  const dispatch = useAppDispatch();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParam, setSearchParams] = useSearchParams();
  const [uploadedPhotos, setUploadedPhotos] = useState<Attachment[]>([]);
  const isUpdateDrawer = useIsUpdateDrawerState();
  const methods = useForm<InternalWorkRequestForm>({
    resolver: zodResolver(InternalWorkRequestSchema),
    defaultValues: {
      email: "",
      contactNo: "",
      location: "",
      title: "",
      description: "",
      photos: [],
    },
  });

  const qc = useQueryClient();

  const { data: user } = useAuthControllerGetCurrentUser();
  const { mutateAsync: createWorkRequest } =
    useWorkRequestControllerCreateWorkRequestInternally();

  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => {
          return (query.queryKey[0] as string).includes("work-request");
        },
      });
      setSearchParams(new URLSearchParams());
    },
  });

  const onSubmit: SubmitHandler<InternalWorkRequestForm> = async (data) => {
    await mutate({
      files: data.photos ?? [],
      mutateAsync: async (atts) => {
        await createWorkRequest({
          data: {
            email: data.email,
            contactNo: data.contactNo,
            location: data.location,
            title: data.title,
            description: data.description,
            photos: atts,
            projectCode: activeProj?.workRequestCode ?? "",
            dbLocationId: data.dbLocation?.id?.toString(),
            assetId: data.asset?.id?.toString(),
          },
        });
      },
    });
  };

  const {
    data: topLevelLocationData,
    isLoading: topLevelLocationDataIsLoading,
    isSuccess: topLevelLocationDataIsSuccess,
    isError: topLevelLocationDataIsError,
    error: topLevelLocationDataError,
  } = useLocationControllerGetTopLocations(
    {
      projectCode: activeProj?.workRequestCode ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Work Request
        </p>
        <AppTextField label="Email" name="email" />
        <AppTextField label="Contact No." name="contactNo" />
        <AppSelectWithDialog
          label="Select Block"
          columns={[
            {
              header: "",
              id: "select",
              cell: ({ row, table }) => (
                <Checkbox
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => {
                    row.toggleSelected(!!value);
                  }}
                  aria-label="Select row"
                />
              ),
              enableSorting: false,
              enableHiding: false,
            },
            {
              accessorKey: "name",
              header: "Name",
            },
            {
              accessorKey: "code",
              header: "Code",
            },
          ]}
          placeholder="Select Block"
          control={methods.control}
          defaultValue={undefined}
          name="dbLocation"
          items={topLevelLocationData?.data ?? []}
          onResultRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          onOptionsRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          dialogTitle="Select Asset Type"
          error={!!methods.formState.errors.dbLocation?.message}
          helperText={methods.formState.errors.dbLocation?.message}
        />
        <WorkRequestSelectAssetDialog />
        <AppTextField label="Location" name="location" />
        <AppTextField label="Title" name="title" />
        <AppTextAreaField label="Description" name="description" />

        <Controller
          control={methods.control}
          name="photos"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFile
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  const newSetPhotos = value?.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.photos?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
                uploadedPhotos={uploadedPhotos}
                onDeleteUploadedPhoto={async (pho) => {}}
              />
            );
          }}
        />
        <AppButton
          isLoading={isPending}
          label={
            searchParam.get("drawerState") === DRAWER_UPDATE_STATE
              ? "Update"
              : "Create"
          }
          className="w-40 mt-4"
          onClick={methods.handleSubmit(onSubmit)}
        />
      </div>
    </FormProvider>
  );
}
