import { useParams } from "react-router-dom";
import { ProjectControllerFindAllUsersParams } from "../../../../../../../../api/model";
import {
  ProjectControllerFindAllUsersQueryResult,
  useProjectControllerFindAllUsers,
} from "../../../../../../../../api/projects/projects";
import useCommonTable from "../../../../../../../../hooks/useCommonTable";
import useDebounce from "../../../../../../../../hooks/useDebounce";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import AppTextField from "../../../../../../../../components/AppTextField";
import { DataTable } from "../../../../../../../../components/react-table/DataTable";
import { userTabColumns } from "./userTabColumns";

export default function UsersTabTable() {
  const { projectId } = useParams();
  const { search, pagination, setPagination } = useCommonTable();

  const queryArg = useDebounce<ProjectControllerFindAllUsersParams>({
    value: {
      rowsPerPage: pagination.pageSize,
      page: pagination.pageIndex,
      search,
    },
    delay: 500,
  });

  const {
    data: users,
    isLoading,
    isFetching,
  } = useProjectControllerFindAllUsers(projectId as string, queryArg, {
    query: {
      enabled: !!projectId,
      select: (res) => res.data,
    },
  });

  const table = useReactTable({
    data: users?.data ?? [],
    columns: userTabColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: users?.totalCount
      ? Math.ceil(users?.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        createdOn: false,
      },
    },
  });

  return (
    <>
      <AppTextField
        name="search"
        className="w-[400px]"
        placeholder="Search.."
      />
      <DataTable
        isFetching={isFetching}
        isLoading={isLoading}
        table={table}
        totalCount={users?.totalCount ?? 0}
        rowsPerPage={pagination.pageSize}
        pageIdx={pagination.pageIndex}
      />
    </>
  );
}
