import { useQueryClient } from "@tanstack/react-query";
import { WorkRequestSetting } from "../../../../../api/model";
import { useAppSelector } from "../../../../../redux/store";
import {
  useWorkRequestSettingControllerGetOneWorkRequestSetting,
  useWorkRequestSettingControllerUpdateWorkRequestSetting,
} from "../../../../../api/work-request-setting/work-request-setting";
import { TableCell } from "../../../../../components/ui/table";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { Loader2 } from "lucide-react";
import { cn } from "../../../../../lib/utils";

interface WorkRequestSettingVisibilityCheckboxProps {
  keyValue: keyof Pick<
    WorkRequestSetting,
    | "locationDetailVisibility"
    | "contactNoVisibility"
    | "subjectVisibility"
    | "descriptionVisibility"
    | "photoVisibility"
    | "assetVisibility"
    | "locationVisibility"
    | "emailVisibility"
  >;
}

export default function WorkRequestSettingVisibilityCheckbox({
  keyValue,
}: WorkRequestSettingVisibilityCheckboxProps) {
  const qc = useQueryClient();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: workReqSettings, isLoading } =
    useWorkRequestSettingControllerGetOneWorkRequestSetting(
      activeProj?.id?.toString() ?? "",
      {
        query: {
          enabled: !!activeProj,
          select: (res) => res.data.data,
        },
      }
    );

  const { mutate, isPending } =
    useWorkRequestSettingControllerUpdateWorkRequestSetting({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("work-request-setting"),
          });
        },
      },
    });

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        // Do not allow user to change subject, it has to be required at all times otherwise it would break the app
        disabled={
          keyValue === "subjectVisibility" || keyValue === "emailVisibility"
        }
        checked={workReqSettings?.[keyValue] === "VISIBLE"}
        onCheckedChange={(e) => {
          if (!workReqSettings) return;
          mutate({
            projectId: activeProj?.id?.toString() ?? "",
            data: {
              ...workReqSettings,
              [keyValue]: e ? "VISIBLE" : "HIDDEN",
            },
          });
        }}
      />
      {isPending && (
        <Loader2 className={cn("h-6 w-6 animate-spin text-primary-900")} />
      )}
    </div>
  );
}
