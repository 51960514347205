import { ThreeEvent } from "@react-three/fiber";
import { useBimActions } from "../../stores/bimStore/bimStore";
import { useBimControllerGetBimNodes } from "../../api/bim/bim";
import { useSearchParams } from "react-router-dom";
import {
  ASSET_DETAIL_DRAWER,
  BIM_DRAWER,
  DRAWER_VIEW_STATE,
} from "../../components/drawers/AppDrawer";

export default function useBimSelection() {
  const [searchParam, setSearchParams] = useSearchParams();
  const bimId = searchParam.get("bimId");
  const { data: bimNodes } = useBimControllerGetBimNodes(bimId as string, {
    query: {
      enabled: !!bimId,
      select: (res) => res.data.data,
    },
  });

  const onDoubleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    const idenNode = bimNodes?.find((node) => node.name === e.object.name);
    if (!idenNode) return;
    setSearchParams((p) => {
      if (!idenNode.assetId || !idenNode.bimUuid) return p;
      p.set("drawerType", ASSET_DETAIL_DRAWER);
      p.set("drawerState", DRAWER_VIEW_STATE);
      p.set("assetId", idenNode.assetId.toString());
      p.set("assetDetailTabIdx", "0");
      return p;
    });
  };

  return {
    onDoubleClick,
  };
}
