import { useParams } from "react-router-dom";
import CardSummary from "../../../../components/CardSummary";
import { useProjectControllerFindOneProject } from "../../../../../../../../../api/projects/projects";
import EditPlanSummaryBtnDialog from "./components/EditPlanSummaryBtnDialog";

export default function PlanSummaryCard() {
  const { projectId } = useParams();

  const { data: project } = useProjectControllerFindOneProject(
    projectId as string,
    {
      query: {
        select: (res) => res.data.data,
      },
    }
  );

  return (
    <CardSummary
      title="Plan Type"
      content={project?.subscriptionType ?? "-"}
      action={<EditPlanSummaryBtnDialog />}
    />
  );
}
