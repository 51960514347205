import * as React from "react";
import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import { ProjectInfoSection } from "./components/ProjectInfoSection";
import { RequestorParticularsSection } from "./components/RequestorParticularsSection";
import { RequestDetailsSection } from "./components/RequestDetailsSection";
import WorkAssessmentSection from "./components/WorkAssessmentSection";
import { SparePartDetailSection } from "./components/SparePartDetailSection";
import { CertificationOfWorkCompletionSection } from "./components/CertificationOfWorkCompletionSection";
import { SupervisorEngineerManagerVerificationSection } from "./components/SupervisorEngineerManagerVerificationSection";
import { BuildingInspectorVerificationSection } from "./components/BuildingInspectorVerificationSection";
import { RepairRectificationReplacePictureSection } from "./components/RepairRectificationReplacePictureSection";
import { JKRWorkOrder } from "@cerev-cmms/zod-types";

Font.register({
  family: "Fira Sans",
  fonts: [
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Regular.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Bold.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

interface JKRWorkOrderPdfProps {
  workOrder?: JKRWorkOrder;
}

const JKRWorkOrderContext = React.createContext<JKRWorkOrder>({} as any);

export const useJKRWorkOrder = () =>
  React.useContext<JKRWorkOrder>(JKRWorkOrderContext);

export function JKRWorkOrderPdf({ workOrder }: JKRWorkOrderPdfProps) {
  return (
    <JKRWorkOrderContext.Provider
      value={
        workOrder ??
        ({
          refNo: "123ABC",
          workRequests: [
            {
              trackingCode: "123ABC",
            } as any,
          ],
          defectType: {
            name: "PRO ACTIVE / SELF FINDING",
          } as any,
          createdOn: new Date(),
          createdBy: {
            name: "John Doe",
          } as any,
        } as any)
      }
    >
      <Document style={{ fontFamily: "Fira Sans" }}>
        <Page
          size="A4"
          style={{
            paddingHorizontal: 40,
            paddingVertical: 24,
            flexDirection: "column",
            gap: 12,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 24,
              border: 1,
              borderColor: "#94a3b8",
              paddingHorizontal: 16,
              paddingVertical: 4,
            }}
          >
            <Image
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/JKR_Malaysia_logo.png/1597px-JKR_Malaysia_logo.png"
              style={{
                height: 75,
                width: 100,
              }}
            />
            <Text
              style={{
                fontWeight: "bold",
                flex: 1,
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              Work Order Details
            </Text>
          </View>
          <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
            Site: KDN Perlis
          </Text>
          <ProjectInfoSection />
          <RequestorParticularsSection />
          <RequestDetailsSection />
          <WorkAssessmentSection />
          <SparePartDetailSection />
          <CertificationOfWorkCompletionSection />
          <SupervisorEngineerManagerVerificationSection />
          <BuildingInspectorVerificationSection />
          <RepairRectificationReplacePictureSection />
        </Page>
      </Document>
    </JKRWorkOrderContext.Provider>
  );
}
