import { getBlob, ref } from "firebase/storage";
import { FileText } from "lucide-react";
import {
  GetOneWorkOrderResponseDto,
  GetPdfReportResponseDto,
} from "../../../../../../api/model";
import {
  useWorkOrderV2ControllerPostPdfReport,
  useWorkOrderV2ControllerPostPdfReportV2,
} from "../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import { storage } from "../../../../../../firebase/firebaseConfig";
import AppButton from "../../../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../ui/dialog";
import { Switch } from "../../../../../ui/switch";
import { useState } from "react";
import SignatoryGuard from "../../../../../../guards/SignatoryGuard";

interface ExportPdfBtnProps {
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function ExportPdfBtn({ workOrder }: ExportPdfBtnProps) {
  const [isLogViewable, setLogViewable] = useState(true);
  const [isCommentViewable, setCommentViewable] = useState(true);
  const [isSignatureViewable, setSignatureViewable] = useState(true);
  const [open, setOpen] = useState(false);
  const { mutate: trigger, isPending } =
    useWorkOrderV2ControllerPostPdfReportV2({
      mutation: {
        onSuccess: (res) => {
          const pdfUrl = (res.data as GetPdfReportResponseDto).url;
          if (pdfUrl) window.open(pdfUrl, "__blank");
          setOpen(false);
        },
      },
    });

  const onConfirm = () => {
    trigger({
      workOrderId: workOrder?.id?.toString() ?? "",
      data: {
        commentsViewable: isCommentViewable,
        logsViewable: isLogViewable,
        signatoryViewable: isSignatureViewable,
      },
    });
  };

  return (
    <>
      <AppButton
        icon={<FileText className="text-primary-900" />}
        isLoading={isPending}
        className="w-40"
        variant="outline"
        label="Export PDF"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={(o) => setOpen(o)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Export Work Order PDF</DialogTitle>
            <DialogDescription>
              Indicate below which page is required in the PDF
            </DialogDescription>
          </DialogHeader>
          <SignatoryGuard>
            <div className="flex items-center gap-4 p-4 rounded-xl border-slate-200 border border-solid">
              <Switch
                className="data-[state=checked]:bg-primary-900"
                checked={isSignatureViewable}
                onCheckedChange={(o) => setSignatureViewable(o)}
              />
              <p className="text-sm">Signature Section</p>
            </div>
          </SignatoryGuard>
          <div className="flex items-center gap-4 p-4 rounded-xl border-slate-200 border border-solid">
            <Switch
              className="data-[state=checked]:bg-primary-900"
              checked={isCommentViewable}
              onCheckedChange={(o) => setCommentViewable(o)}
            />
            <p className="text-sm">Comments Section</p>
          </div>
          <div className="flex items-center gap-4 p-4 rounded-xl border-slate-200 border border-solid">
            <Switch
              className="data-[state=checked]:bg-primary-900"
              checked={isLogViewable}
              onCheckedChange={(o) => setLogViewable(o)}
            />
            <p className="text-sm">Logs Section</p>
          </div>

          <DialogFooter>
            <AppButton
              label="Export PDF"
              isLoading={isPending}
              onClick={onConfirm}
            />
            <AppButton
              variant="ghost"
              label="Cancel"
              onClick={() => setOpen(false)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
