import { Controller, useFormContext } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { cn } from "../../../../utils/util";
import { EscalationType } from "../../../../api/model";
import { Label } from "../../../ui/label";

export default function EscalationTypeSelectField() {
  const methods = useFormContext();

  return (
    <div className="space-y-2">
      <Label>Escalation Type</Label>
      <Controller
        control={methods.control}
        name="escalationStatuses.escalationType"
        render={({ field: { onChange, value } }) => {
          return (
            <Select value={value} onValueChange={onChange}>
              <SelectTrigger className="bg-slate-50">
                <SelectValue
                  className={cn(`
                          font-sans 
                          text-neutral-50 
                          transition-all 
                          text-xs
                          w-full
                          pl-0
                          text-left
                          duration-300
                    `)}
                ></SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={EscalationType.WORK_ORDER}>
                  Work Order
                </SelectItem>
                <SelectItem value={EscalationType.WORK_REQUEST}>
                  Work Request
                </SelectItem>
                <SelectItem value={EscalationType.PM}>
                  Preventive Maintenance
                </SelectItem>
              </SelectContent>
            </Select>
          );
        }}
      />
    </div>
  );
}
