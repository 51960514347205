import { useSearchParams } from "react-router-dom";
import AppItemInfo from "@/components/AppItemInfo";
import { Pencil, Tag } from "lucide-react";
import AppButton from "@/components/AppButton";
import {
  DRAWER_CREATE_STATE,
  DRAWER_UPDATE_STATE,
  PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER,
  PERMIT_TO_WORK_TEMPLATE_DETAIL_DRAWER,
} from "@/components/drawers/AppDrawer";
import { usePtwTemplateControllerGetOnePtwTemplate } from "../../../api/permit-to-work-adv/permit-to-work-adv";
import PtwTemplateDetailItem from "./components/PtwTemplateDetailItem/PtwTemplateDetailItem";

export default function PtwTemplateDetailDrawer() {
  const [searchParam, setSearchParams] = useSearchParams();
  const ptwTemplateId = searchParam.get("ptwTemplateId");

  const { data: ptw } = usePtwTemplateControllerGetOnePtwTemplate(
    ptwTemplateId as string,
    {
      query: {
        enabled: !!ptwTemplateId,
        select: (res) => res.data.data,
      },
    }
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-4 items-center">
        <h3 className="font-sans text-2xl font-bold">{ptw?.name}</h3>
        <AppButton
          label="Edit"
          icon={<Pencil />}
          variant="outline"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", PERMIT_TO_WORK_TEMPLATE_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              return p;
            });
          }}
        />
      </div>
      <AppItemInfo
        icon={<Tag className="text-primary-900" />}
        label="Name"
        content={<p className="font-sans">{ptw?.name}</p>}
      />
      <h3 className="font-sans text-xl font-bold">Fields</h3>
      <PtwTemplateDetailItem ptwFormats={ptw?.ptwFormat ?? []} />
    </div>
  );
}
