import {
  useSelectedSettingProjectId,
  useSettingProjectActions,
  useSettingProjectLists,
} from "../../../../stores/settingStore/settingStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";

export default function SettingSelectProj() {
  const selectedProjId = useSelectedSettingProjectId();
  const projList = useSettingProjectLists();
  const { setSelectedProject } = useSettingProjectActions();

  return (
    <Select
      onValueChange={(v) => {
        setSelectedProject(v);
      }}
      value={selectedProjId ?? undefined}
    >
      <SelectTrigger className="w-[175px]">
        <SelectValue className="text-primary-900" />
      </SelectTrigger>
      <SelectContent className="overflow-y-auto">
        {projList.map((proj) => (
          <SelectItem key={proj.id} value={proj.id?.toString() ?? ""}>
            {proj.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
