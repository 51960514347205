import { useState } from "react";
import {
  EscalationControllerGetEscalationsParams,
  EscalationType,
} from "../../../api/model";
import { DataTable } from "../../../components/react-table/DataTable";
import {
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import useSearch from "../../../hooks/useSearch";
import { useAppSelector } from "../../../redux/store";
import { escalationColumns } from "../../../components/drawers/escalation/escalationColumns";
import { useEscalationControllerGetEscalations } from "../../../api/escalation/escalation";
import { useFormContext, useWatch } from "react-hook-form";
import useDebounce from "../../../hooks/useDebounce";

interface EscalationTabProps {
  escalationType: EscalationType;
}

export default function EscalationTab({ escalationType }: EscalationTabProps) {
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      rowsPerPage: pagination.pageSize,
      page: pagination.pageIndex,
      escalationType: escalationType,
      search,
    },
    delay: 500,
  });

  const {
    data: escalationsData,
    isLoading: escalationsDataIsLoading,
    isFetching,
  } = useEscalationControllerGetEscalations(queryArg, {
    query: {
      enabled: !!activeProj,
      select: (res) => res.data,
    },
  });

  const table = useReactTable({
    data: escalationsData?.data ?? [],
    columns: escalationColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: escalationsData?.totalCount
      ? Math.ceil(escalationsData?.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        defectStatus: escalationType === EscalationType.WORK_ORDER,
        pmStatus: escalationType === EscalationType.PM,
        workRequestStatus: escalationType === EscalationType.WORK_REQUEST,
      },
    },
  });

  return (
    <DataTable
      table={table}
      totalCount={escalationsData?.totalCount ?? 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
      isLoading={escalationsDataIsLoading}
      isFetching={isFetching}
    />
  );
}
