import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Status, UserControllerGetUsersParams } from "../../../../../api/model";
import {
  useUserControllerGetUserCount,
  useUserControllerGetUsers,
} from "../../../../../api/users/users";
import { DataTable } from "../../../../../components/react-table/DataTable";
import useCurrentUser from "../../../../../hooks/useCurrentUser/useCurrentUser";
import { useAppSelector } from "../../../../../redux/store";
import { accountTabColumns } from "../columns/accountTabColumns";
import useDebounce from "../../../../../hooks/useDebounce";

interface UserAccountTabContentProps {
  status: Status;
}

export default function UserAccountTabContent({
  status,
}: UserAccountTabContentProps) {
  const methods = useFormContext();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const currentUser = useCurrentUser();

  const { data: userData } = useCurrentUser();

  const search = useWatch({ control: methods.control, name: "search" });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<UserControllerGetUsersParams>({
    value: {
      companyId: activeComp?.id ?? 0,
      search,
      userId: userData?.id ?? 0,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      status,
    },
    delay: 500,
  });

  const {
    data: usersList,
    isLoading,
    isFetching,
  } = useUserControllerGetUsers(queryArg, {
    query: {
      enabled: !!userData && !!activeProj && !!activeComp,
      select: (res) => res.data,
    },
  });

  const { data: usersListCount } = useUserControllerGetUserCount(queryArg, {
    query: {
      enabled: !!userData && !!activeProj && !!activeComp,
      select: (res) => res.data,
    },
  });

  const table = useReactTable({
    data: usersList ?? [],
    columns: accountTabColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: usersListCount?.totalCount
      ? Math.ceil(usersListCount.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        firebaseUserId: false,
      },
    },
  });

  return (
    <DataTable
      isLoading={isLoading}
      isFetching={isFetching}
      table={table}
      totalCount={usersListCount?.totalCount ?? 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );
}
