import { useSearchParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import {
  DRAWER_CREATE_STATE,
  VENDOR_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import {
  useVendorControllerGetVendorsQuery,
  useWorkscopeControllerGetWorkScopesQuery,
} from "../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import VendorTab from "./VendorTab/VendorTab";
import { useVendorFilterStore } from "../../stores/vendorFilterStore.ts/vendorFilterStore";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import WorkscopeTab from "./WorkscopeTab/WorkscopeTab";
import { useEffect } from "react";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { CerevModule } from "../../api/model";

export default function VendorScreen() {
  const [, setSearchParams] = useSearchParams();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const cleanUp = useVendorFilterStore((state) => state.cleanUp);

  // Clean up filter state
  useEffect(() => {
    return cleanUp;
  }, []);

  return (
    <ModuleAccessGuard module={CerevModule.VENDOR}>
      <div className="flex gap-4">
        <AppTitle title="Vendors" />
        <AppButton
          label="+ Add"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", VENDOR_FORM_DRAWER);
              p.set("drawerState", DRAWER_CREATE_STATE);
              return p;
            });
          }}
        />
      </div>
      <Tabs defaultValue="vendors" className="mb-4">
        <TabsList className="h-18 mb-2">
          <TabsTrigger value="vendors">Vendors</TabsTrigger>
          <TabsTrigger value="workscope">Workscope</TabsTrigger>
        </TabsList>
        <TabsContent value="vendors">
          <VendorTab />
        </TabsContent>
        <TabsContent value="workscope">
          <WorkscopeTab />
        </TabsContent>
      </Tabs>
    </ModuleAccessGuard>
  );
}
