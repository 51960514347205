import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";

export default function ProtectedRoute({children}: {children: React.ReactNode}){

    if(!auth.currentUser){
        return <Navigate to="/" replace />
    }

    return <>{children}</>
}