import { useSearchParams } from "react-router-dom";
import {
  AssetType,
  useAssetTypeControllerDeleteAssetTypeMutation,
} from "../../../redux/slices/OpenApi/cerevApi";
import AppButton from "../../../components/AppButton";
import {
  ASSET_TYPE_FORM_DRAWER,
  DRAWER_UPDATE_STATE,
} from "../../../components/drawers/AppDrawer";
import { Pencil, Trash } from "lucide-react";
import { useAssetTypeControllerDeleteAssetType } from "../../../api/asset-type/asset-type";
import { useQueryClient } from "@tanstack/react-query";

interface AssetTypeRowBtnsProps {
  assetType: AssetType;
}

export default function AssetTypeRowBtns({ assetType }: AssetTypeRowBtnsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const qc = useQueryClient();
  // const [
  //   deleteAssetType,
  //   { isLoading: deleteAssetTypeIsLoading, isError: deleteAssetTypeIsError },
  // ] = useAssetTypeControllerDeleteAssetType();

  const { mutate: deleteAssetType, isPending: deleteAssetTypeIsLoading } =
    useAssetTypeControllerDeleteAssetType({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("asset-type"),
          });
        },
      },
    });

  return (
    <div className="flex gap-4">
      <AppButton
        variant="outline"
        icon={<Pencil />}
        label="Edit"
        onClick={() => {
          setSearchParams((p) => {
            if (!assetType.id) return p;
            p.set("drawerType", ASSET_TYPE_FORM_DRAWER);
            p.set("drawerState", DRAWER_UPDATE_STATE);
            p.set("assetTypeId", assetType.id.toString());
            return p;
          });
        }}
      />
      <AppButton
        isLoading={deleteAssetTypeIsLoading}
        label="Delete"
        icon={<Trash />}
        variant="destructive"
        onClick={() => {
          if (!assetType.id) return;
          deleteAssetType({
            assetTypeId: assetType.id.toString(),
          });
        }}
      />
    </div>
  );
}
