/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type EscalationType =
  (typeof EscalationType)[keyof typeof EscalationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EscalationType = {
  WORK_REQUEST: "WORK_REQUEST",
  WORK_ORDER: "WORK_ORDER",
  PM: "PM",
} as const;
