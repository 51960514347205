import { create } from "zustand";
import {
  LedgersDto,
  StockEntity,
  Project,
  SerializedInventory,
  CreateStockDtoInventoryMode,
  Location,
} from "../../api/model";

export enum SelectInventoryPageState {
  SELECT_INVENTORY = "SELECT_INVENTORY",
  CONFIRM_INVENTORY = "CONFIRM_INVENTORY",
}

interface LedgersDtoWithFieldId extends LedgersDto {
  fieldId?: string;
  location?: Location;
}

interface WorkOrderInventoryStore {
  pageState: SelectInventoryPageState;
  ledgers: LedgersDtoWithFieldId[];
  workOrderId: number;
  actions: {
    setWorkOrderId: (id: number) => void;
    setPageState: (state: SelectInventoryPageState) => void;
    setLedgers: (ledgers: LedgersDto[]) => void;
    getCurrentAmt: ({
      inventoryId,
      locationId,
    }: {
      inventoryId: string;
      locationId?: number;
    }) => number;
    reduceAmt: (dto: { inventoryId: string; locationId?: number }) => void;
    increaseAmt: (dto: {
      inventoryId: string;
      inventoryList: StockEntity[];
      activeProj: Partial<Project>;
      locationId?: number; // The ledger may or may not have location Id tied to it
      location?: Location;
    }) => void;
    cleanUp: () => void;
    setSerializedInventory: ({
      stock,
      serial,
    }: {
      stock: StockEntity;
      serial: SerializedInventory;
    }) => void;
    getIsSelectedAsSerial: (stockId: string, serial: string) => boolean;
  };
}

export const useWorkOrderInventoryStore = create<WorkOrderInventoryStore>(
  (set, get) => ({
    pageState: SelectInventoryPageState.SELECT_INVENTORY,
    ledgers: [],
    workOrderId: 0,
    actions: {
      setWorkOrderId: (id) => set(() => ({ workOrderId: id })),
      setPageState: (state) => set(() => ({ pageState: state })),
      setLedgers: (ledgers) => {
        return set(() => ({ ledgers }));
      },
      getCurrentAmt: ({ inventoryId, locationId }) => {
        return get().ledgers.find(
          (l) => l.stockId === inventoryId && l.locationId === locationId
        )?.adjustmentQty
          ? Number(
              get().ledgers.find(
                (l) => l.stockId === inventoryId && l.locationId === locationId
              )?.adjustmentQty
            )
          : 0;
      },
      reduceAmt: ({ inventoryId, locationId }) => {
        const isDefinedLocation = locationId ? locationId > 0 : false;
        // locationId can be undefined
        const ledger = get().ledgers.find(
          isDefinedLocation
            ? (l) => l.stockId === inventoryId && l.locationId === locationId
            : (l) => l.stockId === inventoryId && l.locationId === -1
        );
        if (ledger) {
          if (ledger.adjustmentQty === "1")
            return set({
              ledgers: get().ledgers.filter(
                (l) => l.fieldId !== ledger.fieldId
              ),
            });

          ledger.adjustmentQty = (
            parseInt(ledger.adjustmentQty ?? "0") - 1
          ).toString();
          set({
            ledgers: [
              ...get().ledgers.filter((l) => l.fieldId !== ledger.fieldId),
              ledger,
            ],
          });
        }
      },
      increaseAmt: ({
        inventoryId,
        inventoryList,
        activeProj,
        locationId,
        location,
      }) => {
        const isDefinedLocation = locationId ? locationId > 0 : false;

        const ledger = get().ledgers.find(
          isDefinedLocation
            ? (l) => l.stockId === inventoryId && l.locationId === locationId
            : (l) => l.stockId === inventoryId && l.locationId === -1
        );
        if (ledger) {
          ledger.adjustmentQty = (
            parseInt(ledger.adjustmentQty ?? "0") + 1
          ).toString();
          set({
            ledgers: [
              ...get().ledgers.filter((l) => l.fieldId !== ledger.fieldId),
              ledger,
            ],
          });
        } else {
          const idenStock = inventoryList?.find(
            (inv) => inv.id.toString() === inventoryId
          );
          if (idenStock)
            set({
              ledgers: [
                ...get().ledgers,
                {
                  stock: idenStock,
                  adjustmentQty: "1",
                  stockId: inventoryId,
                  projectId: activeProj?.id ?? 1,
                  date: new Date().toISOString(),
                  locationId: locationId ?? -1,
                  fieldId: `${idenStock.id}-${locationId ?? -1}`,
                  location,
                },
              ],
            });
        }
      },
      setSerializedInventory: ({ stock, serial }) => {
        const ledger = get().ledgers.find(
          (l) => l.stockId === stock.id.toString()
        );
        if (ledger) {
          // Check if its already added, otherwise remove it
          if (
            ledger.serializedInventory?.find((s) => s.serial === serial.serial)
          ) {
            ledger.serializedInventory = ledger.serializedInventory?.filter(
              (s) => s.serial !== serial.serial
            );
          } else {
            ledger.serializedInventory = [
              ...(ledger.serializedInventory ?? []),
              serial,
            ];
          }

          // Maintain the position of ledger and update the state
          set({
            ledgers: get().ledgers.map((l) =>
              l.stockId === stock.id.toString() ? ledger : l
            ),
          });
        }
      },
      getIsSelectedAsSerial: (stockId, serial) => {
        return !!get()
          .ledgers.find((l) => l.stockId === stockId)
          ?.serializedInventory?.find((s) => s.serial === serial);
      },
      cleanUp: () =>
        set(() => ({
          ledgers: [],
          pageState: SelectInventoryPageState.SELECT_INVENTORY,
          workOrderId: 0,
        })),
    },
  })
);

export const useWorkOrderInventoryLedgers = () =>
  useWorkOrderInventoryStore((state) => state.ledgers);
export const useQuantityInventories = () =>
  useWorkOrderInventoryStore((state) =>
    state.ledgers.filter(
      (l) => l.stock.inventoryMode === CreateStockDtoInventoryMode.QUANTITY
    )
  );
export const useSerializedInventories = () =>
  useWorkOrderInventoryStore((state) =>
    state.ledgers.filter(
      (l) => l.stock.inventoryMode === CreateStockDtoInventoryMode.SERIALIZED
    )
  );
export const useWorkOrderInventoryActions = () =>
  useWorkOrderInventoryStore((state) => state.actions);
export const usePageState = () =>
  useWorkOrderInventoryStore((state) => state.pageState);
export const useCurrentWorkOrderId = () =>
  useWorkOrderInventoryStore((state) => state.workOrderId);
