import { ColumnDef } from "@tanstack/react-table";
import { Location } from "../../../../api/model/location";
import { Checkbox } from "../../../ui/checkbox";

export const selectLocationColumns: ColumnDef<Location>[] = [
    {
        header: "",
        id: "select",
        cell: ({ row, table }) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                    row.toggleSelected(!!value);
                }}
                aria-label="Select row"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: "name",
        header: "Name",
    }
];
