import { useFormContext, useWatch } from "react-hook-form";
import { WorkPermitsControllerGetWorkPermitCountParams, WorkPermitsControllerGetWorkPermitCountWorkPermitStatus } from "../../../api/model";
import { useWorkPermitsControllerGetWorkPermitCount } from "../../../api/work-permits/work-permits";
import {
  WORK_PERMIT_APPROVED_STATUS,
  WORK_PERMIT_NEW_STATUS,
  WORK_PERMIT_REJECTED_STATUS,
} from "../../../constants/workPermitConstants";
import { useAppSelector } from "../../../redux/store";
import useDebounce from "../../../hooks/useDebounce";

interface WorkPermitTabLabelProps {
  workPermitStatus: { name: string; value: string };
}

export default function WorkPermitTabLabel({
  workPermitStatus,
}: WorkPermitTabLabelProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });

  const getWorkPermitStatus = () => {
    switch (workPermitStatus.value) {
      case WORK_PERMIT_NEW_STATUS:
        return WorkPermitsControllerGetWorkPermitCountWorkPermitStatus.NEW;
      case WORK_PERMIT_APPROVED_STATUS:
        return WorkPermitsControllerGetWorkPermitCountWorkPermitStatus.APPROVED;
      case WORK_PERMIT_REJECTED_STATUS:
        return WorkPermitsControllerGetWorkPermitCountWorkPermitStatus.REJECTED;
      default:
        return null;
    }
  };

  const queryCount = useDebounce<WorkPermitsControllerGetWorkPermitCountParams>({
    value: {
      projectId: activeProj?.id ?? 0,
      workPermitStatus: getWorkPermitStatus() ?? undefined,
      search,
    },
    delay: 500,
  })

  const { data: statusCounts } = useWorkPermitsControllerGetWorkPermitCount(
    queryCount,
    {
      query: {
        enabled: !!activeProj && !!getWorkPermitStatus(),
      },
    }
  );

  return <p>{`${workPermitStatus?.name} (${statusCounts?.data ?? "-"})`}</p>;
}
