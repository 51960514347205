/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateLegalComplianceDto,
  GetLegalComplianceCountResponseDto,
  LegalCompliance,
  LegalComplianceControllerGetLegalComplianceCountParams,
  LegalComplianceControllerGetLegalCompliancesParams,
  LegalCompliancesResponseDto,
  UpdateLegalComplianceDto,
} from ".././model";

export const legalComplianceControllerGetLegalCompliances = (
  params: LegalComplianceControllerGetLegalCompliancesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LegalCompliancesResponseDto[]>> => {
  return axios.get(`/legal-compliance`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLegalComplianceControllerGetLegalCompliancesQueryKey = (
  params: LegalComplianceControllerGetLegalCompliancesParams
) => {
  return [`/legal-compliance`, ...(params ? [params] : [])] as const;
};

export const getLegalComplianceControllerGetLegalCompliancesInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>,
      LegalComplianceControllerGetLegalCompliancesParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: LegalComplianceControllerGetLegalCompliancesParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof legalComplianceControllerGetLegalCompliances>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof legalComplianceControllerGetLegalCompliances>
          >,
          QueryKey,
          LegalComplianceControllerGetLegalCompliancesParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getLegalComplianceControllerGetLegalCompliancesQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>,
      QueryKey,
      LegalComplianceControllerGetLegalCompliancesParams["cursorId"]
    > = ({ signal, pageParam }) =>
      legalComplianceControllerGetLegalCompliances(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>,
      TError,
      TData,
      Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>,
      QueryKey,
      LegalComplianceControllerGetLegalCompliancesParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type LegalComplianceControllerGetLegalCompliancesInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>
  >;
export type LegalComplianceControllerGetLegalCompliancesInfiniteQueryError =
  AxiosError<unknown>;

export const useLegalComplianceControllerGetLegalCompliancesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>,
    LegalComplianceControllerGetLegalCompliancesParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LegalComplianceControllerGetLegalCompliancesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalCompliances>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalCompliances>
        >,
        QueryKey,
        LegalComplianceControllerGetLegalCompliancesParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLegalComplianceControllerGetLegalCompliancesInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLegalComplianceControllerGetLegalCompliancesQueryOptions = <
  TData = Awaited<
    ReturnType<typeof legalComplianceControllerGetLegalCompliances>
  >,
  TError = AxiosError<unknown>
>(
  params: LegalComplianceControllerGetLegalCompliancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalCompliances>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLegalComplianceControllerGetLegalCompliancesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>
  > = ({ signal }) =>
    legalComplianceControllerGetLegalCompliances(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LegalComplianceControllerGetLegalCompliancesQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalCompliances>>
  >;
export type LegalComplianceControllerGetLegalCompliancesQueryError =
  AxiosError<unknown>;

export const useLegalComplianceControllerGetLegalCompliances = <
  TData = Awaited<
    ReturnType<typeof legalComplianceControllerGetLegalCompliances>
  >,
  TError = AxiosError<unknown>
>(
  params: LegalComplianceControllerGetLegalCompliancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalCompliances>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLegalComplianceControllerGetLegalCompliancesQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const legalComplianceControllerCreateLegalCompliance = (
  createLegalComplianceDto: CreateLegalComplianceDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LegalCompliance>> => {
  return axios.post(`/legal-compliance`, createLegalComplianceDto, options);
};

export const getLegalComplianceControllerCreateLegalComplianceMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof legalComplianceControllerCreateLegalCompliance>
      >,
      TError,
      { data: CreateLegalComplianceDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerCreateLegalCompliance>>,
    TError,
    { data: CreateLegalComplianceDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof legalComplianceControllerCreateLegalCompliance>
      >,
      { data: CreateLegalComplianceDto }
    > = (props) => {
      const { data } = props ?? {};

      return legalComplianceControllerCreateLegalCompliance(data, axiosOptions);
    };

    return { mutationFn, ...mutationOptions };
  };

export type LegalComplianceControllerCreateLegalComplianceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerCreateLegalCompliance>>
  >;
export type LegalComplianceControllerCreateLegalComplianceMutationBody =
  CreateLegalComplianceDto;
export type LegalComplianceControllerCreateLegalComplianceMutationError =
  AxiosError<unknown>;

export const useLegalComplianceControllerCreateLegalCompliance = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerCreateLegalCompliance>>,
    TError,
    { data: CreateLegalComplianceDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof legalComplianceControllerCreateLegalCompliance>>,
  TError,
  { data: CreateLegalComplianceDto },
  TContext
> => {
  const mutationOptions =
    getLegalComplianceControllerCreateLegalComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const legalComplianceControllerGetLegalComplianceCount = (
  params: LegalComplianceControllerGetLegalComplianceCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetLegalComplianceCountResponseDto>> => {
  return axios.get(`/legal-compliance/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getLegalComplianceControllerGetLegalComplianceCountQueryKey = (
  params: LegalComplianceControllerGetLegalComplianceCountParams
) => {
  return [`/legal-compliance/count`, ...(params ? [params] : [])] as const;
};

export const getLegalComplianceControllerGetLegalComplianceCountInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<
        ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
      >,
      LegalComplianceControllerGetLegalComplianceCountParams["cursorId"]
    >,
    TError = AxiosError<unknown>
  >(
    params: LegalComplianceControllerGetLegalComplianceCountParams,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
          >,
          TError,
          TData,
          Awaited<
            ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
          >,
          QueryKey,
          LegalComplianceControllerGetLegalComplianceCountParams["cursorId"]
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getLegalComplianceControllerGetLegalComplianceCountQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
      >,
      QueryKey,
      LegalComplianceControllerGetLegalComplianceCountParams["cursorId"]
    > = ({ signal, pageParam }) =>
      legalComplianceControllerGetLegalComplianceCount(
        { ...params, cursorId: pageParam || params?.["cursorId"] },
        { signal, ...axiosOptions }
      );

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
      >,
      TError,
      TData,
      Awaited<
        ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
      >,
      QueryKey,
      LegalComplianceControllerGetLegalComplianceCountParams["cursorId"]
    > & { queryKey: QueryKey };
  };

export type LegalComplianceControllerGetLegalComplianceCountInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>>
  >;
export type LegalComplianceControllerGetLegalComplianceCountInfiniteQueryError =
  AxiosError<unknown>;

export const useLegalComplianceControllerGetLegalComplianceCountInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
    >,
    LegalComplianceControllerGetLegalComplianceCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: LegalComplianceControllerGetLegalComplianceCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
        >,
        QueryKey,
        LegalComplianceControllerGetLegalComplianceCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLegalComplianceControllerGetLegalComplianceCountInfiniteQueryOptions(
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLegalComplianceControllerGetLegalComplianceCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
  >,
  TError = AxiosError<unknown>
>(
  params: LegalComplianceControllerGetLegalComplianceCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLegalComplianceControllerGetLegalComplianceCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>>
  > = ({ signal }) =>
    legalComplianceControllerGetLegalComplianceCount(params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LegalComplianceControllerGetLegalComplianceCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>>
  >;
export type LegalComplianceControllerGetLegalComplianceCountQueryError =
  AxiosError<unknown>;

export const useLegalComplianceControllerGetLegalComplianceCount = <
  TData = Awaited<
    ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
  >,
  TError = AxiosError<unknown>
>(
  params: LegalComplianceControllerGetLegalComplianceCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetLegalComplianceCount>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLegalComplianceControllerGetLegalComplianceCountQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const legalComplianceControllerGetOneLegalCompliance = (
  legalComplianceId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LegalCompliancesResponseDto>> => {
  return axios.get(`/legal-compliance/${legalComplianceId}`, options);
};

export const getLegalComplianceControllerGetOneLegalComplianceQueryKey = (
  legalComplianceId: number
) => {
  return [`/legal-compliance/${legalComplianceId}`] as const;
};

export const getLegalComplianceControllerGetOneLegalComplianceInfiniteQueryOptions =
  <
    TData = InfiniteData<
      Awaited<ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>>
    >,
    TError = AxiosError<unknown>
  >(
    legalComplianceId: number,
    options?: {
      query?: Partial<
        UseInfiniteQueryOptions<
          Awaited<
            ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>
          >,
          TError,
          TData
        >
      >;
      axios?: AxiosRequestConfig;
    }
  ) => {
    const { query: queryOptions, axios: axiosOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getLegalComplianceControllerGetOneLegalComplianceQueryKey(
        legalComplianceId
      );

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>>
    > = ({ signal }) =>
      legalComplianceControllerGetOneLegalCompliance(legalComplianceId, {
        signal,
        ...axiosOptions,
      });

    return {
      queryKey,
      queryFn,
      enabled: !!legalComplianceId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type LegalComplianceControllerGetOneLegalComplianceInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>>
  >;
export type LegalComplianceControllerGetOneLegalComplianceInfiniteQueryError =
  AxiosError<unknown>;

export const useLegalComplianceControllerGetOneLegalComplianceInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>>
  >,
  TError = AxiosError<unknown>
>(
  legalComplianceId: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLegalComplianceControllerGetOneLegalComplianceInfiniteQueryOptions(
      legalComplianceId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLegalComplianceControllerGetOneLegalComplianceQueryOptions = <
  TData = Awaited<
    ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>
  >,
  TError = AxiosError<unknown>
>(
  legalComplianceId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLegalComplianceControllerGetOneLegalComplianceQueryKey(
      legalComplianceId
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>>
  > = ({ signal }) =>
    legalComplianceControllerGetOneLegalCompliance(legalComplianceId, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!legalComplianceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LegalComplianceControllerGetOneLegalComplianceQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>>
  >;
export type LegalComplianceControllerGetOneLegalComplianceQueryError =
  AxiosError<unknown>;

export const useLegalComplianceControllerGetOneLegalCompliance = <
  TData = Awaited<
    ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>
  >,
  TError = AxiosError<unknown>
>(
  legalComplianceId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof legalComplianceControllerGetOneLegalCompliance>
        >,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getLegalComplianceControllerGetOneLegalComplianceQueryOptions(
      legalComplianceId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const legalComplianceControllerUpdateLegalCompliance = (
  legalComplianceId: number,
  updateLegalComplianceDto: UpdateLegalComplianceDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LegalCompliance>> => {
  return axios.patch(
    `/legal-compliance/${legalComplianceId}`,
    updateLegalComplianceDto,
    options
  );
};

export const getLegalComplianceControllerUpdateLegalComplianceMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof legalComplianceControllerUpdateLegalCompliance>
      >,
      TError,
      { legalComplianceId: number; data: UpdateLegalComplianceDto },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerUpdateLegalCompliance>>,
    TError,
    { legalComplianceId: number; data: UpdateLegalComplianceDto },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof legalComplianceControllerUpdateLegalCompliance>
      >,
      { legalComplianceId: number; data: UpdateLegalComplianceDto }
    > = (props) => {
      const { legalComplianceId, data } = props ?? {};

      return legalComplianceControllerUpdateLegalCompliance(
        legalComplianceId,
        data,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type LegalComplianceControllerUpdateLegalComplianceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerUpdateLegalCompliance>>
  >;
export type LegalComplianceControllerUpdateLegalComplianceMutationBody =
  UpdateLegalComplianceDto;
export type LegalComplianceControllerUpdateLegalComplianceMutationError =
  AxiosError<unknown>;

export const useLegalComplianceControllerUpdateLegalCompliance = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerUpdateLegalCompliance>>,
    TError,
    { legalComplianceId: number; data: UpdateLegalComplianceDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof legalComplianceControllerUpdateLegalCompliance>>,
  TError,
  { legalComplianceId: number; data: UpdateLegalComplianceDto },
  TContext
> => {
  const mutationOptions =
    getLegalComplianceControllerUpdateLegalComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const legalComplianceControllerDeleteLegalCompliance = (
  legalComplianceId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/legal-compliance/${legalComplianceId}`, options);
};

export const getLegalComplianceControllerDeleteLegalComplianceMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof legalComplianceControllerDeleteLegalCompliance>
      >,
      TError,
      { legalComplianceId: number },
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerDeleteLegalCompliance>>,
    TError,
    { legalComplianceId: number },
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof legalComplianceControllerDeleteLegalCompliance>
      >,
      { legalComplianceId: number }
    > = (props) => {
      const { legalComplianceId } = props ?? {};

      return legalComplianceControllerDeleteLegalCompliance(
        legalComplianceId,
        axiosOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type LegalComplianceControllerDeleteLegalComplianceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerDeleteLegalCompliance>>
  >;

export type LegalComplianceControllerDeleteLegalComplianceMutationError =
  AxiosError<unknown>;

export const useLegalComplianceControllerDeleteLegalCompliance = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerDeleteLegalCompliance>>,
    TError,
    { legalComplianceId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof legalComplianceControllerDeleteLegalCompliance>>,
  TError,
  { legalComplianceId: number },
  TContext
> => {
  const mutationOptions =
    getLegalComplianceControllerDeleteLegalComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const legalComplianceControllerDeleteAttachment = (
  legalComplianceId: number,
  documentId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `/legal-compliance/${legalComplianceId}/document/${documentId}`,
    options
  );
};

export const getLegalComplianceControllerDeleteAttachmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerDeleteAttachment>>,
    TError,
    { legalComplianceId: number; documentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof legalComplianceControllerDeleteAttachment>>,
  TError,
  { legalComplianceId: number; documentId: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof legalComplianceControllerDeleteAttachment>>,
    { legalComplianceId: number; documentId: number }
  > = (props) => {
    const { legalComplianceId, documentId } = props ?? {};

    return legalComplianceControllerDeleteAttachment(
      legalComplianceId,
      documentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type LegalComplianceControllerDeleteAttachmentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof legalComplianceControllerDeleteAttachment>>
  >;

export type LegalComplianceControllerDeleteAttachmentMutationError =
  AxiosError<unknown>;

export const useLegalComplianceControllerDeleteAttachment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof legalComplianceControllerDeleteAttachment>>,
    TError,
    { legalComplianceId: number; documentId: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof legalComplianceControllerDeleteAttachment>>,
  TError,
  { legalComplianceId: number; documentId: number },
  TContext
> => {
  const mutationOptions =
    getLegalComplianceControllerDeleteAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};
