import { useQueryClient } from "@tanstack/react-query";
import { useCustomWorkPermitEnabled } from "../../../../hooks/useFeatures";
import { useParams } from "react-router-dom";
import { useFeatureToggleControllerToggleFeature } from "../../../../api/feature-toggles/feature-toggles";
import { toast } from "sonner";
import { Switch } from "../../../ui/switch";
import { FeatureType } from "../../../../api/model";
import { Loader2 } from "lucide-react";

export default function CustomWorkPermitToggleSwitch() {
  const qc = useQueryClient();
  const { projectId } = useParams();
  const customWorkPermitEnabled = useCustomWorkPermitEnabled({
    projectId: projectId as string,
  });
  const { mutate: toggleSwitch, isPending } =
    useFeatureToggleControllerToggleFeature({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) =>
              (q.queryKey[0] as string).includes("feature-toggles"),
          });
          toast("Successfully updated");
        },
      },
    });

  return (
    <div className="flex items-center gap-4 mt-2">
      <Switch
        className="data-[state=checked]:bg-primary-900"
        checked={customWorkPermitEnabled ?? false}
        onCheckedChange={(checked) => {
          toggleSwitch({
            projectId: projectId as string,
            data: {
              featureType: FeatureType.CUSTOM_PTW,
            },
          });
        }}
      />
      {isPending && (
        <Loader2 className="h-6 w-6 animate-spin text-primary-900" />
      )}
    </div>
  );
}
