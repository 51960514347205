import { ColumnDef } from "@tanstack/react-table";
import { GetWorkRequestsResponseDto } from "../../../../redux/slices/OpenApi/cerevApi";
import { Checkbox } from "../../../ui/checkbox";

export const linkWorkRequestColumns: ColumnDef<GetWorkRequestsResponseDto>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "location",
    header: "Location"
  }
];
