import { Files, Video } from "lucide-react";
import AppButton from "../../../../../AppButton";
import { useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useWorkOrderV2ControllerAddWorkOrderVideoDoc } from "../../../../../../api/work-order-defect-v2/work-order-defect-v2";
import { useSearchParams } from "react-router-dom";
import useStorage from "../../../../../../hooks/useStorage";
import useAppStorage from "../../../../../../hooks/useAppStorage";

export default function AddDocBtn() {
  const [searchParam] = useSearchParams();
  const activeWoId = searchParam.get("workOrderId");
  const qc = useQueryClient();
  const fileRef = useRef(null);
  const onFileAttClicked = () => {
    (fileRef.current as any).click();
  };

  const { useUploadAttachmentMutation } = useAppStorage();

  const { mutateAsync: uploadVideo } =
    useWorkOrderV2ControllerAddWorkOrderVideoDoc();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (qry) =>
          (qry.queryKey[0] as string).includes("work-order") ||
          (qry.queryKey[0] as string).includes("defect") ||
          (qry.queryKey[0] as string).includes("comment"),
      });
    },
  });

  // This is single file upload only
  const onObtainedFiles = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const oneFile = fileList[0];

    mutate({
      files: [oneFile],
      mutateAsync: async (att) => {
        return await uploadVideo({
          workOrderId: Number(activeWoId ?? ""),
          data: {
            docs: att,
          },
        });
      },
    });
  };

  return (
    <>
      <AppButton
        className="flex-grow"
        label="Add Video / Doc"
        variant="outline"
        icon={<Video />}
        onClick={onFileAttClicked}
        isLoading={isPending}
      />
      <input
        ref={fileRef}
        type="file"
        capture="user"
        onChange={onObtainedFiles}
        className="hidden"
        data-testid="work-order-add-doc-btn"
      />
    </>
  );
}
