import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";
import AppButton from "../../../AppButton";
import { DataTablePagination } from "../../../DataTablePagination";
import { DialogFooter } from "../../../ui/dialog";
import { Input } from "../../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import { Base } from "../AppSelectWithDialog";

// This component is broken down, so that it can insert
// react hook form search form in it.

interface DialogContentWithSearchProps<
  T extends FieldValues = FieldValues,
  B extends Base = {
    id: undefined;
    name: "";
    title: string;
    unitPricing?: number;
  }
> {
  items: B[];
  value?: PathValue<T, Path<T>>;
  columns: ColumnDef<B, any>[];
  setRowSelection?: (rowSelection: B[]) => void;
  onSelected?: (selected: B[]) => void;
  closeDialog?: () => void;
  multiple?: boolean;
  showRowSelectionStr?: boolean;
}

export default function DialogContentWithSearchTable<
  T extends FieldValues,
  B extends Base
>({
  items,
  value,
  // setRowSelection,
  columns,
  onSelected,
  closeDialog,
  multiple = false,
  showRowSelectionStr = true,
}: DialogContentWithSearchProps<T, B>) {
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data: items ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    enableMultiRowSelection: multiple,
    initialState: {
      rowSelection: Object.fromEntries(
        items.map((ite, idx) => [
          idx,
          Array.isArray(value)
            ? value.map((v: any) => v.id).includes(ite.id)
            : (value as any)?.id === ite.id,
        ])
      ),
    },
    state: {
      columnVisibility: {
        id: false,
        locationId: false,
      },
      globalFilter,
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <Input
        value={globalFilter ?? ""}
        onChange={(event) => setGlobalFilter(String(event.target.value))}
        placeholder="Search..."
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className="hover:cursor-pointer"
                  onClick={() => {
                    row.toggleSelected();
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DataTablePagination
          table={table}
          showRowSelectionStr={showRowSelectionStr}
        />
      </div>
      <DialogFooter>
        <div className="flex gap-2 justify-end">
          <AppButton
            variant="outline"
            label="Cancel"
            onClick={() => {
              if (closeDialog) closeDialog();

              // onSelected(table.getSelectedRowModel().rows.map((r) => r.original));
            }}
          />
          {onSelected && (
            <AppButton
              label="Select"
              onClick={() => {
                onSelected(
                  table.getSelectedRowModel().rows.map((r) => r.original)
                );
              }}
            />
          )}
        </div>
      </DialogFooter>
    </div>
  );
}
