import { Checkbox } from "../../../../../../../../ui/checkbox";

interface SerialItemProps {
  serial: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export default function SerialItem({
  serial,
  value,
  onChange,
}: SerialItemProps) {
  return (
    <div
      key={serial}
      className="p-2 text-sm flex bg-slate-50 rounded-lg hover:cursor-pointer border border-slate-200 items-center justify-between"
    >
      <Checkbox
        className="rounded border-slate-300"
        checked={value}
        onCheckedChange={onChange}
      />
      {serial}
    </div>
  );
}
