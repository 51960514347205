import { Check, Copy, KeyRound, Mail, MailCheck, Pen } from "lucide-react";
import AppButton from "../../../AppButton";
import AppItemInfo from "../../../AppItemInfo";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../ui/dialog";
import AppTextField from "../../../AppTextField";
import { FormItem } from "../../../ui/form";
import { Input } from "../../../ui/input";
import { cn } from "../../../../lib/utils";
import { Button } from "../../../ui/button";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useAuthV2ControllerResetUserPassword } from "../../../../api/auth-v2/auth-v2";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";

// Change to reset password with user set password instead, similar to casework.
export default function ResetUserPasswordBtn() {
  const [searchParams] = useSearchParams();
  const firebaseUserId = searchParams.get("firebaseId") as string;
  const qc = useQueryClient();
  const [open, setIsOpen] = useState(false);
  const [copied, setIsCopied] = useState(false);
  let timer: NodeJS.Timeout;

  const methods = useForm<{ resetPassword: string }>({
    defaultValues: {
      resetPassword: "cerev-" + uuidv4().substring(0, 4),
    },
  });

  const { mutate, isPending } = useAuthV2ControllerResetUserPassword({
    mutation: {
      onSuccess: () => {
        setIsOpen(false);
        toast.success("Password reset successfully");
      },
    },
  });

  useEffect(() => {
    if (copied) {
      timer = setTimeout(() => setIsCopied(false), 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  const onSubmit: SubmitHandler<{ resetPassword: string }> = (data) => {
    mutate({
      firebaseUserId: firebaseUserId,
      data: {
        resetPassword: data.resetPassword,
      },
    });
  };

  return (
    <>
      <AppItemInfo
        icon={<Pen />}
        label="Actions"
        content={
          <div className="mt-1">
            <AppButton
              label="Reset Password"
              icon={<KeyRound />}
              variant="outline"
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
        }
      />
      <Dialog
        open={open}
        onOpenChange={() => {
          setIsOpen(false);
        }}
      >
        <DialogContent>
          <DialogTitle>Reset Password</DialogTitle>
          <div>The following will be the temporary password for the user</div>
          <div className="relative">
            <FormProvider {...methods}>
              <AppTextField name="resetPassword" className="text-slate-500" />
            </FormProvider>
            <Button
              className="absolute right-0 p-4 top-0"
              variant="ghost"
              onClick={() => {
                navigator.clipboard.writeText(
                  methods.getValues().resetPassword
                );
                setIsCopied(true);
              }}
            >
              {copied ? (
                <Check className="w-5 h-5 text-slate-400" />
              ) : (
                <Copy className="w-5 h-5 text-slate-400" />
              )}
            </Button>
          </div>
          <DialogFooter>
            <AppButton
              label="Cancel"
              variant="ghost"
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <AppButton
              label="Reset Password"
              isLoading={isPending}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
