import { ColumnDef } from "@tanstack/react-table";
import { AssetType } from "../../../../redux/slices/OpenApi/cerevApi";
import AssetTypeRowBtns from "../../component/AssetTypeRowBtns";

export const assetTypeTableColumns: ColumnDef<AssetType>[] = [
  {
    accessorKey: "id",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    header: "Actions",
    cell: ({ row }) => {
      return <AssetTypeRowBtns assetType={row.original} />;
    },
  },
];
