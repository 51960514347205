import { create } from "zustand";
import { GetWorkOrdersResponseDto } from "../../redux/slices/OpenApi/cerevApi";
import { OnChangeFn, RowSelectionState } from "@tanstack/react-table";

interface WorkOrderMultiExportPdfState {
  rowSelection: {};
  isSelectionMode: boolean;
  selectedWorkOrders: GetWorkOrdersResponseDto[];
  actions: {
    setRowSelection: OnChangeFn<RowSelectionState>;
    setSelectionMode: (mode: boolean) => void;
    setSelectedWorkOrders: (workOrders: GetWorkOrdersResponseDto[]) => void;
    cleanUp: () => void;
  };
}

export const useWorkOrderMutliExportPdfStore =
  create<WorkOrderMultiExportPdfState>((set, get) => ({
    rowSelection: {},
    isSelectionMode: false,
    selectedWorkOrders: [],
    actions: {
      setRowSelection: (u) => {
        if (typeof u === "function")
          return set((state) => ({ rowSelection: u(state.rowSelection) }));
        return set(() => ({ rowSelection: u }));
      },
      setSelectionMode: (mode) => {
        if (!mode) get().actions.cleanUp();
        return set(() => ({ isSelectionMode: mode }));
      },
      setSelectedWorkOrders: (workOrders) =>
        set(() => ({ selectedWorkOrders: workOrders })),
      cleanUp: () =>
        set(() => ({
          isSelectionMode: false,
          selectedWorkOrders: [],
          rowSelection: {},
        })),
    },
  }));

export const useWorkOrderMultiExportPdfActions = () =>
  useWorkOrderMutliExportPdfStore((state) => state.actions);
export const useWorkOrderMultiExportPdfRowSelection = () =>
  useWorkOrderMutliExportPdfStore((state) => state.rowSelection);
export const useWorkOrderMultiExportPdfSelectionMode = () =>
    useWorkOrderMutliExportPdfStore((state) => state.isSelectionMode);
export const useWorkOrderMultiExportPdfSelectedWorkOrders = () =>
    useWorkOrderMutliExportPdfStore((state) => state.selectedWorkOrders);

