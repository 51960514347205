import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Plus } from "lucide-react";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import {
  ASSET_TYPE_FORM_DRAWER,
  DRAWER_CREATE_STATE,
} from "../../../components/drawers/AppDrawer";
import { DataTable } from "../../../components/react-table/DataTable";
import useDebounce from "../../../hooks/useDebounce";
import { useAppSelector } from "../../../redux/store";
import { assetTypeTableColumns } from "./columns/assetTypeTableColumns";
import { AssetTypeControllerGetAssetTypeParams } from "../../../api/model";
import {
  useAssetTypeControllerGetAssetType,
  useAssetTypeControllerGetAssetTypeCount,
} from "../../../api/asset-type/asset-type";
import AppTextField from "../../../components/AppTextField";

export default function AssetTypeTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const search = useWatch({ control: methods.control, name: "search" });

  const queryArg = useDebounce<AssetTypeControllerGetAssetTypeParams>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      companyId: activeComp?.id?.toString() ?? "",
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
    },
    delay: 500,
  });

  const {
    data: assetTypes,
    isLoading: assetTypesIsLoading,
    isFetching: assetTypesIsFetching,
  } = useAssetTypeControllerGetAssetType(queryArg, {
    query: {
      enabled: !!activeComp,
    },
  });

  const { data: assetTypesCount, isLoading: assetTypesCountIsLoading } =
    useAssetTypeControllerGetAssetTypeCount(queryArg, {
      query: {
        enabled: !!activeComp,
      },
    });

  const table = useReactTable({
    data: assetTypes?.data ?? [],
    columns: assetTypeTableColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: assetTypesCount?.data
      ? Math.ceil(assetTypesCount?.data / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <AppTextField
            name="search"
            placeholder="Search..."
            className="w-[400px]"
          />
          <AppButton
            icon={<Plus />}
            label="Add Equipment Type"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", ASSET_TYPE_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
        <DataTable
          isLoading={assetTypesIsLoading}
          isFetching={assetTypesIsFetching}
          table={table}
          totalCount={assetTypesCount?.data ?? 0}
          rowsPerPage={pagination.pageSize}
          pageIdx={pagination.pageIndex}
        />
      </div>
    </FormProvider>
  );
}
