import { CreateStockDtoInventoryMode } from "../../../../../../../../api/model";
import { useSerialiedInventoryEnabled } from "../../../../../../../../hooks/useFeatures";
import { useWorkOrderInventoryLedgers } from "../../../../../../../../stores/workOrderInventoryStore/workOrderInventoryStore";
import { Card } from "../../../../../../../ui/card";
import SerialList from "./components/SerialList";

export default function ConfirmInventorySection() {
  const ledgers = useWorkOrderInventoryLedgers();
  const isSerializedEnabled = useSerialiedInventoryEnabled();

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2 mt-4">
        {isSerializedEnabled && (
          <h3 className="text-lg font-semibold">Quantity Inventory</h3>
        )}
        <div className="grid grid-cols-3 gap-4">
          {ledgers
            .filter(
              (l) =>
                l.stock.inventoryMode === CreateStockDtoInventoryMode.QUANTITY
            )
            .map((l) => {
              return (
                <div
                  className="bg-slate-50 rounded-lg  flex justify-center"
                  key={l.fieldId}
                >
                  <div className="p-3 flex flex-col items-center justify-center bg-primary-900 rounded-tl-lg rounded-bl-lg">
                    <p className="text-2xl font-bold text-white">
                      {l.adjustmentQty}
                    </p>
                    <p className="text-sm text-white">nos.</p>
                  </div>
                  <div className="flex-grow flex flex-col p-4 gap-1 justify-center">
                    <p className="font-semibold font-xs">
                      {l?.stock?.name ?? "-"}
                    </p>
                    {l?.stock?.isMultiLocation && (
                      <p className="text-sm text-slate-500">
                        from {l?.location?.name ?? "-"}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {isSerializedEnabled && (
        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-semibold">Serialized Inventory</h3>
          {ledgers
            .filter(
              (l) =>
                l.stock.inventoryMode === CreateStockDtoInventoryMode.SERIALIZED
            )
            .map((l) => {
              return (
                <Card key={l?.id} className="flex flex-col p-6">
                  <p>{l?.stock?.name ?? "-"}</p>
                  <p className="text-sm text-slate-300">
                    {l?.stock?.stockType?.name ?? "-"}
                  </p>
                  <h3 className="my-3 text-sm text-primary-900">
                    Please select {l?.adjustmentQty} serial number
                  </h3>
                  <SerialList ledger={l} />
                </Card>
              );
            })}
        </div>
      )}
    </div>
  );
}
