import React from "react";
import { useSignatoryEnabled } from "../hooks/useFeatures";

interface SignatoryGuardProps {
  children: React.ReactNode;
}

export default function SignatoryGuard({ children }: SignatoryGuardProps) {
  const hasAccess = useSignatoryEnabled();
  if (!hasAccess) return <></>;

  return <>{children}</>;
}
