import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Camera, Delete, SendHorizonal } from "lucide-react";
import { useRef } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import {
  useCommentControllerCreateComment,
  useCommentControllerGetComments,
} from "../../../../../api/comments/comments";
import { GetOneWorkOrderResponseDto } from "../../../../../api/model";
import useAppStorage from "../../../../../hooks/useAppStorage";
import useIsCloseWorkOrder from "../../../../../hooks/useIsCloseWorkOrder";
import { useGetUserDataQuery } from "../../../../../redux/slices/Auth/AuthApi";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import AppButton from "../../../../AppButton";
import AppTextField from "../../../../AppTextField";
import DrawerFormSkeleton from "../../../../skeletons/DrawerFormSkeleton";
import AppComment from "./components/AppComment";
import CommentActions from "./components/CommentActions";
import AppCenterEmpty from "../../../../AppCenterEmpty";
import IsCloseAndEditAfterWorkOrderGuard from "../../components/IsCloseAndEditAfterWorkOrderGuard";

const WorkOrderCommentSchema = z.object({
  comment: z.string().min(1, { message: "Please add a comment" }),
  attachments: z.instanceof(File).array().optional(),
});

export type WorkOrderCommentForm = z.infer<typeof WorkOrderCommentSchema>;

export default function WorkOrderCommentTab({
  workOrder,
}: {
  workOrder?: GetOneWorkOrderResponseDto;
}) {
  const qc = useQueryClient();
  const dispatch = useAppDispatch();
  const activeProject = useAppSelector((state) => state.root.activeProject);
  const { data: user } = useGetUserDataQuery();
  const cameraRef = useRef(null);
  const isClose = useIsCloseWorkOrder({ workOrder });
  const onGalleryClicked = () => {
    (cameraRef.current as any).click();
  };
  const methods = useForm<WorkOrderCommentForm>({
    resolver: zodResolver(WorkOrderCommentSchema),
    defaultValues: {
      comment: "",
      attachments: [],
    },
  });

  const { data: comments, isLoading } = useCommentControllerGetComments(
    {
      defectId: workOrder?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!workOrder,
      },
    }
  );

  const { mutateAsync: createComment } = useCommentControllerCreateComment();
  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => {
          return (query.queryKey[0] as string).includes("comment");
        },
      });
      methods.reset({ comment: "", attachments: [] });
    },
  });

  const onSubmit: SubmitHandler<WorkOrderCommentForm> = async (data) => {
    await mutate({
      files: data.attachments ?? [],
      mutateAsync: async (atts) => {
        if (!user || !activeProject)
          throw new Error("User or Project is not defined");
        await createComment({
          data: {
            createdById: user?.id.toString(),
            defectId: workOrder?.id?.toString() ?? "",
            projectId: activeProject?.id?.toString() ?? "",
            description: data.comment,
            attachments: atts ?? [],
          },
        });
      },
    });
  };

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <IsCloseAndEditAfterWorkOrderGuard>
          <div className="flex gap-4 w-full items-start">
            <div className="bg-neutral-100 w-full rounded-xl border-neutral-900">
              <AppTextField
                name="comment"
                placeholder="Insert a comment here..."
                inputClassName="border-0 bg-transparent focus-visible:ring-2 focus-visible:ring-offset-1 mt-1"
              />
              <Controller
                control={methods.control}
                name="attachments"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <input
                        ref={cameraRef}
                        type="file"
                        className="hidden"
                        multiple
                        onChange={(event) => {
                          let fileArray = [];
                          const fileList = event.target.files;
                          if (!fileList) return;
                          for (let i = 0; i < fileList.length; i++) {
                            fileArray.push(fileList[i]);
                          }
                          onChange(fileArray);
                        }}
                      />
                      {!!value?.length && (
                        <div className="flex flex-wrap gap-2 p-2">
                          {value?.map((pho, idx) => {
                            return (
                              <div className="relative" key={idx}>
                                <img
                                  className="w-32 h-32 rounded-xl object-cover shadow-xl"
                                  src={URL.createObjectURL(pho)}
                                  key={idx}
                                />
                                <AppButton
                                  className="absolute top-2 right-2 rounded-full bg-neutral-900"
                                  onClick={() => {
                                    const newSetPhotos = value.filter(
                                      (v) => v !== pho
                                    );
                                    onChange(newSetPhotos);
                                  }}
                                  icon={<Delete className="text-red-600" />}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <AppButton
              icon={<Camera />}
              onClick={onGalleryClicked}
              className="h-12"
            />
            <AppButton
              icon={<SendHorizonal />}
              onClick={methods.handleSubmit(onSubmit)}
              className="h-12"
              isLoading={isPending}
            />
          </div>
        </IsCloseAndEditAfterWorkOrderGuard>
        {comments?.data?.map((comment) => (
          <AppComment key={comment.id} comment={comment} />
        ))}
        {comments?.data?.length === 0 && <AppCenterEmpty />}
      </div>
    </FormProvider>
  );
}
