import {
  useBimControllerDeleteBimNodes,
  useBimControllerGetBimModel,
} from "../../../api/bim/bim";
import AppButton from "../../../components/AppButton";
import { useAppSelector } from "../../../redux/store";

export default function DeleteBimNodeBtn() {
  const { mutate, isPending } = useBimControllerDeleteBimNodes();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: bimModel } = useBimControllerGetBimModel(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );
  return (
    <AppButton
        label="Delete Nodes"
      isLoading={isPending}
      variant="destructive"
      onClick={() => {
        if (!bimModel) return;
        mutate({
          bimId: bimModel?.id ?? 0,
        });
      }}
    />
  );
}
