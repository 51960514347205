import { zodResolver } from "@hookform/resolvers/zod";
import { Rating, Typography } from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useGetUserDataQuery } from "../../redux/slices/Auth/AuthApi";
import {
  useDefectControllerUpdateDefectMutation,
  useDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQuery,
} from "../../redux/slices/OpenApi/cerevApi";
import { setCloseDialog } from "../../redux/slices/RootSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AppButton from "../AppButton";
import AppTextAreaField from "../AppTextAreaField";
import { useAuthControllerGetCurrentUser } from "../../api/auth/auth";
import { useDefectControllerUpdateDefect } from "../../api/work-orders-defects/work-orders-defects";
import { useQueryClient } from "@tanstack/react-query";

const ConfirmWorkOrderCloseFormSchema = z.object({
  closingRemark: z.string().optional(),
  rating: z.number().default(5),
});

export type ConfirmWorkOrderCloseForm = z.infer<
  typeof ConfirmWorkOrderCloseFormSchema
>;

export default function ConfirmWorkOrderCloseDialog() {
  const qc = useQueryClient();
  const [searchParams] = useSearchParams();
  const workOrderId = searchParams.get("workOrderId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dispatch = useAppDispatch();
  const methods = useForm<ConfirmWorkOrderCloseForm>({
    resolver: zodResolver(ConfirmWorkOrderCloseFormSchema),
    defaultValues: {
      closingRemark: "",
      rating: 5,
    },
  });

  // const [
  //   updateWorkStatus,
  //   { isLoading: updateWorkStatusIsLoading, isError: updateWorkStatusIsError },
  // ] = useDefectControllerUpdateDefectMutation();

  const {
    mutateAsync: updateWorkStatus,
    isPending: updateWorkStatusIsLoading,
  } = useDefectControllerUpdateDefect({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("work-order") ||
            (query.queryKey[0] as string).includes("defect"),
        });
      },
    },
  });

  const { data: user } = useAuthControllerGetCurrentUser({
    query: {
      select: (res) => res.data,
    },
  });

  const { data: workOrderStatuses } =
    useDefectStatusV2ControllerGetProjectBasedWorkOrderStatusQuery(
      {
        roleId:
          user?.roles
            ?.filter((role) => role.projectId === activeProj?.id ?? "")[0]
            .id?.toString() ?? "",
      },
      { skip: !activeProj }
    );

  const onConfirm: SubmitHandler<ConfirmWorkOrderCloseForm> = async (data) => {
    if (workOrderId && user && activeProj && workOrderStatuses) {
      const idenCloseStatus = workOrderStatuses.find(
        (sta) => sta.name === "Close"
      );

      if (!idenCloseStatus) return;
      await updateWorkStatus({
        defectId: workOrderId,
        data: {
          defectId: +workOrderId,
          updatedById: user?.id ?? 0,
          type: "CLOSE",
          defectStatusId: idenCloseStatus.id,
          closingRemark: data.closingRemark,
          rating: data.rating,
          projectId: activeProj?.id ?? 0,
        },
      });
      dispatch(setCloseDialog());
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <AppTextAreaField label="Closing Remark" name="closingRemark" />
        <div className="flex flex-col gap-2">
          <p className="font-sans">Quality Rating</p>
          <Controller
            defaultValue={5}
            control={methods.control}
            name="rating"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <div className="flex">
                  <Rating
                    name="Quality Rating"
                    value={value}
                    size="large"
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="flex flex-row-reverse gap-2">
          <AppButton
            isLoading={updateWorkStatusIsLoading}
            label="Submit"
            onClick={methods.handleSubmit(onConfirm)}
          />
          <AppButton
            variant="ghost"
            label="Cancel"
            onClick={() => {
              dispatch(setCloseDialog());
            }}
          />
        </div>
      </div>
    </FormProvider>
  );
}
