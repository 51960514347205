import { Pencil } from "lucide-react";
import AppButton from "../../../../../../../../../../components/AppButton";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../../../../../../../../../components/ui/dialog";
import AppTextField from "../../../../../../../../../../components/AppTextField";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useProjectControllerAdjustUserQuota,
  useProjectControllerFindOneProject,
} from "../../../../../../../../../../api/projects/projects";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const UserQuotaSchema = z.object({
  userQuota: z.coerce.number().min(1, "Please provide a number"),
});

export type UserQuotaForm = z.infer<typeof UserQuotaSchema>;

export default function EditUserQuotaBtnDialog() {
  const qc = useQueryClient();
  const { projectId } = useParams();
  const [open, setOpen] = useState(false);
  const methods = useForm<UserQuotaForm>({
    resolver: zodResolver(UserQuotaSchema),
    defaultValues: {
      userQuota: 0,
    },
  });

  const { data: project } = useProjectControllerFindOneProject(
    projectId as string,
    {
      query: {
        select: (res) => res.data.data,
      },
    }
  );

  const { mutate, isPending } = useProjectControllerAdjustUserQuota({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] as string).includes("project"),
        });
        setOpen(false);
      },
    },
  });

  useEffect(() => {
    if (project) methods.setValue("userQuota", project.userQuota);
  }, [project]);

  const onSubmit: SubmitHandler<UserQuotaForm> = (data) => {
    if (!projectId) return;
    mutate({
      projectId,
      data: {
        userQuota: data.userQuota,
      },
    });
  };

  return (
    <>
      <AppButton
        icon={<Pencil />}
        onClick={() => setOpen(true)}
        variant="outline"
      />
      <Dialog
        open={open}
        onOpenChange={(o) => {
          setOpen(o);
          methods.reset();
        }}
      >
        <DialogContent>
          <DialogTitle>Change User Quota</DialogTitle>
          <FormProvider {...methods}>
            <AppTextField name="userQuota" label="User Quota" type="number" />
            <DialogFooter>
              <AppButton
                label="Confirm"
                isLoading={isPending}
                onClick={methods.handleSubmit(onSubmit)}
              />
            </DialogFooter>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
