import { PDFViewer } from "@react-pdf/renderer";
import { JkrApdSummaryPdf } from "@cerev-cmms/pdf";
import { useParams } from "react-router-dom";
import { trpc } from "../../lib/trpc";
import { useEffect } from "react";
import { useAppSelector } from "../../redux/store";

export function JkrApdSummaryPdfReview() {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: workOrders } = trpc.workOrders.getWorkOrders.useQuery(
    {
      projectId: activeProj?.id?.toString(),
    },
    {
      enabled: !!activeProj,
    }
  );

  useEffect(() => {
    console.log(workOrders);
  }, [workOrders]);

  return (
    <PDFViewer style={{ height: "100%", width: "100%", borderRadius: "10px" }}>
      <JkrApdSummaryPdf workOrders={[]} />
    </PDFViewer>
  );
}
