import { ColumnDef, Row } from "@tanstack/react-table";
import {
  WorkPermitWithWorkersResponseDto,
  WorkPermitWorker,
} from "../../../../redux/slices/OpenApi/cerevApi";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  WORK_PERMIT_DETAIL_DRAWER,
} from "../../../../components/drawers/AppDrawer";

const WorkPermitCompanyNav = ({
  workPermit,
}: {
  workPermit: Row<WorkPermitWithWorkersResponseDto>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", WORK_PERMIT_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("workPermitId", workPermit.getValue("id"));
          return p;
        });
      }}
    >
      {workPermit.getValue("applierCompanyName") ?? "-"}
    </div>
  );
};

export const workPermitColumns: ColumnDef<WorkPermitWithWorkersResponseDto>[] =
  [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "applierCompanyName",
      header: "Company",
      cell: ({ row }) => <WorkPermitCompanyNav workPermit={row} />,
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "locationOfWork",
      header: "Location of Work",
    },
  ];
