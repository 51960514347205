import { isAfter } from "date-fns";
import { FormProvider, useFormContext } from "react-hook-form";
import { z } from "zod";
import { AppDateTimePickerForm } from "../../../../datetimePicker/AppDateTimePickerForm";
import AppTextAreaField from "../../../../AppTextAreaField";
import AppSelectWithDialog from "../../../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { assignedUserColumns } from "../../columns/assignedUserColumns";
import useCurrentUser from "../../../../../hooks/useCurrentUser/useCurrentUser";
import { useAppSelector } from "../../../../../redux/store";
import { useUserControllerGetUsers } from "../../../../../api/users/users";
import { useSearchParams } from "react-router-dom";
import { useWoTimeLogsControllerReadOneTimeLog } from "../../../../../api/wo-time-logs/wo-time-logs";
import { timeLog } from "console";
import { useEffect } from "react";
import { Loader2 } from "lucide-react";

export const timeLogSchema = z.object({
  timeRange: z
    .object({
      dateStart: z.coerce.date({ required_error: "Start date is required" }),
      dateEnd: z.coerce.date({ required_error: "End date is required" }),
    })
    .refine((data) => isAfter(data.dateEnd, data.dateStart), {
      message: "End date should be greater than start date",
      path: ["dateEnd"],
    }),
  description: z.string().optional(),
  doneBy: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array()
    .min(1, { message: "Please select a user" }),
});

export type TimeLogFormType = z.infer<typeof timeLogSchema>;

export default function ManHourForm() {
  const [searchParam, setSearchParams] = useSearchParams();
  const timeLogId = searchParam.get("timeLogId");
  const isEdit = !!timeLogId;
  const methods = useFormContext<TimeLogFormType>();

  const { data: user } = useCurrentUser();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: editTimeLog, isLoading } =
    useWoTimeLogsControllerReadOneTimeLog(Number(timeLogId) ?? 0, {
      query: {
        enabled: !!timeLogId,
        select: (res) => res.data,
      },
    });

  const { data: usersData } = useUserControllerGetUsers(
    {
      projectId: activeProj?.id ?? 0,
      userId: user?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeProj && !!user && !!activeComp,
      },
    }
  );

  useEffect(() => {
    if (isEdit && editTimeLog) {
      methods.setValue("timeRange.dateStart", new Date(editTimeLog.dateStart));
      methods.setValue("timeRange.dateEnd", new Date(editTimeLog.dateEnd));
      methods.setValue("description", editTimeLog?.description ?? "");
      methods.setValue(
        "doneBy",
        editTimeLog.doneBy?.map((user) => ({
          id: user?.id ?? 0,
          name: user?.name ?? "",
        }))
      );
    }
  }, [editTimeLog]);

  if (isLoading) {
    return (
      <div className="h-64 flex flex-col items-center justify-center gap-4">
        <Loader2 className="h-10 w-10 animate-spin text-primary-900" />
        <p className="text-sm text-slate-400">Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-10">
      <AppDateTimePickerForm name="timeRange.dateStart" label="Start Time" />
      <AppDateTimePickerForm name="timeRange.dateEnd" label="End Time" />
      <AppTextAreaField name="description" label="Description" />
      <AppSelectWithDialog
        columns={assignedUserColumns}
        label="Done By"
        placeholder="Select Users"
        defaultValue={[]}
        control={methods.control}
        name="doneBy"
        multiple
        items={usersData?.data ?? []}
        onResultRender={(item, idx) => (
          <div key={idx} className="flex flex-col">
            <div className="font-medium">{item?.name}</div>
            <div className="font-thin mt-1">{item?.position ?? "-"}</div>
          </div>
        )}
        onOptionsRender={(item, idx) => (
          <div key={idx} className="flex flex-col">
            <div className="font-medium">{item?.name}</div>
            <div className="font-thin mt-1">{item?.position ?? "-"}</div>
          </div>
        )}
        dialogTitle="Select Users"
        error={!!methods.formState.errors.doneBy?.message}
        helperText={methods.formState.errors.doneBy?.message}
      />
    </div>
  );
}
