import { Building } from "lucide-react";
import { CompanyEntity } from "../../../../../api/model";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card";
import { Link } from "react-router-dom";
import { Badge } from "../../../../../components/ui/badge";
import { cn } from "../../../../../lib/utils";

interface CompanyCardItemProps {
  company: CompanyEntity;
}

export default function CompanyCardItem({ company }: CompanyCardItemProps) {
  return (
    <Link to={`${company.id}`}>
      <Card className="transition-all duration-200 hover:shadow-xl hover:cursor-pointer">
        {company?.attachments?.length > 0 ? (
          <img
            src={company?.attachments?.[0]?.url ?? ""}
            alt=""
            className="object-cover w-full aspect-video"
          />
        ) : (
          <div className="aspect-video bg-slate-100 flex flex-col items-center justify-center">
            <Building className="w-24 h-24 text-primary-900" />
          </div>
        )}
        <div>
          <CardHeader>
            <CardTitle>{company?.name ?? ""}</CardTitle>
            <div>
              <Badge
                className={cn("bg-slate-400", {
                  "bg-green-500":
                    company?.environmentType === "PRODUCTION",
                })}
              >
                {company?.environmentType ?? "-"}
              </Badge>
            </div>
          </CardHeader>
        </div>
      </Card>
    </Link>
  );
}
