import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppDialog from "../../components/dialogs/AppDialog";
import AppDrawer from "../../components/drawers/AppDrawer";
import GlobalSnackbar from "../../components/snackbar/GlobalSnackbar";
import { cn } from "../../lib/utils";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AppSideNavigationDrawer from "./components/AppSideNavigationDrawer";
import CerevAppBar from "./components/CerevAppBar/CerevAppBar";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/ui/resizable";
import {
  maximizeDrawer,
  maxOrMinimizeMainDrawer,
  minimizeDrawer,
} from "../../redux/slices/RootSlice";
import { getPanelElement, ImperativePanelHandle } from "react-resizable-panels";
import { ScrollArea } from "../../components/ui/scroll-area";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";

export default function HomeScreen() {
  const dispatch = useAppDispatch();
  const panelRef = useRef<ImperativePanelHandle>(null);

  const isOpenDrawer = useAppSelector(
    (state) => state.root.isMinimizedMainDrawer
  );

  // const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const location = useLocation();
  const hideDrawer =
    location.pathname.includes("/home/settings") ||
    location.pathname.includes("/home/super-admin");
  const navigate = useNavigate();

  // To have indexed route when first navigating into home.
  useEffect(() => {
    if (location.pathname === "/home") navigate("/home/dashboard");
  }, [location]);

  return (
    <>
      <AppDialog />
      <AppDrawer />
      <ResizablePanelGroup
        direction="horizontal"
        className="h-full min-h-screen items-stretch"
      >
        <ResizablePanel
          ref={panelRef}
          id="drawer-panel"
          defaultSize={hideDrawer ? 0 : isOpenDrawer ? 15 : 0}
          collapsible
          minSize={15}
          maxSize={15}
          className={cn("min-w-[60px]", {
            ["hidden"]: hideDrawer,
            [`transition-all duration-300 ease-in-out flex-grow flex flex-col`]:
              !hideDrawer,
            [`min-w-[265px]`]: isOpenDrawer,
          })}
          onCollapse={() => dispatch(minimizeDrawer())}
          onExpand={() => dispatch(maximizeDrawer())}
        >
          <AppSideNavigationDrawer panelRef={panelRef} />
        </ResizablePanel>
        <ResizableHandle className="hover:bg-blue-500 w-1 hover:h-screen ease-in-out duration-300 z-10 bg-transparent" />
        <ResizablePanel>
          <ScrollArea className="h-screen">
            <div
              className={cn({
                [`transition-all duration-300 ease-in-out flex-grow flex flex-col min-h-screen`]:
                  !hideDrawer,
              })}
            >
              <GlobalSnackbar />
              <div
                className={cn({
                  "pt-6 px-6 w-full flex flex-col flex-grow gap-4": !hideDrawer,
                })}
              >
                {!hideDrawer && <CerevAppBar />}
                <div className="flex-grow flex flex-col w-full">
                  <Outlet />
                </div>
              </div>
            </div>
          </ScrollArea>
        </ResizablePanel>
      </ResizablePanelGroup>
    </>
  );
}
