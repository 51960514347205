/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { PmFormatType } from ".././model";

export const pmFormatTypeControllerGetPmFormatTypes = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PmFormatType[]>> => {
  return axios.get(`/pm-format-type`, options);
};

export const getPmFormatTypeControllerGetPmFormatTypesQueryKey = () => {
  return [`/pm-format-type`] as const;
};

export const getPmFormatTypeControllerGetPmFormatTypesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>
  >,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmFormatTypeControllerGetPmFormatTypesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>
  > = ({ signal }) =>
    pmFormatTypeControllerGetPmFormatTypes({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmFormatTypeControllerGetPmFormatTypesInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>
  >;
export type PmFormatTypeControllerGetPmFormatTypesInfiniteQueryError =
  AxiosError<unknown>;

export const usePmFormatTypeControllerGetPmFormatTypesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>
  >,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmFormatTypeControllerGetPmFormatTypesInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPmFormatTypeControllerGetPmFormatTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPmFormatTypeControllerGetPmFormatTypesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>
  > = ({ signal }) =>
    pmFormatTypeControllerGetPmFormatTypes({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PmFormatTypeControllerGetPmFormatTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>
>;
export type PmFormatTypeControllerGetPmFormatTypesQueryError =
  AxiosError<unknown>;

export const usePmFormatTypeControllerGetPmFormatTypes = <
  TData = Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof pmFormatTypeControllerGetPmFormatTypes>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getPmFormatTypeControllerGetPmFormatTypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
