import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { Check, Pen } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import {
  useStocksControllerAdjustWarningInventoryLevels,
  useStocksControllerGetOneStockWithLedger,
} from "../../../../api/stocks-inventory/stocks-inventory";
import AppButton from "../../../AppButton";
import AppTextField from "../../../AppTextField";
import InventoryPolicyGuard from "../../../../guards/InventoryPolicyGuard";

const AdjustWarningBalanceSchema = z.object({
  warningBalance: z.coerce.number().int(),
});

export type AdjustWarningBalanceForm = z.infer<
  typeof AdjustWarningBalanceSchema
>;

export default function AdjustWarningBalanceSection() {
  const qc = useQueryClient();
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");
  const methods = useForm<AdjustWarningBalanceForm>({
    resolver: zodResolver(AdjustWarningBalanceSchema),
    defaultValues: {
      warningBalance: 0,
    },
  });

  const { data: inventoryData } = useStocksControllerGetOneStockWithLedger(
    inventoryId ?? "",
    {
      query: {
        enabled: !!inventoryId,
        select: (res) => res.data,
      },
    }
  );

  useEffect(() => {
    if (inventoryData) {
      methods.reset({
        warningBalance: inventoryData.warningBalance,
      });
    }
  }, [inventoryData]);

  const { mutate: updateInventoryWarningLevels, isPending } =
    useStocksControllerAdjustWarningInventoryLevels({
      mutation: {
        onSuccess: () => {
          setIsEdit(false);
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("stock"),
          });
        },
      },
    });

  const onSubmit: SubmitHandler<AdjustWarningBalanceForm> = (data) => {
    if (!inventoryId) return;
    updateInventoryWarningLevels({
      stockId: +inventoryId,
      data: {
        warningBalance: data.warningBalance,
      },
    });
  };

  return (
    <div className="my-4 flex flex-col gap-4">
      <p className="text-xl font-semibold">Balance Warning</p>
      <div className="flex gap-4 justify-between items-center">
        <p>Warning levels</p>
        <div className="flex gap-1 items-start">
          {isEdit ? (
            <FormProvider {...methods}>
              <AppTextField name="warningBalance" />
            </FormProvider>
          ) : (
            <div className="py-2 px-4 rounded-md border border-slate-200">
              <p className="text-xl font-medium text-primary-900">
                {new Decimal(inventoryData?.warningBalance ?? 0).toString()}
              </p>
            </div>
          )}
          <InventoryPolicyGuard>
            {isEdit ? (
              <AppButton
                icon={<Check className="text-primary-900" />}
                onClick={methods.handleSubmit(onSubmit)}
                variant="ghost"
                isLoading={isPending}
              />
            ) : (
              <AppButton
                variant="ghost"
                icon={<Pen className="text-primary-900" />}
                onClick={() => setIsEdit(true)}
              />
            )}
          </InventoryPolicyGuard>
        </div>
      </div>
    </div>
  );
}
