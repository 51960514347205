import { useSearchParams } from "react-router-dom";
import { useLegalComplianceControllerGetOneLegalComplianceQuery } from "../../redux/slices/OpenApi/cerevApi";
import DrawerFormSkeleton from "../skeletons/DrawerFormSkeleton";
import AppButton from "../AppButton";
import { DRAWER_UPDATE_STATE, LEGAL_COMPLIANCE_FORM_DRAWER } from "./AppDrawer";
import AppItemInfo from "../AppItemInfo";
import useDate from "../../hooks/useDate";
import AppDocument from "../AppDocument";
import { AlignLeft, Calendar, Files, Pencil, Tag } from "lucide-react";
import { useLegalComplianceControllerGetOneLegalCompliance } from "../../api/legal-compliance/legal-compliance";

export default function LegalComplianceDetailDrawer() {
  const { formatDateToLocal } = useDate();
  const [searchParam, setSearchParams] = useSearchParams();
  const legalComplianceId = searchParam.get("legalComplianceId");

  const { data: legal, isLoading } =
    useLegalComplianceControllerGetOneLegalCompliance(
      legalComplianceId ? +legalComplianceId : 0,
      {
        query: {
          enabled: !!legalComplianceId,
          select: (res) => res.data,
        },
      }
    );

  // const {}

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <p className="font-sans text-2xl font-bold">{legal?.name ?? "-"}</p>
        <AppButton
          label="Edit"
          variant="outline"
          icon={<Pencil />}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", LEGAL_COMPLIANCE_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("legalComplianceId", legalComplianceId ?? "");
              return p;
            });
          }}
        />
      </div>
      <AppItemInfo
        label="Name"
        icon={<Tag className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">{legal?.name ?? "-"}</p>
        }
      />
      <AppItemInfo
        label="Description"
        icon={<AlignLeft className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {legal?.description ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Expiry Date"
        icon={<Calendar className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {legal?.expiryDate
              ? formatDateToLocal({
                  dateStr: legal.expiryDate,
                  formatting: "d MMM yyyy",
                })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Documents"
        icon={<Files className="text-primary-900" />}
        content={
          <div className="flex flex-col gap-4">
            {legal?.documents?.length ? (
              legal?.documents.map((p) => <AppDocument key={p.id} att={p} />)
            ) : (
              <p className="font-sans text-neutral-900">-</p>
            )}
          </div>
        }
      />
    </div>
  );
}
