import { useHasWorkOrderPricingPolicy } from "../hooks/usePolicies/usePolicies";

interface WorkOrderPricingPolicyGuard {
  children: React.ReactNode;
}

export default function WorkOrderPricingPolicyGuard({
  children,
}: WorkOrderPricingPolicyGuard) {
  const hasAccess = useHasWorkOrderPricingPolicy();

  if (!hasAccess) return <></>;

  return <>{children}</>;
}
