import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import {
  usePmChecklistControllerGetAssetWithPmCountQuery,
  usePmChecklistControllerGetLocationsWithPmCountsQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import {
  Breadcrumbs,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Home, Place, Settings } from "@mui/icons-material";
import AppButton from "../../../components/AppButton";
import {
  PM_DETAIL_DRAWER,
  DRAWER_VIEW_STATE,
} from "../../../components/drawers/AppDrawer";
import AppTable from "../../../components/table/AppTable";

export default function PmLocationScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);

  const {
    data: pmLocList,
    isLoading: pmLocListIsLoading,
    isFetching,
  } = usePmChecklistControllerGetLocationsWithPmCountsQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
      companyId: activeComp?.id.toString() ?? "",
      parentId:
        searchParams.getAll("locationHistory")?.length === 0
          ? ""
          : searchParams
              .getAll("locationHistory")
              [searchParams.getAll("locationHistory").length - 1]?.split(
                "&"
              )[0], // This is primary location, so empty parentId string
    },
    {
      skip: !activeProj || !activeComp,
    }
  );

  const { data: assetPmList, isLoading: assetPmListIsLoading } =
    usePmChecklistControllerGetAssetWithPmCountQuery(
      {
        projectId: activeProj?.id?.toString() ?? "",
        locationId:
          searchParams.getAll("locationHistory")?.length !== 0
            ? searchParams
                .getAll("locationHistory")
                [searchParams.getAll("locationHistory").length - 1].split(
                  "&"
                )[0]
            : "",
        companyId: activeComp?.id.toString() ?? "",
      },
      {
        skip: !activeProj || !activeComp,
      }
    );

  return (
    <>
      <Breadcrumbs>
        {searchParams.getAll("locationHistory").length > 0 && (
          <IconButton
            onClick={() => {
              setSearchParams((p) => {
                p.delete("locationHistory");
                return p;
              });
            }}
          >
            <Home />
          </IconButton>
        )}
        {searchParams.getAll("locationHistory").map((loc, idx) => {
          const [locId, locName] = loc.split("&");
          return (
            <AppButton
              variant="ghost"
              key={locId}
              label={locName}
              onClick={() => {
                // Remove all locationHistory after this index
                setSearchParams((p) => {
                  const allLocs = p.getAll("locationHistory");
                  p.delete("locationHistory");
                  for (let i = 0; i <= idx; i++) {
                    p.append("locationHistory", allLocs[i]);
                  }
                  return p;
                });
              }}
            />
          );
        })}
      </Breadcrumbs>
      <div className="my-2"></div>
      <AppTable
        colSpan={3}
        isLoading={isFetching}
        isEmpty={
          pmLocList?.length === 0 &&
          assetPmList?.length === 0 &&
          !assetPmListIsLoading &&
          !pmLocListIsLoading
        }
        headerTableRow={
          <TableHead>
            <TableRow>
              <TableCell className="font-sans text-lg font-bold">
                <p className="font-sans text-lg font-bold">
                Location

                </p>
              </TableCell>
              <TableCell className="font-sans text-lg font-bold">
                <div className="flex items-center">
                  <p className="font-bold text-lg font-sans">
                    PM Count
                  </p>
                </div>
              </TableCell>
              <TableCell className="font-sans text-lg font-bold"></TableCell>
            </TableRow>
          </TableHead>
        }
        bodyTableRow={
          <>
            {pmLocList?.map((loc) => (
              <TableRow key={loc.id}>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <Place className="text-primary-900 w-8 h-8" />
                    <div className="flex flex-col">
                      <p className="font-sans">{loc.name}</p>
                      <p className="text-neutral-600 text-sm">
                        Location
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-3">
                    {loc.statusCounts.map((sta) => (
                      <div
                        className="rounded-xl py-2 px-5 flex gap-2"
                        style={{ backgroundColor: `#${sta.colorHex}` }}
                        key={sta.id}
                      >
                        <p className="font-sans text-white font-bold">
                          {sta.count}
                        </p>
                        <p className="font-sans text-white font-bold">
                          {sta.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex gap-2 flex-row-reverse">
                    <AppButton
                      variant="outline"
                      label="Drill Down"
                      onClick={() => {
                        setSearchParams((p) => {
                          p.append(
                            "locationHistory",
                            `${loc.id.toString()}&${loc.name}`
                          );
                          return p;
                        });
                      }}
                    />
                    <AppButton
                      variant="outline"
                      label="View"
                      onClick={() => {
                        setSearchParams((p) => {
                          p.set("drawerType", PM_DETAIL_DRAWER);
                          p.set("drawerState", DRAWER_VIEW_STATE);
                          p.set("locationId", loc.id.toString());
                          p.set("pmDetailTabId", "0");
                          return p;
                        });
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {assetPmList?.map((asset) => (
              <TableRow key={asset.id}>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <Settings className="text-primary-900 w-8 h-8" />
                    <div className="flex flex-col">
                      <p className="font-sans">
                        {asset.name}
                      </p>
                      <p className="text-neutral-600 text-sm">
                        Asset
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-3">
                    {asset.statusCounts.map((sta) => (
                      <div
                        className="rounded-xl py-2 px-5 flex gap-2"
                        style={{ backgroundColor: `#${sta.colorHex}` }}
                        key={sta.id}
                      >
                        <p className="font-sans text-white font-bold">
                          {sta.count}
                        </p>
                        <p className="font-sans text-white font-bold">
                          {sta.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex gap-2 flex-row-reverse">
                    <AppButton
                      variant="outline"
                      label="View"
                      onClick={() => {
                        setSearchParams((p) => {
                          p.set("drawerType", PM_DETAIL_DRAWER);
                          p.set("drawerState", DRAWER_VIEW_STATE);
                          p.set("assetId", asset.id.toString());
                          return p;
                        });
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </>
        }
      />
    </>
  );
}
