import React from "react";
import { PtwTemplateFormType } from "../../ptwTemplateSchema";
import { UseFieldArrayReturn } from "react-hook-form";
import PmFormatActions from "./PmFormatActions";

interface PmFormatHeadingProps {
  children: React.ReactNode;
  label: string;
  icon: React.ReactNode;
  index: number;
  fieldsArray: UseFieldArrayReturn<PtwTemplateFormType>;
}

export default function PmFormatHeading({
  children,
  label,
  icon,
  index,
  fieldsArray,
}: PmFormatHeadingProps) {
  return (
    <div className="flex flex-col gap-2 p-5 rounded-xl bg-gray-50">
      <div className="flex gap-4 items-center justify-between">
        <div className="flex gap-4">
          <div className="p-2 bg-primary-400 flex items-center justify-center rounded-lg">
            {icon}
          </div>
          <p className="font-medium">{label}</p>
        </div>
        <PmFormatActions index={index} fieldsArray={fieldsArray} />
      </div>
      {children}
    </div>
  );
}
