import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Divider } from "./Divider";
import { ItemSection } from "./ItemSection";

export function BuildingInspectorVerificationSection() {
  return (
    <View style={{ gap: 2 }}>
      <Text style={{ fontSize: 10, fontWeight: "bold", color: "#336699" }}>
        G. Building Inspector Verification
      </Text>
      <Divider />
      <View style={{ display: "flex", flexDirection: "row", width: "50%" }}>
        <ItemSection label="Date / Time" value="" />
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <ItemSection label="Comment (if any)" value="" valueWidth="100%" />
        <View
          style={{
            width: "30%",
            border: 1,
            borderColor: "#94a3b8",
            padding: 4,
            margin: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 50,
          }}
        >
          <Text style={{ fontSize: 8, textAlign: "center" }}>
            Name, Designation & Signature
          </Text>
        </View>
      </View>
    </View>
  );
}
