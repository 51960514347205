import { useSearchParams } from "react-router-dom";
import { useCompaniesControllerGetOneProject } from "../../../api/companies/companies";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import AppItemInfo from "../../AppItemInfo";
import { Image, Pen, Tag, ToggleLeft } from "lucide-react";
import AppMultiImage from "../../AppMultiImage";
import AppButton from "../../AppButton";
import { DRAWER_UPDATE_STATE, PROJECT_FORM_DRAWER } from "../AppDrawer";
import { Switch } from "../../ui/switch";
import SchedulerToggleSwitch from "./components/SchedulerToggleSwitch";
import BimToggleSwitch from "./components/BimToggleSwitch";

export default function ProjectDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProjId = searchParams.get("projectId");

  const { data: editProject, isLoading: editProjectIsLoading } =
    useCompaniesControllerGetOneProject(
      searchParams.get("compId") ?? "",
      searchParams.get("projectId") ?? "",
      {
        query: {
          enabled:
            !!searchParams.get("projectId") && !!searchParams.get("compId"),
          select: (res) => res.data,
        },
      }
    );

  if (editProjectIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <p className="font-sans text-2xl font-bold">{editProject?.name ?? "-"}</p>
      <AppItemInfo
        label="Code"
        content={<p className="font-sans">{editProject?.code ?? "-"}</p>}
        icon={<Tag className="text-primary-900" />}
      />
      <AppItemInfo
        label="Photo"
        content={<AppMultiImage attachments={editProject?.attachments} />}
        icon={<Image className="text-primary-900" />}
      />
      <div className="py-4 text-xl">Features</div>
      <AppItemInfo
        label="Modules"
        content={
          <div className="flex flex-col gap-4">
            <SchedulerToggleSwitch />
            <BimToggleSwitch />
          </div>
        }
        icon={<ToggleLeft className="text-primary-900" />}
      />
      <div className="flex gap-2 mt-2">
        <AppButton
          icon={<Pen />}
          label="Update"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", PROJECT_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              return p;
            });
          }}
        />
      </div>
    </div>
  );
}
