import { CerevLogo } from "../../icons/CerevLogo";
import { z } from "zod";
import { parse, isBefore, format, isAfter, addYears } from "date-fns";
import {
  useForm,
  useFieldArray,
  SubmitHandler,
  FormProvider,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import AppTextField from "../../components/AppTextField";
import { useCreateWorkPermitMutation } from "../../redux/slices/WorkPermit/WorkPermitApi";
import { useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import AppTextAreaField from "../../components/AppTextAreaField";
import { AlertCircle } from "lucide-react";
import { trpc } from "../../lib/trpc";
import {
  createWorkPermitInput,
  CreateWorkPermitInput,
} from "@cerev-cmms/zod-types";
import { AppDatePicker } from "../../components/AppDatePicker";

// schema
export const WorkPermitSchema = z
  .object({
    applierCompanyName: z
      .string()
      .min(1, { message: "Please input your company name" }),
    email: z.string().email({ message: "Please key in proper email" }),
    telNo: z.string().min(1, { message: "Please input your contact no." }),
    description: z
      .string()
      .min(1, { message: "You need to describe your work" }),
    workStartDate: z
      .string()
      .min(1, { message: "You need to select a start date" }),
    workEndDate: z
      .string()
      .min(1, { message: "You need to select an end date" }),
    locationOfWork: z.string().min(1, { message: "Please input the location" }),
    workers: z
      .array(
        z.object({
          name: z.string().min(1, { message: "Please input name" }),
          icNo: z.string().min(1, { message: "Please input icNo" }),
        })
      )
      .nonempty({ message: "Must add at least one worker" }),
  })
  .refine(
    (data) => {
      // To ensure that end date is always later than start date,
      // Additional validation to be put under refine method of zod validation.
      const startDate = parse(data.workStartDate, "yyyy-MM-dd", new Date());
      const endDate = parse(data.workEndDate, "yyyy-MM-dd", new Date());
      if (isBefore(endDate, startDate)) return false;
      return true;
    },
    {
      message: "End date cannot be earlier than start date",
      path: ["workEndDate"],
    }
  );

export type WorkPermitTypeForm = z.infer<typeof WorkPermitSchema>;

export default function PublicWorkPermitScreen() {
  const {
    mutate: submitWorkPermit,
    isPending: isLoading,
    isSuccess,
    isError,
  } = trpc.workPermit.createWorkPermit.useMutation();

  const param = useParams();

  const methods = useForm<CreateWorkPermitInput>({
    resolver: zodResolver(createWorkPermitInput),
    defaultValues: {
      projectCode: param.projectCode ?? "",
      workStartDate: new Date(),
      workEndDate: new Date(),
      applierCompanyName: "",
      email: "",
      telNo: "",
      description: "",
      locationOfWork: "",
      workers: [],
    },
  });
  const { fields, insert, remove } = useFieldArray({
    control: methods.control,
    name: "workers",
  });

  const onSubmission: SubmitHandler<CreateWorkPermitInput> = (data) => {
    submitWorkPermit({
      ...data,
      projectCode: param.projectCode ?? "",
    });
  };

  // Component when successfully submitted.
  if (isSuccess) {
    return (
      <div className="min-h-screen w-screen flex flex-col bg-primary-900 justify-center items-center rounded-md">
        <div className="max-w-3xl bg-neutral-50 p-8 rounded-md">
          <div className="w-20 h-20 mb-2">
            <CerevLogo />
          </div>
          <h1 className="font-sans my-2 text-2xl font-bold">
            Work Permit has been submitted
          </h1>
          <p className="font-sans my-2 mb-5">
            Your work permit now is pending approval from management.
          </p>
          <AppButton
            label="Go Back"
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }}
          />
        </div>
      </div>
    );
  }

  // Error Component
  if (isError) {
    return (
      <div className="h-screen w-screen flex flex-col bg-primary-900 justify-center items-center rounded-x">
        <div className="p-24 rounded-xl flex flex-col justify-center items-center bg-white max-w-xl">
          <AlertCircle className="text-red-500 w-20 h-20" />
          <h1 className="font-sans text-4xl text-red-500 font-bold my-2">
            Error
          </h1>
          <p className="font-sans mt-2 text-neutral-800">
            Sorry, Something went wrong. Please try again.
          </p>
          <AppButton
            className="mt-4 w-32"
            onClick={() => {
              location.reload();
            }}
            label="Retry"
          />
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <div className="min-h-screen h-full w-full flex flex-col bg-primary-900 justify-center items-center">
        <div className="bg-neutral-50 p-8 grid grid-cols-1 h-full rounded-md w-full max-w-md">
          <div className="h-16 w-16">
            <CerevLogo />
          </div>
          <div className="gap-2">
            <h1 className="text-3xl font-bold font-sans">Work Permit</h1>
            <p className="form-subtitle font-sans text-slate-500">
              New Contractor Registration
            </p>
          </div>
          <AppTextField label="Company Name" name="applierCompanyName" />
          {/* <p>Email</p> */}
          <AppTextField label="Email" name="email" data-testid="email-field" />
          {/* <p>Contact No.</p> */}
          <AppTextField label="Contact No." name="telNo" />
          {/* <p>Description</p> */}
          <AppTextAreaField label="Description" name="description" />
          <AppDatePicker
            label="Work Start Date"
            name="workStartDate"
            disabled={(date) => isAfter(date, addYears(new Date(), 3))}
          />
          {/* <p>Work End Date</p> */}
          <AppDatePicker
            label="Work End Date"
            name="workEndDate"
            disabled={(date) => isAfter(date, addYears(new Date(), 3))}
          />
          {/* <p>Location of work</p> */}
          <AppTextField label="Location of work" name="locationOfWork" />
          <h3 className="font-sans font-bold text-lg py-4">
            Workers Information
          </h3>
          <div className="mb-4 flex flex-col gap-4">
            {fields.map((fl, idx) => (
              <div key={fl.id} className="flex flex-col gap-4">
                <AppTextField
                  label={`Worker ${idx + 1} Name`}
                  name={`workers.${idx}.name`}
                />
                <AppTextField
                  label={`Worker ${idx + 1} Ic No.`}
                  name={`workers.${idx}.icNo`}
                />
                <div className="flex">
                  <AppButton
                    className="rounded-xl"
                    variant="destructive"
                    onClick={() => {
                      remove(idx);
                    }}
                    label="Remove"
                  />
                </div>
              </div>
            ))}
          </div>

          {methods.formState.errors?.workers?.message && (
            <p style={{ color: "red" }}>
              {methods.formState.errors.workers?.message}
            </p>
          )}
          <AppButton
            onClick={() => {
              insert(fields.length, { name: "", icNo: "" });
            }}
            variant="outline"
            label="+ Add Worker"
          />
          <AppButton
            onClick={methods.handleSubmit(onSubmission)}
            isLoading={isLoading}
            className="mt-4 w-32"
            label="Submit"
          />
        </div>
      </div>
    </FormProvider>
  );
}
