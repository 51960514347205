import { FormProvider, useForm } from "react-hook-form";

interface SearchProviderProps {
  children: React.ReactNode;
}

export default function SearchProvider({ children }: SearchProviderProps) {
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
}
