import { useSearchParams } from "react-router-dom";
import {
  usePmChecklistControllerGetOnePmChecklistQuery,
  usePmChecklistStatusControllerGetAllPmChecklistStatusQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import { useEffect, useState } from "react";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { useAppSelector } from "../../../redux/store";
import { TabPanel } from "../../../screens/DashboardScreen/components/AppTabPanel";
import PmDetailTab from "./tabs/PmDetailTab";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";

export default function PmDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "pmDetailTabId",
    defaultValue: "0",
  });
  // const {currentTabIdx, setCurrentTabIdx} = useAppTabsNavigation({
  //   searchParamStr: "pmDetailTabId",
  // });

  const activeComp = useAppSelector((state) => state.root.activeCompany);

  const { data: pmChecklist, isLoading: pmChecklistIsLoading } =
    usePmChecklistControllerGetOnePmChecklistQuery(
      {
        pmChecklistId: searchParams.get("pmChecklistId") ?? "",
      },
      {
        skip: !searchParams.get("pmChecklistId"),
      }
    );

  const { data: pmStatusList, isLoading: pmStatusIsLoading } =
    usePmChecklistStatusControllerGetAllPmChecklistStatusQuery(
      {
        companyId: activeComp?.id ?? 0,
      },
      {
        skip: !activeComp,
      }
    );

  // const handleChange = (event: React.SyntheticEvent, newTabIdx: number) => {
  //   setSearchParams((p) => {
  //     p.set("pmDetailTabId", newTabIdx.toString());
  //     return p;
  //   });
  //   setCurrentTabIdx(newTabIdx);
  // };

  if (pmStatusIsLoading || pmChecklistIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4 relative">
      <p className="font-sans text-2xl font-bold">PM Detail</p>
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <TabsList>
          {pmStatusList?.map((pmStat, idx) => {
            return (
              <TabsTrigger value={idx.toString()} key={pmStat.id}>
                <p>{pmStat.name}</p>
              </TabsTrigger>
            );
          })}
        </TabsList>
        {pmStatusList?.map((pmStat, idx) => (
          <TabsContent value={idx.toString()} key={pmStat.id}>
            <PmDetailTab pmStatus={pmStat} />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
}
