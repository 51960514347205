import { endOfMonth, startOfMonth } from "date-fns";
import { set } from "lodash";
import { DateRange } from "react-day-picker";
import { create } from "zustand";

export enum PdfReportType {
  INITIAL = "INITIAL", // This is the state when the user first opens the screen
  WORK_ORDER_SUMMARY = "WORK_ORDER_SUMMARY",
  INVENTORY_MOVEMENT_REPORT = "INVENTORY_MOVEMENT_REPORT",
  JKR_APD_SUMMARY = "JKRA_APD_SUMMARY",
}

interface PdfReportStoreState {
  reportType: PdfReportType;
  dateRange: DateRange | null;
  actions: {
    setReportType: (reportType: PdfReportType) => void;
    setDateRange: (dateRange: DateRange | undefined | null) => void;
    reset: () => void;
  };
}

export const usePdfReportStore = create<PdfReportStoreState>((set, get) => ({
  reportType: PdfReportType.INITIAL,
  dateRange: { to: endOfMonth(new Date()), from: startOfMonth(new Date()) },
  actions: {
    setReportType: (reportType) => {
      set(() => ({
        reportType,
      }));
    },
    setDateRange: (dateRange) => {
      set(() => ({
        dateRange,
      }));
    },
    reset: () => {
      set(() => ({
        reportType: PdfReportType.INITIAL,
        dateStart: null,
        dateEnd: null,
      }));
    },
  },
}));

export const usePdfReportDateRange = () =>
  usePdfReportStore((state) => state.dateRange);
export const usePdfReportReportType = () =>
  usePdfReportStore((state) => state.reportType);
export const usePdfReportActions = () =>
  usePdfReportStore((state) => state.actions);
