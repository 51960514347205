import { useState } from "react";
import AppButton from "../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
} from "../../../ui/dialog";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AppTextField from "../../../AppTextField";
import { useLocationControllerGetFullLocationList } from "../../../../api/location/location";
import { useAppSelector } from "../../../../redux/store";
import AppSelectWithDialog from "../../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { selectLocationColumns } from "../columns/selectLocationColumn";
import { useStocksControllerAddInventoryLocation } from "../../../../api/stocks-inventory/stocks-inventory";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

const inventoryLocationAdjustmentSchema = z.object({
  location: z.object({
    id: z.number(),
    name: z.string(),
  }),
  quantity: z.coerce.number(),
});

type InventoryLocationAdjustmentForm = z.infer<
  typeof inventoryLocationAdjustmentSchema
>;

export default function AddInventoryLocationDialogBtn() {
  const [searchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");

  const [isOpen, setIsOpen] = useState(false);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<InventoryLocationAdjustmentForm>({
    resolver: zodResolver(inventoryLocationAdjustmentSchema),
    defaultValues: {
      location: undefined,
      quantity: 0,
    },
  });

  const { data: fullLocationList } = useLocationControllerGetFullLocationList(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const qc = useQueryClient();

  const { mutate, isPending } = useStocksControllerAddInventoryLocation({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => (q.queryKey[0] as string).includes("stock"),
        });
        setIsOpen(false);
      },
    },
  });

  const onSubmit: SubmitHandler<InventoryLocationAdjustmentForm> = (data) => {
    if (!inventoryId) return;
    mutate({
      inventoryId: inventoryId,
      data: {
        locationId: data.location.id,
        quantity: data.quantity,
        projectId: activeProj?.id ?? 0,
      },
    });
  };

  return (
    <>
      <AppButton
        label="Create"
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <Dialog
        open={isOpen}
        onOpenChange={() => {
          setIsOpen(false);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Location</DialogTitle>
            <DialogDescription>
              Indicate where this inventory is also located at a different
              location
            </DialogDescription>
          </DialogHeader>
          <FormProvider {...methods}>
            {/* <AppTextField name="quantity" type="number" label="Initial Quantity" /> */}
            <AppSelectWithDialog
              label="Location"
              placeholder="Select Location"
              defaultValue={undefined}
              control={methods.control}
              columns={selectLocationColumns}
              name="location"
              items={fullLocationList?.data ?? []}
              onResultRender={(item, idx) => {
                return <div key={idx}>{item?.name}</div>;
              }}
              onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
              dialogTitle="Select Location"
              error={!!methods.formState.errors.location?.message}
              helperText={methods.formState.errors.location?.message}
            />
          </FormProvider>
          <DialogFooter>
            <AppButton
              variant="ghost"
              label="Cancel"
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <AppButton
              label="Confirm"
              isLoading={isPending}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
