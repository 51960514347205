/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  CreateVendorDto,
  GetVendorResponseDto,
  GetVendorsResponseDto,
  UpdateVendorDto,
  VendorControllerGetVendorsCountParams,
  VendorControllerGetVendorsParams,
} from ".././model";

export const vendorControllerGetVendorsCount = (
  params: VendorControllerGetVendorsCountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<number>> => {
  return axios.get(`/vendor/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getVendorControllerGetVendorsCountQueryKey = (
  params: VendorControllerGetVendorsCountParams
) => {
  return [`/vendor/count`, ...(params ? [params] : [])] as const;
};

export const getVendorControllerGetVendorsCountInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
    VendorControllerGetVendorsCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
        QueryKey,
        VendorControllerGetVendorsCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getVendorControllerGetVendorsCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
    QueryKey,
    VendorControllerGetVendorsCountParams["cursorId"]
  > = ({ signal, pageParam }) =>
    vendorControllerGetVendorsCount(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
    TError,
    TData,
    Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
    QueryKey,
    VendorControllerGetVendorsCountParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type VendorControllerGetVendorsCountInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>
>;
export type VendorControllerGetVendorsCountInfiniteQueryError =
  AxiosError<unknown>;

export const useVendorControllerGetVendorsCountInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
    VendorControllerGetVendorsCountParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsCountParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
        TError,
        TData,
        Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
        QueryKey,
        VendorControllerGetVendorsCountParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getVendorControllerGetVendorsCountInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getVendorControllerGetVendorsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getVendorControllerGetVendorsCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>
  > = ({ signal }) =>
    vendorControllerGetVendorsCount(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VendorControllerGetVendorsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>
>;
export type VendorControllerGetVendorsCountQueryError = AxiosError<unknown>;

export const useVendorControllerGetVendorsCount = <
  TData = Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsCountParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendorsCount>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getVendorControllerGetVendorsCountQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const vendorControllerGetVendors = (
  params: VendorControllerGetVendorsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetVendorsResponseDto[]>> => {
  return axios.get(`/vendor`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getVendorControllerGetVendorsQueryKey = (
  params: VendorControllerGetVendorsParams
) => {
  return [`/vendor`, ...(params ? [params] : [])] as const;
};

export const getVendorControllerGetVendorsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof vendorControllerGetVendors>>,
    VendorControllerGetVendorsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof vendorControllerGetVendors>>,
        QueryKey,
        VendorControllerGetVendorsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getVendorControllerGetVendorsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof vendorControllerGetVendors>>,
    QueryKey,
    VendorControllerGetVendorsParams["cursorId"]
  > = ({ signal, pageParam }) =>
    vendorControllerGetVendors(
      { ...params, cursorId: pageParam || params?.["cursorId"] },
      { signal, ...axiosOptions }
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof vendorControllerGetVendors>>,
    TError,
    TData,
    Awaited<ReturnType<typeof vendorControllerGetVendors>>,
    QueryKey,
    VendorControllerGetVendorsParams["cursorId"]
  > & { queryKey: QueryKey };
};

export type VendorControllerGetVendorsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerGetVendors>>
>;
export type VendorControllerGetVendorsInfiniteQueryError = AxiosError<unknown>;

export const useVendorControllerGetVendorsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof vendorControllerGetVendors>>,
    VendorControllerGetVendorsParams["cursorId"]
  >,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof vendorControllerGetVendors>>,
        QueryKey,
        VendorControllerGetVendorsParams["cursorId"]
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getVendorControllerGetVendorsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getVendorControllerGetVendorsQueryOptions = <
  TData = Awaited<ReturnType<typeof vendorControllerGetVendors>>,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendors>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getVendorControllerGetVendorsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof vendorControllerGetVendors>>
  > = ({ signal }) =>
    vendorControllerGetVendors(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof vendorControllerGetVendors>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VendorControllerGetVendorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerGetVendors>>
>;
export type VendorControllerGetVendorsQueryError = AxiosError<unknown>;

export const useVendorControllerGetVendors = <
  TData = Awaited<ReturnType<typeof vendorControllerGetVendors>>,
  TError = AxiosError<unknown>
>(
  params: VendorControllerGetVendorsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetVendors>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getVendorControllerGetVendorsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const vendorControllerCreateVendor = (
  createVendorDto: CreateVendorDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/vendor`, createVendorDto, options);
};

export const getVendorControllerCreateVendorMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerCreateVendor>>,
    TError,
    { data: CreateVendorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof vendorControllerCreateVendor>>,
  TError,
  { data: CreateVendorDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vendorControllerCreateVendor>>,
    { data: CreateVendorDto }
  > = (props) => {
    const { data } = props ?? {};

    return vendorControllerCreateVendor(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type VendorControllerCreateVendorMutationResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerCreateVendor>>
>;
export type VendorControllerCreateVendorMutationBody = CreateVendorDto;
export type VendorControllerCreateVendorMutationError = AxiosError<unknown>;

export const useVendorControllerCreateVendor = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerCreateVendor>>,
    TError,
    { data: CreateVendorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof vendorControllerCreateVendor>>,
  TError,
  { data: CreateVendorDto },
  TContext
> => {
  const mutationOptions =
    getVendorControllerCreateVendorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const vendorControllerGetOneVendor = (
  vendorId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetVendorResponseDto>> => {
  return axios.get(`/vendor/${vendorId}`, options);
};

export const getVendorControllerGetOneVendorQueryKey = (vendorId: string) => {
  return [`/vendor/${vendorId}`] as const;
};

export const getVendorControllerGetOneVendorInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof vendorControllerGetOneVendor>>
  >,
  TError = AxiosError<unknown>
>(
  vendorId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getVendorControllerGetOneVendorQueryKey(vendorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof vendorControllerGetOneVendor>>
  > = ({ signal }) =>
    vendorControllerGetOneVendor(vendorId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!vendorId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VendorControllerGetOneVendorInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerGetOneVendor>>
>;
export type VendorControllerGetOneVendorInfiniteQueryError =
  AxiosError<unknown>;

export const useVendorControllerGetOneVendorInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof vendorControllerGetOneVendor>>
  >,
  TError = AxiosError<unknown>
>(
  vendorId: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getVendorControllerGetOneVendorInfiniteQueryOptions(
    vendorId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getVendorControllerGetOneVendorQueryOptions = <
  TData = Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
  TError = AxiosError<unknown>
>(
  vendorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getVendorControllerGetOneVendorQueryKey(vendorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof vendorControllerGetOneVendor>>
  > = ({ signal }) =>
    vendorControllerGetOneVendor(vendorId, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!vendorId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VendorControllerGetOneVendorQueryResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerGetOneVendor>>
>;
export type VendorControllerGetOneVendorQueryError = AxiosError<unknown>;

export const useVendorControllerGetOneVendor = <
  TData = Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
  TError = AxiosError<unknown>
>(
  vendorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof vendorControllerGetOneVendor>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getVendorControllerGetOneVendorQueryOptions(
    vendorId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const vendorControllerUpdateOneVendor = (
  vendorId: string,
  updateVendorDto: UpdateVendorDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/vendor/${vendorId}`, updateVendorDto, options);
};

export const getVendorControllerUpdateOneVendorMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerUpdateOneVendor>>,
    TError,
    { vendorId: string; data: UpdateVendorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof vendorControllerUpdateOneVendor>>,
  TError,
  { vendorId: string; data: UpdateVendorDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vendorControllerUpdateOneVendor>>,
    { vendorId: string; data: UpdateVendorDto }
  > = (props) => {
    const { vendorId, data } = props ?? {};

    return vendorControllerUpdateOneVendor(vendorId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type VendorControllerUpdateOneVendorMutationResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerUpdateOneVendor>>
>;
export type VendorControllerUpdateOneVendorMutationBody = UpdateVendorDto;
export type VendorControllerUpdateOneVendorMutationError = AxiosError<unknown>;

export const useVendorControllerUpdateOneVendor = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerUpdateOneVendor>>,
    TError,
    { vendorId: string; data: UpdateVendorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof vendorControllerUpdateOneVendor>>,
  TError,
  { vendorId: string; data: UpdateVendorDto },
  TContext
> => {
  const mutationOptions =
    getVendorControllerUpdateOneVendorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const vendorControllerDeleteVendor = (
  vendorId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/vendor/${vendorId}`, options);
};

export const getVendorControllerDeleteVendorMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerDeleteVendor>>,
    TError,
    { vendorId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof vendorControllerDeleteVendor>>,
  TError,
  { vendorId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vendorControllerDeleteVendor>>,
    { vendorId: string }
  > = (props) => {
    const { vendorId } = props ?? {};

    return vendorControllerDeleteVendor(vendorId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type VendorControllerDeleteVendorMutationResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerDeleteVendor>>
>;

export type VendorControllerDeleteVendorMutationError = AxiosError<unknown>;

export const useVendorControllerDeleteVendor = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerDeleteVendor>>,
    TError,
    { vendorId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof vendorControllerDeleteVendor>>,
  TError,
  { vendorId: string },
  TContext
> => {
  const mutationOptions =
    getVendorControllerDeleteVendorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const vendorControllerDeleteAttachment = (
  vendorId: string,
  attachmentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.delete(
    `/vendor/${vendorId}/additionalDocuments/${attachmentId}`,
    options
  );
};

export const getVendorControllerDeleteAttachmentMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerDeleteAttachment>>,
    TError,
    { vendorId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof vendorControllerDeleteAttachment>>,
  TError,
  { vendorId: string; attachmentId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vendorControllerDeleteAttachment>>,
    { vendorId: string; attachmentId: string }
  > = (props) => {
    const { vendorId, attachmentId } = props ?? {};

    return vendorControllerDeleteAttachment(
      vendorId,
      attachmentId,
      axiosOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type VendorControllerDeleteAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof vendorControllerDeleteAttachment>>
>;

export type VendorControllerDeleteAttachmentMutationError = AxiosError<unknown>;

export const useVendorControllerDeleteAttachment = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vendorControllerDeleteAttachment>>,
    TError,
    { vendorId: string; attachmentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof vendorControllerDeleteAttachment>>,
  TError,
  { vendorId: string; attachmentId: string },
  TContext
> => {
  const mutationOptions =
    getVendorControllerDeleteAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};
