import { create } from "zustand";

import {
  Asset,
  AssetType,
  DefectType,
  GetLocationResponseDto,
  Location,
  User,
} from "../../api/model";
import { DateRange } from "react-day-picker";

// TODO: add raise by and asset
interface SelectWorkOrderFilterState {
  workOrderType: DefectType[];
  filterLocation: Location | GetLocationResponseDto | null | undefined;
  dateRange: DateRange | null | undefined;
  assetTypes: AssetType[];
  raisedBy: User[];
  setAssetTypes: (assetType: AssetType[]) => void;
  setRaisedBy: (raisedBy: User[]) => void;
  setFilterLocation: (
    loc: Location | GetLocationResponseDto | null | undefined
  ) => void;
  setDateRange: (dateRange: DateRange | undefined) => void;
  getFilterCount: () => number;
  setWorkOrderType: (workOrderType: DefectType[]) => void;
  cleanUp: () => void;
}

export const useSelectWorkOrderFilterStore = create<SelectWorkOrderFilterState>(
  (set, get) => ({
    workOrderType: [],
    filterLocation: null,
    dateRange: null,
    assetTypes: [],
    raisedBy: [],
    setAssetTypes: (assetTypes) => set(() => ({ assetTypes })),
    setRaisedBy: (raisedBy) => set(() => ({ raisedBy })),
    setFilterLocation: (loc) => set(() => ({ filterLocation: loc })),
    setDateRange: (dateRange) => set(() => ({ dateRange })),
    setWorkOrderType: (workOrderType) => set(() => ({ workOrderType })),
    cleanUp: () =>
      set(() => ({
        filterLocation: null,
        dateRange: null,
        raisedBy: [],
        assetTypes: [],
        workOrderType: [],
      })),
    getFilterCount: () => {
      let filterCount = 0;
      if (get().filterLocation) filterCount++;
      if (get().dateRange) filterCount++;
      if (get().assetTypes.length > 0) filterCount++;
      if (get().raisedBy.length > 0) filterCount++;
      if (get().workOrderType.length > 0) filterCount++;
      return filterCount;
    },
  })
);
