/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Cerev API
 * List of APIs used in Cerev
 * OpenAPI spec version: 1.2.0
 */

export type CerevModule = (typeof CerevModule)[keyof typeof CerevModule];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CerevModule = {
  DASHBOARD: "DASHBOARD",
  WORK_ORDER: "WORK_ORDER",
  PM: "PM",
  VENDOR: "VENDOR",
  INVENTORY: "INVENTORY",
  EQUIPMENT: "EQUIPMENT",
  WORK_REQUEST: "WORK_REQUEST",
  WORK_PERMIT: "WORK_PERMIT",
  LEGAL: "LEGAL",
  TNB_BILLS: "TNB_BILLS",
  METERS: "METERS",
  ESCALATION: "ESCALATION",
} as const;
