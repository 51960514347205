import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { InventoryForm } from "@/components/drawers/inventory/InventoryFormDrawer";
import AppButton from "@/components/AppButton";
import { FormLabel } from "@/components/ui/form";
import { CreateStockDtoInventoryMode } from "@/api/model";
import AppTextField from "@/components/AppTextField";
import { Hash, Plus, Trash, Trash2 } from "lucide-react";
import useIsUpdateDrawerState from "../../../../hooks/useDrawerState";

export default function InventorySerialsFormSection() {
  const methods = useFormContext<InventoryForm>();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const { fields, append, remove, swap } = useFieldArray({
    control: methods.control,
    name: "serials",
  });

  const inventoryMode = useWatch({
    control: methods.control,
    name: "inventoryMode",
  });

  if (inventoryMode === CreateStockDtoInventoryMode.QUANTITY) return <></>;

  return (
    <div className={"flex-col gap-2"}>
      {/* Need to show to user how many serial numbers need to be added by showing the balance here */}
      {isUpdateDrawer && (
        <div className="flex flex-col gap-1 mb-6">
          <AppTextField name="balance" label="Current Balance" disabled />
          <p className="text-sm text-slate-400">
            {" "}
            * Cannot adjust balance here, to adjust, use the stock adjustment
            function
          </p>
        </div>
      )}
      <FormLabel>Serial Numbers</FormLabel>
      {fields?.map((f, idx) => {
        return (
          <div key={f.id}>
            <div key={f.id} className={`flex gap-2 items-center`}>
              <AppTextField
                name={`serials.${idx}.name`}
                key={f.id}
                placeholder={`Serial Number..`}
                className={`flex-1`}
              />
              <AppButton
                data-testid={`delete-serial-btn-${idx}`}
                variant={`ghost`}
                icon={<Trash2 className={`text-red-500`} />}
                onClick={() => {
                  remove(idx);
                }}
              />
            </div>
          </div>
        );
      })}
      {methods.formState.errors["serials"] && (
        <p className={"text-sm font-medium text-red-500 dark:text-red-900"}>
          {methods.formState.errors["serials"]?.message ?? ""}
        </p>
      )}
      <AppButton
        className={"mt-2"}
        label={"Add Field"}
        variant={`outline`}
        icon={<Plus />}
        onClick={() => {
          append({ name: "" });
        }}
      />
    </div>
  );
}
