import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  DefectControllerGetDefectsParams,
  DefectControllerGetWorkOrderCountParams,
} from "../../../api/model";
import {
  useDefectControllerGetDefects,
  useDefectControllerGetWorkOrderCount,
} from "../../../api/work-orders-defects/work-orders-defects";
import { DataTable } from "../../../components/react-table/DataTable";
import useDebounce from "../../../hooks/useDebounce";
import { DefectStatus } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../redux/store";
import { useSelectWorkOrderFilterStore } from "../../../stores/workOrder/workOrderFilterStore";
import { workOrderColumns } from "./WorkOrderColumns";
import {
  useRowSelectionState,
  useWorkOrderIsMultiActionMode,
  useWorkOrderMultiSelectActions,
} from "../../../stores/workOrderMultiActionStore/workOrderMultiActionStore";

interface WorkOrderTabProps {
  workOrderStatus: DefectStatus;
}

export default function WorkOrderTab({ workOrderStatus }: WorkOrderTabProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [currentPg, setCurrentPg] = useState(0);
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const locationFilter = useSelectWorkOrderFilterStore(
    (state) => state.filterLocation
  );

  const dateRange = useSelectWorkOrderFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectWorkOrderFilterStore(
    (state) => state.assetTypes
  );
  const usersFilter = useSelectWorkOrderFilterStore((state) => state.raisedBy);

  const workOrderTypeFilter = useSelectWorkOrderFilterStore(
    (state) => state.workOrderType
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<DefectControllerGetDefectsParams>({
    value: {
      locFilterId: locationFilter?.id.toString() ?? "",
      defectStatusId: workOrderStatus.id.toString(),
      projectId: activeProj?.id?.toString() ?? "",
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      filterAssetTypeIds: assetTypesFilter.map(
        (at) => at?.id?.toString() ?? "0"
      ),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
    },
    delay: 500,
  });

  const queryStatusArg = useDebounce<DefectControllerGetWorkOrderCountParams>({
    value: {
      locFilterId: locationFilter?.id.toString() ?? "",
      projectId: activeProj?.id?.toString() ?? "",
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      search,
      filterAssetTypeIds: assetTypesFilter.map(
        (at) => at?.id?.toString() ?? "0"
      ),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
    },
    delay: 500,
  });

  const {
    data: workOrders,
    isLoading,
    isFetching,
  } = useDefectControllerGetDefects(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const { data: workOrderCount } = useDefectControllerGetWorkOrderCount(
    workOrderStatus.id.toString(),
    queryStatusArg,
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const rowSelection = useRowSelectionState();
  const { setRowSelection } = useWorkOrderMultiSelectActions();

  const linkingMode = useWorkOrderIsMultiActionMode();

  const table = useReactTable({
    data: workOrders?.data ?? [],
    columns: workOrderColumns,
    manualPagination: true,
    getRowId: (row) => row.id?.toString() ?? "-",
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: workOrderCount?.data
      ? Math.ceil(+workOrderCount.data / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        select: linkingMode,
        equipmentName: false,
      },
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  // reset table pagination if filters have changed
  useEffect(() => {
    table.resetPagination();
  }, [search, dateRange, assetTypesFilter, usersFilter, locationFilter]);

  return (
    <DataTable
      isLoading={isLoading}
      isFetching={isFetching}
      table={table}
      totalCount={workOrderCount ? +workOrderCount.data : 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );
}
