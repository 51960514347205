import { useSearchParams } from "react-router-dom";
import AppButton from "../../../../../AppButton";
import WoRevenueDialogBtn from "./WoRevenueDialog";
import { DataTable } from "../../../../../react-table/DataTable";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { woRevenueTableColumns } from "./WoRevenueTableColumns";
import { useWoRevenueControllerGetWoRevenue } from "../../../../../../api/work-order-revenue/work-order-revenue";

export default function RevenueSection() {
  const [searchParams] = useSearchParams();
  const activeWoId = searchParams.get("workOrderId");

  const {
    data: revenueList,
    isLoading,
    isFetching,
  } = useWoRevenueControllerGetWoRevenue(activeWoId as string, {
    query: {
      enabled: !!activeWoId,
      select: (res) => res.data.data,
    },
  });

  const table = useReactTable({
    data: revenueList ?? [],
    columns: woRevenueTableColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <div className="space-y-2">
      <div className="space-x-4 flex items-center">
        <h3 className="text-xl font-semibold">Revenue</h3>
        <WoRevenueDialogBtn />
      </div>
      <DataTable
        isLoading={isLoading}
        isFetching={isFetching}
        table={table}
        totalCount={revenueList?.length ?? 0}
      />
    </div>
  );
}
