import { create } from "zustand";
import {
  Asset,
  AssetType,
  GetLocationResponseDto,
  Location,
} from "../../api/model";
import { endOfMonth, startOfMonth } from "date-fns";
import { AssetWithType } from "../../redux/slices/OpenApi/cerevApi";

interface WorkOrderScheduleFilterState {
  filterLocation: Location | GetLocationResponseDto | null | undefined;
  assetTypes: AssetType[];
  filterAsset: Asset | null | undefined;
  setAssetTypes: (assetType: AssetType[]) => void;
  setFilterLocation: (
    loc: Location | GetLocationResponseDto | null | undefined
  ) => void;
  setAsset(filterAsset: Asset | AssetWithType | null | undefined): void;
  cleanUp: () => void;
  getFilterCount: () => number;
}

export const useWorkOrderScheduleFilterStore =
  create<WorkOrderScheduleFilterState>((set, get) => ({
    filterLocation: null,
    assetTypes: [],
    filterAsset: null,
    dateStart: startOfMonth(new Date()),
    dateEnd: endOfMonth(new Date()),
    setAssetTypes: (assetTypes) => set(() => ({ assetTypes })),
    setFilterLocation: (loc) => set(() => ({ filterLocation: loc })),
    setAsset: (filterAsset) => set(() => ({ filterAsset: filterAsset as Asset })),
    cleanUp: () =>
      set(() => ({
        filterLocation: null,
        assetTypes: [],
        filterAsset: null,
        dateStart: startOfMonth(new Date()),
        dateEnd: endOfMonth(new Date()),
      })),
    getFilterCount: () => {
      let filterCount = 0;
      if (get().filterLocation) filterCount++;
      if (get().assetTypes.length > 0) filterCount++;
      if (get().filterAsset) filterCount++;
      return filterCount;
    },
  }));
