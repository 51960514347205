import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { addDays } from "date-fns";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { AppDateRangePicker } from "../../../components/AppDateRangePicker";
import { DataTable } from "../../../components/react-table/DataTable";
import useDebounce from "../../../hooks/useDebounce";
import useError from "../../../hooks/useError";
import { useAppSelector } from "../../../redux/store";
import { tnbBillColumns } from "./components/tnbBillColumns";
import { TnbBillControllerGetTnbReadingsParams } from "../../../api/model";
import {
  useTnbBillControllerDeleteTnbReading,
  useTnbBillControllerGetTnbReadings,
} from "../../../api/tnb-bill/tnb-bill";
import AppTextField from "../../../components/AppTextField";

export default function TnbBillReadingsTab() {
  const [date, setDate] = useState<DateRange | undefined>(undefined);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPg, setCurrentPg] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const queryArg = useDebounce<TnbBillControllerGetTnbReadingsParams>({
    value: {
      projectId: activeProj?.id ?? 0,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search: methods.watch("search"),
      dateStartIsoFilter: date?.from?.toISOString(),
      dateEndIsoFilter: date?.to
        ? addDays(date.to, 1).toISOString()
        : undefined,
    },
    delay: 500,
  });

  const {
    data: tnbBillReadings,
    isLoading,
    isFetching,
  } = useTnbBillControllerGetTnbReadings(queryArg, {
    query: {
      enabled: !!activeProj,
    },
  });

  const { mutateAsync: deleteTnbReading } =
    useTnbBillControllerDeleteTnbReading();

  const table = useReactTable({
    data: tnbBillReadings?.data?.data ?? [],
    columns: tnbBillColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: tnbBillReadings?.data?.totalCount
      ? Math.ceil(tnbBillReadings?.data?.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <AppTextField name="search" className="w-[400px]" placeholder="Search..."/>
        <DataTable
          table={table}
          isFetching={isFetching}
          isLoading={isLoading}
          totalCount={tnbBillReadings?.data?.totalCount ?? 0}
          rowsPerPage={pagination.pageSize}
          pageIdx={pagination.pageIndex}
        />
      </div>
    </FormProvider>
  );

  // return (
  //   <div>
  //     <AppTable
  //       count={tnbBillReadings?.count ?? 0}
  //       rowsPerPage={rowsPerPage}
  //       onRowsPerPageChange={(e) => {
  //         setRowsPerPage(parseInt(e.target.value, 10));
  //       }}
  //       page={currentPg}
  //       onPageChange={(e, value) => {
  //         if (!e) return;
  //         setCurrentPg(parseInt(value.toString(), 10));
  //       }}
  //       isLoading={isLoading || isFetching}
  //       colSpan={6}
  //       isEmpty={tnbBillReadings?.data.length === 0}
  //       headerTableRow={
  //         <TableHead>
  //           <TableRow>
  //             <TableCell className="font-sans text-lg font-bold">
  //               Month Year
  //             </TableCell>
  //             <TableCell className="font-sans text-lg font-bold">kW</TableCell>
  //             <TableCell className="font-sans text-lg font-bold">kWh</TableCell>
  //             <TableCell className="font-sans text-lg font-bold">
  //               Total Charges (RM)
  //             </TableCell>
  //             <TableCell className="font-sans text-lg font-bold">
  //               Attachments
  //             </TableCell>
  //             <TableCell className="font-sans text-lg font-bold">
  //               Actions
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>
  //       }
  //       bodyTableRow={tnbBillReadings?.data.map((tnb) => (
  //         <TableRow key={tnb.id}>
  //           <TableCell>
  //             {formatDateToLocal({
  //               dateStr: tnb.billMonth,
  //               formatting: "MMM yyyy",
  //             })}
  //           </TableCell>
  //           <TableCell>{tnb.kiloWattMd}</TableCell>
  //           <TableCell>{tnb.kiloWattHr}</TableCell>
  //           <TableCell>{tnb.totalBillCost}</TableCell>
  //           <TableCell>
  //             <div className="flex gap-2">
  //               {tnb.attachments?.map((att) => (
  //                 <AppDocument key={att.id} att={att} className="p-2" />
  //               )) ?? "-"}
  //             </div>
  //           </TableCell>
  //           <TableCell>
  //             <div className="flex gap-2">
  //               <AppButton
  //                 label="Update"
  //                 onClick={() => {
  //                   setSearchParams((p) => {
  //                     p.set("tnbReadingId", tnb.id.toString());
  //                     p.set("drawerType", TNB_BILL_FORM_DRAWER);
  //                     p.set("drawerState", DRAWER_UPDATE_STATE);
  //                     return p;
  //                   });
  //                 }}
  //               />
  //               <ConfirmDeleteBtnWithDialog
  //                 confirmDeleteTxt="Confirm"
  //                 isIconDelete
  //                 onDeleteConfirm={async () => {
  //                   deleteTnbReading({
  //                     tnbReadingId: tnb.id,
  //                   });
  //                 }}
  //               />
  //             </div>
  //           </TableCell>
  //         </TableRow>
  //       ))}
  //     />
  //   </div>
  // );
}
