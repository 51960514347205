import AppTitle from "../HomeScreen/components/AppTitle";
import PmLocationScreen from "./PmLocationScreen.tsx/PmLocationScreen";
import { useEffect, useState } from "react";
import { Place } from "@mui/icons-material";
import PmTableScreen from "./PmTableScreen.tsx/PmTableScreen";
import { Switch } from "../../components/ui/switch";
import { useForm } from "react-hook-form";
import { useSelectPmFilterStore } from "../../stores/pm/pmFilterStore";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { CerevModule } from "../../api/model";

export default function PmScreen() {
  const [isLocationView, setIsLocationView] = useState(false);

  // clear filter and sort params when unmount
  const cleanUp = useSelectPmFilterStore((state) => state.cleanUp);
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  return (
    <ModuleAccessGuard module={CerevModule.PM}>
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <AppTitle title="Preventive Maintenance" />
          <div className="flex gap-2 items-center">
            <Switch
              checked={isLocationView}
              onCheckedChange={(c) => setIsLocationView(c)}
              className="data-[state=checked]:bg-primary-900"
            />
            <Place className="text-primary-900" />
            <p className="font-sans">Location View</p>
          </div>
        </div>
        {isLocationView ? <PmLocationScreen /> : <PmTableScreen />}
      </div>
    </ModuleAccessGuard>
  );
}
